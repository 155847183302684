import * as _ from 'lodash';
import Swiper from 'swiper';
import * as moment from 'moment';
import
{
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  NgZone,
} from '@angular/core';
import { MessagesService } from '../messages/messages.service';
import { ScheduleService, DateSelectionSource, DateSelectionEvent } from './schedule.service';
import { Subscription, BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, skip, takeUntil, tap } from 'rxjs/operators';
import { ManageSubscriptionService } from '../settings/manage-subscriptions/manage-subscriptions.service';
import { StudentScheduleDay, ILastedGeoAlertNotification, IAttendance } from '../shared/stopfinder/stopfinder-models';
import { AppService } from '../app.service';
import { ActivatedRoute } from '@angular/router';
import { DeviceService } from '../components/service/device/device.service';
import { RealTimeUpdatesService } from '../shared/real-time-updates.service';
import { VehicleLocationService } from '../shared/vehicle-location.service';
import { AttendanceService } from '../components/service/attendance/attendance.service';

const DEBOUNCE = 1000;
const PULL_DOWN_TO_REFRESH_THRESHOLD = .3;
const VIBRATION_DURATION = 500;

@Component({
  selector: 'sf-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit, AfterViewInit, OnDestroy
{
  private selectedDateSubscription: Subscription;
  private readonly todayIndex = moment(this.scheduleService.getSelectedDate()).day() + 1;
  private readonly dateFormat: string = 'YYYY-MM-DD';
  private readonly today: string = moment().format(this.dateFormat);
  private readonly endOfNextYear: string = moment().add(1, 'year').endOf('month').format(this.dateFormat);
  public selectedDate: Date = null;
  public showCalendar = false;
  public swiper: Swiper;
  public startY = 0;
  public refreshSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public spinnerOpened = false;
  public snackRef = null;
  public triggerRefresh = false;
  private readonly isAndroidDevice: boolean = _.get(window, 'device') && _.isEqual(window['device'].platform.toLowerCase(), 'android');
  private manualPullDown = false;
  private readonly offsetCSS: number = 10; // should consider keep the initial value as the margin + padding size
  private offsetTopPosition = 0;
  public schedulesObservable = this.scheduleService.scheduleDaysWorkingSetObservable.pipe(
    tap(() =>
    {
      if (this.spinnerOpened)
      {
        navigator.vibrate(VIBRATION_DURATION);
      }
    }));

  public geoNotificationsObservable: { [key: string]: ILastedGeoAlertNotification } = null;

  public hideSchedule: boolean = false;
  private destroy$ = new Subject();

  constructor(
    public _appService: AppService,
    public readonly deviceService: DeviceService,
    public scheduleService: ScheduleService,
    private readonly messagesService: MessagesService,
    private readonly subscriptionService: ManageSubscriptionService,
    private readonly zone: NgZone,
    private readonly activeRouter: ActivatedRoute,
    private readonly _realTimeUpdatesService: RealTimeUpdatesService,
    private readonly _vehicleLocationService: VehicleLocationService,
    private readonly _attendanceService: AttendanceService,
  )
  {
    this.selectedDateSubscription = this.scheduleService.selectedDateObservable.pipe(takeUntil(this.destroy$)).subscribe(
      (value: DateSelectionEvent) =>
      {
        this.scheduleService.requestWorkingSet(value.date);
        if (value.source == DateSelectionSource.Calendar)
        {
          this.showCalendar = false;
        }
        if (this.swiper)
        {
          this.selectedDate = value.date;
          if (!_.isEqual(value.source, DateSelectionSource.ScheduleSwiper))
          {
            this.swiper.slideTo(moment(value.date).day() + 1, 300, false);
          }
        }
      }
    );

    this.scheduleService.geoNotificationsObservable.pipe(takeUntil(this.destroy$)).subscribe((notifications: ILastedGeoAlertNotification[]) =>
    {
      this.zone.run(() =>
      {
        this.geoNotificationsObservable = {};
        _.forEach(notifications, (notification: ILastedGeoAlertNotification) =>
        {
          this.geoNotificationsObservable[`${notification.riderId}-${notification.tripId}-${notification.dataSourceId}`] = notification;
        });
      })
    });

    this.scheduleService.firstTimeLoaded.pipe(takeUntil(this.destroy$)).subscribe((hasScheduleLoaded: boolean) =>
    {
      if (hasScheduleLoaded)
      {
        // TODO: not really proper place for this
        this.messagesService.getCommunications();
        this.subscriptionService.getOwnedSubscriptionsStatus();
      }
    });

    // open manual pull down effect for Android device since it doesn't support over scroll effect
    this.manualPullDown = this.isAndroidDevice;

    this.scheduleService.isDataLoaded.pipe(takeUntil(this.destroy$)).subscribe((isLoading: boolean) =>
    {
      if (this.triggerRefresh)
      {
        this.spinnerOpened = isLoading;
      }
    });

    this._appService.closeMapEvent.subscribe(() =>
    {
      if (this.showCalendar)
      {
        this.showCalendar = false;
      }
    });

    this.hideSchedule = !!this.activeRouter.snapshot.paramMap.get(`hideSchedule`);
  }

  ngOnInit()
  {
    this._realTimeUpdatesService.startRealTimeUpdatesSignalRConnection();
  }

  ngAfterViewInit()
  {
    this.zone.runOutsideAngular(() =>
    {
      this.swiper = new Swiper('#schedule-swiper', {
        loop: false,
        direction: 'horizontal',
        slidesPerView: 1.1,
        centeredSlides: true,
        observer: true,
        initialSlide: this.todayIndex,
        noSwipingClass: 'no-swiping',
        slideActiveClass: 'active-slide-schedule',
      });

      this.swiper.on('slideChange', this.indexChange.bind(this));

      const scheduleSwiperDom = document.getElementById('schedule-swiper');
      scheduleSwiperDom && scheduleSwiperDom.addEventListener('touchstart', (e) =>
      {
        this.startY = e.touches[0].pageY;
        this.triggerRefresh = false;

        const activeSlide = document.querySelector('.active-slide-schedule');
        const scrollTop = (activeSlide && activeSlide.scrollTop) || 0;

        if (activeSlide.scrollHeight <= activeSlide.clientHeight)
        {
          this.manualPullDown = true;
        }
        // should consider drag down position has been scrolled
        if (scrollTop > 0)
        {
          // should keep the scroll top value for touch move event
          this.offsetTopPosition = scrollTop + this.offsetCSS;
        }
      }, { passive: true });

      scheduleSwiperDom && scheduleSwiperDom.addEventListener('touchmove', (e) =>
      {
        const y = e.touches[0].pageY;

        const activeSlide = document.querySelector('.active-slide-schedule');
        const currentPosition = (activeSlide && activeSlide.scrollTop) || 0;

        if (currentPosition <= 0 && y > this.startY)
        {
          const movedValue = Math.abs(y - this.startY - this.offsetTopPosition);
          // should manually drag down the component since
          if (this.manualPullDown && movedValue <= window.innerHeight * PULL_DOWN_TO_REFRESH_THRESHOLD)
          {
            activeSlide && ((activeSlide as HTMLElement).style.transform = `translateY(${(y - this.startY - this.offsetTopPosition) * 0.5}px)`);
          }

          if (movedValue > window.innerHeight * PULL_DOWN_TO_REFRESH_THRESHOLD)
          {
            this.triggerRefresh = true;
            return;
          }
        }
        this.triggerRefresh = false;
      }, { passive: true });

      scheduleSwiperDom && scheduleSwiperDom.addEventListener('touchend', (e) =>
      {
        this.zone.run(() =>
        {
          // should reset offsetTopPosition to 0 for next move
          this.offsetTopPosition = 0;

          const activeSlide = document.querySelector('.active-slide-schedule');
          if (this.manualPullDown)
          {
            activeSlide && ((activeSlide as HTMLElement).style.transform = `translateY(0px)`);
            this.manualPullDown = this.isAndroidDevice;
          }

          if (!this.spinnerOpened && this.triggerRefresh)
          {
            this.spinnerOpened = true;
            this.refreshSubject.next(true);
          }
        });
      });

      this.refreshSubject.asObservable().pipe(debounceTime(DEBOUNCE), skip(1), takeUntil(this.destroy$)).subscribe(() =>
      {
        this._vehicleLocationService.flushAllTimersAndSubscriptions();
        this._vehicleLocationService.clearNetworkCache();
        this._realTimeUpdatesService.clearNetworkCache();
        this._attendanceService.clearNetworkDictionary();
        this._realTimeUpdatesService.startRealTimeUpdatesSignalRConnection();
        this.scheduleService.refreshScheduleDays();
        this.swiper.updateSlides();
      });

      this.scheduleService.clearCache();
      this.scheduleService.timerRequests();
    });
  }

  ngOnDestroy()
  {
    this.swiper && this.swiper.destroy(true, true);
    this.selectedDateSubscription.unsubscribe();
    this.destroy$.next();
  }

  indexChange()
  {
    const prevSelectedDate = this.scheduleService.getSelectedDate();
    const prevSelectedIndex = moment(prevSelectedDate).day() + 1;
    const deltaIndex = this.swiper.activeIndex - prevSelectedIndex;
    if (deltaIndex)
    {
      this.zone.run(() =>
      {
        this.scheduleService.addDaysToSelectedDate(
          deltaIndex,
          DateSelectionSource.ScheduleSwiper
        );
      });
    }
    this.swiper.updateSlides();
    if (this.swiper.activeIndex == 0)
    {
      this.swiper.slideTo(7, 1);
    } else if (this.swiper.activeIndex == 8)
    {
      this.swiper.slideTo(1, 1);
    }
  }

  isDisabledScheduled(studentScheduleDay: StudentScheduleDay): boolean
  {
    return moment(studentScheduleDay.date, this.dateFormat)
      .isBetween(
        moment(this.today, this.dateFormat).subtract(1, 'day'),
        moment(this.endOfNextYear, this.dateFormat).add(1, 'day')
      );
  }

  openCalendar()
  {
    this.showCalendar = true;
  }
}
