import { Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'sf-small-header',
  templateUrl: './small-header.component.html',
  styleUrls: ['./small-header.component.scss']
})
export class SmallHeaderComponent implements OnInit {
  constructor() { }

  @Output() public leftButtonClicked: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
  }

}
