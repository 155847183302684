import { Component, OnInit } from '@angular/core';
import { ManageSubscriptionService } from './manage-subscriptions.service';
import { StateService } from '../../components/service/state/state.service';
import { AppService } from '../../app.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from 'src/app/components/service/device/device.service';

@Component({
  selector: 'app-manage-subscriptions',
  templateUrl: './manage-subscriptions.component.html',
  styleUrls: ['./manage-subscriptions.component.scss']
})
export class ManageSubscriptionsComponent implements OnInit
{

  constructor(
    public _appService: AppService,
    public readonly manage: ManageSubscriptionService,
    private stateService: StateService,
    public translate: TranslateService,
    public readonly deviceService: DeviceService,
  )
  {
    this.manage.getSubscriptions();
  }

  ngOnInit()
  {
  }

  back()
  {
    this.stateService.goRoute('schedule');
  }

  scrollToTop()
  {
    const targets = document.getElementsByClassName('scroll-strategy') as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < targets.length; i++)
    {
      targets[i].scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
}
