import { InjectionToken, TemplateRef } from '@angular/core';
import { Communication } from '../stopfinder/stopfinder-models';

export class ToastData
{
  duration?: number;
  type?: ToastType;
  text?: string;
  template?: TemplateRef<any>;
  templateContext?: {};
  data?: {
    communication: Communication,
    appService: any
  }
}

export type ToastType = 'warning' | 'info' | 'success';

export interface ToastConfig
{
  position?: {
    top: number;
    right: number;
  };
  animation?: {
    out: number;
    in: number;
  };
}

export const defaultToastConfig: ToastConfig = {
  position: {
    top: 20,
    right: 0,
  },
  animation: {
    out: 2500,
    in: 300,
  },
};

export const TOAST_CONFIG_TOKEN = new InjectionToken('toast-config');
export const TOAST_DATA: InjectionToken<any> = new InjectionToken('toast-data');
