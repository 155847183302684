import { Injectable } from '@angular/core';
import
{
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { AppService } from './app.service';
import { Observable } from 'rxjs';
import { RouterInterceptService } from './components/service/router-intercept/router-intercept.service';
import { DeviceService } from './components/service/device/device.service';

@Injectable()
export class AuthGuard implements CanActivate
{
  constructor(
    private readonly routerInterceptService: RouterInterceptService,
    private readonly appService: AppService,
    private readonly deviceService: DeviceService,
    private readonly router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean
  {
    if (this.appService.token)
    {
      return this.routerInterceptService.routerDetect(next, state);
    } else
    {
      let router = ['/login'];
      // open the browser and not login, navigate to the download app page
      if (this.deviceService.isBrowser)
      {
        router = ['/downloadapp'];
      }
      this.router.navigate(router);
      return false;
    }
  }
}
