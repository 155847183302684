import { Component, EventEmitter, Input, Output, HostBinding, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'tf-action-sheet',
  templateUrl: './action-sheet.component.html',
  styleUrls: ['./action-sheet.component.scss'],
  animations: [
    trigger('ease', [
      state('visible', style({
        transform: 'translateY(0%)',
        visibility: 'visible'
      })),
      state('hidden', style({
        transform: 'translateY(486px)',
        visibility: 'hidden'
      })),
      transition('visible -> hidden', animate('300ms ease-out')),
      transition('hidden -> visible', animate('300ms ease-in'))
    ])
  ]
})
export class ActionSheetComponent implements OnInit
{

  private _visible = false;
  @HostBinding('@ease')
  public state = 'hidden';
  get visible()
  {
    return this._visible;
  }
  @Input('visible')
  set visible(value: boolean)
  {
    this._visible = value;
    this.state = value ? 'visible' : 'hidden';
  }

  @Input() title: string;
  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input('showOverlay') showOverlay = true;

  constructor(
    public translate: TranslateService,
  ) { }

  ngOnInit() { }

  visibility()
  {
    switch (this.visible)
    {
      case true:
        return 'visible';

      case false:
        return 'hidden';
    }
  }

  close(event: MouseEvent)
  {
    if (!event)
    {
      this.visible = false;
      this.closeEvent.emit();
    } else
    {
      if (event.toElement.className === 'content' || event.toElement.innerHTML === 'RouteBuilder')
      {
        this.visible = false;
        this.closeEvent.emit();
      }
    }
  }
}
