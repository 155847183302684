import { BaseBaseMapGenerator } from '../basemaps/classes/basemap.base';
import { eBaseMaps } from './classes/basemap.enum';
import { BasemapThumbnail } from '../basemaps/classes/basemap.thumbnail';
import { StreetsMap } from './maps/streets';
import { DarkGreyMap } from './maps/dark-grey';
import { LightGreyMap } from './maps/light-grey';
import { OpenStreetsMap } from './maps/open-street-map';
import { StreetsNightsMap } from './maps/streets-night';
import { ImageryLabelMap } from './maps/imagery-labels';

export class BaseMapsFactory
{

  private static readonly _darkGrey: DarkGreyMap = new DarkGreyMap();
  private static readonly _lightGrey: LightGreyMap = new LightGreyMap();
  private static readonly _openStreets: OpenStreetsMap = new OpenStreetsMap();
  private static readonly _streets: StreetsMap = new StreetsMap();
  private static readonly _streetsNight: StreetsNightsMap = new StreetsNightsMap();
  private static readonly _imageryLabels: ImageryLabelMap = new ImageryLabelMap();


  public static getThumbnail(e: eBaseMaps): BasemapThumbnail
  {
    return this.getBaseMapObj(e).getThumbnail();
  }

  public static getAllThumbnails(): BasemapThumbnail[]
  {
    const arr: BasemapThumbnail[] = [];
    arr.push(this.getThumbnail(eBaseMaps.DarkGrey));
    arr.push(this.getThumbnail(eBaseMaps.ImageryWithLabels));
    arr.push(this.getThumbnail(eBaseMaps.LightGrey));
    arr.push(this.getThumbnail(eBaseMaps.OpenStreets));
    arr.push(this.getThumbnail(eBaseMaps.Streets));
    arr.push(this.getThumbnail(eBaseMaps.StreetsNight));
    return arr;
  }

  private static getBaseMapObj(e: eBaseMaps): BaseBaseMapGenerator
  {
    let out: BaseBaseMapGenerator = null;
    switch (e)
    {
      case eBaseMaps.DarkGrey:
        out = this._darkGrey;
        break;
      case eBaseMaps.LightGrey:
        out = this._lightGrey;
        break;
      case eBaseMaps.OpenStreets:
        out = this._openStreets;
        break;
      case eBaseMaps.Streets:
        out = this._streets;
        break;
      case eBaseMaps.StreetsNight:
        out = this._streetsNight;
        break;
      case eBaseMaps.ImageryWithLabels:
        out = this._imageryLabels;
        break;
      default:
        out = null;
    }
    return out;
  }

}
