import { Injectable } from "@angular/core";
import { SignalRWrapperService, HubEventListeners } from "../components/service/signalr/signalr-wrapper.service";
import { StudentSchedule, TripSchedule } from "./stopfinder/stopfinder-models";
import { StopfinderApiService } from '../shared/stopfinder/stopfinder-api.service';
import { catchError, finalize, first, map, shareReplay, skip, startWith, switchMap, take, takeUntil, takeWhile, tap } from "rxjs/operators";
import { Observable, timer, of, Subject, merge, interval, iif } from "rxjs";
import { VEHICLE_SIGNALR_KEY, HIDE_BUS_ICON_INTERVAL } from "./utils/constant";
import { IVehiclePoint } from "./stopfinder/models/map";
import * as moment from "moment";
import { ScheduleService } from "../schedule/schedule.service";
import { ConnectionState } from "../components/service/signalr/signalr.service";

export enum GPSStatus
{
  Searching = 0,
  NotAvailable = 1,
  NoVehicleAssigned = 2,
  ValidGPS = 3,
}

interface NetworkCacheDictionary
{
  [key: string]: Observable<IVehiclePoint>;
}

interface VehicleEventSignalRConnection
{
  [key: string]: Observable<any>;
}

interface TimerDictionary
{
  [key: string]: TimerDictionaryEntry
}

interface TimerDictionaryEntry
{
  cancelGpsTimer: Subject<void>;
  Timer: Observable<number>;
}

@Injectable()
export class VehicleLocationService
{

  private _networkCacheDictionary: NetworkCacheDictionary = {};
  private _connections: VehicleEventSignalRConnection = {};
  private $clear = new Subject<void>();
  private $clearTimer = new Subject<void>();
  private _timerDictionary: TimerDictionary = {};
  constructor(
    private readonly _signalRWrapperService: SignalRWrapperService,
    private readonly _apiService: StopfinderApiService,
    private readonly _scheduleService: ScheduleService)
  {

  }

  public getLastKnownVehicleLocation(studentSchedule: StudentSchedule, tripSchedule: TripSchedule)
  {
    if (!tripSchedule.busNumber)
    {
      this._scheduleService.updateScheduleProperties(studentSchedule, tripSchedule, 'gpsStatus', GPSStatus.NoVehicleAssigned);
      return of(null);
    }

    const groupName = this._signalRWrapperService.getVehicleEventsEndPoint(studentSchedule, tripSchedule);
    const networkCache = this._networkCacheDictionary[groupName];
    this._scheduleService.updateScheduleProperties(studentSchedule, tripSchedule, 'gpsStatus', GPSStatus.Searching);
    if (!networkCache)
    {
      this._networkCacheDictionary[groupName] = this._apiService.getLastBusLocation(this._signalRWrapperService.getVehicleEventsGroupName(studentSchedule, tripSchedule), tripSchedule.busNumber)
        .pipe(
          takeUntil(merge(this.$clear, this._scheduleService.schedulesHiddenForMapObservable)),
          shareReplay(1),
          catchError(() => of(null)),
          finalize(() => delete this._networkCacheDictionary[groupName])
        );
    }
    return this._networkCacheDictionary[groupName];
  }

  public monitorSignalRLocation(studentSchedule: StudentSchedule, tripSchedule: TripSchedule): Observable<any>
  {
    const groupName = this._signalRWrapperService.getVehicleEventsGroupName(studentSchedule, tripSchedule);
    if (this._connections[groupName])
    {
      return this._connections[groupName];
    }
    let vehicleEventConnection = this._startVehicleEventsSignalRConnection(studentSchedule, tripSchedule).pipe(switchMap(
      (connectionEventListeners: HubEventListeners) =>
      {
        if (connectionEventListeners)
        {
          const onConnected = connectionEventListeners.onReceived.pipe(
            map(point =>
            {
              const data: IVehiclePoint = point && point.data[0] || null;
              if (data)
              {
                data.busNumber = point.busNumber;
              }
              return data;
            }),
            tap(() => this.handleGPSTimer(studentSchedule, tripSchedule))
          );
          const notConnected = this._pollForUpdates(studentSchedule, tripSchedule);
          return connectionEventListeners.state.pipe(
            switchMap((state) => iif(() => state === ConnectionState.Connected, onConnected, notConnected))
          );
        } else
        {
          // failed to initially connect begin polling
          return this._pollForUpdates(studentSchedule, tripSchedule);
        }
      }));
    this._connections[groupName] = vehicleEventConnection;
    return vehicleEventConnection;
  }

  private _pollForUpdates(studentSchedule: StudentSchedule, tripSchedule: TripSchedule): Observable<any>
  {

    return interval(60 * 1000).pipe(startWith(0), switchMap(() =>
    {
      return this.getLastKnownVehicleLocation(studentSchedule, tripSchedule).pipe(first(), catchError(() => of(null)));
    }));
  }


  public handleGPSTimer(studentSchedule: StudentSchedule, tripSchedule: TripSchedule, timeDiff = 0): void
  {
    this.clearTimer(studentSchedule, tripSchedule);

    const cancelGpsStatusTimer = new Subject<void>();
    const gpsStatusTimer = timer(HIDE_BUS_ICON_INTERVAL - timeDiff).pipe(
      takeUntil(merge(this.$clearTimer, cancelGpsStatusTimer)),
      finalize(() => this.clearTimer(studentSchedule, tripSchedule))
    );
    this._timerDictionary[this._getDictionaryKey(studentSchedule, tripSchedule)] = {
      cancelGpsTimer: cancelGpsStatusTimer,
      Timer: gpsStatusTimer
    };
    gpsStatusTimer.subscribe(() =>
    {
      this._scheduleService.updateScheduleProperties(studentSchedule, tripSchedule, 'gpsStatus', GPSStatus.NotAvailable);
      if (!this._scheduleService.isTripRunning(studentSchedule, tripSchedule))
      {
        this._closeVehicleEventSignalRConnection(studentSchedule, tripSchedule);
      }
    });
  }

  public isValidEvent(point: IVehiclePoint): boolean
  {
    return !!point && this.getTimeDiffMS(point) < HIDE_BUS_ICON_INTERVAL;
  }

  public getTimeDiffMS(point: IVehiclePoint): number
  {
    const now = moment();
    // potentially unix
    const pointTimeStamp = moment.unix(point.Timestamp);
    return Math.abs(now.diff(pointTimeStamp, 'ms'));
  }

  private _startVehicleEventsSignalRConnection(studentSchedule: StudentSchedule, tripSchedule: TripSchedule): Observable<any>
  {
    if (!tripSchedule.busNumber)
    {
      this._scheduleService.updateScheduleProperties(studentSchedule, tripSchedule, 'gpsStatus', GPSStatus.NoVehicleAssigned);
      return of(null);
    }
    const endPoint = this._signalRWrapperService.getVehicleEventsEndPoint(studentSchedule, tripSchedule);
    const name = this._signalRWrapperService.getName(VEHICLE_SIGNALR_KEY);
    const mark = this._signalRWrapperService.getMark(tripSchedule);
    return this._signalRWrapperService.start(VEHICLE_SIGNALR_KEY, endPoint, name, mark);
  }

  private _closeVehicleEventSignalRConnection(studentSchedule: StudentSchedule, tripSchedule: TripSchedule): void
  {
    this._timerDictionary[this._getDictionaryKey(studentSchedule, tripSchedule)].cancelGpsTimer.next();
    this._signalRWrapperService.close(VEHICLE_SIGNALR_KEY, this._signalRWrapperService.getVehicleEventsEndPoint(studentSchedule, tripSchedule));
  }

  private clearTimer(studentSchedule: StudentSchedule, tripSchedule: TripSchedule)
  {
    const timerEntry = this._timerDictionary[this._getDictionaryKey(studentSchedule, tripSchedule)];
    if (timerEntry)
    {
      timerEntry.cancelGpsTimer.next();
    }
    // clear timer entry
    delete this._timerDictionary[this._getDictionaryKey(studentSchedule, tripSchedule)];
  }

  private _getDictionaryKey(studentSchedule: StudentSchedule, tripSchedule: TripSchedule)
  {
    return `${studentSchedule.riderId}_${tripSchedule.id}`;
  }

  public flushAllTimersAndSubscriptions(): void
  {
    this.$clearTimer.next();
  }

  public clearNetworkCache()
  {
    this._networkCacheDictionary = {};
    this.$clear.next();
  }
}
