import { Document } from './document';
import { StudentSchedule } from './../models/student-schedule';
import { IAttendance } from './attendance';

export interface Communication
{
  type: CommunicationType;
  body: string;
  subject: string;
  sentOn: string;
  read?: boolean;
  archived?: boolean;
  id?: number;
  riderId?: number;
  dataSourceId?: number;
  tripId?: number;
  clientName?: string;
  documents?: Document;
  allMessages?: string[];
  schedule?: StudentSchedule;
  formRecipientId?: number;
  formName?: string;
  completed?: string;
  required?: boolean;
  isExpirationEnabled?: boolean;
  expirationDateTime?: string;
  attendance?: IAttendance
}

export enum CommunicationFilter
{
  UnRead = 'Unread',
  Archived = 'Archived',
  Default = 'default',
  Messages = 'Messages',
  Announcements = 'Announcements',
  Forms = 'Forms',
}

export enum CommunicationType
{
  Announcement = 'Announcement',
  Announcements = 'Announcement',
  Message = 'Message',
  Messages = 'Message',
  GeoAlert = 'GeoAlert',
  EtaAlert = 'EtaAlert',
  Form = 'Form',
  Forms = 'Form',
  Attendance = "Attendance"
}
