import * as _ from 'lodash';
import * as Hammer from 'hammerjs';
import
{
  Directive,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  NgZone,
  OnDestroy,
} from '@angular/core';
import { AppService } from 'src/app/app.service';
import { StateService } from 'src/app/components/service/state/state.service';
import { MatSnackBarRef } from '@angular/material';
import { PushSnackbarComponent } from 'src/app/messages/push-snackbar/push-snackbar.component';

export const IGNORE_LTR = 'swipeLTR-ignore';

// direction enum sets standard base on: https://hammerjs.github.io/api/
enum SwipeDirectionEnum
{
  DIRECTION_NONE = 1,
  DIRECTION_LEFT = 2,
  DIRECTION_RIGHT = 4,
  DIRECTION_UP = 8,
  DIRECTION_DOWN = 16,
  DIRECTION_HORIZONTAL = 6,
  DIRECTION_VERTICAL = 24,
  DIRECTION_ALL = 30,
}

interface IEvent extends RecognizerOptions, HTMLElement
{
  target: Element;
}

@Directive({
  selector: '[swipeLTR]'
})
export class SwipeLTRDirective implements AfterViewInit
{
  constructor(
    private el: ElementRef,
    private appService: AppService,
    private stateService: StateService,
    private readonly ngZone: NgZone,
  ) { }

  @Input() ignoreItem = '';
  @Input() stopSwipeBack = "";
  @Output() callbackHook: EventEmitter<any> = new EventEmitter<any>();
  @Output() beforeSwipeHook: EventEmitter<any> = new EventEmitter<any>();
  @Output() afterSwipeHook: EventEmitter<any> = new EventEmitter<any>();

  ngAfterViewInit()
  {
    const hammerTime = new Hammer(this.el.nativeElement);
    // set hammer direction for swipe event
    hammerTime.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL });

    hammerTime.on('swiperight', (ev) =>
    {
      this.beforeSwipeHook.emit(this.el);
      if (!_.isEmpty(this.ignoreItem))
      {
        const target: any = ev.target.closest(this.ignoreItem) || undefined;
        if (target && target.classList.contains(IGNORE_LTR))
        {
          return;
        }
      }

      if (this.el.nativeElement.classList.contains(IGNORE_LTR))
      {
        return;
      }

      if (this.callbackHook && this.stopSwipeBack)
      {
        this.callbackHook.emit();
        return;
      }

      this.delegateSwipeLTR(ev);

      this.afterSwipeHook.emit(this.el);
    });
  }

  /**
   * Function to delegate swipe LTR event
   * @param event Hammer Swipe events
   * @param to (Optional) default value back to redirect back to previous router
   *
   */
  private delegateSwipeLTR(event)
  {
    this.ngZone.run(() =>
    {
      // direction: 4 means swipe from left to right
      if (event && event.direction && _.isEqual(event.direction, SwipeDirectionEnum.DIRECTION_RIGHT))
      {
        let lastLocation = this.appService.lastLocation ? this.appService.lastLocation : `schedule`;
        this.stateService.goRoute(`${lastLocation}`);
      }
    });

  }

  ngOnDestroy() { }
}

@Directive({
  selector: `[swipeDTU]`
})
export class SwipeDTUDirective implements AfterViewInit, OnDestroy
{
  @Output() swipeUpCallback: EventEmitter<any> = new EventEmitter<any>();
  public hammer: HammerManager;

  constructor(
    private el: ElementRef,
    private readonly ngZone: NgZone,
  ) { }

  ngAfterViewInit()
  {
    this.hammer = new Hammer(this.el.nativeElement);
    // set hammer direction for swipe event
    this.hammer.get('swipe').set({ direction: Hammer.DIRECTION_UP });

    this.hammer.on('swipeup', (ev) =>
    {
      this.ngZone.run(() =>
      {
        this.swipeUpCallback.emit();
      });
    });
  }

  ngOnDestroy()
  {
    this.el = null;
    this.hammer = null;
    this.swipeUpCallback = null;
  }
}
