import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { AppService } from '../../app.service';
import * as pdfjsLib from "pdfjs-dist/webpack.js";
import { PDFDocumentProxy } from 'pdfjs-dist';
import { DeviceService } from 'src/app/components/service/device/device.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

export interface IDocViewerConfig
{
  docType?: string;
  docName?: string;
  docBytes?: string;
  docSize?: string;
  docSrc?: string;
  downloadEvent?: Function;
  closeEvent?: Function;
  shareEvent?: Function;
}

@Component({
  selector: 'doc-viewer',
  templateUrl: './doc-viewer.component.html',
  styleUrls: ['./doc-viewer.component.scss']
})

export class DocViewerComponent implements OnInit
{
  @Input() public config: IDocViewerConfig = {};

  @ViewChild('pdfContent', { static: true }) pdfContent: ElementRef;
  @ViewChild('docContent', { static: true }) docContent: ElementRef;

  public displayName: string;
  public displayType: string;
  public displaySize: string;
  public imageSrc: SafeResourceUrl;
  public zoomHeight: string;
  public enableDownload = false;
  public enableShare = false;
  public docBase64Code: string;

  public hideWhenLoading: boolean = true;

  private pdfBytes: string;
  private screenWidth: number;
  private pdfDocument: PDFDocumentProxy = null;
  private renderPdfPending: boolean = false;
  private pdfScale: number;
  private pinchTimer: any;

  private docType: string;
  private docName: string;
  private docBytes: string;
  private docSrc: string;

  constructor(
    public readonly deviceService: DeviceService,
    private readonly domSanitizer: DomSanitizer,
    private readonly appService: AppService,
  ) { }

  ngOnInit()
  {
    this.mergeConfig();

    const clientHeight = document.body.clientHeight;
    const clientWidth = document.body.clientWidth;
    this.screenWidth = clientWidth;
    this.docContent.nativeElement.style.height = `${clientHeight - 120}px`;

    const docName = (this.docName || '');
    this.displayName = docName.substr(0, docName.lastIndexOf('.'));
    this.displayType = docName.substr(docName.lastIndexOf('.') + 1);

    this.docBase64Code = `data:${this.docType};base64,${this.docBytes}`;
    if (this.docType.includes('image/'))
    {
      this.imageSrc = this.getImageUrl(this.docBase64Code);;
      this.zoomHeight = `${clientHeight - 120}px`; // 120 is header and footer height
    }
    if (this.docType.includes('application/pdf'))
    {
      this.pdfBytes = atob(this.docBytes);
      this.onLoadPdfFile(this.pdfBytes);
    }
    this.hideWhenLoading = false;
  }

  onCloseDocViewer()
  {
    this.emitEvent("closeEvent");
    this.dismiss();
  }

  onDownloadFile()
  {
    this.appService.fileOpenerInstance.showOpenWithDialog(this.docSrc, this.docType, {
      error: (e) => { },
      success: () => { }
    });
    this.emitEvent("downloadEvent", this.config);
  }

  onShareFile()
  {
    const socialShareInstance = this.appService.socialShareInstance;
    if (socialShareInstance)
    {
      socialShareInstance.available((isAvailable) =>
      {
        if (isAvailable)
        {
          socialShareInstance.share(this.docName, this.docName, this.docSrc, null, null, () =>
          {
            //TODO: if you can todo something when share success, you can add some code to here
          }, (e) => { });
        }
      });
    }
    this.emitEvent("shareEvent", this.config);
  }

  onPinchZoomEvents(event)
  {
    if (event && event.type === 'pinch')
    {
      if (!event.scale || !this.docType.includes('application/pdf'))
      {
        return;
      }

      if (this.pinchTimer)
      {
        clearTimeout(this.pinchTimer);
      }

      this.pinchTimer = setTimeout(() =>
      {
        this.zoomInPdf(event.scale);
      }, 50); // Prevent multiple times reload pdf
    }
  }

  private mergeConfig()
  {
    const { docName, docBytes, docSize, docType, docSrc } = this.config;

    this.docName = docName;
    this.docBytes = docBytes;
    this.docType = docType;
    this.docSrc = docSrc;
    this.displaySize = docSize;
  }

  private getImageUrl(base64Img): SafeResourceUrl
  {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(base64Img);
  }

  private async onLoadPdfFile(pdfBytes: string)
  {
    this.pdfDocument = await pdfjsLib.getDocument({ data: pdfBytes });

    if (!this.pdfDocument)
    {
      return;
    }

    this.onGeneratePdfPage();
  }

  private async onGeneratePdfPage(pdfDocument?: PDFDocumentProxy, pdfScale?: number)
  {
    const _pdfDocument = pdfDocument || this.pdfDocument;
    this.pdfContent.nativeElement.innerHTML = '';

    const promiseList = [];
    for (let i = 1; i <= _pdfDocument.numPages; i++)
    {
      promiseList.push(this.onRenderPdf(_pdfDocument, i, pdfScale));
    }

    return Promise.all(promiseList);
  }

  private async onRenderPdf(pdf: PDFDocumentProxy, pageNum: number, pdfScale?: number): Promise<boolean>
  {
    const page = await pdf.getPage(pageNum);
    const viewportWidth = page.getViewport({ scale: 1 }).width;
    this.pdfScale = pdfScale || this.screenWidth / viewportWidth;
    const viewport = page.getViewport({
      scale: this.pdfScale
    });
    const canvas = document.createElement("canvas");
    this.pdfContent.nativeElement.appendChild(canvas);
    const canvasContext = canvas.getContext('2d');
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    return new Promise<boolean>((resolve) =>
    {
      page.render({ canvasContext, viewport, }).promise.then(() =>
      {
        return resolve(true);
      });
    });
  }

  private async zoomInPdf(scale)
  {
    const pdfScale = Number((this.pdfScale + ((scale - 1) * 0.75)).toFixed(2));
    if (pdfScale > 2 || pdfScale < 0.3)
    {
      return;
    }

    if (this.renderPdfPending)
    {
      return;
    }

    await this.onGeneratePdfPage(this.pdfDocument, pdfScale);

    this.renderPdfPending = false;
  }

  private emitEvent(eventName: string, data?: any)
  {
    const event = this.config[eventName];
    typeof event === "function" && event(data);
  }

  private dismiss()
  {
    this.renderPdfPending = false;
    this.pdfDocument = null;
    this.config = {};
    this.hideWhenLoading = true;
  }

}
