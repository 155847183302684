import * as _ from 'lodash';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { DocViewerComponent } from './doc-viewer/doc-viewer.component';
import { RefreshSnackBar } from './refresh/refresh-snackbar.component';
import { SignaturePadComponent, SignaturePadDialogComponent } from './signature-pad/signature-pad.component';
import { AttachmentsComponent } from './attachments/attachments.component';
import { IndicatorService } from './refresh/indicator.service';
import { PortalService } from './portal/portal.service';
import
{
  SafeHtmlPipe,
  StripHtmlPipe,
  RelativeDate,
  FormatTripNamePipe,
  EffectDatingPipe,
  CellPhoneDatingPipe
} from './pipe/pipe.export';
import
{
  SwipeLTRDirective,
  SwipeDTUDirective,
  ValidateResetPasswordDirective,
  PhoneMaskDirective,
} from '../components/directive/declaration.export';
import { RealTimeUpdatesService } from './real-time-updates.service';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    PinchZoomModule,
  ],
  declarations: [
    SafeHtmlPipe,
    StripHtmlPipe,
    RelativeDate,
    FormatTripNamePipe,
    EffectDatingPipe,
    CellPhoneDatingPipe,
    DocViewerComponent,
    SignaturePadComponent,
    SignaturePadDialogComponent,
    AttachmentsComponent,
    RefreshSnackBar,
    PhoneMaskDirective,
    SwipeLTRDirective,
    SwipeDTUDirective,
    ValidateResetPasswordDirective,
  ],
  exports: [
    SafeHtmlPipe,
    StripHtmlPipe,
    RelativeDate,
    FormatTripNamePipe,
    EffectDatingPipe,
    CellPhoneDatingPipe,
    DocViewerComponent,
    SignaturePadComponent,
    SignaturePadDialogComponent,
    AttachmentsComponent,
    RefreshSnackBar,
    PhoneMaskDirective,
    SwipeLTRDirective,
    SwipeDTUDirective,
    ValidateResetPasswordDirective,
  ],
  entryComponents: [
    DocViewerComponent,
    SignaturePadComponent,
    SignaturePadDialogComponent,
    AttachmentsComponent,
    RefreshSnackBar,
  ],
  providers: [
    IndicatorService,
    PortalService,
    RealTimeUpdatesService
  ]
})
export class SharedModule { }
