import { Injectable } from "@angular/core";
import { merge, Observable, of, timer, Subject, BehaviorSubject, interval, iif } from "rxjs";
import { catchError, filter, finalize, first, map, shareReplay, startWith, switchMap, take, takeUntil, takeWhile } from "rxjs/operators";
import { HubEventListeners, SignalRWrapperService } from "../components/service/signalr/signalr-wrapper.service";
import { ScheduleService } from "../schedule/schedule.service";
import { StudentSchedule, TripSchedule } from "./stopfinder/models/student-schedule";
import { StopfinderApiService } from "./stopfinder/stopfinder-api.service";
import { StopStatus } from "./stopfinder/stopfinder-models";
import { REAL_TIME_UPDATES_KEY } from "./utils/constant";
import { VersionDictionary } from "./stopfinder/models/version-dictionary";
import { HIDE_ETA_INTERVAL } from "../shared/utils/constant";
import { HubConnectionState } from "@microsoft/signalr";
import { ConnectionState } from "../components/service/signalr/signalr.service";
import * as moment from "moment";

export interface StopStatusDictionary
{
  [key: string]: StopStatus;
}

interface ITimerDictionary
{
  [key: string]: ITimer
}

interface ITimer
{
  Timer: Observable<number>,
  Clear: Subject<boolean>
}

@Injectable()
export class RealTimeUpdatesService 
{
  private _removeEtaTimers: ITimerDictionary = {};
  private _networkCacheDictionary: Observable<StopStatusDictionary> = null;
  private $clear = new Subject<void>();
  private _versionDictionary: VersionDictionary = {};

  constructor(private readonly _apiService: StopfinderApiService,
    private readonly _scheduleService: ScheduleService,
    private readonly _signalRWrapperService: SignalRWrapperService)
  {

  }

  set versionDictionary(versionDictionary: VersionDictionary)
  {
    this._versionDictionary = versionDictionary;
  }

  get versionDictionary(): VersionDictionary
  {
    return this._versionDictionary;
  }

  fetchLastRealTimeUpdate(studentSchedule: StudentSchedule, tripSchedule: TripSchedule, pickUp: boolean): Observable<StopStatus>
  {
    const groupName = this._signalRWrapperService.getRealTimeUpdatesGroupName(studentSchedule, tripSchedule, pickUp);
    const groupNames = [];
    return this._scheduleService.scheduleDaysWorkingSetObservable.pipe(
      filter((val) => !!val),
      take(1),
      switchMap((studentScheduleDays) =>
      {
        const studentScheduleDay = studentScheduleDays.find((ssd) => moment(ssd.date).isSame(moment().format('YYYY-MM-DD')));
        studentScheduleDay.studentSchedules.forEach((schedule) =>
        {
          if (this._versionDictionary[schedule.clientId] !== false && schedule.enableEtaAlerts)
          {
            schedule.trips.forEach((trip) =>
            {
              groupNames.push(this._signalRWrapperService.getRealTimeUpdatesGroupName(schedule, trip, true));
              groupNames.push(this._signalRWrapperService.getRealTimeUpdatesGroupName(schedule, trip, false));
            });
          }
        });

        if (!this._networkCacheDictionary)
        {
          this._networkCacheDictionary = this._apiService
            .getRealTimeUpdateMultiple(groupNames)
            .pipe(
              takeUntil(
                merge(
                  this.$clear,
                  this._scheduleService.schedulesHiddenForMapObservable
                )
              ),
              shareReplay(1),
              catchError(() => of({})),
              finalize(() => (this._networkCacheDictionary = null))
            );
        }
        return this._networkCacheDictionary.pipe(
          switchMap((dict) =>
          {
            return of(dict[groupName]);
          })
        );
      }));
  }

  // use at schedules level to open connection
  public startRealTimeUpdatesSignalRConnection(): Observable<HubEventListeners>
  {
    const endPoint = this._signalRWrapperService.getRealTimeUpdatesEndPoint();
    const name = this._signalRWrapperService.getName(REAL_TIME_UPDATES_KEY);
    return this._signalRWrapperService.start(REAL_TIME_UPDATES_KEY, endPoint, name)
      .pipe(
        take(1),
        catchError(() => of(null))
      )
  }

  public monitorRealTimeUpdatesGroup(studentSchedule: StudentSchedule, tripSchedule: TripSchedule, pickUp: boolean): Observable<StopStatus>
  {
    if (this._versionDictionary[studentSchedule.clientId] !== false)
    {
      return this._subscribeToUpdatesFromGroupName(studentSchedule, tripSchedule, pickUp);
    } else
    {
      return of(null);
    }
  }

  public removeEtaAfter5Mins(studentSchedule: StudentSchedule, tripSchedule: TripSchedule, pickUp: boolean)
  {
    const key = this._getDictionaryKey(studentSchedule, tripSchedule, pickUp);
    if (this._removeEtaTimers[key])
    {
      this._removeEtaTimers[key].Clear.next(true);
      delete this._removeEtaTimers[key];
    }

    const clear = new Subject<boolean>();
    const rxjsTimer = timer(HIDE_ETA_INTERVAL).pipe(
      take(1),
      takeUntil(clear),
      finalize(() => delete this._removeEtaTimers[key])
    );
    this._removeEtaTimers[key] = {
      Timer: rxjsTimer,
      Clear: clear
    }
    return rxjsTimer;
  }

  private _subscribeToUpdatesFromGroupName(studentSchedule: StudentSchedule, tripSchedule: TripSchedule, isPickUp: boolean): Observable<StopStatus>
  {
    const endPoint = this._signalRWrapperService.getRealTimeUpdatesEndPoint();
    const groupName = this._signalRWrapperService.getRealTimeUpdatesGroupName(studentSchedule, tripSchedule, isPickUp);
    let lastState: ConnectionState;
    const connection = this.startRealTimeUpdatesSignalRConnection().pipe(
      takeWhile(() => this._scheduleService.isTripRunning(studentSchedule, tripSchedule)),
      switchMap((connectionEventListeners) =>
      {
        if (!!connectionEventListeners)
        {
          const onConnected = this._signalRWrapperService.invoke(REAL_TIME_UPDATES_KEY, endPoint, 'Subscribe', groupName)
            .pipe(
              switchMap(() =>
              {
                // listen to full stream and divide the data into what is relevant for the current student schedule calling the function
                return connectionEventListeners.onReceived.pipe(
                  map((messageData: any) => messageData.data),
                  filter((message: StopStatus) => message.GroupName === this._signalRWrapperService.getRealTimeUpdatesGroupName(studentSchedule, tripSchedule, isPickUp)),
                );
              })
            );
          const notConnected = this._pollForUpdates(studentSchedule, tripSchedule, isPickUp);
          // Monitor state of connection and operate as needed
          return connectionEventListeners.state.pipe(
            switchMap((state: ConnectionState) =>
            {
              lastState = state;
              return iif(() => state === ConnectionState.Connected, onConnected, notConnected)
            })
          );
        } else
        {
          return this._pollForUpdates(studentSchedule, tripSchedule, isPickUp);
        }
      }),
      finalize(() => iif(() => lastState === ConnectionState.Connected, this._signalRWrapperService.invoke(REAL_TIME_UPDATES_KEY, endPoint, 'Unsubscribe', groupName), of(null)))
    );
    return connection;
  }

  private _pollForUpdates(studentSchedule: StudentSchedule, tripSchedule: TripSchedule, isPickUp: boolean): Observable<StopStatus>
  {

    return interval(60 * 1000).pipe(startWith(0), switchMap(() =>
    {
      return this.fetchLastRealTimeUpdate(studentSchedule, tripSchedule, isPickUp).pipe(first(), catchError(() => of(null)));
    }));
  }

  private _getDictionaryKey(studentSchedule: StudentSchedule, tripSchedule: TripSchedule, pickUp: boolean): string
  {
    return `${studentSchedule.riderId}_${tripSchedule.id}_${pickUp}`;
  }

  public clearNetworkCache()
  {
    this._networkCacheDictionary = null;
    this.$clear.next();
  }
}
