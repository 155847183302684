import { Injectable } from "@angular/core";
import { SignalRService, ConnectionState } from "./signalr.service";
import * as SignalR from '@microsoft/signalr';
import { Subject, Observable, of } from "rxjs";
import { StudentSchedule, TripSchedule } from "src/app/shared/stopfinder/stopfinder-models";
import { REAL_TIME_UPDATES_KEY, SCANNED_SIGNALR_KEY, VEHICLE_SIGNALR_KEY } from "src/app/shared/utils/constant";
import { catchError, map, take, takeUntil } from "rxjs/operators";

export interface HubEventListeners
{
	onReceived: Observable<any>;
	state: Observable<ConnectionState>;
}

@Injectable()
export class SignalRWrapperService
{
	constructor(private readonly _signalRService: SignalRService)
	{
	}

	start(type: Symbol, endpoint: string, name: string, mark = {}): Observable<HubEventListeners>
	{
		const connectionKey = this.getConnectionKey(type, endpoint);
		const connectionObservable = this._signalRService.get(connectionKey, endpoint, name, mark).pipe(
			map((hubConnection) =>
			{
				const connectionState = this._signalRService.getConnectionState(connectionKey);
				const close = this._signalRService.getClose(connectionKey);
				const onReceived = this.setUpOnReceivedMessage(name, hubConnection, mark).pipe(
					takeUntil(close)
				);
				const state = connectionState.pipe(
					takeUntil(close)
				);
				return {
					onReceived: onReceived,
					state: state,
				} as HubEventListeners;
			}),
			catchError((error) =>
			{
				return of(null);
			})
		);
		return connectionObservable;
	}

	send(type: Symbol, endPoint: string, name: string, data: any): Observable<any>
	{
		const connectionKey = this.getConnectionKey(type, endPoint);
		return this._signalRService.send(connectionKey, name, data);
	}

	invoke(type: Symbol, endPoint: string, name: string, data: any): Observable<any>
	{
		const connectionKey = this.getConnectionKey(type, endPoint);
		return this._signalRService.invoke(connectionKey, name, data);
	}

	close(type: Symbol, endPoint: string): Observable<any>
	{
		const connectionKey = this.getConnectionKey(type, endPoint);
		return this._signalRService.stop(connectionKey);
	}

	closeAllConnections()
	{
		this._signalRService.closeAllConnections();
	}

	public getVehicleEventsGroupName(studentSchedule: StudentSchedule, tripSchedule: TripSchedule): string
	{
		return `${studentSchedule.clientId}_${studentSchedule.dataSourceId}_${tripSchedule.busNumber}`;
	}

	public getRealTimeUpdatesGroupName(studentSchedule: StudentSchedule, tripSchedule: TripSchedule, isPickup: boolean): string
	{
		return `${studentSchedule.clientId}_${studentSchedule.dataSourceId}_${tripSchedule.id}_${isPickup ? tripSchedule.pickUpStopId : tripSchedule.dropOffStopId}`;
	}

	public getVehicleEventsEndPoint(studentSchedule: StudentSchedule, tripSchedule: TripSchedule): string
	{
		return `/VehicleEventHub?stopfinderadminversion=2.0&groupName=${encodeURIComponent(this.getVehicleEventsGroupName(studentSchedule, tripSchedule))}`;
	}

	public getScannedEventsEndPoint(subscriberId: number, deviceIdentifier: string): string
	{
		return `/ScannedHub?stopfinderadminversion=2.0&subscriberId=${subscriberId}&deviceIdentifier=${deviceIdentifier}`;
	}

	public getRealTimeUpdatesEndPoint(): string
	{
		return `/RealTimeUpdateHub?stopfinderadminversion=2.0`;
	}

	private getConnectionKey(type: Symbol, endPoint: string)
	{
		return `${type.toString()}_${endPoint}`;
	}

	public getName(type: Symbol): string
	{
		switch (type)
		{
			case VEHICLE_SIGNALR_KEY:
				return `ReceiveVehicleEvents`;
			case SCANNED_SIGNALR_KEY:
				return `ScannedRecordEvents`;
			case REAL_TIME_UPDATES_KEY:
				return `RealTimeUpdates`;
		}
	}

	public getMark(tripSchedule: TripSchedule)
	{
		return { 'busNumber': tripSchedule.busNumber };
	}

	private setUpOnReceivedMessage(name: string, hubConnection: SignalR.HubConnection, mark?: {})
	{
		const receivedSubject = new Subject();
		hubConnection && hubConnection.on(name, data =>
		{
			receivedSubject.next(mark ? { data, ...mark, } : data);
		});
		return receivedSubject.asObservable();
	}
}
