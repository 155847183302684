
import { PipeTransform, Pipe } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from 'moment';
import { Subscription } from '../stopfinder/stopfinder-models';

@Pipe({ name: 'effectiveDating' })
export class EffectDatingPipe implements PipeTransform
{
  constructor(private translate: TranslateService,) { }
  transform(sub: Subscription)
  {
    const start = sub.effectiveStart;
    const end = sub.effectiveEnd;
    if (start && !end)
    {
      return `${this.translate.instant("system.pipe.effective.dating.starting")} ${moment(start).format('M/D/YYYY')}`;
    }
    else if (!start && end)
    {
      return `${this.translate.instant("system.pipe.effective.dating.until")} ${moment(end).format('M/D/YYYY')}`;
    }
    else if (start && end)
    {
      return ` ${moment(start).format('M/D/YYYY')} - ${moment(end).format('M/D/YYYY')}`;
    }
    else
    {
      return `${this.translate.instant("system.pipe.effective.dating.always")}`;
    }
  }
}
