export interface IGeoData
{
  id?: number;
  subscriberId?: number;
  riderId?: number;
  name?: string;
  xcoord?: number;
  ycoord?: number;
  distance?: number;
  enterAlert?: boolean;
  exitAlert?: boolean;
  startTime?: string;
  endTime?: string;
}

export interface IGeoInfo extends IGeoData
{
  clientId?: number;
  tripId?: number;
  subscriberId?: number;
}

export enum TimeRangeEnum
{
  start = 'start',
  end = 'end',
}
