export const FEET_MIN = 250;
export const FEET_MAX = 1500;

export const METERS_MIN = 75;
export const METERS_MAX = 500;

export const FEET_METERS_EXCHANGE_RATE = 0.3048;
export const INIT_ZOOM_LEVEL = 15000;
export const SCHENECTADY_MAP_CENTER_X = -73.9436027;
export const SCHENECTADY_MAP_CENTER_Y = 42.812111;
export const MAP_PADDING_PIXEL = 60;
export const X_MAP_PADDING_PIXEL = 80;
export const HIDE_BUS_ICON_INTERVAL: number = 300000; // 5mins
export const HIDE_ETA_INTERVAL: number = 300000; // 5mins

/**
 * radius of the earth
 * NOTE: please do not alter the earth's radius, otherwise calculation will no longer be accurate
 */
export const EARTHS_RADIUS = 6378137;

export const VEHICLE_SIGNALR_KEY: unique symbol = Symbol("vehicle_signalR_hub");
export const SCANNED_SIGNALR_KEY: unique symbol = Symbol("scanned_signalR_hub");
export const REAL_TIME_UPDATES_KEY: unique symbol = Symbol("realtimeupdates_signalR_hub");

export const LOCAL_LAST_TOUR_INFO_KEY: string = "last_tour_info";

export const MAX_ATTACHMENT_AMOUNT: number = 5;
export const MAX_SINGLE_ATTACHMENT_SIZE: number = 20 * 100000;
export const ALLOW_ATTACHMENT_FILE_TYPE: string[] = [
  "image/*",
  ".png",
  ".jpg",
  ".jpeg",
  ".gif",
  ".bmp",
  ".svg",
  ".doc",
  ".docx",
  ".pdf",
  ".txt",
  ".rtf",
  ".csv",
  ".xls",
  ".xlsx",
  ".ppt",
  ".pptx"
];

export const DEFAULT_PREVIEW_IMAGE: string = `data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAEJElEQVR4Xu1bSyi9QRT/Xe9HpFtWpIRSWFigbomIUpSFBUqIEBvKQresSB4rzxVCIkRJJGKh2EhElhQlJRIh76sz/678KWY+3/3mK3N2tzvnnDm/OefMfGfOWBwOhwN/mCwKAOUBKgSEc8Dr6ytOTk6wsbGB4+NjqRkkJCQENpsNYWFhcHNzE56LcA64urpCe3s7WlpamDKLxSKsVE8GZwTX1taisbERVqtVSLwQAI+Pj0hKSsLOzo50wz9bSUBER0ezuXl7e3ODIARAfn4+JiYmmPGkMDExEXl5efD39+dWqOfA+/t7zMzMsFAkojllZWVhbm4O7u7uXKq4Abi4uEBoaCgeHh7g4+ODnp4elJaWSvcEMnp8fByVlZW4vb2Fp6cn9vf3ERkZqS8AbW1taGhoYEILCgowNjbGpcCoQTU1Nejr62Pq6uvr0dHRwaWa2wPI7Z2uv7CwwFzNTLS2toaUlBQ2R9oNnp+fuaanCYD19XW29RAdHR0hPT2dS5neg2ZnZxETE8PEUvKLj49/XyTe892vATg4OEBUVJTetnHJ29raYkYrAJQHqBBQOUAlQVm7wOnpKcrKyriytt6Duru7ERERIXcX0NsorfKkhYDWCevNpwCQlQP0Xkmt8pQHKA+Q9DFELnt9fY3Ozk48PT1p9WBuPg8PD9TV1SEgIOA/HqkhYLfb0draym3EbwbSZ25xcTGGhobMAwBVZLu6un5jFzcvAZCRkYGlpSXzAEAhsLm5yYqSRhBVpk0VAkYY/ZMOqTngp8kZ8b8CQJ0DJJ4DFhcXQbdGd3d3Lvd2OgdMT09/KctLDYGKigr09/e73HhSQDtNXFwcdnd3zbMNTk1NoaqqyhAASMnw8DCys7PNAwCtCt0cG3EOoJsfLy+vL3eSUkPAsKX/RpECQG2DErfBl5cX1jhBvQOiRL09elyuSg0B6hug/gEtREltdHQUhYWFWtjfeaQDQI1TWmlwcBAlJSVa2RmfVABubm7YOeDs7EzYiISEBDQ1NWlqcfuoTCoAwla7gEEBoLZBidugCzxaWKQKARUCKgRc2ybn5+cH6s2lT16zN0r6+vqytlke4u4TpFb05uZmJrOoqAgjIyM88g0bQ5czdD1HVF1djd7eXi7d3ADQO4GgoCBWiKC63OTkJHJzc7mUuHrQ8vIycnJy3osyh4eHCA8P51LLDQC5PimZn59nggmE1NRU0FGWKjQyiPqBqVt0dXWVGU+UnJzMftP8eIgbABJ2eXnJGpL39vakt8l/No4WiFp2qY85ODiYx3Y2RggAYqDSd3l5Ocjtzs/P/wmR9GzGWYOkZzJpaWkYGBhAYGAgt/GaAHBKp16AlZUVbG9vCynUe3BsbCwyMzOFnsl8nIOwB+htgGx5CgD1dNaI2wzZfv6NfhUCfz0E3gA8grZ9eq2y4gAAAABJRU5ErkJggg==`;

export const STUDENT_NUMBER_FIELDS: Set<string> = new Set([
  "ActualLoadTime",
  "DistanceFromAMStop",
  "AmrideTimeDeviation",
  "AmwalkToStopDeviation",
  "DistanceFromSchl",
  "DistanceFromResidSch",
  "DistanceFromPmStop",
  "PmrideTimeDeviation",
  "PmwalkToStopDeviation",
  "UserNum1",
  "UserNum2",
  "UserNum3",
  "UserNum4",
  "WalkToSchoolDeviation",
  "WalkToSchoolPolicy",
  "WalkToStopPolicy",
  "IntGratNum1",
  "IntGratNum2",
]);

export const STUDENT_INTEGER_FIELDS: Set<string> = new Set([
  "Age",
  "LockIdAm",
  "AmRideTime",
  "CalculatedLoadTime",
  "GeoConfidence",
  "LockIdPm",
  "PmRideTime",
  "Sifchanged",
  "DlyDoSite",
  "DlyDoTranTripId",
  "DlyDoTranTripStop",
  "DlyDoTripId",
  "DlyDoTripStop",
  "DlyPuSite",
  "DlyPuTranTripId",
  "DlyPuTranTripStop",
  "DlyPuTripId",
  "DlyPuTripStop",
  "LoadTime",
  "Id",
  "LastUpdatedId",
  "LastUpdatedType",
  "InvitedSubscribers",
  "ActivatedSubscribers",
]);

export const STUDENT_COORD_FIELDS: Set<string> = new Set([
  "Xcoord",
  "Ycoord",
]);

export const STUDENT_BOOLEAN_FIELDS: Set<string> = new Set([
  "Geo",
]);

export const STUDENT_DATE_FIELDS: Set<string> = new Set([
  "Dob",
  "EntryDate",
  "IntGratDate1",
  "IntGratDate2",
  "LastUpdated",
  "UserDate1",
  "UserDate2",
  "UserDate3",
  "UserDate4",
]);

export const STUDENT_TIME_FIELDS: Set<string> = new Set([
  "AmstopTime",
  "PmstopTime"
]);

export const STUDENT_IMAGE_FIELDS: Set<string> = new Set([
  "Ampmschedule",
  "Ampmtransportation",
  "PolicyDeviation",
]);

export const IMAGE_FILED_FLAG: string = "STUDENT_IMAGE_FILED______";
