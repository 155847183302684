import { ElementRef } from '@angular/core';
import { ITfMapOptions } from '../interfaces/tf-map.options';
import { eBaseMaps } from '../../basemaps/classes/basemap.enum';
import { Coordinates } from 'src/app/map/esri-map/esri-map.interface';
export class TfMapOptions implements ITfMapOptions
{
    /**
     * Center point for startup of the map.
     */
    public center: Coordinates;
    /**
     * Container to draw the map inside of
     */
    public container: ElementRef;
    /**
     * Basemap to use on startup
     */
    public map: eBaseMaps;
    /**
     * Allow rotation on startup
     */
    public rotationEnabled: boolean;
    /**
     * Startup Zoom
     */
    public zoom: number;
    /**
     * Min Zoom (this can be overridden if it falls outside of the basemap's abilities)
     */
    public zoomMin: number;
    /**
     * Max Zoom (this can be overridden if it falls outside of the basemap's abilities)
     */
    public zoomMax: number;

    /**
     * Constructor holds the minimal options for creating a map.
     * @param container Element Ref to draw map (MUST BE A DIV TAG)
     * @param eBasemap Basemap to use on startup
     */
    constructor(container: ElementRef, eBasemap: eBaseMaps, center: Coordinates)
    {
        this.container = container;
        this.map = eBasemap;
        this.center = center;
    }

}
