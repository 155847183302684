import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { IndicatorRef } from './indicator.ref';
import { RefreshSnackBar } from './refresh-snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class IndicatorService
{
  private overlayConfig = {
    position: {
      top: 20,
      right: 0,
    }
  };

  private indicatorReference: IndicatorRef;

  constructor(
    private overlay: Overlay,
  )
  { }

  public show(hasBackdrop: boolean = true)
  {
    if (this.indicatorReference)
    {
      this.indicatorReference && this.indicatorReference.close();
    }

    const positionStrategy = this.getPositionStrategy();
    const overlayRef = this.overlay.create({ positionStrategy, panelClass: 'loading-indicator-overlay', hasBackdrop: hasBackdrop });
    const ref = new IndicatorRef(overlayRef);
    this.indicatorReference = ref;
    const indicatorPortal = new ComponentPortal(RefreshSnackBar, null, null);
    overlayRef.attach(indicatorPortal);
  }

  public close()
  {
    this.indicatorReference && this.indicatorReference.close();
  }

  private getPositionStrategy()
  {
    return this.overlay.position()
      .global()
      .top(`${this.overlayConfig.position.top}px`)
      .right(`${this.overlayConfig.position.right}px`);
  }
}
