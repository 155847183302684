import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from 'src/app/components/service/device/device.service';
import { environment } from 'src/environments/environment';

export type Orientation = 'horizontal' | 'vertical';

@Component({
  selector: 'app-help',
  templateUrl: './app-help.component.html',
  styleUrls: ['./app-help.component.scss']
})
export class AppHelpComponent implements OnInit
{
  public version: string;
  public uuid: string;
  public isUuidShow: boolean = false;

  @Input() orientation: Orientation;

  constructor(private readonly translate: TranslateService, deviceService: DeviceService)
  {
    this.version = environment.version;
    this.uuid = deviceService.deviceIdentifier;
  }

  ngOnInit() { }
}
