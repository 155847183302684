import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sf-map-header',
  templateUrl: './map-header.component.html',
  styleUrls: ['./map-header.component.scss']
})
export class MapHeaderComponent implements OnInit {

  @Input() public title;
  @Input() public left;
  @Input() public right;
  @Input() public leftIsDisabled = false;
  @Input() public rightIsDisabled = false;
  @Output() public leftButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() public centerButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() public rightButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  constructor() {
   }

  ngOnInit() {
  }

}
