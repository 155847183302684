export class EsriGeometryHelper
{
  public static toPointGeometryJSON = (longitude: number, latitude: number) =>
  {
    return {
      spatialReference: {
        wkid: 4326
      },
      x: longitude,
      y: latitude
    };
  }

  public static toPolylineGeometryJSON = (paths: number[][][], WKID: number = 4326) =>
  {
    return {
      spatialReference: {
        wkid: WKID
      },
      paths: paths
    }
  }
}
