import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {

  readonly keyPrefix = 'stopfinder_';

  constructor() { }

  public set(key: string, value: any): void {
    localStorage.setItem(`${this.keyPrefix}${key}`, JSON.stringify(value));
  }

  public get(key: string, defaultValue: any = null): any {
    const value: string = localStorage.getItem(`${this.keyPrefix}${key}`);
    if (value) {
      try {
        return JSON.parse(value);
      } catch {
        return value;
      }
    }
    return defaultValue;
  }

  public remove(key: string): void {
    localStorage.removeItem(`${this.keyPrefix}${key}`);
  }

}
