import { Component, AfterViewInit, EventEmitter, Input, Output } from '@angular/core';
import { state, trigger, style, transition, animate } from '@angular/animations';
import { DomSanitizer } from '@angular/platform-browser';
import { eBaseMaps } from '../../../tf-map/basemaps/classes/basemap.enum';
import { BaseMapsFactory } from '../../../tf-map/basemaps/basemaps.factory';
import { IBasemapThumbnail } from '../../../tf-map/basemaps/classes/basemap.thumbnail';
import { TfMap } from '../../../tf-map/core/classes/tf-map.class';
import { TfMapFactory } from '../../../tf-map/core/tf-map.factory';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sf-basemap',
  templateUrl: './basemap.component.html',
  styleUrls: ['./basemap.component.scss'],
  animations: [
    trigger('overlay', [
      state('visible', style({
        'z-index': 1000,
        opacity: 0.7,
        transform: 'translateY(0)'
      })),
      state('hidden', style({
        opacity: 0,
        'z-index': -1,
        transform: 'translateY(100%)'
      })),
      transition('hidden => visible', [
        animate(1, style({ transform: 'translateY(0)', opacity: 0 })),
        animate('299ms ease-in', style({ opacity: 0.7 }))
      ]),
      transition('visible => hidden', [
        animate('200ms ease-out', style({ opacity: 0 })),
        animate(1, style({ transform: 'translateY(100%)' }))
      ])
    ])
  ]
})
export class BasemapComponent implements AfterViewInit
{
  @Input() show = false;
  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input('showOverlay')
  public showOverlay = false;

  public basemapName: string;
  public basemaps: IBasemapThumbnail[] = null;

  constructor(
    public sanitizer: DomSanitizer,
    public translate: TranslateService,
  )
  {
    this.basemapName = eBaseMaps.Streets;
    this.basemaps = BaseMapsFactory.getAllThumbnails();
  }

  ngAfterViewInit()
  {
    this.basemapName = eBaseMaps.Streets;
  }

  detectWebGL(): boolean
  {
    // Create canvas element. The canvas is not added to the
    // document itself, so it is never displayed in the
    // browser window.
    const canvas = document.createElement('canvas');
    // Get WebGLRenderingContext from canvas element.
    const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webg');
    return gl && gl instanceof WebGLRenderingContext;
  }

  select(basemap: IBasemapThumbnail)
  {
    this.selectEvent.emit();
    this.basemapName = basemap.type;
    // Can move this functionality to a service like in Wayfinder. can be added later.
    TfMapFactory._awaitGet('sfMap').then((map: TfMap) =>
    {
      map.setBaseMap(basemap.type);
    });
  }
}
