export class CircularQueue
{
  private size: number = 99;
  private head: number = -1;
  private tail: number = -1;
  private queueList = [];

  constructor(size)
  {
    this.size = size;
  }

  public front()
  {
    return this.head === -1 ? -1 : this.queueList[this.head];
  }

  public rear()
  {
    return this.tail === -1 ? -1 : this.queueList[this.tail];
  }

  public create(size)
  {
    return new CircularQueue(size);
  }

  public clear()
  {
    this.queueList = [];
    this.head = -1;
    this.tail = -1;
  }

  public enQueue(value)
  {
    if (this.isFull())
    {
      return false;
    }
    if (this.isEmpty())
    {
      this.head = 0;
    }
    this.tail = (this.tail + 1) % this.size;
    this.queueList[this.tail] = value;
    return true;
  }

  public deQueue()
  {
    if (!this.isEmpty())
    {
      if (this.tail === this.head)
      {
        this.tail = -1;
        this.head = -1;
      } else
      {
        this.head = (this.head + 1) % this.size;
      }
      return true;
    }
    return false;
  }

  private isEmpty()
  {
    return this.tail === -1 && this.head === -1;
  }

  private isFull()
  {
    return (this.tail + 1) % this.size === this.head;
  }
}
