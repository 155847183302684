import { OnInit, OnDestroy, Component, Input } from '@angular/core';
import { StateService } from '../../components/service/state/state.service';

@Component({
  selector: 'sf-message-control',
  templateUrl: './message-control.component.html',
  styleUrls: ['./message-control.component.scss']
})
export class MessageControlComponent implements OnInit, OnDestroy {
  @Input() public unreadMessge: number;
  public showState = false;
  constructor(
    private stateService: StateService,
  ) {}

  ngOnInit() {
    if (this.unreadMessge > 0) {
      this.showState = true;
    } else {
      this.unreadMessge = null;
    }
  }

  loadMessages() {
    this.stateService.goRoute('messages');
  }

  ngOnDestroy() {}
}
