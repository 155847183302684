import { Component, OnInit } from '@angular/core';
import { DeviceService } from 'src/app/components/service/device/device.service';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-downloadapp',
  templateUrl: './downloadapp.component.html',
  styleUrls: ['./downloadapp.component.scss']
})
export class DownloadappComponent implements OnInit
{

  constructor(
    public app: AppService,
    public readonly deviceService: DeviceService,) { }

  ngOnInit()
  {
  }

}
