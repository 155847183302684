export enum eBaseMaps
{
  DarkGrey = 'dark-grey',
  LightGrey = 'light-grey',
  OpenStreets = 'open-streets',
  Streets = 'streets',
  StreetsNight = 'streets-night',
  ImageryWithLabels = 'imagery-with-labels',
}

export enum BaseMapThumbnailEnum
{
  DarkGrey = 'map.basemap.thumbnail.dark.grey',
  LightGrey = 'map.basemap.thumbnail.light.grey',
  OpenStreets = 'map.basemap.thumbnail.open.streets',
  Streets = 'map.basemap.thumbnail.streets',
  StreetsNight = 'map.basemap.thumbnail.streets.night',
  ImageryWithLabels = 'map.basemap.thumbnail.imagery.with.labels',
}
