import * as _ from 'lodash';
import
{
  Component,
  OnDestroy,
  OnChanges,
  SimpleChange,
  Input,
  OnInit,
} from "@angular/core";
import { AppService } from 'src/app/app.service';
import { MapService } from 'src/app/components/service/map/map.service';
import { AndroidBackService } from 'src/app/androidBack.service';
import { IndicatorService } from '../../shared/refresh/indicator.service';
import
{
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { TargetedBlockingScrollStrategy } from 'src/app/shared/material/targeted-blocking-scroll-strategy';
import { StopfinderApiService } from 'src/app/shared/stopfinder/stopfinder-api.service';
import { ConfirmationDialogComponent } from 'src/app/shared/layout/confirmation-dialog/confirmation-dialog.component';

import { GeoActionSheetComponent } from './action-sheet/action.component';
import { AddGeoBottomSheetComponent } from './action-sheet/actions/actions.component';
import { TooltipService } from '../../components/service/tooltip/tooltip.service';
import { IGeoData } from "./action-sheet/actions/geo.interface";
import { MapGeoAlertService } from 'src/app/components/service/map/map-geoalert.service';
import { MapVehicleService } from 'src/app/components/service/map/map-vehicle.service';
import { DeviceService } from 'src/app/components/service/device/device.service';
import { MapCoreService } from 'src/app/components/service/map/map-core.service';

@Component({
  selector: 'app-geo',
  templateUrl: './geo.component.html',
  styleUrls: ['./geo.component.scss']
})
export class GeoComponent implements OnDestroy, OnChanges, OnInit
{
  private readonly defaultActionLabel: string = 'Yes';
  private readonly defaultSecondaryLabel: string = 'No';
  private readonly geoalertNotEnableModalId = 'geoalertNotEnabled';
  private isBottomSheetOpened = false;
  private dialogRef: MatDialogRef<ConfirmationDialogComponent, any> = null;

  @Input() backButtonHook: boolean;
  @Input() tooltipListener: any;  // should declare interface for this input

  constructor(
    public mapService: MapService,
    private tooltipService: TooltipService,
    private readonly apiService: StopfinderApiService,
    private readonly appService: AppService,
    private readonly androidService: AndroidBackService,
    private readonly deviceService: DeviceService,
    private readonly dialog: MatDialog,
    private readonly geoAlertService: MapGeoAlertService,
    private readonly loadingIndicator: IndicatorService,
    private readonly mapCoreService: MapCoreService,
    private readonly translate: TranslateService,
    private readonly vehicleService: MapVehicleService,
  )
  { }

  ngOnInit()
  {
    this.geoAlertService.onUIEditMode && this.androidService.onShouldCheckCallback(() =>
    {
      // should close bottom sheet once click Android back button
      if (this.isBottomSheetOpened)
      {
        this.geoAlertService.bottomSheet.dismiss();
      }

      // should do nothing since typing new name is the mandatory part in whole process
      if (this.dialog.openDialogs.length)
      {
        this.androidService.disableAndroidBack = true;
        return;
      }

      if (this.mapService.isGeoAlertEditing)
      {
        this.cancel();
      }
    });

    this.tooltipService.tooltipCallback = this.showEditSheet.bind(this);
  }

  ngOnDestroy()
  {
    this.tooltipService.tooltipCallback = null;
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange })
  {
    if (
      !_.isUndefined(changes.backButtonHook.previousValue) &&
      !_.isEqual(changes.backButtonHook.currentValue, changes.backButtonHook.previousValue)
    )
    {
      this.cancel();
    }
  }

  public showActionSheet()
  {
    if (this.tooltipService.tooltip && this.tooltipService.getTooltipStatus())
    {
      this.tooltipService.close();
    }

    if (this.geoAlertService.disable)
    {
      var dialog = this.dialog.getDialogById(this.geoalertNotEnableModalId);
      this.androidService.onShouldCheckCallback(() =>
      {
        if (dialog)
        {
          dialog.close();
          return;
        }
      });
      if (dialog)
      {
        dialog.close();
      }
      else
      {
        dialog = this.dialog
          .open(ConfirmationDialogComponent, {
            disableClose: false,
            data: {
              title: this.translate.instant("geo.sheet.modal.disable.title"),
              message: this.translate.instant("geo.sheet.modal.disable.body"),
              action: this.translate.instant("geo.sheet.modal.disable.ok"),
              secondary: false,
            },
            scrollStrategy: new TargetedBlockingScrollStrategy(),
            panelClass: "confirm-dialog",
            id: this.geoalertNotEnableModalId
          });
        dialog.beforeClosed()
          .subscribe(() =>
          {
            this.androidService.onDestroyCallback();
            dialog = null;
          });
      }
    }
    else
    {
      this.geoAlertService.bottomSheet.open(GeoActionSheetComponent, {
        data: {
          geoData: this.mapService.geoData,
        },
        scrollStrategy: new TargetedBlockingScrollStrategy()
      });

      this.isBottomSheetOpened = true;
    }
  }

  public showEditSheet()
  {
    this.tooltipService.tooltip && this.tooltipService.tooltip.close().then((closeState: boolean) =>
    {
      closeState && this.geoAlertService.bottomSheet.open(AddGeoBottomSheetComponent, {
        data: {
          geoData: this.mapService.geoData,
          isEditMode: true,
        },
        panelClass: 'geo-configuration',
        hasBackdrop: false,
      }).afterOpened().subscribe(() =>
      {
        const resetMapSize = () =>
        {
          const topPadding = document.querySelector(".geo-header").clientHeight;
          const bottomPadding = document.querySelector(".mat-bottom-sheet-container").clientHeight;
          this.mapService.setViewSize(
            0,
            topPadding,
            this.appService.windowWidth,
            this.appService.windowHeight - topPadding - bottomPadding);
        }
        this.deviceService.isiOS && resetMapSize();
        // should checked plugin status and then popover actions sheet
        this.mapService.isMapPluginReady() &&
          this.mapService.turnOnGeoMode(this.mapService.geoData.id, () =>
          {
            // callback to handle rest of the logic
            return this.mapService.geoData;
          }, this.deviceService.isAndroid ? resetMapSize : null);
      });

      this.mapService.turnOnGeoAddMode();

      this.isBottomSheetOpened = true;
    });
  }

  public cancel()
  {
    this.beforeCancelAndSave(
      this.translate.instant("geo.sheet.modal.cancel.title"),
      this.translate.instant("geo.sheet.modal.cancel.body"),
      this.translate.instant("geo.sheet.modal.cancel.yes"),
      this.translate.instant("geo.sheet.modal.cancel.no"),
      () =>
      {
        this.geoAlertService.turnOffBottomSheet();
        this.mapService.turnOffGeoAddMode();

        this.mapService.resetViewSize(this.appService.windowWidth, this.appService.windowHeight);
        this.mapService.fetchAndZoomToGeoAlerts(this.mapService.geoData.riderId, true, true);

        this.androidService.onDestroyCallback();
      }
    );
  }

  public save()
  {
    const currentAlert = this.mapService.geoData;
    this.loadingIndicator.show();
    this.mapService.setGeoAlertCenter(currentAlert.id).then(() =>
    {
      this.calculateOverlapAsync(currentAlert).then((isOverlapped: boolean) =>
      {
        this.saveConfirmation(isOverlapped, currentAlert);
      });
    });
  }

  private calculateOverlapAsync(currentAlert: IGeoData): Promise<boolean>
  {
    return new Promise<boolean>((resolve) =>
    {
      this.apiService.getAllGeoAlerts(currentAlert.riderId).toPromise().then((alerts: IGeoData[]) =>
      {
        let isOverlapped = false;
        for (let i = 0, count = alerts.length; i < count; i++)
        {
          const geoAlert = alerts[i];
          if (this.excludeCurrentGeoAlert(geoAlert, currentAlert) &&
            this.isOverlapOtherGeoAlert(geoAlert, currentAlert))
          {
            isOverlapped = true;
            break;
          }
        }

        resolve(isOverlapped);
      });
    });
  }

  private excludeCurrentGeoAlert(base: IGeoData, current: IGeoData): boolean
  {
    return !this.isIdenticalGeoAlert(base, current);
  }

  private isIdenticalGeoAlert(base: IGeoData, target: IGeoData): boolean
  {
    const epsilon = 0.01;
    return (base.id === target.id) ||
      (Math.abs(base.xcoord - target.xcoord) < epsilon &&
        Math.abs(base.ycoord - target.ycoord) < epsilon &&
        Math.abs(base.distance - target.distance) < epsilon &&
        base.name === target.name);
  }

  private isOverlapOtherGeoAlert(base: IGeoData, target: IGeoData): boolean
  {
    const distance = this.mapCoreService.haversine_distance(base.xcoord, base.ycoord, target.xcoord, target.ycoord);
    const geoAlertDistance = base.distance + target.distance;
    return distance < geoAlertDistance;
  }

  private enableSaveButton(): void
  {
    const button = this.getRightButton();
    const wrapper: HTMLElement = this.getBottomSheetWrapper();
    button.classList.remove('processing');
    wrapper.classList.remove('processing');
  }

  private getRightButton(): HTMLElement
  {
    const button = document.querySelectorAll(".geo-header .right")[0];
    return (button as HTMLElement);
  }

  private getBottomSheetWrapper(): HTMLElement
  {
    const wrapper = document.querySelectorAll(".geo-configuration-wrapper")[0];
    return (wrapper as HTMLElement);
  }

  private saveConfirmation(failToSave: boolean, currentAlert?: IGeoData)
  {
    // should prompt dialog when overlap
    failToSave && this.beforeCancelAndSave(
      this.translate.instant("geo.add.modal.overlap.title"),
      this.translate.instant("geo.add.modal.overlap.body"),
      this.translate.instant("geo.add.modal.overlap.ok"),
      '',
      () =>
      {
        this.enableSaveButton();
      }
    );

    // should prompt dialog when enter and exist all set to false
    !failToSave && !currentAlert.enterAlert && !currentAlert.exitAlert && this.beforeCancelAndSave(
      this.translate.instant("geo.add.modal.not.receive.title"),
      this.translate.instant("geo.add.modal.not.receive.body", { name: currentAlert.name }),
      this.translate.instant("geo.add.modal.not.receive.yes"),
      this.translate.instant("geo.add.modal.not.receive.no"),
      () =>
      {
        this.saveGeoAlerts(currentAlert);
      }
    );

    // should directly save data when all conditions set correctly
    !failToSave && (currentAlert.enterAlert || currentAlert.exitAlert) && this.saveGeoAlerts(currentAlert);
  }

  private saveGeoAlerts(currentAlert: IGeoData)
  {
    // if id is -1, it is a new geo alert, need to delete the id to save the entity.
    if (currentAlert.id === -1)
    {
      delete currentAlert.id;
    }
    this.loadingIndicator.show();
    this.apiService.saveGeoData(currentAlert).toPromise().then(() =>
    {
      this.geoAlertService.turnOffBottomSheet();

      this.vehicleService.clearCenterToBus();
      this.mapService.resetViewSize(this.appService.windowWidth, this.appService.windowHeight);
      this.mapService.fetchAndZoomToGeoAlerts(currentAlert.riderId, true, true);
      this.androidService.onDestroyCallback();

      this.loadingIndicator.close();
      this.enableSaveButton();
    }).catch(() =>
    {
      this.loadingIndicator.close();
    });
  }

  private beforeCancelAndSave(
    type: string,
    msg: string,
    actionLabel?: string,
    secondaryLabel?: string,
    callback?: Function,
  )
  {
    const dialogConfig: MatDialogConfig = {
      // set to true to disable overlay clickable to close the dialog
      disableClose: true,
      data: {
        title: type,
        message: msg,
        action: actionLabel ? actionLabel : this.defaultActionLabel,
        secondary: _.isEmpty(secondaryLabel) ? false : true,
        secondaryAction: secondaryLabel ? secondaryLabel : this.defaultSecondaryLabel,
      },
      scrollStrategy: new TargetedBlockingScrollStrategy(),
      panelClass: 'confirm-dialog'
    };

    if (!this.dialogRef)
    {
      this.dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    }

    this.dialogRef.beforeClosed().subscribe((confirm: boolean) =>
    {
      this.loadingIndicator.close();
      if (confirm)
      {
        callback && callback();
      }
      this.dialogRef = null;
      this.isBottomSheetOpened = false;
    });
  }
}
