export interface AppRating {
  id?: number;
  subscriberId?: number;
  lastPromptedOn?: string;
  lastResponse: number;
}

export enum RatingResponseType {
  No = 1,
  Yes = 2,
  AskMeLate = 3,
}
