import { Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../../../app.service';
import { MessagesService } from '../../../messages/messages.service';
import { MatBottomSheet } from '@angular/material';
import { SettingsBottomSheetComponent } from 'src/app/app.component';
import { TargetedBlockingScrollStrategy } from '../../material/targeted-blocking-scroll-strategy';
import { StateService } from '../../../components/service/state/state.service';

@Component({
  selector: 'sf-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  shouldAnime: boolean;

  constructor(
    public messagesService: MessagesService,
    public router: Router,
    public route: ActivatedRoute,
    public appService: AppService,
    public bottomSheet: MatBottomSheet,
    private stateService: StateService,
  ) { }

  ngOnInit() {

    if (this.appService.shouldAnime) {
      this.shouldAnime = true;
      this.appService.shouldAnime = false;
    } else {
      this.shouldAnime = false;
    }
  }

  gotoMessages() {
    this.messagesService.clearLastScrollLocation();
    this.messagesService.searchCriteria = '';
    this.stateService.goRoute('messages');
  }

  logout() {
    this.appService.confirmLogout();
  }

  showSettingsBottomSheet() {
    this.bottomSheet.open(SettingsBottomSheetComponent, { scrollStrategy: new TargetedBlockingScrollStrategy() });
  }

}
