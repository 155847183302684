import * as _ from 'lodash';
import
{
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { IQuestionData, ReplyQuestionStatusEnum, IQuestionResult, IQuestionPickListOption, FormQuestionEnum } from '../form.interface';
import { MatButtonToggleChange, MatCheckboxChange, MatRadioChange, MatDialog, } from '@angular/material';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { SignaturePadDialogComponent } from 'src/app/shared/signature-pad/signature-pad.component';
import { Document } from '../../shared/stopfinder/stopfinder-models';
import { ALLOW_ATTACHMENT_FILE_TYPE, MAX_ATTACHMENT_AMOUNT, MAX_SINGLE_ATTACHMENT_SIZE } from 'src/app/shared/utils/constant';
import { generatePreviewImage, isImageSystemFiled, setImageSystemFiledClass, formatSystemField, isValidPhoneNumber } from 'src/app/shared/utils/utils';

@Component({
  styleUrls: ['form.questions.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: ``
})
export class BaseQuestionComponent implements OnInit, OnDestroy
{
  @Input() data: IQuestionData;
  @Input() isAllQuestionsLoaded: boolean;
  @Input() cacheResult: IQuestionResult;
  @Input() questionStatus: Observable<ReplyQuestionStatusEnum>;
  @Input() questionValidation: BehaviorSubject<any>;

  @Input() questionAnswer: BehaviorSubject<IQuestionResult>;
  @Input() translateObservable: Observable<any>;

  public REQUIRED_MSG = ReplyQuestionStatusEnum.required;
  public ERROR_MSG = ReplyQuestionStatusEnum.error;
  public WARNING_MSG = ReplyQuestionStatusEnum.warning;
  public SUCCESS_MSG = ReplyQuestionStatusEnum.success;
  public INVALID_MSG = ReplyQuestionStatusEnum.invalid;
  public INVALID_ANSWER_MSG = ReplyQuestionStatusEnum.invalid_answer;

  private _questionResult: IQuestionResult;

  constructor() { }

  ngOnInit() { }

  ngOnDestroy() { }

  get questionResult()
  {
    return this._questionResult || {
      questionId: this.data.Id,
      typeId: this.data.TypeId,
      value: '',
      other: null,
    };
  }

  set questionResult(answer: any)
  {
    if (this.questionResult.typeId === FormQuestionEnum.attachment)
    {
      this._questionResult = { ...this.questionResult, ...answer };
    } else
    {
      this._questionResult = _.merge(this.questionResult, answer);
    }
  }

  public questionStatusUpdate(callback?: Function): Subscription
  {
    return this.questionStatus.subscribe((status: ReplyQuestionStatusEnum) =>
    {
      switch (status)
      {
        case ReplyQuestionStatusEnum.validate:
        case ReplyQuestionStatusEnum.submit:
          return callback && callback(status);
        default:
          return;
      }
    });
  }

  public isEmptyValidation(value: any): boolean
  {
    return _.isEmpty(`${value}`) || _.isNull(value) || _.isUndefined(value);
  };

  public resetDefaultStatus(status: ReplyQuestionStatusEnum)
  {
    if (_.isEqual(status, ReplyQuestionStatusEnum.next) || _.isEqual(status, ReplyQuestionStatusEnum.validate))
    {
      this.questionValidation.next(!this.data.Required);
    }
  }

  public setQuestionValidMark(isRequiredQuestion: boolean, isQuestionEmpty: boolean)
  {
    if (this.isAllQuestionsLoaded)
    {
      isRequiredQuestion && this.questionValidation.next({
        questionId: `${this.data.Id}`,
        valid: !isQuestionEmpty
      });
    } else
    {
      isRequiredQuestion && this.questionValidation.next(!isQuestionEmpty);
    }
  }

  public cacheAnswer = () => this.questionAnswer.next(this.questionResult);

  public clearContent(e: Event, control: AbstractControl)
  {
    e.stopPropagation();
    const suppressEventOpts = { emitEvent: false };
    control.setValue('', suppressEventOpts);

    this.clearContentCallback();
  }

  public clearContentCallback() { }

  public setTranslateText()
  {
    this.translateObservable.subscribe((formText) =>
    {
      this.REQUIRED_MSG = formText["question.answer.is.required"];
      this.INVALID_MSG = formText["question.invalid.number"];
      this.INVALID_ANSWER_MSG = formText["question.invalid.answer"];
    });
  }
}

@Component({
  selector: `sf-base-question`,
  styleUrls: ['form.questions.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <div
      class="question-wrapper"
      [innerHTML]="data.QuestionContent | safeHtml"
    ></div>
  `
})
export class QuestionHeaderComponent implements OnInit, OnDestroy
{
  @Input() data: IQuestionData;

  ngOnInit() { }

  ngOnDestroy() { }
}

/**
 * QuestionType = 1
 * Boolean Type
 */
@Component({
  styleUrls: ['form.questions.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <section>
      <sf-base-question [data]="booleanQuestionData"></sf-base-question>
      <div class="question-type-single-list">
        <mat-radio-group
          class="flex-column"
          (change)="singleSelectChange($event)"
        >
          <mat-radio-button
            [value]="true"
            [checked]="isAnswerSelected(true)"
          >
            {{booleanQuestionData.TrueDisplayName || 'True'}}
          </mat-radio-button>
          <mat-radio-button
            [value]="false"
            [checked]="isAnswerSelected(false)"
          >
            {{booleanQuestionData.FalseDisplayName || 'False'}}
          </mat-radio-button>
        </mat-radio-group>
        <mat-error
          class="form-question-error"
          *ngIf="booleanQuestionData.Required && isEmpty"
        >{{ REQUIRED_MSG }}</mat-error>
      </div>
    </section>
  `
})
export class BooleanQuestionComponent extends BaseQuestionComponent implements OnInit, OnDestroy
{
  public booleanQuestionData: IQuestionData;
  public isEmpty: boolean = false;
  public booleanStatusSubscription: Subscription;

  private booleanQuestionAnswer = null;

  constructor()
  {
    super();
  }

  ngOnInit()
  {
    this.setTranslateText();
    this.preInit();

    this.booleanStatusSubscription = this.questionStatusUpdate((status: ReplyQuestionStatusEnum) =>
    {
      this.resetDefaultStatus(status);
      this.validation();
    });

    this.cacheAnswer();
  }

  ngOnDestroy()
  {
    this.booleanStatusSubscription && this.booleanStatusSubscription.unsubscribe();
  }

  private preInit()
  {
    // pre-initial default data
    this.booleanQuestionData = _.merge(this.data, {});

    if (this.cacheResult)
    {
      this.booleanQuestionData = _.merge(this.booleanQuestionData, {
        defaultVal: this.cacheResult.value
      });

      this.booleanQuestionAnswer = this.cacheResult.value;
      this.cacheQuestionResult();
    }
  }

  public singleSelectChange(event: MatRadioChange)
  {
    this.booleanQuestionAnswer = event.value;
    this.validation();

    this.cacheQuestionResult();
  }

  private validation()
  {
    this.isEmpty = this.data.Required && this.isEmptyValidation(this.booleanQuestionAnswer);
    this.questionValid();
  }

  private cacheQuestionResult()
  {
    this.questionResult = { value: this.booleanQuestionAnswer };
    this.cacheAnswer();
  }

  private questionValid = () => this.setQuestionValidMark(this.data.Required, this.isEmpty);

  public isAnswerSelected = (bool: boolean) => _.isEqual(this.booleanQuestionData.defaultVal, bool);
}


/**
 * QuestionType = 2
 * Date Type
 */
@Component({
  styleUrls: ['form.questions.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <section>
      <sf-base-question [data]="dateQuestionData"></sf-base-question>
      <div class="question-type-date">
        <form [formGroup]="dateForm">
          <mat-form-field class="form-field-date">
            <input
              matInput
              type="date"
              formControlName="dateInput"
              autocomplete="off"
              min="dateQuestionData.min"
              max="dateQuestionData.max"
              [value]="dateQuestionData.defaultVal"
            >
            <div class="icon date"></div>
            <div class="clear-date"
              [hidden]='!dateForm.controls["dateInput"].value'
              (click)='clearContent($event, dateForm.controls["dateInput"])'
            >
              <img
                src="assets/images/clear.svg"
                alt="clear"
              />
            </div>
          </mat-form-field>
          <mat-error
            class="form-question-error"
            *ngIf="dateQuestionData.Required && isEmpty"
          >{{ REQUIRED_MSG }}</mat-error>
        </form>
      </div>
    </section>
  `
})
export class DateQuestionComponent extends BaseQuestionComponent implements OnInit, OnDestroy
{
  public dateQuestionData: IQuestionData;
  public dateForm: FormGroup;
  public isEmpty: boolean = false;
  public dateStatusSubscription: Subscription;

  private dateQuestionAnswer = null;

  constructor()
  {
    super();
  }

  ngOnInit()
  {
    this.setTranslateText();
    this.preInit();

    this.dateForm = new FormGroup({
      dateInput: new FormControl({
        value: this.dateQuestionData.defaultVal,
        disabled: this.dateQuestionData.disable
      }, []),
    });

    this.dateForm.get('dateInput').valueChanges.subscribe((newValue: string) => this.updateDateQuestion(newValue));

    this.dateStatusSubscription = this.questionStatusUpdate((status: ReplyQuestionStatusEnum) =>
    {
      this.resetDefaultStatus(status);
      this.validation();
    });

    this.cacheAnswer();
  }

  ngOnDestroy()
  {
    this.dateStatusSubscription && this.dateStatusSubscription.unsubscribe();
  }

  private preInit()
  {
    this.dateQuestionData = _.merge(this.data, {
    });

    if (this.cacheResult)
    {
      this.dateQuestionData = _.merge(this.dateQuestionData, {
        defaultVal: this.cacheResult.value
      });

      this.dateQuestionAnswer = this.cacheResult.value;
      this.cacheQuestionResult();
    }
  }

  public validation()
  {
    this.updateDateQuestion(this.dateForm.get('dateInput').value);
  }

  private updateDateQuestion(date: string)
  {
    this.dateQuestionAnswer = date;
    this.isEmpty = this.data.Required && this.isEmptyValidation(this.dateQuestionAnswer);

    this.questionValid();
    this.cacheQuestionResult();
  }

  private cacheQuestionResult()
  {
    this.questionResult = { value: this.dateQuestionAnswer };
    this.cacheAnswer();
  }

  private questionValid = () => this.setQuestionValidMark(this.data.Required, this.isEmpty);

  // overwrite base component
  public clearContentCallback()
  {
    this.validation();
    this.questionValid();
    this.cacheAnswer();
  }
}


/**
 * QuestionType = 3
 * DateTime Type
 */
@Component({
  styleUrls: ['form.questions.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <section>
      <sf-base-question [data]="dateTimeDateQuestionData"></sf-base-question>
      <div class="question-type-datetime">
        <div class="type-date">
          <form [formGroup]="dateTimeDateForm">
            <mat-form-field class="form-field-date-time">
              <input
                matInput
                type="date"
                formControlName="dateTimeDateInput"
                autocomplete="off"
                min="dateTimeDateQuestionData.min"
                max="dateTimeDateQuestionData.max"
                [value]="defaultDateVal"
              >
              <div class="icon date"></div>
              <div class="clear-date"
                [hidden]='!dateTimeDateForm.controls["dateTimeDateInput"].value'
                (click)='clearContent($event, dateTimeDateForm.controls["dateTimeDateInput"])'
              >
                <img
                  src="assets/images/clear.svg"
                  alt="clear"
                />
              </div>
            </mat-form-field>
          </form>
        </div>
        <div class="type-time">
          <form [formGroup]="dateTimeTimeForm">
            <mat-form-field class="form-field-date-time">
              <input
                matInput
                type="time"
                formControlName="dateTimeTimeInput"
                autocomplete="off"
                min="dateTimeTimeQuestionData.min"
                max="dateTimeTimeQuestionData.max"
                [value]="defaultTimeVal"
              >
              <div class="icon time"></div>
              <div class="clear-date"
                [hidden]='!dateTimeTimeForm.controls["dateTimeTimeInput"].value'
                (click)='clearContent($event, dateTimeTimeForm.controls["dateTimeTimeInput"])'
              >
                <img
                  src="assets/images/clear.svg"
                  alt="clear"
                />
              </div>
            </mat-form-field>
          </form>
        </div>
      </div>
      <mat-error
        class="form-question-error"
        *ngIf="dateTimeQuestionData.Required && isEmpty && !isInvalid"
      >{{ REQUIRED_MSG }}</mat-error>
      <mat-error
            class="form-question-error"
            *ngIf="isInvalid"
          >{{ INVALID_ANSWER_MSG }}</mat-error>
    </section>
  `
})
export class DateTimeQuestionComponent extends BaseQuestionComponent implements OnInit, OnDestroy
{
  public dateTimeDateQuestionData: IQuestionData;
  public dateTimeTimeQuestionData: IQuestionData;
  public dateTimeQuestionData: IQuestionData;
  public dateTimeDateForm: FormGroup;
  public dateTimeTimeForm: FormGroup;
  public dateTimeForm: FormGroup;
  public isEmpty: boolean = false;
  public isInvalid: boolean = false;

  public defaultDateVal: string;
  public defaultTimeVal: string;

  private dateTimeQuestionAnswer = null;
  private dateTimeQuestionSubscription: Subscription;
  private tempDate: string = '';
  private tempTime: string = '';

  constructor()
  {
    super();
  }

  ngOnInit()
  {
    this.setTranslateText();
    this.preInit();

    const defaultVal = this.dateTimeDateQuestionData.defaultVal && `${this.dateTimeDateQuestionData.defaultVal}`.split("T");
    this.defaultDateVal = defaultVal && defaultVal[0];
    this.defaultTimeVal = defaultVal && defaultVal[1];

    this.dateTimeDateForm = new FormGroup({
      dateTimeDateInput: new FormControl({ value: this.defaultDateVal, disabled: this.dateTimeDateQuestionData.disable }),
    });

    this.dateTimeDateForm.get('dateTimeDateInput').valueChanges.subscribe((newValue: string) => this.updateDateTimeQuestion(newValue, null));

    this.dateTimeTimeForm = new FormGroup({
      dateTimeTimeInput: new FormControl({ value: this.defaultTimeVal, disabled: this.dateTimeTimeQuestionData.disable }),
    });

    this.dateTimeTimeForm.get('dateTimeTimeInput').valueChanges.subscribe((newValue: string) => this.updateDateTimeQuestion(null, newValue));

    this.dateTimeQuestionSubscription = this.questionStatusUpdate((status: ReplyQuestionStatusEnum) =>
    {
      this.resetDefaultStatus(status);
      this.validation();
    });

    this.cacheAnswer();
  }

  ngOnDestroy()
  {
    this.dateTimeQuestionSubscription && this.dateTimeQuestionSubscription.unsubscribe();
  }

  private preInit()
  {
    this.dateTimeQuestionData = _.merge(this.data, {
    });
    this.dateTimeDateQuestionData = _.merge(this.data, {
    });
    this.dateTimeTimeQuestionData = _.merge(this.data, {
    });

    if (this.cacheResult)
    {
      this.dateTimeQuestionData = _.merge(this.dateTimeQuestionData, {
        defaultVal: this.cacheResult.value
      });
      this.dateTimeDateQuestionData = _.merge(this.dateTimeDateQuestionData, {
        defaultVal: this.cacheResult.value
      });
      this.dateTimeTimeQuestionData = _.merge(this.dateTimeTimeQuestionData, {
        defaultVal: this.cacheResult.value
      });

      this.dateTimeQuestionAnswer = this.cacheResult.value;
      this.cacheQuestionResult();
    }
  }

  private updateDateTimeQuestion(date: string, time: string)
  {
    date !== null && (this.tempDate = date);
    time !== null && (this.tempTime = time);

    if (((this.tempDate || "").length === 0 && (this.tempTime || "").length === 0)
      || ((this.tempDate || "").length > 0 && (this.tempTime || "").length > 0))
    {
      this.isInvalid = false;
    } else
    {
      this.isInvalid = true;
    }

    if (!!this.tempDate && !!this.tempTime)
    {
      this.dateTimeQuestionAnswer = `${this.tempDate}T${this.tempTime}`;
    }

    this.isEmpty = this.data.Required && (this.isEmptyValidation(this.tempDate) || this.isEmptyValidation(this.tempTime));

    this.answerIsInvalid();
    this.questionValid();
    this.cacheQuestionResult();
  }

  private cacheQuestionResult()
  {
    this.questionResult = { value: this.dateTimeQuestionAnswer };
    this.cacheAnswer();
  }

  private validation = () => this.updateDateTimeQuestion(this.dateTimeDateForm.get('dateTimeDateInput').value, this.dateTimeTimeForm.get('dateTimeTimeInput').value);

  private questionValid = () => this.setQuestionValidMark(this.data.Required, this.isEmpty);

  private answerIsInvalid = () => this.setQuestionValidMark(true, this.isInvalid);

  // overwrite base component
  public clearContentCallback()
  {
    this.validation();
    this.questionValid();
    this.answerIsInvalid();
    this.cacheAnswer();
  }
}


/**
 * QuestionType = 4
 * Memo Type
 */
@Component({
  styleUrls: ['form.questions.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <section>
      <sf-base-question [data]="memoQuestionData"></sf-base-question>
      <div class="question-type-memo">
        <form [formGroup]="memoForm">
          <mat-form-field class="form-field-memo">
            <textarea
              formControlName="memoInput"
              matInput
              class="no-tap-highlight"
              [rows]="memoQuestionData.min"
              [value]="memoQuestionData.defaultVal"
            ></textarea>
          </mat-form-field>
          <mat-error
            class="form-question-error"
            *ngIf="memoQuestionData.Required && isEmpty"
          >{{ REQUIRED_MSG }}</mat-error>
        </form>
      </div>
    </section>
  `
})
export class MemoQuestionComponent extends BaseQuestionComponent implements OnInit, OnDestroy
{
  public memoQuestionData: IQuestionData;
  public memoForm: FormGroup;
  public isEmpty: boolean = false;

  private memoQuestionAnswer: string;
  private memoQuestionSubscription: Subscription;

  constructor()
  {
    super();
  }

  ngOnInit()
  {
    this.setTranslateText();
    this.preInit();

    this.memoForm = new FormGroup({
      memoInput: new FormControl({
        value: this.memoQuestionData.defaultVal,
        disabled: this.memoQuestionData.disable,
      }),
    });

    this.memoForm.get('memoInput').valueChanges.subscribe((newValue: string) => this.updateMemoQuestion(newValue));

    this.memoQuestionSubscription = this.questionStatusUpdate((status: ReplyQuestionStatusEnum) =>
    {
      this.resetDefaultStatus(status);
      this.validation();
    });

    this.cacheAnswer();
  }

  ngOnDestroy()
  {
    this.memoQuestionSubscription && this.memoQuestionSubscription.unsubscribe();
  }

  private preInit()
  {
    this.memoQuestionData = _.merge(this.data, {
      defaultVal: '',
    });

    if (this.cacheResult)
    {
      this.memoQuestionData = _.merge(this.memoQuestionData, {
        defaultVal: this.cacheResult.value
      });

      this.memoQuestionAnswer = `${this.cacheResult.value}`;
      this.cacheQuestionResult();
    }
  }

  private updateMemoQuestion(msg: string)
  {
    this.memoQuestionAnswer = msg;
    this.isEmpty = this.data.Required && this.isEmptyValidation(this.memoQuestionAnswer);
    this.questionValid();
    this.cacheQuestionResult();
  }

  private cacheQuestionResult()
  {
    this.questionResult = { value: this.memoQuestionAnswer };
    this.cacheAnswer();
  }

  private validation = () => this.updateMemoQuestion(this.memoForm.get('memoInput').value);

  private questionValid = () => this.setQuestionValidMark(this.data.Required, this.isEmpty);
}


/**
 * QuestionType = 5
 * Number Type
 */
@Component({
  styleUrls: ['form.questions.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <section>
      <sf-base-question [data]="numberQuestionData"></sf-base-question>
      <div class="question-type-number">
        <form [formGroup]="numberForm">
          <mat-form-field class="form-field-number">
            <input
              matInput
              type="number"
              inputmode="decimal"
              step="any"
              formControlName="numberInput"
              [required]="numberQuestionData.Required"
              [value]="numberQuestionData.defaultVal"
              (keypress)="keypress($event)"
              maxLength="19"
            >
          </mat-form-field>
          <mat-error
            class="form-question-error"
            *ngIf="numberQuestionData.Required && isEmpty && !isInvalid"
          >{{ REQUIRED_MSG }}</mat-error>
          <mat-error
            class="form-question-error"
            *ngIf="isInvalid"
          >{{ INVALID_MSG }}</mat-error>
        </form>
      </div>
    </section>
  `
})
export class NumberQuestionComponent extends BaseQuestionComponent implements OnInit, OnDestroy, AfterViewInit
{
  public numberQuestionData: IQuestionData;
  public numberForm: FormGroup;
  public isEmpty: boolean = false;
  public isInvalid: boolean = false;

  private numberQuestionAnswer: string;
  private numberQuestionSubscription: Subscription;
  private numberInput: HTMLInputElement;

  constructor(
    private elementRef: ElementRef,
  )
  {
    super();
  }

  ngAfterViewInit()
  {
    this.numberInput = <HTMLInputElement>this.elementRef.nativeElement;
  }

  ngOnInit()
  {
    this.setTranslateText();
    this.preInit();

    this.numberForm = new FormGroup({
      numberInput: new FormControl({ value: this.numberQuestionData.defaultVal, disabled: this.numberQuestionData.disable }),
    });

    this.numberForm.get('numberInput').valueChanges.subscribe((newValue: string) =>
    {
      this.updateNumberQuestion(newValue);
    });

    this.numberQuestionSubscription = this.questionStatusUpdate((status: ReplyQuestionStatusEnum) =>
    {
      this.resetDefaultStatus(status);
      this.validation();
    });

    this.cacheAnswer();
  }

  ngOnDestroy()
  {
    this.numberQuestionSubscription && this.numberQuestionSubscription.unsubscribe();
  }

  private preInit()
  {
    // pre-initial default data
    this.numberQuestionData = _.merge(this.data, {});

    if (this.cacheResult)
    {
      this.numberQuestionData = _.merge(this.numberQuestionData, {
        defaultVal: this.cacheResult.value
      });

      this.numberQuestionAnswer = `${this.cacheResult.value}`;
      this.cacheQuestionResult();
    }
  }

  private updateNumberQuestion(num: string)
  {
    const inputEle = this.numberInput.querySelector("input[type='number']") as any;
    this.isInvalid = inputEle && inputEle.validity.badInput;
    this.numberQuestionAnswer = this.lengthControl(num);
    if (`${num}`.length >= 18 || `${num}` !== `${this.numberQuestionAnswer}`)
    {
      this.numberForm.get('numberInput').setValue(this.numberQuestionAnswer, { emitEvent: false });
    }
    this.isEmpty = this.data.Required && this.isEmptyValidation(this.numberQuestionAnswer);
    this.questionValid();
    this.cacheQuestionResult();
  }

  private lengthControl(num: any)
  {
    const splitNum = `${num}`.split('.');
    const isDecimal = !_.isEqual(splitNum.length, 1);
    const beforePeriodThreshold = 10;

    if (isDecimal)
    {
      const afterPeriodThreshold = this.data.NumberPrecision == null ? 8 : Math.min(Number(this.data.NumberPrecision), 8)
      let beforePeriodValue = `${splitNum[0]}`, afterPeriodValue = `${splitNum[1]}`;
      if (`${splitNum[0]}`.length > beforePeriodThreshold)
      {
        beforePeriodValue = `${splitNum[0]}`.slice(0, beforePeriodThreshold);
      }

      if (`${splitNum[1]}`.length > afterPeriodThreshold)
      {
        afterPeriodValue = `${splitNum[1]}`.slice(0, afterPeriodThreshold);
      }

      return [beforePeriodValue, afterPeriodValue].join('.');
    }
    else if (`${num}`.length > beforePeriodThreshold)
    {
      return `${num}`.slice(0, beforePeriodThreshold);
    }

    return num;
  }

  private cacheQuestionResult()
  {
    this.questionResult = { value: this.numberQuestionAnswer };
    this.cacheAnswer();
  }

  public keypress = function (ev)
  {
    var keyCode = ev.which || ev.keyCode || 0;
    if (
      (keyCode != ".".charCodeAt(0) || (this.numberQuestionData && this.numberQuestionData.NumberPrecision === 0) || (ev.target.value.indexOf('.') !== -1))
      && (keyCode != "-".charCodeAt(0))
      && (keyCode < "0".charCodeAt(0) || keyCode > "9".charCodeAt(0))
      && !(keyCode == 37 && ev.key != "%")
      && !(keyCode == 39 && ev.key != "'")
      && keyCode !== 9)
    {
      ev.preventDefault();
      ev.stopPropagation();
      return;
    }
  }

  private validation = () => this.updateNumberQuestion(this.numberForm.get('numberInput').value);

  private questionValid = () => this.setQuestionValidMark(this.data.Required, this.isEmpty);
}


/**
 * QuestionType = 6
 * Phone Type
 */
@Component({
  styleUrls: ['form.questions.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <section>
      <sf-base-question [data]="phoneQuestionData"></sf-base-question>
      <div class="question-type-phone">
        <form [formGroup]="phoneForm">
          <mat-form-field class="form-field-phone">
            <input
              matInput
              type="tel"
              formControlName="phoneInput"
              [value]="phoneQuestionData.defaultVal"
              (keyup)="keyupValue($event)"
              autocomplete="off"
              phoneMask
              maxlength="14"
            >
          </mat-form-field>
          <mat-error
            class="form-question-error"
            *ngIf="phoneQuestionData.Required && isEmpty"
          >{{ REQUIRED_MSG }}</mat-error>
          <mat-error *ngIf="isInvalid">
              {{ 'subscription.share.mobile.invalid' | translate }}
            </mat-error>
        </form>
      </div>
    </section>
  `
})
export class PhoneQuestionComponent extends BaseQuestionComponent implements OnInit, OnDestroy
{
  public phoneQuestionData: IQuestionData;
  public phoneForm: FormGroup;
  public isEmpty: boolean = false;
  public isInvalid: boolean = false;

  private phoneQuestionAnswer: string;
  private phoneQuestionSubscription: Subscription;

  constructor()
  {
    super();
  }

  ngOnInit()
  {
    this.setTranslateText();
    this.preInit();

    this.phoneForm = new FormGroup({
      phoneInput: new FormControl({ value: this.phoneQuestionData.defaultVal, disabled: this.phoneQuestionData.disable }),
    });

    this.phoneForm.get('phoneInput').valueChanges.subscribe((newValue: string) => this.updatePhoneQuestion(newValue));

    this.phoneQuestionSubscription = this.questionStatusUpdate((status: ReplyQuestionStatusEnum) =>
    {
      this.resetDefaultStatus(status);
      this.validation();
    });

    this.cacheAnswer();
  }

  ngOnDestroy()
  {
    this.phoneQuestionSubscription && this.phoneQuestionSubscription.unsubscribe();
  }

  private preInit()
  {
    // pre-initial default data
    this.phoneQuestionData = _.merge(this.data, {
      defaultVal: '',
    });

    if (this.cacheResult)
    {
      this.phoneQuestionData = _.merge(this.phoneQuestionData, {
        defaultVal: this.cacheResult.value
      });

      this.phoneQuestionAnswer = `${this.cacheResult.value}`;
      this.cacheQuestionResult();
    }
  }

  private updatePhoneQuestion(num: string)
  {
    this.phoneQuestionAnswer = num;
    this.isEmpty = this.data.Required && this.isEmptyValidation(this.phoneQuestionAnswer);
    this.isInvalid = this.data.Required ? !isValidPhoneNumber(num) : !!this.phoneQuestionAnswer ? !isValidPhoneNumber(num) : false;
    this.questionValid();
    this.questionIsInvalid();
    this.cacheQuestionResult();
  }

  private cacheQuestionResult()
  {
    this.questionResult = { value: this.phoneQuestionAnswer };
    this.cacheAnswer();
  }

  private validation = () => this.updatePhoneQuestion(this.phoneQuestionAnswer);

  private questionValid = () => this.setQuestionValidMark(this.data.Required, this.isEmpty);

  private questionIsInvalid = () => this.setQuestionValidMark(true, this.isInvalid);

  public keyupValue(event: any)
  {
    const value: string = _.get(event, 'target.value') || '';
    this.updatePhoneQuestion(value);
    if (_.isEmpty(value))
    {
      this.phoneQuestionAnswer = '';
      this.cacheQuestionResult();
    }
  }
}


/**
 * QuestionType = 7
 * Text Type
 */
@Component({
  styleUrls: ['form.questions.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <section>
      <sf-base-question [data]="textQuestionData"></sf-base-question>
      <div class="question-type-text">
        <form [formGroup]="textForm">
          <mat-form-field class="form-field-text">
            <input
              matInput
              type="text"
              formControlName="textInput"
              [maxlength]="textQuestionData.MaxLength || 255"
              [required]="textQuestionData.Required"
              [value]="textQuestionData.defaultVal"
              autocomplete="off"
            >
          </mat-form-field>
          <mat-error
            class="form-question-error"
            *ngIf="textQuestionData.Required && isEmpty"
          >{{ REQUIRED_MSG }}</mat-error>
        </form>
      </div>
    </section>
  `
})
export class TextQuestionComponent extends BaseQuestionComponent implements OnInit, OnDestroy
{
  public textQuestionData: IQuestionData;
  public textForm: FormGroup;
  public isEmpty: boolean = false;

  private textQuestionAnswer: string;
  private textQuestionSubscription: Subscription;

  constructor()
  {
    super();
  }

  ngOnInit()
  {
    this.setTranslateText();
    this.preInit();

    this.textForm = new FormGroup({
      textInput: new FormControl({ value: this.textQuestionData.defaultVal, disabled: this.textQuestionData.disable }),
    });

    this.textForm.get('textInput').valueChanges.subscribe((newValue: string) => this.updateTextQuestion(newValue));

    this.textQuestionSubscription = this.questionStatusUpdate((status: ReplyQuestionStatusEnum) =>
    {
      this.resetDefaultStatus(status);
      this.validation();
    });

    this.cacheAnswer();
  }

  ngOnDestroy()
  {
    this.textQuestionSubscription && this.textQuestionSubscription.unsubscribe();
  }

  private preInit()
  {
    // pre-initial default data
    this.textQuestionData = _.merge(this.data, {
      defaultVal: ``,
    });

    if (this.cacheResult)
    {
      this.textQuestionData = _.merge(this.textQuestionData, {
        defaultVal: this.cacheResult.value
      });

      this.textQuestionAnswer = `${this.cacheResult.value}`;
      this.cacheQuestionResult();
    }
  }

  private updateTextQuestion(text: string)
  {
    this.textQuestionAnswer = text;
    this.isEmpty = this.data.Required && this.isEmptyValidation(this.textQuestionAnswer);
    this.questionValid();
    this.cacheQuestionResult();
  }

  private cacheQuestionResult()
  {
    this.questionResult = { value: this.textQuestionAnswer };
    this.cacheAnswer();
  }

  private validation = () => this.updateTextQuestion(this.textForm.get('textInput').value);

  private questionValid = () => this.setQuestionValidMark(this.data.Required, this.isEmpty);
}


/**
 * QuestionType = 8
 * Time Type
 */
@Component({
  styleUrls: ['form.questions.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <section>
      <sf-base-question [data]="timeQuestionData"></sf-base-question>
      <div class="question-type-time">
        <form [formGroup]="timeForm">
          <mat-form-field class="form-field-time">
            <input
              matInput
              type="time"
              formControlName="timeInput"
              autocomplete="off"
              min="timeQuestionData.min"
              max="timeQuestionData.max"
              [value]="timeQuestionData.defaultVal"
            >
            <div class="icon time"></div>
            <div class="clear-date"
              [hidden]='!timeForm.controls["timeInput"].value'
              (click)='clearContent($event, timeForm.controls["timeInput"])'
            >
              <img
                src="assets/images/clear.svg"
                alt="clear"
              />
            </div>
          </mat-form-field>
          <mat-error
            class="form-question-error"
            *ngIf="timeQuestionData.Required && isEmpty"
          >{{ REQUIRED_MSG }}</mat-error>
        </form>
      </div>
    </section>
  `
})
export class TimeQuestionComponent extends BaseQuestionComponent implements OnInit, OnDestroy
{
  public timeQuestionData: IQuestionData;
  public timeForm: FormGroup;
  public isEmpty: boolean = false;

  private timeQuestionAnswer: string;
  private timeQuestionSubscription: Subscription;

  constructor()
  {
    super();
  }

  ngOnInit()
  {
    this.setTranslateText();
    this.preInit();

    this.timeForm = new FormGroup({
      timeInput: new FormControl({
        value: this.timeQuestionData.defaultVal,
        disabled: this.timeQuestionData.disable
      }),
    });

    this.timeForm.get('timeInput').valueChanges.subscribe((newValue: string) => this.updateTimeQuestion(newValue));

    this.timeQuestionSubscription = this.questionStatusUpdate((status: ReplyQuestionStatusEnum) =>
    {
      this.resetDefaultStatus(status);
      this.validation();
    });

    this.cacheAnswer();
  }

  ngOnDestroy()
  {
    this.timeQuestionSubscription && this.timeQuestionSubscription.unsubscribe();
  }

  private preInit()
  {
    // pre-initial default data
    this.timeQuestionData = _.merge(this.data, {
    });

    if (this.cacheResult)
    {
      this.timeQuestionData = _.merge(this.timeQuestionData, {
        defaultVal: this.cacheResult.value
      });

      this.timeQuestionAnswer = `${this.cacheResult.value}`;
      this.cacheQuestionResult();
    }
  }

  private updateTimeQuestion(date: string)
  {
    // TO-DO: needs to add moment format
    this.timeQuestionAnswer = date;
    this.isEmpty = this.data.Required && this.isEmptyValidation(this.timeQuestionAnswer);
    this.questionValid();
    this.cacheQuestionResult();
  }

  private cacheQuestionResult()
  {
    this.questionResult = { value: this.timeQuestionAnswer };
    this.cacheAnswer();
  }

  private validation = () => this.updateTimeQuestion(this.timeForm.get('timeInput').value);

  private questionValid = () => this.setQuestionValidMark(this.data.Required, this.isEmpty);

  // overwrite base component
  public clearContentCallback()
  {
    this.validation();
    this.questionValid();
    this.cacheAnswer();
  }
}


/**
 * QuestionType = 9
 * Zip Type
 */
@Component({
  styleUrls: ['form.questions.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <section>
      <sf-base-question [data]="zipQuestionData"></sf-base-question>
      <div>
        ZipQuestionComponent
      </div>
    </section>
  `
})
export class ZipQuestionComponent extends BaseQuestionComponent implements OnInit, OnDestroy
{
  public zipQuestionData: IQuestionData;
  public isEmpty: boolean = false;

  private zipQuestionAnswer: string;
  private zipQuestionSubscription: Subscription;

  constructor()
  {
    super();
  }

  ngOnInit()
  {
    this.setTranslateText();
    this.zipQuestionData = _.merge(this.data, {
      question: 'zip question title'
    });

    this.zipQuestionSubscription = this.questionStatusUpdate((status: ReplyQuestionStatusEnum) =>
    {
      this.resetDefaultStatus(status);
      this.validation();
    });

    this.cacheAnswer();
  }

  ngOnDestroy()
  {
    this.zipQuestionSubscription && this.zipQuestionSubscription.unsubscribe();
  }

  private validation() { }
}


/**
 * QuestionType = 10.1
 * Single List Type
 */
@Component({
  styleUrls: ['form.questions.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <section>
      <sf-base-question [data]="singleListQuestionData"></sf-base-question>
      <div class="question-type-single-list">
        <form [formGroup]="singleListForm">
          <mat-radio-group
            class="flex-column"
            (change)="singleSelectChange($event)"
          >
            <mat-radio-button
              [value]="option.Id"
              name="radio_{{option.Id}}"
              *ngFor="let option of singleListQuestionData.QuestionPickListOptions"
              [checked]="option.Id === singleListQuestionData.defaultVal"
            >
              {{option.PickList}}
            </mat-radio-button>
          </mat-radio-group>
          <mat-form-field
            class="form-field-memo form-field-other"
            *ngIf="shouldShowComment"
          >
            <textarea
              formControlName="otherInput"
              matInput
              class="no-tap-highlight"
              [rows]="singleListQuestionData.min"
              [value]="singleListQuestionData.otherVal"
            ></textarea>
          </mat-form-field>
          <mat-error
            class="form-question-error"
            *ngIf="singleListQuestionData.Required && isEmpty"
          >{{ REQUIRED_MSG }}</mat-error>
        </form>
      </div>
    </section>
  `
})
export class SingleListQuestionComponent extends BaseQuestionComponent implements OnInit, OnDestroy
{
  public singleListQuestionData: IQuestionData;
  public isEmpty: boolean = false;
  public singleListForm: FormGroup;
  public shouldShowComment: boolean = false;

  private singleListQuestionAnswer: any;
  private otherOptionSelected: boolean = false;
  private singleListOtherComment: string = null;
  private otherOptionId: number = null;
  private singleListQuestionSubscription: Subscription;

  constructor()
  {
    super();
  }

  ngOnInit()
  {
    this.setTranslateText();
    this.preInit();

    this.singleListQuestionSubscription = this.questionStatusUpdate((status: ReplyQuestionStatusEnum) =>
    {
      this.resetDefaultStatus(status);
      this.validation();
    });

    this.cacheAnswer();
  }

  ngOnDestroy()
  {
    this.singleListQuestionSubscription && this.singleListQuestionSubscription.unsubscribe();
    this.singleListQuestionAnswer = null;
    this.singleListQuestionData = null;
    this.singleListOtherComment = null;
    this.otherOptionId = null;
    this.otherOptionSelected = false;
  }

  private preInit()
  {
    if (this.data && !_.isEmpty(this.data.QuestionPickListOptions))
    {
      _.sortBy(this.data.QuestionPickListOptions, ['Sort']);
    }

    this.singleListQuestionData = _.merge(this.data, {
      otherVal: '',
    });

    this.getOtherOption();

    this.singleListForm = new FormGroup({
      otherInput: new FormControl({
        value: this.singleListQuestionData.otherVal || '',
        disabled: true,
      })
    });

    this.singleListForm.get('otherInput').valueChanges.subscribe((newValue: string) => this.updateOtherComment(newValue));

    if (this.cacheResult)
    {
      this.singleListQuestionData = _.merge(this.singleListQuestionData, {
        defaultVal: this.cacheResult.value,
        otherVal: this.cacheResult.other || '',
      });

      this.singleListQuestionAnswer = this.cacheResult.value;
      this.otherOptionId = this.cacheResult.otherOptionId;
      // enable comment area once cache value is other option
      this.otherOptionSelected = _.isEqual(this.singleListQuestionAnswer, this.otherOptionId);
      this.otherOptionSelected && this.singleListForm.get('otherInput').enable();
      this.otherOptionSelected && (this.singleListOtherComment = this.cacheResult.other);
      this.otherOptionSelected && this.updateOtherComment(this.singleListOtherComment);
      this.cacheQuestionResult();
    }
  }

  private getOtherOption()
  {
    _.forEach(this.singleListQuestionData.QuestionPickListOptions, (option: IQuestionPickListOption) =>
    {
      if (option.IsOtherOption)
      {
        this.otherOptionId = option.Id;
        this.shouldShowComment = true;
      }
    });
  }

  private updateOtherComment(msg: string)
  {
    // set other comment as parameter message
    this.singleListOtherComment = msg;
    // value control for single list option comment,
    // set null for API value once its empty
    _.isEmpty(_.trim(msg)) && (this.singleListOtherComment = null);
    this.isEmpty = this.data.Required && this.isEmptyValidation(this.singleListOtherComment);
    this.cacheQuestionResult();
  }

  public singleSelectChange(event: MatRadioChange)
  {
    this.singleListQuestionAnswer = event.value;
    this.resetComment();
    this.validateComment(event.value);
    this.validation();
    this.cacheQuestionResult();
  }

  private resetComment()
  {
    this.singleListOtherComment = null;
    this.singleListForm.get('otherInput').setValue(this.singleListOtherComment, { emitEvent: false });
    this.singleListForm.get('otherInput').disable();
  }

  private validateComment(optionValue: number)
  {
    this.otherOptionSelected = _.isEqual(optionValue, this.otherOptionId);

    if (this.otherOptionSelected)
    {
      this.data.Required && this.updateOtherComment('');
      this.singleListForm.get('otherInput').enable();
    }
  }

  private cacheQuestionResult()
  {
    this.questionResult = { value: this.singleListQuestionAnswer };
    _.merge(this.questionResult, {
      other: this.singleListOtherComment,
      otherOptionId: this.otherOptionId,
    });
    this.cacheAnswer();
  }

  private validation()
  {
    this.isEmpty = this.data.Required && this.isEmptyValidation(this.singleListQuestionAnswer);
    this.otherOptionSelected && this.updateOtherComment(this.singleListOtherComment);
    this.questionValid();
  }

  private questionValid = () => this.setQuestionValidMark(this.data.Required, this.isEmpty);
}


/**
 * QuestionType = 10.2
 * Multiple List Type
 */
@Component({
  styleUrls: ['form.questions.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <section>
      <sf-base-question [data]="multipleListQuestionData"></sf-base-question>
      <div class="question-type-multiple-list">
        <form [formGroup]="multipleListForm">
          <div class="flex-column">
            <mat-checkbox
              class="example-margin"
              [value]="option.Id"
              (change)="multipleSelectChange(option.Id, $event)"
              [checked]="isAnswerSelected(option.Id)"
              *ngFor="let option of multipleListQuestionData.QuestionPickListOptions"
            >{{ option.PickList }}</mat-checkbox>
          </div>
          <mat-form-field
            class="form-field-memo form-field-other"
            *ngIf="shouldShowComment"
          >
            <textarea
              formControlName="otherInput"
              matInput
              class="no-tap-highlight"
              [rows]="multipleListQuestionData.min"
              [value]="multipleListQuestionData.otherVal"
            ></textarea>
          </mat-form-field>
          <mat-error
            class="form-question-error"
            *ngIf="multipleListQuestionData.Required && isEmpty"
          >{{ REQUIRED_MSG }}</mat-error>
        </form>
      </div>
    </section>
  `
})
export class MultipleListQuestionComponent extends BaseQuestionComponent implements OnInit, OnDestroy
{
  public multipleListQuestionData: IQuestionData;
  public isEmpty: boolean = false;
  public multipleListForm: FormGroup;
  public cachedAnswerList: any[] = [];
  public shouldShowComment: boolean = false;

  private multipleListQuestionAnswer: Set<any> = new Set<any>();
  private multipleListOtherComment: string = null;
  private otherOptionSelected: boolean = false;
  private otherOptionId: number = null;
  private multipleListQuestionSubscription: Subscription;

  constructor(
    private cdRef: ChangeDetectorRef,
  )
  {
    super();
  }

  ngOnInit()
  {
    this.setTranslateText();
    this.preInit();

    this.multipleListQuestionSubscription = this.questionStatusUpdate((status: ReplyQuestionStatusEnum) =>
    {
      this.resetDefaultStatus(status);
      this.validation();
    });

    this.cacheAnswer();
  }

  ngOnDestroy()
  {
    this.multipleListQuestionSubscription && this.multipleListQuestionSubscription.unsubscribe();
    this.multipleListQuestionAnswer = null;
    this.multipleListQuestionData = null;
    this.multipleListOtherComment = null;
    this.otherOptionId = null;
    this.otherOptionSelected = false;
  }

  private preInit()
  {
    if (this.data && !_.isEmpty(this.data.QuestionPickListOptions))
    {
      _.sortBy(this.data.QuestionPickListOptions, ['Sort']);
    }

    this.multipleListQuestionData = _.merge(this.data, {
      otherVal: ''
    });

    this.getOtherOption();

    this.multipleListForm = new FormGroup({
      otherInput: new FormControl({
        value: this.multipleListQuestionData.otherVal || '',
        disabled: true,
      })
    });

    this.multipleListForm.get('otherInput').valueChanges.subscribe((newValue: string) => this.updateOtherComment(newValue));

    if (this.cacheResult)
    {
      this.multipleListQuestionData = _.merge(this.multipleListQuestionData, {
        defaultVal: this.cacheResult.value
      });

      `${this.cacheResult.value}`.split(',').map((selectedVal) =>
      {
        if (Number(selectedVal) > 0)
        {
          this.multipleListQuestionAnswer.add(selectedVal);
          this.cachedAnswerList.push(Number(selectedVal));

          this.otherOptionSelected = _.isEqual(Number(selectedVal), Number(this.otherOptionId));

          if (this.otherOptionSelected)
          {
            this.multipleListForm.get('otherInput').enable();
            this.multipleListOtherComment = this.cacheResult.other;
            this.updateOtherComment(this.multipleListOtherComment);
            this.multipleListForm.get('otherInput').setValue(this.multipleListOtherComment, { emitEvent: false });
          }

          this.cdRef.detectChanges();
        }

      });

      this.cacheQuestionResult();
    }
  }

  private updateOtherComment(msg: string)
  {
    // set other comment as parameter message
    this.multipleListOtherComment = msg;
    // value control for single list option comment,
    // set null for API value once its empty
    _.isEmpty(_.trim(msg)) && (this.multipleListOtherComment = null);
    this.multipleListQuestionData.otherVal = this.multipleListOtherComment;
    this.isEmpty = this.data.Required && this.otherOptionSelected && this.isEmptyValidation(this.multipleListOtherComment);
    this.cacheQuestionResult();
  }

  private getOtherOption()
  {
    _.forEach(this.multipleListQuestionData.QuestionPickListOptions, (option: IQuestionPickListOption) =>
    {
      if (option.IsOtherOption)
      {
        this.otherOptionId = option.Id;
        this.shouldShowComment = true;
      }
    });
  }

  public isAnswerSelected(optionValue: any): boolean
  {
    return this.cachedAnswerList.indexOf(optionValue) !== -1;
  }

  public multipleSelectChange(value: any, event: MatCheckboxChange)
  {
    event.checked && this.multipleListQuestionAnswer.add(`${value}`);
    !event.checked && this.multipleListQuestionAnswer.delete(`${value}`);
    this.isEmpty = this.data.Required && this.isEmptyValidation(Array.from(this.multipleListQuestionAnswer));
    this.validateComment(value, event.checked);
    this.cacheQuestionResult();
  }

  private cacheQuestionResult()
  {
    this.questionResult = { value: Array.from(this.multipleListQuestionAnswer).join(`,`) };
    _.merge(this.questionResult, {
      other: this.multipleListOtherComment,
      otherOptionId: this.otherOptionId
    });
    this.cacheAnswer();
  }

  private validation()
  {
    this.isEmpty = this.data.Required && this.isEmptyValidation(Array.from(this.multipleListQuestionAnswer));
    this.otherOptionSelected && this.data.Required && this.updateOtherComment(this.multipleListOtherComment);
    this.otherOptionSelected && this.cdRef.detectChanges();
    this.questionValid();
  }

  private validateComment(optionValue: number, isChecked: boolean)
  {
    // other option clicked, maybe selected or deselected
    if (_.isEqual(Number(optionValue), Number(this.otherOptionId)))
    {
      // prior is selected
      if (this.otherOptionSelected)
      {
        // now is deselected
        if (!isChecked)
        {
          this.otherOptionSelected = isChecked;
          this.updateOtherComment(``);
          this.multipleListForm.get('otherInput').setValue(this.multipleListOtherComment);
          this.multipleListForm.get('otherInput').disable();
          // do normal validation
          this.validation();
          this.cacheQuestionResult();
        }
      }
      // prior is deselected
      else
      {
        if (isChecked)
        {
          this.otherOptionSelected = isChecked;
          this.multipleListForm.get('otherInput').enable();
          this.updateOtherComment('');
        }
      }
    }
    // other option not clicked, but should keep checking comment valid
    else
    {
      this.otherOptionSelected && this.updateOtherComment(this.multipleListOtherComment);
    }
  }

  private questionValid = () => this.setQuestionValidMark(this.data.Required, this.isEmpty);
}


/**
 * QuestionType = 11
 * Rate Type
 */
@Component({
  styleUrls: ['form.questions.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <section>
      <sf-base-question [data]="rateQuestionData"></sf-base-question>
      <div class="question-type-rate">
        <div class="flex-row">
          <span class="scale-min-desc">{{ rateQuestionData?.LeftSideRatingLabel }}</span>
          <span class="scale-max-desc">{{ rateQuestionData?.RightSideRatingLabel }}</span>
        </div>
        <mat-button-toggle-group name="scaleGroup" (change)="rateValueChange($event)">
          <mat-button-toggle
            *ngFor="let scale of scaleRange"
            [value]="scale"
            [checked]="scale === rateQuestionData.defaultVal"
          >{{ scale }}</mat-button-toggle>
        </mat-button-toggle-group>
        <mat-error
          class="form-question-error"
          *ngIf="rateQuestionData.Required && isEmpty"
        >{{ REQUIRED_MSG }}</mat-error>
      </div>
    </section>
  `
})
export class RateQuestionComponent extends BaseQuestionComponent implements OnInit, OnDestroy
{
  public rateQuestionData: IQuestionData;
  public scaleRange: number[];
  public isEmpty: boolean = false;

  private rateQuestionAnswer: any;
  private rateQuestionSubscription: Subscription;

  constructor()
  {
    super();
  }

  ngOnInit()
  {
    this.setTranslateText();
    this.preInit();

    // default start rating value should be 1, so needs to extend the range size 1
    this.scaleRange = _.range(1, this.rateQuestionData.Scale + 1);

    this.rateQuestionSubscription = this.questionStatusUpdate((status: ReplyQuestionStatusEnum) =>
    {
      this.resetDefaultStatus(status);
      this.validation();
    });

    this.cacheAnswer();
  }

  ngOnDestroy()
  {
    this.rateQuestionSubscription && this.rateQuestionSubscription.unsubscribe();
  }

  private preInit()
  {
    // pre-initial default data
    this.rateQuestionData = _.merge(this.data, {
      scale: 5,
    });

    if (this.cacheResult)
    {
      this.rateQuestionData = _.merge(this.rateQuestionData, {
        defaultVal: this.cacheResult.value
      });

      this.rateQuestionAnswer = this.cacheResult.value;
      this.cacheQuestionResult();
    }
  }

  public rateValueChange(event: MatButtonToggleChange)
  {
    this.rateQuestionAnswer = event.value;
    this.isEmpty = this.data.Required && this.isEmptyValidation(this.rateQuestionAnswer);
    this.questionValid();
    this.cacheQuestionResult();
  }

  private cacheQuestionResult()
  {
    this.questionResult = { value: this.rateQuestionAnswer };
    this.cacheAnswer();
  }

  private validation()
  {
    this.isEmpty = this.data.Required && this.isEmptyValidation(this.rateQuestionAnswer);
    this.questionValid();
  }

  private questionValid = () => this.setQuestionValidMark(this.data.Required, this.isEmpty);
}


/**
 * QuestionType = 12
 * System_Field Type
 */
@Component({
  styleUrls: ['form.questions.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <section>
      <sf-base-question [data]="systemFieldQuestionData"></sf-base-question>
      <div class="question-type-systemField">
        <div [class]="isImageAnswer(answer) ? setImageAnswerClass(answer) : 'answer'">{{ isImageAnswer(answer) ? "" : answer }}</div>
      </div>
    </section>
  `
})
export class SystemFieldQuestionComponent extends BaseQuestionComponent implements OnInit, OnDestroy
{
  public systemFieldQuestionData: IQuestionData;
  public answer = null;

  private systemFieldStatusSubscription: Subscription;
  private systemFieldQuestionAnswer = null;

  public isImageAnswer = isImageSystemFiled.bind(this);
  public setImageAnswerClass = setImageSystemFiledClass.bind(this);

  constructor()
  {
    super();
  }

  ngOnInit()
  {
    this.preInit();
    this.systemFieldQuestionAnswer = this.data.Answer;

    this.systemFieldStatusSubscription = this.questionStatusUpdate((status: ReplyQuestionStatusEnum) =>
    {
      this.cacheQuestionResult();
    });
  }

  ngOnDestroy()
  {
    this.systemFieldStatusSubscription && this.systemFieldStatusSubscription.unsubscribe();
  }

  private preInit()
  {
    // pre-initial default data
    this.systemFieldQuestionData = _.merge(this.data, {});
    const { Answer, SystemFieldName, udfFields } = this.systemFieldQuestionData;
    this.answer = formatSystemField(SystemFieldName, Answer, udfFields);
    this.setQuestionValidMark(true, false);
  }

  private cacheQuestionResult()
  {
    this.questionResult = { value: this.systemFieldQuestionAnswer };
    this.cacheAnswer();
  }
}

/**
 * QuestionType = 13
 * Currency Type
 */
@Component({
  styleUrls: ['form.questions.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <section>
      <sf-base-question [data]="currencyQuestionData"></sf-base-question>
      <div class="question-type-currency">
        <form [formGroup]="currencyForm">
          <mat-form-field class="form-field-currency">
           <span class="currency-symbol">{{currencySymbol}}</span>
            <input
              matInput
              type="number"
              inputmode="decimal"
              formControlName="currencyInput"
              step="any"
              class="currencyInput"
              [required]="currencyQuestionData.Required"
              autocomplete="off"
              (keypress)="keypress($event)"
              [value]="currencyQuestionData.defaultVal"
            >
          </mat-form-field>
          <mat-error
            class="form-question-error"
            *ngIf="currencyQuestionData.Required && isEmpty"
          >{{ REQUIRED_MSG }}</mat-error>
        </form>
      </div>
    </section>
  `
})
export class CurrencyQuestionComponent extends BaseQuestionComponent implements OnInit, OnDestroy
{
  public currencyQuestionData: IQuestionData;
  public currencyForm: FormGroup;
  public isEmpty: boolean = false;
  public isInvalid: boolean = false;

  private currencyQuestionAnswer: any;
  private currencyQuestionSubscription: Subscription;

  public currencySymbol: string = "$";

  public maxIntegerLength: number = 10;
  public maxDecimalLength: number = 2;

  constructor()
  {
    super();
  }

  ngOnInit()
  {
    this.setTranslateText();
    this.preInit();

    this.currencyForm = new FormGroup({
      currencyInput: new FormControl({ value: this.currencyQuestionData.defaultVal, disabled: this.currencyQuestionData.disable }),
    });

    this.currencyForm.get('currencyInput').valueChanges.subscribe((newValue: string) => this.updateCurrencyQuestion(newValue));

    this.currencyQuestionSubscription = this.questionStatusUpdate((status: ReplyQuestionStatusEnum) =>
    {
      this.resetDefaultStatus(status);
      this.validation();
    });

    this.cacheAnswer();
  }

  ngOnDestroy()
  {
    this.currencyQuestionSubscription && this.currencyQuestionSubscription.unsubscribe();
  }

  private preInit()
  {
    // pre-initial default data
    this.currencyQuestionData = _.merge(this.data, {
      defaultVal: ``,
    });

    if (this.cacheResult)
    {
      this.currencyQuestionData = _.merge(this.currencyQuestionData, {
        defaultVal: this.cacheResult.value
      });

      this.currencyQuestionAnswer = `${this.cacheResult.value}`;
      this.cacheQuestionResult();
    }

    // set the max decimal length
    this.maxDecimalLength = this.currencyQuestionData.MaxLength === 0 ? 0 : (this.currencyQuestionData.MaxLength || 2);
  }

  private updateCurrencyQuestion(value: string)
  {
    if (!this.currencyQuestionAnswer && !value)
    {
      this.currencyForm.get('currencyInput').setValue("", { emitEvent: false });
    }

    this.currencyQuestionAnswer = value ? parseFloat(value).toFixed(this.maxDecimalLength) : "";
    this.currencyQuestionAnswer = this.lengthControl(value);
    if (`${value}`.length >= 12 || `${value}` !== `${this.currencyQuestionAnswer}`)
    {
      this.currencyForm.get('currencyInput').setValue(this.currencyQuestionAnswer, { emitEvent: false });
    }
    this.isEmpty = this.data.Required && this.isEmptyValidation(this.currencyQuestionAnswer);
    this.questionValid();
    this.cacheQuestionResult();
  }

  private lengthControl(num: any)
  {
    const splitNum = `${num}`.split('.');
    const isDecimal = !_.isEqual(splitNum.length, 1);
    const beforePeriodThreshold = this.maxIntegerLength;

    if (isDecimal)
    {
      const afterPeriodThreshold = this.maxDecimalLength == null ? 2 : Math.min(Number(this.maxDecimalLength), 2)
      let beforePeriodValue = `${splitNum[0]}`, afterPeriodValue = `${splitNum[1]}`;
      if (`${splitNum[0]}`.length > beforePeriodThreshold)
      {
        beforePeriodValue = `${splitNum[0]}`.slice(0, beforePeriodThreshold);
      }

      if (`${splitNum[1]}`.length > afterPeriodThreshold)
      {
        afterPeriodValue = `${splitNum[1]}`.slice(0, afterPeriodThreshold);
      }

      return [beforePeriodValue, afterPeriodValue].join('.');
    }
    else if (`${num}`.length > beforePeriodThreshold)
    {
      return `${num}`.slice(0, beforePeriodThreshold);
    }

    return num;
  }

  private cacheQuestionResult()
  {
    this.questionResult = { value: this.currencyQuestionAnswer };
    this.cacheAnswer();
  }

  private validation()
  {
    this.updateCurrencyQuestion(this.currencyForm.get('currencyInput').value);
  }

  private questionValid = () => this.setQuestionValidMark(this.data.Required, this.isEmpty);


  public keypress = function (ev)
  {
    var keyCode = ev.which || ev.keyCode || 0;
    if (
      ((keyCode != ".".charCodeAt(0)) || (this.currencyQuestionData && (this.currencyQuestionData.MaxLength === 0)) || (ev.target.value.indexOf('.') !== -1))
      && (keyCode != "-".charCodeAt(0))
      && (keyCode < "0".charCodeAt(0) || keyCode > "9".charCodeAt(0))
      && !(keyCode == 37 && ev.key != "%")
      && !(keyCode == 39 && ev.key != "'")
      && keyCode !== 9)
    {
      ev.preventDefault();
      ev.stopPropagation();
      return;
    }
  }
}

/**
 * QuestionType = 14
 * Attachment Type
 */
@Component({
  styleUrls: ['form.questions.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <section>
      <sf-base-question [data]="attachmentQuestionData"></sf-base-question>
      <div class="question-type-attachment">
        <div class="question-title">
          {{ 'form.attachment.title' | translate }}
          <span class="amount-error" *ngIf="fileAmountError">{{ 'form.attachment.size.error' | translate }}</span>
        </div>
        <div class="question-type-attachment-content">
          <div class="item add-file">
            <mat-icon>add</mat-icon>
            <input [disabled]="fileAmountError" type="file" multiple="multiple" [accept]="acceptFile" (change)="onCreateAttachment($event)" />
          </div>
          <div *ngFor="let attachment of attachmentQuestionAnswer, index as i" class="item file-item">
            <span class="close-btn" (click)="onDeleteAttachment(i)"><mat-icon>close</mat-icon></span>
            <span class="file-icon"><mat-icon>description</mat-icon></span>
            <span class="file-name">{{ attachment.filename }}</span>
          </div>
        </div>
        <mat-error
            class="form-question-error"
            *ngIf="attachmentQuestionData.Required && isEmpty && !fileInvalid"
          >{{ REQUIRED_MSG }}</mat-error>
        <mat-error
            class="form-question-error"
            *ngIf="fileInvalid"
          >{{ 'form.attachment.type.error' | translate }}</mat-error>
      </div>
    </section>
  `
})
export class AttachmentQuestionComponent extends BaseQuestionComponent implements OnInit, OnDestroy
{
  public attachmentQuestionData: IQuestionData;
  public isEmpty: boolean = false;

  public attachmentQuestionAnswer: Document[] = [];
  private attachmentQuestionSubscription: Subscription;

  public acceptFile = ALLOW_ATTACHMENT_FILE_TYPE.join(", ");
  public fileAmountError = false;
  public fileInvalid: boolean = false;

  constructor(
    private dialogRef: MatDialog,
  )
  {
    super();
  }

  ngOnInit()
  {
    this.setTranslateText();
    this.preInit();

    this.attachmentQuestionSubscription = this.questionStatusUpdate((status: ReplyQuestionStatusEnum) =>
    {
      this.resetDefaultStatus(status);
      this.validation();
    });

    this.cacheAnswer();
  }

  ngOnDestroy()
  {
    this.attachmentQuestionSubscription && this.attachmentQuestionSubscription.unsubscribe();
    this.dialogRef && this.dialogRef.closeAll();
  }

  onCreateAttachment(event: any)
  {
    const files: Array<File> = event && event.target && event.target.files;
    _.forEach(files, ((file) =>
    {
      const document: Document = {
        name: file.name,
        filename: file.name,
        mimeType: file.type,
        size: file.size,
        documentStorage: [],
      };

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = async () =>
      {
        const dataUrl = reader.result as string;
        const encodedBytes = dataUrl.split(",")[1] || "";
        const previewImage = await generatePreviewImage(document.mimeType, encodedBytes);
        const previewEncodedBytes = previewImage.split(",")[1] || "";

        document.previewMimeType = 'image/png';
        document.previewEncodedBytes = previewEncodedBytes;
        document.documentStorage.push({ encodedBytes });

        const attachments: Document[] = [...(this.attachmentQuestionAnswer || [])];
        const existIndex = attachments.findIndex((attachment, index) =>
        {
          if (file.type.includes("image/") && file.name.includes("image.jpg"))
          {
            const _fileName = `image_${index}_$.jpg`;
            document.name = _fileName;
            document.filename = _fileName;
            return false;
          }

          return attachment.filename === document.filename && attachment.mimeType === document.mimeType;
        });

        if (existIndex >= 0)
        {
          attachments[existIndex] = document;
        } else
        {
          attachments.push(document);
        }

        this.updateAttachmentQuestion(attachments);
      };
    }));

    // must clear the input value after select file
    event.target.value = "";
  }

  onDeleteAttachment(index: number)
  {
    const attachments = [...this.attachmentQuestionAnswer];
    attachments.splice(index, 1);
    this.updateAttachmentQuestion(attachments);
  }

  private preInit()
  {
    this.attachmentQuestionData = _.merge(this.data, {
      defaultVal: ``,
    });

    if (this.cacheResult)
    {
      this.attachmentQuestionData = _.merge(this.attachmentQuestionData, {
        defaultVal: this.cacheResult.value
      });

      this.attachmentQuestionAnswer = this.cacheResult.value as Document[];
      this.cacheQuestionResult();
    }
  }

  private updateAttachmentQuestion(attachments: Document[])
  {
    let _attachments = [...attachments];
    if (attachments.length >= MAX_ATTACHMENT_AMOUNT)
    {
      this.fileAmountError = true;
      _attachments = _attachments.slice(0, MAX_ATTACHMENT_AMOUNT);
    } else
    {
      this.fileAmountError = false;
    }

    this.attachmentQuestionAnswer = _attachments;
    this.fileInvalid = this.checkFileSizeAndType(_attachments);
    this.isEmpty = (this.data.Required && this.isEmptyValidation(this.attachmentQuestionAnswer)) || this.fileInvalid;
    this.questionValid();
    this.cacheQuestionResult();
  }

  private cacheQuestionResult()
  {
    this.questionResult = { value: this.attachmentQuestionAnswer };
    this.cacheAnswer();
  }

  private validation = () => this.updateAttachmentQuestion(this.attachmentQuestionAnswer);

  private questionValid = () => this.setQuestionValidMark(this.data.Required, this.isEmpty);

  private checkFileSizeAndType(files: Document[]): boolean
  {
    const types = ALLOW_ATTACHMENT_FILE_TYPE.join(",").replace(/\*/g, '');
    let size = 0;
    let typeError = false;
    _.forEach(files, (file: Document) =>
    {
      if (types.indexOf(file.name.split('.').pop().toLowerCase()) === -1
        || !file.mimeType)
      {
        typeError = true;
        return false;
      }

      size += file.size;
    });

    return typeError || size > MAX_ATTACHMENT_AMOUNT * MAX_SINGLE_ATTACHMENT_SIZE;
  }
}


/**
 * QuestionType = 15
 * Signature Type
 */
@Component({
  styleUrls: ['form.questions.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <section>
      <sf-base-question [data]="signatureQuestionData"></sf-base-question>
      <div class="question-type-signature">
        <div class="question-type-signature-content">
          <div class="question-title">{{ 'form.signature.title' | translate }}:</div>
          <div (click)="openSignaturePad()" class="question-type-signature-button">
            <span *ngIf="!signatureQuestionAnswer">{{ 'form.signature.click.desc' | translate }}</span>
            <img *ngIf="signatureQuestionAnswer" src="{{signatureQuestionAnswer}}" alt="" />
          </div>
        </div>
        <mat-error
            class="form-question-error"
            *ngIf="signatureQuestionData.Required && isEmpty"
          >{{ REQUIRED_MSG }}</mat-error>
      </div>
    </section>
  `
})
export class SignatureQuestionComponent extends BaseQuestionComponent implements OnInit, OnDestroy
{
  public signatureQuestionData: IQuestionData;
  public isEmpty: boolean = false;

  public signatureQuestionAnswer: string;
  private signatureQuestionSubscription: Subscription;

  constructor(
    private dialogRef: MatDialog,
  )
  {
    super();
  }

  ngOnInit()
  {
    this.setTranslateText();
    this.preInit();

    this.signatureQuestionSubscription = this.questionStatusUpdate((status: ReplyQuestionStatusEnum) =>
    {
      this.resetDefaultStatus(status);
      this.validation();
    });

    this.cacheAnswer();
  }

  ngOnDestroy()
  {
    this.signatureQuestionSubscription && this.signatureQuestionSubscription.unsubscribe();
    this.dialogRef && this.dialogRef.closeAll();
  }

  openSignaturePad()
  {
    this.dialogRef && this.dialogRef.closeAll();
    this.dialogRef.open(SignaturePadDialogComponent, {
      data: {
        saveAction: this.updateSignatureQuestion.bind(this),
        defaultValue: this.signatureQuestionAnswer
      }
    });
  }

  private preInit()
  {
    this.signatureQuestionData = _.merge(this.data, {
      defaultVal: ``,
    });

    if (this.cacheResult)
    {
      this.signatureQuestionData = _.merge(this.signatureQuestionData, {
        defaultVal: this.cacheResult.value
      });

      this.signatureQuestionAnswer = `${this.cacheResult.value}`;
      this.cacheQuestionResult();
    }
  }

  private updateSignatureQuestion(signature: string)
  {
    this.signatureQuestionAnswer = signature;
    this.isEmpty = this.data.Required && this.isEmptyValidation(this.signatureQuestionAnswer);
    this.questionValid();
    this.cacheQuestionResult();
  }

  private cacheQuestionResult()
  {
    this.questionResult = { value: this.signatureQuestionAnswer };
    this.cacheAnswer();
  }

  private validation = () => this.updateSignatureQuestion(this.signatureQuestionAnswer);

  private questionValid = () => this.setQuestionValidMark(this.data.Required, this.isEmpty);
}

