import 'hammerjs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import
{
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent, SettingsBottomSheetComponent } from './app.component';
import { MaterialModule } from './shared/material/material.module';
import { HeaderModule } from './shared/layout/header.module';
import { FooterModule } from './shared/layout/footer.module';
import { SmallHeaderModule } from './shared/layout/small-header.module';
import { ToastModule } from './shared/toast';
import { FormQuestionModule } from './form/form.module';
import { SharedModule } from './shared/shared.module';

import { LoginComponent } from './login/login.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { StudentScheduleComponent, StudentActionsBottomSheetComponent } from './schedule/student-schedule/student-schedule.component';
import { StudentSchedulesComponent } from './schedule/student-schedules/student-schedules.component';
import { SettingsComponent, DialogOverviewExampleDialog } from './settings/settings.component';

import { ApiInterceptor } from './http-interceptor.service';
import { StopfinderApiService } from './shared/stopfinder/stopfinder-api.service';
import { AppService } from './app.service';
import { AuthGuard } from './auth.guard';
import { ScheduleService } from './schedule/schedule.service';
import { MessageControlComponent } from './shared/message-control/message-control.component';
import { MessagesComponent } from './messages/messages.component';
import { ViewMessageComponent } from './messages/view/view-message.component';
import { MessagesService } from './messages/messages.service';
import { LocalStorageService } from './shared/local-storage/local-storage.service';
import { ActivationComponent } from './activation/activation.component';
import { MapToolsModule } from './map/map-tools/map-tools.module';
import { EsriMapComponent } from './map/esri-map/esri-map.component';
import { SfDatePickerComponent } from './schedule/sf-date-picker/sf-date-picker.component';
import { MapHeaderComponent } from './map/map-header/map-header.component';
import { ScrollingCalendarComponent } from './scrolling-calendar/scrolling-calendar.component';
import { MessageDetailComponent, AddAttachmentBottomSheetComponent } from './messages/message-detail/message-detail.component';
import { ShareSubscriptionComponent } from './schedule/share-subscription/share-subscription.component';
import { PushSnackbarComponent } from './messages/push-snackbar/push-snackbar.component';
import { CompassComponent } from './map/compass/compass.component';
import { SubscriberCardComponent, SubscriberCardBottomSheet } from './settings/manage-subscriptions/subscriber-card/subscriber-card.component';
import { ManageSubscriptionsComponent } from './settings/manage-subscriptions/manage-subscriptions.component';
import { ManageSubscriptionService } from './settings/manage-subscriptions/manage-subscriptions.service';
import { ProfileComponent } from './settings/profile/profile.component';
import { ChangePasswordComponent } from './settings/profile/change-password/change-password.component';
import { DownloadappComponent } from './activation/downloadapp/downloadapp.component';
import { AppFeedbackComponent } from './messages/app-feedback/app-feedback.component';
import { GeoAlertsHistoryComponent } from './map/geo/geo-history/geo-alerts-history.component';
import { TooltipComponent } from './shared/tooltip/tooltip.component';
import { StepFlowComponent } from './shared/step-flow/step-flow.component';
import { AttendanceHistoryComponent } from './attendance/attendance-history/attendance-history.component';

// Geo Alert
import * as GeoAlert from './map/geo/export.geo.components';
// Tutorial
import * as Tutorial from './tutorial/tutorial.export';


// Service
import { StateService } from './components/service/state/state.service';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { MapService } from 'src/app/components/service/map/map.service';
import { PathLineService } from 'src/app/components/service/path-line/path-line.service';
import { SignalRService } from 'src/app/components/service/signalr/signalr.service';
import { AndroidBackService } from './androidBack.service';
import { TooltipService } from './components/service/tooltip/tooltip.service';
import { CompassService } from './map/compass/compass.service';
import { RouterInterceptService } from './components/service/router-intercept/router-intercept.service';
import { MapGeoAlertService } from './components/service/map/map-geoalert.service';
import { MapAttendanceService } from './components/service/map/map-attendance.service';
import { MapVehicleService } from './components/service/map/map-vehicle.service';
import { AttendanceService } from './components/service/attendance/attendance.service';
import { MapGraphicsService } from './components/service/map/map-graphics.service';
import { DeviceService } from './components/service/device/device.service';
import { MapCoreService } from './components/service/map/map-core.service';
import { AppHelpComponent } from './shared/app-help/app-help.component';
import { StopfinderDateTimeService } from './shared/stopfinder/stopfinder-datetime.service';
import { SignalRWrapperService } from './components/service/signalr/signalr-wrapper.service';
import { VehicleLocationService } from './shared/vehicle-location.service';
import { ETAAlertService } from './shared/services/eta-alert.service';

// required for AOT compilation
export const HttpLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ScheduleComponent,
    StudentScheduleComponent,
    StudentSchedulesComponent,
    SettingsComponent,
    DialogOverviewExampleDialog,
    MessageControlComponent,
    MessagesComponent,
    ViewMessageComponent,
    ActivationComponent,
    EsriMapComponent,
    SfDatePickerComponent,
    MapHeaderComponent,
    ScrollingCalendarComponent,
    AddAttachmentBottomSheetComponent,
    MessageDetailComponent,
    StudentActionsBottomSheetComponent,
    ShareSubscriptionComponent,
    PushSnackbarComponent,
    CompassComponent,
    GeoAlert.GeoComponent,
    GeoAlert.GeoActionSheetComponent,
    GeoAlert.AddGeoBottomSheetComponent,
    GeoAlert.ViewGeoComponent,
    GeoAlert.EtaAlertComponent,
    GeoAlert.EtaAlertsComponent,
    SettingsBottomSheetComponent,
    SubscriberCardComponent,
    ManageSubscriptionsComponent,
    SubscriberCardBottomSheet,
    ProfileComponent,
    ChangePasswordComponent,
    DownloadappComponent,
    AppFeedbackComponent,
    ForgotPasswordComponent,
    GeoAlertsHistoryComponent,
    TooltipComponent,
    StepFlowComponent,
    AttendanceHistoryComponent,
    Tutorial.TutorialComponent,
    Tutorial.TutorialListComponent,
    AppHelpComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HeaderModule,
    FooterModule,
    SmallHeaderModule,
    MaterialModule,
    AppRoutingModule,
    MapToolsModule,
    FlexLayoutModule,
    ToastModule.forRoot(),
    FormQuestionModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      useDefaultLang: true,
      defaultLanguage: 'en'
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    AuthGuard,
    StopfinderApiService,
    AppService,
    ScheduleService,
    MessagesService,
    LocalStorageService,
    ManageSubscriptionService,
    StateService,
    MapService,
    PathLineService,
    SignalRService,
    AndroidBackService,
    TooltipService,
    CompassService,
    Tutorial.TutorialService,
    RouterInterceptService,
    MapGeoAlertService,
    MapAttendanceService,
    MapVehicleService,
    AttendanceService,
    MapGraphicsService,
    DeviceService,
    MapCoreService,
    StopfinderDateTimeService,
    SignalRWrapperService,
    VehicleLocationService,
    ETAAlertService,
  ],
  entryComponents: [
    AddAttachmentBottomSheetComponent,
    StudentActionsBottomSheetComponent,
    ShareSubscriptionComponent,
    AppFeedbackComponent,
    PushSnackbarComponent,
    SettingsBottomSheetComponent,
    GeoAlert.GeoActionSheetComponent,
    GeoAlert.AddGeoBottomSheetComponent,
    GeoAlert.ViewGeoComponent,
    GeoAlert.EtaAlertComponent,
    GeoAlert.EtaAlertsComponent,
    SubscriberCardBottomSheet,
    ChangePasswordComponent,
    GeoAlertsHistoryComponent,
    Tutorial.TutorialComponent,
    Tutorial.TutorialListComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
