import * as _ from 'lodash';
import { OnInit, Input, Component, Inject, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from '../../../shared/stopfinder/models/subscription';
import { TargetedBlockingScrollStrategy } from './../../../shared/material/targeted-blocking-scroll-strategy';
import { MatBottomSheet, MAT_BOTTOM_SHEET_DATA, MatDialog, MatBottomSheetRef, MatDialogRef } from '@angular/material';
import { ManageSubscriptionService } from '../manage-subscriptions.service';
import { ConfirmationDialogComponent } from '../../../shared/layout/confirmation-dialog/confirmation-dialog.component';
import { AppService } from '../../../app.service';
import { StateService } from '../../../components/service/state/state.service';

@Component({
  selector: 'app-subscriber-card',
  templateUrl: './subscriber-card.component.html',
  styleUrls: ['./subscriber-card.component.scss']
})
export class SubscriberCardComponent implements OnInit, AfterViewInit
{
  @Input() subscription: Subscription;
  constructor(private readonly bottomSheet: MatBottomSheet)
  {
  }

  ngOnInit()
  {
  }

  ngAfterViewInit()
  {
  }

  openBottomSheet(subscription)
  {
    //  add the setTimeout to prevent request not complete
    setTimeout(() =>
    {
      this.bottomSheet.open(SubscriberCardBottomSheet, { data: { subscription: subscription }, scrollStrategy: new TargetedBlockingScrollStrategy() });
    })
  }

}

interface IDialogConfig
{
  disableClose: boolean | true,
  data: {
    title: string | 'title',
    message: string | 'message',
    action: string | 'Action',
    secondary: boolean | false,
    secondaryAction: string | 'Cancel'
  }
}

@Component({
  selector: 'subscriber-card-sheet',
  template: `
  <div style="text-align: center">
    <h3>{{ subscription.subscriberName }}</h3>
  </div>
  <mat-nav-list>
    <a mat-list-item (click)="editSubscription()">
      <mat-icon matListIcon>edit</mat-icon>
      <span mat-line>{{ 'subscription.manage.edit' | translate }}</span>
      <span mat-line>{{ 'subscription.manage.edit.subtitle' | translate }}</span>
    </a>
    <a mat-list-item (click)="disableAccess()" *ngIf="subscription.active">
      <mat-icon matListIcon>cancel</mat-icon>
      <span mat-line>{{ 'subscription.manage.disable' | translate }}</span>
      <span mat-line>{{ 'subscription.manage.disable.subtitle' | translate }}</span>
    </a>
    <a mat-list-item (click)="enableAccess()" *ngIf="!subscription.active">
      <mat-icon matListIcon>check</mat-icon>
      <span mat-line>{{ 'subscription.manage.enable' | translate }}</span>
      <span mat-line>{{ 'subscription.manage.enable.subtitle' | translate }}</span>
    </a>
  </mat-nav-list>`,
})
export class SubscriberCardBottomSheet
{
  subscription: Subscription;

  private questionTemplate: Function;
  private selfLocalization: any;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private readonly manageSubs: ManageSubscriptionService,
    private readonly dialog: MatDialog,
    private readonly bottomSheetRef: MatBottomSheetRef<SubscriberCardBottomSheet>,
    private appService: AppService,
    private stateService: StateService,
    public translate: TranslateService,
  )
  {
    this.subscription = this.data.subscription;
    // declare template format RegExp
    _.templateSettings.interpolate = /{{([\s\S]+?)}}/g;
    // declare question
    this.translate.stream('subscription.manage').subscribe((res: string) =>
    {
      this.questionTemplate = _.template(res['question']);
      this.selfLocalization = res;
    });
  }

  private openDialog(config: IDialogConfig): MatDialogRef<any>
  {
    return this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      data: {
        title: config.data.title,
        message: config.data.message,
        action: config.data.action,
        secondary: true,
        secondaryAction: `${this.selfLocalization['secondary']}`
      },
      scrollStrategy: new TargetedBlockingScrollStrategy(),
      panelClass: "confirm-dialog"
    });
  }

  enableAccess()
  {
    this.bottomSheetRef.dismiss();
    this.bottomSheetRef.afterDismissed().subscribe(() =>
    {
      this.openDialog({
        data: {
          title: `${this.selfLocalization['enable']}`,
          message: this.questionTemplate({
            'action': `${this.selfLocalization['enable.only']}`,
            'user': this.subscription.subscriberName
          }),
          action: `${this.selfLocalization['action']}`,
        }
      } as IDialogConfig).afterClosed().subscribe((val) =>
      {
        if (val)
        {
          this.manageSubs.patchAccess(this.subscription.subscriptionRangeId,
            [{
              op: 'replace',
              path: 'active',
              value: true,
            }]);
        }
      });
    });
  }

  disableAccess()
  {
    this.bottomSheetRef.dismiss();
    this.bottomSheetRef.afterDismissed().subscribe(() =>
    {
      this.openDialog({
        data: {
          title: `${this.selfLocalization['disable']}`,
          message: this.questionTemplate({
            'action': `${this.selfLocalization['disable.only']}`,
            'user': this.subscription.subscriberName
          }),
          action: `${this.selfLocalization['disable.action']}`,
        }
      } as IDialogConfig).afterClosed().subscribe((val) =>
      {
        if (val)
        {
          this.manageSubs.patchAccess(this.subscription.subscriptionRangeId,
            [{
              op: 'replace',
              path: 'active',
              value: false
            }]);
        }
      });
    })

  }

  editSubscription()
  {
    this.appService.currentSubscription = this.subscription;
    this.stateService.goRoute('editsubscription');
  }
}
