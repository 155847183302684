import * as _ from 'lodash';
import { Injectable, NgZone } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
	providedIn: 'root',
})
export class CompassService
{
	private _rotateAngle: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	public rotateAngleObserable: Observable<number> = this._rotateAngle.asObservable();

	public rotateAngleTransform: {} = { 'transform': `rotate(0)` };
	public rotateAngleStyleObserable: Observable<number> = this._rotateAngle.asObservable();

	constructor(
		private readonly ngZone: NgZone,
	)
	{
		this.rotateAngleStyleObserable.subscribe((angleValue: number) =>
		{
			this.rotateAngleTransform = { 'transform': `rotate(${360 - angleValue}deg)` };
		});
	}

	public updateRotate = (angle: number) => this.ngZone.run(() => this._rotateAngle.next(angle));

	public resetRotateDefault = () => this.ngZone.run(() => this._rotateAngle.next(0));

	public getRotateAngle = (): number => this._rotateAngle.value || 0;

	public isRotateChanged = (): boolean => _.isEqual(this._rotateAngle.value, 0);
}
