import * as _ from 'lodash';
import { MessagesService } from './messages.service';
import { Communication, CommunicationType, CommunicationFilter } from '../shared/stopfinder/stopfinder-models';
import
{
  OnInit,
  OnDestroy,
  Component,
  ChangeDetectionStrategy,
  NgZone,
  AfterViewInit,
  ViewChild,
  ElementRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import
{
  MatDialog
} from '@angular/material';
import * as moment from 'moment';
import { tap, debounceTime, skip } from 'rxjs/operators';
import
{
  BehaviorSubject,
  Observable,
  Subscription
} from 'rxjs';

import * as Hammer from 'hammerjs';
import { AppService } from '../app.service';
import { StateService } from '../components/service/state/state.service';
import { IGNORE_LTR } from 'src/app/components/directive/swipe/swipe.directive';
import { IndicatorService } from '../shared/refresh/indicator.service';
import { TranslateService } from '@ngx-translate/core';
import { FormService } from 'src/app/components/service/form/form.service';
import { ConfirmationDialogComponent } from '../shared/layout/confirmation-dialog/confirmation-dialog.component';
import { TargetedBlockingScrollStrategy } from '../shared/material/targeted-blocking-scroll-strategy';
import { DeviceService } from '../components/service/device/device.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

export interface Point
{
  x: number;
  y: number;
}

const MAX_DRAG_DEFLECTION = 100;
const DRAG_BUTTON_WIDTH = 84;
const DRAG_LOCK_DEFLECTION = 100;
const DIRECTION_DISTANCE_THRESHOLD = 8;
const FLICK_LOCK_DISTANCE_THRESHOLD = 20;
const RIGHT_DRAG_BUTTON_COUNT = 2;
const RIGHT_BUTTON_LOCK_OVERLAP_DISTANCE = (200 - 188) / RIGHT_DRAG_BUTTON_COUNT;
const PULL_DOWN_TO_REFRESH_THRESHOLD = .20;
const DEBOUNCE_TIME = 1000;
const SNACK_DURATION = 4000;
const VIBRATION_DURATION = 500;

const SEARCH_DEBOUNCE_MS = 300;

@Component({
  selector: 'sf-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessagesComponent implements OnInit, OnDestroy, AfterViewInit
{
  public swipeButtonsCount = 2;
  public dragButtonsLeft = [];

  public dragButtonsRight = [
    { label: 'button 2', matColor: 'warn' },
    { label: 'button 3', matColor: 'accent' }
  ];

  public dragPositions = {};
  public gestureInitialDirection: number = Hammer.DIRECTION_NONE;
  public currentDragTarget = null;
  public moment = moment;
  public requestedMessageId = -1;
  public localMessageObservable = null;
  public isEmptyMessageThread = false;
  public clearSearchCriteria = false;
  public localSearchCriteria = '';
  public keyboardEventSubject: BehaviorSubject<any> = new BehaviorSubject<any>('');
  public keyboardEventObservable: Observable<any> = this.keyboardEventSubject.asObservable();
  public keyboardEventSubscription: Subscription;
  public hammerTime;
  public startY = 0;
  public refreshSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public snackBarOpened = false;
  private triggerRefresh = false;
  public filterEnum = CommunicationFilter;
  private isSwipeMenuOpened = false;
  private gettingMessage = false;
  private readonly isAndroidDevice: boolean = _.get(window, 'device') && _.isEqual(window['device'].platform.toLowerCase(), 'android');
  private manualPullDown = false;
  private notScrollMessage = false;
  @ViewChild('mainContentArea', { static: true }) mainContentArea: ElementRef;
  @ViewChild('messageScrolling', { static: false }) messageScrolling: CdkVirtualScrollViewport;

  constructor(
    private router: Router,
    public messageService: MessagesService,
    public dialog: MatDialog,
    private readonly activatedRoute: ActivatedRoute,
    public zone: NgZone,
    public _appService: AppService,
    private stateService: StateService,
    private indicatorService: IndicatorService,
    public translate: TranslateService,
    private formService: FormService,
    public readonly deviceService: DeviceService,
  )
  {
    this.activatedRoute.params.subscribe(params =>
    {
      this.requestedMessageId = +params['id'] || -1;
      this.loading();
      this.gettingMessage = true;
      this.messageService.getCommunications(() =>
      {
        this.gettingMessage = false;
        this.indicatorService.close();
      });
    });

    this.localMessageObservable = this.messageService.sortedFilteredCommunicationsObservable.pipe(
      tap(messages =>
      {
        this.resetDragLocks('');
        // This checks if there is a route param and then covers the initial
        // setup of the behavior subject which emits []
        this.isEmptyMessageThread = _.isEmpty(messages);

        if (messages && this.requestedMessageId !== -1)
        {
          const foundMessage = messages.find(
            message => message.id == this.requestedMessageId
          );
          if (foundMessage)
          {
            this.openMessage(foundMessage);
            this.requestedMessageId = -1;
          }
        }
        if (!this.gettingMessage)
        {
          navigator.vibrate(VIBRATION_DURATION);
          this.indicatorService.close();
        }

        if (!this.notScrollMessage)
        {
          if (messages && this.messageService.lastScrollLocation > 0)
          {
            this.scrollToPrevReadLocation();
          }
          else
          {
            this.scrollToTopNoAnimation();
          }
        }
      })
    );

    this.keyboardEventSubscription = this.keyboardEventObservable
      .pipe(debounceTime(SEARCH_DEBOUNCE_MS))
      .subscribe(event =>
      {
        if (!event)
        {
          return;
        }

        if (this.localSearchCriteria.length > 2 || event.keyCode === 13)
        {
          this.loading();
          this.messageService.searchCriteria = this.localSearchCriteria;
          this.messageService.getCommunications(() =>
          {
            this.indicatorService.close();
            this.snackBarOpened = false;
          });
        }
        else
        {
          this.messageService.removeSearchFilter();
        }
      });

    // open manual pull down effect for Android device since it doesn't support over scroll effect
    this.manualPullDown = this.isAndroidDevice;

    if (this.messageService.searchCriteria)
    {
      this.localSearchCriteria = this.messageService.searchCriteria;
      this.clearSearchCriteria = true;
    }
  }

  loading()
  {
    this.indicatorService.show(false);
  }

  keyUp(event)
  {
    this.clearScrollLocation();
    this.clearSearchCriteria = this.localSearchCriteria.length > 0;
    this.keyboardEventSubject.next(event);
  }

  ngOnInit() { }

  ngOnDestroy()
  {
    this.keyboardEventSubscription.unsubscribe();
    this.indicatorService.close();
    this.hammerTime && this.hammerTime.destroy();
    this.hammerTime = null;
  }

  ngAfterViewInit()
  {
    this.initializeHammer();
    this.zone.runOutsideAngular(() =>
    {
      const activeSlide = document.getElementById('main-content-area');

      activeSlide && activeSlide.addEventListener('touchstart', (e) =>
      {
        this.startY = e.touches[0].pageY;
        this.triggerRefresh = false;

        if (activeSlide.scrollHeight <= activeSlide.clientHeight)
        {
          this.manualPullDown = true;
        }
      }, { passive: true });

      activeSlide && activeSlide.addEventListener('touchmove', (e) =>
      {
        const y = e.touches[0].pageY;
        const scrollTop = this._getScrollTop();

        if (scrollTop <= 0 && y > this.startY)
        {
          const movedValue = Math.abs(y - this.startY);
          // should manually drag down the component
          if (this.manualPullDown && movedValue <= window.innerHeight * PULL_DOWN_TO_REFRESH_THRESHOLD)
          {
            activeSlide.style.transform = `translateY(${(y - this.startY) * 0.5}px)`;
          }

          if (movedValue > window.innerHeight * PULL_DOWN_TO_REFRESH_THRESHOLD)
          {
            this.clearScrollLocation();
            this.triggerRefresh = true;
            return;
          }
        }
        this.triggerRefresh = false;
      }, { passive: true });

      activeSlide && activeSlide.addEventListener('touchend', (e) =>
      {
        this.zone.run(() =>
        {
          if (this.manualPullDown)
          {
            activeSlide.style.transform = `translateY(0px)`;
            this.manualPullDown = this.isAndroidDevice;
          }

          if (!this.snackBarOpened && this.triggerRefresh)
          {
            this.loading();

            this.refreshSubject.next(true);
          }
        });
      });
    });

    this.refreshSubject.asObservable().pipe(debounceTime(DEBOUNCE_TIME), skip(1)).subscribe(() =>
    {
      this.messageService.getCommunications(() =>
      {
        this.snackBarOpened = false;
        this.indicatorService.close();
      });
    });

    this.messageScrolling.elementScrolled().subscribe(() =>
    {
      Object.keys(this.dragPositions).length > 0 && this.resetDragLocks("");
    })
  }

  back()
  {
    this.stateService.goRoute('schedule');
  }

  scrollToTop()
  {
    let scrollTop = this._getScrollTop();
    let timer = window.setInterval(() =>
    {
      if (scrollTop === this._getScrollTop())
      {
        window.clearInterval(timer);
        this._scrollToTop();
      }
      else
      {
        scrollTop = this._getScrollTop();
      }
    }, 50);
  }

  clickMessage(message: Communication, messageIndex?: number)
  {
    if (this.isSwipeMenuOpened)
    {
      this.isSwipeMenuOpened = false;
      return;
    }
    this.openMessage(message, messageIndex);
  }

  openMessage(message: Communication, messageIndex?: number)
  {
    // set the last click message virtual scroll index
    this.setLastScrollLocation(messageIndex);

    if (!message.read && message.type !== CommunicationType.Form)
    {
      this.messageService.markCommunicationReadStatus(message, true);
      message.read = true;
    }
    this.messageService.selectedCommunication = message;
    this._appService.lastLocation = 'messages';
    if (message.type === CommunicationType.Form)
    {
      if (message.completed != null)
      {
        this.formService.communication = message;
        this.router.navigate([`formAnswer/${message.id}/${message.formRecipientId}`]);
      }
      else
      {
        const isFormSentExpired = this.messageService.checkFormSentExpired(message);
        if (isFormSentExpired)
        {
          this.dialog.open(ConfirmationDialogComponent, {
            disableClose: true,
            data: {
              title: this.translate.instant('form.dialog.unavailable.title'),
              message: this.translate.instant('form.dialog.unavailable.message'),
              action: this.translate.instant('form.dialog.action'),
              secondary: false,
            },
            scrollStrategy: new TargetedBlockingScrollStrategy(),
            panelClass: "confirm-dialog"
          }).afterClosed().subscribe(result =>
          {
            this.messageService.removeFormDataFromCommunications(message.id, message.type, message.formRecipientId);
          });
        }
        else
        {
          this.router.navigate([`formQuestion/${message.id}/${message.formRecipientId}`]);
        }
      }
    }
    else
    {
      this.stateService.goRoute('message-detail');
    }
  }

  scrollToPrevReadLocation()
  {
    this.messageScrolling && this.messageScrolling.scrollToIndex(this.messageService.lastScrollLocation);
  }

  scrollToTopNoAnimation()
  {
    this.messageScrolling && this.messageScrolling.scrollToIndex(0);
  }

  setLastScrollLocation(scrollIndex: number = 0)
  {
    this.notScrollMessage = false;
    this.messageService.lastScrollLocation = scrollIndex;
  }

  assignFilterForMessages(event, menuItemClicked)
  {
    switch (menuItemClicked)
    {
      case CommunicationFilter.Default:
        this.messageService.selectedFilter = CommunicationFilter.Default;
        this.messageService.filterCollection.forEach(filter =>
        {
          filter.active = _.isEqual(filter.filterApplied, CommunicationFilter.Default);
        });
        break;
      case CommunicationFilter.UnRead:
        this.messageService.selectedFilter = CommunicationFilter.UnRead;
        this.messageService.filterCollection.forEach(filter =>
        {
          filter.active = _.isEqual(filter.filterApplied, CommunicationFilter.UnRead);
        });
        break;
      case CommunicationFilter.Archived:
        this.messageService.selectedFilter = CommunicationFilter.Archived;
        this.messageService.filterCollection.forEach(filter =>
        {
          filter.active = _.isEqual(filter.filterApplied, CommunicationFilter.Archived);
        });
        break;
      case CommunicationFilter.Messages:
        this.messageService.selectedFilter = CommunicationFilter.Messages;
        this.messageService.filterCollection.forEach(filter =>
        {
          filter.active = _.isEqual(filter.filterApplied, CommunicationFilter.Messages);
        });
        break;
      case CommunicationFilter.Announcements:
        this.messageService.selectedFilter = CommunicationFilter.Announcements;
        this.messageService.filterCollection.forEach(filter =>
        {
          filter.active = _.isEqual(filter.filterApplied, CommunicationFilter.Announcements);
        });
        break;
      case CommunicationFilter.Forms:
        this.messageService.selectedFilter = CommunicationFilter.Forms;
        this.messageService.filterCollection.forEach(filter =>
        {
          filter.active = _.isEqual(filter.filterApplied, CommunicationFilter.Forms);
        });
        break;
    }
    this.clearScrollLocation();
    this.loading();
    this.messageService.getCommunications(() =>
    {
      this.snackBarOpened = false;
      this.indicatorService.close();
    });
  }

  clearSearch()
  {
    this.clearSearchCriteria = false;
    this.localSearchCriteria = '';
    this.clearScrollLocation();
    this.messageService.removeSearchFilter();
  }

  clearFilter()
  {
    this.messageService.filterCollection.forEach(filter =>
    {
      filter.active = false;
    });
    this.messageService.selectedFilter = CommunicationFilter.Default;
    this.clearScrollLocation();
    this.loading();

    this.messageService.getCommunications(() =>
    {
      this.snackBarOpened = false;
      this.indicatorService.close();
    });
  }

  resetDragLocks(excludeId: string)
  {
    for (const id of Object.keys(this.dragPositions))
    {
      if (id != excludeId)
      {
        const messageItem = document.querySelector(`#${id}`) as HTMLElement;
        const messageIndex = id.replace('message', '');
        this.resizeDragExposeButtons(messageIndex, 0);
        delete this.dragPositions[id];
        messageItem && (messageItem.style.transform = `translateX(0)`);
      }
    }
  }

  getCurrentDragAbsoluteX(deltaX): number
  {
    if (this.currentDragTarget)
    {
      const passiveX = this.dragPositions[this.currentDragTarget.id];
      let absoluteX = deltaX + passiveX;
      // constrain deflection
      if (absoluteX > 0)
      {
        absoluteX = 0; // disallow dragging right
      }
      else if (absoluteX < -MAX_DRAG_DEFLECTION * this.swipeButtonsCount)
      {
        absoluteX = -MAX_DRAG_DEFLECTION * this.swipeButtonsCount;
      }

      return absoluteX;
    }
    else
    {
      return 0;
    }
  }

  initializeHammer()
  {
    this.zone.runOutsideAngular(() =>
    {
      const container = document.getElementById('main-content-area');
      const hammerOpts: any = {
        recognizers: [[Hammer.Pan, { direction: Hammer.DIRECTION_HORIZONTAL }]]
      };

      this.hammerTime = new Hammer.Manager(container, hammerOpts);

      this.hammerTime.on('hammer.input', ev =>
      {
        this.swipeButtonsCount = 2;
        if (
          ev.target.closest('.message-item') &&
          ev.target.closest('.message-item').getAttribute('type') === CommunicationType.Form
        )
        {
          // CONDITION: once status is not completed, archive will be opened
          this.swipeButtonsCount = 1;
        }

        if (ev.isFirst)
        {
          this.gestureStart(ev);
        }
        else if (ev.isFinal)
        {
          this.gestureEnd(ev);
        }
        else
        {
          this.gestureMove(ev);
        }
      });
    });
  }

  gestureStart(e)
  {
    const listItem = e.target.closest('.message-item');
    if (listItem)
    {
      this.resetDragLocks('');
      if (!this.dragPositions[listItem.id])
      {
        this.dragPositions[listItem.id] = 0;
      }
      this.currentDragTarget = listItem;
    }
    else
    {
      this.currentDragTarget = null;
    }
  }

  gestureEnd(e)
  {
    if (
      this.gestureInitialDirection &
      (Hammer.DIRECTION_LEFT | Hammer.DIRECTION_RIGHT)
    )
    {
      if (this.currentDragTarget)
      {
        let absoluteX = this.getCurrentDragAbsoluteX(e.deltaX);

        if (absoluteX > DRAG_LOCK_DEFLECTION * this.swipeButtonsCount)
        {
          absoluteX = DRAG_LOCK_DEFLECTION * this.swipeButtonsCount;
        }
        else if (absoluteX < -DRAG_LOCK_DEFLECTION * this.swipeButtonsCount)
        {
          absoluteX = -DRAG_LOCK_DEFLECTION * this.swipeButtonsCount;
        }
        else if (absoluteX > FLICK_LOCK_DISTANCE_THRESHOLD)
        {
          absoluteX = DRAG_LOCK_DEFLECTION * this.swipeButtonsCount;
        }
        else if (absoluteX < -FLICK_LOCK_DISTANCE_THRESHOLD)
        {
          absoluteX = -DRAG_LOCK_DEFLECTION * this.swipeButtonsCount;
        }
        else
        {
          absoluteX = 0;
        }

        const messageIndex = +(this.currentDragTarget.id as string).replace(
          'message',
          ''
        );

        this.resizeDragExposeButtons(messageIndex, absoluteX);

        this.currentDragTarget.style.transform = `translateX(${absoluteX}px)`;

        this.dragPositions[this.currentDragTarget.id] = absoluteX;

        if (!_.isEqual(absoluteX, 0))
        {
          this.currentDragTarget.classList.add(IGNORE_LTR);
          this.isSwipeMenuOpened = true;
        }
        else
        {
          this.currentDragTarget.classList.remove(IGNORE_LTR);
          this.isSwipeMenuOpened = false;
        }
        this.currentDragTarget = null;
      }
    }

    this.gestureInitialDirection = Hammer.DIRECTION_NONE;
  }

  gestureMove(e)
  {
    if (this.gestureInitialDirection == Hammer.DIRECTION_NONE)
    {
      // no decision on gesture yet made
      if (
        e.distance > DIRECTION_DISTANCE_THRESHOLD &&
        e.direction > Hammer.DIRECTION_NONE
      )
      {
        this.gestureInitialDirection = e.direction;
      }
    }
    else if (
      this.gestureInitialDirection &
      (Hammer.DIRECTION_LEFT | Hammer.DIRECTION_RIGHT)
    )
    {
      if (this.currentDragTarget)
      {
        const absoluteX = this.getCurrentDragAbsoluteX(e.deltaX);

        const messageIndex = +(this.currentDragTarget.id as string).replace(
          'message',
          ''
        );

        this.resizeDragExposeButtons(messageIndex, absoluteX);
        this.currentDragTarget.style.transform = `translateX(${absoluteX}px)`;
      }
    }
  }

  resizeDragExposeButtons(messageIndex, absoluteX)
  {
    const leftButtonWidth = absoluteX / this.dragButtonsLeft.length;
    const rightButtonWidth = absoluteX / this.dragButtonsRight.length;

    this.dragButtonsLeft.forEach((button, buttonIndex) =>
    {
      const elementId = `message${messageIndex}-left-button${buttonIndex}`;
      const buttonElement: HTMLElement = document.querySelector(`#${elementId}`);
      const buttonPosition = buttonIndex * leftButtonWidth;

      buttonElement && (buttonElement.style.transform = `translateX(${buttonPosition}px)`);
    });

    this.dragButtonsRight.forEach((button, buttonIndex) =>
    {
      const elementId = `message${messageIndex}-right-button${buttonIndex}`;
      const buttonElement: HTMLElement = document.querySelector(`#${elementId}`);
      const buttonPosition = (buttonIndex + 1) * rightButtonWidth;

      buttonElement && (buttonElement.style.transform = `translateX(${buttonPosition}px)`);
    });
  }

  getTranslatedValue(value)
  {
    var returnValue = value.toString();
    switch (returnValue)
    {
      case 'Unread':
        returnValue = this.translate.instant("message.unread");
        break;
      case 'Archived':
        returnValue = this.translate.instant("message.archived");
        break;
      case 'default':
        returnValue = this.translate.instant("message.default");
        break;
      case 'Messages':
        returnValue = this.translate.instant("message.messages");
        break;
      case 'Announcements':
        returnValue = this.translate.instant("message.announcements");
        break;
      case 'Forms':
        return this.translate.instant('message.forms');
      default:
        returnValue = '';
        break;
    }
    return returnValue;
  }

  readMessage(message)
  {
    this.notScrollOnActionMessage();
    this.messageService.markCommunicationReadStatus(message, true);
  }

  unreadMessage(message)
  {
    this.notScrollOnActionMessage();
    this.messageService.markCommunicationReadStatus(message, false);
  }

  archiveMessage(message, event)
  {
    this.notScrollOnActionMessage();
    if (message.type === CommunicationType.Form && message.required && !message.completed)
    {
      const currentClickElement = event.currentTarget.parentElement.getElementsByClassName('message-item')[0] as HTMLElement;
      this.dialog.open(ConfirmationDialogComponent, {
        disableClose: true,
        data: {
          title: this.translate.instant('form.dialog.title'),
          message: this.translate.instant('form.dialog.message'),
          action: this.translate.instant('form.dialog.action'),
          secondary: false,
        },
        scrollStrategy: new TargetedBlockingScrollStrategy(),
        panelClass: "confirm-dialog"
      }).afterClosed().subscribe(result =>
      {
        currentClickElement.style.transform = `translateX(0px)`;
      });
    }
    else
    {
      this.messageService.markCommunicationArchiveStatus(message, true);
    }
  }

  unarchiveMessage(message)
  {
    this.notScrollOnActionMessage();
    this.messageService.markCommunicationArchiveStatus(message, false);
  }

  clearScrollLocation()
  {
    this.notScrollMessage = false;
    this.messageService.clearLastScrollLocation();
  }

  notScrollOnActionMessage()
  {
    this.notScrollMessage = true;
    this.isSwipeMenuOpened = false;
  }

  private _getScrollTop(): number
  {
    const targets = document.getElementsByClassName('scroll-strategy') as HTMLCollectionOf<HTMLElement>;
    return targets && targets.length > 0 && targets[0].scrollTop || 0;
  }

  private _scrollToTop()
  {
    const targets = document.getElementsByClassName('scroll-strategy') as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < targets.length; i++)
    {
      targets[i].scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
}
