import { Component, OnDestroy } from "@angular/core";
import { NativeMapView } from "../../tf-map/core/native-mapview";
import { AppService } from "../../app.service";
import { skip } from "rxjs/operators";
import { CompassService } from "./compass.service";

@Component({
  selector: 'app-compass',
  templateUrl: './compass.component.html',
  styleUrls: ['./compass.component.scss']
})
export class CompassComponent implements OnDestroy
{
  constructor(
    public readonly compassService: CompassService,
    private readonly appService: AppService,
  )
  {
    NativeMapView.pluginLoaded.pipe(skip(1)).subscribe((loaded) =>
    {
      this.setUpEvents().then(() =>
      {
        this.setUpRotationWatch();
      });
    });
  }

  compassClicked()
  {
    this.compassService.resetRotateDefault();
    NativeMapView.nativeEsriMapPlugin.setMapRotation(this.appService.mapId, 0, () => { }, () => { });
  }

  ngOnDestroy() { }

  setUpRotationWatch()
  {
    NativeMapView.nativeEsriMapPlugin.doRotateWatch(this.appService.mapId,
      (response: any) =>
      {
        if (response && response.rotationAngle)
        {
          this.compassService.updateRotate(Math.ceil(parseFloat(parseInt(response.rotationAngle).toString())));
        }
      },
      () => { }
    );
  }

  setUpEvents(): Promise<void>
  {
    return new Promise((resolve) =>
    {
      NativeMapView.nativeEsriMapPlugin.startEvents(this.appService.mapId, () => resolve(), () => { });
    });
  }
}
