import { ScrollStrategy} from '@angular/cdk/overlay';

// just add 'scroll-strategy' class to scroll containers
export class TargetedBlockingScrollStrategy implements ScrollStrategy {

    private targets: HTMLCollectionOf<HTMLElement>;

    constructor() {
      this.targets = document.getElementsByClassName('scroll-strategy') as HTMLCollectionOf<HTMLElement>;

    }

    attach() {}

    enable() {
      for (let i = 0; i < this.targets.length; i++) {
        this.targets[i].style.overflowY = 'hidden';
      }
    }

    disable() {
      for (let i = 0; i < this.targets.length; i++) {
        this.targets[i].style.overflowY = 'auto';
      }
    }

  }
