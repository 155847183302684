import * as _ from 'lodash';
import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable, Subject, iif, interval, merge, of } from 'rxjs';
import { IAttendance, StudentSchedule } from 'src/app/shared/stopfinder/stopfinder-models';
import * as moment from 'moment';
import { MapService } from '../map/map.service';
import { AppService } from 'src/app/app.service';
import { StopfinderDateTimeService } from 'src/app/shared/stopfinder/stopfinder-datetime.service';
import { HubEventListeners, SignalRWrapperService } from '../signalr/signalr-wrapper.service';
import { ScheduleService } from '../../../schedule/schedule.service';
import { switchMap, map, catchError, shareReplay, finalize, takeUntil, tap, startWith } from 'rxjs/operators';
import { SCANNED_SIGNALR_KEY } from '../../../shared/utils/constant';
import { ConnectionState } from '../signalr/signalr.service';
import { StateService } from '../state/state.service';
import { StopfinderApiService } from '../../../shared/stopfinder/stopfinder-api.service';
import { DeviceService } from '../device/device.service';

export interface IAttendanceDictionary
{
  [key: string]: IAttendance[];
}

@Injectable()
export class AttendanceService
{
  private _selectStudentSchedule: BehaviorSubject<StudentSchedule> = new BehaviorSubject<StudentSchedule>(null);
  private _selectAttendance: BehaviorSubject<IAttendance> = new BehaviorSubject<IAttendance>(null);
  private _attendanceDictionary: IAttendanceDictionary = {};
  private _networkDictionary: Observable<IAttendance[]>;
  private clear$ = new Subject<void>();
  private _attendanceDictionarySubject: BehaviorSubject<IAttendanceDictionary> = new BehaviorSubject<IAttendanceDictionary>({});
  public attendanceObservable: Observable<IAttendance[]> = this._attendanceDictionarySubject
    .pipe(
      map(() =>
      {
        return Object.values(this._attendanceDictionary).reduce((rv, x) =>
        {
          return rv.concat(x);
        }, []);
      }));

  public hideGeoAlertMap: boolean = false;
  public headerTitle: string = '';

  constructor(
    private readonly dateTimeService: StopfinderDateTimeService,
    private readonly ngZone: NgZone,
    private readonly mapService: MapService,
    public readonly _signalRWrapperService: SignalRWrapperService,
    public readonly _scheduleService: ScheduleService,
    private readonly _stateService: StateService,
    private readonly apiService: StopfinderApiService,
    private readonly _deviceService: DeviceService
  )
  {
  }

  get selectStudentSchedule()
  {
    return this._selectStudentSchedule;
  }

  get selectAttendance()
  {
    return this._selectAttendance;
  }

  updateSelectStudentSchedule = (schedule: StudentSchedule) => { this._selectStudentSchedule.next(schedule) }

  updateSelectAttendance = (attendance: IAttendance) => { this._selectAttendance.next(attendance) }

  clearCache()
  {
    this.updateSelectStudentSchedule(null);
    this.updateSelectAttendance(null);
  }

  public openAttendanceMap(fromSchedule = false,
    appService: AppService,
    data: {
      schedule?: StudentSchedule,
      attendance?: IAttendance | IAttendance[],
    })
  {
    const { schedule, attendance } = data;
    if (!Array.isArray(attendance))
    {
      this.updateSelectAttendance(attendance);
    }

    if (fromSchedule && schedule && attendance)
    {
      const selectAttendance = { ...this.filterAttendance(schedule, attendance as IAttendance[]) };
      this.updateSelectStudentSchedule(schedule);
      selectAttendance.scannedDate = this.calcClientScanDate(selectAttendance.scannedDate);
      this.updateSelectAttendance(selectAttendance);
    }

    this.ngZone.run(() =>
    {
      this.mapService.displayAttendanceMap(this.selectStudentSchedule.value, appService, this.selectAttendance.value);
    });
  }

  public onCloseAttendanceMap(appService: AppService)
  {
    this.mapService.hideGeoAlertMap = false;
    this.mapService.clearMap();
    appService.setMapVisibility(false);
  }

  public calcClientScanDate(scannedDate: string): string
  {
    const timeZoneMinutes = this.mapService.getScheduleTimeZone(this.selectStudentSchedule.value);
    return this.dateTimeService.toUtcDate(scannedDate, timeZoneMinutes).format(this.dateTimeService.formatDate);
  }

  public getClientToday(): moment.Moment
  {
    const timeZoneMinutes = this.mapService.getScheduleTimeZone(this.selectStudentSchedule.value);
    return this.dateTimeService.utcNow(timeZoneMinutes);
  }

  private filterAttendance(schedule: StudentSchedule, attendances: IAttendance[]): IAttendance
  {
    if (!attendances || !schedule)
    {
      return null;
    }

    const { riderId, dataSourceId } = schedule;
    const sortScans = ([].concat(attendances || [])).sort((a, b) => moment(a.scannedDate).diff(b.scannedDate));

    return _.findLast(sortScans, { riderId, dataSourceId });
  }

  // =========================================
  // Signal R attendance code
  // =========================================

  public startScannedSignalR(): Observable<HubEventListeners>
  {
    const endPoint = this._signalRWrapperService.getScannedEventsEndPoint(this._stateService.subscriber.id, this._deviceService.deviceIdentifier);
    const name = this._signalRWrapperService.getName(SCANNED_SIGNALR_KEY);
    return this._signalRWrapperService.start(SCANNED_SIGNALR_KEY, endPoint, name);
  }

  public stopScannedSignalR(): Observable<any>
  {

    const endPoint = this._signalRWrapperService.getScannedEventsEndPoint(this._stateService.subscriber.id, this._deviceService.deviceIdentifier)
    return this._signalRWrapperService.close(SCANNED_SIGNALR_KEY, endPoint);
  }

  public updateScannedBody(scannedRecord: string | IAttendance)
  {
    if (!scannedRecord) return;
    const scannedRecordOB: IAttendance = this._parseAttendance(scannedRecord);
    const attendanceDictionaryKey = this._getAttendanceDictionaryKey(scannedRecordOB.dataSourceId, scannedRecordOB.riderId);
    const newScans = this._attendanceDictionary[attendanceDictionaryKey];
    const timeOffset = moment().utc().diff(moment(scannedRecordOB.scannedDate));
    if (timeOffset <= (1000 * 60 * 60) && timeOffset > 0 && scannedRecordOB.id && !_.find(newScans, { id: scannedRecordOB.id })
    )
    {
      this._updateDictionaryEntry(attendanceDictionaryKey, [scannedRecordOB]);
    }
  }

  public fetchLatestAttendance(): Observable<IAttendance[]>
  {
    if (!this._networkDictionary)
    {
      this._networkDictionary = this.apiService.getCurrentScanned(this.dateTimeService.utcNow(-60).format(this.dateTimeService.formatDate3))
        .pipe(
          takeUntil(this.clear$),
          shareReplay(1),
          catchError(() => of([])),
          tap((scans: Array<IAttendance>) =>
          {
            this._updateDictionary(scans.reduce((dict, scan) =>
            {
              (dict[this._getAttendanceDictionaryKey(scan.dataSourceId, scan.riderId)] = dict[this._getAttendanceDictionaryKey(scan.dataSourceId, scan.riderId)] || []).push(scan);
              return dict;
            }, {}));
          }),
          finalize(() => this._networkDictionary = null)
        );
    }
    return this._networkDictionary;
  }

  public clearNetworkDictionary()
  {
    this._networkDictionary = null;
  }

  public monitorAttendance(): Observable<IAttendance | IAttendance[]>
  {
    return this.startScannedSignalR()
      .pipe(
        switchMap((connectionEventListeners) => 
        {
          if (connectionEventListeners)
          {
            const onConnected = connectionEventListeners.onReceived
              .pipe(
                map((scannedBody: any) => this._parseAttendance(scannedBody.data))
              );
            const notConnected = this._pollForUpdates();
            return connectionEventListeners.state.pipe(
              switchMap((state: ConnectionState) => iif(() => state === ConnectionState.Connected, onConnected, notConnected))
            );
          } else
          {
            return this._pollForUpdates();
          }
        }),
        tap((scannedBody: any) => this.updateScannedBody(scannedBody)),
      );
  }

  public registerForAttendanceUpdates(schedule: StudentSchedule): Observable<IAttendance>
  {
    let lastAttendance: IAttendance;
    const network = this.fetchLatestAttendance()
      .pipe(
        map((scans: IAttendance[]) =>
        {
          if (!scans || scans.length === 0)
          {
            return null;
          }
          return this.filterAttendance(schedule, scans);
        })
      );
    const signalR = this.monitorAttendance()
      .pipe(
        map((val: IAttendance | IAttendance[]) =>
        {
          if (Array.isArray(val))
          {
            return this.filterAttendance(schedule, val);
          }
          if ('scannedDate' in val && schedule.riderId === val.riderId && schedule.dataSourceId === val.dataSourceId)
          {
            return val;
          }
          return null;
        })
      );
    const updateTime = interval(60 * 1000).pipe(startWith(0));
    return merge(network, signalR, updateTime)
      .pipe(
        map((mergeValue: IAttendance) =>
        {
          if (!!mergeValue && !(typeof mergeValue === 'number'))
          {
            lastAttendance = mergeValue;
          }
          if (!lastAttendance)
          {
            return null;
          }
          return lastAttendance;
        })
      );
  }

  public getScans(schedule: StudentSchedule): IAttendance[]
  {
    const groupName = this._getAttendanceDictionaryKey(schedule.dataSourceId, schedule.riderId);
    return Object.values(this._attendanceDictionary).reduce((rv, x) =>
    {
      return rv.concat(x);
    }, []);
  }

  private _parseAttendance(scannedRecord: string | IAttendance): IAttendance
  {
    return typeof scannedRecord === 'string' ? JSON.parse(scannedRecord) : scannedRecord;
  }

  private _updateDictionary(scans: IAttendanceDictionary): void
  {
    this._attendanceDictionary = scans;
    this._attendanceDictionarySubject.next(this._attendanceDictionary);
  }

  private _updateDictionaryEntry(groupName, scans: IAttendance[]): void
  {
    const entry = this._attendanceDictionary[groupName];
    if (!entry)
    {
      this._attendanceDictionary[groupName] = [];
    }
    this._attendanceDictionary[groupName] = this._attendanceDictionary[groupName].concat(scans);
    this._attendanceDictionarySubject.next(this._attendanceDictionary);
  }

  private _getAttendanceDictionaryKey(dataSourceId: number, riderId: number)
  {
    return `${dataSourceId}_${riderId}`;
  }

  private _pollForUpdates(): Observable<IAttendance[]>
  {
    return interval(60 * 1000).pipe(switchMap(() =>
    {
      return this.fetchLatestAttendance();
    }));
  }

  // =========================================
  // End Signal R attendance code
  // =========================================
}
