import { NgModule } from '@angular/core';
import { Routes, RouterModule, RouteReuseStrategy } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { ShareSubscriptionComponent } from './schedule/share-subscription/share-subscription.component';
import { AuthGuard } from './auth.guard';
import { MessagesComponent } from './messages/messages.component';
import { ViewMessageComponent } from './messages/view/view-message.component';
import { ActivationComponent } from './activation/activation.component';
import { CustomReuseStrategy } from './custom-reuse-strategy';
import { EsriMapComponent } from './map/esri-map/esri-map.component';
import { MessageDetailComponent } from './messages/message-detail/message-detail.component';
import { ManageSubscriptionsComponent } from './settings/manage-subscriptions/manage-subscriptions.component';
import { ProfileComponent } from './settings/profile/profile.component';
import { DownloadappComponent } from './activation/downloadapp/downloadapp.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SFFormAnswerComponent, SFFormComponent } from './form/form.export';
import { AttendanceHistoryComponent } from './attendance/attendance-history/attendance-history.component';
import * as Tutorial from './tutorial/tutorial.export';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'schedule',
    component: ScheduleComponent,
    data: {
      animation: 'SchedulePage'
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'esri-map',
    component: EsriMapComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'OtherPage'
    },
  },
  {
    path: '',
    redirectTo: '/schedule',
    pathMatch: 'full',
    data: {
      animation: 'SchedulePage'
    },
  },
  {
    path: 'messages',
    component: MessagesComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'OtherPage'
    },
  },
  {
    path: 'messages/:id',
    component: MessagesComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'OtherPage'
    },
  },
  {
    path: 'view-message/:id',
    component: ViewMessageComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'OtherPage'
    },
  },
  {
    path: 'feedback',
    component: MessageDetailComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'OtherPage'
    },
  },
  {
    path: 'message-detail',
    component: MessageDetailComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'MessageDetailPage'
    },
  },
  {
    path: 'manage-subscriptions',
    component: ManageSubscriptionsComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'OtherPage'
    },
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'OtherPage'
    },
  },
  {
    path: 'sharesubscription',
    component: ShareSubscriptionComponent,
    data: {
      animation: 'OtherPage'
    },
  },
  {
    path: 'editsubscription',
    component: ShareSubscriptionComponent,
    data: {
      animation: 'OtherPage'
    },
  },
  {
    path: 'activation',
    component: ActivationComponent,
    data: {
      animation: 'OtherPage'
    },
  },
  {
    path: 'downloadapp',
    component: DownloadappComponent,
    data: {
      animation: 'OtherPage'
    },
  },
  {
    path: 'forgotpassword',
    component: ForgotPasswordComponent,
    data: {
      animation: 'OtherPage'
    },
  },
  {
    path: 'formQuestion/:id/:recipientId',
    component: SFFormComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'MessageDetailPage'
    }
  },
  {
    path: 'formAnswer/:formSendId/:recipientId',
    component: SFFormAnswerComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'MessageDetailPage'
    }
  },
  {
    path: 'attendanceHistory/:riderId/:dataSourceId',
    component: AttendanceHistoryComponent,
    data: {
      animation: 'OtherPage'
    }
  },
  {
    path: `tutorial`,
    component: Tutorial.TutorialListComponent,
    data: { animation: 'OtherPage' }
  },
  {
    path: 'tutorialSlide',
    component: Tutorial.TutorialComponent,
    data: { animation: `OtherPage` }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy
    }
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
