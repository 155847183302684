import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  AfterViewInit,
  Inject
} from '@angular/core';
import { AppService } from '../app.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'sf-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, AfterViewInit, OnDestroy {
  public showChangePassword = false;
  public currentPassword = '';
  public currentPasswordRequired = false;
  public newPassword = '';
  public newPasswordRequired = false;
  public verifyNewPassword = '';
  public verifyNewPasswordRequired = false;
  public currentPasswordIncorrect = false;
  public validationMessage = '';
  public newPasswordMatches = false;
  public newPasswordValidationMsg = '';
  public userFirstName = 'FirstName';
  public userLastName = 'LastName';

  @Output() notify: EventEmitter<null> = new EventEmitter();

  constructor(public app: AppService) {}
  ngOnInit() {}
  ngAfterViewInit() {}
  ngOnDestroy() {}

  close() {
    this.notify.emit();
  }

  changePassword() {

  }

  handleValidations() {
    let success = true;
    if (this.currentPassword === '') {
      this.currentPasswordRequired = true;
      success = false;
    }
    if (this.newPassword === '') {
      this.newPasswordRequired = true;
      success = false;
    }
    if (this.verifyNewPassword === '') {
      this.verifyNewPasswordRequired = true;
      success = false;
    }
    if (this.newPassword !== this.verifyNewPassword) {
      this.newPasswordMatches = true;
      this.newPasswordValidationMsg = 'Password must match';
      success = false;
    }
    return success;
  }

  resetPasswordFields() {
    this.currentPassword = '';
    this.currentPasswordRequired = false;
    this.currentPasswordIncorrect = false;
    this.validationMessage = '';
    this.newPassword = '';
    this.newPasswordMatches = false;
    this.newPasswordRequired = false;
    this.newPasswordValidationMsg = '';
    this.verifyNewPassword = '';
    this.verifyNewPasswordRequired = false;
  }
}

@Component({
  selector: 'change-password-dialog',
  templateUrl: 'change-password-dialog.html'
})
export class DialogOverviewExampleDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
