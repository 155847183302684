import { BaseVectorMapGenerator } from '../classes/basemap.base';
import { eBaseMaps, BaseMapThumbnailEnum } from '../classes/basemap.enum';

export class LightGreyMap extends BaseVectorMapGenerator
{
    constructor()
    {
        super();
    }

    public get thumbnail(): string { return './assets/images/Light-Grey-Canvas.jpg'; }
    public get title(): string { return 'Light Grey'; }
    public get jsonUrl(): string { return './assets/vectors/light_grey.json'; }
    public get allowRotation(): boolean { return true; }
    public get maxZoom(): number { return 21; }
    public get maxNativeZoom(): number { return 19; }
    public get type(): eBaseMaps { return eBaseMaps.LightGrey; }
    public get translateKey(): BaseMapThumbnailEnum { return BaseMapThumbnailEnum.LightGrey; }
}
