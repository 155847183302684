import * as moment from 'moment';
import { Injectable } from "@angular/core";
import { ListPickerEnum } from '../utils/enum';

@Injectable()
export class StopfinderDateTimeService
{
  public readonly formatDate = 'M/DD/YYYY h:mm A';
  public readonly formatDate2 = 'M/DD/YY h:mm A';
  public readonly formatDate3 = 'YYYY-MM-DDTHH:mm:ss';
  public readonly formatDate4 = 'MM/DD/YYYY';

  public utcNow(offsetMinutes: number): moment.Moment
  {
    return moment().utc().add(offsetMinutes, 'm');
  }

  public toUtcDate(date: Date | String, offsetMinutes?: number): moment.Moment
  {
    var result: moment.Moment;
    if (typeof date === 'string')
    {
      result = moment.utc(date);
    }
    else
    {
      result = moment.utc((date as Date).toUTCString());
    }

    if (offsetMinutes !== undefined)
    {
      result = result.add(offsetMinutes, 'm');
    }

    return result;
  }

  public getClientToday(timeZoneMinutes: number): moment.Moment
  {
    return this.utcNow(timeZoneMinutes);
  }

  public filterDate(timeZoneMinutes: number, filterType: ListPickerEnum, dateArray: any[], dateKey: string)
  {
    const dateFormat = this.formatDate4;
    const today = this.getClientToday(timeZoneMinutes).format(dateFormat);
    const yesterday = this.getClientToday(timeZoneMinutes).subtract(1, 'day').format(dateFormat);
    const startOfThisWeek = this.getClientToday(timeZoneMinutes).startOf('week').format(dateFormat);
    const endOfThisWeek = this.getClientToday(timeZoneMinutes).endOf('week').format(dateFormat);
    const startOfLastWeek = this.getClientToday(timeZoneMinutes).subtract(1, 'weeks').startOf('week').format(dateFormat);
    const lastOfLastWeed = this.getClientToday(timeZoneMinutes).subtract(1, 'weeks').endOf('week').format(dateFormat);
    switch (filterType)
    {
      case ListPickerEnum.Today:
        return dateArray.filter(g => today === moment(g[dateKey]).format(dateFormat));
      case ListPickerEnum.Yesterday:
        return dateArray.filter(g => yesterday === moment(g[dateKey]).format(dateFormat));
      case ListPickerEnum.ThisWeek:
        return dateArray.filter(g => startOfThisWeek <= moment(g[dateKey]).format(dateFormat) && endOfThisWeek >= moment(g[dateKey]).format(dateFormat));
      case ListPickerEnum.LastWeek:
        return dateArray.filter(g => startOfLastWeek <= moment(g[dateKey]).format(dateFormat) && lastOfLastWeed >= moment(g[dateKey]).format(dateFormat));
    }
  }
}
