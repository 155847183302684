import { NativeMapViewFactory, NativeMapView } from '../native-mapview';
import { TfMapOptions } from '../classes/tf-map.options.class';
import { BasemapThumbnail } from '../../basemaps/classes/basemap.thumbnail';
import { eBaseMaps } from '../../basemaps/classes/basemap.enum';
import { BehaviorSubject } from 'rxjs';
import { MapModeType, IMapMode } from '../../services/view-manegement/interfaces/interface.map.mode';
import { TooltipService } from '../../../components/service/tooltip/tooltip.service';

export class TfMap
{

    public id: string;

    public _mapView: NativeMapView;

    private _status = 'new';

    public isReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public basemap: BehaviorSubject<BasemapThumbnail> = new BehaviorSubject<BasemapThumbnail>(null);

    // public events: TfMapEvents;

    public mode: IMapMode = { type: MapModeType.Track, padding: { top: 10, right: 10, bottom: 10, left: 10 }, className: 'track' };

    private curBaseMap: eBaseMaps = null;

    public get status()
    {
        return this._status;
    }

    public tooltipService: TooltipService;

    /**
     * Creates a TF Map with specified options.
     * @param opts Map Options interface.
     */
    constructor(
        id: string,
        opts: TfMapOptions,
    )
    {
        this._status = 'starting';
        this.id = id;
        this.curBaseMap = opts.map;
        NativeMapViewFactory.CreateMapView(this.id, this.curBaseMap, opts.center, (mapView: NativeMapView) =>
        {
            this._mapView = mapView;
            this._status = 'esri map loaded';
            this.isReady.next(true);
        }, (err) =>
        {
            this._status = 'failed to load';
        });
    }

    public async addLayer(layer: string, index = -1)
    {
        return new Promise<void>((resolve) =>
        {
            NativeMapView.nativeEsriMapPlugin.createGraphicsLayerForMap(
                this._mapView.id,
                layer,
                (index > -1) ? index : -1,
                () => { resolve(); },
                () => { resolve(); });
        });
    }

    public setBaseMap(map: eBaseMaps)
    {
        if ((this._mapView))
        {
            this.curBaseMap = map;
            if (NativeMapView.nativeEsriMapPlugin)
            {
                NativeMapView.nativeEsriMapPlugin.setMapBaseMap(this.id, NativeMapViewFactory.getNativeBasemapName(map), () =>
                {
                    this.toggleLabelColor();
                }, () => { });
            }
        }
    }

    public toggleLabelColor()
    {
        if (this.curBaseMap && NativeMapView.nativeEsriMapPlugin)
        {
            let isDarkMap = false;

            if (this.curBaseMap === eBaseMaps.DarkGrey || this.curBaseMap === eBaseMaps.StreetsNight || this.curBaseMap === eBaseMaps.ImageryWithLabels)
            {
                isDarkMap = true;
            }

            if (this.tooltipService && this.tooltipService.tooltip)
            {
                this.tooltipService.reverseTooltipStyle(isDarkMap);
            }
        }
    }

    public refreshBaseMap()
    {
        this.setBaseMap(this.curBaseMap);
    }

    /**
     * Promise which resolves when the map is ready.  If the map is already ready,
     * it resolves right away.
     */
    public ready(): Promise<void>
    {
        return new Promise<void>((resolve) =>
        {
            if (this.isReady.value === true)
            {
                resolve();
            } else
            {
                const sub = this.isReady.subscribe(() =>
                {
                    if (this.isReady.value === true)
                    {
                        resolve();
                        sub.unsubscribe();
                    }
                });
            }
        });
    }

    public destroy(): Promise<void>
    {
        return new Promise<void>((resolve) =>
        {
            // add in destroy logic here
            throw new Error('Not yet Implemented!');
        });
    }
}
