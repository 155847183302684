import { NativeEsriMapPlugin } from '../../map/esri-map/native-esri-map-plugin.interface';
import { error } from 'util';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Coordinates } from 'src/app/map/esri-map/esri-map.interface';

export class NativeMapView
{

    public static nativeEsriMapPlugin?: NativeEsriMapPlugin = null;
    public static pluginLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public id: string;
    public basemap: string;
    public loaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public error: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    public static validateNativeEsriMapPlugin(): Promise<NativeEsriMapPlugin>
    {
        return new Promise<NativeEsriMapPlugin>(resolve =>
        {
            const p = this.nativeEsriMapPlugin;
            if (p)
            {
                resolve(p);
            } else
            {
                throw error('native esri plugin could not be found!');
            }
        });
    }

    constructor(id: string, basemap: string, center: Coordinates)
    {
        if (!NativeMapView.nativeEsriMapPlugin)
        {
            NativeMapView.nativeEsriMapPlugin = (<any>window).plugins.nativeEsriMapPlugin;
        }
        this.id = id;
        this.basemap = basemap;
        let setupSuccessCallback = false;
        // parameter - handleLocation should always be false for Stopfinder.
        NativeMapView.nativeEsriMapPlugin.setup(this.id, false, this.basemap,
            {
                defaultSymbol: 'heading@3x.png',
                headingSymbol: 'heading@3x.png',
                accuracySymbol: 'accuracy.png',
                courseSymbol: 'heading@3x.png'
            },
            'Stopfinder',
            center.longitude || null,
            center.latitude || null,
            () =>
            {
                if (NativeMapView.nativeEsriMapPlugin)
                {
                    if (!setupSuccessCallback)
                    {
                        // ensure call pluginLoaded only once. 
                        setupSuccessCallback = true;
                        NativeMapView.pluginLoaded.next(true);
                        NativeMapView.nativeEsriMapPlugin.setEventMode('webView', () => { }, () => { });
                        NativeMapView.nativeEsriMapPlugin.setHorizontalSplit(-1, true, () => { }, () => { });
                    }
                }
                this.loaded.next(true);
            },
            err => this.error.next(err)
        );
    }
}

export class NativeMapViewFactory
{
    public static getNativeBasemapName(baseMap: string)
    {
        switch (baseMap)
        {
            case 'dark-grey':
                return 'DarkGrayCanvasVector';
            case 'light-grey':
                return 'LightGrayCanvasVector';
            case 'open-streets':
                return 'OpenStreetMap';
            case 'streets':
                return 'StreetsVector';
            case 'streets-night':
                return 'StreetsNightVector';
            case 'imagery-with-labels':
                return 'ImageryWithLabelsVector';
        }
    }

    /**
     * Creates a Map View Object
     * @param options Options to load the MapView
     */
    public static CreateMapView(id: string, baseMap: string, center: Coordinates,
        success: (result: NativeMapView) => any,
        error: (err: any) => any): void
    {
        const nvw = new NativeMapView(id, NativeMapViewFactory.getNativeBasemapName(baseMap), center);
        const sub: Subscription = nvw.loaded.subscribe((val: boolean) =>
        {
            if (val === true)
            {
                sub.unsubscribe();
                success(nvw);
            }
        });
        const sub2: Subscription = nvw.error.subscribe((val: any) =>
        {
            if (val)
            {
                sub2.unsubscribe();
                error(val);
            }
        });
    }

}
