import * as _ from 'lodash';
import
{
  Component,
  Inject,
  OnInit,
  NgZone,
  OnDestroy,
} from '@angular/core';
import
{
  MapService,
} from 'src/app/components/service/map/map.service';
import { StopfinderApiService } from 'src/app/shared/stopfinder/stopfinder-api.service';
import { AppService } from 'src/app/app.service';
import { AndroidBackService } from 'src/app/androidBack.service';
import
{
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
  MatBottomSheet,
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material';
import { TargetedBlockingScrollStrategy } from 'src/app/shared/material/targeted-blocking-scroll-strategy';
import { IGeoData } from './actions/geo.interface';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent, IDialogInput, ICallback } from 'src/app/shared/layout/confirmation-dialog/confirmation-dialog.component';
import { AddGeoBottomSheetComponent } from './actions/actions.component';
import { ViewGeoComponent } from './actions/view.geo.component';
import { GeoAlertsHistoryComponent } from '../geo-history/geo-alerts-history.component';
import { MapGeoAlertService } from 'src/app/components/service/map/map-geoalert.service';
import { DeviceService } from 'src/app/components/service/device/device.service';
import { MapCoreService } from 'src/app/components/service/map/map-core.service';
import { EtaAlert } from 'src/app/shared/stopfinder/models/eta-alert';
import { EtaAlertComponent } from './actions/eta/eta-alert.component';
import { EtaAlertsComponent } from './actions/eta/eta-alerts.component';
import { ETAAlertService } from '../../../shared/services/eta-alert.service';

@Component({
  selector: 'geo-action-bottom-sheet',
  templateUrl: './action.component.html',
  styleUrls: ['./actions/actions.component.scss'],
})
export class GeoActionSheetComponent implements OnInit, OnDestroy
{
  // actions sheet status once Geo alert named been typed, action sheet will be closed and actions will be true as opened
  private isActionsSheetOpened = false;

  private openDialogRef: MatDialogRef<ConfirmationDialogComponent, any> = null;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { geoData: IGeoData },
    public actionsBottomSheet: MatBottomSheet,
    public readonly geoAlertService: MapGeoAlertService,
    public readonly mapService: MapService,
    public readonly etaAlertService: ETAAlertService,
    private androidService: AndroidBackService,
    private readonly apiService: StopfinderApiService,
    private readonly appService: AppService,
    private readonly bottomSheetRef: MatBottomSheetRef<GeoActionSheetComponent>,
    private readonly deviceService: DeviceService,
    private readonly dialogRef: MatDialog,
    private readonly mapCoreService: MapCoreService,
    private readonly ngZone: NgZone,
    private readonly translate: TranslateService,
  )
  {
    // must be bind this here to prevent the event not remove
    this.hideMapTools = this.hideMapTools.bind(this);
  }

  ngOnInit()
  {
    this.androidService.onShouldCheckCallback(() =>
    {
      // should do nothing since typing new name is the mandatory part in whole process
      if (this.dialogRef.openDialogs.length)
      {
        this.openDialogRef.close();
        return;
      }
      // should only turn off actions bottom sheet once Geo settings is in configuration mode
      if (this.bottomSheetRef)
      {
        // should emit states to geo.component.ts to trigger cancel event
        this.bottomSheetRef.dismiss();
        return;
      }

      this.androidService.onDestroyCallback();
    });
  }

  ngOnDestroy()
  {
  }

  /**
   * Function to add geographic layers onto the map
   */
  public addGeo()
  {
    // should hide previous action bottom sheet
    this.bottomSheetRef.dismiss();

    this.bottomSheetRef.afterDismissed().subscribe(() =>
    {
      // should open add name dialog as first step
      // after confirmed the name, geo alert setup will continue to next step
      this.addGeoName();
    });
  }

  private addGeoName()
  {
    // should enable unique validation for geo name
    const uniqueValidation = (result: ICallback): Promise<IDialogInput> =>
    {
      return new Promise<IDialogInput>(resolve =>
      {
        // should validate empty value
        if (_.isEmpty(_.trim(result.message)))
        {
          return resolve({
            isEmpty: true,
            errorMsg: this.translate.instant("geo.add.modal.add.name.required"),
          } as IDialogInput);
        }

        // should validate duplicate value
        this.apiService.checkGeoAlertNotTaken(-1, this.mapService.geoData.riderId, result.message)
          .toPromise().then((isNotDuplicated: boolean) =>
          {
            if (!isNotDuplicated)
            {
              return resolve({
                isError: true,
                errorMsg: this.translate.instant("geo.add.modal.add.name.duplicated"),
              } as IDialogInput);
            }

            // should pass okay to confirm dialog if all validation passed
            return resolve({
              isError: false,
            } as IDialogInput);
          });
      });
    };

    const sizeTop: number = Math.round(this.geoAlertService.calculateDialogTopValue());
    // should prepare dialog config
    const dialogConfig: MatDialogConfig = {
      // set to true to disable overlay clickable to close the dialog
      disableClose: false,
      data: {
        title: this.translate.instant("geo.add.modal.add.title"),
        enableInput: true,
        inputLabel: this.translate.instant("geo.add.modal.add.name"),
        inputValue: '',
        input: {
          maxLength: 100,
          isError: false,
          isEmpty: false,
        },
        action: this.translate.instant("geo.add.modal.add.save"),
        secondary: true,
        secondaryAction: this.translate.instant("geo.add.modal.add.cancel"),
        promiseCallback: uniqueValidation,
      },
      panelClass: ['confirm-dialog', `confirm-dialog-top-${_.isNull(sizeTop) ? 180 : sizeTop}`],
    };

    window.removeEventListener('resize', this.hideMapTools);
    window.addEventListener('resize', this.hideMapTools);

    // should open dialog to let user input geo name
    this.openDialogRef = this.dialogRef.open(ConfirmationDialogComponent, dialogConfig);
    // should hide map compass and geo alert icon when keyboard shows up
    this.mapService.shouldHideMapTools = true;

    this.openDialogRef.afterClosed().subscribe((geoName: string) =>
    {
      if (geoName)
      {
        this.data.geoData.id = -1;
        this.data.geoData.name = geoName;
        this.mapService.resetGeoModel();
        this.openAddGeoActions();
      }
    });

    this.openDialogRef.backdropClick().subscribe(() =>
    {
      this.openDialogRef.close();
      this.mapService.shouldHideMapTools = false;
    });
  }

  private hideMapTools(e)
  {
    setTimeout(() =>
    {
      const currentHeight = window.innerHeight;
      const keyboardPlaceholderHeight = 100;
      if (this.appService.windowHeight - currentHeight < keyboardPlaceholderHeight)
      {
        this.ngZone.run(() => this.mapService.shouldHideMapTools = false);
      }
      else
      {
        this.ngZone.run(() => this.mapService.shouldHideMapTools = true);
      }
    }, 100);
  }


  /**
   * Private function to open geo settings in adding mode
   */
  private openAddGeoActions()
  {
    // should enable action header
    this.mapService.turnOnGeoAddMode();
    this.isActionsSheetOpened = true;

    const cdkOverContainer = document.querySelector("div.cdk-overlay-container") as HTMLElement;
    this.deviceService.isAndroid && cdkOverContainer && (cdkOverContainer.style.visibility = "hidden");

    // should transfer the data from student schedule components part
    const _actionsBottomSheet = this.actionsBottomSheet.open(AddGeoBottomSheetComponent, {
      data: {
        geoData: this.data.geoData
      },
      panelClass: 'geo-configuration',
      hasBackdrop: false,
      scrollStrategy: new TargetedBlockingScrollStrategy()
    });

    _actionsBottomSheet.afterOpened().subscribe(() =>
    {
      const topPadding = document.querySelector(".geo-header").clientHeight;
      const bottomPadding = document.querySelector(".mat-bottom-sheet-container").clientHeight;
      this.mapService.setViewSize(
        0,
        topPadding,
        this.appService.windowWidth,
        this.appService.windowHeight - topPadding - bottomPadding);

      // should checked plugin status and then popover actions sheet
      this.mapService.isMapPluginReady() &&
        this.mapService.turnOnGeoMode(-1, () =>
        {
          // callback to handle rest of the logic
        });

      // There is a certain delay on some bad android pads
      this.deviceService.isAndroid && setTimeout(() =>
      {
        cdkOverContainer && (cdkOverContainer.style.visibility = "visible");
      })
    });

    // prevent repeated binding of events and execute multiple times
    // must remove the resize event when close the edit geo alert mode
    _actionsBottomSheet.afterDismissed().subscribe(() =>
    {
      window.removeEventListener('resize', this.hideMapTools);
    });
  }

  private readonly actionWebViewMode = () => this.deviceService.isAndroid && this.mapCoreService.webViewMode();

  /**
   * Public function to open geo settings in editing mode
   */
  public editGeo()
  {
    this.bottomSheetRef.dismiss();

    this.bottomSheetRef.afterDismissed().subscribe(() =>
    {
      this.openEditBottomSheet();
    });
  }

  private openEditBottomSheet()
  {
    this.actionWebViewMode();
    // should enable action header
    this.isActionsSheetOpened = true;
    // should transfer the data from student schedule components part
    this.actionsBottomSheet.open(ViewGeoComponent, {
      data: {
        riderId: this.data.geoData.riderId
      },
      panelClass: 'geo-view-list-wrapper',
      hasBackdrop: false,
    })
  }

  public geoHistory()
  {
    this.bottomSheetRef.dismiss();
    this.bottomSheetRef.afterDismissed().subscribe(() =>
    {
      this.openGeoHistoryBottomSheet();
    });
  }

  private openGeoHistoryBottomSheet()
  {
    this.actionWebViewMode();
    // should enable action header
    this.isActionsSheetOpened = true;
    // should transfer the data from student schedule components part
    this.actionsBottomSheet.open(GeoAlertsHistoryComponent, {
      data: {
        riderId: this.data.geoData.riderId
      },
      panelClass: 'geo-history-wrapper',
      hasBackdrop: false,
    });
  }

  public addEtaAlert(): void
  {
    this.bottomSheetRef.dismiss();
    this.bottomSheetRef.afterDismissed().subscribe(() =>
    {
      this.actionWebViewMode();
      this.isActionsSheetOpened = true;
      this.actionsBottomSheet.open(EtaAlertComponent, {
        data: {
          subscriberId: this.data.geoData.subscriberId,
          riderId: this.data.geoData.riderId,
          id: null,
          alertTime: null,
        } as EtaAlert,
        panelClass: 'geo-history-wrapper',
        hasBackdrop: false,
      })
    });

  }

  public editEtaAlert(): void
  {
    this.bottomSheetRef.dismiss();
    this.bottomSheetRef.afterDismissed().subscribe(() =>
    {
      this.actionWebViewMode();
      this.isActionsSheetOpened = true;
      this.actionsBottomSheet.open(EtaAlertsComponent, {
        data: {
          subscriberId: this.data.geoData.subscriberId,
          riderId: this.data.geoData.riderId
        },
        panelClass: 'geo-view-list-wrapper',
        hasBackdrop: false,
      })
    });
  }
}
