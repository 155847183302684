import { BaseRasterMapGenerator } from '../classes/basemap.base';
import { eBaseMaps, BaseMapThumbnailEnum } from '../classes/basemap.enum';

export class OpenStreetsMap extends BaseRasterMapGenerator
{
    constructor()
    {
        super();
    }

    public get thumbnail(): string { return './assets/images/OpenStreetMap.jpg'; }
    public get title(): string { return 'Open Streets Map'; }
    public get name(): string { return 'osm'; }
    public get maxZoom(): number { return 17; }
    public get maxNativeZoom(): number { return 16; }
    public get type(): eBaseMaps { return eBaseMaps.OpenStreets; }
    public get translateKey(): BaseMapThumbnailEnum { return BaseMapThumbnailEnum.OpenStreets; }
}
