import * as _ from 'lodash';
import { OnInit, OnDestroy, Component, ElementRef, ChangeDetectorRef, NgZone, ViewEncapsulation, DoCheck } from '@angular/core';
import { ErrorStateMatcher, MatDialog } from '@angular/material';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { StudentSchedule, Subscription } from '../../shared/stopfinder/stopfinder-models';
import { FormControl, Validators, FormGroupDirective, NgForm, FormGroup, ValidatorFn, ValidationErrors } from '@angular/forms';
import { ScheduleService } from '../schedule.service';
import { ManageSubscriptionService } from '../../settings/manage-subscriptions/manage-subscriptions.service';
import { LocalStorageService } from '../../shared/local-storage/local-storage.service';
import { StateService } from '../../components/service/state/state.service';
import { debounceTime, map, flatMap, tap, startWith } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../../shared/layout/confirmation-dialog/confirmation-dialog.component';
import { TargetedBlockingScrollStrategy } from '../../shared/material/targeted-blocking-scroll-strategy';
import { AndroidBackService } from '../../androidBack.service';
import * as moment from 'moment';
import { AppService } from '../../app.service';
import { IndicatorService } from 'src/app/shared/refresh/indicator.service';
import { DeviceService } from 'src/app/components/service/device/device.service';
import { DateValidation } from 'src/app/shared/stopfinder/validation/date-validation';
import { stringToDate } from 'src/app/shared/utils/utils';

export class MyErrorStateMatcher implements ErrorStateMatcher
{
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean
  {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'share-subscription',
  templateUrl: './share-subscription.component.html',
  styleUrls: ['./share-subscription.component.scss'],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class ShareSubscriptionComponent implements OnInit, OnDestroy, DoCheck
{
  public schedule: StudentSchedule;
  public subscription: Subscription;
  public matcher;
  public shareSubscriptionForm: FormGroup;
  public formControls;
  public filteredSubscriptions;
  public isEmailFocused = false;
  public shouldPopAutoComplete = false;
  public pageTitle = "";

  private effectiveStartDate: string = null;
  public selectionStartDateMin: string = null;
  // normally, effective-start-max should set to null as unlimited. its value will based on effective end value if needed
  // effective-start-max is the value to limit start-date calendar selection range greater than end-date by different scenarios
  public selectionStartDateMax: string = null;
  private systemStart: string = null;

  private effectiveEndDate: string = null;
  public selectionEndDateMin: string = null;

  public rightHeaderTranslateText: string = "";
  public subscriptionLocalization;

  clientId: number;
  subscriberId: number;
  currentEmail: string;
  private startDateInitialized = false;
  private endDateInitialized = false;
  private readonly dateFormat: string = 'YYYY-MM-DD';

  constructor(
    public _appService: AppService,
    public localStorageService: LocalStorageService,
    public scheduleService: ScheduleService,
    public subscriptionService: ManageSubscriptionService,
    public translate: TranslateService,
    public readonly deviceService: DeviceService,
    private _adapter: DateAdapter<any>,
    private cdRef: ChangeDetectorRef,
    private stateService: StateService,
    private readonly androidBackService: AndroidBackService,
    private readonly dialog: MatDialog,
    private readonly loadingIndicator: IndicatorService,
    private readonly ngZone: NgZone,
  )
  {
    const dateValidation = new DateValidation();
    this.translate.stream('subscription').subscribe((res: { [key: string]: any }) =>
    {
      this.subscriptionLocalization = res;
      this._adapter.setLocale(this.translate.currentLang);
    });

    if (this._appService.currentStudentSchedule)
    {
      this.schedule = _appService.currentStudentSchedule;
    }
    if (this._appService.currentSubscription)
    {
      this.subscription = _appService.currentSubscription;
      if (!this.subscription.subscriberFirstName || !this.subscription.subscriberLastName)
      {
        this.subscription.subscriberFirstName = this.subscription.subscriberName.split(" ")[0];
        this.subscription.subscriberLastName = this.subscription.subscriberName.split(" ")[1];
      }
    }

    // set default
    this.selectionStartDateMin = this.selectionEndDateMin = this.systemStart = moment().format(this.dateFormat);
    this.selectionStartDateMax = null;
    // overwrite by payload data
    if (this.subscription)
    {
      // cached payload effectiveStart
      this.effectiveStartDate = this.subscription.effectiveStart ? moment(this.subscription.effectiveStart).format(this.dateFormat) : null;
      // cached payload effectiveEnd
      this.effectiveEndDate = this.subscription.effectiveEnd ? moment(this.subscription.effectiveEnd).format(this.dateFormat) : null;
      // default to set selection end range from cached or system start date
      this.selectionEndDateMin = this.effectiveStartDate ? stringToDate(this.effectiveStartDate) >= stringToDate(this.systemStart) ? this.effectiveStartDate : this.systemStart : this.systemStart;
      // default to set selection start date max date as effectiveEnd date if exist
      this.selectionStartDateMax = this.effectiveEndDate ? this.effectiveEndDate : null;
    }

    this.clientId = null;
    this.currentEmail = this.localStorageService.get('currentEmail');
    this.subscriberId = null;

    this.matcher = new MyErrorStateMatcher();
    this.shareSubscriptionForm = new FormGroup({
      email: new FormControl({ value: this.subscription && this.subscription.subscriberEmail ? this.subscription.subscriberEmail.trimRight() : '', disabled: this.subscription ? true : false }, [this.customRequiredAfterTrimValidators(), this.customEmailAfterTrimValidators(), this.customEmailValidators()]),
      first: new FormControl({ value: this.subscription && this.subscription.subscriberFirstName ? this.subscription.subscriberFirstName.trimRight() : '', disabled: this.subscription ? true : false }, [this.customRequiredAfterTrimValidators()]),
      last: new FormControl({ value: this.subscription && this.subscription.subscriberLastName ? this.subscription.subscriberLastName.trimRight() : '', disabled: this.subscription ? true : false }, [this.customRequiredAfterTrimValidators()]),
      cell: new FormControl({ value: this.subscription && this.subscription.subscriberPhoneNumber ? this.subscription.subscriberPhoneNumber.trimRight() : '', disabled: this.subscription ? true : false }, [this.customRequiredAfterTrimValidators()]),
      startDate: new FormControl({ value: this.subscription && this.subscription.effectiveStart ? this.formatBindDateValue(this.subscription.effectiveStart) : '', disabled: false }, [dateValidation.customStartDateAfterTodayValidators(), dateValidation.customStartDateBeforeEndDateValidators(), dateValidation.customStartDateAfterTodayAndBeforeEndDateValidators()]),
      endDate: new FormControl({ value: this.subscription && this.subscription.effectiveEnd ? this.formatBindDateValue(this.subscription.effectiveEnd) : '', disabled: false }, [dateValidation.customEndDateAfterStartDateValidators()]),
      active: new FormControl({ value: this.subscription && this.subscription.active, disabled: false }),
      comments: new FormControl({ value: this.subscription && this.subscription.comments ? this.subscription.comments.trimRight() : '', disabled: false }),
    });
    this.formControls = this.shareSubscriptionForm.controls;
    dateValidation.formControls = this.formControls;

    this._adapter.setLocale(this.translate.currentLang);
  }

  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }

  ngDoCheck()
  {
    if (!this.startDateInitialized && this.getDateLabel('startDate') && !(this.subscription && this.subscription.effectiveStart))
    {
      this.inactiveDate('startDate');
      this.startDateInitialized = true;
    }

    if (!this.endDateInitialized && this.getDateLabel('endDate') && !(this.subscription && this.subscription.effectiveEnd))
    {
      this.inactiveDate('endDate');
      this.endDateInitialized = true;
    }
  }

  ngOnInit()
  {
    var self = this;
    this.shareSubscriptionForm.get('startDate').valueChanges.subscribe(val =>
    {
      self.selectionEndDateMin = !val ? this.systemStart : moment(val).format(this.dateFormat);

      if (this.formControls['startDate'].value)
      {
        this.activeDate('startDate');
      }
    });

    this.shareSubscriptionForm.get('endDate').valueChanges.subscribe(val =>
    {
      self.selectionStartDateMax = val ? moment(val).format(this.dateFormat) : null;

      if (this.formControls['endDate'].value)
      {
        this.activeDate('endDate');
      }
    });

    this.androidBackService.onShouldCheckCallback(this.cancel.bind(this));

    window.addEventListener('keyboardDidHide', this.updateContainerMargin.bind(this));

    window.addEventListener('keyboardDidShow', (event) =>
    {
      if (this.deviceService.isiOS)
      {
        if (this.deviceService.isiPhoneX)
        {
          document.getElementsByTagName('share-subscription')[0].parentElement.parentElement.parentElement.style.marginTop = '40px';
        }
        else
        {
          document.getElementsByTagName('share-subscription')[0].parentElement.parentElement.parentElement.style.marginTop = '20px';
        }
      }
    });

    this.filteredSubscriptions = this.shareSubscriptionForm.get('email').valueChanges.pipe(startWith(''), tap(val =>
    {
      if (this.shareSubscriptionForm.get('first').disabled)
      {
        const suppressEventOpts = { emitEvent: false };
        this.shareSubscriptionForm.get('email').setValidators([Validators.required, Validators.email, this.customEmailValidators()]);
        this.shareSubscriptionForm.get('email').updateValueAndValidity(suppressEventOpts);

        this.shareSubscriptionForm.get('first').setValue('', suppressEventOpts);
        this.shareSubscriptionForm.get('last').setValue('', suppressEventOpts);
        this.shareSubscriptionForm.get('cell').setValue('', suppressEventOpts);
        this.shareSubscriptionForm.get('comments').setValue('', suppressEventOpts);

        this.shareSubscriptionForm.get('first').enable();
        this.shareSubscriptionForm.get('last').enable();
        this.shareSubscriptionForm.get('cell').enable();

        this.shouldPopAutoComplete = true;
      }

      if (_.isEmpty(this.shareSubscriptionForm.get('email').value) && this.isEmailFocused)
      {
        this.shouldPopAutoComplete = true;
      }
    }), debounceTime(300), flatMap((val: any) =>
    {
      const valEmail = val.subscriberEmail ? val.subscriberEmail : val;
      return this.subscriptionService.sortedSubscriptionsObservable.pipe(map(subscriptions =>
      {
        const filteredSubs = subscriptions.filter(s =>
        {
          const email = s.subscriberEmail.toLowerCase();
          return email.includes(valEmail.toLowerCase()) && !email.includes(this.currentEmail.toLowerCase()) && s.riderId === this.schedule.riderId;
        });
        return _.uniqWith(filteredSubs, (arrVal, othVal) => { return arrVal.subscriberEmail === othVal.subscriberEmail });
      }));
    }));

    // register external validation into state service
    this.stateService.disableCallback();
    this.stateService.setExternalValidation(this.cancel.bind(this));

    if (this.schedule)
    {
      this.pageTitle = this.translate.instant('subscription.share.title');
      this.rightHeaderTranslateText = this.translate.instant('subscription.manage.navigation.right.share');
    }
    else
    {
      this.pageTitle = this.translate.instant('subscription.manage.edit');
      this.rightHeaderTranslateText = this.translate.instant('subscription.manage.navigation.right.save');
    }
  }

  private updateContainerMargin()
  {
    if (this.deviceService.isiOS)
    {
      document.getElementsByTagName('share-subscription')[0].parentElement.parentElement.parentElement.style.marginTop = '0px';
    }
  }

  ngOnDestroy()
  {
    this.androidBackService.onDestroyCallback();
    window.removeEventListener('keyboardDidHide', this.updateContainerMargin);

    this.stateService.destroyStateChange();
    this.loadingIndicator.close();
  }

  subscriptionEmailDisplayFn(subscription: Subscription)
  {
    return subscription.subscriberEmail;
  }

  /**
   * Public function to show or hide auto complete
   * Display logic:
   *  1. Empty + Focus + No Value change
   *
   * @param event HTMLElement
   * @param status Mandatory. True means focus, False means blur
   */
  public emailInputEvent(event: any, status: boolean)
  {
    // should show auto complete when:
    // status = true
    this.isEmailFocused = status;
    this.shouldPopAutoComplete = status;

    const relatedRef = _.get(event, 'relatedTarget.classList');

    // should only care HTMLRef when onBlur
    if (!!relatedRef && !status)
    {
      if (relatedRef.contains('mat-option'))
      {
        this.shouldPopAutoComplete = !status;
      }
      else
      {
        this.shouldPopAutoComplete = status;
      }
    }
    else
    {
      this.shouldPopAutoComplete = status;
    }
  }

  clearDate(e: Event, dateStr: string)
  {
    e.stopPropagation();
    const suppressEventOpts = { emitEvent: false };
    this.shareSubscriptionForm.get(dateStr).setValue('', suppressEventOpts);

    if (_.isEqual(dateStr, 'startDate'))
    {
      this.selectionStartDateMin = this.selectionEndDateMin = this.systemStart;
      this.inactiveDate('startDate');
    }

    if (_.isEqual(dateStr, 'endDate'))
    {
      this.selectionStartDateMax = null;
      this.inactiveDate('endDate');
    }

    this.shareSubscriptionForm.get('startDate').updateValueAndValidity();
    this.shareSubscriptionForm.get('endDate').updateValueAndValidity();
  }

  autoCompleteOptionSelect(subscription: Subscription, event: any)
  {
    event.stopPropagation();
    event.preventDefault();
    const suppressEventOpts = { emitEvent: false };

    if (!this.shareSubscriptionForm.get('first').disabled)
    {

      this.shareSubscriptionForm.get('email').clearValidators();
      this.shareSubscriptionForm.get('email').updateValueAndValidity(suppressEventOpts);

      setTimeout(() =>
      {
        this.shareSubscriptionForm.get('email').setValue(subscription.subscriberEmail, suppressEventOpts);
        this.shareSubscriptionForm.get('email').updateValueAndValidity();
        this.shareSubscriptionForm.get('first').setValue(subscription.subscriberFirstName, suppressEventOpts);
        this.shareSubscriptionForm.get('last').setValue(subscription.subscriberLastName, suppressEventOpts);
        this.shareSubscriptionForm.get('cell').setValue(subscription.subscriberPhoneNumber, suppressEventOpts);

        this.shareSubscriptionForm.get('first').disable();
        this.shareSubscriptionForm.get('last').disable();
        this.shareSubscriptionForm.get('cell').disable();

        // option click should always manually set focus to false
        this.shouldPopAutoComplete = false;
      }, 300);
    }

    this.shouldPopAutoComplete = false;
  }

  back()
  {
    if (this.schedule)
    {
      this.stateService.goRoute('schedule');
    }
    else
    {
      this.stateService.goRoute('manage-subscriptions');
    }
    this._appService.currentStudentSchedule = null;
    this._appService.currentSubscription = null;
  }

  onSubmit()
  {
    if (!this.shareSubscriptionForm.valid) return;

    const active = this.formControls['active'].value;
    const email = this.formControls['email'].value.subscriberEmail ? this.formControls['email'].value.subscriberEmail.trimRight() : this.formControls['email'].value ? this.formControls['email'].value.trimRight() : this.formControls['email'].value;
    const first = this.formControls['first'].value ? this.formControls['first'].value.trimRight() : this.formControls['first'].value;
    const last = this.formControls['last'].value ? this.formControls['last'].value.trimRight() : this.formControls['last'].value;
    const startDate = this.formControls['startDate'].value ? moment(this.formControls['startDate'].value).format('M/D/YYYY') : this.formControls['startDate'].value;
    const endDate = this.formControls['endDate'].value ? moment(this.formControls['endDate'].value).format('M/D/YYYY') : this.formControls['endDate'].value;
    const cell = (this.formControls['cell'].value || '').replace(/\D/g, '');
    const comments = this.formControls['comments'].value ? this.formControls['comments'].value.trimRight() : this.formControls['comments'].value;
    if (this.schedule)
    {
      this.loadingIndicator.show();
      const riderId = this.schedule.riderId;
      // TODO: Loading dialog? Confirmation dialog?
      this.scheduleService.shareSubscription(riderId, email, first, last, startDate, endDate, comments, cell).subscribe(result =>
      {
        this.loadingIndicator.close();
        if (result.subscriberBanStatus)
        {
          let msg = this.translate.instant('subscription.dialog.ban.message', { email: email });
          const ref = this.dialog.open(ConfirmationDialogComponent, {
            disableClose: true,
            data: {
              title: this.translate.instant('subscription.dialog.ban.title'),
              message: msg,
              action: this.translate.instant('subscription.dialog.ban.action'),
              secondary: false,
            },
            scrollStrategy: new TargetedBlockingScrollStrategy(),
            panelClass: "confirm-dialog"
          });
          ref.afterClosed().subscribe(result =>
          {
            if (result)
            {
              this.back();
            }
          });
        }
        else
        {
          this.dialog.open(ConfirmationDialogComponent, {
            disableClose: true,
            data: {
              title: this.translate.instant('subscription.dialog.title'),
              message: this.translate.instant('subscription.dialog.message'),
              action: this.translate.instant('subscription.dialog.action'),
              secondary: false,
            },
            scrollStrategy: new TargetedBlockingScrollStrategy(),
            panelClass: "confirm-dialog"
          }).afterClosed().subscribe(result =>
          {
            if (result)
            {
              this.subscriptionService.getSubscriptions();
              this.back();
            }
          });
        }
      });
    }

    if (this.subscription)
    {
      var self = this;
      this.loadingIndicator.show();
      this.subscriptionService.patchFromEditDialog({
        id: this.subscription.id,
        active: active,
        subscriberId: this.subscription.subscriberId,
        subscriberEmail: email,
        subscriberFirstName: first,
        subscriberLastName: last,
        subscriberName: `${first} ${last}`,
        subscriberPhoneNumber: (cell || '').replace(/\D/g, ''),
        effectiveStart: startDate,
        effectiveEnd: endDate,
        comments: comments,
        subscriptionRangeId: this.subscription.subscriptionRangeId,
      }).then(function (result)
      {
        self.loadingIndicator.close();
        self.dialog.open(ConfirmationDialogComponent, {
          disableClose: true,
          data: {
            title: self.translate.instant('subscription.dialog.edit.title'),
            message: self.translate.instant('subscription.dialog.edit.message'),
            action: self.translate.instant('subscription.dialog.edit.action'),
            secondary: false,
          },
          scrollStrategy: new TargetedBlockingScrollStrategy(),
          panelClass: "confirm-dialog"
        }).afterClosed().subscribe(result =>
        {
          self.back();
        });
      });
    }
  }

  customEmailValidators(): ValidatorFn
  {
    return (control: FormControl): ValidationErrors =>
    {
      const email = control.value.subscriberEmail ? control.value.subscriberEmail.trimRight() : control.value.trimRight();
      if (email.toLowerCase() === this.currentEmail.toLowerCase())
      {
        return { 'selfEmail': true };
      }
      return null;
    }
  }

  customRequiredAfterTrimValidators(): ValidatorFn
  {
    return (control: FormControl): ValidationErrors =>
    {
      let value = control.value;
      if (typeof (control.value) === 'string') { value = control.value.trimRight(); }
      return value == null || value.length === 0 ? { 'required': true } : null;
    }
  }

  customEmailAfterTrimValidators(): ValidatorFn
  {
    return (control: FormControl): ValidationErrors =>
    {
      const value = control.value.subscriberEmail ? control.value.subscriberEmail.trimRight() : control.value.trimRight();
      if (value == null || value.length === 0)
      {
        return null; // don't validate empty values to allow optional controls
      }
      const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return EMAIL_REGEXP.test(value) ? null : { 'email': true };
    }
  }

  convertToUTC(date: Date): string
  {
    if (date instanceof Date)
    {
      var theDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
      return theDate.toUTCString();
    }
    return date;
  }

  cancel(elRef?: ElementRef)
  {
    this.ngZone.run(() =>
    {
      event && event.stopImmediatePropagation();

      let changed = false;
      if (this.subscription)
      {
        const currentComments = this.subscription.comments ? this.subscription.comments : '';
        changed = this.formControls['active'].value !== this.subscription.active ||
          (this.formControls['startDate'].value ? this.getTime(this.formControls['startDate'].value) : '') !== (this.subscription.effectiveStart ? this.getTime(this.subscription.effectiveStart) : '') ||
          (this.formControls['endDate'].value ? this.getTime(this.formControls['endDate'].value) : '') !== (this.subscription.effectiveEnd ? this.getTime(this.subscription.effectiveEnd) : '') ||
          this.formControls['comments'].value !== currentComments;
      }
      else
      {
        changed = (this.formControls['email'].value ||
          this.formControls['first'].value ||
          this.formControls['last'].value ||
          this.formControls['startDate'].value ||
          this.formControls['endDate'].value ||
          this.formControls['cell'].value ||
          this.formControls['comments'].value) !== '';
      }
      if (changed)
      {
        this.androidBackService.onDisableMultipleTarget(true);
        this.dialog
          .open(ConfirmationDialogComponent, {
            disableClose: false,
            data: {
              title: this.translate.instant('subscription.dialog.cancel.title'),
              message: this.translate.instant('subscription.dialog.cancel.message'),
              action: this.translate.instant('subscription.dialog.cancel.action'),
              secondary: true,
              secondaryAction: this.translate.instant('subscription.dialog.cancel.secondary'),
            },
            scrollStrategy: new TargetedBlockingScrollStrategy(),
            panelClass: "confirm-dialog"
          })
          .afterClosed()
          .subscribe(response =>
          {
            this.androidBackService.onDisableMultipleTarget(false);
            if (response)
            {
              this.stateService.handleBlockStateCallback(true) && this.back();
            }
            this.stateService.disableCallback();
          });
      }
      else
      {
        this.stateService.handleBlockStateCallback(false) && this.back();
      }
    });
  }

  private activeDate(id: 'startDate' | 'endDate')
  {
    const startDateLabel = this.getDateLabel(id);
    startDateLabel.classList.remove('inactive');
    startDateLabel.classList.add('active');
  }

  private inactiveDate(id: 'startDate' | 'endDate')
  {
    const startDateLabel = this.getDateLabel(id);
    startDateLabel.classList.remove('active');
    startDateLabel.classList.add('inactive');
  }

  private getDateLabel(id: 'startDate' | 'endDate'): HTMLElement
  {
    return document.getElementById(id).parentElement.querySelector('.mat-form-field-label');
  }

  private getTime(date: string)
  {
    return stringToDate(date).getTime();
  }

  private formatBindDateValue(date: string): string
  {
    if (!date)
    {
      return "";
    }
    // Safari will get incorrect date if the date string contains '-'
    const _date = new Date(moment(date).format('M/D/YYYY'));
    return moment(_date).format("YYYY-MM-DD");
  }
}
