/**
 * Noticed, any other extra layer name cannot be implemented since backend factory class hard code those values.
 * New layer will not be generated if its name out of the scope.
 * New layer due to customize layer name, will need extra implementation in both java and swift in nativeEsriMap.
 */
export enum MapLayerName
{
    stopfinderPath = 'stopFinder-trip-path',
    stopfinderStudent = 'student-layer',
    stopfinderSchool = 'school-layer',
    stopfinderBusStop = 'stop-layer',
    TouchTransparency = 'touch-transparency',
    labelLayer = 'label-layer',
    stopfinderGeoAlertText = 'geo-alert-text-layer',
    stopfinderGeoAlertPolygon = 'geo-alert-polygon-layer',
    stopfinderStudentStop = 'student-stop-layer',
    stopfinderAttendance = 'student-attendance-layer',
}
