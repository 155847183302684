import * as _ from 'lodash';
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { FormService } from '../../components/service/form/form.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'tf-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProgressBarComponent implements OnInit, OnDestroy
{
  @Input() label: string;
  @Input() min: number = 0;
  @Input() max: number = 10;
  @Input() now: number = 5;

  public uniqueId = _.uniqueId(`progress_`);
  public activatedStyle = { 'width': `${(this.now / (this.max - this.min)) * 100}%` };

  private inprogressQuestionStatusSubscription: Subscription;

  constructor(
    private readonly formService: FormService,
  )
  { }

  ngOnInit()
  {
    this.inprogressQuestionStatusSubscription = this.formService.inprogressQuestionStatus.subscribe((value: number) =>
    {
      this.now = value + 1;
      this.activatedStyle = { 'width': `${(this.now / (this.max - this.min)) * 100}%` };
    });

    this.formService.isProgressLoaded.next(true);
  }

  ngOnDestroy()
  {
    this.inprogressQuestionStatusSubscription && this.inprogressQuestionStatusSubscription.unsubscribe();
  }
}
