import { Directive, ElementRef, AfterViewInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { formatPhoneNumber, isValidPhoneNumber } from '../../../shared/utils/utils';

@Directive({
  selector: '[phoneMask]',
  host: {
    '(ngModelChange)': 'onInputChange($event)',
    '(keydown.backspace)': 'onDelKeydown($event)'
  }
})
export class PhoneMaskDirective implements AfterViewInit{
  private inputElement: HTMLInputElement = null;
  constructor(
    private readonly element: ElementRef,
    private readonly model: NgControl) {
      this.inputElement = <HTMLInputElement>element.nativeElement;
  }

  ngAfterViewInit() {
    this.initElement();
    if (this.model.control.value) {
      this.onInputChange(this.model.control.value);
    }
  }

  initElement() {
    if (this.initElement) {
      this.inputElement.type = 'tel';
      this.inputElement.maxLength = 17;
    }
  }

  onInputChange(event) {
    let newVal = event.replace(/\D/g, '');
    if (newVal.length === 0) {
      newVal = '';
      this.model.control.setErrors({required: true});
    } else {
      newVal = formatPhoneNumber(newVal);
      if (isValidPhoneNumber(newVal))
      {
        this.model.control.setErrors(null);
      } else
      {
        this.model.control.setErrors({ invalid: true });
      }
    }
    this.model.valueAccessor.writeValue(newVal);
  }

  onDelKeydown(event){
    event.preventDefault();
    const value = (event.target.value || '').replace(/\D/g, '');
    this.onInputChange(value.substring(0, value.length - 1));
  }
}
