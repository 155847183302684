import { BaseVectorMapGenerator } from '../classes/basemap.base';
import { eBaseMaps, BaseMapThumbnailEnum } from '../classes/basemap.enum';

export class StreetsNightsMap extends BaseVectorMapGenerator
{
    constructor()
    {
        super();
    }

    public get thumbnail(): string { return './assets/images/streets_night_map.jpg'; }
    public get title(): string { return 'Streets Night'; }
    public get jsonUrl(): string { return './assets/vectors/street_night.json'; }
    public get allowRotation(): boolean { return true; }
    public get maxZoom(): number { return 21; }
    public get maxNativeZoom(): number { return 19; }
    public get type(): eBaseMaps { return eBaseMaps.StreetsNight; }
    public get translateKey(): BaseMapThumbnailEnum { return BaseMapThumbnailEnum.StreetsNight; }
}
