import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { MyErrorStateMatcher } from '../../../schedule/share-subscription/share-subscription.component';
import { ConfirmationDialogComponent } from '../../../shared/layout/confirmation-dialog/confirmation-dialog.component';
import { TargetedBlockingScrollStrategy } from '../../../shared/material/targeted-blocking-scroll-strategy';
import { FormGroup, FormControl, Validators, ValidatorFn, FormBuilder, ValidationErrors } from '@angular/forms';
import { skip, take } from 'rxjs/operators';
import { LocalStorageService } from '../../../shared/local-storage/local-storage.service';
import { ManageSubscriptionService } from '../../../settings/manage-subscriptions/manage-subscriptions.service';
import { ChangePasswordForm } from '../../../shared/stopfinder/models/change-password-form';
import { AndroidBackService } from '../../../androidBack.service';
import { AppService } from '../../../app.service';
import { StateService } from '../../../components/service/state/state.service';

@Component({
  selector: 'app-changepassword',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit
{
  static changePasswordResponse: boolean;
  public matcher;
  public passwordChangeForm: FormGroup;
  private formControls;
  public validationRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/;
  // external validation stored in state.service.ts
  // this property will record existing validation event since this component self will register external validation
  private existingExternalCallback: Function = null;

  constructor(
    private readonly matDialog: MatDialog,
    private readonly fb: FormBuilder,
    private readonly manageSub: ManageSubscriptionService,
    private readonly androidBackService: AndroidBackService,
    private readonly localStorageService: LocalStorageService,
    private readonly _appService: AppService,
    private readonly stateService: StateService,
    private readonly translate: TranslateService,
  )
  {
    this.matcher = new MyErrorStateMatcher();
    this.passwordChangeForm = this.fb.group({
      current: new FormControl({ value: '', disabled: false }, [Validators.required]),
      newPass: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.pattern(this.validationRegex)]),
      confirm: new FormControl({ value: '', disabled: false }, [Validators.required])
    });
    this.passwordChangeForm.setValidators(this.matchValidator());
    this.formControls = this.passwordChangeForm.controls;
  }

  ngOnInit()
  {
    this.androidBackService.onShouldCheckCallback(this.onCancel.bind(this));
    if (this.stateService.externalValidationEvent)
    {
      this.existingExternalCallback = this.stateService.externalValidationEvent;
    }
    this.stateService.disableCallback();
    this.stateService.dialogOpenStatus = true;
    this.stateService.setExternalValidation(this.onCancel.bind(this));
  }

  ngOnDestroy()
  {
    this.androidBackService.onDestroyCallback();
    this.stateService.dialogOpenStatus = false;
  }

  matchValidator(): ValidatorFn
  {
    return (group: FormGroup): ValidationErrors =>
    {
      // should same value - new password and confirmed password
      const match = _.isEqual(group.get("newPass").value, group.get("confirm").value);
      // should not same value - new password and current password
      const shouldNotSame = !_.isEqual(group.get("newPass").value, group.get('current').value);

      if (!match)
      {
        group.get("confirm").setErrors({ mismatch: true });
      } else
      {
        group.get("confirm").setErrors(null);
      }

      if (!shouldNotSame && !_.isEmpty(group.get("newPass").value))
      {
        group.get('newPass').setErrors({ matchSame: true });
      } else
      {
        const newPass = String(group.get('newPass').value);
        if (newPass.trim().length > 0)
        {
          if (!this.validationRegex.test(newPass))
          {
            group.get('newPass').setErrors({ meetRequirements: true });
          } else
          {
            group.get('newPass').setErrors(null);
          }
        }
      }
      return;
    }
  }

  onSubmit()
  {
    const form: ChangePasswordForm = {
      currentPassword: this.passwordChangeForm.get("current").value,
      password: this.passwordChangeForm.get("newPass").value,
      passwordVerify: this.passwordChangeForm.get("confirm").value,
    };

    this.manageSub.changePassword(form).pipe(skip(1), take(1)).subscribe(
      (val: boolean) =>
      {
        ChangePasswordComponent.changePasswordResponse = val;
        if (val)
        {
          this.matDialog.closeAll();
          var currentEmail = this.localStorageService.get('currentEmail');
          //Get newest token for new password.
          this._appService.login(currentEmail, this.passwordChangeForm.get("newPass").value).subscribe(
            success =>
            {
              this.matDialog
                .open(ConfirmationDialogComponent, {
                  data: {
                    title: this.translate.instant("profile.password.modal.changed.title"),
                    message: this.translate.instant("profile.password.modal.changed.body"),
                    action: this.translate.instant("profile.password.modal.changed.ok")
                  },
                  scrollStrategy: new TargetedBlockingScrollStrategy(),
                  panelClass: "confirm-dialog"
                });
            },
            failure => { }
          );
        } else
        {
          this.passwordChangeForm.get("current").setErrors({ wrong: true });
        }
      }
    );

    this.passwordChangeForm.get("current").valueChanges.subscribe(
      (val) =>
      {
        if (this.passwordChangeForm.get("current").hasError("wrong"))
        {
          this.passwordChangeForm.get("current").setErrors({ wrong: null });
          this.passwordChangeForm.updateValueAndValidity();
        }
      }
    );
  }

  onCancel(e: MouseEvent)
  {
    const _event = (e || event || window.event);
    _event && _event.preventDefault();
    const form: ChangePasswordForm = {
      currentPassword: this.passwordChangeForm.get("current").value,
      password: this.passwordChangeForm.get("newPass").value,
      passwordVerify: this.passwordChangeForm.get("confirm").value,
    };

    if (form.currentPassword || form.password || form.passwordVerify)
    {
      this.androidBackService.onDisableMultipleTarget(true);
      this.matDialog
        .open(ConfirmationDialogComponent, {
          disableClose: false,
          data: {
            title: this.translate.instant("profile.modal.cancel.title"),
            message: this.translate.instant("profile.modal.cancel.body"),
            action: this.translate.instant("profile.modal.cancel.yes"),
            secondary: true,
            secondaryAction: this.translate.instant("profile.modal.cancel.no")
          },
          scrollStrategy: new TargetedBlockingScrollStrategy(),
          panelClass: "confirm-dialog"
        })
        .afterClosed()
        .subscribe(response =>
        {
          this.androidBackService.onDisableMultipleTarget(false);
          if (response)
          {
            this.stateService.handleBlockStateCallback(true);
            this.matDialog.closeAll();
            this.passwordChangeForm.reset();
          }

          if (this.stateService.hasNotificationEvent())
          {
            this.stateService.disableCallback();
          }

          if (!this.stateService.dialogOpenStatus)
          {
            this.stateService.externalValidationEvent = this.existingExternalCallback;
          }
        });
      return;
    }
    else
    {
      this.stateService.handleBlockStateCallback(false) && this.matDialog.closeAll();
    }
  }
}
