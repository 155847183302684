import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";

enum Platform
{
  AndroidNative = 'AndroidNative',
  IosNative = 'IosNative',
  AndroidWeb = 'AndroidWeb',
  IosWeb = 'IosWeb',
  DesktopWeb = 'Web',
  Unknown = 'Unknown'
}

@Injectable()
export class DeviceService
{
  public deviceId: string;
  public deviceName: string;
  public isNativeMobile = false;
  public isAndroid = false;
  public isiOS = false;
  public isiPhone = false;
  public isiPad = false;
  public isiPhoneX = false;
  public isBrowser = false;
  public platform: Platform = Platform.Unknown;
  public deviceInstance = null;
  public deviceIdentifier: string; // this value used the register push services, please don't change elsewhere!!!
  public deviceReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public deviceDarkModeEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  public getDevice()
  {
    const platform = ((window['device'] && window['device'].platform) || '').toLowerCase();
    const model = ((window['device'] && window['device'].model) || '').toLowerCase();
    this.isBrowser = !platform || platform === "browser";
    this.deviceId = window.navigator.userAgent;
    this.deviceName = window.navigator.userAgent;
    this.isiOS = platform === "ios" || !!window.navigator.userAgent.match(/iP[(hone)|(ad)|(od)]/);
    this.isiPhone = model.includes("iphone") || !!window.navigator.userAgent.match(/iP[(hone)]/);
    this.isiPad = model.includes("ipad") || !!window.navigator.userAgent.match(/iP(ad)]/);
    this.isAndroid = platform === "android" || !!window.navigator.userAgent.match(/Android/);
    this.isiPhoneX = this.isiPhoneXCheck();
    const app = !this.isBrowser; // old check native device logic does not work for ionic webview, must use the "device" plugin to check
    this.platform = Platform.DesktopWeb;
    if (app)
    {
      this.isNativeMobile = true;
      if (this.isiOS)
      {
        this.platform = Platform.IosNative;
      }
      else if (this.isAndroid)
      {
        this.platform = Platform.AndroidNative;
      }
    }
    else
    {
      if (this.isiOS)
      {
        this.platform = Platform.IosWeb;
      }
      else if (this.isAndroid)
      {
        this.platform = Platform.AndroidWeb;
      }
    }
  }

  public initializeDevicePlugin()
  {
    const devicePlugin = window['device'];
    if (devicePlugin)
    {
      this.deviceInstance = devicePlugin;
      this.deviceId = devicePlugin.uuid;
      this.deviceIdentifier = devicePlugin.uuid;
      this.deviceName = `${devicePlugin.manufacturer} ${devicePlugin.model}`;
      this.deviceReady.next(true);
    }
  }

  private isiPhoneXCheck = () =>
  {
    if (typeof window !== 'undefined' && window)
    {
      return /iphone/gi.test(window.navigator.userAgent) && window.screen.height >= 812;
    }
    return false;
  };
}
