import * as _ from 'lodash';
import { Injectable, OnDestroy } from '@angular/core';
import
{
  StudentSchedule,
  StudentScheduleDay,
  ILastedGeoAlertNotification,
  IScheduledNotification,
  TripSchedule,
  ITripSchedule,
} from '../shared/stopfinder/models/student-schedule';
import { StopfinderApiService } from '../shared/stopfinder/stopfinder-api.service';
import { StateService } from '../components/service/state/state.service';
import { Observable, Subject, BehaviorSubject, Subscription, interval, of, iif } from 'rxjs';
import { map, flatMap, debounceTime, catchError, switchMap, first } from 'rxjs/operators';
import * as moment from 'moment';
import { Invitation, Subscriber, Communication, IAttendance, CommunicationType } from '../shared/stopfinder/stopfinder-models';
import { SCANNED_SIGNALR_KEY } from 'src/app/shared/utils/constant';
import { ConnectionState, SignalRService } from '../components/service/signalr/signalr.service';
import { StopfinderDateTimeService } from '../shared/stopfinder/stopfinder-datetime.service';
import { SignalRWrapperService } from '../components/service/signalr/signalr-wrapper.service';

export enum DateSelectionSource
{
  Unknown,
  ScheduleSwiper,
  WeekPickerSwiper,
  WeekPickerClick,
  Calendar
}

export interface DateSelectionEvent
{
  source: DateSelectionSource;
  date: Date;
}

interface IRequestDateRange { from: string; to: string }

@Injectable()
export class ScheduleService implements OnDestroy
{
  public readonly WORKING_SET_LENGTH = 7;
  public readonly CACHE_LIFETIME_MINUTES = 15;
  private readonly ISO_YMD_FORMAT = 'YYYY-MM-DD';
  private readonly today: string = moment().format(this.ISO_YMD_FORMAT);
  private readonly endOfNextYear: string = moment().add(1, 'year').endOf('month').format(this.ISO_YMD_FORMAT);
  private readonly selectedDateSubject = new BehaviorSubject<DateSelectionEvent>({ source: DateSelectionSource.Unknown, date: new Date() });
  public readonly selectedDateObservable = this.selectedDateSubject.asObservable();
  public readonly selectedDateDateObservable = this.selectedDateObservable.pipe(map(dateEvent => dateEvent.date));
  public readonly selectedDateMonthTextObservable = this.selectedDateObservable.pipe(map(dateEvent => moment(dateEvent.date).toDate().toLocaleString('en-us', { month: 'long' })));
  public readonly selectedDateYearObservable = this.selectedDateObservable.pipe(map(dateEvent => moment(dateEvent.date).year()));
  public readonly selectedDateWeekDayObservable = this.selectedDateObservable.pipe(map(dateEvent => moment(dateEvent.date).day()));

  private readonly cacheClearIntervalObservable: Observable<number> = interval(this.CACHE_LIFETIME_MINUTES * 60 * 1000);
  private readonly geoNotificationIntervalObservable: Observable<number> = interval(this.CACHE_LIFETIME_MINUTES * 4 * 1000);

  private cacheClearIntervalSubscription: Subscription;
  private geoNotificationIntervalSubscription: Subscription;
  private scannedIntervalSubscription: Subscription;
  private readonly fetchScheduleDaysRequestObservable: Subject<Date> = new Subject<Date>();
  public weeksCollection = [];
  public scheduleForFeedback: StudentSchedule = null;
  private _selectedTrip: TripSchedule = null;
  public studentSchedule: StudentSchedule = null;

  private scheduleDaysWorkingSet: Array<StudentScheduleDay> = new Array<StudentScheduleDay>(this.WORKING_SET_LENGTH);

  private readonly scheduleDaysWorkingSetSubject = new BehaviorSubject<StudentScheduleDay[]>([]);
  public readonly scheduleDaysWorkingSetObservable: Observable<StudentScheduleDay[]> = this.scheduleDaysWorkingSetSubject.asObservable()
    .pipe(
      map((scheduleDays: StudentScheduleDay[]) =>
      {
        // keep moment local as en so avoid formatting time zone for different language displaying
        moment.locale('en');
        // should find prepend date if exist, from cache
        const prependSchedule = this.fetchPreviewSchedule(moment(scheduleDays[0].date).subtract(1, 'day').format(this.ISO_YMD_FORMAT));
        const firstDay = prependSchedule || { date: moment(scheduleDays[0].date).subtract(1, 'day').format(this.ISO_YMD_FORMAT), studentSchedules: null };

        // should find append date if exist, from cache
        const appendSchedule = this.fetchPreviewSchedule(moment(scheduleDays[this.WORKING_SET_LENGTH - 1].date).add(1, 'day').format(this.ISO_YMD_FORMAT));
        const lastDay = appendSchedule || { date: moment(scheduleDays[this.WORKING_SET_LENGTH - 1].date).add(1, 'day').format(this.ISO_YMD_FORMAT), studentSchedules: null };
        return [firstDay, ...scheduleDays, lastDay];
      })
    );

  // main catch for whole schedule days
  private scheduleDays: StudentScheduleDay[] = [];
  private cachedScheduleDays: { [key: string]: StudentScheduleDay[] } = {};
  private _isFirstTimeLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public firstTimeLoaded: Observable<boolean> = this._isFirstTimeLoaded.asObservable();
  private readonly scheduleDaysSubject: BehaviorSubject<StudentScheduleDay> = new BehaviorSubject<StudentScheduleDay>(null);
  public readonly scheduleDaysObservable: Observable<StudentScheduleDay> = this.scheduleDaysSubject.asObservable();

  private geoNotificationsBody: BehaviorSubject<ILastedGeoAlertNotification[]> = new BehaviorSubject<ILastedGeoAlertNotification[]>(null);
  public readonly geoNotificationsObservable: Observable<ILastedGeoAlertNotification[]> = this.geoNotificationsBody.asObservable();

  private scannedBody: BehaviorSubject<IAttendance[]> = new BehaviorSubject<IAttendance[]>(null);
  public readonly scannedBodyObservable: Observable<IAttendance[]> = this.scannedBody.asObservable();

  private scheduleAPIPendingList: IRequestDateRange[] = [];

  public loggedIn = false;
  private _loadingDate = new BehaviorSubject<boolean>(false);
  public isDataLoaded: Observable<boolean> = this._loadingDate.asObservable();
  private forceRequestDateRange: IRequestDateRange = null;
  private _translateForTransfer: string = '(transfer)';
  private _schedulesHiddenForMap = false; // GeoAlert map (Student map) is open
  public opaqueToken: string;
  public deviceIdentifier: string;
  private destroy = new Subject<boolean>();
  private readonly _schedulesHiddenForMapSubject = new Subject<boolean>();
  public schedulesHiddenForMapObservable = this._schedulesHiddenForMapSubject.asObservable();
  private readonly _selectedTripChangedSubject = new Subject<TripSchedule>();
  public selectedTripChangedObservable = this._selectedTripChangedSubject.asObservable();

  constructor(
    private readonly apiService: StopfinderApiService,
    private readonly stateService: StateService,
    private readonly signalRWrapperService: SignalRWrapperService,
  )
  {
    // keep moment local as en so avoid formatting time zone for different language displaying
    moment.locale('en');
    this.observableRequests();
  }

  get schedulesHiddenForMap(): boolean
  {
    return this._schedulesHiddenForMap;
  }

  set schedulesHiddenForMap(val: boolean)
  {
    this._schedulesHiddenForMap = val;
    this._schedulesHiddenForMapSubject.next(val);
  }

  get translateForTransfer()
  {
    return this._translateForTransfer;
  }

  set translateForTransfer(newTranslateMsg: string)
  {
    this._translateForTransfer = newTranslateMsg;
  }

  set selectedTrip(trip: TripSchedule)
  {
    if ((!this.selectedTrip && !trip) || (this.selectedTrip && trip && this._selectedTrip.id === trip.id))
    {
      return;
    }
    this._selectedTrip = trip;
    this._selectedTripChangedSubject.next(trip);
  }

  get selectedTrip()
  {
    return this._selectedTrip;
  }

  ngOnDestroy()
  {
    this.cacheClearIntervalSubscription.unsubscribe();
    this.fetchScheduleDaysRequestObservable.unsubscribe();
    this.geoNotificationIntervalSubscription.unsubscribe();
  }

  public timerRequests()
  {
    this.cacheClearIntervalSubscription = this.cacheClearIntervalObservable.subscribe(() => this.loggedIn && this.refresh());
    this.geoNotificationIntervalSubscription = this.geoNotificationIntervalObservable.subscribe(
      () => this.refreshSchedulesGeoNotifications(),
      () =>
      {
        this.clearCache();
        this.timerRequests();
      });
  }

  private observableRequests()
  {
    this.fetchScheduleDaysRequestObservable.pipe(debounceTime(100), map(date =>
    {
      moment.locale('en');
      // set mapping key as year-(number of week)
      const yearDashWeek = `${this.getValidYear(date)}-${moment(date).week()}`;
      if (!this.cachedScheduleDays[`${yearDashWeek}`])  // there is no catch found, setup placeholder days
      {
        const placeholderDays = this.getEmptyDatedWorkingSet(moment(date).startOf('week').toDate());
        this.cachedScheduleDays[`${yearDashWeek}`] = placeholderDays;
      }

      this.consumeScheduleDays(this.cachedScheduleDays[`${yearDashWeek}`]);
      this._loadingDate.next(true);
      return date;
    }), flatMap(date => this.fetchScheduleDays(date).pipe(map((responseStudentScheduleDays: StudentScheduleDay[]) =>
    {
      moment.locale('en');
      const yearDashWeek = `${this.getValidYear(this.getSelectedDate())}-${moment(this.getSelectedDate()).week()}`;
      this.extendCachedScheduleDays(responseStudentScheduleDays);
      // pre-fetch response to consuming scheduleDays
      this.consumeScheduleDays(responseStudentScheduleDays);

      this.scheduleDaysWorkingSet = this.cachedScheduleDays[`${yearDashWeek}`];
      this.scheduleDaysWorkingSetSubject.next(this.scheduleDaysWorkingSet);
    })))).subscribe();

    // once schedule days fetched, directly request geo notifications
    this.scheduleDaysObservable.subscribe(() =>
    {
      this.refreshSchedulesGeoNotifications();
    });
  }

  shareSubscription(
    riderId: number,
    email: string,
    first: string,
    last: string,
    startDate: string,
    endDate: string,
    comments: string,
    cellPhone: string
  ): Observable<any>
  {
    const invitation: Invitation = { riderId, email, first, last, startDate, endDate, comments, cellPhone };
    return this.apiService.postShareInvitation(invitation);
  }

  addWeeksToSelectedDate(numWeeks: number, source: DateSelectionSource)
  {
    const newDate = moment(this.selectedDateSubject.value.date).add(numWeeks, 'weeks').toDate();
    this.selectedDateSubject.next({ source, date: newDate });
  }

  addDaysToSelectedDate(numDays: number, source: DateSelectionSource)
  {
    const newDate = moment(this.selectedDateSubject.value.date).add(numDays, 'days').toDate();
    this.selectedDateSubject.next({ source, date: newDate });
  }

  public getSelectedDate = (): Date => this.selectedDateSubject.value.date;

  public getSelectedWeekDay = (): number => moment(this.selectedDateSubject.value.date).day();

  setSelectedDate = (date: Date, source: DateSelectionSource) => this.selectedDateSubject.next({ source, date });

  refresh = () =>
  {
    this.requestWorkingSet(this.getSelectedDate(), true);
  }

  clearCache()
  {
    this.scheduleDays = new Array<StudentScheduleDay>();
    this.cachedScheduleDays = {};
    this.scheduleAPIPendingList.length = 0;
    this.scheduleDaysSubject.next(null);
    this.cacheClearIntervalSubscription && this.cacheClearIntervalSubscription.unsubscribe();
    this.geoNotificationIntervalSubscription && this.geoNotificationIntervalSubscription.unsubscribe();
    this.scannedIntervalSubscription && this.scannedIntervalSubscription.unsubscribe();
  }

  clearDate = () => this.selectedDateSubject.next({ source: DateSelectionSource.Unknown, date: new Date() });

  getEmptyDatedWorkingSet(startDate: Date): StudentScheduleDay[]
  {
    const clearDatedSet = new Array<StudentScheduleDay>(this.WORKING_SET_LENGTH);
    for (let i = 0; i < this.WORKING_SET_LENGTH; i++)
    {
      clearDatedSet[i] = { date: moment(startDate).add(i, 'days').format(this.ISO_YMD_FORMAT), studentSchedules: null };
    }
    return clearDatedSet;
  }

  private setEmptyCacheScheduleDays(date: Date)
  {
    const startDate = moment(date).startOf('week');
    this.cachedScheduleDays[`${this.getValidYear(date)}-${moment(date).week()}`] = this.getEmptyDatedWorkingSet(startDate.toDate());
    this.fetchScheduleDaysRequestObservable.next(date);
  }

  private requestDate(date: Date): boolean
  {
    const day = moment(date);

    if (day.isBefore(moment(this.endOfNextYear)) && day.isAfter(moment(this.today)))
    {
      const cacheWeek = this.cachedScheduleDays[`${this.getValidYear(date)}-${day.week()}`];
      if (!cacheWeek)
      {
        this.setEmptyCacheScheduleDays(day.toDate());
        return true;
      }
      else
      {
        const cacheData = _.find(cacheWeek, (sd: StudentScheduleDay) => day.isSame(sd.date, 'day'));
        return cacheData && _.isNull(cacheData.studentSchedules);
      }
    }
    return false;
  }

  private getValidYear(date: Date): number
  {
    const startDateOfYear = moment(date).startOf('week').year();
    const endDateOfYear = moment(date).endOf('week').year();

    return endDateOfYear > startDateOfYear ? endDateOfYear : startDateOfYear;
  }

  requestSet(date: Date, autoRefresh?: boolean): StudentScheduleDay[]
  {
    moment.locale('en');
    this.forceRequestDateRange = null;
    const yearDashWeek = `${this.getValidYear(date)}-${moment(date).week()}`;
    const currentWeekSchedule = this.cachedScheduleDays[`${yearDashWeek}`];

    if (!currentWeekSchedule)
    {
      this.setEmptyCacheScheduleDays(date);
    } else
    {
      const todayIsNull = this.requestDate(moment(date).toDate());
      const tomorrowIsNull = this.requestDate(moment(date).add(1, 'days').toDate());
      const yesterdayIsNull = this.requestDate(moment(date).subtract(1, 'days').toDate());

      if (
        (todayIsNull && tomorrowIsNull && yesterdayIsNull) ||
        (!todayIsNull && tomorrowIsNull && yesterdayIsNull)
      )
      {
        this.forceRequestDateRange = {
          from: moment(date).subtract(1, 'days').format(this.ISO_YMD_FORMAT),
          to: moment(date).add(1, 'days').format(this.ISO_YMD_FORMAT)
        };
        this.fetchScheduleDaysRequestObservable.next(moment(date).toDate());
      }

      if (todayIsNull && !yesterdayIsNull)
      {
        this.fetchScheduleDaysRequestObservable.next(moment(date).toDate());
      }

      if (todayIsNull && !tomorrowIsNull)
      {
        this.fetchScheduleDaysRequestObservable.next(moment(date).subtract(1, 'days').toDate());
      }

      if (!todayIsNull && !tomorrowIsNull && yesterdayIsNull)
      {
        this.fetchScheduleDaysRequestObservable.next(moment(date).subtract(2, 'days').toDate());
      }

      if (!todayIsNull && !yesterdayIsNull && tomorrowIsNull)
      {
        const isSameWeek: boolean = moment(date).isSame(this.today, 'week');
        if (isSameWeek)
        {
          this.fetchScheduleDaysRequestObservable.next(moment(date).add(1, 'days').toDate());
        } else
        {
          this.fetchScheduleDaysRequestObservable.next(moment(date).add(2, 'days').toDate());
        }
      }
    }

    if (autoRefresh)
    {
      this.fetchScheduleDaysRequestObservable.next(moment(date).toDate());
    }

    // once load schedules from cache, which means route back to schedule.
    // directly request geo notifications
    this.refreshSchedulesGeoNotifications();

    return this.cachedScheduleDays[`${yearDashWeek}`];
  }

  requestWorkingSet(date: Date, autoRefresh?: boolean)
  {
    const workingSet = this.requestSet(date, autoRefresh);
    this.scheduleDaysWorkingSet = workingSet;
    this.scheduleDaysWorkingSetSubject.next(this.scheduleDaysWorkingSet);
  }

  private extendCachedScheduleDays(responseStudentScheduleDays: StudentScheduleDay[])
  {
    _.forEach(responseStudentScheduleDays, (scheduleDays: StudentScheduleDay) =>
    {
      const key = `${this.getValidYear(moment(scheduleDays.date).toDate())}-${moment(scheduleDays.date).week()}`;

      if (!this.cachedScheduleDays[`${key}`])
      {
        this.cachedScheduleDays[`${key}`] = this.getEmptyDatedWorkingSet(moment(scheduleDays.date).toDate());
      }
    });
  }

  consumeScheduleDays(studentScheduleDays: StudentScheduleDay[])
  {
    moment.locale('en');
    _.forEach(studentScheduleDays, (studentScheduleDay: StudentScheduleDay) =>
    {
      const yearDashWeek = `${this.getValidYear(moment(studentScheduleDay.date).toDate())}-${moment(studentScheduleDay.date).week()}`;
      _.map(this.cachedScheduleDays[`${yearDashWeek}`], (cachedScheduleDay: StudentScheduleDay) =>
      {
        if (moment(studentScheduleDay.date).isSame(cachedScheduleDay.date, 'day'))
        {
          cachedScheduleDay.studentSchedules = this.sortScheduleDay(studentScheduleDay).studentSchedules;
        }
      });
    });
  }

  private convertDate(source: Date | string, target: Date | string): string
  {
    return moment(source).format('YYYY-MM-DDT') + moment(target).format('HH:mm:ss');
  }

  private sortScheduleDay(studentScheduleDay: StudentScheduleDay): StudentScheduleDay
  {
    if (!_.isNull(studentScheduleDay.studentSchedules))
    {
      // store today schedules into this.scheduleDaysSubject
      if (moment(studentScheduleDay.date).isSame(this.today))
      {
        !_.isNull(studentScheduleDay.studentSchedules) && _.isNull(this.scheduleDaysSubject.value) && this.scheduleDaysSubject.next(studentScheduleDay);
      }

      _.forEach(studentScheduleDay.studentSchedules, (studentSchedule: StudentSchedule) =>
      {
        studentSchedule.date = studentScheduleDay.date;

        this.formatTripStartAndFinishTime(studentSchedule.date, studentSchedule.trips);
      });

      studentScheduleDay.studentSchedules = _.sortBy(studentScheduleDay.studentSchedules, [
        (schedule: StudentSchedule) => (schedule.lastName || "").toLowerCase() + (schedule.firstName || "").toLowerCase()
      ], ['asc']);
    }

    return studentScheduleDay;
  }

  public formatTripStartAndFinishTime(date: string, trips: TripSchedule[])
  {
    !_.isEmpty(trips) && _.forEach(trips, (trip: TripSchedule) =>
    {
      trip.startTime && (trip.startTime = this.convertDate(date, trip.startTime));
      trip.pickUpTime && (trip.pickUpTime = this.convertDate(date, trip.pickUpTime));
      trip.dropOffTime && (trip.dropOffTime = this.convertDate(date, trip.dropOffTime));
      trip.finishTime && (trip.finishTime = this.convertDate(date, trip.finishTime));
    });
  }

  fetchScheduleDays(date: Date): Observable<StudentScheduleDay[]>
  {
    const range = this.getRequestDateRange(date);

    if (_.isNull(range))
    {
      return of(this.fetchExistedSchedules(date));
    }

    return this.apiService.getStudentSchedules(range.from, range.to).pipe(map(response =>
    {
      response && !this._isFirstTimeLoaded.value && this._isFirstTimeLoaded.next(true);
      const scheduleDaysArray: StudentScheduleDay[] = _.sortBy(response, 'date');
      this.consumeScheduleDays(scheduleDaysArray);

      _.remove(this.scheduleAPIPendingList, (item: IRequestDateRange) => _.isEqual(item.from, range.from) && _.isEqual(item.to, range.to));
      this._loadingDate.next(false);
      return scheduleDaysArray;
    }), catchError(error =>
    {
      return of(this.getEmptyDatedWorkingSet(moment(this.getSelectedDate()).startOf('week').toDate()));
    }));
  }

  fetchExistedSchedules(date: Date): StudentScheduleDay[]
  {
    const key = `${this.getValidYear(date)}-${moment(date).week()}`;
    return this.cachedScheduleDays[`${key}`] || this.getEmptyDatedWorkingSet(moment(date).startOf('week').toDate());
  }

  private getRequestDateRange(date: Date): IRequestDateRange | null
  {
    const isSameWeek: boolean = moment(date).isSame(this.today, 'week');
    let dateFrom, dateTo;

    if (!_.isNull(this.forceRequestDateRange))
    {
      dateFrom = this.forceRequestDateRange.from;
      dateTo = this.forceRequestDateRange.to;
    } else
    {
      if (!isSameWeek)
      {
        dateFrom = moment(date).subtract(1, 'days').format(this.ISO_YMD_FORMAT);
      } else
      {
        dateFrom = moment(date).format(this.ISO_YMD_FORMAT);
      }

      dateTo = moment(date).add(1, 'days').format(this.ISO_YMD_FORMAT);
    }

    if (
      _.find(this.scheduleAPIPendingList, (dateRange: IRequestDateRange) =>
      {
        return _.isEqual(dateRange.from, dateFrom) && _.isEqual(dateRange.to, dateTo);
      })
    )
    {
      return null;
    }

    this.scheduleAPIPendingList.push({
      from: dateFrom,
      to: dateTo
    });

    return {
      from: dateFrom,
      to: dateTo
    }
  }

  refreshScheduleDays()
  {
    this.scheduleDays = [];
    this.fetchScheduleDaysRequestObservable.next(this.getSelectedDate());
  }

  fetchPreviewSchedule(date: string): StudentScheduleDay
  {
    const yearDashWeek: string = `${this.getValidYear(moment(date).toDate())}-${moment(date).week()}`;
    return _.find(this.cachedScheduleDays[`${yearDashWeek}`], { date: date });
  }

  getTodaySchedules = (): StudentScheduleDay => this.scheduleDaysSubject.value || null;

  public getScheduledNotifications()
  {
    const todaySchedules: StudentSchedule[] = _.get(this.getTodaySchedules(), 'studentSchedules');
    const subscriber: Subscriber = this.stateService.subscriber;

    if (!_.isUndefined(todaySchedules) && !_.isEmpty(todaySchedules) && !_.isNull(subscriber))
    {
      let scheduledData: IScheduledNotification[] = [];
      // iterate StudentSchedule[]
      _.forEach(todaySchedules, (eachSchedule: StudentSchedule) =>
      {
        // iterate TripSchedules[] in each StudentSchedule
        eachSchedule.dataSourceId && !_.isEmpty(eachSchedule.trips) && _.forEach(eachSchedule.trips, (eachTrip: TripSchedule) =>
        {
          scheduledData.push({
            riderId: eachSchedule.riderId,
            subscriberId: subscriber.id,
            tripId: eachTrip.id,
            dataSourceId: eachSchedule.dataSourceId,
          } as IScheduledNotification);
        });
      });

      !_.isEmpty(scheduledData) && this.apiService.getScheduledGeoNotifications(subscriber.id, scheduledData).toPromise()
        .then((geoNotifications: ILastedGeoAlertNotification[]) => this.geoNotificationsBody.next(geoNotifications));
    }
  }

  public updateGeoNotification(riderId: number, tripId: number, dataSourceId: number)
  {
    let scheduledData: IScheduledNotification[] = [];
    const subscriberId: number = _.get(this.stateService.subscriber, 'id') || null;

    if (!_.isNull(subscriberId))
    {
      scheduledData.push({
        riderId: riderId,
        subscriberId: subscriberId,
        tripId: tripId,
        dataSourceId: dataSourceId,
      } as IScheduledNotification);

      // get current cached notifications
      const currentNotifications: ILastedGeoAlertNotification[] = this.geoNotificationsBody.value || [];

      this.apiService.getScheduledGeoNotifications(subscriberId, scheduledData).toPromise()
        .then((geoNotifications: ILastedGeoAlertNotification[]) =>
        {
          if (!_.isEmpty(geoNotifications))
          {
            // manually insert notification
            _.forEach(geoNotifications, (geoNotification: ILastedGeoAlertNotification) => currentNotifications.push(geoNotification));
            // update observable notification subject
            this.geoNotificationsBody.next(currentNotifications);
          }
        });
    }
  }

  public refreshWeek(yearDashWeek: string)
  {
    this.scheduleDaysWorkingSetSubject.next(this.cachedScheduleDays[yearDashWeek]);
  }

  public refreshSchedulesGeoNotifications()
  {
    if (moment(moment(this.getSelectedDate()).format(this.ISO_YMD_FORMAT)).isSame(this.today))
    {
      this.getScheduledNotifications();
    }
  }

  public getStudentScheduleByTripId(data: any, tripId: number, dataSourceId: number): StudentSchedule
  {
    return _.find(data, (schedule: StudentSchedule) =>
    {
      return _.isEqual(Number(schedule.dataSourceId), Number(dataSourceId)) && !_.isEmpty(schedule.trips) && _.find(schedule.trips, (trip: TripSchedule) =>
      {
        return _.isEqual(Number(trip.id), Number(tripId));
      });
    }) || null;
  }

  public async getStudentScheduleWithNotification(communication: Communication): Promise<StudentSchedule>
  {
    const todayStudentSchedule = (this.scheduleDaysSubject.value && this.scheduleDaysSubject.value.studentSchedules) || [];
    let studentSchedules: StudentSchedule[] = todayStudentSchedule.filter(item => Number(item.riderId) === Number(communication.riderId));

    if (!studentSchedules.length)
    {
      studentSchedules = await this.apiService.getStudentScheduleByRiderId(moment().format(this.ISO_YMD_FORMAT), communication.riderId).toPromise();
    }

    let studentSchedule = null;

    if (communication.type === CommunicationType.GeoAlert || communication.type === CommunicationType.EtaAlert) 
    {
      studentSchedule = this.getStudentScheduleByTripId(studentSchedules, communication.tripId, communication.dataSourceId);
    } else if (communication.type === CommunicationType.Attendance)
    {
      studentSchedule = _.find(studentSchedules, (schedule: StudentSchedule) => { return _.isEqual(Number(schedule.dataSourceId), Number(communication.dataSourceId)) });
    }

    if (!studentSchedule) return;
    studentSchedule.date = moment().format(this.ISO_YMD_FORMAT);

    return studentSchedule;
  }

  public refreshcachedScheduleDaysAttendanceValue(riderId: number, attendanceValue: boolean)
  {
    const cachedScheduleDays = _.values(this.cachedScheduleDays);
    _.forEach(cachedScheduleDays, (scheduleDays) =>
    {
      _.forEach(scheduleDays, (scheduleDay) =>
      {
        if (scheduleDay.studentSchedules !== null)
        {
          _.forEach(scheduleDay.studentSchedules, (studentSchedule) =>
          {
            if (studentSchedule.riderId === riderId)
            {
              studentSchedule.attendanceValue = attendanceValue;
            }
          });
        }
      });
    });
  }

  public getTripScheduleOrder(studentSchedule: StudentSchedule): StudentSchedule
  {
    const TRIP_TRANSFER_STEPPER = this.translateForTransfer;
    if (_.isEmpty(studentSchedule.trips))
    {
      return studentSchedule;
    }

    studentSchedule.trips.length >= 2 && _.forEach(studentSchedule.trips, (trip: ITripSchedule, index: number) =>
    {
      let nextTrip: ITripSchedule = studentSchedule.trips[index + 1];
      if (nextTrip && nextTrip.toSchool == trip.toSchool && (trip.isException != nextTrip.isException || (trip.isException && nextTrip.isException)))
      {
        trip.needSeparator = true;
      }

      if (index + 1 <= studentSchedule.trips.length)
      {
        if (index !== 0)
        {
          let prevTransferTrip: ITripSchedule;
          for (let i = index - 1; i >= 0; i--)
          {
            let currentTrip = studentSchedule.trips[i];

            if (!currentTrip.isException)
            {
              prevTransferTrip = studentSchedule.trips[i];
              break;
            }
          }

          if (trip.isException)
          {
            return;
          }

          // add transfer symbol to those stops when they need
          if (prevTransferTrip && ((prevTransferTrip.toSchool && trip.toSchool) || (!prevTransferTrip.toSchool && !trip.toSchool)))
          {
            if (trip.isTransfer || (prevTransferTrip.isTransfer && !trip.isTransfer))
            {
              prevTransferTrip.dropOffSubtitle = `${TRIP_TRANSFER_STEPPER}`;
              trip.pickupSubtitle = `${TRIP_TRANSFER_STEPPER}`;
            }
          }

          // when prev trip is to school but next trip is from school
          if (prevTransferTrip && (prevTransferTrip.toSchool && !trip.toSchool))
          {
            prevTransferTrip.dropOffSubtitle = ``;
          }
        }
      }
    });

    return studentSchedule;
  }

  public async checkSchedule(studentSchedule: StudentSchedule, isOpenedFromBackgroundNotification: boolean = false)
  {
    if (!studentSchedule || !studentSchedule.dataSourceId)
    {
      return studentSchedule;
    }

    // if open the map from background notification, don't need send request to get new data
    if (isOpenedFromBackgroundNotification)
    {
      this.formatTripStartAndFinishTime(studentSchedule.date, studentSchedule.trips);
      return studentSchedule;
    }

    const scheduleDate: string = moment(studentSchedule.date).format('YYYY-MM-DD');
    return this.apiService.getStudentScheduleByRiderId(scheduleDate, studentSchedule.riderId).toPromise().then(studentSchedules =>
    {
      const schedule = studentSchedules.filter(schedule => schedule.dataSourceId === studentSchedule.dataSourceId);
      if (schedule.length <= 0)
      {
        return studentSchedule;
      };
      const defaultSchedule = schedule[0];
      defaultSchedule.date = studentSchedule.date;
      const studentScheduleDay = { ...this.fetchPreviewSchedule(scheduleDate) };
      const updateIndex = (studentScheduleDay.studentSchedules || []).findIndex(item => item.clientId === defaultSchedule.clientId
        && item.riderId === defaultSchedule.riderId && item.dataSourceId === defaultSchedule.dataSourceId);
      if (updateIndex < 0)
      {
        this.formatTripStartAndFinishTime(defaultSchedule.date, defaultSchedule.trips);
        return defaultSchedule;
      }
      studentScheduleDay.studentSchedules[updateIndex] = { ...defaultSchedule };

      this.consumeScheduleDays([studentScheduleDay]);
      const yearDashWeek = `${moment(studentSchedule.date).year()}-${moment(studentSchedule.date).week()}`;
      this.refreshWeek(yearDashWeek);

      return defaultSchedule;
    });
  }

  public isStopfinderV2(studentSchedule: StudentSchedule): boolean
  {
    return +studentSchedule.dataSourceId > 0;
  }

  /**
   * Function to truncate student name.
   * Max length for displaying: 13 characters
   * Those name over 13 would separate to two parts:
   *   1. firstName + lastName (less than 10 characters) + ... (3 characters) as eclipse string
   *   2. firstName (display first letter and together with .) + last name + ... (3 characters) as eclipse string
   * @param first Mandatory. First name
   * @param last Mandatory. Last name
   * @return string - Generated name
   */
  public truncateStudentName(first: string, last: string)
  {
    // Max length for displaying name set as 13 characters
    const maxLength = 13;
    let truncatedName = '';
    const fullName = `${first} ${last}`;

    if (fullName.length <= maxLength)
    {
      truncatedName = fullName;
    }
    else if (last.length <= maxLength - 3)
    {
      truncatedName = `${first.split('').shift()}. ${last}`;
    }
    else
    {
      truncatedName = `${first.split('').shift()}. ${last.substr(0, (maxLength - 3))}...`;
    }

    return truncatedName;
  }

  public async getGeoAlertInfoForSchedule(studentSchedule: StudentSchedule)
  {
    const studentRiderId = studentSchedule.riderId;
    const result = await this.apiService.getGPSSwitch(studentSchedule.clientId, studentRiderId).toPromise();
    studentSchedule.enableGeoAlerts = result.GeoAlertEnabled;
    studentSchedule.enableEtaAlerts = result.EtaAlertEnabled;
    return (result.GPSEnabled && studentSchedule.enableGeoAlerts);
  }

  public isTripRunning(studentSchedule: StudentSchedule, tripSchedule: TripSchedule): boolean
  {
    moment().locale('en');
    let startTime = moment(tripSchedule.startTime);
    let finishTime = moment(tripSchedule.finishTime);
    // start and end times are in client timezones
    startTime = startTime.utcOffset(studentSchedule.timeZoneMinutes, true);
    finishTime = finishTime.utcOffset(studentSchedule.timeZoneMinutes, true);
    // transform utc time to client specified local time
    startTime = startTime.utc();
    finishTime = finishTime.utc();
    // individual trip adjustment
    startTime = this.getAdjustTime(startTime, tripSchedule.adjustMinutes);
    finishTime = this.getAdjustTime(finishTime, tripSchedule.adjustMinutes);
    // schedule wide adjustment
    startTime = this.getAdjustTime(startTime, studentSchedule.beforeTrip, true);
    finishTime = this.getAdjustTime(finishTime, studentSchedule.afterTrip);
    /**
     * - Undefined is passed to not change the granularity of the comparison, ie. minutes vs hours vs days
     * - The 4th parameter is moments implementation of specification of inclusivity/exclusivity, by default it
     * will exclude the times passed as parameters, here we want it to be inclusive
     * i.e. time is 3:30 PM and dropOffTime is 3:30 PM by default that is false, by specifying inclusivity it becomes true
    */
    let now = moment.utc();
    return now.isBetween(startTime, finishTime, 'm', '[]');
  }

  public getAdjustTime(time: any, adjustMinutes: number, subtract = false)
  {
    if (!adjustMinutes)
    {
      return time;
    }
    const newTime = time;

    if (newTime.isValid())
    {
      if (subtract)
      {
        newTime.subtract('m', adjustMinutes);
      } else
      {
        newTime.add('m', adjustMinutes);
      }
      return newTime;
    }
    return time;
  }

  public isScheduleToday(studentSchedule: StudentSchedule)
  {
    moment().locale('en');
    return studentSchedule && moment(studentSchedule.date).isSame(moment(), 'day');
  }

  public async setGeoAlertForSchedule(studentSchedule: StudentSchedule)
  {
    if (this.isScheduleToday(studentSchedule) && this.tripOnScheduleRunning(studentSchedule))
    {
      const geoAlertsEnabled = await this.getGeoAlertInfoForSchedule(studentSchedule);
      studentSchedule.enableGeoAlerts = geoAlertsEnabled;
    }
  }

  private tripOnScheduleRunning(studentSchedule: StudentSchedule): boolean
  {
    return !studentSchedule.trips.every((t: TripSchedule) => this.isScheduleToday(studentSchedule) && !this.isTripRunning(studentSchedule, t));
  }

  public getDateDiffMs(timestamp: number): number
  {
    let ms = 0;
    if (timestamp)
    {
      moment().locale('en');
      const currentTime = moment();
      let gpsTime = moment.unix(timestamp);
      ms = currentTime.diff(gpsTime, 'ms'); //return milliseconds
    }
    return ms;
  }

  public updateScheduleProperties(studentSchedule: StudentSchedule, tripSchedule: TripSchedule, propertyName: string, value: any): void
  {
    const date = moment().format('YYYY-MM-DD');
    const scheduleForDate = this.scheduleDaysWorkingSet.find((sdws) => date === sdws.date);
    if (scheduleForDate && scheduleForDate.studentSchedules)
    {
      const scheduleForStudent = scheduleForDate.studentSchedules.find((ss) => ss.riderId === studentSchedule.riderId);
      if (scheduleForStudent && scheduleForStudent.trips)
      {
        const tripScheduleToUpdate = scheduleForStudent.trips.find((trip) => trip.id === tripSchedule.id);
        tripScheduleToUpdate[propertyName] = value;
        this.scheduleDaysWorkingSetSubject.next([].concat(this.scheduleDaysWorkingSet));
      }
    }
  }

  public closeSchedulesSignalRConnections()
  {
    this.signalRWrapperService.closeAllConnections();
    this.destroy.next(true);
  }
}
