import { Injectable, NgZone } from "@angular/core";
import { IBusParameter, IVehiclePoint } from "src/app/shared/stopfinder/models/map";
import { StudentSchedule, TripSchedule } from "src/app/shared/stopfinder/stopfinder-models";
import { NativeMapView } from "src/app/tf-map/core/native-mapview";
import { ScheduleService } from "../../../schedule/schedule.service";
import { GPSStatus, VehicleLocationService } from "src/app/shared/vehicle-location.service";
import { tap, switchMap, first, takeUntil, filter, take, takeWhile, map, concatMap, finalize, catchError } from "rxjs/operators";
import { Observable, merge, of, concat } from "rxjs";
import * as moment from "moment";
import { HIDE_BUS_ICON_INTERVAL } from "src/app/shared/utils/constant";
import { TFMapType } from "src/app/shared/utils/enum";
import { StateService } from "../state/state.service";


@Injectable()
export class MapVehicleService
{
	public static alreadyCenterBus = false;
	public static centerToBusTimer: number | null = null;
	public static GPSEnabled = true;
	public static isVehicleDisplayingOnMap: boolean = false; // location is sent to location display to show on the map. return true when native side completes the drawing.
	private _isVehicleWatchingOn: boolean = false; // turn on location display capability at native side
	private _currentLocation: IVehiclePoint = null;
	private _removeBusTimer: number; // timeout id

	constructor(
		private readonly _ngZone: NgZone,
		private readonly _scheduleService: ScheduleService,
		private readonly _vehicleLocationService: VehicleLocationService,
		private readonly _stateService: StateService,
	) { }

	get busPoint(): IVehiclePoint
	{
		return this._currentLocation;
	}

	public clearCenterToBus = () =>
	{
		// clear the center timer
		MapVehicleService.alreadyCenterBus = false;
		MapVehicleService.centerToBusTimer && clearTimeout(MapVehicleService.centerToBusTimer);
	}

	private hideVehicle(mapId: string): Observable<boolean>
	{
		return new Observable((subscriber) =>
		{
			NativeMapView.nativeEsriMapPlugin.toggleShowVehicle(mapId, false,
				() =>
				{
					MapVehicleService.isVehicleDisplayingOnMap = false;
					this.clearCenterToBus();
					subscriber.next(true);
					subscriber.complete();
				},
				() =>
				{
					subscriber.next(false);
					subscriber.complete();
				}
			);
		});
	}

	public turnOffVehicleLocationDisplay(mapId: string): Observable<boolean>
	{
		return new Observable((subscriber) =>
		{
			NativeMapView.nativeEsriMapPlugin.turnOffVehicleWatching(mapId,
				() =>
				{
					this._isVehicleWatchingOn = false;
					MapVehicleService.isVehicleDisplayingOnMap = false;
					subscriber.next(true);
					subscriber.complete();
				},
				() =>
				{
					subscriber.next(false);
					subscriber.complete();
				}
			);
		});
	}

	public startMonitoringBusLocation(param: IBusParameter): Observable<void>
	{
		const mapId = param.mapId, tripId = param.tripId, studentSchedule = param.studentSchedule, selectedTrip = param.selectedTrip, appService = param.appService;
		if (!tripId || !selectedTrip || !studentSchedule.displayVehicleOnMap || !MapVehicleService.GPSEnabled || !this._scheduleService.isTripRunning(studentSchedule, selectedTrip))
		{
			return of(null);
		}

		const switchTrip$ = this._scheduleService.selectedTripChangedObservable.pipe(take(1));
		const existMap$ = this._scheduleService.schedulesHiddenForMapObservable.pipe(first(val => !val));
		const pause = appService.pauseEvent;

		const lastKnownLocation$ = this.getLastKnownBusLocation(mapId, studentSchedule, selectedTrip);
		const signalRLocation$ = this._vehicleLocationService.monitorSignalRLocation(studentSchedule, selectedTrip);
		const vehicleLocation$ = concat(lastKnownLocation$, signalRLocation$).pipe(
			takeUntil(merge(existMap$, switchTrip$, pause)),
			takeWhile(() => this._stateService.getMapState() === TFMapType.GeoAlertMap && this._scheduleService.isTripRunning(studentSchedule, selectedTrip)),
			filter(val => val && val.busNumber && this._scheduleService.selectedTrip.busNumber === val.busNumber),
			switchMap(vehiclePoint => this.updateBusLocationOnMap(mapId, studentSchedule, selectedTrip, vehiclePoint)),
			catchError(() => of(null)));

		return this.turnOnVehicleLocationDisplay(mapId).pipe(concatMap(() => vehicleLocation$));
	}

	private turnOnVehicleLocationDisplay(mapId: string): Observable<boolean>
	{
		if (this._isVehicleWatchingOn)
		{
			return of(null);
		}

		return new Observable((subscriber) =>
		{
			NativeMapView.nativeEsriMapPlugin.turnOnVehicleWatching(mapId,
				() =>
				{
					this._isVehicleWatchingOn = true;
					subscriber.next(true);
					subscriber.complete();
				},
				() =>
				{
					subscriber.next(false);
					subscriber.complete();
				});
		});
	}

	private updateBusLocationOnMap(mapId: string, studentSchedule: StudentSchedule, tripSchedule: TripSchedule, vehiclePoint: IVehiclePoint): Observable<void>
	{
		this._currentLocation = this._currentLocation ? this._currentLocation.Timestamp > vehiclePoint.Timestamp ? this._currentLocation : vehiclePoint : vehiclePoint;
		return new Observable((subscriber) =>
		{
			const busPoint = this._currentLocation;
			if (this._scheduleService.selectedTrip.busNumber !== busPoint.busNumber) 
			{
				subscriber.error();
				subscriber.complete();
			}
			else
			{
				NativeMapView.nativeEsriMapPlugin.updateGeoAlertBusLocation(mapId, [busPoint.Longitude, busPoint.Latitude],
					() =>
					{
						this._scheduleService.updateScheduleProperties(studentSchedule, tripSchedule, 'gpsStatus', GPSStatus.ValidGPS);
						MapVehicleService.isVehicleDisplayingOnMap = true;
						this.removeBusFromMapAfter5Mins(mapId, busPoint.Timestamp); // don't show the bus if its location is outdated
						subscriber.next();
						subscriber.complete();
					},
					() => 
					{
						subscriber.error();
						subscriber.complete();
					});
			}
		});
	}

	private removeBusFromMapAfter5Mins(mapId: string, timeStamp: number)
	{
		if (!!this._removeBusTimer)
		{
			window.clearTimeout(this._removeBusTimer);
		}

		let timeOut = HIDE_BUS_ICON_INTERVAL; // millseconds
		if (!!timeStamp)
		{
			const timeDiff = moment().utc().diff(moment(timeStamp * 1000).utc()); // current time - gps event time
			timeOut = HIDE_BUS_ICON_INTERVAL - timeDiff;
		}

		if (timeOut <= 0) 
		{
			this.hideVehicle(mapId).subscribe();
		}
		else
		{
			this._removeBusTimer = window.setTimeout(() =>
			{
				this.hideVehicle(mapId).subscribe();
			}, timeOut);
		}
	}

	private getLastKnownBusLocation(mapId: string, schedule: StudentSchedule, selectedTrip: any): Observable<any>
	{
		return this._vehicleLocationService.getLastKnownVehicleLocation(schedule, selectedTrip).pipe(take(1), switchMap((lastPoint) =>
		{
			if (lastPoint || selectedTrip.gpsStatus !== GPSStatus.NoVehicleAssigned)
			{
				if (lastPoint && this._vehicleLocationService.isValidEvent(lastPoint))
				{
					this._vehicleLocationService.handleGPSTimer(schedule, selectedTrip, this._vehicleLocationService.getTimeDiffMS(lastPoint));
					this._scheduleService.updateScheduleProperties(schedule, selectedTrip, 'gpsStatus', GPSStatus.ValidGPS);
					return of(lastPoint);
				}
				else
				{
					this._scheduleService.updateScheduleProperties(schedule, selectedTrip, 'gpsStatus', GPSStatus.NotAvailable);
					return this.hideVehicle(mapId).pipe(map(() => null));
				}
			}
			else 
			{
				return of(null);
			}
		}));
	}
}
