import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ForgotPasswordService } from './forgot-password.service';
import { PasswordValidation } from '../shared/stopfinder/validation/password-validation';
import { zip } from 'rxjs';
import { AppService } from '../app.service';
import { DeviceService } from '../components/service/device/device.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  providers: [ForgotPasswordService]
})
export class ForgotPasswordComponent implements OnInit
{

  public activationForm: FormGroup;
  public isGuidValid = true;
  public isLoading = true;
  public isChanged = false;
  public guidParameter: string;
  public emailParameter: string;
  public subscriberId: number;
  public forgotPasswordSentOn: string;
  public validationRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/;
  public readonly fullYear = new Date().getFullYear();
  private readonly password = new FormControl('', [Validators.required,
  Validators.pattern(this.validationRegex)]);
  private readonly passwordConfirmation = new FormControl('', [Validators.required,
  Validators.minLength(8)]);

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly _appService: AppService,
    private readonly deviceService: DeviceService,
    public forgotPasswordService: ForgotPasswordService,
    public route: ActivatedRoute
  ) { }

  ngOnInit()
  {
    const parameterObservables = zip(this.route.params, this.route.queryParams);
    parameterObservables.subscribe(([p, qp]) =>
    {
      this.guidParameter = p['guid'] || qp['guid'];
      this.emailParameter = p['email'] || qp['email'];
      this.getEnrollment();
    });

    this.activationForm = this.formBuilder.group({
      password: this.password,
      passwordConfirmation: this.passwordConfirmation
    }, {
      validator: PasswordValidation.MatchPassword
    });
  }

  getEnrollment()
  {
    this.forgotPasswordService
      .getForgotPasswordEnrollment(this.emailParameter).toPromise().then(
        enrollment =>
        {
          this.checkForgotPasswordStatus();
        },
        () =>
        {
          this.isGuidValid = false;
          this.isLoading = false;
        });
  }

  checkForgotPasswordStatus()
  {
    this.forgotPasswordService
      .checkForgotPassword(this.guidParameter, this.emailParameter)
      .subscribe(
        forgotPassword =>
        {
          this.subscriberId = forgotPassword.subscriberId;
          this.forgotPasswordSentOn = forgotPassword.sentOn;
          this.isLoading = false;
        },
        () =>
        {
          this.isGuidValid = false;
          this.isLoading = false;
        });
  }

  isForgotPasswordExpired()
  {
    if (this.forgotPasswordSentOn)
    {
      const baseSentOn = this.forgotPasswordSentOn;
      const splitSentOn = baseSentOn.split(/[^0-9]/);
      const formatedSentOn = new Date(Number.parseInt(splitSentOn[0]), Number.parseInt(splitSentOn[1]) - 1, Number.parseInt(splitSentOn[2]), Number.parseInt(splitSentOn[3]), Number.parseInt(splitSentOn[4]), Number.parseInt(splitSentOn[5]));
      const oneHour = 60 * 60 * 1000;
      const expiredOn = Date.parse(formatedSentOn.toString()) + oneHour;
      const sentOn = new Date(expiredOn);
      const dateTimeNow = new Date().getTime();
      const timeOffSet = new Date().getTimezoneOffset();
      const expiredDate = dateTimeNow + timeOffSet * 60000;
      const dateTimeUTCNow = new Date(expiredDate);
      const expired = dateTimeUTCNow > sentOn;
      return expired || !this.isGuidValid;
    }
    else
    {
      return true;
    }
  }

  // convenience getter for easy access to form fields
  get f()
  {
    return this.activationForm.controls;
  }

  onSubmit()
  {
    if (this.activationForm.invalid)
    {
      return;
    }

    this.forgotPasswordService.submitForgotPassword(
      this.subscriberId,
      this.f.password.value,
      this.f.passwordConfirmation.value).subscribe(
        result =>
        {
          if (this.deviceService.isNativeMobile)
          {
            this.onActivation();
          }
          this.isChanged = true;
        },
        failure => { });
  }

  onActivation()
  {
    window.open('stopfinder://', '_blank');
  }
}
