import * as _ from 'lodash';
import { Injectable, NgZone, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import
{
  MatDialog,
  MatIconRegistry,
  MatSnackBar,
  MatBottomSheet
} from '@angular/material';
import { ConfirmationDialogComponent } from './shared/layout/confirmation-dialog/confirmation-dialog.component';

import { StopfinderApiServiceBaseUri, StopfinderApiService } from './shared/stopfinder/stopfinder-api.service';
import { TargetedBlockingScrollStrategy } from './shared/material/targeted-blocking-scroll-strategy';

import
{
  Observable,
  Subscription,
  BehaviorSubject,
  throwError,
  zip,
  of,
  Subject
} from 'rxjs';
import
{
  map,
  flatMap,
  catchError,
  filter,
  take
} from 'rxjs/operators';
import
{
  TokenResponse,
  Communication,
  CommunicationType,
  GeoAlertNotification,
  PushNotification,
  IAttendance,
  IGeoLocationPosition,
  IGeoLocationPositionError,
  GeoLocationErrorCode,
} from './shared/stopfinder/stopfinder-models';
import { LocalStorageService } from './shared/local-storage/local-storage.service';
import { ScheduleService } from './schedule/schedule.service';
import { environment } from './../environments/environment';
import { NativeMapView } from './tf-map/core/native-mapview';
import { DomSanitizer } from '@angular/platform-browser';
import { MessagesService } from './messages/messages.service';
import { TfMapFactory } from './tf-map/core/tf-map.factory';
import { TfMap } from './tf-map/core/classes/tf-map.class';
import { StateService } from './components/service/state/state.service';
import { AndroidBackService } from './androidBack.service';
import { MapService } from 'src/app/components/service/map/map.service';
import { PathLineService } from 'src/app/components/service/path-line/path-line.service';
import * as moment from 'moment';
import { ToastService } from './shared/toast';
import { CompassService } from './map/compass/compass.service';
import { formatVersion, getLocalRFApiVersion } from 'src/app/shared/utils/utils';
import { LOCAL_LAST_TOUR_INFO_KEY } from 'src/app/shared/utils/constant';
import { Language, TFMapType } from 'src/app/shared/utils/enum';
import { FormService } from 'src/app/components/service/form/form.service';
import { MapVehicleService } from './components/service/map/map-vehicle.service';
import { MapGeoAlertService } from './components/service/map/map-geoalert.service';
import { IBusParameter } from './shared/stopfinder/models/map';
import { MapAttendanceService } from './components/service/map/map-attendance.service';
import { DeviceService } from './components/service/device/device.service';
import { MapCoreService } from './components/service/map/map-core.service';
import { StopfinderDateTimeService } from './shared/stopfinder/stopfinder-datetime.service';
import { RealTimeUpdatesService } from './shared/real-time-updates.service';
import { VehicleLocationService } from './shared/vehicle-location.service';
import { HttpErrorResponse } from '@angular/common/http';
import { VersionDictionary } from './shared/stopfinder/models/version-dictionary';
import { AttendanceService } from './components/service/attendance/attendance.service';

@Injectable()
export class AppService
{
  public clientId: string;
  public token: string;
  public refreshToken: string;
  public opaqueToken: string;
  public email: string;
  public rememberMe = false;
  public cordovaReady = false;
  public loggedIn = false;
  public waitingOnIntent = false;
  public nativeTransparentWindow = false;
  public shouldAnime = false;
  public badClients = [];
  public cordova = null;
  public pushDeviceToken: string;
  public pushRegistered = false;
  public pushInstance = null;
  public lastLocation = '';
  public cameraInstance = null;
  public cameraDefaultOptions = null;
  public mapId = 'sfMap';
  public fileInstance = null;
  public fileOpenerInstance = null;
  public fileChooserInstance = null;
  public socialShareInstance = null;
  public themeDetectionInstance = null;
  public appRateInstance = null;
  public statusBarInstance = null;
  public currentStudentSchedule = null;
  public currentSubscription = null;
  public listPickerInstance = null;
  public nativeDialogInstance = null;
  public diagnosticInstance = null;
  public windowHeight = 0;
  public windowWidth = 0;
  public versionDictionary: VersionDictionary = {};

  private userValidateSubscription: Subscription;

  private _tokenRefresh: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public tokenRefreshObservable: Observable<boolean> = this._tokenRefresh.asObservable();

  public showMap = false;
  public tripScheduleNameForDisplay = 'placeholder';
  public _mapToolOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private nativeHostElements: any;
  private nativeHostMapStuffElements: any;
  private dialogRef = undefined;
  private _isPaused = false;
  private readonly headerChecked = new BehaviorSubject<boolean>(false);
  public headerCheckedObservable = this.headerChecked.asObservable();
  public isMapToolOpened: Observable<boolean> = this._mapToolOpened.asObservable();

  // transparent change as event emitters are rxjs subjects, angular recommends
  // you only use event emitters in the template rather than subscribe to them directly
  public resumeEvent: Subject<void> = new Subject<void>();
  public pauseEvent: Subject<void> = new Subject<void>();
  public closeMapEvent: EventEmitter<any> = new EventEmitter();

  public subscriberLanguage = Language.en;

  // if you need todo some action after change language, you can subscriber this event
  public updatedLanguageEvent: EventEmitter<any> = new EventEmitter();
  public translateText: { [key: string]: string } = {};

  public async setMapVisibility(showMap, centerX: number = 0, centerY: number = 0, isOpenedFromNotification: boolean = false, notTransparentWindow = false)
  {
    if (!NativeMapView.nativeEsriMapPlugin)
    {
      return;
    }

    this.showMap = showMap;
    this.compassService.resetRotateDefault();
    NativeMapView.nativeEsriMapPlugin.setMapRotation(this.mapId, 0, () => { }, () => { });

    if (showMap)
    {
      this.nativeTransparentWindow = true;

      await this.stateService.getMapState() === MapAttendanceService.mapType ? this.mapCoreService.centerAt(this.mapId, centerX, centerY, MapAttendanceService.mapScale) :
        this.mapCoreService.centerAt(this.mapId, centerX, centerY);

      // fix the map have a space issue when add geo alert for Android
      if (this.deviceService.isAndroid)
      {
        this.mapService.resetViewSize(this.windowWidth, this.windowHeight).then(() =>
        {
          this.initializeMap();
          isOpenedFromNotification && this.router.navigate(["/schedule", { hideSchedule: 1 }]);
        });
      }
      else
      {
        this.initializeMap();
        isOpenedFromNotification && this.router.navigate(["/schedule", { hideSchedule: 1 }]);
      }
    }
    else
    {
      // refresh the geo notification and attendance
      this.scheduleService.refreshSchedulesGeoNotifications();

      this.nativeTransparentWindow = false;
      NativeMapView.nativeEsriMapPlugin.toggleMapViewVisible(this.mapId, false, () => null, () => null);
      this.vehicleService.turnOffVehicleLocationDisplay(this.mapId).subscribe();

      !notTransparentWindow && (this.nativeHostElements || []).forEach(nativeHostElement =>
      {
        (nativeHostElement as HTMLElement).classList.remove('transparent-native-window');
      });
      !notTransparentWindow && (this.nativeHostMapStuffElements || []).forEach(nativeHostMapStuffElements =>
      {
        (nativeHostMapStuffElements as HTMLElement).classList.remove('hideMap', 'mustShowMap');
      });
    }
  }

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private stateService: StateService,
    private readonly androidBackService: AndroidBackService,
    private readonly bottomSheet: MatBottomSheet,
    private readonly compassService: CompassService,
    private readonly dateTimeService: StopfinderDateTimeService,
    private readonly domSanitizer: DomSanitizer,
    private readonly deviceService: DeviceService,
    private readonly formService: FormService,
    private readonly geoAlertService: MapGeoAlertService,
    private readonly localStorageService: LocalStorageService,
    private readonly mapCoreService: MapCoreService,
    private readonly mapService: MapService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly messageService: MessagesService,
    private readonly ngZone: NgZone,
    private readonly pathLineService: PathLineService,
    private readonly router: Router,
    private readonly scheduleService: ScheduleService,
    private readonly stopfinderApi: StopfinderApiService,
    private readonly toastService: ToastService,
    private readonly vehicleService: MapVehicleService,
    private readonly _realTimeUpdatesService: RealTimeUpdatesService,
    private readonly _vehicleLocationService: VehicleLocationService,
    private readonly _attendanceService: AttendanceService,
  )
  {

    this.initializeMaterial();
    this.loadProfile();
    this.deviceService.getDevice();
    this.initializeCordova();
    this.initialWindowSize();
  }

  initialWindowSize()
  {
    this.windowHeight = window.innerHeight;
    this.windowWidth = window.innerWidth;
  }

  initializeMaterial()
  {
    this.addSvgIcon('ios_share', 'assets/images/icons8-share-rounded.svg');
    this.addSvgIcon('transfinder_message', 'assets/images/message.svg');
  }

  addSvgIcon = (name, url) =>
  {
    this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(url));
  }

  saveProfile()
  {
    this.localStorageService.set('clientId', this.clientId);
    this.localStorageService.set('token', this.token);
    this.localStorageService.set('refreshToken', this.refreshToken);
    this.localStorageService.set('opaqueToken', this.opaqueToken);
    this.localStorageService.set('subscriberLanguage', this.subscriberLanguage);
    if (this.refreshToken && this.token)
    {
      this.loggedIn = true;
      this.messageService.loggedIn = true;
      this.scheduleService.loggedIn = true;

      // reset token refresh observable status
      this._tokenRefresh.next(false);
    }
  }

  loadProfile()
  {
    this.clientId = this.localStorageService.get('clientId');
    this.token = this.localStorageService.get('token');
    this.refreshToken = this.localStorageService.get('refreshToken');
    this.opaqueToken = this.localStorageService.get('opaqueToken');

    this._handleVersionDictionary();
    this.updateScheduleServiceVariable();

    if (this.refreshToken && this.token)
    {
      this.loggedIn = true;
      this.scheduleService.loggedIn = true;
    }
  }

  private _handleVersionDictionary()
  {
    const storedVersionDictionary = this.localStorageService.get('versionDictionary');
    if (storedVersionDictionary)
    {
      this.versionDictionary = storedVersionDictionary;
    } else
    {
      this.versionDictionary = {};
    }
    this._realTimeUpdatesService.versionDictionary = this.versionDictionary;
  }

  clearProfile()
  {
    this.clientId = null;
    this.token = null;
    this.refreshToken = null;
    this.opaqueToken = null;
    this.loggedIn = false;
    this.messageService.loggedIn = false;
    this.scheduleService.loggedIn = false;
    this.localStorageService.remove('clientId');
    this.localStorageService.remove('token');
    this.localStorageService.remove('refreshToken');
    this.localStorageService.remove('opaqueToken');
    this.localStorageService.remove('versionDictionary');
    this.localStorageService.remove(StopfinderApiServiceBaseUri);
    this.stopfinderApi.clearStopFinderBaseUri();
    this.localStorageService.remove(LOCAL_LAST_TOUR_INFO_KEY);

    // stop the scanned signalR when logout
  }

  initializeMap()
  {
    NativeMapView.nativeEsriMapPlugin.toggleMapViewVisible(this.mapId, true, () => { }, () => null);
    TfMapFactory._awaitGet('sfMap').then((map: TfMap) => { map.toggleLabelColor(); });
    this.mapCoreService.splitMode();
    this.mapCoreService.setHorizontalSplitSize(60, true);

    this.nativeHostElements = document.querySelectorAll('.native-host');
    this.nativeHostElements.forEach(nativeHostElement =>
    {
      (nativeHostElement as HTMLElement).classList.add('transparent-native-window');
    });
    this.nativeHostMapStuffElements = document.querySelectorAll('.hiddenDueToMap');
    this.nativeHostMapStuffElements.forEach(nativeHostMapStuffElements =>
    {
      (nativeHostMapStuffElements as HTMLElement).classList.add('hideMap');
    });
  }

  initializeCordova()
  {
    // main module ensures cordova device ready has fired before angular is bootstrapped
    this.cordova = window['cordova'];
    if (this.cordova)
    {
      // pause and resume are the only core events supported on both iOS and Android
      document.addEventListener('pause', this.onPause.bind(this), false);
      document.addEventListener('resume', this.onResume.bind(this), false);

      // handle android back button
      document.addEventListener(
        'backbutton',
        this.onBackButton.bind(this),
        false
      );

      this.initializeNativeIntentsPlugin();

      this.deviceService.initializeDevicePlugin();

      this.initializePushPlugin();

      this.initializeCameraPlugin();

      this.initializeFilePlugin();

      this.initializeFileOpener();

      this.initializeFileChooser();

      this.initializeSocialShare();

      this.initializeStatusBar();

      this.initializeThemeDetection();

      this.initializeListPicker();

      this.initializeNativeDialog();

      this.initializeDiagnostic();

      this.cordovaReady = true;
    }
  }

  initializeNativeIntentsPlugin()
  {
    const nativeIntentsPlugin = window['plugins'] && window['plugins']["nativeIntents"];
    if (nativeIntentsPlugin)
    {
      // get existing/startup intent
      this.waitingOnIntent = true;
      nativeIntentsPlugin.getIntent(this.onIntent.bind(this));
      // register for future intents
      nativeIntentsPlugin.onIntent(this.onIntent.bind(this));
    }
  }

  initializePushPlugin()
  {
    const pushNotificationPlugin = window['PushNotification'];
    if (pushNotificationPlugin)
    {
      const pushNotificationInitOptions: any = {
        android: {
          icon: 'notification'
        },
        ios: {
          vibration: true,
          badge: true,
          sound: true,
          alert: true,
        }
      };

      const push = pushNotificationPlugin.init(pushNotificationInitOptions);
      push.on('registration', this.onPushRegistration.bind(this));
      push.on('notification', this.onPushNotification.bind(this));
      push.on('error', this.onPushError.bind(this));

      this.pushInstance = push;

      // subscribe to communication count and set badge appropriately
      this.messageService.unreadCommunicationCountObservable.subscribe(count => this.setBadgeCount(count));
    }
  }

  initializeCameraPlugin()
  {
    const cameraPlugin = (navigator as any).camera;

    if (cameraPlugin)
    {
      const cameraOptions = {
        sourceType: cameraPlugin.PictureSourceType.PHOTOLIBRARY,
        destinationType: cameraPlugin.DestinationType.FILE_URI,
        mediaType: cameraPlugin.MediaType.PICTURE,
        encodingType: cameraPlugin.EncodingType.JPEG,
        quality: 50,
      };
      this.cameraDefaultOptions = cameraOptions;
      this.cameraInstance = cameraPlugin;
    }
  }

  initializeFilePlugin()
  {
    const filePlugin = this.cordova["file"];

    if (filePlugin !== null)
    {
      this.fileInstance = filePlugin;
    }
  }

  initializeFileOpener()
  {
    const fileOpenerPlugin = this.cordova["plugins"].fileOpener2;

    if (fileOpenerPlugin !== null)
    {
      this.fileOpenerInstance = fileOpenerPlugin;
    }
  }

  initializeFileChooser()
  {
    const fileChooser = window['chooser'];
    if (fileChooser)
    {
      this.fileChooserInstance = fileChooser;
    }
  }

  initializeSocialShare()
  {
    const socialSharePlugin = window['plugins'] && window['plugins']['socialsharing'];
    if (socialSharePlugin)
    {
      this.socialShareInstance = socialSharePlugin;
    }
  }

  initializeStatusBar()
  {
    const statusBarPlugin = window['StatusBar'];
    if (statusBarPlugin)
    {
      this.statusBarInstance = statusBarPlugin;
    }
  }

  initializeThemeDetection()
  {
    const themeDetectionPlugin = this.cordova && this.cordova.plugins && this.cordova.plugins.ThemeDetection;
    if (themeDetectionPlugin)
    {
      this.themeDetectionInstance = themeDetectionPlugin;
      this.setDarkModeValue();
    }
  }

  initializeListPicker()
  {
    const listPickerPlugin = window['plugins'] && window['plugins']["listpicker"];

    if (listPickerPlugin !== null)
    {
      this.listPickerInstance = listPickerPlugin;
    }
  }

  initializeNativeDialog()
  {
    const nativeDialogPlugin = navigator["notification"];

    if (nativeDialogPlugin !== null)
    {
      this.nativeDialogInstance = nativeDialogPlugin;
    }
  }

  initializeDiagnostic()
  {
    const diagnosticPlugin = window['cordova']["plugins"] && window['cordova']["plugins"]["diagnostic"];

    if (diagnosticPlugin !== null)
    {
      this.diagnosticInstance = diagnosticPlugin;
    }
  }

  getGeoLocationPosition(): Promise<IGeoLocationPosition | IGeoLocationPositionError>
  {
    return new Promise<any>((resolve, reject) =>
    {
      const geolocation = navigator.geolocation;

      if (!geolocation) return reject({
        code: GeoLocationErrorCode.PLUGIN_NOT_FOUND
      });

      geolocation && geolocation.getCurrentPosition(
        (data: IGeoLocationPosition) =>
        {
          return resolve(data);
        },
        (error: IGeoLocationPositionError) =>
        {
          return reject(error);
        },
        { enableHighAccuracy: true, timeout: 1500, maximumAge: 0 });
    });
  }

  setDarkModeValue()
  {
    if (this.themeDetectionInstance)
    {
      this.themeDetectionInstance.isDarkModeEnabled((dark) =>
      {
        if (dark & dark.value)
        {
          this.statusBarInstance && this.statusBarInstance.styleDefault();
        }
        // you can subscribe "deviceDarkModeEnabled" and write your action
        // param is true means enable dark mode
        // only ios 13+ and android api 29+ can enable dark mode
        this.deviceService.deviceDarkModeEnabled.next(Boolean(dark && dark.value));
      }, () => { });
    }
  }

  onPause()
  {
    this.ngZone.run(() =>
    {
      this.pauseEvent.next();
      // stop scanned signalR
      this._attendanceService.clearNetworkDictionary();
      this._vehicleLocationService.clearNetworkCache();
      this._realTimeUpdatesService.clearNetworkCache();
      this._vehicleLocationService.flushAllTimersAndSubscriptions();
      this._isPaused = true;
    })
  }

  onResume()
  {
    this.ngZone.run(() =>
    {
      // try to start the SignalR service and display bus icon
      if (this.showMap)
      {
        this.mapService.isVehicleDisplayEnabled = false;
        this.deviceService.isAndroid && this.mapService.RefreshMapCenter();
        this.mapService.refreshStudentScheduleAndTrip(this.mapService.studentSchedule).then(() =>
        {
          const param: IBusParameter = {
            mapId: this.mapService.mapId,
            tripId: this.mapService.tripId,
            studentSchedule: this.mapService.studentSchedule,
            selectedTrip: this.mapService.selectedTrip,
            appService: this
          };
          this._vehicleLocationService.flushAllTimersAndSubscriptions();
          if (this.stateService.getMapState() === TFMapType.GeoAlertMap)
          {
            this.vehicleService.startMonitoringBusLocation(param).subscribe();
          }
        });
      }
      // get the message and announcement when switch app form background to foreground
      this.messageService.getCommunications();
      this.scheduleService.refreshSchedulesGeoNotifications();
      // reload the scanned info
      this.updateScheduleServiceVariable();
      this.resumeEvent.next();
    });
  }

  onBackButton()
  {
    if (this.androidBackService.disableAndroidBack)
    {
      this.androidBackService.onAndroidBackCallback();
      return;
    }
    this.ngZone.run(() =>
    {
      if (this.showMap)
      {
        this.stateService.leaveMap();
      }
      else
      {
        this.stateService.goRoute(this.lastLocation);
      }
    });
  }

  onPushRegistration(data)
  {
    this.pushDeviceToken = data.registrationId;
    if (this.loggedIn)
    {
      this.registerPushDevice().subscribe();
    }
  }

  public showNotification(notification: PushNotification, referencedCommunication: any)
  {
    if (notification.additionalData.foreground)
    {
      this.showPushNotificationSnack(referencedCommunication);
    }
    else if (this.deviceService.isiOS || notification.additionalData.dismissed === false)
    {
      this.showCommunication(notification, referencedCommunication);
    }
  }

  public showEtaAlertNotification(data: PushNotification)
  {
    this.showNotification(data, {
      id: data.additionalData.etaAlertNotificationId,
      subject: data.title,
      body: data.message,
      type: CommunicationType.EtaAlert,
      riderId: Number(data.additionalData.riderId),
      dataSourceId: Number(data.additionalData.dataSourceId),
      tripId: Number(data.additionalData.tripId),
    } as Communication);
  }

  public async showGeoAlertNotification(data: PushNotification)
  {
    this.showNotification(data, {
      id: data.additionalData.geoAlertNotificationId,
      subject: data.title,
      body: data.message,
      riderId: Number(data.additionalData.riderId),
      type: CommunicationType.GeoAlert,
      dataSourceId: Number(data.additionalData.dataSourceId),
      tripId: Number(data.additionalData.tripId),
    } as Communication);

    this.scheduleService.updateGeoNotification(
      Number(data.additionalData.riderId),
      Number(data.additionalData.tripId),
      Number(data.additionalData.dataSourceId)
    );

    const geoNotificationData = ({
      id: Number(data.additionalData.geoAlertNotificationId),
      subscriberId: _.get(this.stateService.subscriber, 'id') || null,
      riderId: Number(data.additionalData.riderId),
      name: String(data.additionalData.name),
      alertType: false,
      sentOn: moment().local().toDate(),
      body: data.message,
      subject: data.title,
      tripId: Number(data.additionalData.tripId),
      dataSourceId: Number(data.additionalData.dataSourceId),
    } as GeoAlertNotification);

    this.geoAlertService.geoHistory.next(geoNotificationData);
  }

  private showAnnouncementAndMessageNotification(data: PushNotification)
  {
    this.messageService.getCommunications(function ()
    {
      this.messageService.sortedUnreadCommunicationsObservable
        .pipe(take(1))
        .subscribe(communications =>
        {
          let referencedCommunication = null;
          if (data.additionalData.messageId > 0)
          {
            referencedCommunication = communications.find(communication =>
            {
              return (
                communication.id == data.additionalData.messageId &&
                communication.type === CommunicationType.Message
              );
            });

          }
          else if (data.additionalData.sentAnnouncementId > 0)
          {
            referencedCommunication = communications.find(communication =>
            {
              return (
                communication.id == data.additionalData.sentAnnouncementId &&
                communication.type === CommunicationType.Announcement
              );
            });
          }

          this.showNotification(data, referencedCommunication);
        });
      // subscribe to communication count and set badge appropriately
      this.messageService.unreadCommunicationCountObservable.subscribe(count => this.setBadgeCount(count));
    }.bind(this));
  }

  private showFormNotification(data: PushNotification)
  {
    this.formService.showNotification(data, this);
  }

  private showAttendanceNotification(data: PushNotification)
  {
    const { riderId, dataSourceId, attendanceDate, attendanceId, attendanceLongitude, attendanceLatitude } = data.additionalData;
    const attendance: IAttendance = {
      id: attendanceId,
      riderId: riderId,
      dataSourceId: dataSourceId,
      longitude: attendanceLongitude,
      latitude: attendanceLatitude,
      scannedDate: attendanceDate,
    };

    this.showNotification(data, {
      id: data.additionalData.attendanceId,
      subject: data.title,
      body: data.message,
      riderId: Number(data.additionalData.riderId),
      type: CommunicationType.Attendance,
      dataSourceId: Number(data.additionalData.dataSourceId),
      attendance: {
        ...attendance,
        scannedDate: attendanceDate  // Convert to client date after get the student schedule
      },
    } as Communication);

    this._attendanceService.updateScannedBody(attendance);
  }

  onPushNotification(data: PushNotification)
  {
    // app in foreground: data.additionalData.foreground === true && data.additionalData.coldstart === false
    // app in background: data.additionalData.foreground === false && data.additionalData.coldstart === true
    // click notification: data.additionalData.foreground === false && data.additionalData.coldstart === false

    const notificationData = this.formatNotificationData(data);

    this.ngZone.run(() =>
    {
      if (notificationData.additionalData.geoAlertNotificationId > 0)
      {
        this.showGeoAlertNotification(notificationData);
      } else if (notificationData.additionalData.sentFormId > 0)
      {
        this.showFormNotification(notificationData);
      }
      else if (notificationData.additionalData.messageId > 0 ||
        notificationData.additionalData.sentAnnouncementId > 0)
      {
        this.showAnnouncementAndMessageNotification(notificationData);
      } else if (notificationData.additionalData.attendanceId > 0)
      {
        this.showAttendanceNotification(notificationData);
      } else if (notificationData.additionalData.etaAlertNotificationId > 0)
      {
        this.showEtaAlertNotification(notificationData);
      }
    });
  }

  setBadgeCount(badgeCount: number)
  {
    this.pushInstance.setApplicationIconBadgeNumber((success) => { }, (failure) => { }, badgeCount);
  }

  showPushNotificationSnack(communication: Communication)
  {
    const self = this;
    this.deviceService.isiOS && this.deviceService.isiPhoneX && this.toastService.updateConfig({
      position: {
        top: 40,
        right: 0
      }
    });

    this.toastService.showSnackBar({
      duration: 5000,
      data: {
        communication,
        appService: self
      }
    });
  }

  async showCommunication(notification: PushNotification, communication: Communication)
  {
    // will be triggered when a os level push is received on Android
    if (this._isPaused && notification.additionalData.coldstart === true && this.deviceService.isAndroid)
    {
      console.log("This condition will never meet, because the combinatin of dimissed = false and coldstart = true can only happen after app is killed");
      return;
    }

    if (communication.type === CommunicationType.GeoAlert || communication.type === CommunicationType.EtaAlert)
    {
      this.closeHistoryPage();

      const studentSchedule = await this.scheduleService.getStudentScheduleWithNotification(communication);
      // correctly set # of parameters and pass along status of fromNotification
      return studentSchedule && this.mapService.displayGeoAlertMapOnClickNotification(
        studentSchedule,
        this,
        this.pathLineService,
        true,
        communication.tripId,
        true
      );
    }

    if (communication.type === CommunicationType.Attendance)
    {
      const studentSchedule = await this.scheduleService.getStudentScheduleWithNotification(communication);
      // Update attendance.scannedDate
      const timeZoneMinutes = this.mapService.getScheduleTimeZone(studentSchedule);
      communication.attendance.scannedDate = this.dateTimeService.toUtcDate(communication.attendance.scannedDate, timeZoneMinutes).format(this.dateTimeService.formatDate);
      return studentSchedule && this.mapService.displayAttendanceMap(
        studentSchedule,
        this,
        communication.attendance,
        true);
    }

    this.showMap && this.setMapVisibility(false);
    if (communication.type === CommunicationType.Form)
    {
      const isFormSentExpired = await this.messageService.getFormSentExpiredStatusById(communication.id);
      if (isFormSentExpired)
      {
        this.dialog.open(ConfirmationDialogComponent, {
          disableClose: true,
          data: {
            title: this.translateText['form']["dialog"]["unavailable.title"],
            message: this.translateText['form']["dialog"]["unavailable.message"],
            action: this.translateText['form']["dialog"]["unavailable.action"],
            secondary: false,
          },
          scrollStrategy: new TargetedBlockingScrollStrategy(),
          panelClass: "confirm-dialog"
        }).afterClosed().subscribe(result =>
        {
          this.messageService.removeFormDataFromCommunications(communication.id, communication.type, communication.formRecipientId);
        });
      }
      else
      {
        this.router.navigate([`formQuestion/${communication.id}/${communication.formRecipientId}`]);
      }
    }
    else
    {
      this.messageService.selectedCommunication = communication;
      this.messageService.markCommunicationReadStatus(communication, true);
      communication.read = true;
      this.stateService.goRoute('message-detail');
    }
  }

  private closeHistoryPage()
  {
    const wrapper = document.querySelector(".geo-history-wrapper");
    if (!wrapper) return;

    (wrapper as HTMLElement).style.display = "none";
    // turn off bottom sheet
    this.bottomSheet && this.bottomSheet._openedBottomSheetRef && this.bottomSheet._openedBottomSheetRef.dismiss();
    // turn off dialog if it is opened
    this.dialog && this.dialog.openDialogs.length && this.dialog.closeAll();
  }

  onPushError(err) { }

  registerPushDevice(): Observable<any>
  {
    if (this.pushDeviceToken)
    {
      const deviceRegistration = {
        deviceName: this.deviceService.deviceName,
        deviceIdentifier: this.deviceService.deviceIdentifier,
        deviceToken: this.pushDeviceToken,
        platform: this.deviceService.platform // platform's value must be "AndroidNative" or "IosNative" can registered push device success
      };
      return this.stopfinderApi.registerPushDevice(deviceRegistration).pipe(
        map(res =>
        {
          this.pushRegistered = true;
        })
      );
    }
    else
    {
      return throwError(
        'Push device token unavailable. Device not registered.'
      );
    }
  }

  unregisterPushDevice(): Observable<any>
  {
    const deviceRegistration = {
      deviceName: this.deviceService.deviceName,
      deviceIdentifier: this.deviceService.deviceIdentifier,
      deviceToken: this.pushDeviceToken,
      platform: this.deviceService.platform
    };
    return this.stopfinderApi.unregisterPushDevice(deviceRegistration).pipe(
      map(() =>
      {
        this.pushRegistered = false;
      })
    );
  }

  reRegisterPushDevice(lastDeviceIdentifier: string, deviceIdentifier: string): Observable<any>
  {
    if (this.pushDeviceToken)
    {
      return this.stopfinderApi.reRegisterPushDevice({
        lastDeviceIdentifier: lastDeviceIdentifier,
        deviceName: this.deviceService.deviceName,
        deviceIdentifier: deviceIdentifier,
        deviceToken: this.pushDeviceToken,
        platform: this.deviceService.platform // platform's value must be "AndroidNative" or "IosNative" can registered push device success
      }).pipe(
        map(res =>
        {
          this.pushRegistered = true;
        })
      );
    }
    else
    {
      return throwError(
        'Push device token unavailable. Device not re-registered.'
      );
    }
  }

  onIntent(intent)
  {
    // anything outside of the Angular zone should not interact with app
    this.ngZone.run(() =>
    {
      if (!intent)
      {
        return;
      }

      if (intent.action === 'android.intent.action.VIEW')
      {
        this.handleDeepLink(intent.data);
      }
      else if (
        intent.action === 'ios.action.continue_user_activity.BROWSING_WEB'
      )
      {
        this.handleDeepLink(intent.url);
      }

      this.waitingOnIntent = false;
    });
  }

  handleDeepLink(fullUrlString: string)
  {
    if (!fullUrlString)
    {
      return;
    }

    const url = new URL(fullUrlString);
    const path = url.pathname;

    if (path.startsWith('/activation'))
    {
      // activation link
      const guid = url.searchParams.get('guid');

      if (guid)
      {
        this.stateService.goRoute('activation', { guid });
      }
    } // else if()... handle other supported deep routes here
  }

  login(email: string, password: string): Observable<any>
  {
    return this.stopfinderApi.getEnvironmentURL(email).pipe(flatMap((response: string) =>
    {
      this.stopfinderApi.stopFinderBaseUri = response;
      this.localStorageService.set(StopfinderApiServiceBaseUri, response);
      return this.loginAction(email, password);
    }));
  }

  loginAction(email: string, password: string): Observable<any>
  {
    return this.stopfinderApi
      .postTokenRequest({
        grantType: 'password',
        username: email,
        password: password,
        deviceId: this.deviceService.deviceIdentifier,
        rfApiVersion: environment.rfApiVersion,
      })
      .pipe(
        flatMap(tokenResponse =>
        {
          // set access token for auth interceptor
          this.token = tokenResponse.token;
          this.opaqueToken = tokenResponse.opaqueToken;

          this.updateScheduleServiceVariable();
          this.scheduleService.clearCache();

          const currentLanguage = tokenResponse.language;
          if (currentLanguage && (currentLanguage == Language.en || currentLanguage === Language.es || currentLanguage === Language.fr))
          {
            this.subscriberLanguage = currentLanguage;
          }
          let pushRegistrationObservable: Observable<any>;

          pushRegistrationObservable = this.registerPushDevice().pipe(
            catchError(error =>
            {
              return of({});
            })
          );

          const versionCheckObservable = this.stopfinderApi.getVersions().pipe(
            map(versions =>
            {
              const supportedAppVersions = _.map(versions, "supportedProductVersion");
              const supportedRFApiVersion = _.map(versions, "rfApiVersion");
              const supportedSFApiVersion = _.map(versions, "sfApiVersion");
              this.versionDictionary = Object.assign({}, ...versions.map(
                (x) =>
                {
                  let result;
                  switch (x.supportedProductVersion)
                  {
                    case "v2.5":
                      result = true;
                      break;
                    case "v2.0":
                      result = false;
                      break;
                    default:
                      result = null;
                      break;
                  }
                  return ({ [x.id]: result })
                }));
              this._realTimeUpdatesService.versionDictionary = this.versionDictionary;
              this.localStorageService.set(`versionDictionary`, this.versionDictionary);
              const needUpdateApp = (this.checkVersionResponses(environment.appVersion, supportedAppVersions)
                || this.checkVersionResponses(environment.sfApiVersion, supportedSFApiVersion));
              const needUpdateApi = this.checkVersionResponses("", supportedRFApiVersion, true);
              if (needUpdateApp || needUpdateApi)
              {
                this.token = null;
                if (needUpdateApp)
                {
                  this.confirmUpdateApp();
                  throw new Error(this.translateText["modal.app.upgrade.title"]);
                }

                if (needUpdateApi)
                {
                  this.confirmUpdateApi();
                  throw new Error(this.translateText["modal.api.upgrade.title"]);
                }
              }
              else
              {
                return versions;
              }
            })
          );

          return zip(
            pushRegistrationObservable,
            versionCheckObservable,
            this.headerCheckedObservable.pipe(filter(value => value))
          ).pipe(
            map(resp =>
            {
              this.refreshToken = tokenResponse.refreshToken;
              this.shouldAnime = true;
              this.saveProfile();
              this.scheduleService.clearDate();
              if (!this.pushInstance)
              {
                this.initializePushPlugin();
              }
            })
          );
        })
      );
  }

  refreshLogin(): Observable<TokenResponse>
  {
    const currentDeviceIdentifier = (this.deviceService.deviceInstance && this.deviceService.deviceInstance.uuid) || this.deviceService.deviceIdentifier;
    return this.stopfinderApi
      .postTokenRequest({
        grantType: 'refresh',
        refreshToken: this.refreshToken,
        username: this.email,
        deviceId: currentDeviceIdentifier,
        rfApiVersion: environment.rfApiVersion,
      })
      .pipe(
        map(response =>
        {
          this.token = response.token;
          this.refreshToken = response.refreshToken;
          this.opaqueToken = response.opaqueToken;

          this.updateScheduleServiceVariable();

          const currentLanguage = response.language;
          if (currentLanguage && (currentLanguage == Language.en || currentLanguage === Language.es || currentLanguage === Language.fr))
          {
            this.subscriberLanguage = currentLanguage;
            // update observable value
            this._tokenRefresh.next(true);
          }
          this.saveProfile();
          if (response.lastDeviceId !== currentDeviceIdentifier)
          {
            this.reRegisterPushDevice(response.lastDeviceId, currentDeviceIdentifier).toPromise()
              .then(() => { })
              .catch(ex => { });
          }
          return response;
        }),
        catchError(error =>
        {
          if (error && error.error && JSON.stringify(error.error || "").indexOf('Execution Timeout Expired') >= 0)
          {
            this.refreshLogin();
          }
          else
          {
            if (!this.dialogRef)
            {
              this.dialogRef = this.dialog
                .open(ConfirmationDialogComponent, {
                  disableClose: false,
                  data: {
                    title: this.translateText['login']["modal.login.expired.title"],
                    message: this.translateText['login']["modal.login.expired.body"],
                    action: this.translateText['login']["modal.login.expired.ok"],
                    secondary: false,
                  },
                  scrollStrategy: new TargetedBlockingScrollStrategy(),
                  panelClass: "confirm-dialog"
                });
              this.dialogRef.beforeClosed()
                .subscribe(() =>
                {
                  this.dialogRef = null;
                  this.logout();
                });
            }
          }
          throw error;
        })
      );
  }

  logout(callback: Function = null)
  {
    if (this.userValidateSubscription)
    {
      this.userValidateSubscription.unsubscribe();
      this.userValidateSubscription = null;
    }

    this.messageService && this.messageService.unsubscribeCommunicationRefresh();

    this.unregisterPushPlugin();
    this.unregisterPushDevice().subscribe(
      () => { },
      (err) =>
      {
        const errMsg = (err instanceof HttpErrorResponse) ? err.message : err;
        console.log(`Cannot unregister device ${this.deviceService.deviceIdentifier}\n${errMsg}`);
      });
    this.scheduleService.closeSchedulesSignalRConnections();
    this.returnToLogin();
    callback && callback();
  }

  returnToLogin()
  {
    this.pushDeviceToken = null;
    this.setMapVisibility(false);
    this.scheduleService.clearCache();
    this.stateService.goRoute('login');
    this.clearProfile();
  }

  startUserValidate()
  {
    if (this.userValidateSubscription)
    {
      this.userValidateSubscription.unsubscribe();
      this.userValidateSubscription = null;
    }
  }

  // true is local version less than support version
  // false is local version more than support version
  checkVersionResponses(localVersion: string, supportVersions: string[], isRFApi: boolean = false): boolean
  {
    return _.some((supportVersions || []), (version) =>
    {
      if (isRFApi)
      {
        localVersion = getLocalRFApiVersion(version);
      }

      return (isRFApi ? this.compareVersions(version, localVersion) < 0 : this.compareVersions(localVersion, version) < 0);
    });
  }

  handleVersionHeaders(supportedProducts: string[], rfApiVersions: string[], sfApiVersion: string)
  {
    let supportedProductVersions: string[] = [];
    const supportRFApiVersions: string[] = [];
    (supportedProducts || []).forEach(el =>
    {
      (el.split(',') || []).forEach(element =>
      {
        const product = element.split(';').filter(elp =>
        {
          return elp.indexOf('stopfinder:v') !== -1;
        });
        const versionString = product[0]
          && product[0].substring(product[0].indexOf('v') + 1, product[0].length).replace(/\[|]/g, "");
        supportedProductVersions.push(versionString);
      })
    });

    (rfApiVersions || []).forEach(el =>
    {
      (el || "").replace(/\[|]/g, "").split(",").forEach(v =>
      {
        (v || "").split("=")[1] && supportRFApiVersions.push((v || "").split("=")[1]);
      });
    })
    const needUpdateApp = (this.checkVersionResponses(environment.appVersion, supportedProductVersions)
      || this.compareVersions(environment.sfApiVersion, sfApiVersion) < 0);
    const needUpdateApi = this.checkVersionResponses("", supportRFApiVersions, true);
    this.handleVersionCheckResults(needUpdateApp, needUpdateApi);
  }

  // 1 is version a >= version b, -1 is version a < version b
  compareVersions(versionA: string, versionB: string): number
  {
    return formatVersion(versionA) - formatVersion(versionB) >= 0 ? 1 : -1;
  }

  handleVersionCheckResults(updateApp: boolean, updateApi: boolean)
  {
    if (updateApp)
    {
      this.logout(this.confirmUpdateApp.bind(this));
      throw new Error(this.translateText['login']["modal.app.upgrade.title"]);
    }
    else if (updateApi)
    {
      this.logout(this.confirmUpdateApi.bind(this));
      throw new Error(this.translateText['login']["modal.api.upgrade.title"]);
    }
    this.headerChecked.next(true);
  }

  confirmLogout()
  {
    this.showLogoutDialog().subscribe(confirmed =>
    {
      if (confirmed)
      {
        this.logout();
      }
    });
  }

  confirmUpdateApp()
  {
    this.showAppOutOfDateDialogue().subscribe(confirmed =>
    {
      if (confirmed)
      {
        this.openAppStorePage();
      }
    });
  }

  confirmUpdateApi()
  {
    this.showApiOutOfDateDialogue().subscribe(confirmed => { });
  }

  // TODO: Proper store app links when app is available
  openAppStorePage()
  {
    const iosAppStoreLink = 'https://apps.apple.com/us/app/stopfinder/id1038063658'; // itms-apps://itunes.apple.com/app/[appId]
    const androidAppStoreLink = 'https://play.google.com/store/apps/details?id=com.transfinder.stopfinder'; // market://details?id=[packageName]

    if (this.deviceService.isNativeMobile)
    {
      // use inappbrowser plugin
      if (this.deviceService.isiOS)
      {
        this.cordova.plugins.browsertab.openUrl(iosAppStoreLink);
      }
      else
      {
        this.cordova.plugins.browsertab.openUrl(androidAppStoreLink);
      }
    }
    else
    {
      if (this.deviceService.isiOS)
      {
        window.open(iosAppStoreLink, '_blank');
      }
      else
      {
        window.open(androidAppStoreLink, '_blank');
      }
    }
  }

  showLogoutDialog(): Observable<boolean>
  {
    if (!this.dialogRef)
    {
      this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false,
        data: {
          title: this.translateText['login']["modal.log.out.title"],
          message: this.translateText['login']["modal.log.out.body"],
          action: this.translateText['login']["modal.log.out.logout"],
          secondary: true,
          secondaryAction: this.translateText['login']["modal.log.out.cancel"]
        },
        scrollStrategy: new TargetedBlockingScrollStrategy(),
        panelClass: "confirm-dialog"
      });
    }

    return this.dialogRef.afterClosed().pipe(
      map(result =>
      {
        this.dialogRef = undefined;
        return !!result;
      })
    );
  }

  showApiOutOfDateDialogue(): Observable<boolean>
  {
    if (!this.dialogRef)
    {
      this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: true,
        data: {
          title: this.translateText['login']["modal.api.upgrade.title"],
          message: this.translateText['login']["modal.api.upgrade.body"],
          action: this.translateText['login']["modal.api.upgrade.ok"],
          secondary: false
        },
        scrollStrategy: new TargetedBlockingScrollStrategy(),
        panelClass: "confirm-dialog"
      });
    }

    return this.dialogRef.afterClosed().pipe(
      map(result =>
      {
        this.dialogRef = undefined;
        return !!result;
      })
    );
  }

  showAppOutOfDateDialogue(): Observable<boolean>
  {
    if (!this.dialogRef)
    {
      this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: true,
        data: {
          title: this.translateText['login']["modal.app.upgrade.title"],
          message: this.translateText['login']["modal.app.upgrade.body"],
          action: this.translateText['login']["modal.app.upgrade.update"],
          secondary: true,
          secondaryAction: this.translateText['login']["modal.app.upgrade.close"]
        },
        scrollStrategy: new TargetedBlockingScrollStrategy(),
        panelClass: "confirm-dialog"
      });
    }

    return this.dialogRef.afterClosed().pipe(
      map(result =>
      {
        this.dialogRef = undefined;
        return !!result;
      })
    );
  }

  communicationsObservableDirectly(): Observable<any>
  {
    return zip(
      this.stopfinderApi.getAnnouncements(),
      this.stopfinderApi.getMessageThreads(),
      this.stopfinderApi.getFormSent(),
    );
  }

  verifyEmailAddressFormat(emailAddress: string)
  {
    var emailRegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
    return emailRegExp.test(emailAddress);
  }

  updateMessageServiceLogin(isLogin)
  {
    this.messageService.loggedIn = !!isLogin;
  }

  private updateScheduleServiceVariable()
  {
    this.scheduleService.opaqueToken = this.opaqueToken;
    this.scheduleService.deviceIdentifier = this.deviceService.deviceIdentifier;
  }

  private formatNotificationData(data: PushNotification): PushNotification
  {
    const additionalData = { ...data.additionalData };

    additionalData.geoAlertNotificationId = Number(additionalData.geoAlertNotificationId);
    additionalData.etaAlertNotificationId = Number(additionalData.etaAlertNotificationId);
    additionalData.messageId = Number(additionalData.messageId);
    additionalData.riderId = Number(additionalData.riderId);
    additionalData.dataSourceId = Number(additionalData.dataSourceId);
    additionalData.rowId = Number(additionalData.rowId);
    additionalData.tripId = Number(additionalData.tripId);
    additionalData.sentAnnouncementId = Number(additionalData.sentAnnouncementId);
    additionalData.sentFormId = Number(additionalData.sentFormId);
    additionalData.sentFormRecipientId = Number(additionalData.sentFormRecipientId);
    additionalData.attendanceId = Number(additionalData.attendanceId);

    additionalData.isRemindLater = String(additionalData.isRemindLater) === 'true';
    additionalData.isShowAllQuestions = String(additionalData.isShowAllQuestions) === 'true';
    additionalData.required = String(additionalData.required) === 'true';

    return { ...data, additionalData };
  }

  private unregisterPushPlugin()
  {
    if (this.pushInstance)
    {
      this.setBadgeCount(0); // clear the badge count when user logout
      this.pushInstance.unregister(
        () => { this.pushInstance = null; },
        () => { }
      );
    }
  }
}
