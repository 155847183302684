import { ChangeDetectorRef, Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef, MatDialog, MatDialogConfig, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { DeviceService } from 'src/app/components/service/device/device.service';
import { MapService } from 'src/app/components/service/map/map.service';
import { ConfirmationDialogComponent } from 'src/app/shared/layout/confirmation-dialog/confirmation-dialog.component';
import { ETAAlertService } from 'src/app/shared/services/eta-alert.service';
import { EtaAlert } from 'src/app/shared/stopfinder/models/eta-alert';
import { NativeMapView } from 'src/app/tf-map/core/native-mapview';
import { AppService } from '../../../../../app.service';
import { IndicatorService } from '../../../../../shared/refresh/indicator.service';
import { EtaAlertsComponent } from './eta-alerts.component';
import { MapCoreService } from '../../../../../components/service/map/map-core.service';

@Component({
  selector: 'app-eta-alert',
  templateUrl: './eta-alert.component.html',
  styleUrls: ['../actions.component.scss'],
})
export class EtaAlertComponent implements OnInit, OnDestroy
{
  public title: string;
  public alertTimes: number[] = [5, 10, 15, 20];
  public isShowSelectionList: boolean = false;
  private _oldAlertTime: number;
  private readonly destroy$ = new Subject<boolean>();

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public etaAlert: EtaAlert,
    public actionsBottomSheet: MatBottomSheet,
    public readonly deviceService: DeviceService,
    public readonly etaAlertService: ETAAlertService,
    private readonly translate: TranslateService,
    private readonly dialogRef: MatDialog,
    private readonly bottomSheetRef: MatBottomSheetRef<EtaAlertsComponent>,
    private readonly _loadingIndicatorService: IndicatorService,
    private readonly _appService: AppService,
    private readonly mapCoreService: MapCoreService,
    private readonly ngZone: NgZone,
    private readonly _cdRef: ChangeDetectorRef,
    private readonly mapService: MapService)
  {

  }

  get isiOS()
  {
    return this.deviceService.isiOS;
  }

  get isiPhoneX()
  {
    return this.deviceService.isiPhoneX;
  }

  ngOnInit()
  {
    if (this.isNewEtaAlert())
    {
      this.etaAlert.alertTime = this.alertTimes[0]; //default value for new eta alert
      this.title = `${this.translate.instant("geo.add.eta.alert")}`;
    } else
    {
      this.title = `${this.translate.instant("geo.edit.eta.alert")}`;
      this._oldAlertTime = this.etaAlert.alertTime;
    }
  }

  ngOnDestroy()
  {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public save()
  {
    if (!this.etaAlert || !this.etaAlert.alertTime)
    {
      return;
    }
    this._loadingIndicatorService.show();
    const saveAlert$ = this.isNewEtaAlert()
      ? this.etaAlertService.createNewEtaAlert(this.etaAlert.subscriberId, this.etaAlert.riderId, this.etaAlert.alertTime)
      : this.etaAlertService.updateEtaAlert(this.etaAlert.id, this.etaAlert.alertTime);
    saveAlert$.subscribe(() =>
    {
      this.actionSplitViewMode();
      this._loadingIndicatorService.close();
      this.bottomSheetRef.dismiss();
    },
      (val) =>
      {
        if (val === false)
        {
          this._loadingIndicatorService.close();
          const dialogConfig: MatDialogConfig = {
            disableClose: true,
            data: {
              title: this.translate.instant("geo.add.modal.eta.title"),
              message: `${this.translate.instant("geo.add.modal.eta.body")}`,
              action: this.translate.instant("geo.add.modal.eta.button.ok"),
            },
            panelClass: "confirm-dialog"
          };
          this.dialogRef.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe();
        }
      });
  }

  public cancel()
  {
    if (this.isNewEtaAlert())
    {
      this.actionSplitViewMode();
      this.bottomSheetRef.dismiss();
    } else
    {
      const dialogConfig: MatDialogConfig = {
        disableClose: true,
        data: {
          title: this.translate.instant("geo.sheet.modal.cancel.title"),
          message: `${this.translate.instant("geo.sheet.modal.cancel.body")}`,
          action: this.translate.instant("geo.sheet.modal.cancel.yes"),
          secondary: true,
          secondaryAction: this.translate.instant("geo.sheet.modal.cancel.no"),
        },
        panelClass: "confirm-dialog"
      };

      this.dialogRef.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe((isCancel: boolean) =>
      {
        if (isCancel)
        {
          this.actionSplitViewMode();
          this.etaAlert.alertTime = this._oldAlertTime;
          this.bottomSheetRef.dismiss();
        }
      });
    }
  }

  public showAlertTimeSelector()
  {
    const listPickerInstance = this._appService.listPickerInstance;

    if (!listPickerInstance)
    {
      return;
    };

    const items = this.alertTimes.map((time) =>
    {
      return { text: time.toString(), value: time.toString() }
    });
    const config = {
      items: items,
      selectedValue: this.etaAlert.alertTime.toString(),
      doneButtonLabel: this.translate.instant("profile.language.done"),
      cancelButtonLabel: this.translate.instant("profile.language.cancel")
    };

    this.addClass();
    // Show the picker
    listPickerInstance.showPicker(config,
      (value) =>
      {
        this.ngZone.run(() =>
        {
          this.etaAlert.alertTime = +value;
          this._cdRef.markForCheck();
          this.removeClass();
        });
      },
      () =>
      {
        this.ngZone.run(() =>
        {
          this.removeClass();
        });
      }
    );
  }

  private addClass()
  {
    if (this.deviceService.isiPhone)
    {
      this.mapService.mapToolState = false;
      const etaAlertBody = document.getElementsByClassName('eta-alert-body')[0];
      const etaAlertRoot = document.getElementsByClassName('view-eta-alert-wrapper')[0];
      const overlayWrapper = document.getElementsByClassName('cdk-global-overlay-wrapper')[0];
      const bottomSheetWrapper = document.getElementsByClassName('mat-bottom-sheet-container')[0];
      const overlay = document.getElementsByClassName('eta-alert-overlay')[0];
      etaAlertBody.classList.add('expand');
      etaAlertRoot.classList.add('expand');
      overlayWrapper.classList.add('expand');
      bottomSheetWrapper.classList.add('eta-alert');
      (overlay as HTMLElement).style.display = 'block';
      NativeMapView.nativeEsriMapPlugin.toggleMapViewVisible(this.mapService.mapId, false, () => { }, () => null);
    }
  }

  private removeClass()
  {
    if (this.deviceService.isiPhone)
    {
      this.mapService.mapToolState = true;
      const etaAlertBody = document.getElementsByClassName('eta-alert-body')[0];
      const etaAlertRoot = document.getElementsByClassName('view-eta-alert-wrapper')[0];
      const overlayWrapper = document.getElementsByClassName('cdk-global-overlay-wrapper')[0];
      const bottomSheetWrapper = document.getElementsByClassName('mat-bottom-sheet-container')[0];
      const overlay = document.getElementsByClassName('eta-alert-overlay')[0];
      etaAlertBody.classList.remove('expand');
      etaAlertRoot.classList.remove('expand');
      overlayWrapper.classList.remove('expand');
      bottomSheetWrapper.classList.remove('eta-alert');
      (overlay as HTMLElement).style.display = 'none';
      NativeMapView.nativeEsriMapPlugin.toggleMapViewVisible(this.mapService.mapId, true, () => { }, () => null);
    }
  }

  private isNewEtaAlert()
  {
    return !this.etaAlert || !this.etaAlert.id || this.etaAlert.id < 0;
  }

  private readonly actionSplitViewMode = () => this.deviceService.isAndroid && this.mapCoreService.splitMode();
}
