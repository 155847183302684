/**
 * The different Symbols that will be used in this project
 */
export enum SymbolType
{
    transparentTouchSymbol = 'transparentTouchSymbol',
    tripStopMarkerSymbol = 'tripStopMarkerSymbol',
    sfStudentAddressSymbol = 'sfStudentAddressSymbol',
    sfSchoolAddressSymbol = 'sfSchoolAddressSymbol',
    sfSchoolAddressPictureSymbol = 'sfSchoolAddressPictureSymbol',
    sfStudentAddressPictureSymbol = 'sfStudentAddressPictureSymbol',
    labelTop = 'labelTop',
    labelBottom = 'labelBottom',
    tripPathSymbol = 'tripPathSymbol',
    tripStopPointSymbol = 'tripStopPointSymbol',
    tripStopLabelSymbol = 'tripStopLabelSymbol',
    attendancePointSymbol = 'attendancePointSymbol',
}
