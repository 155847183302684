import
{
  Injectable,
  Injector,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  ApplicationRef,
  Renderer2,
  RendererFactory2,
  ComponentRef
} from '@angular/core';

@Injectable()
export class PortalService
{
  renderer: Renderer2;
  componentRef: ComponentRef<any>;

  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly appRef: ApplicationRef,
    private readonly injector: Injector,
    private readonly rendererFactory: RendererFactory2
  )
  {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  create(component: any, data?: { [key: string]: any })
  {
    this.appendComponentToBody(component, data);
  }

  update(data?: { [key: string]: any })
  {
    this.componentRef && this.updateComponentInstance(this.componentRef, data);
  }

  destroy()
  {
    if (!this.componentRef) return;
    this.appRef.detachView(this.componentRef.hostView);
    this.componentRef.destroy();
  }

  private appendComponentToBody(component: any, data?: { [key: string]: any })
  {
    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(this.injector);
    this.updateComponentInstance(componentRef, data);
    this.appRef.attachView(componentRef.hostView);
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    this.renderer.appendChild(document.body, domElem);
    this.componentRef = componentRef;
  }

  private updateComponentInstance(componentRef: ComponentRef<any>, data?: { [key: string]: any })
  {
    for (const key in data)
    {
      key && (componentRef.instance[key] = data[key]);
    }
  }
}
