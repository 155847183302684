import { AbstractControl } from '@angular/forms';

export class PasswordValidation {
    static MatchPassword(abstractionControl: AbstractControl) {
       const password = abstractionControl.get('password').value;
       const confirmPassword = abstractionControl.get('passwordConfirmation').value;
        if (password !== confirmPassword) {
            abstractionControl.get('passwordConfirmation').setErrors( { MatchPassword: true } );
        } else {
            return null;
        }
    }
}