import * as _ from 'lodash';
import
{
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  ComponentFactoryResolver,
  ViewContainerRef,
  ComponentFactory,
  ViewChild,
  ComponentRef,
} from '@angular/core';
import { FormService } from '../../components/service/form/form.service';
import
{
  IQuestion,
  FormQuestionEnum,
  QuestionType,
  ReplyQuestionStatusEnum,
  IRequiredQuestionValid,
  IQuestionResult,
} from '../form.interface';

import { QuestionComponentsHostDirective } from './question-host.directive';

import
{
  BooleanQuestionComponent,
  DateQuestionComponent,
  DateTimeQuestionComponent,
  MemoQuestionComponent,
  NumberQuestionComponent,
  PhoneQuestionComponent,
  TextQuestionComponent,
  TimeQuestionComponent,
  ZipQuestionComponent,
  SingleListQuestionComponent,
  MultipleListQuestionComponent,
  RateQuestionComponent,
  SystemFieldQuestionComponent,
  CurrencyQuestionComponent,
  AttachmentQuestionComponent,
  SignatureQuestionComponent,
} from './question.component';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AndroidBackService } from '../../androidBack.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: `sf-form-questions`,
  templateUrl: 'form.questions.component.html',
  styleUrls: ['form.questions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SFFormQuestionsComponent implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(QuestionComponentsHostDirective, { static: true }) questionComponentsHost: QuestionComponentsHostDirective;

  public showAllQuestions: boolean = false;
  public buttonText: string = ReplyQuestionStatusEnum.next;

  private _formQuestionsList: IQuestion[];
  private formQuestionsList: Observable<IQuestion[]> = this.formService.questionsList.asObservable();
  private formStatus: BehaviorSubject<ReplyQuestionStatusEnum> = new BehaviorSubject<ReplyQuestionStatusEnum>(ReplyQuestionStatusEnum.start);

  // only have data once showAllQuestions = false
  private _inprogressQuestion: BehaviorSubject<IQuestion> = new BehaviorSubject<IQuestion>(null);
  private inprogressQuestion: Observable<IQuestion> = this._inprogressQuestion.asObservable();

  // declare subscriptions
  private formQuestionsSubscription: Subscription;
  private inprogressQuestionSubscription: Subscription;
  private questionValidSubscription: Subscription;
  private questionAnswerSubscription: Subscription;
  private formLoadedSubscription: Subscription;

  public inprogressQuestionIndex: number = 0;
  public progressTotal: number = 0;

  private _singleQuestionValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private singleQuestionValid: Observable<boolean> = this._singleQuestionValid.asObservable();
  public inprogressQuestionValid: boolean;

  private _allQuestionsValid: BehaviorSubject<IRequiredQuestionValid> = new BehaviorSubject<IRequiredQuestionValid>(null);
  private allQuestionsValid: Observable<IRequiredQuestionValid> = this._allQuestionsValid.asObservable();
  public totalQuestionsValid: IRequiredQuestionValid[] = [];

  private _questionAnswer: BehaviorSubject<IQuestionResult> = new BehaviorSubject<IQuestionResult>(null);
  private questionAnswer: Observable<IQuestionResult> = this._questionAnswer.asObservable();

  private _translateObservable: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private translateObservable: Observable<any> = this._translateObservable.asObservable();

  private currentClickElement: {
    top: number;
    height: number;
  } = null;

  constructor(
    public readonly formService: FormService,
    public readonly translate: TranslateService,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    private readonly androidBackService: AndroidBackService,

  )
  {
    this.keyboardOpen = this.keyboardOpen.bind(this);
    this.translate.stream("form").subscribe((translate) =>
    {
      this._translateObservable.next(translate);
    })
  }

  ngOnInit()
  {
    this.viewContainerRef = this.questionComponentsHost.viewContainerRef;

    this.formQuestionsSubscription = this.formQuestionsList.subscribe((questions: IQuestion[]) => this.prepareQuestionsList(questions));

    this.androidBackService.onShouldCheckCallback(() => { }); // disable the android back button

    window.addEventListener("keyboardDidShow", this.keyboardOpen);
  }

  ngAfterViewInit() { }

  ngOnDestroy()
  {
    this.formQuestionsSubscription && this.formQuestionsSubscription.unsubscribe();
    this.formLoadedSubscription && this.formLoadedSubscription.unsubscribe();
    this.inprogressQuestionSubscription && this.inprogressQuestionSubscription.unsubscribe();
    this.questionValidSubscription && this.questionValidSubscription.unsubscribe();
    this.questionAnswerSubscription && this.questionAnswerSubscription.unsubscribe();
    this.androidBackService.onDestroyCallback();
    window.removeEventListener("keyboardDidShow", this.keyboardOpen);
    this.currentClickElement = null;
  }

  private prepareQuestionsList(questions: IQuestion[])
  {
    // 1. set property value from subscribed value
    this._formQuestionsList = questions;
    // 2. set show all questions or show single question
    // TEST CASE: Manually enable single question by un-comment below code:
    // this.showAllQuestions = false && this.formService.isShowAllQuestions;
    this.showAllQuestions = this.formService.isShowAllQuestions;
    // 3. launch question or questions
    this.showAllQuestions && this.loadAllComponents();
    // 4. only trigger --- single question
    !this.showAllQuestions && this.singleQuestionHandler();
    // 5. trigger single question subscribe
    !this.showAllQuestions && this.singleQuestionPerPage();
  }

  private singleQuestionPerPage()
  {
    // set total questions count
    this.progressTotal = this._formQuestionsList.length;

    // subscribe single question per page subscription
    this.inprogressQuestionSubscription = this.inprogressQuestion.subscribe((question: IQuestion) =>
    {
      this.buttonText = ReplyQuestionStatusEnum.next;
      // load question
      this.loadSingleComponent(question);

      this.formLoadedSubscription = this.formService.isProgressLoadedObservable.subscribe((isLoaded: boolean) =>
      {
        if (!isLoaded) return;

        // update progress bar
        this.formService.inprogressIndex.next(this.inprogressQuestionIndex);

        // update form status to submit once is the last question
        if (_.isEqual(this.inprogressQuestionIndex + 1, this._formQuestionsList.length))
        {
          setTimeout(() => { this.buttonText = ReplyQuestionStatusEnum.finish; })
        }
      });
    });

    this.questionValidSubscription = this.singleQuestionValid.subscribe((isValid: boolean) =>
    {
      this.inprogressQuestionValid = isValid;
    });
  }

  private singleQuestionHandler()
  {
    // get specific question from response
    const loadingQuestion: IQuestion = this._formQuestionsList[this.inprogressQuestionIndex];
    // set inProgress question
    this._inprogressQuestion.next(loadingQuestion);
  }

  private getComponentByType(questionType: QuestionType): any
  {
    switch (questionType)
    {
      case FormQuestionEnum.boolean:
        return BooleanQuestionComponent;
      case FormQuestionEnum.date:
        return DateQuestionComponent;
      case FormQuestionEnum.dateTime:
        return DateTimeQuestionComponent;
      case FormQuestionEnum.memo:
        return MemoQuestionComponent;
      case FormQuestionEnum.number:
        return NumberQuestionComponent;
      case FormQuestionEnum.phone:
        return PhoneQuestionComponent;
      case FormQuestionEnum.text:
        return TextQuestionComponent;
      case FormQuestionEnum.time:
        return TimeQuestionComponent;
      case FormQuestionEnum.zip:
        return ZipQuestionComponent;
      case FormQuestionEnum.listSingleSelect:
        return SingleListQuestionComponent;
      case FormQuestionEnum.listMultipleSelect:
        return MultipleListQuestionComponent;
      case FormQuestionEnum.rate:
        return RateQuestionComponent;
      case FormQuestionEnum.systemField:
        return SystemFieldQuestionComponent;
      case FormQuestionEnum.currency:
        return CurrencyQuestionComponent;
      case FormQuestionEnum.attachment:
        return AttachmentQuestionComponent;
      case FormQuestionEnum.signature:
        return SignatureQuestionComponent;
    }
  }

  private loadComponentBase = (questionType: QuestionType): ComponentFactory<any> => this.componentFactoryResolver.resolveComponentFactory(this.getComponentByType(questionType))

  private loadSingleComponent(question: IQuestion)
  {
    // for list question, app self declare two sub types as single list and multiple list
    // QuestionType = 10 only means list question, the sub type will be handler by below event
    const questionTypeId: QuestionType = this.listQuestionHandler(question);
    // get mapping Component
    const componentFactory = this.loadComponentBase(questionTypeId);
    // clear the view container. in this case, view container is the directive host
    this.viewContainerRef.clear();
    // create Component to the view container
    const componentRef = this.viewContainerRef.createComponent(componentFactory);
    // init component and data
    const _question = question.TypeId !== FormQuestionEnum.systemField ? question : { ...question, udfFields: this.formService.userDefendFields.getValue() };
    this.componentInitialize(componentRef, _question);
  }

  private loadAllComponents(manualList?: IQuestion[])
  {
    // handle manual list from external passed, if needed.
    // otherwise directly use all question list
    const questionList = manualList ? manualList : this._formQuestionsList;
    // clear the view container once loading all questions
    this.viewContainerRef.clear();
    // set button type to submit
    this.buttonText = ReplyQuestionStatusEnum.submit;
    // map all question type
    questionList.map((eachQuestion: IQuestion) =>
    {
      // for list question, app self declare two sub types as single list and multiple list
      // QuestionType = 10 only means list question, the sub type will be handler by below event
      const questionId: QuestionType = this.listQuestionHandler(eachQuestion);
      // get mapping Component
      const componentFactory = this.loadComponentBase(questionId);
      // create Component to the view container
      const componentRef = this.viewContainerRef.createComponent(componentFactory);
      // init component and data
      const _question = eachQuestion.TypeId !== FormQuestionEnum.systemField ? eachQuestion : { ...eachQuestion, udfFields: this.formService.userDefendFields.getValue() };
      this.componentInitialize(componentRef, _question);
    });

    this.questionValidSubscription = this.allQuestionsValid.subscribe((inValidList: IRequiredQuestionValid) => 
    {
      _.remove(this.totalQuestionsValid, (i: IRequiredQuestionValid) => i.questionId === inValidList.questionId)
      inValidList && inValidList.questionId && this.totalQuestionsValid.push(inValidList);
    });
  }

  private componentInitialize(componentRef: ComponentRef<any>, question: IQuestion)
  {
    // attach the Component instance data
    componentRef.instance.data = question;
    // attach question status
    componentRef.instance.questionStatus = this.formStatus.asObservable();
    // set question type is show all or show single
    componentRef.instance.isAllQuestionsLoaded = this.showAllQuestions;
    // set question answer behavior and listen the value update
    componentRef.instance.questionAnswer = this._questionAnswer;
    // set question validation: THIS IS SINGLE QUESTION VALIDATION
    !this.showAllQuestions && (componentRef.instance.questionValidation = this._singleQuestionValid);
    // set question validation: THIS IS ALL QUESTIONS VALIDATION
    this.showAllQuestions && (componentRef.instance.questionValidation = this._allQuestionsValid);
    // get cached question result
    const cachedQuestionResult = this.formService.getSubmitQuestionsResult(question.Id);
    // if cached question reply existed, apply to component instance
    cachedQuestionResult && (componentRef.instance.cacheResult = cachedQuestionResult);
    // add the translate service
    componentRef.instance.translateObservable = this.translateObservable;

    // answer question and save to cache subscription. Subscription will be released in destroy event
    this.questionAnswerSubscription = this.questionAnswer.subscribe((answer: IQuestionResult) => this.formService.addQuestionResult(answer));
  }

  private listQuestionHandler(question: IQuestion): QuestionType
  {
    if (question && _.isEqual(question.TypeId, 10))
    {
      return question.PickListMultiSelect ? 10.2 : 10.1;
    }
    return question.TypeId;
  }

  public formQuestionButtonClick()
  {
    // 1. set submit before clicking next
    if (_.isEqual(this.buttonText, ReplyQuestionStatusEnum.submit) || _.isEqual(this.buttonText, ReplyQuestionStatusEnum.finish))
    {
      // Save form questions, all question handler will validate themselves by status observable value change
      // form status submit will trigger validation
      this.formStatus.next(ReplyQuestionStatusEnum.submit);
      let isReadyToSubmit: boolean = false;

      if (this.showAllQuestions)
      {
        const requiredErrorList = _.remove(this.totalQuestionsValid, (i: IRequiredQuestionValid) => !i.valid);
        isReadyToSubmit = _.isEqual(requiredErrorList.length, 0);
      }
      else
      {
        isReadyToSubmit = this.inprogressQuestionValid;
      }

      isReadyToSubmit && this.formService.updateLoadingStatus(true);

      isReadyToSubmit && this.formService.hasFormCompleted().then((hasCompleted) =>
      {
        if (hasCompleted)
        {
          this.formService.updateLoadingStatus(false);
          this.formService.showFormCompletedAlert();
          return;
        }

        // no error left, safe to submit
        this.formService.saveQuestionResults();

        return;
      }).catch(() => { this.formService.updateLoadingStatus(false); });
    }

    // 2.normal set form status to validate
    this.formStatus.next(ReplyQuestionStatusEnum.validate);

    // 3. trigger next question
    if (this.inprogressQuestionValid && this.inprogressQuestionIndex < this._formQuestionsList.length - 1)
    {
      // increase the inProgress Question index
      this.inprogressQuestionIndex++
      // launch the question
      this.singleQuestionHandler();
      // update the progress bar 
      this.formService.inprogressIndex.next(this.inprogressQuestionIndex);
      // update form status to next
      this.formStatus.next(ReplyQuestionStatusEnum.next);
    }
  }

  public previousClick()
  {
    // set form status as previous
    this.formStatus.next(ReplyQuestionStatusEnum.previous);

    if (this.inprogressQuestionIndex > 0)
    {
      // decrease the inProgress Question index
      this.inprogressQuestionIndex--
      // launch the question
      this.singleQuestionHandler();
      // update the progress bar
      this.formService.inprogressIndex.next(this.inprogressQuestionIndex);
    }
    else
    {
      this.formService.updateCoverStatus(true);
    }
  }

  public cancelClick()
  {
    if (this.formService.isRequired)
    {
      this.formService.doRemindMeLater();
      return;
    }

    this.formService.updateFormStatusToCancel();
  }

  public onWrapperClick(event: MouseEvent)
  {
    const target = event.target as HTMLElement;
    const tagName = target.tagName.toLowerCase();
    if (tagName === "input" || tagName === "textarea")
    {
      this.currentClickElement = {
        top: event.clientY,
        height: target.offsetHeight,
      };
    }
  }

  private keyboardOpen(event)
  {
    const questionWrapper = document.querySelector(".question-component-wrapper") as HTMLElement;
    if (questionWrapper && this.currentClickElement)
    {
      const clientY = this.currentClickElement.top + this.currentClickElement.height;

      if (screen.availHeight - clientY <= event.keyboardHeight)
      {
        questionWrapper.scrollTop += (event.keyboardHeight + this.currentClickElement.height);
      }

      this.currentClickElement = null;
    }
  }
}
