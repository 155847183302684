import { Component, OnInit, OnDestroy, AfterViewChecked } from '@angular/core';

import { StopfinderApiService } from '../../shared/stopfinder/stopfinder-api.service';
import
{
  AppFeedback,
  AppRating,
  RatingResponseType
} from '../../shared/stopfinder/stopfinder-models';
import
{
  MatDialog,
} from '@angular/material';
import { FormControl, FormGroup } from '@angular/forms';
import { ConfirmationDialogComponent } from '../../shared/layout/confirmation-dialog/confirmation-dialog.component';
import { TargetedBlockingScrollStrategy } from '../../shared/material/targeted-blocking-scroll-strategy';
import { AppService } from '../../app.service';
import { Subscription } from '../../shared/stopfinder/models/subscription';
import { StateService } from '../../components/service/state/state.service';
import { DeviceService } from 'src/app/components/service/device/device.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './app-feedback.component.html',
  styleUrls: ['./app-feedback.component.scss'],
})
export class AppFeedbackComponent implements OnInit, OnDestroy, AfterViewChecked
{
  public subscription: Subscription;
  public currentMessage = '';
  public maxRows = 100;
  public expand = true;
  public raise = false;
  public feedbackForm: FormGroup;
  public formControls;
  constructor(
    public readonly deviceService: DeviceService,
    private readonly stopfinderApi: StopfinderApiService,
    private readonly dialogRef: MatDialog,
    private readonly confirmdialogRef: MatDialog,
    readonly _appService: AppService,
    private stateService: StateService,
  )
  {
    this.feedbackForm = new FormGroup({
      currentMessage: new FormControl({ value: '', disabled: false }),
    });
    this.formControls = this.feedbackForm.controls;
  }

  ngAfterViewChecked()
  {
  }

  ngOnDestroy()
  {
    this._appService.lastLocation = '';
  }

  ngOnInit()
  {
    const focusElement = document.querySelector('#feedback-input') as HTMLElement;
    focusElement.blur();
    focusElement.focus();
  }

  scrollToTop()
  {
    const targets = document.getElementsByClassName('scroll-strategy') as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < targets.length; i++)
    {
      targets[i].scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  cancel(e: MouseEvent)
  {
    e.preventDefault();
    if (this.currentMessage)
    {
      this.confirmdialogRef
        .open(ConfirmationDialogComponent, {
          disableClose: false,
          data: {
            title: 'Cancel',
            message: 'Are you sure you want to cancel?',
            action: 'Yes',
            secondary: true,
            secondaryAction: 'No'
          },
          scrollStrategy: new TargetedBlockingScrollStrategy(),
          panelClass: "confirm-dialog"
        })
        .beforeClosed()
        .subscribe(response =>
        {
          if (response)
          {
            this.confirmdialogRef.closeAll();
            this.back();
          }
        });
    } else
    {
      this.dialogRef.closeAll();
      this.back();
    }
  }

  back()
  {
    this.stateService.goRoute(`${this._appService.lastLocation}`);
  }

  onSubmit()
  {
    if (!this.currentMessage)
    {
      return;
    }

    this.raise = false;
    const messageBody = this.currentMessage;

    this.stopfinderApi
      .postAppFeedback({
        sentOn: new Date().toISOString().replace("Z", ""),
        body: messageBody,
      } as AppFeedback).subscribe(response =>
      {
        this.stopfinderApi.postAppRating({
          lastPromptedOn: new Date().toISOString().replace("Z", ""),
          lastResponse: RatingResponseType.No
        } as AppRating).subscribe(response =>
        {
        });
      });
    this.dialogRef.closeAll();
    this.back();
    // clear input
    this.currentMessage = '';
  }
}
