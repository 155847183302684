
import { PipeTransform, Pipe } from "@angular/core";
import { formatPhoneNumber } from '../../shared/utils/utils';

@Pipe({ name: 'cellPhoneDating' })
export class CellPhoneDatingPipe implements PipeTransform
{
  constructor() { }
  transform(phone: string)
  {
    return formatPhoneNumber((phone || '').replace(/\D/g, ''));
  }
}
