import * as _ from 'lodash';
import * as moment from 'moment';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, zip } from 'rxjs';
import { IForm, IFormAnswer, FormQuestionEnum } from '../form.interface';
import { AndroidBackService } from '../../androidBack.service';
import { FormService } from '../../components/service/form/form.service';
import { StopfinderApiService } from 'src/app/shared/stopfinder/stopfinder-api.service';
import { Communication, CommunicationType, IUserDefinedField } from '../../shared/stopfinder/stopfinder-models';
import { MessagesService } from 'src/app/messages/messages.service';
import { MenuOption } from 'src/app/shared/layout/header/header.component';
import { AppService } from 'src/app/app.service';
import { CurrencyPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from 'src/app/components/service/device/device.service';
import { formatSystemField, isImageSystemFiled, setImageSystemFiledClass, formatPhoneNumber } from 'src/app/shared/utils/utils';

export enum FormActions
{
  Archive,
  Restore,
}

@Component({
  selector: `sf-form-answer`,
  templateUrl: 'form.answer.component.html',
  styleUrls: ['form.answer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SFFormAnswerComponent implements OnInit, AfterViewInit, OnDestroy
{
  public communicationType = CommunicationType;
  public showNoData: boolean = false;
  public formInfo: IForm = null;
  public communication: Communication = null;

  public title: string = "";
  public options: MenuOption[] = [];
  public isImageAnswer = isImageSystemFiled.bind(this);
  public setImageAnswerClass = setImageSystemFiledClass.bind(this);

  public isLoading: boolean = true;

  private formSendId: number;
  private recipientId: number;

  public formQuestionAnswer: Array<IFormAnswer> = [];
  private udfFields: IUserDefinedField[];

  constructor(
    private readonly currencyPipe: CurrencyPipe,
    private readonly router: ActivatedRoute,
    private readonly androidBackService: AndroidBackService,
    private readonly stopfinderApiService: StopfinderApiService,
    private readonly messageService: MessagesService,
    private readonly formService: FormService,
    public readonly _appService: AppService,
    public readonly translate: TranslateService,
    public readonly deviceService: DeviceService,
  )
  {
  }

  ngOnInit()
  {
    this.communication = this.formService.communication;
    this.androidBackService.onShouldCheckCallback(this.onCancel.bind(this));

    if (!this.communication)
    {
      this.formService.openMessagesPage();
      return;
    }

    this.options = [
      {
        text: this.communication.archived ? this.translate.instant("message.restore") : this.translate.instant("message.archive"),
        action: this.communication.archived ? FormActions.Restore : FormActions.Archive
      }
    ];

    this.formSendId = Number(this.router.snapshot.paramMap.get('formSendId'));
    this.recipientId = Number(this.router.snapshot.paramMap.get('recipientId'));
    this.title = this.communication.formName;

    zip(
      this.stopfinderApiService.getQuestionResults(this.formSendId, this.recipientId),
      this.stopfinderApiService.getUserDefinedFields(this.recipientId),
    ).subscribe(([results, udf]) =>
    {
      this.udfFields = udf;

      let questionResult = [];
      results.forEach(item =>
      {
        if (item.UDFTypeName === 'Phone Number' && item.Answer)
        {
          item.Answer = formatPhoneNumber(item.Answer);
        }
        
        questionResult.push({
          question: item.QuestionContent,
          answer: this.generateAnswerByType(item),
          type: item.TypeId,
        });
      });
      this.formQuestionAnswer = questionResult;
      this.isLoading = false;
    });

  }

  ngAfterViewInit()
  {
  }

  ngOnDestroy()
  {
    this.androidBackService.onDestroyCallback();
    this.isLoading = false;
  }

  onCancel()
  {
    this.formService.openMessagesPage();
  }

  scrollToTop()
  {
    const targets = document.getElementsByClassName('scroll-strategy') as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < targets.length; i++)
    {
      targets[i].scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  handleMenuAction(event)
  {
    let obsData: Observable<any>;
    switch (event.action)
    {
      case FormActions.Archive:
        obsData = this.messageService.markCommunicationArchiveStatus(this.communication, true);
        this.communication.archived = true;
        break;
      case FormActions.Restore:
        obsData = this.messageService.markCommunicationArchiveStatus(this.communication, false);
        this.communication.archived = false;
        break;
    }

    obsData.subscribe((data) =>
    {
      !!data && this.formService.openMessagesPage();
    })
  }

  private generateAnswerByType(question: any)
  {
    const questionType = question.TypeId;
    const answer = questionType === FormQuestionEnum.attachment ? question.attachments : question.Answer;

    if (!answer) return answer;

    switch (questionType)
    {
      case FormQuestionEnum.date:
        return moment(answer).format('MM/DD/YYYY');
      case FormQuestionEnum.dateTime:
        return moment(answer).format('MM/DD/YYYY hh:mm A');
      case FormQuestionEnum.time:
        const dateTime = moment(moment().format('MM/DD/YYYY') + " " + answer);
        return moment(dateTime).format('hh:mm A');
      case FormQuestionEnum.rate:
        return `${answer} ${this.translate.instant("form.question.out.of")} ${question.Scale}`;
      case FormQuestionEnum.number:
        return parseFloat(answer).toFixed(question.NumberPrecision);
      case FormQuestionEnum.currency:
        const maxDecimal = question.MaxLength === 0 ? 0 : (question.MaxLength || 2);
        return this.currencyPipe.transform(answer, '', '$', `1.${maxDecimal}`);
      case FormQuestionEnum.systemField:
        return formatSystemField(question.SystemFieldName, answer, this.udfFields);
      default:
        return answer;
    }
  }
}
