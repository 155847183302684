import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { EtaAlert } from "../stopfinder/models/eta-alert";
import { StopfinderApiService } from "../stopfinder/stopfinder-api.service";


@Injectable()
export class ETAAlertService
{
  private _etaAlerts: Array<EtaAlert>;
  private _etaAlertsSubject: BehaviorSubject<Array<EtaAlert>>;
  public etaAlertsObservable: Observable<Array<EtaAlert>>;
  constructor(private readonly _apiService: StopfinderApiService)
  {
    this._etaAlerts = [];
    this._etaAlertsSubject = new BehaviorSubject<Array<EtaAlert>>(this._etaAlerts);
    this.etaAlertsObservable = this._etaAlertsSubject.asObservable();
  }

  get etaAlertCount()
  {
    return this._etaAlerts.length;
  }

  createNewEtaAlert(subscriberId: number, riderId: number, alertTime: number): Observable<void>
  {
    const etaAlertExists = this._etaAlerts.find((e) => e.subscriberId === subscriberId && e.riderId === riderId && e.alertTime === alertTime);
    if (!!etaAlertExists)
    {
      return throwError(false);
    }
    const etaAlert = {
      subscriberId: subscriberId,
      riderId: riderId,
      alertTime: alertTime
    } as EtaAlert;
    return this._apiService.createEtaAlert(etaAlert).pipe(switchMap(() => this.fetchETAAlerts(subscriberId, riderId)));
  }

  // Use in conjunction with other ops to update list automatically
  fetchETAAlerts(subscriberId: number, riderId: number): Observable<void>
  {
    return this._apiService.getETAAlerts(subscriberId, riderId).pipe(catchError(() => of([])),tap((response) =>
    {
      this._etaAlerts = response.sort((a, b) => a.alertTime > b.alertTime ? 1 : -1);
      this._etaAlertsSubject.next(this._etaAlerts);
    }), map(() => { return; }));
  }

  deleteEtaAlert(id: number): Observable<void>
  {
    const etaAlertToDelete = this._etaAlerts.find((e) => e.id === id);
    return this._apiService.deleteEtaAlert(id).pipe(switchMap(() => this.fetchETAAlerts(etaAlertToDelete.subscriberId, etaAlertToDelete.riderId)));
  }

  updateEtaAlert(id: number, alertTime: number): Observable<void>
  {
    const etaAlertToUpdate = this._etaAlerts.find((e) => e.id === id);
    const etaAlertExists = this._etaAlerts.find((e) => 
      e.riderId === etaAlertToUpdate.riderId && e.subscriberId === etaAlertToUpdate.subscriberId && 
      e.alertTime === alertTime && e.id !== id) // duplicated eta alert other than itself
    if (!!etaAlertExists)  
    {
      return throwError(false); 
    }

    etaAlertToUpdate.alertTime = alertTime;
    return this._apiService.putEtaAlert(etaAlertToUpdate).pipe(switchMap(() => this.fetchETAAlerts(etaAlertToUpdate.subscriberId, etaAlertToUpdate.riderId)));
  }
}
