import * as _ from 'lodash';
import { Injectable, Injector, Inject } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { ToastComponent } from './toast.component';
import { ToastData, TOAST_CONFIG_TOKEN, ToastConfig, defaultToastConfig } from './toast-config';
import { ToastRef } from './toast-ref';

import { PushSnackbarComponent } from '../../messages/push-snackbar/push-snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class ToastService
{
  private lastToast: ToastRef;

  constructor(
    private overlay: Overlay,
    private parentInjector: Injector,
    @Inject(TOAST_CONFIG_TOKEN) public toastConfig: ToastConfig
  )
  {
    this.toastConfig = defaultToastConfig;
  }

  private showBase(data: ToastData)
  {
    const positionStrategy = this.getPositionStrategy();
    // toast cdk overlay overwrite in style.scss
    const overlayRef = this.overlay.create({ positionStrategy, panelClass: 'toast-cdk-overlay' });
    const toastRef = new ToastRef(overlayRef);
    this.lastToast = toastRef;

    return {
      toastRef: toastRef,
      overlayRef: overlayRef,
      injector: this.getInjector(data, toastRef, this.parentInjector),
    };
  }

  public show(data: ToastData)
  {
    const { toastRef, overlayRef, injector } = this.showBase(data);
    const toastPortal = new ComponentPortal(ToastComponent, null, injector);
    overlayRef.attach(toastPortal);
    return toastRef;
  }

  public showSnackBar(data: ToastData)
  {
    const { toastRef, overlayRef, injector } = this.showBase(data);
    const toastPortal = new ComponentPortal(PushSnackbarComponent, null, injector);
    overlayRef.attach(toastPortal);
    overlayRef.hostElement.classList.add(`toast-overlay-wrapper`);

    if (_.isEqual(this.toastConfig.position.top, 40))
    {
      overlayRef.hostElement.classList.add(`iPhoneX`);
    }

    return toastRef;
  }

  public updateConfig(config: ToastConfig)
  {
    _.merge(this.toastConfig, config);
  }

  public getPositionStrategy()
  {
    return this.overlay.position()
      .global()
      .top(this.getPosition())
      .right(`${this.toastConfig.position.right}px`);
  }

  public getPosition()
  {
    const lastToastIsVisible = this.lastToast && this.lastToast.isVisible();
    const position = lastToastIsVisible
      ? this.lastToast.getPosition().bottom
      : this.toastConfig.position.top;

    return `${position}px`;
  }

  private getInjector(data: ToastData, toastRef: ToastRef, parentInjector: Injector)
  {
    const tokens = new WeakMap();
    tokens.set(ToastData, data);
    tokens.set(ToastRef, toastRef);
    return new PortalInjector(parentInjector, tokens);
  }
}
