import { AfterViewInit, Component, NgZone, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TutorialStateEnum, ITutorialSlide, ITutorial, ITutorialImage } from './tutorial.interface';
import * as State from '../components/service/state/state.service';
import Swiper from 'swiper';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { AppService } from '../app.service';
import { AndroidBackService } from '../androidBack.service';
import { Language } from '../shared/utils/enum';
import { getAppVersion } from '../shared/utils/utils';
import { TutorialService } from '../components/service/tutorial/tutorial.service';
import { DeviceService } from '../components/service/device/device.service';

@Component({
  selector: `tf-tutorial`,
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TutorialComponent implements OnInit, AfterViewInit, OnDestroy
{
  public showSpinner = true;

  public tutorialId = null;
  public tutorialType: TutorialStateEnum = null;
  public prevAppVersion: string = `0`;

  public tutorialButtonText: string = null;
  public swipe: Swiper = null;

  public tutorialImages = [];
  public imageHeight = this.setImageHeight();

  public hiddenMap: boolean = false;

  constructor(
    public readonly appService: AppService,
    public readonly deviceService: DeviceService,
    private readonly stateService: State.StateService,
    private readonly activeRouter: ActivatedRoute,
    private readonly androidBackService: AndroidBackService,
    private readonly ngZone: NgZone,
    private readonly sanitizer: DomSanitizer,
    public readonly translate: TranslateService,
    private readonly tutorialService: TutorialService,
  )
  {
    // which feature should be shown
    this.tutorialId = this.activeRouter.snapshot.paramMap.get(`id`);
    // wizard display type, all or single
    this.tutorialType = this.activeRouter.snapshot.paramMap.get(`state`) as TutorialStateEnum;
    // get the last app version
    this.prevAppVersion = this.activeRouter.snapshot.paramMap.get(`prevAppVersion`);

    this.tutorialButtonText = this.translate.instant(`tutorial.skip`);

    this.androidBackService.onShouldCheckCallback(this.endTutorial.bind(this));

    this.hiddenMap = this.tutorialType === TutorialStateEnum.all && !this.tutorialId;
  }

  ngOnInit()
  {
  }

  async ngAfterViewInit()
  {
    this.getTutorialImg().then((value) =>
    {
      if (value)
      {
        this.translate.stream('tutorial').subscribe(() =>
        {
          setTimeout(() =>
          {
            const swiper = new Swiper('.swiper-container', {
              roundLengths: true,
              flipEffect: {
                slideShadows: false,
              },
              spaceBetween: 20,
              pagination: {
                el: '.tutorial-pagination',
                type: 'custom',
                renderCustom: (swiper, current, total) =>
                {
                  this.tutorialButtonText = this.translate.instant(`tutorial.skip`);
                  if (current === total)
                  {
                    this.tutorialButtonText = this.translate.instant(`tutorial.end`);
                  }

                  return `<span class="currentCount">${current}</span>` +
                    ` ${this.translate.instant(`tutorial.pagination`)} ` + // TODO: this part can be translated
                    `<span class="totalCount">${total}</span>`;
                },
              },
              navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              },
            });

            this.swipe = swiper;
          });
        })
      } else
      {
        this.endTutorial();
      }
    });
  }

  ngOnDestroy()
  {
    this.tutorialId = null;
    this.tutorialType = null;
    this.showSpinner = false;
    this.swipe = null;
    this.androidBackService.onDestroyCallback();
  }

  public endTutorial()
  {
    if (this.appService.lastLocation !== "tutorial")
    {
      this.tutorialService.initializeServiceData();
    }

    this.ngZone.run(() =>
    {
      this.stateService.goRoute(this.appService.lastLocation);
    });
  }

  private setImageHeight()
  {
    const paddingTop = this.deviceService.isiPhoneX ? 40 : 20;
    const height = document.body.offsetHeight - 90 - paddingTop;
    const paddingHorizontal = 54;
    const width = document.body.offsetWidth - paddingHorizontal;
    const imageRatio = 9 / 16;

    if (imageRatio < width / height)
    {
      // 80 menu "skip tour" nad "1 of 7" height num
      return `${document.body.offsetHeight - 90 - paddingTop}px`;
    }

    return null;
  }

  private getTutorialImg(): Promise<boolean>
  {
    return new Promise((resolve, reject) =>
    {
      const cacheData = this.tutorialService.tutorialImagesCache.getValue();
      const tutorialCache = cacheData && (cacheData || []).find(item => Number(item.id) === Number(this.tutorialId));
      if (tutorialCache)
      {
        setTimeout(() => this.showSpinner = false);
        this.tutorialImages = this.formatTutorialImages(tutorialCache);
        return resolve(true);
      }

      const appVersion = (this.tutorialType === TutorialStateEnum.all && !this.tutorialId) ? getAppVersion() : null;

      return this.tutorialService.getTutorialImages(this.tutorialId, appVersion, this.prevAppVersion).subscribe(
        (tutorial: ITutorial) =>
        {
          this.showSpinner = false;
          if (tutorial)
          {
            this.tutorialImages = this.formatTutorialImages(tutorial);
            return resolve(true);
          } else
          {
            return resolve(false);
          }
        }
      );
    });
  }

  private formatTutorialImages(tutorial: ITutorial | ITutorial[]): Array<SafeResourceUrl>
  {
    let result: Array<SafeResourceUrl> = [];

    if (Array.isArray(tutorial))
    {
      tutorial.forEach((item) =>
      {
        result = result.concat(this.setBase64Images(item.appTutorialPhoto));
      });
    } else
    {
      result = this.setBase64Images(tutorial && tutorial.appTutorialPhoto);
    }

    return result;
  }

  private setBase64Images(images: ITutorialImage[]): Array<SafeResourceUrl>
  {
    const base64Images: Array<SafeResourceUrl> = [];
    (images || []).forEach((item: ITutorialImage) =>
    {
      base64Images.push(this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/svg+xml;base64,${btoa(item.image)}`));
    });
    return base64Images;
  }
}


/**
 * Tutorial list component for help center
 */
@Component({
  selector: 'tf-tutorial-list',
  templateUrl: './tutorial.list.html',
  styleUrls: ['./tutorial.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TutorialListComponent implements OnInit, AfterViewInit, OnDestroy
{
  public showSpinner: boolean = true;

  public tutorialList: ITutorial[] = [];

  constructor(
    public readonly appService: AppService,
    private readonly stateService: State.StateService,
    private readonly androidBackService: AndroidBackService,
    private readonly ngZone: NgZone,
    public readonly translate: TranslateService,
    private readonly tutorialService: TutorialService,
    public readonly deviceService: DeviceService,
  )
  {
    this.androidBackService.onShouldCheckCallback(this.leftClick.bind(this));
  }

  ngOnInit()
  {

    const cacheData = this.tutorialService.tutorialList.getValue();

    if (cacheData && cacheData.length > 0)
    {
      this.showSpinner = false;
      this.tutorialList = this.formatTutorial(cacheData);
    } else
    {
      this.tutorialService.getTutorialList().subscribe((tutorials: ITutorial[]) =>
      {
        this.showSpinner = false;
        this.tutorialList = this.formatTutorial(tutorials);
      });
    }
  }

  ngAfterViewInit() { }

  ngOnDestroy()
  {
    this.showSpinner = false;
    this.androidBackService.onDestroyCallback();
  }

  public leftClick = () =>
  {
    this.ngZone.run(() =>
    {
      this.stateService.goRoute(`schedule`);
      this.tutorialService.initializeServiceData()
    });
  }

  public viewTutorial(tutorial: ITutorial)
  {
    // TODO: _.merge() tutorial from parameter if needed
    const tutorialListData: ITutorialSlide = {
      state: TutorialStateEnum.single,
      id: tutorial.id
    };

    this.appService.lastLocation = `tutorial`;

    this.stateService.goRoute('/tutorialSlide', tutorialListData);
  }

  private formatTutorial(tutorials: ITutorial[]): Array<ITutorial>
  {
    const language = this.translate.currentLang;
    const result = [].concat(tutorials);

    result.forEach(item =>
    {
      switch (language)
      {
        case Language.es:
          item.title = item.spanishTitle;
          item.description = item.spanishDescription;
          break;
        case Language.fr:
          item.title = item.frenchTitle;
          item.description = item.frenchDescription;
          break;
        default:
          item.title = item.englishTitle;
          item.description = item.englishDescription;
          break;
      }
    });

    return result;
  }
}
