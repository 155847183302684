import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { AppService } from '../../app.service';
import { DeviceService } from 'src/app/components/service/device/device.service';
import { CommunicationType, Document, DocumentStorage } from '../stopfinder/stopfinder-models';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpEventType } from '@angular/common/http';
import { StopfinderApiService } from '../stopfinder/stopfinder-api.service';
import { StateEnum, StateService } from 'src/app/components/service/state/state.service';
import { PortalService } from '../portal/portal.service';
import { DocViewerComponent } from "../doc-viewer/doc-viewer.component";
import { RefreshSnackBar } from '../refresh/refresh-snackbar.component';

export interface IAttachments extends Document
{
  type?: CommunicationType;
}

@Component({
  selector: 'attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})

export class AttachmentsComponent implements OnInit
{
  @Input() public title: string;
  @Input() public type: string;
  @Input() public data: any[];

  @Output() public openAttachmentEvent: EventEmitter<any> = new EventEmitter<any>();

  public attachments: IAttachments[];
  public showDocView = false;
  public docViewerConfig: any = {};

  private fileCache: any = {};

  constructor(
    private readonly ngZone: NgZone,
    public readonly deviceService: DeviceService,
    private readonly domSanitizer: DomSanitizer,
    private readonly stopfinderApi: StopfinderApiService,
    private readonly stateService: StateService,
    private readonly appService: AppService,
    private readonly portalService: PortalService,
  ) { }

  ngOnInit()
  {
    if (this.type === CommunicationType.Message)
    {
      this.attachments = (this.data || []).map(md => md.document);
    } else
    {
      this.attachments = this.data;
    }
  }

  generateImageSrc(attachment: IAttachments): SafeResourceUrl
  {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(`data:${attachment.previewMimeType};base64, ${attachment.previewEncodedBytes}`);
  }

  openAttachment(attachment: IAttachments)
  {
    if (!this.checkCanPreview(attachment.mimeType))
    {
      return;
    }

    if (this.fileCache[`${attachment.filename}_${attachment.id}`])
    {
      this.openFile(attachment, this.fileCache[`${attachment.filename}_${attachment.id}`]);
    } else
    {
      this.openUploadDocLoadingIndicator();
      this.stopfinderApi.getDocumentStorage(attachment.id).subscribe((event) =>
      {
        this.openAttachmentEvent.emit(event);

        switch (event.type)
        {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break
          case HttpEventType.DownloadProgress:
            const progress = Math.round(100 * event.loaded / (attachment.size * 1.33));
            this.updateUploadProgress(progress);
            break;
          case HttpEventType.Response:
            setTimeout(() =>
            {
              this.closeUploadDocLoadingIndicator();
              this.openFile(attachment, event.body);
            }, 500);
            break;
          default:
            break;
        }
      });
    }
  }

  openDocViewer()
  {
    this.portalService.create(DocViewerComponent,
      {
        config: {
          ...this.docViewerConfig,
          closeEvent: this.closeDocViewer.bind(this),
        },
      }
    )
  }

  closeDocViewer()
  {
    this.portalService.destroy();
  }

  private openUploadDocLoadingIndicator()
  {
    this.portalService.create(RefreshSnackBar, {
      isOverlay: true,
      spinnerMode: "determinate",
      spinnerProgress: 0
    })
  }

  private updateUploadProgress(progress = 0)
  {
    this.portalService.update({
      spinnerProgress: progress
    })
  }

  private closeUploadDocLoadingIndicator()
  {
    this.portalService.destroy();
  }

  private openFile(attachment: Document, documentStorage: DocumentStorage)
  {
    if (!attachment) return;

    const mimeType = String(attachment.mimeType).toLowerCase();
    const _openDocViewer = (docSrc: string) =>
    {
      const documentStorageBytes = attachment && attachment.documentStorage[0] && attachment.documentStorage[0].encodedBytes;
      this.docViewerConfig = {
        docType: attachment.mimeType,
        docName: attachment.filename,
        docSize: attachment.displaySize,
        docBytes: (documentStorage && documentStorage.encodedBytes) || documentStorageBytes,
        docSrc,
      };

      this.openDocViewer();
      this.stateService.goState(StateEnum.OpenAttachment);
      this.stateService.closeAttachmentView = this.closeDocViewer.bind(this);
    };
    (window as any).requestFileSystem(1, 0, (fs) =>
    {
      (window as any).resolveLocalFileSystemURL(this.appService.fileInstance.dataDirectory, (dirEntry) =>
      {
        dirEntry.getFile(attachment.name, { create: true, exclusive: false }, (fileEntry) =>
        {
          fileEntry.createWriter((fileWriter) =>
          {
            fileWriter.onwriteend = () =>
            {
              const path = this.appService.fileInstance.dataDirectory + fileEntry.fullPath.replace("/", "");
              if (this.checkCanPreview(mimeType))
              {
                this.ngZone.run(() =>
                {
                  _openDocViewer(path);
                });
                return;
              }

              this.appService.fileOpenerInstance.showOpenWithDialog(path, attachment.mimeType, {
                error: (e) => { },
                success: () => { }
              });
            };
            fileWriter.onerror = (e) => { }
            const byteCharacters = atob(documentStorage.encodedBytes);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++)
            {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const dataObj = new Blob([byteArray], { type: attachment.mimeType });
            fileWriter.write(dataObj);
            this.fileCache[`${attachment.filename}_${attachment.id}`] = documentStorage;
          });
        }, (e) => { });
      }, (e) => { })
    }, (e) => { });
  }

  private checkCanPreview(mimeType: string): boolean
  {
    return mimeType.includes('image/') || mimeType.includes('application/pdf');
  }
}
