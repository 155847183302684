import { BaseVectorMapGenerator } from '../classes/basemap.base';
import { eBaseMaps, BaseMapThumbnailEnum } from '../classes/basemap.enum';

export class StreetsMap extends BaseVectorMapGenerator
{
    constructor()
    {
        super();
    }

    public get thumbnail(): string { return './assets/images/Streets.jpg'; }
    public get title(): string { return 'Streets'; }
    public get jsonUrl(): string { return './assets/vectors/streets.json'; }
    public get allowRotation(): boolean { return true; }
    public get maxZoom(): number { return 17; }
    public get maxNativeZoom(): number { return 16; }
    public get type(): eBaseMaps { return eBaseMaps.Streets; }
    public get translateKey(): BaseMapThumbnailEnum { return BaseMapThumbnailEnum.Streets; }
}
