import { Injectable } from '@angular/core';
import { StopfinderApiService } from '../shared/stopfinder/stopfinder-api.service';
import { flatMap } from 'rxjs/operators';

@Injectable()
export class ActivationService {
  public firstName: string;
  public lastName: string;
  public mobilePhone: string;
  public password: string;

  constructor(
    private readonly stopfinderApi: StopfinderApiService
  ) { }

  activate(email: string, guid: string, firstName: string, lastName: string, password: string, mobilePhoneNumber: string) {
    // api client baseuri will have already been set at this point.
    return this.stopfinderApi.activateUser({ email, guid, firstName, lastName, password, mobilePhoneNumber });
  }

  getEnrollment(guid: string, email: string) {
    return this.stopfinderApi.getEnvironmentURL(email).pipe(flatMap((response: string) => {
      this.stopfinderApi.stopFinderBaseUri = response;
      return this.stopfinderApi.getEnrollment(guid);
    }));
  }
}
