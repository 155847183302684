import * as _ from 'lodash';
import
{
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  AfterViewInit,
  OnDestroy,
  NgZone,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { ScheduleService, DateSelectionSource } from '../schedule.service';
import Swiper from 'swiper';
import { Subscription } from 'rxjs';
import { getCustomMomentTranslate } from 'src/app/shared/utils/moment-translate';
import { Language } from 'src/app/shared/utils/enum';

@Component({
  selector: 'sf-date-picker',
  templateUrl: './sf-date-picker.component.html',
  styleUrls: ['./sf-date-picker.component.scss'],
})
export class SfDatePickerComponent implements OnInit, AfterViewInit, OnDestroy
{

  @Output() passAlongCalendarEvent = new EventEmitter<any>();
  @Input() noSwiping = false;
  public weekDays = Array(7);
  private readonly selectedDateSubscription: Subscription;
  private swiper: Swiper;
  private readonly dateFormat: string = 'D-MM-YYYY';
  private readonly today: string = moment().format(this.dateFormat);
  private readonly endOfNextYear: string = moment().add(1, 'year').endOf('month').format(this.dateFormat);
  private selectedDate: Date;
  current: number;
  private prevDate;
  private lastEvent = null;

  public currentMonth;
  public fullDate;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public scheduleService: ScheduleService,
    private readonly zone: NgZone,
    public translate: TranslateService,
  )
  {
    moment.locale(this.translate.currentLang, getCustomMomentTranslate(this.translate.currentLang));
    this.selectedDateSubscription = this.scheduleService.selectedDateObservable.subscribe(dateEvent =>
    {
      if (this.swiper)
      {
        if (moment(this.prevDate).isBefore(this.scheduleService.getSelectedDate(), 'week') && dateEvent.source === DateSelectionSource.ScheduleSwiper)
        {
          this.lastEvent = dateEvent.source;
          this.swiper.slideNext(300, true);
        } else if (moment(this.prevDate).isAfter(this.scheduleService.getSelectedDate(), 'week') && dateEvent.source === DateSelectionSource.ScheduleSwiper)
        {
          this.lastEvent = dateEvent.source;
          this.swiper.slidePrev(300, true);
        }
        this.swiper.allowSlidePrev = this.allowPriorScroll(dateEvent.date);
        this.swiper.allowSlideNext = this.allowNextScroll(dateEvent.date);
      }

      if (dateEvent.source === DateSelectionSource.Calendar || dateEvent.source === DateSelectionSource.WeekPickerClick)
      {
        this.setWeekDates(dateEvent.date);
      }

      this.current = moment(dateEvent.date).day();
      this.prevDate = moment(dateEvent.date).toDate();
      this.selectedDate = dateEvent.date;

      this.validateSwipeScroll(dateEvent.date);
      this.updateTranslateDate();
    });

    this.translate.onLangChange.subscribe(() =>
    {
      moment.updateLocale(this.translate.currentLang, getCustomMomentTranslate(this.translate.currentLang));
      this.setWeekDates(this.scheduleService.getSelectedDate());
      this.updateTranslateDate();
    })
  }

  ngOnInit()
  {
    this.setWeekDates(this.scheduleService.getSelectedDate());
    this.current = moment(this.scheduleService.getSelectedDate()).day();
    this.prevDate = moment(this.scheduleService.getSelectedDate()).toDate();
  }

  ngAfterViewInit()
  {
    this.zone.runOutsideAngular(() =>
    {
      this.swiper = new Swiper('#sf-date-picker-swiper', {
        observer: true,
        direction: 'horizontal',
        slidesPerView: 1,
        spaceBetween: 0,
        initialSlide: 1,
        noSwipingClass: 'no-swiping',
        preventInteractionOnTransition: true,
        allowSlidePrev: false,
        allowSlideNext: true,
      });
      this.swiper.on("slideNextTransitionEnd", this.changeToNextWeek.bind(this));
      this.swiper.on("slidePrevTransitionEnd", this.changeToPrevWeek.bind(this));
      this.swiper.allowSlidePrev = this.allowPriorScroll(this.selectedDate);
      this.swiper.allowSlideNext = this.allowNextScroll(this.selectedDate);
    });

    document.body.style.backgroundColor = "#fff";
    window.scrollTo(0, 0);
  }

  ngOnDestroy()
  {
    this.swiper.destroy(true, true);
    this.selectedDateSubscription.unsubscribe();
  }

  changeToNextWeek()
  {
    this.zone.run(() =>
    {
      if (this.lastEvent !== DateSelectionSource.ScheduleSwiper)
      {
        this.scheduleService.addWeeksToSelectedDate(1, DateSelectionSource.WeekPickerSwiper);
      }
      if (this.swiper.activeIndex !== 1)
      {
        this.swiper.slideTo(1, 0, false);
        this.setWeekDates(this.scheduleService.getSelectedDate());
      }
      this.lastEvent = DateSelectionSource.Unknown;
    });
  }

  changeToPrevWeek()
  {
    this.zone.run(() =>
    {
      if (this.lastEvent !== DateSelectionSource.ScheduleSwiper)
      {
        this.scheduleService.addWeeksToSelectedDate(-1, DateSelectionSource.WeekPickerSwiper);
      }
      if (this.swiper.activeIndex !== 1)
      {
        this.swiper.slideTo(1, 0, false);
        this.setWeekDates(this.scheduleService.getSelectedDate());
      }
      this.lastEvent = DateSelectionSource.Unknown;
    });
  }

  changeDate(date: Date)
  {
    const d: Date = date || new Date();
    if (moment(d, this.dateFormat)
      .isBetween(
        moment(this.today, this.dateFormat).subtract(1, 'day'),
        moment(this.endOfNextYear, this.dateFormat).add(1, 'day')
      )
    )
    {
      this.scheduleService.setSelectedDate(d, DateSelectionSource.WeekPickerClick);
    }
  }

  isDisabledDate(date: Date): boolean
  {
    return moment(date, this.dateFormat).isBefore(moment(this.today, this.dateFormat)) ||
      moment(date, this.dateFormat).isAfter(moment(this.endOfNextYear, this.dateFormat));
  }

  /**
   * Function to validate scrolling calendar week is same week or later week.
   * @param date - Mandatory param
   * @return true   Schedule header calendar can scroll to prior week
   * @return false  Schedule header calendar cannot scroll to prior week
   */
  allowPriorScroll(date: Date): boolean
  {
    return moment(date, this.dateFormat).isAfter(moment(this.today, this.dateFormat), 'week');
  }

  /**
   * Public function to validate scrolling calendar week should before end of next year's week.
   * @param date  - Mandatory param
   * @return true   Schedule header calendar can scroll next week
   * @return false  Schedule header calendar cannot scroll next week
   */
  allowNextScroll(date: Date): boolean
  {
    return moment(date, this.dateFormat).isBefore(moment(this.endOfNextYear, this.dateFormat), 'week');
  }

  /**
   * Public function to swipe to default threshold date.
   * If any invalid date provided which:
   *    is before today, both calendar date and student card will reset to today data.
   *    is after end date of next year, both calendar date and student card will reset to end date of next year.
   * @param date Mandatory. Date format.
   */
  validateSwipeScroll(date: Date)
  {
    // date that applied
    const scrollDate: moment.Moment = moment(date, this.dateFormat);
    // threshold date for beginning date: set as today
    const thresholdStartDate: moment.Moment = moment(this.today, this.dateFormat);
    // threshold date for ending date: set as end date of next year
    const thresholdEndDate: moment.Moment = moment(this.endOfNextYear, this.dateFormat);
    let defaultDate: moment.Moment = null;

    scrollDate.isBefore(thresholdStartDate) && (defaultDate = thresholdStartDate);
    scrollDate.isAfter(thresholdEndDate) && (defaultDate = thresholdEndDate);

    defaultDate && this.changeDate(defaultDate.toDate());
    defaultDate && this.setWeekDates(defaultDate.toDate());
  }

  openCalendar($event)
  {
    this.passAlongCalendarEvent.emit($event);
  }

  setWeekDates(inputDate)
  {
    const weeks = [];
    for (let j = -1; j < 2; j++)
    {
      const weekDays = [];
      const date = moment(inputDate).add(j * 7, 'days').toDate();
      const firstDayOfWeek = moment(date).day(0).toDate();
      for (let i = 0; i < this.weekDays.length; i++)
      {
        const d = moment(firstDayOfWeek).add(i, 'days').toDate();
        const current = moment(d).day();
        const language = _.isEqual(this.translate.currentLang, 'es') ? 'es-us' : this.translate.currentLang;

        weekDays[i] = {
          day: d.toLocaleDateString(language, { weekday: 'narrow' }),
          date: d.toLocaleDateString(this.translate.currentLang, { day: 'numeric' }),
          current: current,
          jsDate: d
        };
      }
      weeks.push(weekDays);
    }
    this.scheduleService.weeksCollection = weeks;
  }

  private updateTranslateDate()
  {
    if (this.translate.currentLang === Language.es || this.translate.currentLang === Language.fr)
    {
      let fullDate = moment(this.scheduleService.getSelectedDate()).locale(this.translate.currentLang).format('dddd,D MMMM,YYYY');
      const fullDateArr = fullDate.split(",");
      fullDate = `${fullDateArr[0]} ${fullDateArr[1].toLowerCase()}, ${fullDateArr[2]}`;
      this.fullDate = fullDate;
    } else
    {
      this.fullDate = moment(this.scheduleService.getSelectedDate()).locale(this.translate.currentLang).format('dddd MMMM D, YYYY');
    }

    this.currentMonth = moment(this.scheduleService.getSelectedDate()).locale(this.translate.currentLang).format('MMMM YYYY');
  }
}
