import { Injectable } from "@angular/core";
import { Subscription, Subscriber } from "../../shared/stopfinder/stopfinder-models";
import { BehaviorSubject, Observable } from "rxjs";
import { StopfinderApiService } from "../../shared/stopfinder/stopfinder-api.service";
import { map } from "rxjs/operators";
import { ChangePasswordForm } from "../../shared/stopfinder/models/change-password-form";
import * as moment from 'moment';

@Injectable()
export class ManageSubscriptionService
{

  private subscriptions: Array<Subscription> = new Array<Subscription>();
  public hasOwnedSubscriptions: boolean = false;
  public hasSharedSubscriptions: boolean = false;
  private subscriptionSubject: BehaviorSubject<Array<Subscription>> = new BehaviorSubject<Array<Subscription>>([]);
  public subscriptionObservable: Observable<Array<Subscription>> = this.subscriptionSubject.asObservable();
  public sharedSubscriptionObservable: Observable<Array<Subscription>> = this.subscriptionObservable.pipe(map(subscriptions =>
  {
    let sharedSubscriptions =  subscriptions.filter(subscription => subscription.ownerId != subscription.subscriberId);
    this.hasSharedSubscriptions = sharedSubscriptions.length !== 0;
    return sharedSubscriptions;
  }));
  public sortedSubscriptionsObservable: Observable<Array<Subscription>> = this.subscriptionObservable.pipe(map(subscriptions =>
  {
    return this.alphaSortSubscriptions(subscriptions);
  }));
  public sortedSharedSubscriptionObservable: Observable<Array<Subscription>> = this.sharedSubscriptionObservable.pipe(map(subscriptions =>
  {
    return this.alphaSortSubscriptions(subscriptions);
  }));

  // subscription shared with owner
  private subscriptionsShareWithOwner: Array<Subscription> = new Array<Subscription>();
  private subscriptionhareWithOwnerSubject: BehaviorSubject<Array<Subscription>> = new BehaviorSubject<Array<Subscription>>([]);
  public subscriptionsShareWithOwnerObservable = this.subscriptionhareWithOwnerSubject.asObservable();
  public sortedSubscriptionShareWithOwnerObservable: Observable<Array<Subscription>> = this.subscriptionsShareWithOwnerObservable.pipe(map(subscriptions =>
  {
    return this.alphaSortSubscriptionsSharedOwner(subscriptions);
  }));

  constructor(private readonly stopfinder: StopfinderApiService)
  {

  }

  private alphaSortSubscriptions(subscriptions: Array<Subscription>): Array<Subscription>
  {
    return subscriptions.sort((a, b) =>
    {
      const nameA = a.subscriberLastName && a.subscriberFirstName ? a.subscriberFirstName.toLowerCase().trim() + a.subscriberLastName.toLowerCase().trim() : '';
      const nameB = b.subscriberLastName && b.subscriberFirstName ? b.subscriberFirstName.toLowerCase().trim() + b.subscriberLastName.toLowerCase().trim() : '';
      const riderNameA = a.riderLastName && a.riderFirstName ? a.riderFirstName.toLowerCase().trim() + a.riderLastName.toLowerCase().trim() : '';
      const riderNameB = b.riderLastName && b.riderFirstName ? b.riderFirstName.toLowerCase().trim() + b.riderLastName.toLowerCase().trim() : '';
      const dateA = (a.effectiveStart ? a.effectiveStart : '0000-00-00T00:00:00') + (a.effectiveEnd ? a.effectiveEnd : '9999-99-99T00:00:00');
      const dateB = (b.effectiveStart ? b.effectiveStart : '0000-00-00T00:00:00') + (b.effectiveEnd ? b.effectiveEnd : '9999-99-99T00:00:00');
      return nameA < nameB ? -1 : (nameA > nameB ? 1 : (riderNameA < riderNameB ? -1 : (riderNameA > riderNameB ? 1 : (dateA < dateB ? -1 : (dateA > dateB ? 1 : 0)))));
    });
  }

  private alphaSortSubscriptionsSharedOwner(subscriptions: Array<Subscription>): Array<Subscription>
  {
    return subscriptions.sort((a, b) =>
    {
      const nameA = a.riderLastName && a.riderFirstName ? a.riderFirstName.toLowerCase().trim() + a.riderLastName.toLowerCase().trim() : '';
      const nameB = b.riderLastName && b.riderFirstName ? b.riderFirstName.toLowerCase().trim() + b.riderLastName.toLowerCase().trim() : '';
      const dateA = (a.effectiveStart ? a.effectiveStart : '0000-00-00T00:00:00') + (a.effectiveEnd ? a.effectiveEnd : '9999-99-99T00:00:00');
      const dateB = (b.effectiveStart ? b.effectiveStart : '0000-00-00T00:00:00') + (b.effectiveEnd ? b.effectiveEnd : '9999-99-99T00:00:00');
      return nameA < nameB ? -1 : (nameA > nameB ? 1 : (dateA < dateB ? -1 : (dateA > dateB ? 1 : 0)));
    });
  }

  getSubscriptions()
  {
    this.stopfinder.getSubscriptionsByOwner().subscribe((subs) =>
    {
      this.subscriptions = subs;
      this.subscriptionSubject.next(this.subscriptions);
    });
  }

  getOwnedSubscriptionsStatus()
  {
    this.stopfinder.hasOwnedSubscriptions().subscribe((subs) =>
    {
      this.hasOwnedSubscriptions = subs;
    });
  }

  getSubscriptionsShareWithOwner()
  {
    this.stopfinder.getSubscriptionsSharedWithOwner().subscribe((subs) =>
    {
      this.subscriptionsShareWithOwner = subs;
      this.subscriptionhareWithOwnerSubject.next(this.subscriptionsShareWithOwner);
    });
  }

  patchAccess(id: number, document: any)
  {
    this.subscriptions.forEach((sub) =>
    {
      if (sub.subscriptionRangeId === id)
      {
        sub.active = JSON.parse(document[0].value);
      }
    });
    this.subscriptionSubject.next(this.subscriptions);
    this.stopfinder.patchSubscriptionRange(id, document).subscribe((sub: Subscription) =>
    {
      if (sub && sub.subscriptionRangeId)
      {
        this.getSubscriptions();
      }
    });
  }

  buildDocument(original, edited)
  {
    const document: Array<any> = new Array<any>();
    for (const property in original)
    {
      if (original.hasOwnProperty(property) && edited.hasOwnProperty(property) && property.toLowerCase() !== 'id')
      {
        if ((property === 'effectiveStart' || property === 'effectiveEnd') && original[property] !== null)
        {
          original[property] = moment(new Date(original[property])).format('M/D/YYYY');
        }
        if (original[property] != edited[property])
        {
          const value = edited[property];
          document.push({
            op: 'replace',
            path: property,
            value: value
          });
        }
      }
    }
    return document;
  }

  patchFromEditDialog(subscription: Subscription): any
  {
    const originalSub = this.subscriptions.find((sub) =>
    {
      return subscription.id === sub.id && subscription.subscriptionRangeId === sub.subscriptionRangeId;
    });
    const document = this.buildDocument(originalSub, subscription);
    const subscriberDocument = new Array<any>();
    const subscriberRangeDocument = new Array<any>();
    const promiseList = [];

    document.forEach(element =>
    {
      if (element.path === 'subscriberName')
      {
        subscriberDocument.push({ path: 'firstName', op: 'replace', value: subscription.subscriberFirstName });
        subscriberDocument.push({ path: 'lastName', op: 'replace', value: subscription.subscriberLastName });
      } else if (element.path === 'subscriberPhoneNumber')
      {
        subscriberDocument.push({ path: 'mobilePhoneNumber', op: 'replace', value: element.value });
      } else if (element.path === 'effectiveStart' || element.path === 'effectiveEnd' || element.path === 'comments' || element.path === 'active')
      {
        subscriberRangeDocument.push(element);
      }
    });

    if (subscriberDocument.length > 0)
    {
      promiseList.push(this.stopfinder.patchSubscriber(subscription.subscriberId, subscriberDocument).toPromise());
    }

    if (subscriberRangeDocument.length > 0)
    {
      promiseList.push(this.stopfinder.patchSubscriptionRangeAsync(subscription.subscriptionRangeId, subscriberRangeDocument).toPromise());
    }
    if (promiseList.length > 0)
    {
      return Promise.all(promiseList).then(() =>
      {
        return this.stopfinder.sendSubscriptionChangedEmail(subscription.id, subscription.subscriptionRangeId).toPromise();
      });
    }
    else
    {
      return Promise.resolve(true);
    }
  }

  patchFromProfile(originSub: Subscriber, subscriber: Subscriber)
  {
    const document = this.buildDocument(originSub, subscriber);
    return this.stopfinder.patchSubscriber(subscriber.id, document);
  }

  changePassword(changePasswordForm: ChangePasswordForm): Observable<any>
  {
    const subject = new BehaviorSubject<any>(null);
    this.stopfinder.changePassword(changePasswordForm).subscribe((success) =>
    {
      subject.next(true);
    },
      (error) =>
      {
        subject.next(false);
      })
    return subject.asObservable();
  }

  mapSubscriptionToSubscriber(subscription: Subscription)
  {
    return {
      id: subscription.id,
      firstName: subscription.subscriberFirstName,
      lastName: subscription.subscriberLastName,
      email: subscription.subscriberEmail,
      mobilePhoneNumber: subscription.subscriberPhoneNumber,
    } as Subscriber
  }
}
