export interface ITutorial
{
  id: number,
  type: string,
  title?: string,
  description?: string,
  englishTitle: string,
  englishDescription: string,
  frenchTitle: string,
  frenchDescription: string,
  spanishTitle: string,
  spanishDescription: string,
  sequence: number,
  appVersion: number,
  appTutorialPhoto: ITutorialImage[],
}

export interface ITutorialImage
{
  id: number,
  appTutorialId: number,
  image: string,
  language?: string,
}


export enum TutorialStateEnum
{
  all = 'ALL',
  single = 'SINGLE'
}

export interface ITutorialAccess
{
  disable: boolean;
}

export interface ITutorialSlide
{
  state: TutorialStateEnum;
  id?: number;
}
