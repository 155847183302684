import { OnInit, OnDestroy, Component, Inject } from '@angular/core';
import { MessagesService } from '../messages.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { Communication } from '../../shared/stopfinder/stopfinder-models';

@Component({
  selector: 'sf-view-message',
  templateUrl: './view-message.component.html',
  styleUrls: ['./view-message.component.scss']
})
export class ViewMessageComponent implements OnInit, OnDestroy {

  constructor(
    private readonly messagesService: MessagesService,
    public dialogRef: MatDialogRef<ViewMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public message: Communication) {

    }

  ngOnInit() { }

  ngOnDestroy() {}

  back() {
    this.dialogRef.close();
  }

}

