import * as _ from 'lodash';
import { Injectable, NgZone } from '@angular/core';
import { StudentSchedule, TripSchedule, Subscriber, IAttendance } from 'src/app/shared/stopfinder/stopfinder-models';
import { NativeMapView } from 'src/app/tf-map/core/native-mapview';
import { MapLayerName } from 'src/app/tf-map/themes/enums/enum.map-layer';
import { SymbolType } from 'src/app/tf-map/themes/enums/enum.symbol-type';
import { MatOption } from '@angular/material/core';
import { TfMapFactory } from '../../../tf-map/core/tf-map.factory';
import { TfMap } from '../../../tf-map/core/classes/tf-map.class';
import { IMapHeaderSelection } from 'src/app/components/interface/map-header-selection.interface';
import { IGeoData } from 'src/app/map/geo/action-sheet/actions/geo.interface';
import { StopfinderApiService } from 'src/app/shared/stopfinder/stopfinder-api.service';
import { SCHENECTADY_MAP_CENTER_X, SCHENECTADY_MAP_CENTER_Y } from 'src/app/shared/utils/constant';
import { AppService } from 'src/app/app.service';
import { TooltipService } from '../tooltip/tooltip.service';
import { PathLineService } from '../path-line/path-line.service';
import { StateService } from '../state/state.service';
import { ITooltip } from '../../../shared/tooltip/tooltip.component';
import { MatSelect } from '@angular/material';
import { ScheduleService } from 'src/app/schedule/schedule.service';
import { MapGeoAlertService, IGeoAlert } from './map-geoalert.service';
import { TFMapType } from 'src/app/shared/utils/enum';
import { MapAttendanceService } from './map-attendance.service';
import { MapVehicleService } from './map-vehicle.service';
import { GraphicNameEnum, IBusParameter, IGraphic, ILayerState, IPoint, IPosition } from 'src/app/shared/stopfinder/models/map';
import { MapGraphicsService } from './map-graphics.service';
import { DeviceService } from '../device/device.service';
import { MapCoreService } from './map-core.service';
import { ETAAlertService } from '../../../shared/services/eta-alert.service';
import { switchMap, take } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
  deps: [TooltipService],
})
export class MapService
{
  public hideGeoAlertMap: boolean = false;
  public attendanceMapHeaderTitle: string = "";

  public scheduledTrips: IMapHeaderSelection = {
    defaultValue: GraphicNameEnum.pickupGraphic,
    options: []
  };
  public layerStates: ILayerState = {};
  public tripStates: { [name: string]: TripSchedule } = {};
  public currentLayerStates: ILayerState;
  public shouldDisplayGeoAlerts = false;
  public shouldTriggerOnBackHook = false;
  public shouldDisplayEtaAlerts = false;
  public shouldHideMapTools = false;
  public tooltipInstance = null;
  public mapToolState = true;
  public isGeoAlertEditing = false;
  public compassPosition: IPosition = null;
  public isVehicleDisplayEnabled = false;
  public showBellMenu = false;

  private _headerDropdown: MatSelect;
  private _studentSchedule: StudentSchedule = null;
  private _mapId = 'sfMap';
  private _geoData: IGeoData = null;
  private _pickupTrip: TripSchedule = null;
  private _dropOffTrip: TripSchedule = null;
  private _tripId: number = null;
  private _riderId: number = null;
  private _tripTitleName: string;
  private _selectedTrip: TripSchedule = null;
  private _shouldDisplayTripAlias: boolean = false;
  private currentTripState: GraphicNameEnum;
  private tooltipListenerBind = false;
  private zoomToDefaultIsComplete = true;
  // stop graphic validation scope
  private stopGraphicScope = [];
  private mapCenter: IPoint;

  constructor(
    private tooltipService: TooltipService,
    private readonly apiService: StopfinderApiService,
    private readonly attendanceService: MapAttendanceService,
    private readonly deviceService: DeviceService,
    private readonly geoAlertService: MapGeoAlertService,
    private readonly graphicsService: MapGraphicsService,
    private readonly mapCoreService: MapCoreService,
    private readonly ngZone: NgZone,
    private readonly scheduleService: ScheduleService,
    private readonly stateService: StateService,
    private readonly vehicleService: MapVehicleService,
    private readonly _etaAlertService: ETAAlertService,
  )
  {
    this.graphicsService.generateGraphicsBasedOnEnum();
    this.generateLayerStatesBasedOnEnum();
  }

  set studentSchedule(newValue: StudentSchedule)
  {
    this.setterValidation(newValue) && (this._studentSchedule = newValue);
    this.scheduleService.studentSchedule = this._studentSchedule;
  }

  get studentSchedule(): StudentSchedule
  {
    return this._studentSchedule || null;
  }

  set mapId(newValue: string)
  {
    this.setterValidation(newValue) && (this._mapId = newValue);
  }

  get mapId(): string
  {
    return this._mapId || '';
  }

  set geoData(value: IGeoData)
  {
    this._geoData = value;
  }

  get geoData()
  {
    return this._geoData || null;
  }

  get pickupTrip(): TripSchedule
  {
    return this._pickupTrip;
  }

  get dropOffTrip(): TripSchedule
  {
    return this._dropOffTrip;
  }

  set selectedTrip(value: any)
  {
    this.currentTripState = value;
    this._selectedTrip = this.tripStates[value];
    this.scheduleService.selectedTrip = this._selectedTrip;
  }

  get selectedTrip()
  {
    return this._selectedTrip
  }

  set tripTitleName(value: string)
  {
    this._tripTitleName = value;
  }

  get tripTitleName()
  {
    return this._tripTitleName;
  }

  set tripId(value: number)
  {
    this._tripId = value;
  }

  get tripId()
  {
    return (this._selectedTrip && this._selectedTrip.id) || this._tripId;
  }

  set riderId(value: number)
  {
    this._riderId = value;
  }

  get riderId()
  {
    return this._riderId;
  }

  set headerDropdown(dropdown: MatSelect)
  {
    this._headerDropdown = dropdown;
  }

  get headerDropdown()
  {
    return this._headerDropdown;
  }

  get shouldDisplayTripAlias()
  {
    return this._shouldDisplayTripAlias;
  }

  set shouldDisplayTripAlias(setting: boolean)
  {
    this._shouldDisplayTripAlias = setting;
  }

  /**
   * Public function to validate nativeEsriMapPlugin is launched or not.
   * Exception control for any JavaScript block
   */
  public isMapPluginReady(): boolean
  {
    return !!NativeMapView &&
      !!NativeMapView.nativeEsriMapPlugin &&
      !!NativeMapView.nativeEsriMapPlugin.addGraphics &&
      !!NativeMapView.nativeEsriMapPlugin.turnOnGeoAlertMode &&
      !!NativeMapView.nativeEsriMapPlugin.turnOffGeoAlertMode;
  }

  /**
   * Public function for outside control to switch different type of layers.
   * Layers will switch to default states without providing any states parameters.
   * @param manuallyControl Optional. Use initial layer state by default
   */
  public async switchLayer(manuallyControl: ILayerState, callback?: Function)
  {
    _.forIn(manuallyControl, (layerState: boolean, layerName: GraphicNameEnum) =>
    {
      // should only trigger when layerState different
      if (!_.isEqual(layerState, _.get(this.layerStates, layerName)))
      {
        // get layerState false: command to clear layer
        // get layerState true: command to add layer
        this.autoApplyLayer(layerName, layerState);
      }
    });

    // reset the student stop graphic
    this.graphicsService.studentStopGraphic = [];

    // reset trip id
    const currentTrip: TripSchedule = this.tripStates[this.scheduledTrips.defaultValue];
    // current trip could be several situations
    // this.scheduledTrips.defaultValue could be TripSchedule or Student Graphic
    if (currentTrip)
    {
      this.tripId = currentTrip.id;
      this.tripTitleName = this.getTripName(currentTrip, this.shouldDisplayTripAlias);

      // set the current trip stop graphic
      if (!this.studentSchedule.displayOtherStop)
      {
        const tripEnum = currentTrip.isTransfer
          ? GraphicNameEnum[currentTrip.toSchool ? "pickupTransferGraphic" : "dropOffTransferGraphic"]
          : GraphicNameEnum[currentTrip.toSchool ? "pickupGraphic" : "dropOffGraphic"];
        const point: IPoint = {
          longitude: currentTrip[currentTrip.toSchool ? "pickUpStopXCoord" : "dropOffStopXCoord"] || null,
          latitude: currentTrip[currentTrip.toSchool ? "pickUpStopYCoord" : "dropOffStopYCoord"] || null
        };
        this.graphicsService.studentStopGraphic = [this.graphicsService.createStopGraphic(this.mapId, tripEnum, currentTrip, point)];
      }
    }
    else
    {
      this.tripId = null;
      this.tripTitleName = this.scheduleService.truncateStudentName(this.studentSchedule.firstName, this.studentSchedule.lastName);
    }

    // merge manual state to service state
    _.merge(this.layerStates, manuallyControl);

    if (this.scheduleService.isStopfinderV2(this.studentSchedule))
    {
      // add callback when switch success
      typeof callback === "function" && callback();
    }

    await this.updateEnableGeoAlertsStatus(this.studentSchedule);

    this.layerChangeImpactedEvents();
  }

  public turnOnGeoMode(currentGeoAlertId = -1, dataCallback: Function, callback?: Function)
  {
    const existedGeoData: IGeoData = dataCallback();

    if (existedGeoData)
    {
      this.deviceService.isiOS ? this.turnOnGeoModeForIOSWhenExisting(currentGeoAlertId, existedGeoData, callback)
        : this.turnOnGeoModeForAndroidWhenExisting(existedGeoData, callback);
    }
    else
    {
      this.deviceService.isiOS ? this.turnOnGeoModeForIOSWhenNotExisting(currentGeoAlertId)
        : this.turnOnGeoModeForAndroidWhenNotExisting();
    }
  }

  public setViewSize(left: number, top: number, width: number, height: number)
  {
    NativeMapView.nativeEsriMapPlugin.setViewSize(
      this.mapId,
      left,
      top,
      width,
      height,
      false,
      '',
      () =>
      {
        this.RefreshMapCenter();
      },
      () => { });

    // set the android touch event area, must include the header height
    this.mapCoreService.setHorizontalSplitSize(height + top, false);
  }

  public resetViewSize(width: number, height: number): Promise<boolean>
  {
    return new Promise((resolve, reject) =>
    {
      NativeMapView.nativeEsriMapPlugin.setViewSize(
        this.mapId,
        0,
        // in Android device, if the map is full screen, keyboard will push the map up, set the top to 1px to avoid this.
        this.deviceService.isiOS ? 0 : 1,
        width,
        height,
        false,
        '',
        () =>
        {
          this.RefreshMapCenter().then(() =>
          {
            resolve(true);
          });
        },
        () => { });

      this.mapCoreService.setHorizontalSplitSize(height, false);
    });
  }

  public RefreshMapCenter(): Promise<boolean>
  {
    return new Promise(async (resolve, reject) =>
    {
      const center: IPoint = await this.mapCoreService.getMapCenter(this.mapId);
      await this.mapCoreService.centerAt(this.mapId, center.longitude, center.latitude, -1);
      resolve(true);
    });
  }

  public async turnOffGeoMode()
  {
    return new Promise(async (resolve) =>
    {
      await this.geoAlertService.stopEditing(this.mapId);
      !this.deviceService.isiOS && await this.geoAlertService.hide(this.mapId);

      // should disable action mode
      this.isGeoAlertEditing = false;
      this.shouldHideMapTools = false;
      resolve(true);
    });
  }

  public async addGeoAlertPolygon(geoAlert: IGeoAlert)
  {
    const mapId = this.mapId;
    this.deviceService.isiOS ? await this.geoAlertService.create(mapId, geoAlert)
      : await this.geoAlertService.showInAndroidCenter(mapId, geoAlert);
  }

  public resetGeoModel(): void
  {
    this.geoData.distance = this.geoAlertService.getDefaultDistance();
    this.geoData.enterAlert = true;
    this.geoData.exitAlert = true;
  }

  public turnOnGeoAddMode = () =>
  {
    this.ngZone.run(() =>
    {
      this.isGeoAlertEditing = true;
      this.shouldHideMapTools = true;
    });
  }

  public turnOffGeoAddMode = () =>
  {
    this.ngZone.run(() =>
    {
      this.isGeoAlertEditing = false;
      this.shouldHideMapTools = false;
    });
  }

  public async hideGeoAlert(callback: Function)
  {
    !this.deviceService.isiOS && await this.geoAlertService.hide(this.mapId);
    callback && callback();
  }

  public async setGeoAlertCenter(geoAlertId = -1)
  {
    const center: IPoint = this.deviceService.isiOS ? await this.geoAlertService.getCenter(this.mapId, geoAlertId)
      : await this.mapCoreService.getMapCenter(this.mapId);
    this.geoData.xcoord = center.longitude;
    this.geoData.ycoord = center.latitude;
  }

  public async displayAttendanceMap(studentSchedule: StudentSchedule, appService: AppService, attendance: IAttendance, isFromNotification: boolean = false)
  {
    const { firstName, lastName } = studentSchedule;
    this.attendanceMapHeaderTitle = `${firstName} ${lastName}`;
    await this._resetMapToBaseState(isFromNotification, appService);
    this.hideGeoAlertMap = true;

    isFromNotification && appService.showMap && appService.setMapVisibility(false, null, null, false, true);
    this.stateService.setMapState(TFMapType.AttendanceMap);
    const isOpenedFromBackgroundNotification = false;
    this.graphicsService.initAttendanceMapGraphics(this.mapId, studentSchedule, attendance);

    await this.drawAttendanceMap();

    await this.zoomToDefault(true);

    if (!this.tooltipListenerBind)
    {
      this.graphicEventsListener();
    }

    let center: IPoint = await this.mapCoreService.getMapCenter(this.mapId);
    this._setMapCenter(center.longitude, center.latitude);
    this.openMap(appService, isOpenedFromBackgroundNotification);
  }

  public async displayGeoAlertMap(
    studentSchedule: StudentSchedule,
    appService: AppService,
    pathLineService: PathLineService,
    isOpenedFromNotification: boolean = false,
    isOpenedFromBackgroundNotification: boolean = false,
    tripId: number = null,
    toSchool: boolean = null
  )
  {
    if (!studentSchedule)
    {
      return;
    }

    this.stateService.setMapState(TFMapType.GeoAlertMap);

    if (this.selectedTrip && this.selectedTrip.lastPointStale)
    {
      this.selectedTrip.lastPointStale = false; // reset when open fresh map for the trip
    }

    await this._resetMapToBaseState(isOpenedFromNotification || isOpenedFromBackgroundNotification, appService);

    this.setMapCenter(studentSchedule);
    // first open the map
    await this.openMap(appService, isOpenedFromBackgroundNotification);
    // extract trip dropdown after clean all data
    // trip dropdown will ignore @param schedule trips since @param could be a specific trip via notification
    this.extractTripDropdown(studentSchedule, tripId, toSchool);
    // update the new student schedule
    this.studentSchedule = await this.scheduleService.checkSchedule(studentSchedule, isOpenedFromBackgroundNotification);
    const tripSchedule = tripId ? this.studentSchedule.trips.find(t => t.id && t.id === tripId) : this.studentSchedule.trips[0];
    const isTripActive = tripSchedule && this.scheduleService.isTripRunning(this.studentSchedule, tripSchedule);
    this.isVehicleDisplayEnabled = isTripActive && this.studentSchedule.displayVehicleOnMap;
    // extract trip
    this.extractTrips();
    await this.updateEnableGeoAlertsStatus(studentSchedule);

    if (tripId != null && this.tripId == null)
    {
      this.tripId = tripId;
    }
    // execute tripPathAndGeoAlertHandler after extractTrips success;
    this.tripPathAndGeoAlertHandler(this.studentSchedule, pathLineService, isOpenedFromNotification, true);

    if (!this.tooltipListenerBind)
    {
      this.graphicEventsListener();
    }
  }

  public async tripPathAndGeoAlertHandler(
    studentSchedule: StudentSchedule,
    pathLineService: PathLineService,
    isOpenedFromNotification: boolean,
    drawGeoAlerts: boolean)
  {
    if (!studentSchedule || !studentSchedule.dataSourceId || !this.tripId)
    {
      this.zoomToSchoolAndStudentStopMBR();
      this.fetchAndZoomToGeoAlerts(studentSchedule.riderId);

      if (!this.geoAlertService.disable && drawGeoAlerts)
      {
        const subscriber: Subscriber = this.stateService.subscriber || await this.apiService.getCurrentSubscriber().toPromise();
        this.initialGeoData(studentSchedule, subscriber);
      }
      return;
    }

    if (this.selectedTrip === undefined)
    {
      this.updateSelectedTrip();
    }

    const enableDisplayTripPath = studentSchedule.displayTripPath;
    const displayTripPathAndStop = enableDisplayTripPath && !isOpenedFromNotification;
    if (!this.geoAlertService.disable && drawGeoAlerts)
    {
      const studentRiderId = studentSchedule.riderId;
      this.riderId = studentRiderId;
      this.fetchAndZoomToGeoAlerts(studentRiderId, !displayTripPathAndStop).then(() => null);
      // should get subscriber Id first
      // this part can be optimized, to check if current data exist then no need to call api request again
      const subscriber: Subscriber = this.stateService.subscriber || await this.apiService.getCurrentSubscriber().toPromise();
      this.initialGeoData(studentSchedule, subscriber);
    }

    if (displayTripPathAndStop)
    {
      // init trip path and stop base or settings
      pathLineService.initTripPathAndStop(true);
    }
    else
    {
      this.zoomToSchoolAndStudentStopMBR();
    }
  }

  public busMovingTrackHandler(studentSchedule: StudentSchedule, zoomToBus: boolean, appService: AppService)
  {
    if (!studentSchedule.displayVehicleOnMap)
    {
      return;
    }

    const param: IBusParameter = {
      mapId: this.mapId,
      tripId: this.tripId,
      studentSchedule: studentSchedule,
      selectedTrip: this.selectedTrip,
      appService: appService
    };

    this.vehicleService.startMonitoringBusLocation(param).subscribe(() =>
    {
      if (!MapVehicleService.alreadyCenterBus && zoomToBus)
      {
        this.centerToBus(); // Center the map at the 1st bus location
      }
    });
  }

  public fetchAndZoomToGeoAlerts(riderId: number, doZoom = true, cancelEditMode = false): Promise<boolean>
  {
    return new Promise<boolean>(async (resolve) =>
    {
      await this.getGeoAlerts(riderId, doZoom, cancelEditMode);
      this.zoomToDefault(doZoom);
      resolve(true);
    });
  }

  public async clearGeoAlertMap()
  {
    await Promise.all([
      this.graphicsService.clearTripPath(this.mapId),
      this.graphicsService.clearStudentStop(this.mapId)
    ]);
    this.vehicleService.turnOffVehicleLocationDisplay(this.mapId).subscribe();
    this.vehicleService.clearCenterToBus();
  }

  public async displayGeoAlertMapOnClickNotification(
    studentSchedule: StudentSchedule,
    appService: AppService,
    pathLineService: PathLineService,
    zoomToBus: boolean = false,
    tripId: number,
    toSchool: boolean = null,
    isOpenedFromNotification: boolean = true,
    isOpenedFromBackgroundNotification: boolean = false,
  )
  {
    if (!studentSchedule)
    {
      return;
    }
    // first close the map
    appService.setMapVisibility(false);
    this.displayGeoAlertMap(studentSchedule, appService, pathLineService, isOpenedFromNotification, isOpenedFromBackgroundNotification, tripId, toSchool).then(() =>
    {
      if (tripId)
      {
        this.switchTrip(tripId);
        if (studentSchedule.dataSourceId && isOpenedFromNotification)
        {
          studentSchedule.displayTripPath && pathLineService.initTripPathAndStop(zoomToBus);
        }
        let subscriber;
        if (this.stateService.subscriber)
        {
          subscriber = of(this.stateService.subscriber);
        } else
        {
          subscriber = this.apiService.getCurrentSubscriber();
        }
        subscriber.pipe(take(1), switchMap((val: Subscriber) =>
          this._etaAlertService.fetchETAAlerts(val.id, this.studentSchedule.riderId)
        )).subscribe();

        this.busMovingTrackHandler(studentSchedule, zoomToBus, appService);
        this.drawGeoAlertsMap();
      }
    });
  }

  public async zoomToDefault(enforceZoom = true, ignoreAlreadyCenterBus = false)
  {
    this.zoomToDefaultIsComplete = false;
    this.zoomToDefaultIsComplete = await this.zoomTo(enforceZoom, ignoreAlreadyCenterBus);
  }

  public refreshStudentScheduleAndTrip(studentSchedule: StudentSchedule): Promise<StudentSchedule>
  {
    return new Promise(async (resolve, reject) =>
    {
      if (this.currentTripState === GraphicNameEnum.studentGraphic)
      {
        return resolve(studentSchedule);
      }
      const _studentSchedule = await this.scheduleService.checkSchedule(studentSchedule);
      if (!_studentSchedule)
      {
        return reject(false);
      }
      this.studentSchedule = _studentSchedule;
      const isTripActive = this.selectedTrip && this.scheduleService.isTripRunning(studentSchedule, this.selectedTrip);
      this.isVehicleDisplayEnabled = isTripActive && studentSchedule.displayVehicleOnMap && this.stateService.getMapState() === TFMapType.GeoAlertMap;
      this.fetchTripGraphic(_studentSchedule.trips, false);
      this.currentTripState && (this.selectedTrip = this.currentTripState);
      return resolve(_studentSchedule);
    });
  }

  public getScheduleTimeZone(studentSchedule: StudentSchedule = null)
  {
    return (this.studentSchedule && this.studentSchedule.timeZoneMinutes) || (studentSchedule && studentSchedule.timeZoneMinutes) || 0;
  }

  public async clearMap()
  {
    try
    {
      await this.vehicleService.turnOffVehicleLocationDisplay(this.mapId).toPromise().then(() => this.graphicsService.clearAll(this.mapId));
      this.tooltipService.close();
      this.mapCoreService.webViewMode();
      this.mapCoreService.setHorizontalSplitSize(-1, true);
      this.vehicleService.clearCenterToBus();
      // close the SignalR service
      return Promise.resolve(true);
    }
    catch
    {
      return Promise.reject(false);
    }
  }

  /**
   * Private function to validate setter, not empty, not undefined and not null
   * @param value boolean, true as pass
   */
  private setterValidation(value: string | any): boolean
  {
    return !_.isEmpty(value) && !_.isUndefined(value) && !_.isNull(value);
  }

  private generateLayerStatesBasedOnEnum()
  {
    _.forEach(GraphicNameEnum, (eachGraphic: GraphicNameEnum) =>
    {
      this.layerStates[eachGraphic] = false;
    });

    _.set(this.layerStates, GraphicNameEnum.studentGraphic, true);
    _.set(this.layerStates, GraphicNameEnum.schoolGraphic, true);
  }

  /**
   * Function to reset all layers and graphics
   */
  private eraseGraphic(cleanAll = true, cleanTarget?: GraphicNameEnum)
  {
    // clean all condition:
    // 1. cleanAll equals to TRUE
    // 2. cleanAll equals to FALSE but cleanTarget is null or undefined
    const matchCleanAllCondition: boolean = cleanAll ||
      (!cleanAll &&
        (_.isUndefined(cleanTarget) || _.isNull(cleanTarget))
      );

    if (matchCleanAllCondition)
    {
      this.graphicsService.generateGraphicsBasedOnEnum();
      this.generateLayerStatesBasedOnEnum();
      // reset trip alias settings
      this.shouldDisplayTripAlias = false;
      // reset current schedule all geo alerts
      MapGeoAlertService.currentScheduleGeoAlerts = [];
      // reset selected trip
      this.selectedTrip = null;
      // reset trip id
      this.tripId = null;
      // reset rider Id
      this.riderId = null;
      // reset identify flag of has geo alerts
      this.geoAlertService.hasGeoAlerts = false;
    }
    else
    {
      this.graphicsService.setLayerGraphic(cleanTarget, []);

      if (_.isEqual(cleanTarget, GraphicNameEnum.pickupGraphic) ||
        _.isEqual(cleanTarget, GraphicNameEnum.dropOffGraphic))
      {
        this.shouldDisplayTripAlias = false;
        this.scheduledTrips = this.eraseScheduledTripsSelection();
        this.layerStates = {};
        this.graphicsService.setStopLayerGraphic([]);
      }
    }
  }

  /**
   * Private function to generate or erase scheduled trip selection
   * @return As new object
   */
  private eraseScheduledTripsSelection(): IMapHeaderSelection
  {
    return {
      defaultValue: GraphicNameEnum.studentGraphic,
      options: []
    };
  }

  private extractTripDropdown(studentSchedule: StudentSchedule, tripId: number = null, toSchool: boolean = null)
  {
    // reset trip options
    this.scheduledTrips = this.eraseScheduledTripsSelection();

    const sortedStudentSchedule = this.scheduleService.getTripScheduleOrder(studentSchedule);
    const trips: TripSchedule[] = sortedStudentSchedule.trips || [];

    if (trips.length == 1)
    {
      // keep this option before fetching pickup and drop off data
      this.scheduledTrips.options.push({
        value: GraphicNameEnum.studentGraphic,
        viewValue: this.scheduleService.truncateStudentName(studentSchedule.firstName, studentSchedule.lastName)
      } as MatOption);
      this.scheduledTrips.defaultValue = GraphicNameEnum.studentGraphic;
    }

    this.updateShouldDisplayTripAlias(studentSchedule);
    !_.isEmpty(trips) && _.forEach(trips, (trip: TripSchedule) =>
    {
      let tripEnum: GraphicNameEnum;
      if (trip.toSchool)
      {
        tripEnum = trip.isTransfer ? GraphicNameEnum.pickupTransferGraphic : GraphicNameEnum.pickupGraphic;
      }

      if (!trip.toSchool)
      {
        tripEnum = trip.isTransfer ? GraphicNameEnum.dropOffTransferGraphic : GraphicNameEnum.dropOffGraphic;
      }

      // push pickup option to map header selection
      this.scheduledTrips.options.push({
        value: `${tripEnum}_${trip.id}`,
        viewValue: this.getTripName(trip, this.shouldDisplayTripAlias)
      } as MatOption);
    });

    if (this.scheduledTrips.options.length === 0)
    {
      this.tripTitleName = this.scheduleService.truncateStudentName(studentSchedule.firstName, studentSchedule.lastName);
      return;
    }

    if (tripId)
    {
      var currentTrips = this.scheduledTrips.options.filter(
        a => a.value.indexOf(tripId) >= 0 && a.value.toLowerCase().indexOf((toSchool || toSchool === null) ? "pickup" : "dropoff") >= 0);
      if (currentTrips.length > 0)
      {
        this.scheduledTrips.defaultValue = currentTrips[0].value;
      }
    }
    else if (this.scheduledTrips.options.length > 0)
    {
      var nonStudentTrips = this.scheduledTrips.options.filter(s => s.value != GraphicNameEnum.studentGraphic);
      if (nonStudentTrips.length > 0)
      {
        this.scheduledTrips.defaultValue = nonStudentTrips[0].value;
      }
    }
  }

  /**
   * Function to generate graphics and label layers
   * @param scheduledTrips Optional, needs to prepare student graphic, school graphic, pickup graphic and drop off graphic
   * @param isNew Optional, true to erase all graphics array and label layer array
   * Could use function return value to identify trip schedule data validation
   * True to pass
   * False to fail
   */
  private async extractTrips(scheduledTrips?: TripSchedule[])
  {
    const trips: TripSchedule[] = scheduledTrips || (this.studentSchedule && this.studentSchedule.trips) || [];
    // should force erase all related data
    this.eraseGraphic();
    // should setup trip name as truncated Student name as default
    // trip name would be overwrite later
    this.tripTitleName = this.scheduleService.truncateStudentName(this.studentSchedule.firstName, this.studentSchedule.lastName);

    this.updateShouldDisplayTripAlias(this.studentSchedule);
    this.fetchStudentGraphic();
    this.fetchSchoolGraphic();
    this.fetchTripGraphic(trips);
  }

  private updateShouldDisplayTripAlias(studentSchedule: StudentSchedule)
  {
    this.shouldDisplayTripAlias = studentSchedule.displayTripAlias || false;
  }

  /**
   * Private function for validation, exception control for generate graphic data nad layers.
   * @param validateData Enum, mandatory, validation type
   * @param withCoords Interface, optional, validate coords not none
   */
  private beforeFetch(validateData: GraphicNameEnum, withCoords?: IPoint): boolean
  {
    if (_.isEmpty(this.studentSchedule))
    {
      return false;
    }

    switch (validateData)
    {
      // should not have any empty value in xCoords or yCoords for student graphic
      case GraphicNameEnum.studentGraphic:
        return !(_.isEqual(this.studentSchedule.xCoord, null) || _.isEqual(this.studentSchedule.yCoord, null));
      // should not have any empty value in schoolX or schoolY for school graphic
      case GraphicNameEnum.schoolGraphic:
        return !(_.isEqual(this.studentSchedule.schoolX, null) || _.isEqual(this.studentSchedule.schoolY, null));
      // should not have any empty value in xCoords or yCoords in validation
      default: return withCoords && !(_.isEqual(withCoords.longitude, null) || _.isEqual(withCoords.latitude, null));
    }
  }

  /**
   * Function to fetch student position graphic and label layer
   */
  private fetchStudentGraphic()
  {
    // should validate student data correct or not.
    const isPassed: boolean = this.beforeFetch(GraphicNameEnum.studentGraphic);
    isPassed && this.graphicsService.getStudentLayerGraphic().push(this.graphicsService.createStudentGraphic(this.mapId, this.studentSchedule));
    isPassed && _.merge(this.layerStates, { [GraphicNameEnum.studentGraphic]: isPassed });
  }

  /**
   * Private function to fetch school position graphic and label layer.
   * As school position owns two types: Pickup (school -> home), DropOff (home -> school)
   */
  private fetchSchoolGraphic()
  {
    // should validate school data correct or not.
    const isPassed: boolean = this.beforeFetch(GraphicNameEnum.schoolGraphic);
    isPassed && this.graphicsService.getSchoolLayerGraphic().push(this.graphicsService.createSchoolGraphic(this.mapId, this.studentSchedule));
    isPassed && _.merge(this.layerStates, { [GraphicNameEnum.schoolGraphic]: isPassed });
  }

  /**
   * Private function to fetch pickup graphic and label layer
   * @param pickupTrip When toSchool = true
   */
  private fetchPickupGraphic(pickupTrip: TripSchedule, isSetDefaultTrip: boolean = true)
  {
    this._pickupTrip = pickupTrip;
    const pickupEnum = pickupTrip.isTransfer ? GraphicNameEnum.pickupTransferGraphic : GraphicNameEnum.pickupGraphic;
    // should validate pickup data correct or not.
    const point: IPoint = {
      longitude: pickupTrip.pickUpStopXCoord || null,
      latitude: pickupTrip.pickUpStopYCoord || null
    };
    const isPassed = this.beforeFetch(pickupEnum, point);

    if (isPassed)
    {
      const graphic = this.graphicsService.createStopGraphic(this.mapId, pickupEnum, pickupTrip, point);
      this.graphicsService.getLayerGraphic(pickupEnum).push(graphic);

      // set trip to trip states
      this.tripStates[`${pickupEnum}_${pickupTrip.id}`] = pickupTrip;
      // Important, this is the scope validation for auto apply layer
      this.stopGraphicScope.push(pickupEnum);
      // stop graphic would be add to map layer
      this.graphicsService.setStopLayerGraphic(_.concat(this.graphicsService.getStopLayerGraphic(), this.graphicsService.getLayerGraphic(pickupEnum)));

      if (!pickupTrip.isTransfer)
      {
        // set default stop graphic as pickup graphic
        _.set(this.layerStates, pickupEnum, true);
        // set default pickup trip name
        this.tripTitleName = this.getTripName(pickupTrip, this.shouldDisplayTripAlias);
        // default selected trip
        isSetDefaultTrip && (this.selectedTrip = pickupEnum);
        // should store pickup trip id
        this.tripId = pickupTrip.id;
      }
    }
  }

  /**
   * Private function to fetch drop off graphic and label layer
   * @param dropOffTrip When toSchool = false
   */
  private fetchDropOffGraphic(dropOffTrip: TripSchedule)
  {
    this._dropOffTrip = dropOffTrip;
    const dropOffEnum = dropOffTrip.isTransfer ? GraphicNameEnum.dropOffTransferGraphic : GraphicNameEnum.dropOffGraphic;
    // should validate pickup data correct or not.
    const point: IPoint = {
      longitude: dropOffTrip.dropOffStopXCoord || null,
      latitude: dropOffTrip.dropOffStopYCoord || null
    };
    const isPassed = this.beforeFetch(GraphicNameEnum.dropOffGraphic, point);

    if (isPassed)
    {
      const graphic = this.graphicsService.createStopGraphic(this.mapId, dropOffEnum, dropOffTrip, point);
      this.graphicsService.getLayerGraphic(dropOffEnum).push(graphic);

      this.tripStates[`${dropOffEnum}_${dropOffTrip.id}`] = dropOffTrip;
      // Important, this is the scope validation for auto apply layer
      this.stopGraphicScope.push(dropOffEnum);
      // stop graphic would be add to map layer
      this.graphicsService.setStopLayerGraphic(_.concat(this.graphicsService.getStopLayerGraphic(), this.graphicsService.getLayerGraphic(dropOffEnum)));
    }
  }

  /**
   * Private function to get trip name for title.
   * this.studentSchedule has major Alias control: {displayTripAlias} for using alias name or trip name
   * Trip name will be tripAlias name if not empty, otherwise will use trip name instead.
   * @param trip Mandatory. TripSchedule.
   * @param shouldUseAlias Mandatory. boolean.
   */
  private getTripName(trip: TripSchedule, shouldUseAlias: boolean): string
  {
    if (shouldUseAlias && !_.isEmpty(_.trim(trip.tripAlias)))
    {
      return this.truncateTripName(trip.tripAlias);
    }
    return this.truncateTripName(trip.name);
  }

  private truncateTripName(name: string): string
  {
    return _.truncate(_.trim(name), { 'length': 30 });
  }

  private updateTripStopSymbolColor()
  {
    if (_.isEqual(this.graphicsService.getStopLayerGraphic().length, 1))
    {
      (this.graphicsService.getStopLayerGraphic()[0] as IGraphic).symbolName = SymbolType.tripStopMarkerSymbol;
    }
  }

  private updateStopGraphicLayer()
  {
    const displayOtherStop = this.studentSchedule.displayOtherStop;
    _.each(this.graphicsService.getStopLayerGraphic(), (graphic: IGraphic) =>
    {
      graphic.layerId = displayOtherStop ? MapLayerName.stopfinderBusStop : MapLayerName.stopfinderStudentStop;
    });
  }

  /**
   * Public function to call NativeEsriMap plugin to draw graphics
   */
  private async drawGeoAlertsMap()
  {
    if (!this.isMapPluginReady())
    {
      return false;
    }

    this.updateTripStopSymbolColor();
    this.updateStopGraphicLayer();
    try
    {

      await Promise.all([
        this.graphicsService.clearStudent(this.mapId),
        this.graphicsService.clearSchool(this.mapId),
        this.graphicsService.clearBusStop(this.mapId)
      ]);

      await Promise.all([
        this.graphicsService.addStudent(),
        this.graphicsService.addSchool(),
        this.graphicsService.addStop(this.studentSchedule.displayOtherStop)
      ]);
      return true;
    }
    catch (error)
    {
      return false;
    }
  }

  private async drawAttendanceMap()
  {
    if (!this.isMapPluginReady())
    {
      return false;
    }

    try
    {
      await Promise.all([
        this.graphicsService.clearStudent(this.mapId),
        this.graphicsService.clearSchool(this.mapId),
        this.graphicsService.clearAttendance(this.mapId)
      ]);

      await Promise.all([
        this.graphicsService.addStudent(),
        this.graphicsService.addSchool(),
        this.graphicsService.addAttendance()
      ]);
      return true;
    }
    catch (error)
    {
      return false;
    }
  }

  private zoomToSchoolAndStudentStopMBR(): void
  {
    const plugin = NativeMapView.nativeEsriMapPlugin;
    if (this.scheduleService.isStopfinderV2(this.studentSchedule))
    {
      // Stopfinder V2.0
      this.zoomToDefault(true);
    }
    else
    {
      // Stopfinder V1.0
      plugin.layerSearchAndAction(this.mapId, MapLayerName.stopfinderStudent, { type: 'all' },
        { type: 'zoomToTripBound' }, true, () => { }, () => { });
    }
  }

  /**
   * Private function for switch layer control
   * @param layer Mandatory. Scope in GraphicNameEnum, could be pickup graphic or drop off graphic
   * @param layerState Mandatory. Layer state to control add graphic or clear graphic
   */
  private async autoApplyLayer(layer: GraphicNameEnum, layerState: boolean)
  {
    // should add graphic
    if (layerState)
    {
      this.graphicsService.setStopLayerGraphic((_.includes(this.stopGraphicScope, layer) &&
        _.concat([], this.graphicsService.getLayerGraphic(layer))) || []);
    }
    // should clear graphic
    else
    {
      this.graphicsService.setStopLayerGraphic(_.filter(this.graphicsService.getStopLayerGraphic(), (graphic: IGraphic) => graphic.graphicId !== layer));
    }

    this.updateTripStopSymbolColor();
    this.updateStopGraphicLayer();

    try
    {
      await Promise.all([
        this.graphicsService.clearStudentStop(this.mapId),
        this.graphicsService.clearBusStop(this.mapId)
      ]);
      await this.graphicsService.addStop(this.studentSchedule.displayOtherStop);
    }
    catch (error) { }
  }

  private async layerChangeImpactedEvents()
  {
    const studentRiderId = this.studentSchedule.riderId;

    if (this.geoAlertService.disable)
    {
      this.geoAlertService.clean(this.mapId).then((result) =>
      {
        this.geoAlertService.hasGeoAlerts = false;
        return;
      });
    }

    if (!this.tripId)
    {
      this.geoAlertService.clean(this.mapId).then((result) =>
      {
        this.geoAlertService.hasGeoAlerts = false;
      });
    }

    await this.getGeoAlerts(studentRiderId);
    if (!_.isEqual(this.riderId, studentRiderId))
    {
      this.riderId = studentRiderId;
    }

    if (this.tripId)
    {
      !this.studentSchedule.displayTripPath && this.zoomToSchoolAndStudentStopMBR();
    }
    else
    {
      this.zoomToDefault(true);
    }

    TfMapFactory._awaitGet(this._mapId).then((map: TfMap) =>
    {
      map.toggleLabelColor();
    });
  }

  private async turnOnGeoModeForAndroidWhenExisting(existedGeoData: IGeoData, callback?: Function)
  {
    this.geoAlertService.remove(this.mapId, existedGeoData.id);
    await this.mapCoreService.centerAt(this.mapId, existedGeoData.xcoord, existedGeoData.ycoord);
    // once turn on, geo mode will in editing mode
    this.isGeoAlertEditing = true;
    // should initial geo alerts as new
    this.initialGeoAlert(existedGeoData);

    typeof callback === "function" && callback();
  }

  private turnOnGeoModeForAndroidWhenNotExisting()
  {
    // once turn on, geo mode will in editing mode
    this.isGeoAlertEditing = true;
    // should initial geo alerts as new
    this.initialGeoAlert();
  }

  private async turnOnGeoModeForIOSWhenExisting(currentGeoAlertId = -1, existedGeoData: IGeoData, callback?: Function)
  {
    await this.mapCoreService.centerAt(this.mapId, existedGeoData.xcoord, existedGeoData.ycoord);
    await this.geoAlertService.startEditing(this.mapId, currentGeoAlertId);

    // once turn on, geo mode will in editing mode
    this.isGeoAlertEditing = true;
    // should initial geo alerts as new
    this.initialGeoAlert(existedGeoData);

    typeof callback === "function" && callback();
  }

  private async turnOnGeoModeForIOSWhenNotExisting(currentGeoAlertId = -1)
  {
    await this.geoAlertService.startEditing(this.mapId, currentGeoAlertId);
    // once turn on, geo mode will in editing mode
    this.isGeoAlertEditing = true;
    // should initial geo alerts as new
    this.initialGeoAlert();
  }

  private geoModel(schedule: StudentSchedule, subscriber: Subscriber): IGeoData
  {
    return {
      name: '',
      subscriberId: subscriber.id,
      riderId: schedule.riderId,
      xcoord: schedule.xCoord,
      ycoord: schedule.yCoord,
      distance: this.geoAlertService.getDefaultDistance(),
      enterAlert: true,
      exitAlert: true,
    } as IGeoData;
  }

  private isValidCoordinate(coordinate: number): boolean
  {
    return !isNaN(coordinate) && Math.abs(coordinate) > 0.1;
  }

  private setDefaultMapCenter()
  {
    this._setMapCenter(SCHENECTADY_MAP_CENTER_X, SCHENECTADY_MAP_CENTER_Y);
  }

  /**
   * Public function to prepare geo data. This method will store all geo needed data for API
   * @param schedule
   * @param subscriber
   */
  private initialGeoData(schedule: StudentSchedule, subscriber: Subscriber)
  {
    // should prepare geo data from current data payload
    this.geoData = this.geoModel(schedule, subscriber);
  }

  private async updateEnableGeoAlertsStatus(studentSchedule: StudentSchedule)
  {
    // if no gps config, not show geo button and init the geo data
    const studentRiderId = studentSchedule.riderId;
    const result = await this.apiService.getGPSSwitch(studentSchedule.clientId, studentRiderId).toPromise();
    MapVehicleService.GPSEnabled = result.GPSEnabled;
    studentSchedule.enableGeoAlerts = result.GeoAlertEnabled;
    this.geoAlertService.disable = !((MapVehicleService.GPSEnabled && studentSchedule.enableGeoAlerts) || result.EtaAlertEnabled);
    this.shouldDisplayGeoAlerts = result.GeoAlertEnabled;
    this.shouldDisplayEtaAlerts = result.EtaAlertEnabled;
    this.showBellMenu = true;
  }

  private async openMap(appService: AppService, isOpenedFromNotification: boolean = false)
  {
    // must add the "hiddenDueToMap" class to "app-layout" when open the map
    const appLayoutEle = document.querySelector('#app-layout');
    appLayoutEle && appLayoutEle.classList.add('hiddenDueToMap');

    const longitude = (this.mapCenter && this.mapCenter.longitude) ? this.mapCenter.longitude : SCHENECTADY_MAP_CENTER_X;
    const latitude = (this.mapCenter && this.mapCenter.latitude) ? this.mapCenter.latitude : SCHENECTADY_MAP_CENTER_Y;
    // first open the map
    !appService.showMap && await appService.setMapVisibility(true, longitude, latitude, isOpenedFromNotification);
  }

  private setMapCenter(studentSchedule: StudentSchedule)
  {
    if (!this.scheduleService.isStopfinderV2(studentSchedule))
    {
      this.setDefaultMapCenter();
      return;
    }

    try
    {
      const item = studentSchedule.mapSettings;
      if (item && this.isValidCoordinate(item.longitude) && this.isValidCoordinate(item.latitude))
      {
        this._setMapCenter(item.longitude, item.latitude);
      }
      else
      {
        if (this.isValidCoordinate(studentSchedule.schoolX) && this.isValidCoordinate(studentSchedule.schoolY))
        {
          this._setMapCenter(studentSchedule.schoolX, studentSchedule.schoolY);
        }
        else
        {
          // There is no map settings for studentSchedule.clientId.
          this.setDefaultMapCenter();
        }
      }
    }
    catch (e)
    {
      this.setDefaultMapCenter();
    }
  }

  private getGeoAlerts(riderId: number, enforceZoom = false, cancelEditMode = false): Promise<boolean>
  {
    return new Promise(async (resolve, reject) =>
    {
      try
      {
        const geoData: IGeoData[] = this.shouldDisplayGeoAlerts ? await this.apiService.getAllGeoAlerts(riderId).toPromise() : [];
        const totalGeoAlertCount = geoData.length;
        this.geoAlertService.hasGeoAlerts = totalGeoAlertCount > 0;

        const mapId = this.mapId;
        await this.geoAlertService.clean(mapId);
        if (cancelEditMode)
        {
          await this.turnOffGeoMode();
        }

        if (this.geoAlertService.hasGeoAlerts)
        {
          MapGeoAlertService.currentScheduleGeoAlerts = geoData || [];
        }
        else
        {
          resolve(true);
          return;
        }

        let pList = [];
        for (let i = 0; i < totalGeoAlertCount; i++)
        {
          const geoAlert = this.geoAlertService.toGeoAlert(geoData[i]);
          const p = this.geoAlertService.create(mapId, geoAlert);
          pList.push(p);
        }

        await Promise.all(pList);
        resolve(enforceZoom);
      }
      catch
      {
        reject();
      }
    });
  }

  private async initialGeoAlert(data: IGeoData = null)
  {
    const center: IPoint = await this.mapCoreService.getMapCenter(this.mapId);
    const geoAlert: IGeoAlert = {
      id: this.geoData.id,
      name: this.geoData.name,
      longitude: data ? data.xcoord : center.longitude,
      latitude: data ? data.ycoord : center.latitude,
      distance: data ? data.distance : this.geoAlertService.getDefaultDistance(),
      fill: this.geoAlertService.getFillColor(this.geoData.enterAlert, this.geoData.exitAlert),
    };
    this.addGeoAlertPolygon(geoAlert);
  }

  private centerToBus(): void
  {
    const busPoint = this.vehicleService.busPoint;
    if (!busPoint)
    {
      this.zoomToDefault();
      return;
    }
    // use the set time out keep the center map to last on the plugin ui thread
    const centerAction = () => setTimeout(async () =>
    {
      await this.mapCoreService.centerAt(this.mapId, +busPoint.Longitude, +busPoint.Latitude);
    });
    // must wait the zoom to default complete then to center the bus
    if (this.zoomToDefaultIsComplete)
    {
      centerAction();
    }
    else
    {
      MapVehicleService.centerToBusTimer = window.setInterval(() =>
      {
        if (this.zoomToDefaultIsComplete)
        {
          centerAction();
          MapVehicleService.centerToBusTimer && clearInterval(MapVehicleService.centerToBusTimer);
        }
      }, 20);
    }
    MapVehicleService.alreadyCenterBus = true;
  }

  private fetchTripGraphic(trips: TripSchedule[], isSetDefaultTrip: boolean = true)
  {
    !_.isEmpty(trips) && _.forEach(trips, (trip: TripSchedule) =>
    {
      trip.toSchool && this.fetchPickupGraphic(trip, isSetDefaultTrip);
      !trip.toSchool && this.fetchDropOffGraphic(trip);
    });
  }

  private mappingGraphicByTripId(tripId: number): GraphicNameEnum
  {
    return _.findKey(this.tripStates, (registeredTrip: TripSchedule) =>
    {
      return _.isEqual(tripId, registeredTrip.id);
    });
  }

  private mappingLayerStatesByLayerChange(selectedTripGraphic: GraphicNameEnum): ILayerState
  {
    // reset all layer states to false as default, use new instance not reference
    const useState = _.mapValues(_.assign({}, this.layerStates), () => false);
    // student layout currently load (must) as default. Can directly delete once optional
    _.set(useState, GraphicNameEnum.studentGraphic, true);
    // school layout currently load (must) as default. Can directly delete once optional
    _.set(useState, GraphicNameEnum.schoolGraphic, true);
    // only set layer match selected value to visible
    _.set(useState, selectedTripGraphic, true);

    return useState;
  }

  private switchTrip(tripId: number)
  {
    // update selection label
    this.scheduledTrips.defaultValue = this.mappingGraphicByTripId(tripId);
    this.updateSelectedTrip();
    // update layer states
    const newState = this.mappingLayerStatesByLayerChange(this.scheduledTrips.defaultValue as GraphicNameEnum);
    // apply layer
    this.switchLayer(newState);
  }

  private graphicEventsListener()
  {
    const isiOS = this.deviceService.isiOS;
    const rate = isiOS ? 1 : window.devicePixelRatio;

    if (!this.tooltipService.tooltip)
    {
      this.tooltipService.tooltip = this.tooltipInstance;
    }

    NativeMapView.nativeEsriMapPlugin.doGeoAlertGraphicsWatch(this.mapId, (result: any) =>
    {
      if (this.isGeoAlertEditing)
      {
        return;
      }

      const tooltipConfig: ITooltip = {
        message: '',
      };

      if (_.get(result, 'attributes.GeoAlertId'))
      {
        this.geoData = _.find(MapGeoAlertService.currentScheduleGeoAlerts, (data: IGeoData) => _.isEqual(data.id, result.attributes.GeoAlertId));
      }

      const needsIcon = _.get(result, 'attributes.Name');

      if (needsIcon)
      { // tapping on the geo alert
        _.merge(tooltipConfig, {
          message: result.attributes.Name,
          icon: needsIcon,
          triangle: true,
          revisible: false,
          iconOptions: {
            iconName: 'mode_edit'
          }
        } as ITooltip);
      }
      else
      {
        _.merge(tooltipConfig, {
          message: result.attributes.text,
          triangle: true,
          reverse: !this.tooltipService.isTooltipDarkMode,
          reversible: true,
          icon: false,
        } as ITooltip);
      }

      _.merge(tooltipConfig, {
        position: {
          position: 'fixed',
          top: `${result.screenY / rate}px`,
          left: `${result.screenX / rate}px`
        }
      });

      this.tooltipService.close();
      this.tooltipService.config(tooltipConfig);

      needsIcon && this.tooltipService.iconClickEvent();

      this.tooltipService.show();
    }, () => { });

    if (isiOS)
    { // ios
      NativeMapView.nativeEsriMapPlugin.doZoomWatch(this.mapId, () =>
      {
        this.tooltipService.close();
        this.closeHeaderDropdown();
      }, () => { });

      // for rotate map event, it will trigger doRotateWatch in compass.component.

      NativeMapView.nativeEsriMapPlugin.doDragWatch(this.mapId, () =>
      {
        this.tooltipService.close();
        this.closeHeaderDropdown();
      }, () => { });

      NativeMapView.nativeEsriMapPlugin.doMapOnDownWatch(this.mapId, () =>
      {
        this.tooltipService.close();
        this.closeHeaderDropdown();
      }, () => { });
    }
    else
    {
      NativeMapView.nativeEsriMapPlugin.doZoomWatch(this.mapId, () =>
      {
        if (this.geoData && this.geoData.distance && this.isGeoAlertEditing)
        {
          this.initialGeoAlert(this.geoData);
        }
      }, () => { });

      NativeMapView.nativeEsriMapPlugin.doMapOnDownWatch(this.mapId, () =>
      {
        // This time out is for Android tap and call out disappear
        // Keep the timeout otherwise the call out will not disappear
        setTimeout(() =>
        {
          this.tooltipService.close();
          this.closeHeaderDropdown();
        }, 100);
      }, () => { });
    }

    TfMapFactory._awaitGet(this._mapId).then((map: TfMap) =>
    {
      map.tooltipService = this.tooltipService;
    });

    this.tooltipListenerBind = true;
  }

  private closeHeaderDropdown()
  {
    this.ngZone.run(() =>
    {
      this.headerDropdown && this.headerDropdown.close();
    });
  }

  private zoomTo(enforceZoom = true, ignoreAlreadyCenterBus = false): Promise<boolean>
  {
    return new Promise(async (resolve, reject) =>
    {
      if (!enforceZoom)
      {
        resolve(true);
      }

      try
      {
        if (this.stateService.getMapState() === MapGeoAlertService.mapType)
        {
          if (MapVehicleService.alreadyCenterBus && !ignoreAlreadyCenterBus)
          {
            resolve(true);
          }
          else 
          {
            await this.geoAlertService.zoomToBounds(this.mapId);
          }
        }
        else if (this.stateService.getMapState() === MapAttendanceService.mapType)
        {
          const response = await this.attendanceService.zoomToBounds(this.mapId);
          MapAttendanceService.mapScale = response.scale;
        }

        resolve(true);
      }
      catch { resolve(false); }
    });
  }

  private _setMapCenter(longitude: number, latitude: number)
  {
    this.mapCenter = { longitude, latitude };
  }

  private updateSelectedTrip()
  {
    this.selectedTrip = this.scheduledTrips.defaultValue;
  }

  private async _resetMapToBaseState(isFromNotification: boolean, appService: AppService): Promise<boolean>
  {
    this.hideGeoAlertMap = false;
    this.scheduleService.schedulesHiddenForMap = true;
    this.isVehicleDisplayEnabled = false;
    this.shouldDisplayEtaAlerts = false;
    this.shouldDisplayGeoAlerts = false;
    this.showBellMenu = false;
    this.geoAlertService.disable = true;
    if (isFromNotification && this.isGeoAlertEditing)
    {
      this.turnOffGeoAddMode();
      NativeMapView.nativeEsriMapPlugin.hideCenterGeoAlert(this.mapId, () => { }, () => { });
      this.geoAlertService.turnOffBottomSheet();
      this.resetViewSize(appService.windowWidth, appService.windowHeight);
    }

    return new Promise((resolve, reject) =>
    {
      this.clearMap().then(() =>
      {
        return resolve(true);
      }).catch(() =>
      {
        return resolve(false);
      })
    });
  }
}
