import { Component, OnInit, OnDestroy, NgZone, Inject, ChangeDetectorRef } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { StopfinderApiService } from 'src/app/shared/stopfinder/stopfinder-api.service';
import * as moment from 'moment';
import { MapService } from 'src/app/components/service/map/map.service';
import { GeoAlertNotification } from 'src/app/shared/stopfinder/stopfinder-models';
import { AndroidBackService } from 'src/app/androidBack.service';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { IndicatorService } from '../../../shared/refresh/indicator.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ListPickerEnum } from '../../../shared/utils/enum';
import { MapGeoAlertService } from 'src/app/components/service/map/map-geoalert.service';
import { DeviceService } from 'src/app/components/service/device/device.service';
import { MapCoreService } from 'src/app/components/service/map/map-core.service';
import { StopfinderDateTimeService } from 'src/app/shared/stopfinder/stopfinder-datetime.service';
import { NativeMapView } from 'src/app/tf-map/core/native-mapview';
@Component({
  selector: 'app-geo-alerts-history',
  templateUrl: './geo-alerts-history.component.html',
  styleUrls: ['./geo-alerts-history.component.scss']
})
export class GeoAlertsHistoryComponent implements OnInit, OnDestroy
{
  public pageTitle = 'GeoAlert History';
  public geoList = [];
  public geoFilterList = [];
  public selectedValue: ListPickerEnum = ListPickerEnum.Today;
  public selectedValueTranslateText: string = "";
  private updateGeoHistorySubs: Subscription;

  private isLoadingGeoHistory: boolean = false;
  private resumeSubscription: Subscription;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { riderId: number },
    public _appService: AppService,
    public androidBackService: AndroidBackService,
    public mapService: MapService,
    public readonly deviceService: DeviceService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly bottomSheetRef: MatBottomSheetRef<GeoAlertsHistoryComponent>,
    private readonly dateTimeService: StopfinderDateTimeService,
    private readonly geoAlertService: MapGeoAlertService,
    private readonly loadingIndicator: IndicatorService,
    private readonly mapCoreService: MapCoreService,
    private readonly ngZone: NgZone,
    private readonly stopfinderApi: StopfinderApiService,
    private readonly translate: TranslateService,
  )
  {
    this.getGeoHistory = this.getGeoHistory.bind(this);
    this.translate.stream("geo.history.geo.alert.history").subscribe((text) =>
    {
      this.pageTitle = text;
      this.updateSelectValueText();
    })
  }

  ngOnInit()
  {
    this.androidBackService.onShouldCheckCallback(this.back.bind(this));
    const riderId = this.data.riderId;
    const dataSourceId = this.mapService.studentSchedule.dataSourceId;
    const tripId = this.mapService.tripId;
    const date = moment().utc().subtract(1, 'weeks').startOf('week').format(this.dateTimeService.formatDate2);

    this.resumeSubscription = this._appService.resumeEvent.subscribe(() =>
    {
      this.getGeoHistory(riderId, dataSourceId, date, this.selectedValue || ListPickerEnum.Today);
    });

    this.getGeoHistory(riderId, dataSourceId, date, ListPickerEnum.Today);

    this.updateGeoHistorySubs = this.geoAlertService.geoHistoryObservable.subscribe((notification: GeoAlertNotification) =>
    {
      if (notification &&
        _.isEqual(Number(notification.riderId), Number(riderId)) &&
        _.isEqual(Number(notification.dataSourceId), Number(dataSourceId)) &&
        _.isEqual(Number(notification.tripId), Number(tripId)) &&
        !_.find(this.geoList, { id: notification.id })
      )
      {
        const timeZoneMinutes = this.mapService.getScheduleTimeZone();
        this.geoList.unshift({
          sentOn: this.dateTimeService.toUtcDate(notification.sentOn, timeZoneMinutes).format(this.dateTimeService.formatDate2),
          body: notification.body,
          id: notification.id,
        });

        if (!this.isLoadingGeoHistory)
        {
          this.filterGeoHistory(this.selectedValue || ListPickerEnum.Today);
        }
        this._changeDetectorRef.markForCheck();
      }
    });
  }

  ngOnDestroy()
  {
    this.androidBackService.onDestroyCallback();
    this.updateGeoHistorySubs && this.updateGeoHistorySubs.unsubscribe();
    this.resumeSubscription && this.resumeSubscription.unsubscribe();
    this.isLoadingGeoHistory = false;
  }

  openListPicker()
  {
    const listPickerInstance = this._appService.listPickerInstance;
    const config = {
      items: [
        { text: this.translate.instant("geo.history.today"), value: ListPickerEnum.Today },
        { text: this.translate.instant("geo.history.yesterday"), value: ListPickerEnum.Yesterday },
        { text: this.translate.instant("geo.history.this.week"), value: ListPickerEnum.ThisWeek },
        { text: this.translate.instant("geo.history.last.week"), value: ListPickerEnum.LastWeek }
      ],
      selectedValue: this.selectedValue,
      doneButtonLabel: this.translate.instant("geo.history.done"),
      cancelButtonLabel: this.translate.instant("geo.history.cancel")
    };

    this.addClass();
    // Show the picker
    listPickerInstance.showPicker(config,
      (value) =>
      {
        this.ngZone.run(() =>
        {
          this.selectedValue = value;
          this.updateSelectValueText();
          this.removeClass();
          this.filterGeoHistory(value);
          this._changeDetectorRef.markForCheck();
        });
      },
      () =>
      {
        this.ngZone.run(() =>
        {
          this.removeClass();
        });
      }
    );
  }

  public back()
  {
    this.actionSplitViewMode();
    this.bottomSheetRef.dismiss();
  }

  private filterGeoHistory(filterType: ListPickerEnum)
  {
    this.geoList = _.uniqWith(this.geoList, (a, b) => Number(a.id) === Number(b.id));
    const timeZoneMinutes = this.mapService.getScheduleTimeZone();
    this.geoFilterList = this.dateTimeService.filterDate(timeZoneMinutes, filterType, this.geoList, 'sentOn');
  }

  private getGeoHistory(riderId: number, dataSourceId: number, date: string, filterType: ListPickerEnum)
  {
    this.loadingIndicator.show();
    this.isLoadingGeoHistory = true;

    this.stopfinderApi.getGeoAlertHistory(riderId, dataSourceId, date, this.mapService.tripId).subscribe((response: Array<GeoAlertNotification>) =>
    {
      const timeZoneMinutes = this.mapService.getScheduleTimeZone();
      response.forEach(item =>
      {
        this.geoList.push({
          sentOn: this.dateTimeService.toUtcDate(item.sentOn, timeZoneMinutes).format(this.dateTimeService.formatDate2),
          body: item.body,
          id: item.id,
        });
      });

      this.filterGeoHistory(filterType);

      this._changeDetectorRef.markForCheck();

      this.isLoadingGeoHistory = false;
      this.loadingIndicator.close();
    });
  }

  private addClass()
  {
    if (this.deviceService.isiPhone)
    {
      this.mapService.mapToolState = false;
      const mainContent = document.getElementById('geo-history-container');
      const bottomSheetEle = document.getElementsByClassName('geo-history-wrapper')[0];
      const overlayWrapper = document.getElementsByClassName('cdk-global-overlay-wrapper')[0];
      const overlay = document.getElementsByClassName('geo-history-overlay')[0];
      mainContent.classList.add('opacity');
      bottomSheetEle.classList.add('expand');
      overlayWrapper.classList.add('expand');
      (overlay as HTMLElement).style.display = 'block';
      NativeMapView.nativeEsriMapPlugin.toggleMapViewVisible(this.mapService.mapId, false, () => { }, () => null);
    }
  }

  private removeClass()
  {
    if (this.deviceService.isiPhone)
    {
      this.mapService.mapToolState = true;
      const mainContent = document.getElementById('geo-history-container');
      const bottomSheetEle = document.getElementsByClassName('geo-history-wrapper')[0];
      const overlayWrapper = document.getElementsByClassName('cdk-global-overlay-wrapper')[0];
      const overlay = document.getElementsByClassName('geo-history-overlay')[0];
      mainContent.classList.remove('opacity');
      bottomSheetEle.classList.remove('expand');
      overlayWrapper.classList.remove('expand');
      (overlay as HTMLElement).style.display = 'none';
      NativeMapView.nativeEsriMapPlugin.toggleMapViewVisible(this.mapService.mapId, true, () => { }, () => null);
    }
  }

  private readonly actionSplitViewMode = () => this.deviceService.isAndroid && this.mapCoreService.splitMode();

  private updateSelectValueText()
  {
    switch (this.selectedValue)
    {
      case ListPickerEnum.Today:
        this.selectedValueTranslateText = this.translate.instant("geo.history.today");
        break;
      case ListPickerEnum.Yesterday:
        this.selectedValueTranslateText = this.translate.instant("geo.history.yesterday");
        break;
      case ListPickerEnum.ThisWeek:
        this.selectedValueTranslateText = this.translate.instant("geo.history.this.week");
        break;
      case ListPickerEnum.LastWeek:
        this.selectedValueTranslateText = this.translate.instant("geo.history.last.week");
        break;
    }
  }
}
