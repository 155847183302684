// Map Only functions, not related to Stopfinder App

import { Injectable } from "@angular/core";
import { IPoint } from "src/app/shared/stopfinder/models/map";
import { EARTHS_RADIUS, INIT_ZOOM_LEVEL } from "src/app/shared/utils/constant";
import { NativeMapView } from "src/app/tf-map/core/native-mapview";

@Injectable()
export class MapCoreService
{
  constructor() { }

  /**
   * Distance between two points accounting for earth's curvature
   * https://en.wikipedia.org/wiki/Haversine_formula
   * @param lng1 longitude of point 1
   * @param lat1 latitude of point 1
   * @param lng2 longitude of point 2
   * @param lat2 latitude of point 2
   * Earth Radius = 6378137m
   */
  public haversine_distance(lng1, lat1, lng2, lat2): number
  {
    if ((lng1 === lng2) && (lat1 === lat2))
    {
      return 0;
    }
    else
    {
      const dLng = this.radians(lng2 - lng1);
      const dLat = this.radians(lat2 - lat1);

      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.radians(lat1)) * Math.cos(this.radians(lat2)) *
        Math.sin(dLng / 2) * Math.sin(dLng / 2)
        ;

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      return EARTHS_RADIUS * c; // Distance in meters
    }
  }

  public getMapCenter(mapId: string): Promise<IPoint>
  {
    return new Promise((resolve, reject) =>
    {
      NativeMapView.nativeEsriMapPlugin.getMapCenter(mapId, (center) => resolve(center), () => reject(null));
    });
  }

  public centerAt(mapId: string, longitude: number, latitude: number, zoom: number = INIT_ZOOM_LEVEL): Promise<void>
  {
    return new Promise((resolve, reject) =>
    {
      NativeMapView.nativeEsriMapPlugin.centerMapAt(mapId, latitude, longitude, zoom, () => resolve(), (error) => reject(error));
    });
  }

  /**
   * Public function to turn on web view mode, and this view mode will disable map event like:
   * drag, rotate, scale, pinch, etc.
   */
  public webViewMode = () => NativeMapView.nativeEsriMapPlugin.setEventMode('webView', () => { }, () => { });

  /**
   * Public function to turn on split view mode, and this view mode will block UI event like:
   * swipe, scroll, etc.
   */
  public splitMode = () => NativeMapView.nativeEsriMapPlugin.setEventMode('splitView', () => { }, () => { });

  public setHorizontalSplitSize = (size: number, fromTop: boolean) => NativeMapView.nativeEsriMapPlugin.setHorizontalSplit(size, fromTop, () => { }, () => { });

  /**
  * Converts degrees to radians
  * @param angle degrees to convert
  */
  private radians(angle): number
  {
    return angle * (Math.PI / 180);
  }

}
