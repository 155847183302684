import {
  Component,
  ViewEncapsulation,
  Inject,
  NgZone,
} from "@angular/core";
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
} from '@angular/material';

export interface IPosition {
  position: string;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
}

interface ITooltipBase {
  message: string;
  position?: IPosition;
}

interface IOptionsBase {
  color?: string;
  opacity?: string;
}

interface IIconOptions {
  iconLabel?: string;
  iconName: string;
}

interface ITooltipOptions {
  triangle?: boolean;
  triangleOptions?: IOptionsBase;
  icon?: boolean;
  iconOptions?: IIconOptions;
  reverse?: boolean;
  reversible?: boolean;
}

export interface ITooltip extends ITooltipBase, ITooltipOptions {
  iconClickCallback?: Function;
}

export interface ITooltipEvents {

  show(): void;

  close(): void;

  closeAll(): void;

  updatePosition(position: IPosition): void;

  lockPosition(): void;

  unlockPosition(): void;

  getPosition(): IPosition;

  iconClickEvent(): void;
}

@Component({
  selector: 'sf-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  // Need to remove view encapsulation so that the custom tooltip style defined in
  // `tooltip.component.scss` will not be scoped to this component's view.
  encapsulation: ViewEncapsulation.None,
})
export class TooltipComponent implements ITooltipEvents {
  public showTooltip = false;

  constructor(
    @Inject(MAT_TOOLTIP_DEFAULT_OPTIONS) public config: ITooltip,
    private readonly ngZone: NgZone,
  ) {
  }

  public show = () => {
    this.ngZone.run(() => {
      if (this.config.message)
      {
        this.showTooltip = true;
      }
    })
  };

  public close = (): Promise<boolean> => {
    return new Promise<boolean>((resolve) => {
      this.ngZone.run(() => {
        this.showTooltip = false;
        resolve(true);
      })
    });
  }

  public closeAll() { this.close(); }

  public updatePosition(newPosition: IPosition) {
    this.config.position = newPosition;
  }

  public lockPosition() { }

  public unlockPosition() { }

  public getPosition(): IPosition {
    return this.config.position;
  }

  public iconClickEvent() {
    this.config.icon && this.config.iconClickCallback && this.config.iconClickCallback();
  }
}
