import { Component, OnInit, NgZone, ChangeDetectorRef, OnDestroy, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AndroidBackService } from 'src/app/androidBack.service';
import { AppService } from 'src/app/app.service';
import { StateService } from 'src/app/components/service/state/state.service';
import { StopfinderApiService } from 'src/app/shared/stopfinder/stopfinder-api.service';
import { ListPickerEnum } from '../../shared/utils/enum';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { IndicatorService } from 'src/app/shared/refresh/indicator.service';
import { IAttendance } from 'src/app/shared/stopfinder/models/attendance';
import { AttendanceService } from 'src/app/components/service/attendance/attendance.service';
import { DeviceService } from 'src/app/components/service/device/device.service';
import { StopfinderDateTimeService } from 'src/app/shared/stopfinder/stopfinder-datetime.service';
import { MapService } from 'src/app/components/service/map/map.service';
@Component({
  selector: 'app-attendance-history',
  templateUrl: './attendance-history.component.html',
  styleUrls: ['./attendance-history.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AttendanceHistoryComponent implements OnInit, OnDestroy
{
  public pageTitle = 'Scan History';
  public scanList: IAttendance[] = [];
  public scanFilterList: IAttendance[] = [];
  public selectedValue: ListPickerEnum = ListPickerEnum.Today;
  public selectedValueTranslateText: string = "";

  constructor(
    private readonly ngZone: NgZone,
    private readonly stopfinderApi: StopfinderApiService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly translate: TranslateService,
    private readonly router: ActivatedRoute,
    private readonly loadingIndicator: IndicatorService,
    private readonly stateService: StateService,
    private readonly attendanceService: AttendanceService,
    private readonly dateTimeService: StopfinderDateTimeService,
    private readonly mapService: MapService,
    public readonly _appService: AppService,
    public readonly androidBackService: AndroidBackService,
    public readonly deviceService: DeviceService,
  ) 
  {
    this.getAllScanHistory = this.getAllScanHistory.bind(this);
    this.translate.stream("scanned.history.title").subscribe((text) =>
    {
      this.pageTitle = text;
      this.updateSelectValueText();
    })
  }

  ngOnInit()
  {
    this.androidBackService.onShouldCheckCallback(this.back.bind(this));
    const riderId = Number(this.router.snapshot.paramMap.get('riderId'));
    const dataSourceId = Number(this.router.snapshot.paramMap.get('dataSourceId'));
    const date = moment().utc().subtract(1, 'weeks').startOf('week').format(this.dateTimeService.formatDate);
    this.getAllScanHistory(riderId, dataSourceId, date, this.selectedValue || ListPickerEnum.Today);
  }

  back()
  {
    if (this._appService.showMap)
    {
      this.attendanceService.onCloseAttendanceMap(this._appService);
      return;
    }
    this.stateService.goRoute('schedule');
  }

  ngOnDestroy()
  {
    this.androidBackService.onDestroyCallback();
  }

  openListPicker()
  {
    const listPickerInstance = this._appService.listPickerInstance;

    if (!listPickerInstance)
    {
      return;
    };

    const config = {
      items: [
        { text: this.translate.instant("scanned.history.today"), value: ListPickerEnum.Today },
        { text: this.translate.instant("scanned.history.yesterday"), value: ListPickerEnum.Yesterday },
        { text: this.translate.instant("scanned.history.this.week"), value: ListPickerEnum.ThisWeek },
        { text: this.translate.instant("scanned.history.last.week"), value: ListPickerEnum.LastWeek }
      ],
      selectedValue: this.selectedValue,
      doneButtonLabel: this.translate.instant("scanned.history.done"),
      cancelButtonLabel: this.translate.instant("scanned.history.cancel")
    };

    this.updateClass(true);

    listPickerInstance.showPicker(config,
      (value) =>
      {
        this.ngZone.run(() =>
        {
          this.selectedValue = value;
          this.updateSelectValueText();
          this.updateClass(false);
          this.filterScanHistory(value);
          this.changeDetectorRef.markForCheck();
        });
      },
      () =>
      {
        this.ngZone.run(() =>
        {
          this.updateClass(false);
        });
      }
    );
  }

  openAttendanceMap(attendance: IAttendance)
  {
    this.attendanceService.openAttendanceMap(false, this._appService, {
      attendance
    })
  }

  private filterScanHistory(filterType: ListPickerEnum)
  {
    this.scanList = _.uniqWith(this.scanList, (a, b) => Number(a.id) === Number(b.id));
    const timeZoneMinutes = this.mapService.getScheduleTimeZone(this.attendanceService.selectStudentSchedule.value);
    this.scanFilterList = this.dateTimeService.filterDate(timeZoneMinutes, filterType, this.scanList, 'scannedDate');
  }

  private updateClass(isShow: boolean)
  {
    if (this.deviceService.isAndroid || this.deviceService.isiPad) return;

    const mainContent = document.getElementById('scanned-history-container');
    const overlay = document.getElementById('app-layout');
    const mainNav = document.getElementsByClassName('main-nav');
    if (isShow)
    {
      document.body.style.backgroundColor = "transparent";
      mainContent && mainContent.classList.add('opacity');
      overlay && overlay.classList.add('opacity');
      for (let i = 0; i < mainNav.length; i++)
      {
        mainNav.item(i).classList.add('opacity');
      }
    } else
    {
      document.body.style.backgroundColor = "#fff";
      mainContent && mainContent.classList.remove('opacity');
      overlay && overlay.classList.remove('opacity');
      for (let i = 0; i < mainNav.length; i++)
      {
        mainNav.item(i).classList.remove('opacity');
      }
    }
  }

  private getAllScanHistory(riderId: number, dataSourceId: number, date: string, filterType: ListPickerEnum)
  {
    this.loadingIndicator.show();
    this.stopfinderApi.getScannedHistory(riderId, dataSourceId, date).subscribe((response) =>
    {
      response.forEach(item =>
      {
        if (!_.isEmpty(item.scannedDate))
        {
          this.scanList.push({
            ...item,
            scannedDate: this.attendanceService.calcClientScanDate(item.scannedDate),
          });
        }
      });

      this.filterScanHistory(filterType);

      this.changeDetectorRef.markForCheck();
      this.loadingIndicator.close();
    });
  }

  private updateSelectValueText()
  {
    switch (this.selectedValue)
    {
      case ListPickerEnum.Today:
        this.selectedValueTranslateText = this.translate.instant("scanned.history.today");
        break;
      case ListPickerEnum.Yesterday:
        this.selectedValueTranslateText = this.translate.instant("scanned.history.yesterday");
        break;
      case ListPickerEnum.ThisWeek:
        this.selectedValueTranslateText = this.translate.instant("scanned.history.this.week");
        break;
      case ListPickerEnum.LastWeek:
        this.selectedValueTranslateText = this.translate.instant("scanned.history.last.week");
        break;
    }
  }
}
