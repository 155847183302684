
import { Injectable } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { Observable } from "rxjs/internal/Observable";
import { IGeoData } from "src/app/map/geo/export.geo.components";
import { IMapScaleResponse, IPoint } from "src/app/shared/stopfinder/models/map";
import { GeoAlertNotification } from "src/app/shared/stopfinder/stopfinder-models";
import { FEET_MAX, FEET_METERS_EXCHANGE_RATE, FEET_MIN, MAP_PADDING_PIXEL, METERS_MAX, METERS_MIN, X_MAP_PADDING_PIXEL } from "src/app/shared/utils/constant";
import { TFMapType, UnitOfLength } from "src/app/shared/utils/enum";
import { NativeMapView } from "src/app/tf-map/core/native-mapview";
import { MapLayerName } from "src/app/tf-map/themes/enums/enum.map-layer";
import { DeviceService } from "../device/device.service";

export interface IGeoAlert
{
  id: number,
  name: string,
  longitude: number,
  latitude: number,
  distance: number,
  fill: string
}

@Injectable()
export class MapGeoAlertService
{
  public static mapType = TFMapType.GeoAlertMap;
  public static currentScheduleGeoAlerts: IGeoData[];
  public static meterToFoot = (meters: number): number => (meters / FEET_METERS_EXCHANGE_RATE);
  public static footToMeter = (feet: number): number => (feet * FEET_METERS_EXCHANGE_RATE);

  private _disable: boolean = true;  // enable / disable GeoAlert
  private _distanceUnit: UnitOfLength = UnitOfLength.Feet;
  private _defaultDistance: number = FEET_MIN;

  public geoHistory: BehaviorSubject<GeoAlertNotification> = new BehaviorSubject<GeoAlertNotification>(null);
  public readonly geoHistoryObservable: Observable<GeoAlertNotification> = this.geoHistory.asObservable();
  public hasGeoAlerts = false;
  public onUIEditMode = false;

  constructor(public bottomSheet: MatBottomSheet, private readonly deviceService: DeviceService,) { }

  get disable(): boolean
  {
    return this._disable;
  }

  set disable(value: boolean)
  {
    this._disable = value;
  }

  get distanceUnit(): UnitOfLength
  {
    return this._distanceUnit;
  }

  set distanceUnit(value: UnitOfLength)
  {
    this._distanceUnit = value;
  }

  get defaultDistance(): number
  {
    return this._defaultDistance;
  }

  set defaultDistance(value: number)
  {
    if (this._distanceUnit === UnitOfLength.Meters && (value < METERS_MIN || value > METERS_MAX) ||
      this._distanceUnit === UnitOfLength.Feet && (value < FEET_MIN || value > FEET_MAX))
    {
      throw new Error('Invalid geoAlert distance.');
    }

    this._defaultDistance = value;
  }

  public toGeoAlert(geoData: IGeoData): IGeoAlert
  {
    return {
      id: geoData.id,
      name: geoData.name,
      longitude: geoData.xcoord,
      latitude: geoData.ycoord,
      distance: geoData.distance,
      fill: this.getFillColor(geoData.enterAlert, geoData.exitAlert)
    };
  }

  public startEditing(mapId: string, geoAlertId: number): Promise<void>
  {
    return new Promise((resolve, reject) =>
    {
      NativeMapView.nativeEsriMapPlugin.turnOnGeoAlertMode(mapId, geoAlertId, () => resolve(), () => reject());
    });
  }

  public stopEditing(mapId: string): Promise<void>
  {
    return new Promise((resolve, reject) =>
    {
      NativeMapView.nativeEsriMapPlugin.turnOffGeoAlertMode(mapId, () => resolve(), () => reject());
    });
  }

  public clean(mapId: string): Promise<boolean>
  {
    return new Promise((resolve, reject) =>
    {
      NativeMapView.nativeEsriMapPlugin.deleteGeoAlerts(mapId, () =>
      {
        resolve(true);
      }, () => { reject(false) });
    });
  }

  public remove(mapId: string, geoAlertId: number): void
  {
    NativeMapView.nativeEsriMapPlugin.deleteGeoAlert(mapId, geoAlertId, () => { }, () => { });
  }

  public create(mapId: string, geoAlert: IGeoAlert): Promise<boolean>
  {
    return new Promise((resolve, reject) =>
    {
      NativeMapView.nativeEsriMapPlugin.createGeoAlertBoundary(
        mapId, geoAlert.id, geoAlert.name, geoAlert.longitude, geoAlert.latitude, geoAlert.distance, geoAlert.fill, () =>
      {
        resolve(true);
      }, () =>
      {
        reject(false);
      });
    });
  }

  public showInAndroidCenter(mapId: string, geoAlert: IGeoAlert): Promise<void>
  {
    return new Promise((resolve, reject) =>
    {
      NativeMapView.nativeEsriMapPlugin.calcMapScreenPixelValue(mapId, geoAlert.longitude, geoAlert.latitude, geoAlert.distance, (result) =>
      {
        NativeMapView.nativeEsriMapPlugin.showCenterGeoAlert(mapId, geoAlert.name, parseInt(result.pixel), geoAlert.fill, () => resolve(), () => reject());
      }, () => reject());
    });
  }

  public hide(mapId: string): Promise<void>
  {
    return new Promise((resolve, reject) =>
    {
      NativeMapView.nativeEsriMapPlugin.hideCenterGeoAlert(mapId, () => resolve(), () => reject());
    });
  }

  public getFillColor(enter?: boolean, exit?: boolean): string
  {
    return (enter || exit) ? '#ff3333' : '#959595';
  }

  public getDefaultDistance()
  {
    if (this.distanceUnit === UnitOfLength.Feet)
    {
      return MapGeoAlertService.footToMeter(this.defaultDistance);
    }
    return this.defaultDistance;
  }

  public getDistance(value: number, isInit?: boolean): number
  {
    if (isInit)
    {
      return this.distanceUnit === UnitOfLength.Meters ? value : MapGeoAlertService.meterToFoot(value);
    }
    let output = MapGeoAlertService.footToMeter(value);
    if (this.distanceUnit === UnitOfLength.Meters)
    {
      output = value;
    }
    return Number(output.toFixed(2));
  }

  public zoomToBounds(mapId: string): Promise<IMapScaleResponse>
  {
    const padding = this.deviceService.isiOS ? X_MAP_PADDING_PIXEL : MAP_PADDING_PIXEL;
    return new Promise((resolve, reject) =>
    {
      NativeMapView.nativeEsriMapPlugin.getMapLayerExtent(mapId, this.getMapLayers(), padding, (response) => resolve(response), () => reject());
    });
  }

  public calculateDialogTopValue(): number
  {
    let safeTopSize: number = null;
    if (window && window.outerHeight)
    {
      safeTopSize = Number(((window.outerHeight / 4) / 100).toFixed(1));
    }

    return safeTopSize * 100;
  }

  public getCenter(mapId: string, geoAlertId: number): Promise<IPoint>
  {
    return new Promise((resolve, reject) =>
    {
      NativeMapView.nativeEsriMapPlugin.getGeoAlertCenter(mapId, geoAlertId, (center: IPoint) => resolve(center), () => reject());
    });
  }

  public turnOffBottomSheet()
  {
    this.bottomSheet && this.bottomSheet._openedBottomSheetRef && this.bottomSheet._openedBottomSheetRef.dismiss();
  }

  private getMapLayers(): MapLayerName[]
  {
    return [
      MapLayerName.stopfinderPath,
      MapLayerName.stopfinderBusStop,
      MapLayerName.stopfinderGeoAlertPolygon,
      MapLayerName.stopfinderSchool,
      MapLayerName.stopfinderStudent,
      MapLayerName.stopfinderStudentStop
    ];
  }
}
