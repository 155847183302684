import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { trigger, style, animate, transition, query, state } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../../app.service';
import { MapToolOptions } from '../map-tools';
import { BehaviorSubject } from 'rxjs';
import { DeviceService } from 'src/app/components/service/device/device.service';

@Component({
  selector: 'map-tools',
  templateUrl: './map-tools.component.html',
  styleUrls: ['./map-tools.component.scss'],
  animations: [
    trigger('manage', [
      state('closed', style({ opacity: 1 })),
      state('open', style({ opacity: 0 })),
      transition('closed => open', [animate(350)]),
      transition('open => closed', [animate(350)])
    ]),
    trigger('clear', [
      state('closed', style({ opacity: 0 })),
      state('open', style({ opacity: 1 })),
      transition('closed => open', [animate(350)]),
      transition('open => closed', [animate(350)])
    ]),
    trigger('buttonIcon', [
      transition('closed => open', [query('.button-image', [animate('350ms ease-out', style({ transform: 'rotate(180deg)' }))])]),
      transition('open => closed', [
        query('.button-image', [style({ transform: 'rotate(180deg)' }), animate('350ms ease-in', style({ transform: 'rotate(0deg)' }))])
      ])
    ])
  ]
})
export class MapToolsComponent implements OnInit
{
  @Input() options: MapToolOptions;
  @Output() mapToolsClicked = new EventEmitter<boolean>(false);
  @Output() zoomtoboundEvent = new EventEmitter<boolean>(false);
  @Input() showBasemaps = false;

  private _observableShowBasemaps: BehaviorSubject<any>;
  public dict = {};
  public mapTools = 'closed';

  @Input('observableShowBasemaps')
  set observableShowBasemaps(value: BehaviorSubject<any>)
  {
    this._observableShowBasemaps = value;
    this._observableShowBasemaps.subscribe((data) =>
    {
      if (data !== null)
      {
        this.toggleState(true);
      }
    });
  }

  constructor(
    readonly _appService: AppService,
    public readonly deviceService: DeviceService,
    private readonly translate: TranslateService,)
  { }

  ngOnInit()
  {
    if (!this.options)
    {
      this.options = {
        Basemap: true,
        ZoomToBounds: true,
        MyLocation: true
      };
    }
    this.optionTop();
  }

  optionTop()
  {
    let i = 0;
    if (this.options.Basemap)
    {
      this.dict['BM'] = i;
      i = i + 1;
    }
    if (this.options.ZoomToBounds)
    {
      this.dict['ZTB'] = i;
      i = i + 1;
    }
    if (this.options.MyLocation)
    {
      this.dict['ML'] = i;
      i = i + 1;
    }
  }

  toggleState(mapToolsEvent = false)
  {
    if (this.mapTools === 'closed')
    {
      this.mapTools = 'open';
    } else
    {
      this.mapTools = 'closed';
      this._appService._mapToolOpened.next(false);
    }
    if (!mapToolsEvent)
    {
      this.mapToolsClicked.emit(true);
    }
  }

  basemapsClosed()
  {
    this.showBasemaps = false;
    this.toggleState();
    this._appService._mapToolOpened.next(this.showBasemaps);
  }
  /**
   * Instead of just setting the showBasemaps to true or false, this does some cleanup and propagates the info up to the parent
   */
  handleBasemaps()
  {
    if (!this.showBasemaps)
    {
      this.showBasemaps = !this.showBasemaps;
    }
    this._appService._mapToolOpened.next(true);
  }

  baseMapOverlayClick()
  {
    this.showBasemaps = false;
    this._appService._mapToolOpened.next(false);
  }

  handleZoomToBounds()
  {
    this.zoomtoboundEvent.emit();
    this.toggleState();
  }
}
