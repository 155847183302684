import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivationService } from './activation.service';
import { PasswordValidation } from '../shared/stopfinder/validation/password-validation';
import { zip } from 'rxjs';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.scss'],
  providers: [ActivationService]
})
export class ActivationComponent implements OnInit
{
  public phonePattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  public activationForm: FormGroup;
  public isGuidValid = true;
  public isLoading = true;
  public guidParameter: string;
  public emailParameter: string;
  public email: string;
  public firstName: string;
  public lastName: string;
  public enrollmentSentOn: string;
  public mobilePhoneNumber: string;
  public validationRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/;
  private readonly password = new FormControl('', [Validators.required,
  Validators.pattern(this.validationRegex)]);
  private readonly passwordConfirmation = new FormControl('', [Validators.required,
  Validators.minLength(8)]);
  public fullYear = new Date().getFullYear();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    public activationService: ActivationService,
    public route: ActivatedRoute
  ) { }

  ngOnInit()
  {

    const parameterObservables = zip(this.route.params, this.route.queryParams);

    parameterObservables.subscribe(([p, qp]) =>
    {
      this.guidParameter = p['guid'] || qp['guid'];
      this.emailParameter = p['email'] || qp['email'];
      this.getEnrollment();
    });

    this.activationForm = this.formBuilder.group({
      password: this.password,
      passwordConfirmation: this.passwordConfirmation
    }, {
      validator: PasswordValidation.MatchPassword
    });
  }

  getEnrollment()
  {
    this.activationService
      .getEnrollment(this.guidParameter, this.emailParameter)
      .subscribe(
        enrollment =>
        {
          this.email = enrollment.email;
          this.firstName = enrollment.firstName;
          this.lastName = enrollment.lastName;
          this.enrollmentSentOn = enrollment.sentOn;
          this.mobilePhoneNumber = enrollment.mobilePhoneNumber;
          this.isLoading = false;
        },
        () =>
        {
          this.isGuidValid = false;
          this.isLoading = false;
        });
  }

  // convenience getter for easy access to form fields
  get f()
  {
    return this.activationForm.controls;
  }

  onSubmit()
  {
    if (this.activationForm.invalid)
    {
      return;
    }

    this.activationService.activate(
      this.email,
      this.guidParameter,
      this.firstName,
      this.lastName,
      this.f.password.value,
      this.mobilePhoneNumber).subscribe(
        result =>
        {
          this.onActivation();
        },
        failure => { });
  }

  onActivation()
  {
    this.router.navigate(['/downloadapp']);
  }

}
