import { BehaviorSubject, Subscription } from 'rxjs';
import { TfMap } from './classes/tf-map.class';
import { TfMapOptions } from './classes/tf-map.options.class';

/**
 * Factory for creating, getting and destroying TF Map Objects.
 */
export class TfMapFactory {
    private static _maps: { [key: string]: TfMap } = {};
    private static _mapLoaded: BehaviorSubject<TfMap> = new BehaviorSubject<TfMap>(null);

    /**
     * Creates a map and adds it to the hash map
     * @param id Unique ID of the map
     * @param opts Options for creating the map
     */
    public static _create(id: string, opts: TfMapOptions): Promise<TfMap> {
        return new Promise<TfMap>((resolve) => {
            if (this._checkID(id) === true) {
                throw new Error('Map with ID = ' + id + ' already exists in system!');
            }
            opts.rotationEnabled = false;
            const map: TfMap = new TfMap(id, opts);
            map.ready().then(() => {
                resolve(map);
                this._maps[id] = map;
                TfMapFactory._mapLoaded.next(map);
            });
        });
    }

    /**
     * Get Map from the hash
     * @param id Unique ID of the Map
     */
    public static _get(id: string): Promise<TfMap> {
        return new Promise<TfMap>((resolve) => {
            if (this._checkID(id) === false) {
                resolve(null);
            } else {
                resolve(this._maps[id]);
            }
        });
    }

    public static _awaitGet(id: string): Promise<TfMap> {
        return new Promise<TfMap>((resolve) => {
            TfMapFactory._get(id).then((testMap) => {
                if (testMap) {
                    resolve(testMap);
                } else {
                    const sub: Subscription = TfMapFactory._mapLoaded.subscribe((map: TfMap) => {
                        if ((map) && (map.id === id)) {
                            resolve(map);
                            sub.unsubscribe();
                        }
                    });
                }
            });
        });
    }

    /**
     * Destroys map an removes it from the hash
     * @param id ID of Map to Destroy
     */
    public static _destroy(id: string): Promise<void> {
        return new Promise<void>((resolve) => {
            if (this._checkID(id) === false) {
                throw new Error('No Map with ID = ' + id + ' exists in system, cannot destroy null, it is impossible!');
            } else {
                this._maps[id].destroy().then(() => {
                    this._maps[id] = undefined;
                    resolve();
                });
            }
        });
    }

    /**
     * Checks to see if id is already in our hashmap,
     * throws an exception if it is.
     * @param id ID to check for uniqueness
     */
    private static _checkID(id: string): boolean {
        if ((!id) || (id === '')) {
            throw new Error('ID is null undefined or empty string!');
        } else if (this._maps[id]) {
            return true;
        }
        return false;
    }

}
