import { Language } from "./enum";

export const getCustomMomentTranslate = (lang: string) =>
{
  switch (lang)
  {
    case Language.fr:
      return frTerms();
    case Language.es:
      return esTerms();
    default:
      return enTerms();
  }
}

const enTerms = () =>
{
  return {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: 'less than 1 min',
      ss: 'less than 1 min',
      m: '1 min',
      mm: '%d mins',
      h: 'an hour',
      hh: '%d hours',
      d: 'a day',
      dd: '%d days',
      M: 'a month',
      MM: '%d months',
      y: 'a year',
      yy: '%d years',
    },
  }
};

const frTerms = () =>
{
  return {
    months: 'Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre'.split('_'),
    monthsShort: 'Jan_Fév_Mar_Avr_Mai_Juin_Juil_Août_Sep_Oct_Nov_Déc'.split('_'),
    weekdays: 'Dimanche_Lundi_Mardi_Mercredi_Jeudi_Vendredi_Samedi'.split('_'),
    weekdaysShort: 'Dim._Lun._Mar._Mer._Jeu._Ven._Sam.'.split('_'),
    weekdaysMin: 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
    relativeTime: {
      future: 'dans %s',
      past: '%s depuis',
      s: 'il y a moins d\'une minute',
      ss: 'il y a moins d\'une minute',
      m: '1 minute',
      mm: '%d minutes',
      h: 'une heure',
      hh: '%d heures',
      d: 'un jour',
      dd: '%d jours',
      w: 'une semaine',
      ww: '%d semaines',
      M: 'un mois',
      MM: '%d mois',
      y: 'un an',
      yy: '%d ans',
    },
  };
};

const esTerms = () =>
{
  return {
    months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
    monthsShort: 'Ene._Feb._Mar._Abr._May._Jun._Jul._Ago._Sep._Oct._Nov._Dic.'.split('_'),
    weekdays: 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
    weekdaysShort: 'Dom._Lun._Mar._Mié._Jue._Vie._Sáb.'.split('_'),
    weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sá'.split('_'),
    relativeTime: {
      future: 'en %s',
      past: '%s hace',
      s: 'menos de 1 min.',
      ss: 'menos de 1 min.',
      m: '1 minuto',
      mm: '%d minutos',
      h: 'una hora',
      hh: '%d horas',
      d: 'un día',
      dd: '%d días',
      w: 'una semana',
      ww: '%d semanas',
      M: 'un mes',
      MM: '%d meses',
      y: 'un año',
      yy: '%d años',
    },
  };
};
