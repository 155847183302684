import { Directive } from '@angular/core';
import { StopfinderApiService } from '../../../shared/stopfinder/stopfinder-api.service';
import { Subscriber } from '../../../shared/stopfinder/stopfinder-models';
import { StateService } from '../../service/state/state.service';

// Reference component
import { MatDialog } from '@angular/material';
import { ChangePasswordComponent } from '../../../settings/profile/change-password/change-password.component';

@Directive({
  selector: '[validatePasswordStatus]'
})
export class ValidateResetPasswordDirective {
  // store login subscriber for reference
  private loginSubscriber: Subscriber = null;

  constructor(
    private apiService: StopfinderApiService,
    private stateService: StateService,
    private dialog: MatDialog,
  ) {
    this.validatePasswordStatus().then(
      (shouldPromptReset: boolean) => {
        shouldPromptReset && this.promptRestDialog();
      }
    );
  }

  /**
   * Function to validate subscriber password status as required.
   * Once resetPassword return false, should prompt dialog to force reset password
   */
  private validatePasswordStatus(): Promise<boolean> {
    return this.apiService.getCurrentSubscriber().toPromise().then(
      (getSubscriber: Subscriber) => {
        this.loginSubscriber = getSubscriber;
        this.stateService.subscriber = getSubscriber;
        return getSubscriber && getSubscriber.resetPassword;
      }
    );
  }

  /**
   * Private function to close all existing dialogs.
   */
  private closeAllDialogs(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.dialog.closeAll();
      resolve(true);
    });
  }

  /**
   * Function to prompt reset password dialog.
   * Reuse component 'ChangePasswordComponent', this component been used in profile setting page.
   */
  private promptRestDialog(): void {
    // force close all dialogs
    this.closeAllDialogs().then((status: boolean) => {
      status && this.dialog.open(ChangePasswordComponent, {
        disableClose: true
      }).afterClosed().subscribe(
        () => {
          if (!ChangePasswordComponent.changePasswordResponse) {
            this.stateService.goRoute('login');
          }
        }
      );
    });
  }
}
