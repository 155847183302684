import { BaseHybridVectorMapGenerator } from '../classes/basemap.base';
import { eBaseMaps, BaseMapThumbnailEnum } from '../classes/basemap.enum';

export class ImageryLabelMap extends BaseHybridVectorMapGenerator
{
    constructor()
    {
        super();
    }

    public get thumbnail(): string { return './assets/images/Imagery-With-Labels.jpg'; }
    public get title(): string { return 'Imagery with Labels'; }
    public get jsonUrl(): string { return './assets/vectors/hybrid_english.json'; }
    public get name(): string { return 'satellite'; }
    public get allowRotation(): boolean { return true; }
    public get maxZoom(): number { return 17; }
    public get maxNativeZoom(): number { return 16; }
    public get type(): eBaseMaps { return eBaseMaps.ImageryWithLabels; }
    public get translateKey(): BaseMapThumbnailEnum { return BaseMapThumbnailEnum.ImageryWithLabels; }
}
