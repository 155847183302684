import * as _ from 'lodash';
import { Component, OnInit, Inject, OnDestroy, NgZone, ViewEncapsulation } from '@angular/core';
import { MatBottomSheet, MatDialog } from '@angular/material';
import { AnimationEvent } from '@angular/animations';
import { Communication, CommunicationType } from '../../shared/stopfinder/stopfinder-models';
import { MessagesService } from '../messages.service';
import { MapService } from 'src/app/components/service/map/map.service';
import { PathLineService } from 'src/app/components/service/path-line/path-line.service';
import { TooltipService } from '../../components/service/tooltip/tooltip.service';
import { Router } from '@angular/router';
import { ToastData, TOAST_CONFIG_TOKEN, ToastConfig, defaultToastConfig } from '../../shared/toast/toast-config';
import { ToastRef } from 'src/app/shared/toast/toast-ref';
import { ToastAnimationState, toastSlideAnimations } from '../../shared/toast/toast-animation';
import { ScheduleService } from 'src/app/schedule/schedule.service';
import { StateService } from 'src/app/components/service/state/state.service';
import { StopfinderDateTimeService } from 'src/app/shared/stopfinder/stopfinder-datetime.service';

@Component({
  selector: 'app-push-snackbar',
  templateUrl: './push-snackbar.component.html',
  styleUrls: ['./push-snackbar.component.scss'],
  animations: [toastSlideAnimations.slideInToast],
  encapsulation: ViewEncapsulation.None,
})
export class PushSnackbarComponent implements OnInit, OnDestroy
{
  public referCommunication: Communication;
  public communication: Communication;
  public animationState: ToastAnimationState = 'default';
  private intervalId: any;
  private duration: number;

  private uniqueId = _.uniqueId(`notification_`);

  constructor(
    private readonly bottomSheet: MatBottomSheet,
    private readonly dateTimeService: StopfinderDateTimeService,
    private readonly dialog: MatDialog,
    private mapService: MapService,
    private ngZone: NgZone,
    private pathLineService: PathLineService,
    private scheduleService: ScheduleService,
    private readonly stateService: StateService,
    private readonly tooltipService: TooltipService,
    public messageService: MessagesService,
    public readonly ref: ToastRef,
    public router: Router,
    public readonly toastData: ToastData,
    @Inject(TOAST_CONFIG_TOKEN) public toastConfig: ToastConfig,
  )
  {
    this.toastConfig = defaultToastConfig;
    this.communication = this.toastData.data.communication;
    this.referCommunication = { ...this.communication };
    this.referCommunication.subject = this.communication.subject.replace(/(<([^>]+)>)/ig, "");
    this.referCommunication.body = this.communication.body.replace(/(<([^>]+)>)/ig, "");
    this.duration = this.toastData.duration || 5000;
  }

  ngOnInit()
  {
    this.intervalId = setTimeout(() => this.animationState = 'closing', this.duration);
  }

  ngOnDestroy()
  {
    clearTimeout(this.intervalId);
  }

  async openCommunication()
  {
    this.stateService.setCallbackId(this.uniqueId);
    this.stateService.setNotificationEvent(this.afterValidation.bind(this));
    // keep this validation before open snack bar
    // before open action will close all none related reference before we goto next step
    this.beforeOpenAction();

    if (_.isNull(this.stateService.externalValidationEvent))
    {
      this.afterValidation();
    }
  }

  private async afterValidation()
  {
    const appService = this.toastData.data.appService;
    // geo alert notification not detail page, if add the jump logic, update here
    if (this.communication.type === CommunicationType.GeoAlert || this.communication.type === CommunicationType.EtaAlert)
    {
      const studentSchedule = await this.scheduleService.getStudentScheduleWithNotification(this.communication);

      if (this.mapService.isGeoAlertEditing)
      {
        this.mapService.turnOffGeoAddMode();
        await this.mapService.turnOffGeoMode();
        this.mapService.resetViewSize(appService.windowWidth, appService.windowHeight);
      }

      return studentSchedule && this.mapService.displayGeoAlertMapOnClickNotification(
        studentSchedule,
        appService,
        this.pathLineService,
        true,
        this.communication.tripId
      );
    }

    if (this.communication.type === CommunicationType.Attendance)
    {
      const studentSchedule = await this.scheduleService.getStudentScheduleWithNotification(this.communication);
      const timeZoneMinutes = this.mapService.getScheduleTimeZone(studentSchedule);
      this.communication.attendance.scannedDate = this.dateTimeService.toUtcDate(this.communication.attendance.scannedDate, timeZoneMinutes).format(this.dateTimeService.formatDate);

      return studentSchedule && this.mapService.displayAttendanceMap(
        studentSchedule,
        appService,
        this.communication.attendance,
        true);
    }

    appService.showMap && appService.setMapVisibility(false);

    if (this.communication.type === CommunicationType.Form)
    {
      this.router.navigate([`formQuestion/${this.communication.id}/${this.communication.formRecipientId}`]);
    }
    else
    {
      // else go to message / announcement notification
      this.messageService.selectedCommunication = this.communication;
      this.messageService.notificationCommunication.next(this.communication);
      this.messageService.markCommunicationReadStatus(this.communication, true);
      this.communication.read = true;
      this.router.navigate(['message-detail']);
    }
  }

  private beforeOpenAction()
  {
    // turn off bottom sheet
    this.bottomSheet && this.bottomSheet._openedBottomSheetRef && this.bottomSheet._openedBottomSheetRef.dismiss();

    this.dialogRefValidation();

    this.stateValidation();
    // turn off tooltip if it is opened
    this.tooltipService.tooltip && this.tooltipService.getTooltipStatus() && this.tooltipService.closeAll();

    // remove hide map class
    document.querySelector("#app-layout") && document.querySelector("#app-layout").classList.remove("mustHiddenMap");
  }

  private dialogRefValidation()
  {
    if (this.dialog && this.dialog.openDialogs.length)
    {
      this.dialog.closeAll();
      this.dialog.afterAllClosed.subscribe(() =>
      {
        // there will have more actions for closing confirm dialog or other window
      });
    }
  }

  private stateValidation()
  {
    if (this.stateService.blockStateChange.length)
    {
      this.stateService.triggerExternalValidation();
    }
  }

  public close()
  {
    this.ngZone.run(() =>
    {
      this.ref.close();
    });
  }

  public slideOut()
  {
    this.ngZone.run(() =>
    {
      this.animationState = 'closing';
    });
  }

  public onAnimationFinished(event: AnimationEvent)
  {
    const { toState } = event;
    const isSlideOut = _.isEqual((toState as ToastAnimationState), `closing`);
    const itFinished = _.isEqual(this.animationState, `closing`);

    isSlideOut && itFinished && this.close();
  }
}
