import * as _ from 'lodash';
import { Component, OnInit, AfterViewInit, NgZone, OnDestroy, AfterViewChecked, ElementRef } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { StopfinderApiService } from '../../shared/stopfinder/stopfinder-api.service';
import
{
  MessageThread,
  Message,
  Communication,
  CommunicationType,
  Document,
  DocumentStorage,
  MessageDocument
} from '../../shared/stopfinder/stopfinder-models';
import
{
  MatBottomSheet,
  MatBottomSheetRef,
  MatDialog,
} from '@angular/material';
import { ConfirmationDialogComponent } from '../../shared/layout/confirmation-dialog/confirmation-dialog.component';
import { TargetedBlockingScrollStrategy } from '../../shared/material/targeted-blocking-scroll-strategy';
import { MessagesService } from '../messages.service';
import { MenuOption } from '../../shared/layout/header/header.component';
import { AppService } from '../../app.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { tap, flatMap, map } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';
import { StateService, StateEnum } from '../../components/service/state/state.service';
import { AndroidBackService } from '../../androidBack.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from 'src/app/components/service/device/device.service';
import { generatePreviewImage } from 'src/app/shared/utils/utils';

export enum MessageActions
{
  Archive,
  Restore,
  MarkUnread,
  MarkRead
}

@Component({
  selector: 'app-message-detail',
  templateUrl: './message-detail.component.html',
  styleUrls: ['./message-detail.component.scss']
})
export class MessageDetailComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked
{
  public communicationType = CommunicationType;
  public currentMessage = '';
  public communication: Communication = null;
  public messagesObservable: Observable<Array<Message>>;
  public announcementsSendDocumentsObservable: Observable<Array<Document>>;
  public maxRows = 6;
  public expand = false;
  public raise = false;
  public options: MenuOption[] = [];
  public attachmentsBehaviorSubject: BehaviorSubject<Array<Document>> = new BehaviorSubject<Array<Document>>([]);
  public attachments = new Array<Document>();
  public messageDocument = new Array<MessageDocument>();
  public moment = moment;
  public sizeLimit = 20000000;
  public currentSize = 0;
  public src;
  public documentTransferring = false;
  public documentProgress = 0;
  private textareaDom: any;
  private textareaRaiseDom: any;

  private readonly defaultTextareaClass: string = 'textarea-expand';
  private readonly defaultTextareaRaiseClass: string = 'textarea-raise-normal';
  private readonly iPhoneXTextareaRaiseClass: string = 'textarea-raise-iphoneX';
  private readonly iPhoneXTextareaRaiseAttachmentClass: string = 'textarea-raise-iphoneX-attachment';
  private readonly defaultTextareaRaisedClass: string = 'textarea-raised-normal';
  private readonly iPhoneXTextareaRaisedClass: string = 'textarea-raised-iphoneX';
  private readonly iPhoneXTextareaRaisedAttachmentClass: string = 'textarea-raised-iphoneX-attachment';
  private readonly defaultTextareaExpandedClass: string = 'textarea-expanded-normal';
  private readonly iphoneTextareaExpandedClass: string = 'textarea-expanded-iphone';
  private readonly iphoneXTextareaExpandedClass: string = 'textarea-expanded-iphoneX';
  private readonly defaultTextareaExpandedAttachmentClass: string = 'textarea-expanded-normal-attachment';
  private readonly iphoneTextareaExpandedAttachmentClass: string = 'textarea-expanded-iphone-attachment';
  private readonly iphoneXTextareaExpandedAttachmentClass: string = 'textarea-expanded-iphoneX-attachment';
  private textareaRaiseClass = '';
  private textareaRaiseExpandClass = '';
  private textareaExpandedClass = '';

  constructor(
    private readonly stopfinderApi: StopfinderApiService,
    private readonly messagesService: MessagesService,
    private readonly bottomSheet: MatBottomSheet,
    private readonly dialogRef: MatDialog,
    readonly appService: AppService,
    private readonly domSanitizer: DomSanitizer,
    private readonly ngZone: NgZone,
    private readonly stateService: StateService,
    private readonly androidBackService: AndroidBackService,
    public translate: TranslateService,
    public readonly deviceService: DeviceService,
  )
  {

    this.moment.locale(this.translate.currentLang);
    this.communication = messagesService.selectedCommunication;
    this.messagesObservable = messagesService.threadMessagesObservable.pipe(tap((messages) =>
    {
      messages.forEach((message) =>
      {
        message.showName = message.sentBy ? message.organizationName : this.getTranslatedValue('You');
        message.showTime = message.sentOn ? this.moment(message.sentOn).format('h:mm a') : '';
        message.messageDocument.forEach((attachment: MessageDocument) =>
        {
          if (attachment)
          {
            if (attachment.document.previewEncodedBytes)
            {
              attachment.document.previewLink = this.domSanitizer.bypassSecurityTrustResourceUrl(`data:${attachment.document.mimeType};base64, ${attachment.document.previewEncodedBytes}`);
            }
            attachment.document.displaySize = attachment.document.size > 1000000 ? `${(attachment.document.size / 1000000).toFixed(2)}MB` : (attachment.document.size / 1000).toFixed(2) + 'KB';
          }
        });
      });

      // wait the page render complete and scroll to the last message
      setTimeout(() =>
      {
        if (this.messagesService.needScrollToLastMessage && messages.length > 0)
        {
          this.scrollToLastMessage(this.communication.type);
          this.messagesService.needScrollToLastMessage = false;
        }
      });
    }));

    this.announcementsSendDocumentsObservable = messagesService.announcementSendDocumentsObservable.pipe(tap((documents) =>
    {
      documents.forEach((attachment: Document) =>
      {
        if (attachment)
        {
          if (attachment.previewEncodedBytes)
          {
            attachment.previewLink = this.domSanitizer.bypassSecurityTrustResourceUrl(`data:${attachment.mimeType};base64, ${attachment.previewEncodedBytes}`);
          }
          attachment.displaySize = attachment.size > 1000000 ? `${(attachment.size / 1000000).toFixed(2)}MB` : (attachment.size / 1000).toFixed(2) + 'KB';
        }
      });

      // wait the page render complete and scroll to the last message
      setTimeout(() =>
      {
        this.scrollToLastMessage(this.communication.type);
      });
    }));

    this.messagesService.notificationCommunicationObservable.subscribe((communication: Communication) =>
    {
      if (communication)
      {
        this.communication = communication;
        this.initialEvents();
        this.currentMessage = ``;
      }
    });
  }


  getTranslatedValue(value)
  {
    var returnValue = value.toString();
    switch (returnValue)
    {
      case 'You':
        returnValue = this.translate.instant("message.you");
        break;
      case 'One or more attachments has a file type that is not allowed':
        returnValue = this.translate.instant("message.one.or.more.attachments.file.type.not.allowed");
        break;
      default:
        returnValue = returnValue;
        break;
    }
    return returnValue;
  }

  ngAfterViewChecked()
  {
  }

  ngAfterViewInit()
  {
    this.textareaDom = document.querySelector("textarea.no-tap-highlight");
    this.textareaRaiseDom = document.querySelector("mat-form-field.send-message-formfield");

    if (this.textareaDom)
    {
      this.textareaDom.classList.add(this.defaultTextareaClass);
    }
    if (this.textareaRaiseDom)
    {
      if (this.deviceService.isiPhoneX)
      {
        this.textareaRaiseClass = this.iPhoneXTextareaRaiseClass;
        this.textareaRaiseDom.classList.add(this.textareaRaiseClass);
      }
      else
      {
        this.textareaRaiseClass = this.defaultTextareaRaiseClass;
        this.textareaRaiseDom.classList.add(this.textareaRaiseClass);
      }
    }

    window.addEventListener('keyboardDidHide', this.updateTextarea.bind(this));

    this.attachmentsBehaviorSubject.subscribe(attachmentList =>
    {
      this.handleAnimationsAfterAttachmentsChange(attachmentList.length > 0);
      this.attachments = attachmentList;
    });

    this.initialAnimationClasses();
  }

  private updateTextarea()
  {
    if (!this.textareaDom)
    {
      this.textareaDom = document.querySelector("textarea.no-tap-highlight");
    }
    this.textareaDom.style.height = null;
    setTimeout(() =>
    {
      this.textareaDom.style.height = null;
    }, 300);
  }

  initialAnimationClasses()
  {
    if (this.deviceService.isiPhoneX)
    {
      this.initialIphoneXAnimations();
    } else
    {
      if (this.deviceService.isiOS)
      {
        this.initialIphoneAnimations();
      } else
      {
        this.initialNormalAnimations();
      }
    }
  }

  initialNormalAnimations()
  {
    if (this.textareaRaiseExpandClass === '')
    {
      this.textareaRaiseExpandClass = this.defaultTextareaRaisedClass;
    }
    if (this.textareaExpandedClass === '')
    {
      this.textareaExpandedClass = this.defaultTextareaExpandedClass;
    }
  }

  initialIphoneAnimations()
  {
    if (this.textareaRaiseExpandClass === '')
    {
      this.textareaRaiseExpandClass = this.defaultTextareaRaisedClass;
    }
    if (this.textareaExpandedClass === '')
    {
      this.textareaExpandedClass = this.iphoneTextareaExpandedClass;
    }
  }

  initialIphoneXAnimations()
  {
    if (this.textareaRaiseExpandClass === '')
    {
      this.textareaRaiseExpandClass = this.iPhoneXTextareaRaisedClass;
    }
    if (this.textareaExpandedClass === '')
    {
      this.textareaExpandedClass = this.iphoneXTextareaExpandedClass;
    }
  }

  handleAnimations()
  {
    this.animationHandler();
  }

  animationHandler()
  {
    if (this.textareaDom)
    {
      if (!this.expand)
      {
        this.textareaDom.classList.replace(this.defaultTextareaClass, this.textareaExpandedClass);
        setTimeout(() =>
        {
          this.textareaDom.style.height = null;
        }, 300);
      } else
      {
        this.textareaDom.style.height = null;
        this.textareaDom.classList.replace(this.textareaExpandedClass, this.defaultTextareaClass);
      }
    }

    if (this.textareaRaiseDom)
    {
      if (!this.expand)
      {
        this.textareaRaiseDom.classList.replace(this.textareaRaiseClass, this.textareaRaiseExpandClass);
      } else
      {
        this.textareaRaiseDom.classList.replace(this.textareaRaiseExpandClass, this.textareaRaiseClass);
      }
    }
  }

  changeCurrentAttachment(attachmentList)
  {
    this.attachmentsBehaviorSubject.next(attachmentList);
  }

  handleAnimationsAfterAttachmentsChange(hasAttachment: boolean)
  {
    this.handleTextareaRaiseDomWithAttachment(hasAttachment);
    this.handleTextareaDomWithAttachment(hasAttachment);
  }

  handleTextareaRaiseDomWithAttachment(hasAttachment: boolean)
  {
    // handle text areas raise position base on attachment when iphoneX
    if (this.textareaRaiseDom && this.deviceService.isiPhoneX)
    {
      if (this.expand)
      {
        if (hasAttachment)
        {
          if (this.textareaRaiseExpandClass === this.iPhoneXTextareaRaisedClass)
          {
            this.textareaRaiseDom.classList.replace(this.iPhoneXTextareaRaisedClass, this.iPhoneXTextareaRaisedAttachmentClass);
            this.textareaRaiseExpandClass = this.iPhoneXTextareaRaisedAttachmentClass;
            this.textareaRaiseClass = this.iPhoneXTextareaRaiseAttachmentClass;
          }
        }
        else
        {
          if (this.textareaRaiseExpandClass === this.iPhoneXTextareaRaisedAttachmentClass)
          {
            this.textareaRaiseDom.classList.replace(this.iPhoneXTextareaRaisedAttachmentClass, this.iPhoneXTextareaRaisedClass);
            this.textareaRaiseExpandClass = this.iPhoneXTextareaRaisedClass;
            this.textareaRaiseClass = this.iPhoneXTextareaRaiseClass;
          }
        }
      }
      else
      {
        if (hasAttachment)
        {
          if (this.textareaRaiseClass === this.iPhoneXTextareaRaiseClass)
          {
            this.textareaRaiseDom.classList.replace(this.iPhoneXTextareaRaiseClass, this.iPhoneXTextareaRaiseAttachmentClass);
            this.textareaRaiseClass = this.iPhoneXTextareaRaiseAttachmentClass;
            this.textareaRaiseExpandClass = this.iPhoneXTextareaRaisedAttachmentClass;
          }
        }
        else
        {
          if (this.textareaRaiseClass === this.iPhoneXTextareaRaiseAttachmentClass)
          {
            this.textareaRaiseDom.classList.replace(this.iPhoneXTextareaRaiseAttachmentClass, this.iPhoneXTextareaRaiseClass);
            this.textareaRaiseClass = this.iPhoneXTextareaRaiseClass;
            this.textareaRaiseExpandClass = this.iPhoneXTextareaRaisedClass;
          }
        }
      }
    }
  }

  handleTextareaDomWithAttachment(hasAttachment: boolean)
  {
    if (this.textareaDom)
    {
      if (hasAttachment)
      {
        switch (this.textareaExpandedClass)
        {
          case this.defaultTextareaExpandedClass:
            this.textareaDom.classList.replace(this.defaultTextareaExpandedClass, this.defaultTextareaExpandedAttachmentClass);
            this.textareaExpandedClass = this.defaultTextareaExpandedAttachmentClass;
            break;
          case this.iphoneTextareaExpandedClass:
            this.textareaDom.classList.replace(this.iphoneTextareaExpandedClass, this.iphoneTextareaExpandedAttachmentClass);
            this.textareaExpandedClass = this.iphoneTextareaExpandedAttachmentClass;
            break;
          case this.iphoneXTextareaExpandedClass:
            this.textareaDom.classList.replace(this.iphoneXTextareaExpandedClass, this.iphoneXTextareaExpandedAttachmentClass);
            this.textareaExpandedClass = this.iphoneXTextareaExpandedAttachmentClass;
            break;
          default:
            break;
        }
      }
      else
      {
        switch (this.textareaExpandedClass)
        {
          case this.defaultTextareaExpandedAttachmentClass:
            this.textareaDom.classList.replace(this.defaultTextareaExpandedAttachmentClass, this.defaultTextareaExpandedClass);
            this.textareaExpandedClass = this.defaultTextareaExpandedClass;
            break;
          case this.iphoneTextareaExpandedAttachmentClass:
            this.textareaDom.classList.replace(this.iphoneTextareaExpandedAttachmentClass, this.iphoneTextareaExpandedClass);
            this.textareaExpandedClass = this.iphoneTextareaExpandedClass;
            break;
          case this.iphoneXTextareaExpandedAttachmentClass:
            this.textareaDom.classList.replace(this.iphoneXTextareaExpandedAttachmentClass, this.iphoneXTextareaExpandedClass);
            this.textareaExpandedClass = this.iphoneXTextareaExpandedClass;
            break;
          default:
            break;
        }
      }
    }
  }

  scrollToLastMessage(messageType: CommunicationType)
  {
    const messageContainer = document.querySelector('#main-content-area');
    if (!messageContainer)
    {
      return;
    }

    if (messageType === CommunicationType.Announcement)
    {
      messageContainer.scrollTop = 0;
      return;
    }

    if (messageType === CommunicationType.Message)
    {
      const lastMessageDom = document.querySelector('.message-thread-history .thread-message:last-child') as HTMLElement;
      messageContainer.scrollTop = (lastMessageDom && lastMessageDom.offsetTop) || 0
    }
  }

  ngOnDestroy()
  {
    const sendMessageForm = document.querySelector(".send-message-form");
    !_.isNull(sendMessageForm) && sendMessageForm.classList.add('destroy-form');
    this.appService.lastLocation = '';
    window.removeEventListener('keyboardDidHide', this.updateTextarea);
    this.closeUploadDocLoadingIndicator();
    this.androidBackService.onDestroyCallback();
    this.stateService.destroyStateChange();
    this.messagesService.notificationCommunication.next(null);
  }

  ngOnInit()
  {
    if (!this.communication)
    {
      this.stateService.goRoute('messages');
      return;
    }

    this.options = [
      {
        text: this.communication.archived ? this.translate.instant("message.restore") : this.translate.instant("message.archive"),
        action: this.communication.archived ? MessageActions.Restore : MessageActions.Archive
      },
      {
        text: this.translate.instant("message.mark.as.unread"),
        action: MessageActions.MarkUnread
      }
    ];

    this.initialEvents();

    this.androidBackService.onShouldCheckCallback(this.cancel.bind(this));
    this.stateService.disableCallback();
    this.stateService.setExternalValidation(this.cancel.bind(this));
  }

  private initialEvents()
  {
    if (this.communication.type == CommunicationType.Message)
    {
      // get thread messages
      this.messagesService.getThreadMessages(this.communication.id);
    }

    if (this.communication.type === CommunicationType.Announcement)
    {
      // get announcement send document
      this.messagesService.getAnnouncementSendDocuments(this.communication.id);
    }
  }

  openUploadDocLoadingIndicator()
  {
    this.documentTransferring = true;
    this.documentProgress = 0;
  }

  updateUploadProgress(progress = 0)
  {
    this.documentProgress = progress;
  }

  closeUploadDocLoadingIndicator()
  {
    this.documentTransferring = false;
    this.documentProgress = 0;
  }

  handleMenuAction(action)
  {
    let obsData: Observable<any>;
    switch (action.action)
    {
      case MessageActions.Archive:
        obsData = this.messagesService.markCommunicationArchiveStatus(this.communication, true);
        this.communication.archived = true;
        break;
      case MessageActions.MarkRead:
        obsData = this.messagesService.markCommunicationReadStatus(this.communication, true);
        this.communication.read = true;
        break;
      case MessageActions.MarkUnread:
        obsData = this.messagesService.markCommunicationReadStatus(this.communication, false);
        this.communication.read = false;
        break;
      case MessageActions.Restore:
        obsData = this.messagesService.markCommunicationArchiveStatus(this.communication, false);
        this.communication.archived = false;
        break;
    }

    obsData.subscribe((data) =>
    {
      if (data)
      {
        this.stateService.goRoute('messages');
      }
    })
  }

  scrollToTop()
  {
    const targets = document.getElementsByClassName('scroll-strategy') as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < targets.length; i++)
    {
      targets[i].scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  minimizeTextarea(e: MouseEvent)
  {
    if (this.expand)
    {
      this.toggleTextArea(e);
    }
  }

  toggleTextArea(e: MouseEvent)
  {
    e.stopPropagation();
    if (this.expand)
    {
      this.maxRows = 100;
      this.handleAnimations();
      setTimeout(() =>
      {
        this.scrollToLastMessage(this.communication.type);
      });
    } else
    {
      this.maxRows = 6;
      this.handleAnimations();
    }
    this.expand = !this.expand;
    this.raise = !this.raise;
  }

  cancel(elRef?: ElementRef)
  {
    this.ngZone.run(() =>
    {
      if (this.currentMessage || this.attachments.length > 0)
      {
        this.androidBackService.onDisableMultipleTarget(true);
        this.dialogRef
          .open(ConfirmationDialogComponent, {
            disableClose: false,
            data: {
              title: this.translate.instant("message.cancel"),
              message: this.translate.instant("message.are.you.sure.you.want.to.cancel"),
              action: this.translate.instant("message.yes"),
              secondary: true,
              secondaryAction: this.translate.instant("message.no")
            },
            scrollStrategy: new TargetedBlockingScrollStrategy(),
            panelClass: "confirm-dialog"
          })
          .afterClosed()
          .subscribe(response =>
          {
            this.androidBackService.onDisableMultipleTarget(false);
            if (response)
            {
              this.stateService.handleBlockStateCallback(true) && this.back();
            }
            this.stateService.disableCallback();
          });
      } else
      {
        this.stateService.handleBlockStateCallback(false) && this.back();
      }
    });
  }

  back()
  {
    this.stateService.goRoute(`${this.appService.lastLocation}`);
  }

  sendMessage(e: MouseEvent)
  {
    e.stopPropagation();
    (document.activeElement as any).blur();

    if (!this.currentMessage)
    {
      return;
    }

    const messageSubject = this.communication.subject;
    const messageBody = this.currentMessage;

    if (this.communication.id)
    {
      let total = 1;
      const newMessage = {
        sentBy: 0,
        sentOn: new Date().toISOString().replace("Z", ""),
        messageThreadId: this.communication.id,
        body: messageBody,
        messageDocument: this.messageDocument,
      } as Message;
      const newMessageForDisplay = {
        sentBy: 0,
        sentOn: moment().format("YYYY-MM-DDTHH:mm:ss"),
        messageThreadId: this.communication.id,
        body: messageBody,
        messageDocument: this.messageDocument,
      } as Message;
      this.messagesService.addTemporaryMessage(newMessageForDisplay);
      if (this.attachments.length > 0)
      {
        this.openUploadDocLoadingIndicator();
        this.attachments.forEach(attachment => total += attachment.size);
      }
      this.stopfinderApi
        .postMessage(newMessage)
        .subscribe(event =>
        {
          if (this.attachments.length > 0)
          {
            switch (event.type)
            {
              case HttpEventType.Sent:
                break;
              case HttpEventType.ResponseHeader:
                break;
              case HttpEventType.UploadProgress:
                this.updateUploadProgress(Math.round(100 * event.loaded / (total * 1.33)));
                break;
              case HttpEventType.User:
                break;
              case HttpEventType.DownloadProgress:
                break;
              case HttpEventType.Response:
                setTimeout(() =>
                {
                  this.closeUploadDocLoadingIndicator();
                  this.messagesService.getThreadMessages(event.body.messageThreadId);
                  this.minimizeTextarea(e);
                }, 500);
                this.currentSize = 0;

                // this.attachments = [];
                this.changeCurrentAttachment([]);
                this.messageDocument = [];
                break;
              default:
                break;
            }
          } else
          {
            if (event.type === HttpEventType.Response)
            {
              this.messagesService.getThreadMessages(event.body.messageThreadId);
              this.minimizeTextarea(e);
            }
          }
        }, error =>
        {
          this.messagesService.getThreadMessages(this.communication.id);
          this.currentSize = 0;

          // this.attachments = [];
          this.changeCurrentAttachment([]);
          this.closeUploadDocLoadingIndicator();
          this.messageDocument = [];
          this.dialogRef.open(ConfirmationDialogComponent, {
            data: {
              title: this.translate.instant("message.unable.to.send.message"),
              message: this.getTranslatedValue(error.error),
              action: this.translate.instant("message.ok")
            },
            scrollStrategy: new TargetedBlockingScrollStrategy(),
            panelClass: "confirm-dialog"
          });
        });
    } else
    {
      this.stopfinderApi
        .postMessageThread({
          sentBy: 0,
          sentOn: new Date().toISOString().replace("Z", ""),
          read: true,
          archived: false,
          status: 0,
          riderId: this.messagesService.selectedCommunication.riderId,
          dataSourceId: this.messagesService.selectedCommunication.dataSourceId,
        } as MessageThread)
        .subscribe(messageThread =>
        {
          // TODO: Need filled 'for display' MessageThread
          this.communication = this.messagesService.getCommunicationFromMessageThread(messageThread);
          this.communication.subject = messageSubject;
          this.messagesService.selectedCommunication = this.communication;
          let total = 1;
          if (this.attachments.length > 0)
          {
            this.attachments.forEach(attachment => total += attachment.size);
            this.openUploadDocLoadingIndicator();
          }
          const newMessage = {
            sentBy: 0,
            sentOn: new Date().toISOString().replace("Z", ""),
            messageThreadId: messageThread.id,
            body: messageBody,
            messageDocument: this.messageDocument,
          } as Message;
          const newMessageForDisplay = {
            sentBy: 0,
            sentOn: moment().format("YYYY-MM-DDTHH:mm:ss"),
            messageThreadId: messageThread.id,
            body: messageBody,
            messageDocument: this.messageDocument,
          } as Message;
          this.messagesService.addTemporaryMessage(newMessageForDisplay);
          this.stopfinderApi
            .postMessage(newMessage)
            .subscribe(event =>
            {
              if (this.attachments.length > 0)
              {
                switch (event.type)
                {
                  case HttpEventType.Sent:
                    break;
                  case HttpEventType.ResponseHeader:
                    break;
                  case HttpEventType.UploadProgress:
                    this.updateUploadProgress(Math.round(100 * event.loaded / (total * 1.33)));
                    break;
                  case HttpEventType.Response:
                    setTimeout(() =>
                    {
                      this.closeUploadDocLoadingIndicator();
                      this.messagesService.getThreadMessages(event.body.messageThreadId);
                      this.minimizeTextarea(e);
                    }, 500);
                    this.currentSize = 0;
                    // this.attachments = [];
                    this.changeCurrentAttachment([]);
                    this.messageDocument = [];
                    break;
                  default:
                    break;
                }
              } else
              {
                if (event.type === HttpEventType.Response)
                {
                  this.messagesService.getThreadMessages(event.body.messageThreadId);
                  this.minimizeTextarea(e);
                }
              }
            }, error =>
            {
              this.messagesService.getThreadMessages(messageThread.id);
              this.currentSize = 0;

              // this.attachments = [];
              this.changeCurrentAttachment([]);
              this.closeUploadDocLoadingIndicator();
              this.messageDocument = [];
              this.dialogRef.open(ConfirmationDialogComponent, {
                data: {
                  title: this.translate.instant("message.unable.to.send.message"),
                  message: error.error,
                  action: this.translate.instant("message.ok")
                },
                scrollStrategy: new TargetedBlockingScrollStrategy(),
                panelClass: "confirm-dialog"
              });
            });
        });
    }
    // clear input
    this.currentMessage = '';
  }

  openAttachmentBottomSheet()
  {
    (document.getElementById('paperclip') as any).focus();
    if (this.deviceService.isBrowser)
    {
      return;
    }
    this.bottomSheet.open(AddAttachmentBottomSheetComponent);
    this.bottomSheet._openedBottomSheetRef.afterDismissed().pipe(
      flatMap((files) =>
      {
        return this.createDocuments(files).pipe(map((documents) =>
        {
          if (documents)
          {
            this.documentsHandler(documents);
          }
        }));
      })).subscribe();
  }

  addMessageAttachment(e: MouseEvent)
  {
    e.stopPropagation();
    document.body.focus();
    this.uploadDocuments((e as any).target.files);
  }

  uploadDocuments(files)
  {
    this.createDocuments(files).subscribe((documents) =>
    {
      if (documents)
      {
        this.documentsHandler(documents);
      }
    })
  }

  documentsHandler(documents: Document[])
  {
    documents.forEach((data: Document) =>
    {
      if (this.currentSize + data.size > this.sizeLimit)
      {
        this.dialogRef.open(ConfirmationDialogComponent, {
          disableClose: false,
          data: {
            title: this.translate.instant("message.attachment.limit.reached"),
            message: this.translate.instant("message.total.size.exceeded") + `${this.sizeLimit / 1000000}MB.`,
            action: this.translate.instant("message.ok"),
          },
          scrollStrategy: new TargetedBlockingScrollStrategy(),
          panelClass: "confirm-dialog"
        });
        return;
      }

      generatePreviewImage(data.mimeType, data.documentStorage[0].encodedBytes).then((preview) =>
      {
        data.previewEncodedBytes = preview as string;
        data.previewEncodedBytes = data.previewEncodedBytes.split(',')[1];
        data.previewMimeType = 'image/png';
        data.previewLink = this.domSanitizer.bypassSecurityTrustResourceUrl(`data:${data.previewMimeType};base64, ${data.previewEncodedBytes}`);
        this.currentSize += data.size;
      });
    });

    // this.attachments = this.attachments.concat(documents);
    this.changeCurrentAttachment(this.attachments.concat(documents));
  }

  createDocuments(files: Array<File>): Observable<Array<Document>>
  {
    if (!files)
    {
      return;
    }
    const documentSubject = new Subject<Array<Document>>();
    const documents = new Array<Document>();
    let processedCount = 0;

    for (const file of files)
    {
      const document = {
        name: file.name,
        filename: file.name,
        mimeType: file.type,
        size: file.size,
        displaySize: file.size > 1000000 ? `${(file.size / 1000000).toFixed(2)}MB` : `${(file.size / 1000).toFixed(2)}KB`,
        documentStorage: []
      } as Document;

      const reader = new FileReader();
      reader.onloadend = () =>
      {
        const dataUrl = reader.result as string;
        document.documentStorage.push({ encodedBytes: dataUrl.split(",")[1] } as DocumentStorage);
        documents.push(document);
        this.messageDocument.push({ messageId: 0, document: document } as MessageDocument);
        if (++processedCount >= files.length) { documentSubject.next(documents); }
      };
      // TODO: Log/Notify of error/abort?
      reader.onerror = () => { if (++processedCount >= files.length) { documentSubject.next(documents); } };
      reader.onabort = () => { if (++processedCount >= files.length) { documentSubject.next(documents); } };

      reader.readAsDataURL(file);
    }

    return documentSubject.asObservable();
  }

  removeMessageAttachment(document)
  {
    if (!this.attachments)
    {
      return;
    }

    this.currentSize -= document.size;

    // this.attachments = this.attachments.filter((element) => {
    //   return element !== document;
    // });
    this.changeCurrentAttachment(this.attachments.filter((element) =>
    {
      return element !== document;
    }));

    this.messageDocument = this.messageDocument.filter((element) =>
    {
      return element.document !== document;
    });
  }
}

@Component({
  selector: 'add-attachment-bottom-sheet',
  template: `
    <div style="text-align: center">
      <h3>{{ 'message.add.message.attachment.from' | translate }}</h3>
    </div>
    <mat-nav-list>
    <label for="capture">
      <a mat-list-item (click)="onClickTakePhoto($event)">
        <mat-icon matListIcon>more_horiz</mat-icon>
        <span mat-line>{{ 'message.photo.library' | translate }}</span>
     </a>
    </label>
      <label for="browse">
        <a mat-list-item (click)="onClickBrowse($event)">
          <mat-icon matListIcon>more_horiz</mat-icon>
          <span mat-line>{{ 'message.browse' | translate }}</span>
        </a>
      </label>
    </mat-nav-list>
  `
})

export class AddAttachmentBottomSheetComponent
{
  constructor(
    private readonly bottomSheetRef: MatBottomSheetRef<AddAttachmentBottomSheetComponent>,
    public appService: AppService,
    public ngZone: NgZone,
    public translate: TranslateService,
  ) { }

  onClickTakePhoto(event: any)
  {
    if (this.appService.cameraInstance)
    {
      this.appService.cameraInstance.getPicture((imageString) =>
      {
        (window as any).resolveLocalFileSystemURL(imageString, (fileEntry) =>
        {
          fileEntry.file((file) => this.ngZone.run(() =>
          {
            const fileType = file.type.split('/')[1];
            file.name = `${fileEntry.name.replace(':', '_')}.${fileType}`;
            this.bottomSheetRef.dismiss([file]);
          }), (error) => { });
        });
      }, (error) => { }, this.appService.cameraDefaultOptions);
    }
  }

  async onClickBrowse(event: any)
  {
    const fileChooser = this.appService.fileChooserInstance;
    if (fileChooser)
    {
      const file = await fileChooser.getFile("application/pdf,image/*");
      const imageString = file && file.uri;
      const filename = file && file.name;
      (window as any).resolveLocalFileSystemURL(imageString, (fileEntry) =>
      {
        fileEntry.file((file) => this.ngZone.run(() =>
        {
          file.name = filename;
          this.bottomSheetRef.dismiss([file]);
        }), (error) => { });
      });
    }
  }

  onFileChange(event)
  {
    this.bottomSheetRef.dismiss(event.target.files);
  }
}
