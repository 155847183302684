import * as _ from 'lodash';
import { PipeTransform, Pipe } from '@angular/core';
import { StudentSchedule, TripSchedule } from '../stopfinder/stopfinder-models';

@Pipe({ name: 'formatTripName' })
export class FormatTripNamePipe implements PipeTransform
{
  transform(schedule: StudentSchedule, trip: TripSchedule): string
  {
    return this.tripName(schedule, trip);
  }

  public tripName(schedule: StudentSchedule, trip: TripSchedule): string
  {
    if (trip)
    {
      if (schedule.displayTripAlias && !_.isEmpty(trip.tripAlias))
      {
        return _.trim(trip.tripAlias);
      }
      return _.trim(trip.name);
    }
    return ``;
  }
}
