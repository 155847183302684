import { Injectable } from '@angular/core';
import
{
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import
{
  Observable,
  BehaviorSubject
} from 'rxjs';
import
{
  catchError,
  flatMap,
  take,
  switchMap,
  skip,
  map
} from 'rxjs/operators';
import { AppService } from './app.service';
import { environment } from '../environments/environment';

@Injectable()
export class ApiInterceptor implements HttpInterceptor
{
  private readonly refreshing: BehaviorSubject<boolean>;

  public publicRequestLists: string[] = [
    environment.requireStopfinderApi,
  ];

  constructor(
    private appService: AppService,
  )
  {
    this.refreshing = new BehaviorSubject<boolean>(false);
  }

  private addDefaultHeaders(headers: HttpHeaders): HttpHeaders
  {
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Token', this.appService.token || '');
    headers = headers.append('X-StopfinderApp-Version', environment.appVersion);
    return headers;
  }

  private verifySupportedProducts(supportedProducts: string[], rfApiVersions: string[], sfApiVersion: string)
  {
    this.appService.handleVersionHeaders(supportedProducts, rfApiVersions, sfApiVersion);
  }

  private updateLoginStatus()
  {
    this.appService.updateMessageServiceLogin(!!this.appService.token);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>>
  {
    let apiReq: HttpRequest<any>;
    if (this.publicRequestLists.some(item =>
    {
      return req.url && req.url.includes(item);
    }))
    {
      apiReq = req;
    } else
    {
      apiReq = req.clone({ headers: this.addDefaultHeaders(req.headers) });
    }

    return next.handle(apiReq).pipe(
      map((event: HttpEvent<any>) =>
      {
        const neededHeaders = [];
        let sfApiVersion = '';
        const rfApiVersions = [];
        if (event instanceof HttpResponse)
        {
          event.headers.keys().forEach((headerKey) =>
          {
            if (headerKey.toLowerCase().indexOf("supported-products") !== -1)
            {
              neededHeaders.push(event.headers.get(headerKey));
            }
            if (headerKey.toLowerCase().indexOf("stopfinder-api-version") !== -1)
            {
              sfApiVersion = event.headers.get(headerKey);
            }
            if (headerKey.toLowerCase().indexOf("routefinder-api-version") !== -1)
            {
              rfApiVersions.push(event.headers.get(headerKey));
            }
          });
          this.verifySupportedProducts(neededHeaders, rfApiVersions, sfApiVersion);
        }
        this.updateLoginStatus();
        return event;
      }),
      catchError(err =>
      {
        const httpErrorResponse: HttpErrorResponse = err;
        if (httpErrorResponse.status === 401 || httpErrorResponse.status === 203)
        {
          return this.refreshing.pipe(
            take(1), switchMap(isRefreshing =>
            {
              if (isRefreshing)
              {
                return this.refreshing.pipe(
                  skip(1), take(1), flatMap(val =>
                  {
                    // resend coincident request
                    const requestReauth = req.clone({
                      headers: this.addDefaultHeaders(req.headers)
                    });
                    return next.handle(requestReauth);
                  })
                );
              } else
              {
                this.refreshing.next(true);
                return this.appService.refreshLogin().pipe(
                  flatMap(response =>
                  {
                    this.refreshing.next(false);
                    // resend initial 401 failed request
                    const requestReauth = req.clone({
                      headers: this.addDefaultHeaders(req.headers)
                    });
                    return next.handle(requestReauth);
                  }),
                  catchError(refreshErr =>
                  {
                    this.refreshing.next(false);
                    throw refreshErr;
                  })
                );
              }
            })
          );
        }

        throw err;
      })
    );
  }
}
