import * as _ from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable, NgZone } from '@angular/core';
import { StopfinderApiService } from 'src/app/shared/stopfinder/stopfinder-api.service';
import { MessagesService } from '../../../messages/messages.service';
import
{
  IForm,
  IQuestion,
  FormStatusType,
  IFormSentRecipient,
  FormStatusEnum,
  IFormSendQuestionResult,
  IQuestionResult,
  IRecipientQuestionResult,
  FormSource,
  FormNotificationType,
  FormNotificationParams,
  FormQuestionEnum,
  ISaveQuestionPayload
} from 'src/app/form/form.interface';
import { ConfirmationDialogComponent } from 'src/app/shared/layout/confirmation-dialog/confirmation-dialog.component';
import { TargetedBlockingScrollStrategy } from 'src/app/shared/material/targeted-blocking-scroll-strategy';
import { MatBottomSheet, MatDialog } from '@angular/material';
import { StateService } from '../state/state.service';
import { AdditionalData, Communication, CommunicationType, IGeoLocationPosition, IUserDefinedField, PushNotification } from 'src/app/shared/stopfinder/stopfinder-models';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { DeviceService } from '../device/device.service';
import { formatPhoneNumber } from '../../../shared/utils/utils';

@Injectable()
export class FormService
{
  private dialogRef;

  private _isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private _isCover: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private _formInfo: BehaviorSubject<IForm> = new BehaviorSubject<IForm>(null);
  private _questionsList: BehaviorSubject<Array<IQuestion>> = new BehaviorSubject<Array<IQuestion>>(null);
  private _selectRecipient: BehaviorSubject<IFormSentRecipient> = new BehaviorSubject<IFormSentRecipient>(null);
  private _questionsResult: BehaviorSubject<Array<IFormSendQuestionResult>> = new BehaviorSubject<Array<IFormSendQuestionResult>>(null);

  public userDefendFields: BehaviorSubject<IUserDefinedField[]> = new BehaviorSubject<IUserDefinedField[]>(null);
  public geoLocationPosition: BehaviorSubject<IGeoLocationPosition> = new BehaviorSubject<IGeoLocationPosition>(null);
  public inprogressIndex: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public inprogressQuestionStatus: Observable<number> = this.inprogressIndex.asObservable();
  public isProgressLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isProgressLoadedObservable: Observable<boolean> = this.isProgressLoaded.asObservable();
  public communication: Communication = null;
  public formSource: string = null;

  public translateText: BehaviorSubject<{ [key: string]: string }> = new BehaviorSubject<{ [key: string]: string }>(null);

  constructor(
    private readonly ngZone: NgZone,
    private readonly router: Router,
    private readonly apiService: StopfinderApiService,
    private readonly dialog: MatDialog,
    private readonly bottomSheet: MatBottomSheet,
    private readonly stateService: StateService,
    public readonly messageService: MessagesService,
    private readonly deviceService: DeviceService,
  ) { }

  get isCover()
  {
    return this._isCover;
  }

  get isLoading()
  {
    return this._isLoading;
  }

  get formInfo()
  {
    return this._formInfo;
  }

  get selectRecipient()
  {
    return this._selectRecipient;
  }

  get questionsList()
  {
    return this._questionsList;
  }

  get questionsResult()
  {
    return this._questionsResult;
  }

  get isRequired()
  {
    return this.formInfo.value && this.formInfo.value.required;
  }

  get isShowAllQuestions()
  {
    return this.formInfo.value && this.formInfo.value.isShowAllQuestions;
  }

  public initializeServiceData()
  {
    this.updateLoadingStatus(true);
    this.updateCoverStatus(true);
    this.updateFormInfo(null);
    this.updateSelectRecipient(null);
    this.updateQuestions(null);
    this.updateQuestionsResult(null);
    this.dialogRef = null;
  }

  public updateLoadingStatus = (isOpen: boolean) => this._isLoading.next(isOpen);

  public updateCoverStatus = (isOpen: boolean) => this._isCover.next(isOpen);

  public updateFormInfo = (formInfo: IForm) => this._formInfo.next(formInfo);

  public updateQuestions = (questions: Array<IQuestion>) => this._questionsList.next(questions);

  public updateQuestionsResult = (results: Array<IFormSendQuestionResult>) => this._questionsResult.next(results);

  public updateSelectRecipient = (recipient: IFormSentRecipient) => this._selectRecipient.next(recipient);

  public openMessagesPage = () =>
  {
    // if from the auto opened form, back to the schedule home and check again
    if (this.formSource === FormSource.remind)
    {
      this.openSchedulePage();
      this.formSource = null;
      return;
    }

    this.ngZone.run(() =>
    {
      this.stateService.goRoute(`messages`);
    });
  }

  public openSchedulePage = () =>
  {
    this.ngZone.run(() =>
    {
      this.stateService.goRoute(`schedule`);
    });
  }

  public getFormInfo(formSendId: number, recipientId?: number): Observable<IForm>
  {
    return this.apiService.getFormByFormSendId(formSendId, recipientId).pipe(map(res =>
    {
      !!res && this.updateFormInfo(res);
      return res;
    }), catchError(error =>
    {
      this.updateLoadingStatus(false);
      return of(null);
    }));
  }

  public getFormRecipient(recipientId: number): Observable<IFormSentRecipient>
  {
    return this.apiService.getFormRecipientById(recipientId).pipe(map(res =>
    {
      return res;
    }), catchError(error =>
    {
      return of(null);
    }));
  }

  public getQuestions(formSendId: number, formSentRecipientId: number): Observable<Array<IQuestion>>
  {
    return this.apiService.getQuestionByFormSendId(formSendId, formSentRecipientId).pipe(map(res =>
    {
      if (res && res.length > 0)
      {
        res.forEach(element => {
          if (element.UDFTypeName === 'Phone Number' && element.Answer)
          {
            element.Answer = formatPhoneNumber(element.Answer);
          }
        });
      }
      
      (res || []).length > 0 && this.updateQuestions(res);

      return res;
    }), catchError(error =>
    {
      this.updateLoadingStatus(false);
      return of(null);
    }));
  }

  public checkFormIsComplete(recipientIds: number[]): Observable<number[]>
  {
    return this.apiService.getUnCompleteForms(recipientIds).pipe(map(res => res), catchError(error =>
    {
      this.updateLoadingStatus(false);
      return of([]);
    }));
  }

  public updateFormStatus(recipientId: number, statusId: FormStatusType): Observable<boolean>
  {
    return this.apiService.updateFormStatus(recipientId, statusId).pipe(map(res => !!res), catchError(error =>
    {
      this.updateLoadingStatus(false);
      return of(false);
    }));
  }

  public getUserDefinedFields(formSentRecipientId: number)
  {
    return this.apiService.getUserDefinedFields(formSentRecipientId).pipe(map(res =>
    {
      return res;
    }), catchError(error =>
    {
      return of(null);
    }));
  }

  public openConfirmDialog(title: string, message: string, action: string = "Yes", secondaryAction: string = "No"): Observable<boolean>
  {
    if (!this.dialogRef)
    {
      this.ngZone.run(() =>
      {
        this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: true,
          data: {
            title: title,
            message: message,
            action: action,
            secondary: true,
            secondaryAction: secondaryAction
          },
          scrollStrategy: new TargetedBlockingScrollStrategy(),
          panelClass: "confirm-dialog"
        });
      });
    }

    return this.dialogRef.afterClosed().pipe(
      map(result =>
      {
        this.dialogRef = undefined;
        return !!result;
      })
    );
  }

  public updateFormStatusToCancel()
  {
    if (!this.isRequired && this.selectRecipient.value)
    {
      this.updateLoadingStatus(true);
      // change the read to true here, or user will see the red dot on the form card in message center
      // page first, then the red dot disappeared.
      this.messageService.selectedCommunication && (this.messageService.selectedCommunication.read = true);
      this.updateFormStatus(this.selectRecipient.value.id, FormStatusEnum.CANCELED).subscribe(() =>
      {
        this.updateLoadingStatus(false);
        this.openMessagesPage();
      });
    } else
    {
      this.openMessagesPage();
    }
  }

  public addQuestionResult(questionResult: IQuestionResult)
  {
    if (!questionResult || !_.get(this.selectRecipient, 'value') || !this.formInfo.value) return;

    let results = [].concat(this.questionsResult.value || []);
    const formSendId = this.formInfo.value.id;
    let existFormSendIndex: number = _.findIndex(results, { formSendId });

    if (existFormSendIndex < 0)
    {
      existFormSendIndex = results.push({
        formSendId,
        recipients: [],
      });
      existFormSendIndex = existFormSendIndex - 1;
    }

    const recipientId = this.selectRecipient.value.id;
    let existRecipientIndex: number = _.findIndex(results[existFormSendIndex].recipients, { recipientId });
    if (existRecipientIndex < 0)
    {
      existRecipientIndex = results[existFormSendIndex].recipients.push({
        recipientId,
        questionsResult: [],
      });
      existRecipientIndex = existRecipientIndex - 1;
    }

    const existResultIndex: number = _.findIndex(results[existFormSendIndex].recipients[existRecipientIndex].questionsResult,
      { questionId: questionResult.questionId });

    if (existResultIndex < 0)
    {
      results[existFormSendIndex].recipients[existRecipientIndex].questionsResult.push(questionResult);
    }
    else
    {
      results[existFormSendIndex].recipients[existRecipientIndex].questionsResult[existResultIndex] = questionResult;
    }

    this.updateQuestionsResult(results);
  }

  getSubmitQuestionsResult(questionId?: number): IRecipientQuestionResult | IQuestionResult
  {
    const formRecipient: IFormSendQuestionResult = _.find(this.questionsResult.value, { formSendId: this.formInfo.value.id });

    const recipientQuestions: IRecipientQuestionResult = _.find(formRecipient && formRecipient.recipients, { recipientId: this.selectRecipient.value.id });

    if (!formRecipient || !recipientQuestions) return null;

    if (!_.isUndefined(questionId) && recipientQuestions)
    {
      return _.find(recipientQuestions.questionsResult, (result: IQuestionResult) => result.questionId === questionId);
    }

    // format attachment question
    const questionsResult = [...recipientQuestions.questionsResult];
    _.forEach(questionsResult, (result: IQuestionResult) =>
    {
      if (result.typeId !== FormQuestionEnum.attachment) return;

      const attachments = Array.isArray(result.value) ? [...result.value] : [];
      attachments.forEach((document, index) =>
      {
        if (index === 0)
        {
          result.document = document;
          result.value = "";
        } else
        {
          questionsResult.push({
            ...result,
            document
          });
        }
      })
    });

    recipientQuestions.questionsResult = questionsResult;

    return recipientQuestions;
  }

  clearSubmitQuestionsResult()
  {
    const results = [].concat(this.questionsResult.value || []);
    const existFormSendResultIndex = _.findIndex(results, { formSendId: this.formInfo.value.id });
    const existRecipientIndex = _.findIndex(results[existFormSendResultIndex].recipients, { recipientId: this.selectRecipient.value.id });
    if (existFormSendResultIndex >= 0 && existRecipientIndex >= 0)
    {
      results[existFormSendResultIndex].recipients.splice(existRecipientIndex, 1);
      this.updateQuestionsResult(results);
    }
  }

  saveQuestionResults()
  {
    const submitQuestionsResult = this.getSubmitQuestionsResult();
    const locationCoords = this.geoLocationPosition.getValue() && this.geoLocationPosition.getValue().coords;
    const questionPayload: ISaveQuestionPayload = {
      formSentRecipientId: (submitQuestionsResult as IRecipientQuestionResult).recipientId,
      results: (submitQuestionsResult as IRecipientQuestionResult).questionsResult,
      longitude: locationCoords && locationCoords.longitude,
      latitude: locationCoords && locationCoords.latitude,
    };

    submitQuestionsResult && this.apiService.saveFormResults(questionPayload).subscribe((result) =>
    {
      this.updateLoadingStatus(false);
      if (!!result)
      {
        this.clearSubmitQuestionsResult();
        if (this.messageService.selectedCommunication)
        {
          this.messageService.selectedCommunication.read = true;
        }
        this.openMessagesPage();
      }
    });
  }

  async hasFormCompleted()
  {
    const recipientId = this.selectRecipient.value.id;
    const formSentRecipient: IFormSentRecipient = await this.getFormRecipient(recipientId).toPromise();
    return formSentRecipient && formSentRecipient.openedStatusId == FormStatusEnum.COMPLETED;
  }

  showFormCompletedAlert()
  {
    const { dialog } = this.translateText.getValue();
    this.showDialogAlert(
      dialog[`completed.title`],
      dialog[`completed.desc`],
      dialog[`completed.action`],
      this.openMessagesPage.bind(this)
    );
  }

  showDialogAlert(title: string, message: string, action: string, callback: Function): void
  {
    if (!this.dialogRef)
    {
      this.ngZone.run(() =>
      {
        this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: true,
          data: {
            title: title,
            message: message,
            action: action,
            secondary: false,
          },
          scrollStrategy: new TargetedBlockingScrollStrategy(),
          panelClass: "confirm-dialog"
        });
      });

      this.dialogRef.afterClosed().subscribe(result =>
      {
        this.dialogRef = undefined;
        if (result)
        {
          typeof callback === "function" && callback();
        }
      });
    }
  }

  /* 
    form notification logic
  */
  async showNotification(notification: PushNotification, appService: AppService)
  {
    const additionalData = notification && notification.additionalData;
    const showNotification = () =>
    {
      appService && appService.showNotification(notification, {
        id: additionalData.sentFormId,
        body: notification.title,
        subject: notification.message,
        type: CommunicationType.Form,
        formRecipientId: additionalData.sentFormRecipientId,
        formName: notification.title,
      } as Communication);
    };
    const isShowMap = appService && appService.showMap;
    const hideMap = () => { appService && appService.setMapVisibility(false); }
    const params: FormNotificationParams = {
      additionalData,
      isShowMap,
      showNotification,
      hideMap,
      title: notification.message,
    };

    if (!additionalData.foreground)
    {
      // will be triggered when a background push is received on Android
      if (additionalData.coldstart === true && this.deviceService.isAndroid)
      {
        return;
      }

      const isFormSentExpired = await this.messageService.getFormSentExpiredStatusById(additionalData.sentFormId);
      if (isFormSentExpired)
      {
        const formDialog = this.translateText.getValue();
        this.showDialogAlert(
          formDialog["dialog"]["unavailable.title"],
          formDialog["dialog"]["unavailable.message"],
          formDialog["dialog"]["unavailable.action"],
          null
        );
      }
      else
      {
        this.openFormPage(additionalData.sentFormId, additionalData.sentFormRecipientId, isShowMap && hideMap);
      }

      return;
    }

    switch (this.checkFormType(notification.additionalData))
    {
      case FormNotificationType.REMIND_LATER_FORM_REQUIRED:
        this.processRemindLaterRequiredForm(params);
        break;
      case FormNotificationType.REMIND_LATER_FORM_NOT_REQUIRED:
        this.processRemindLaterNotRequiredForm(params);
        break;
      case FormNotificationType.NEW_FORM_REQUIRED:
        this.processNewRequiredForm(params);
        break;
      case FormNotificationType.NEW_FORM_NOT_REQUIRED:
        this.processNewNotRequiredForm(params);
        break;
      default:
        showNotification();
        break;
    }
  }

  public doRemindMeLater(selectedRecipientId?: number)
  {
    const recipientId = selectedRecipientId || (this.selectRecipient.getValue() && this.selectRecipient.getValue().id);
    this.updateLoadingStatus(true);
    if (this.messageService.selectedCommunication)
    {
      this.messageService.selectedCommunication.read = false;
    }
    this.updateFormStatus(recipientId, FormStatusEnum.REMIND_ME_LATER).subscribe(() =>
    {
      this.updateLoadingStatus(false);
      this.openMessagesPage();
    });
  }

  private checkFormType(additionalData: AdditionalData): FormNotificationType
  {
    if (!additionalData.required && additionalData.isRemindLater)
    {
      return FormNotificationType.REMIND_LATER_FORM_NOT_REQUIRED;
    }
    else if (additionalData.required && additionalData.isRemindLater)
    {
      return FormNotificationType.REMIND_LATER_FORM_REQUIRED;
    }
    else if (additionalData.required && !additionalData.isRemindLater)
    {
      return FormNotificationType.NEW_FORM_REQUIRED;
    }
    else if (!additionalData.required && !additionalData.isRemindLater)
    {
      return FormNotificationType.NEW_FORM_NOT_REQUIRED;
    }

    return null;
  }

  private processRemindLaterRequiredForm(params: FormNotificationParams)
  {
    const { additionalData, isShowMap, hideMap, title } = params;
    const _openFormPage = () =>
    {
      this.openFormPage(additionalData.sentFormId, additionalData.sentFormRecipientId, isShowMap && hideMap);
    };
    // if background notification, click notification open the app and auto open form
    if (!additionalData.foreground)
    {
      _openFormPage();
    }
    else
    {
      // in other page, don't show the black push bar and prompt form automatically when user go back to student page
      // in schedule page, prompt form automatically and don't show the black push bar
      this.isSchedulePage(isShowMap) && this.showOpenFormDialog(title, _openFormPage);
    }
  }

  private processRemindLaterNotRequiredForm(params: FormNotificationParams)
  {
    const { showNotification } = params;
    // remind me later, not require, keep previous behavior
    typeof showNotification === 'function' && showNotification();
  }

  private processNewRequiredForm(params: FormNotificationParams)
  {
    const { additionalData, isShowMap, showNotification, hideMap, title } = params;
    const _openFormPage = () =>
    {
      this.openFormPage(additionalData.sentFormId, additionalData.sentFormRecipientId, isShowMap && hideMap);
    };

    // in background notification, show the push notification bar on the top of the device
    // if in schedule page, open the form when click it
    if (!additionalData.foreground)
    {
      this.isSchedulePage(isShowMap) && _openFormPage();
    }
    else
    {
      // in schedule page, prompt form automatically and don't show the black push bar
      // in other page, show notification bar
      if (this.isSchedulePage(isShowMap))
      {
        this.showOpenFormDialog(title, _openFormPage);
      }
      else
      {
        typeof showNotification === 'function' && showNotification();
      }
    }
  }

  private processNewNotRequiredForm(params: FormNotificationParams)
  {
    // not require form same as require form 
    this.processNewRequiredForm(params);
  }

  private isSchedulePage(isShowMap: boolean = false)
  {
    return window.location.pathname.includes("/schedule") && !isShowMap;
  }

  private showOpenFormDialog(title: string, callback: Function)
  {
    const { dialog } = this.translateText.getValue();
    const showDialog = this.showDialogAlert.bind(this,
      title,
      dialog["parent.drop.off.desc"],
      dialog["parent.drop.off.action"],
      callback);

    const hasBottomSheet = this.bottomSheet && this.bottomSheet._openedBottomSheetRef;

    !hasBottomSheet && showDialog();
    // turn off bottom sheet
    hasBottomSheet && this.bottomSheet._openedBottomSheetRef.dismiss();
    hasBottomSheet && this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(() => showDialog());
  }

  private openFormPage(id: string | number, formRecipientId: string | number, hideMap?: Function | boolean)
  {
    typeof hideMap === 'function' && hideMap();

    this.router.navigate([`formQuestion/${id}/${formRecipientId}`, {
      source: FormSource.remind
    }]);
  }
}
