import { BasemapThumbnail } from './basemap.thumbnail';
import { eBaseMaps, BaseMapThumbnailEnum } from '../classes/basemap.enum';

/**
 * Core class for generating basemaps
 */
export abstract class BaseBaseMapGenerator
{
    constructor() { }
    abstract get title(): string;

    abstract get thumbnail(): string;

    abstract get type(): eBaseMaps;

    abstract get maxZoom(): number;

    abstract get maxNativeZoom(): number;

    abstract get translateKey(): BaseMapThumbnailEnum;

    public getThumbnail(): BasemapThumbnail
    {
        return new BasemapThumbnail(this.title, this.thumbnail, this.type, this.translateKey);
    }

}

/**
 * Base for generating Raster Maps
 */
export abstract class BaseRasterMapGenerator extends BaseBaseMapGenerator
{
    abstract get name(): string;
}

/**
 * Base for generating Vector Maps
 */
export abstract class BaseVectorMapGenerator extends BaseBaseMapGenerator
{
}

/**
 * Base for generating hybrid Vector Maps
 */
export abstract class BaseHybridVectorMapGenerator extends BaseVectorMapGenerator
{
    abstract get name(): string;
}
