import { Injectable } from '@angular/core';
import { StopfinderApiService } from '../shared/stopfinder/stopfinder-api.service';
import { flatMap } from 'rxjs/operators';

@Injectable()
export class ForgotPasswordService
{

  constructor(
    private readonly stopfinderApi: StopfinderApiService
  ) { }

  checkForgotPassword(guid: string, email: string)
  {
    // api client baseuri will have already been set at this point.
    return this.stopfinderApi.verifyForgotPassword(guid, email);
  }

  submitForgotPassword(subscriberId: number, password: string, passwordVerify: string)
  {
    const id = 0, guid = '', email = '', sentOn = '';
    // api client baseuri will have already been set at this point.
    return this.stopfinderApi.submitForgotPassword({ subscriberId, password, passwordVerify, id, guid, email, sentOn });
  }

  createForgotPassword(email: string)
  {
    // api client baseuri will have already been set at this point.
    return this.stopfinderApi.createForgotPassword(email);
  }

  getForgotPasswordEnrollment(email: string)
  {
    return this.stopfinderApi.getEnvironmentURL(email).pipe(flatMap((response: string) =>
    {
      this.stopfinderApi.stopFinderBaseUri = response;
      return response;
    }));
  }
}
