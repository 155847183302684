export interface IGeoLocationPosition
{
  coords: IGeoLocationCoords;
  timestamp: number;
}

export interface IGeoLocationCoords
{
  accuracy: number;
  altitude: number | null;
  altitudeAccuracy: number | null;
  heading: number | null;
  latitude: number;
  longitude: number;
  speed: number | null;
}

export interface IGeoLocationPositionError
{
  code?: GeoLocationErrorCode;
  message?: string;
}

export enum GeoLocationErrorCode
{
  PLUGIN_NOT_FOUND = -1,
  PERMISSION_DENIED = 1,
  POSITION_UNAVAILABLE = 2,
  TIMEOUT = 3,
}
