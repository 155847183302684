import { Component, OnInit, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from './app.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material';
import { RouterOutlet } from '@angular/router';
import { LocalStorageService } from './shared/local-storage/local-storage.service';
import { ManageSubscriptionService } from './settings/manage-subscriptions/manage-subscriptions.service';
import { StateService } from './components/service/state/state.service';
import { slideInAnimation } from './components/animation/animation';
import * as Tutorial from './tutorial/tutorial.export';
import { Language } from './shared/utils/enum';
import { FormService } from './components/service/form/form.service';
import { DeviceService } from './components/service/device/device.service';
import { MapGeoAlertService } from './components/service/map/map-geoalert.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slideInAnimation
    // animation triggers go here
  ],
})
export class AppComponent implements OnInit
{
  title = 'ng-material';
  public animationsDisabled = false;
  constructor(
    public appService: AppService,
    public readonly deviceService: DeviceService,
    private stateService: StateService,
    private translate: TranslateService,
    private readonly localStorageService: LocalStorageService,
    public readonly formService: FormService,
    public readonly geoAlertService: MapGeoAlertService,
  )
  {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');
    this.translate.currentLang = 'en';
    // add the support language
    const languages = Object.values(Language) as Array<string>;
    this.translate.addLangs(languages);
    const subscriberLanguage = this.localStorageService.get('subscriberLanguage');
    const currentLang = subscriberLanguage ? subscriberLanguage : String(this.translate.getBrowserLang());
    if (languages.includes(currentLang))
    {
      // the lang to use, if the lang isn't available, it will use the current loader to get them
      this.translate.use(currentLang);
      this.translate.currentLang = currentLang;
    }

    this.setAppServiceTranslateText();

    this.appService.tokenRefreshObservable.subscribe((refreshed: boolean) =>
    {
      if (refreshed)
      {
        this.translate.use(this.appService.subscriberLanguage);
        this.translate.currentLang = this.appService.subscriberLanguage;
        this.translate.use(this.translate.currentLang);
      }
    });
  }

  prepareRoute(outlet: RouterOutlet)
  {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  toggleAnimations()
  {
    this.animationsDisabled = !this.animationsDisabled;
  }

  ngOnInit()
  {
    this.deviceService.deviceId = navigator.productSub;
    if (this.deviceService.isiOS)
    {
      if (this.deviceService.isiPhoneX)
      {
        document.body.className = 'native-host iPhone-body iPhoneX-padding-top';
      }
      else
      {
        document.body.className = 'native-host iPhone-body iPhone-padding-top';
      }
    }
    else
    {
      document.body.className = 'native-host iPhone-body';
    }

    if (this.deviceService.isBrowser)
    {
      document.body.classList.add('browser-body');
    } else
    {
      document.body.classList.remove('browser-body');
    }
  }

  public closeMap()
  {
    this.appService.closeMapEvent.emit(true);
    // to prevent routing back to others, hard code the last location as schedule page
    this.stateService.goRoute(`schedule`);
    this.appService.setMapVisibility(false);
  }

  onBackEvent()
  {
    this.stateService.goRoute(`${this.appService.lastLocation}`);
  }

  private setAppServiceTranslateText()
  {
    this.translate.stream(["login", "form"]).subscribe((res: { [key: string]: string }) =>
    {
      this.appService.translateText = res;
      this.formService.translateText.next(res["form"] as any);
    });
  }
}

@Component({
  selector: 'settings-bottom-sheet',
  template: `
  <mat-nav-list>
    <a class="sf-color-black" mat-list-item *ngIf="subscriptionsService.hasOwnedSubscriptions" (click)="openManageSubscriptions($event)">
      <mat-icon matListIcon>share</mat-icon>
      <span mat-line>{{ 'settings.sheet.manage.shared.subscriptions' | translate }}</span>
      <span mat-line>{{ 'settings.sheet.manage.shared.subscriptions.desc' | translate }}</span>
    </a>
    <a class="sf-color-black" mat-list-item (click)="openEditProfile($event)">
      <mat-icon matListIcon>account_circle</mat-icon>
      <span mat-line>{{ 'settings.sheet.profile' | translate }}</span>
      <span mat-line>{{ 'settings.sheet.profile.desc' | translate }}</span>
    </a>
    <a class="sf-color-black" mat-list-item (click)="openHelpCenter()">
      <mat-icon matListIcon>help_outline</mat-icon>
      <span mat-line>{{ 'settings.sheet.help' | translate }}</span>
      <span mat-line>{{ 'settings.sheet.help.desc' | translate }}</span>
    </a>
    <a class="sf-color-black" mat-list-item (click)="openLogoutDialog($event)">
      <mat-icon matListIcon>power_settings_new</mat-icon>
      <span mat-line>{{ 'settings.sheet.logout' | translate }}</span>
      <span mat-line>{{ 'settings.sheet.logout.desc' | translate }}</span>
    </a>
  </mat-nav-list>
  `,
})
export class SettingsBottomSheetComponent
{
  public hasSharedSubscriptions: Observable<boolean>;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    public subscriptionsService: ManageSubscriptionService,
    private readonly appService: AppService,
    private readonly bottomSheetRef: MatBottomSheetRef<SettingsBottomSheetComponent>,
    private readonly localStorageService: LocalStorageService,
    private stateService: StateService,
  )
  {
    this.hasSharedSubscriptions = this.subscriptionsService.sharedSubscriptionObservable.pipe(map(subs => (subs && subs.length > 0)));
  }

  openLogoutDialog(event: any)
  {
    var currentEmail = this.localStorageService.get('currentEmail');
    this.bottomSheetRef.dismiss();
    this.bottomSheetRef.afterDismissed().subscribe(() =>
    {
      this.appService.confirmLogout();
    })
  }

  openManageSubscriptions(event: any)
  {
    this.bottomSheetRef.dismiss();
    this.stateService.goRoute('manage-subscriptions');
  }

  openEditProfile(event: any)
  {
    this.stateService.goRoute('profile');
  }

  public openHelpCenter()
  {
    // TODO - goto new help page
    const tutorialData: Tutorial.ITutorialAccess = {
      disable: false
    };
    this.stateService.goRoute('tutorial', tutorialData);
  }
}
