import * as _ from 'lodash';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, interval, Subscription, zip, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { StopfinderApiService } from '../shared/stopfinder/stopfinder-api.service';
import { Announcement, MessageThread, Message, Document, PushNotification } from '../shared/stopfinder/stopfinder-models';
import { Communication, CommunicationType, CommunicationFilter } from '../shared/stopfinder/models/communication';
import * as moment from 'moment';
import { IForm } from '../form/form.export';
import { StopfinderDateTimeService } from '../shared/stopfinder/stopfinder-datetime.service';

export interface Filter
{
  displayText: string;
  filterApplied: CommunicationFilter;
  active: boolean;
}

@Injectable()
export class MessagesService implements OnDestroy
{
  public readonly REFRESH_INTERVAL_MINUTES = 2;
  public selectedCommunication: Communication;

  public notificationCommunication: BehaviorSubject<Communication> = new BehaviorSubject<Communication>(null);
  public notificationCommunicationObservable: Observable<Communication> = this.notificationCommunication.asObservable();

  private threadMessages = new Array<Message>();
  private announcementSendDocuments = new Array<Document>();
  private readonly threadMessagesSubject = new BehaviorSubject<Array<Message>>(this.threadMessages);
  public readonly threadMessagesObservable = this.threadMessagesSubject.asObservable();
  private readonly announcementSendDocumentsSubject = new BehaviorSubject<Array<Document>>(this.announcementSendDocuments);
  public readonly announcementSendDocumentsObservable = this.announcementSendDocumentsSubject.asObservable();

  private readonly communicationRefreshIntervalObservable: Observable<number> = interval(this.REFRESH_INTERVAL_MINUTES * 60 * 1000);
  private communicationRefreshIntervalSubscription: Subscription;
  private communications: Array<Communication> = new Array<Communication>();
  private readonly communicationsSubject = new BehaviorSubject<Array<Communication>>([]);
  public readonly communicationsObservable: Observable<Array<Communication>> = this.communicationsSubject.asObservable();
  public readonly sortedCommunicationsObservable: Observable<Array<Communication>> = this.communicationsObservable.pipe(map(messages => messages.sort((a, b) =>
  {
    const sentOnA = a.sentOn ? new Date(a.sentOn).valueOf() : 0;
    const sentOnB = b.sentOn ? new Date(b.sentOn).valueOf() : 0;

    if (sentOnA > sentOnB)
    {
      return -1;
    } else if (sentOnA < sentOnB)
    {
      return 1;
    } else
    {
      return 0;
    }
  })));
  public selectedFilter: CommunicationFilter = CommunicationFilter.Default;
  public searchCriteria = '';
  public loggedIn = false;
  public filterCollection: Array<Filter> = [
    {
      displayText: 'Messages',
      filterApplied: CommunicationFilter.Messages,
      active: false,
    },
    {
      displayText: 'Announcements',
      filterApplied: CommunicationFilter.Announcements,
      active: false,
    },
    {
      displayText: 'Forms',
      filterApplied: CommunicationFilter.Forms,
      active: false,
    },
    {
      displayText: 'Unread',
      filterApplied: CommunicationFilter.UnRead,
      active: false,
    },
    {
      displayText: 'Archived',
      filterApplied: CommunicationFilter.Archived,
      active: false,
    }];

  public readonly sortedUnarchivedCommunicationsObservable: Observable<Array<Communication>> = this.sortedCommunicationsObservable.pipe(map(messages => messages.filter(m => !m.archived)));
  public readonly unreadCommunicationsObservable: Observable<Array<Communication>> = this.communicationsObservable.pipe(map(messages => messages.filter(message => !message.read)));
  public readonly sortedUnreadCommunicationsObservable: Observable<Array<Communication>> = this.sortedCommunicationsObservable.pipe(map(messages => messages.filter(message => !message.read)));
  public readonly unreadCommunicationCountObservable: Observable<number> = this.communicationsObservable.pipe(map(messages => messages.filter(message => !message.read && !message.archived).length));
  public readonly unreadCommunicationCountStoppyObservable: Observable<string> = this.unreadCommunicationCountObservable.pipe(map(messageCount => messageCount == 0 ? '' : messageCount.toString()));
  public readonly sortedFilteredCommunicationsObservable: Observable<Array<Communication>> = this.sortedCommunicationsObservable.pipe(map(messages => messages.filter(m =>
  {
    const searchMatched = this.searchCriteria ?
      ((m.body ? m.body.toLowerCase().includes(this.searchCriteria.toLowerCase()) : false) ||
        (m.subject ? m.subject.toLowerCase().includes(this.searchCriteria.toLowerCase()) : false) ||
        (m.allMessages && m.allMessages.length > 0 ? m.allMessages.some(item => { return item.toLowerCase().indexOf(this.searchCriteria.toLowerCase()) >= 0; }) : false)) : true;
    const sentOnNull = m.sentOn !== null;
    switch (this.selectedFilter)
    {
      case CommunicationFilter.UnRead:
        return !m.read && !m.archived && searchMatched && sentOnNull;
      case CommunicationFilter.Archived:
        return m.archived && searchMatched && sentOnNull;
      case CommunicationFilter.Default:
        return !m.archived && searchMatched && sentOnNull;
      case CommunicationFilter.Messages:
      case CommunicationFilter.Announcements:
      case CommunicationFilter.Forms:
        return m.type === CommunicationType[this.selectedFilter] && !m.archived && searchMatched && sentOnNull;
      case CommunicationFilter.Forms:
        return m.type === CommunicationType.Form && !m.archived && searchMatched && sentOnNull;
    }
  })));

  public needScrollToLastMessage = false;
  public lastScrollLocation = 0;

  constructor(
    private readonly dateTimeService: StopfinderDateTimeService,
    private readonly stopfinderApi: StopfinderApiService,
  )
  {
    this.communicationRefreshIntervalSubscription = this.communicationRefreshIntervalObservable.subscribe(val =>
    {
      if (this.loggedIn)
      {
        this.getCommunications();
      }
    });
  }

  ngOnDestroy()
  {
    this.unsubscribeCommunicationRefresh();
  }

  unsubscribeCommunicationRefresh()
  {
    this.communicationRefreshIntervalSubscription.unsubscribe();
  }

  getCommunicationFromAnnouncement(announcement: Announcement): Communication
  {
    return {
      type: CommunicationType.Announcement,
      body: announcement.body,
      subject: announcement.subject,
      sentOn: announcement.sentOn,
      read: announcement.read,
      archived: announcement.archived,
      id: announcement.id
    } as Communication;
  }

  getCommunicationFromMessageThread(messageThread: MessageThread): Communication
  {
    return {
      type: CommunicationType.Message,
      body: messageThread.lastMessageBody,
      allMessages: messageThread.allMessages,
      subject: `${messageThread.studentFirstName} ${messageThread.studentLastName}`,
      sentOn: (moment(messageThread.lastMessageSentOn).isAfter(moment(messageThread.lastMessageReceivedOn)) || !messageThread.lastMessageReceivedOn) ? messageThread.lastMessageSentOn : messageThread.lastMessageReceivedOn,
      read: messageThread.subscriberRead,
      archived: messageThread.archived,
      id: messageThread.id,
      riderId: messageThread.riderId,
      clientName: messageThread.clientName,
    } as Communication;
  }

  getCommunicationFromFormSent(form: IForm): Communication
  {
    return {
      type: CommunicationType.Form,
      body: form.formSentRecipient[0].riderId ? form.displayName : form.description,
      subject: form.formSentRecipient[0].riderId ? `${form.formSentRecipient[0].riderName}` : form.displayName,
      sentOn: form.sentOn,
      read: form.read,
      archived: form.formSentRecipient[0].archived,
      id: form.id,
      formRecipientId: form.formSentRecipient[0].id,
      formName: form.displayName,
      completed: form.formSentRecipient[0].completed,
      required: form.required,
      isExpirationEnabled: form.isExpirationEnabled,
      expirationDateTime: form.expirationDateTime,
    } as Communication;
  }

  async getFormSentExpiredStatusById(formSentId: number | string): Promise<boolean>
  {
    return await this.stopfinderApi.getFormSentExpiredStatus(formSentId).toPromise();
  }

  getCommunications(callbackFunc?: Function, needGetForm: boolean = true)
  {
    if (!this.loggedIn)
    {
      return;
    }

    const formatData = (communications) =>
    {
      const announcements = communications[0].map(announcement =>
      {
        return this.getCommunicationFromAnnouncement(announcement);
      });
      const messages = communications[1].filter(messageThread =>
      {
        return messageThread.feedbackEnabled;
      }).map(messageThread =>
      {
        return this.getCommunicationFromMessageThread(messageThread);
      });

      let forms = [];
      if (needGetForm)
      {
        forms = communications[2].map(form =>
        {
          return this.getCommunicationFromFormSent(form);
        });
      }

      this.communications = messages.concat(announcements).concat(forms);
      this.communicationsSubject.next(this.communications);

      callbackFunc && callbackFunc();
    }

    return needGetForm
      ? zip(this.stopfinderApi.getAnnouncements(), this.stopfinderApi.getMessageThreads(), this.stopfinderApi.getFormSent()).subscribe((communications) =>
      {
        formatData(communications);
      })
      : zip(this.stopfinderApi.getAnnouncements(), this.stopfinderApi.getMessageThreads()).subscribe((communications) =>
      {
        formatData(communications);
      })
  }

  getThreadMessages(messageThreadId: number)
  {
    if (messageThreadId)
    {
      this.stopfinderApi.getMessages(messageThreadId).subscribe(messages =>
      {
        if (messages != null && messages.length > 0)
        {
          messages.forEach(m =>
          {
            if (!!m.sentOn)
            {
              var utcSentOn = moment.utc(m.sentOn);
              m.sentOn = moment(utcSentOn).local().format(this.dateTimeService.formatDate3);
            }
          });
        }

        this.needScrollToLastMessage = true;
        this.threadMessages = messages;
        this.threadMessagesSubject.next(this.threadMessages);
      });
    } else
    {
      this.threadMessages = [];
      this.threadMessagesSubject.next(this.threadMessages);
    }
  }

  getAnnouncementSendDocuments(announcementSendId: number)
  {
    if (announcementSendId)
    {
      this.stopfinderApi.getAnnouncementSendDocuments(announcementSendId).subscribe(documents =>
      {
        this.announcementSendDocuments = documents;
        this.announcementSendDocumentsSubject.next(this.announcementSendDocuments);
      });
    } else
    {
      this.announcementSendDocuments = [];
      this.announcementSendDocumentsSubject.next(this.announcementSendDocuments);
    }
  }

  markCommunicationReadStatus(communication: Communication, status: boolean)
  {
    let obs: Observable<any>;
    if (communication.type === CommunicationType.Announcement)
    {
      obs = this.stopfinderApi.markAnnouncementsStatus(communication.id, status, 'read');
    } else if (communication.type === CommunicationType.Message)
    {
      obs = this.stopfinderApi.markMessagesReadStatus(communication.id, status);
    } else
    {
      obs = of([]);
    }

    obs.subscribe(opRes =>
    {
      this.communications.filter(c => c.type === communication.type && c.id == communication.id).forEach(m => m.read = status);
      this.communicationsSubject.next(this.communications);
    });

    return obs;
  }

  markCommunicationArchiveStatus(communication: Communication, status: boolean)
  {
    let obs: Observable<any>;
    if (communication.type === CommunicationType.Announcement)
    {
      obs = this.stopfinderApi.markAnnouncementsStatus(communication.id, status, 'archive');
    } else if (communication.type === CommunicationType.Message)
    {
      obs = this.stopfinderApi.markMessagesArchiveStatus(communication.id, status);
    } else if (communication.type === CommunicationType.Form)
    {
      obs = this.stopfinderApi.markFormSentRecipientArchiveStatus(communication.formRecipientId, status);
    } else
    {
      obs = of([]);
    }

    obs.subscribe(opRes =>
    {
      this.communications.filter(c => c.type === communication.type && c.id == communication.id).forEach(m => m.archived = status);
      this.communicationsSubject.next(this.communications);
    });

    return obs;
  }

  filterMessagesForSearch(searchCriteria)
  {
    const tempMessages = this.communications;
    this.communicationsSubject.next(tempMessages.filter((message) =>
    {
      return message.body.includes(searchCriteria) || message.subject.includes(searchCriteria)
    }));
  }

  removeSearchFilter()
  {
    this.searchCriteria = '';
    this.communicationsSubject.next(this.communications);
  }

  markMessageDocumentsInTransit(id: number, value: boolean)
  {
    this.threadMessages.forEach((message) =>
    {
      if (message.id == id)
      {
        message.transferring = value;
      }
    });
    this.threadMessagesSubject.next(this.threadMessages);
  }

  setMessageDocumentsProgress(id: number, value: number)
  {
    this.threadMessages.forEach((message) =>
    {
      if (message.id == id)
      {
        message.progress = value;
      }
    });
    this.threadMessagesSubject.next(this.threadMessages);
  }

  addTemporaryMessage(newMessage: Message)
  {
    this.threadMessages.push(newMessage);
    this.threadMessagesSubject.next(this.threadMessages);
  }

  clearLastScrollLocation()
  {
    this.lastScrollLocation = 0;
  }

  patchAttendance(subscriptionId: number, document: any)
  {
    return this.stopfinderApi.patchAttendance(subscriptionId, document);
  }

  removeFormDataFromCommunications(id: number, type: string, formRecipientId: number)
  {
    this.communications = this.communications.filter(c => !(c.type === type && c.id === id && c.formRecipientId === formRecipientId));
    this.communicationsSubject.next(this.communications);
  }

  checkFormSentExpired(message: Communication): boolean
  {
    let isFormSentExpired = false;

    if (message.isExpirationEnabled === true && !!message.expirationDateTime)
    {
      let currentUtcDate = moment.utc(new Date().toUTCString());
      let formSentUtcDate = moment.utc(message.expirationDateTime);
      if (formSentUtcDate.isSameOrBefore(currentUtcDate))
      {
        isFormSentExpired = true;
      }
    }

    return isFormSentExpired;
  }
}

