import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Router, Route } from '@angular/router';
import { MatBottomSheet, MatDialog } from '@angular/material';
import { Subscriber } from 'src/app/shared/stopfinder/models/subscriber';
import { TFMapType } from '../../../shared/utils/enum';

export enum StateEnum
{
  OpenAttachment = 'open.attachment',
  OpenMap = 'open.map',
}

interface IState extends Route
{
  name?: string;
  template?: any;
  templateUrl?: any;
  controller?: any;
  resolve?: {};
  url?: string;
  params?: any;
  views?: any;
  abstract?: boolean;
  onEnter?: any;
  onExit?: any;
  data?: any;
}

interface IStateService
{
  current: IState;
  /**
   * Used in place of $state.go when making calls to a state.
   * @param stateName
   * @param args
   */
  goState(stateName?: string, ...args: any[]): Promise<boolean>;

  /**
   * Get the fully qualified state name of a state. The base state is needed to know which panel this state name is
   * supposed to be based off of.
   * to the root element of each panel.
   * @param stateName Name of the state to open.
   * @param basePanelName Name of the element that this state is going to be opened
   */
  getActiveState(): string;

  /**
   * Get all enabled states under a route
   * @param routeName - the route name which needs to get its own states
   */
  getRouteStates(routeName: string): Array<string>;

  /**
   * Get current router's base state (default route) name
   * In route settings, default route will own property 'redirectTo'
   */
  getBaseState(): Route;

  /**
   * Leave current state and go to next state if param state provided
   *
   * @param stateName - the next state name where to go
   */
  leaveState(stateName?: string): Promise<boolean>;

  /**
   * Takes the user to a specific route
   * @param routeName - the route name where the user want to go
   * @param data - optional - value to pass
   */
  goRoute(routeName: string, data?: any): Promise<Route>;
}

@Injectable()
export class StateService implements IStateService
{
  public current: IState;
  public activeState: { [name: string]: boolean };
  private baseRoute: Route;

  private childStates: {
    [name: string]: string[]
  };

  // stored properties in state service
  public subscriber: Subscriber = null;

  public externalValidationEvent: Function = null;
  public notificationCallback: Function = null;
  public blockStateChange = [];
  private callbackId: any = null;
  public dialogOpenStatus = false;
  private _mapState: TFMapType;

  constructor(
    private router: Router,
    private materialBottomSheet: MatBottomSheet,
    private materialDialog: MatDialog,
  )
  {
    this.current = {};
    this.activeState = {};
    this.childStates = {};

    this.initBaseRoute();
  }

  private initBaseRoute()
  {
    this.baseRoute = {};
    _.find(this.router.config, (route: Route) =>
    {
      _.has(route, 'redirectTo') && _.merge(this.baseRoute, route);
    });
  }

  private cleanPath(routeName: string): string
  {
    return _.replace(routeName, '/', '')
  }

  private backState()
  {
    switch (_.findKey(this.activeState))
    {
      case StateEnum.OpenAttachment:
        this.closeAttachmentView();
        break;
      case StateEnum.OpenMap:
        this.leaveMap();
        break;
    }
  }

  public goState(stateName?: string): Promise<boolean>
  {
    return new Promise<boolean>(resolve =>
    {
      const routeRegistered = _.has(this.childStates, this.current.name);
      // should register current route if not exist
      !routeRegistered && (this.childStates[this.current.name] = []);
      // should register current state if not exist
      !_.includes(this.childStates[this.current.name], stateName) && this.childStates[this.current.name].push(stateName);
      // should set active state
      this.activeState = { [`${stateName}`]: true }

      resolve(true);
    });
  }

  public getActiveState(): string
  {
    return _.findKey(this.activeState);
  }

  public getRouteStates(routeName: string): Array<string>
  {
    return this.childStates[routeName];
  }

  public getBaseState(): Route
  {
    return this.baseRoute;
  }

  public leaveState(stateName?: string): Promise<boolean>
  {
    return new Promise<boolean>(resolve =>
    {
      if (stateName)
      {
        this.goState(stateName);
      }

      this.activeState = {};
      this.materialDialog && this.materialDialog.closeAll();
      this.materialBottomSheet && this.materialBottomSheet.dismiss();
      resolve(true);
    });
  }

  public goRoute(routeName: string, data?: any): Promise<Route>
  {
    return new Promise<IState>(resolve =>
    {
      const route: Route = _.find(this.router.config, (route: Route) => _.isEqual(route.path, this.cleanPath(routeName)));

      if (_.isUndefined(route))
      {
        return;
      }

      this.current = route;
      this.current.name = route.path

      if (!_.isEmpty(this.activeState))
      {
        this.backState();
        this.activeState = {};
        return resolve(route);
      }

      this.leaveState();
      if (_.isEmpty(routeName))
      {
        this.router.navigate([this.baseRoute.redirectTo, data || {}]);
      } else
      {
        this.router.navigate([routeName, data || {}]);
      }

      resolve(route);
    });
  }

  public leaveMap() { }

  public setMapState(mapType: TFMapType)
  {
    this._mapState = mapType;
  }

  public getMapState(): TFMapType
  {
    return this._mapState;
  }

  public closeAttachmentView() { }

  public setExternalValidation = (validationEvent: Function | null) => this.externalValidationEvent = validationEvent;

  public setNotificationEvent = (callback: Function | null) => this.notificationCallback = callback;

  public hasNotificationEvent = (): boolean => !_.isNull(this.notificationCallback);

  public setCallbackId(id: any)
  {
    this.callbackId = id;
    this.blockStateChange.push(id);
  }

  public getCallbackId = () => this.callbackId;

  public disableCallback(id?: any)
  {
    if (!_.isUndefined(id))
    {
      _.remove(this.blockStateChange, (item) => item === id);
    } else
    {
      this.blockStateChange = [];
      this.callbackId = null;
    }
  }

  public triggerExternalValidation = () => this.externalValidationEvent && this.externalValidationEvent(null);

  public triggerNotificationEvent = () => this.notificationCallback && this.notificationCallback();

  public handleBlockStateCallback(destroy: boolean = true): boolean
  {
    if (this.blockStateChange.length)
    {
      this.triggerNotificationEvent();
      destroy && this.destroyStateChange();
      !destroy && this.disableCallback();
      return false;
    }
    return true;
  }

  public destroyStateChange()
  {
    this.disableCallback();
    this.notificationCallback = null;
    this.externalValidationEvent = null;
  }
}
