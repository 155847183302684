import { Component, OnInit, Input, AfterViewInit, NgZone, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ScheduleService, DateSelectionSource } from '../schedule/schedule.service';
import { getCustomMomentTranslate } from 'src/app/shared/utils/moment-translate';

@Component({
  selector: 'app-scrolling-calendar',
  templateUrl: './scrolling-calendar.component.html',
  styleUrls: ['./scrolling-calendar.component.scss']
})
export class ScrollingCalendarComponent implements OnInit, OnDestroy, AfterViewInit
{
  @Input() selectedDate;
  private readonly calendarDisplay = Array<Display>();
  private readonly calendarDisplaySubject: BehaviorSubject<Array<Display>> = new BehaviorSubject<Array<Display>>([]);
  public readonly calendarDisplayObservable: Observable<Array<Display>> = this.calendarDisplaySubject.asObservable();
  private monthName: string;
  private dateFormat = 'D-M-YYYY';
  private readonly today: string = moment().format(this.dateFormat);
  private calendar: any;
  centerPoints = [];
  heightPrev = 0;
  heightCurrent = 0;
  heightNext = 0;
  heightNextNext = 0;
  currentMiddleOfScreen = (window.scrollY + ((window.innerHeight / 2) - 80));
  paddingForHeader = 70;
  calendarDiv: any;
  private readonly options = {
    month: 'long',
    year: 'numeric'
  };
  public displayYear;
  public newDisplayYear;
  private scrollHandler: () => void;
  public weekDaysShort: string[];

  private readonly currentDate: any = moment();
  constructor(
    private readonly scheduleService: ScheduleService,
    private readonly sanitizer: DomSanitizer,
    private readonly zone: NgZone,
    public translate: TranslateService,
  )
  {
    this.updateTranslate();
    this.translate.onLangChange.subscribe(() =>
    {
      this.updateTranslate();
    });
  }

  onScroll(event)
  {
    this.currentMiddleOfScreen = (this.calendarDiv.scrollTop + ((window.innerHeight / 2) - 80));
    if (this.heightPrev + this.paddingForHeader > this.currentMiddleOfScreen)
    {
      this.newDisplayYear = moment().subtract(1, 'year').year();
    } else if (this.heightCurrent + this.paddingForHeader > this.currentMiddleOfScreen)
    {
      this.newDisplayYear = moment().year();
    } else if (this.heightNext + this.paddingForHeader > this.currentMiddleOfScreen)
    {
      this.newDisplayYear = moment().add(1, 'year').year();
    } else if (this.heightNextNext + this.paddingForHeader > this.currentMiddleOfScreen)
    {
      this.newDisplayYear = moment().add(2, 'year').year();
    }

    if (this.displayYear !== this.newDisplayYear)
    {
      this.zone.run(() =>
      {
        this.displayYear = this.newDisplayYear;
      });
    }
  }

  ngOnInit()
  {
    for (let i = 0; i < 13; i++)
    {
      this.genCalendar(moment().add(i, 'months'));
    }
    this.highlightCurrentDay();

    this.scrollHandler = () => this.onScroll.bind(this)();
  }

  ngOnDestroy()
  {
    window.removeEventListener('scroll', this.scrollHandler);
  }

  ngAfterViewInit()
  {
    this.calendarDiv = document.getElementById("calendars");
    this.highlightCurrentDay();
    const scrollTo = moment(this.selectedDate).format('MMM') + moment(this.selectedDate).year();
    document.getElementById(scrollTo).scrollIntoView();
    const header = document.getElementsByClassName('calendar-header').item(0);
    const insetTop = window.getComputedStyle(header).getPropertyValue('--safeAreaInsetTop').replace('px', '');

    this.calendarDiv.scrollTo(
      {
        top: this.calendarDiv.scrollTop - (100 + (parseInt(insetTop, 10)))
      }
    );

    // Used to calc the height of each year to determine year change
    const calendars = document.getElementsByClassName('calendar');
    const total = 0;
    let heightPrevTemp = 0;
    let heightCurrentTemp = 0;
    let heightNextTemp = 0;
    let heightNextNextTemp = 0;

    if (calendars)
    {
      for (let i = 0; i < calendars.length; i++)
      {
        const calendar = calendars.item(i);
        if (calendar.id.substring(calendar.id.length - 4, calendar.id.length) === moment().subtract(1, 'year').year().toString())
        {
          heightPrevTemp += calendar.scrollHeight;
        } else if (calendar.id.substring(calendar.id.length - 4, calendar.id.length) === `${moment().year()}`)
        {
          heightCurrentTemp += calendar.scrollHeight;
        } else if (calendar.id.substring(calendar.id.length - 4, calendar.id.length) === `${moment().add(1, 'year').year()}`)
        {
          heightNextTemp += calendar.scrollHeight;
        } else if (calendar.id.substring(calendar.id.length - 4, calendar.id.length) === `${moment().add(2, 'year').year()}`)
        {
          heightNextNextTemp += calendar.scrollHeight;
        }
      }
      this.heightPrev = heightPrevTemp;
      this.heightCurrent = heightPrevTemp + heightCurrentTemp;
      this.heightNext = heightPrevTemp + heightCurrentTemp + heightNextTemp;
      this.heightNextNext = heightPrevTemp + heightCurrentTemp + heightNextTemp + heightNextNextTemp;
    }

    this.zone.runOutsideAngular(() =>
    {
      this.calendarDiv.addEventListener('scroll', this.scrollHandler, { passive: true });
    });
    document.body.style.backgroundColor = "#f2f4f4";
    window.scrollTo(0, 0);
  }

  highlightCurrentDay()
  {
    let currentDate = document.getElementById(moment(this.selectedDate).format(this.dateFormat));
    if (currentDate)
    {
      currentDate = document.getElementById(moment(this.selectedDate).format(this.dateFormat)).children[0] as HTMLElement;
      currentDate.className = 'today';
    }
  }

  handleClick(date: string)
  {
    // should be clickable for date is today or after today
    if (moment(date, this.dateFormat).isSameOrAfter(moment(this.today, this.dateFormat)))
    {
      this.scheduleService.setSelectedDate(moment(date, this.dateFormat).toDate(), DateSelectionSource.Calendar);
    }
  }

  isDisabledDate(date: string): boolean
  {
    return moment(date, this.dateFormat).isBefore(moment(this.today, this.dateFormat));
  }

  genCalendar(currentDate: moment.Moment)
  {
    const year: number = currentDate.year();
    const month: number = currentDate.month();
    const start: number = currentDate.startOf('month').day();
    const monthName = moment(currentDate).format('MMM');
    let dow = start;
    let current = 1;
    const display: Display = {
      year: year,
      month: month,
      displayMonth: monthName,
      currentMonth: currentDate.format('MMYYYY') === moment().format('MMYYYY'),
      indent: this.sanitizer.bypassSecurityTrustStyle(`calc( ${start} * (100vw/7))`),
      weeks: []
    };
    const daysInMonth = currentDate.daysInMonth();
    let week: Week = {
      sun: { date: '', blank: true },
      mon: { date: '', blank: true },
      tues: { date: '', blank: true },
      weds: { date: '', blank: true },
      thur: { date: '', blank: true },
      fri: { date: '', blank: true },
      sat: { date: '', blank: true }
    };

    while (current <= daysInMonth)
    {
      const iterateDay = _.get(week, _.get(_.keys(week), dow));

      iterateDay.date = current;
      iterateDay.current = moment().locale(this.translate.currentLang).date() === current && month === moment().month() && moment().year() === year;
      iterateDay.first = current === 1 && start === dow;
      iterateDay.id = `${current}-${month + 1}-${year}`;
      iterateDay.blank = false;
      current++;
      dow++;

      if (dow === 7 || current === daysInMonth + 1)
      {
        dow = 0;
        display.weeks.push(week);
        week = {
          sun: { date: '', blank: true },
          mon: { date: '', blank: true },
          tues: { date: '', blank: true },
          weds: { date: '', blank: true },
          thur: { date: '', blank: true },
          fri: { date: '', blank: true },
          sat: { date: '', blank: true },
        };
      }
    }
    this.calendarDisplay.push(display);
    this.calendarDisplaySubject.next(this.calendarDisplay);
  }

  setToday()
  {
    this.scheduleService.setSelectedDate(new Date(), DateSelectionSource.Calendar);
  }

  private updateTranslate()
  {
    moment.locale(this.translate.currentLang, getCustomMomentTranslate(this.translate.currentLang));
    this.displayYear = moment(this.selectedDate).year();
    this.newDisplayYear = moment(this.selectedDate).year();
    const language = _.isEqual(this.translate.currentLang, 'es') ? 'es-us' : this.translate.currentLang;

    this.weekDaysShort = [
      moment(this.selectedDate).day(0).toDate().toLocaleDateString(language, { weekday: 'narrow' }),
      moment(this.selectedDate).day(1).toDate().toLocaleDateString(language, { weekday: 'narrow' }),
      moment(this.selectedDate).day(2).toDate().toLocaleDateString(language, { weekday: 'narrow' }),
      moment(this.selectedDate).day(3).toDate().toLocaleDateString(language, { weekday: 'narrow' }),
      moment(this.selectedDate).day(4).toDate().toLocaleDateString(language, { weekday: 'narrow' }),
      moment(this.selectedDate).day(5).toDate().toLocaleDateString(language, { weekday: 'narrow' }),
      moment(this.selectedDate).day(6).toDate().toLocaleDateString(language, { weekday: 'narrow' })
    ];
  }
}

export interface Display
{
  year: string | number;
  month: number;
  displayMonth: string;
  currentMonth: boolean;
  indent: any;
  weeks: Week[];
}

export interface Week
{
  mon: Day;
  tues: Day;
  weds: Day;
  thur: Day;
  fri: Day;
  sat: Day;
  sun: Day;
}

export interface Day
{
  date: string | number;
  blank: boolean;
  active?: boolean;
  first?: boolean;
  id?: string;
  current?: boolean;
}
