import * as _ from 'lodash';
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../shared/material/material.module';
import { SharedModule } from '../shared/shared.module';
import { HeaderModule } from '../shared/layout/header.module';

import
{
  SFFormComponent,
  SFFormCoverComponent,
  SFFormQuestionsComponent,
  SFFormAnswerComponent,
  BaseQuestionComponent,
  QuestionHeaderComponent,
  BooleanQuestionComponent,
  DateQuestionComponent,
  DateTimeQuestionComponent,
  MemoQuestionComponent,
  NumberQuestionComponent,
  PhoneQuestionComponent,
  TextQuestionComponent,
  TimeQuestionComponent,
  ZipQuestionComponent,
  SingleListQuestionComponent,
  MultipleListQuestionComponent,
  RateQuestionComponent,
  SystemFieldQuestionComponent,
  CurrencyQuestionComponent,
  AttachmentQuestionComponent,
  SignatureQuestionComponent,
  ProgressBarComponent,
  QuestionComponentsHostDirective,
  FormService,
} from './form.export';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export const HttpLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
    HeaderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      useDefaultLang: true,
      defaultLanguage: 'en'
    })
  ],
  declarations: [
    SFFormComponent,
    SFFormCoverComponent,
    SFFormQuestionsComponent,
    SFFormAnswerComponent,
    BaseQuestionComponent,
    QuestionHeaderComponent,
    BooleanQuestionComponent,
    DateQuestionComponent,
    DateTimeQuestionComponent,
    MemoQuestionComponent,
    NumberQuestionComponent,
    PhoneQuestionComponent,
    TextQuestionComponent,
    TimeQuestionComponent,
    ZipQuestionComponent,
    SingleListQuestionComponent,
    MultipleListQuestionComponent,
    RateQuestionComponent,
    SystemFieldQuestionComponent,
    CurrencyQuestionComponent,
    AttachmentQuestionComponent,
    SignatureQuestionComponent,
    ProgressBarComponent,
    QuestionComponentsHostDirective,
  ],
  exports: [
    SFFormComponent,
    SFFormCoverComponent,
    SFFormQuestionsComponent,
    SFFormAnswerComponent,
    BaseQuestionComponent,
    QuestionHeaderComponent,
    BooleanQuestionComponent,
    DateQuestionComponent,
    DateTimeQuestionComponent,
    MemoQuestionComponent,
    NumberQuestionComponent,
    PhoneQuestionComponent,
    TextQuestionComponent,
    TimeQuestionComponent,
    ZipQuestionComponent,
    SingleListQuestionComponent,
    MultipleListQuestionComponent,
    RateQuestionComponent,
    SystemFieldQuestionComponent,
    CurrencyQuestionComponent,
    AttachmentQuestionComponent,
    SignatureQuestionComponent,
    ProgressBarComponent,
    QuestionComponentsHostDirective,
  ],
  entryComponents: [
    SFFormComponent,
    SFFormCoverComponent,
    SFFormQuestionsComponent,
    SFFormAnswerComponent,
    BaseQuestionComponent,
    QuestionHeaderComponent,
    BooleanQuestionComponent,
    DateQuestionComponent,
    DateTimeQuestionComponent,
    MemoQuestionComponent,
    NumberQuestionComponent,
    PhoneQuestionComponent,
    TextQuestionComponent,
    TimeQuestionComponent,
    ZipQuestionComponent,
    SingleListQuestionComponent,
    MultipleListQuestionComponent,
    RateQuestionComponent,
    SystemFieldQuestionComponent,
    CurrencyQuestionComponent,
    AttachmentQuestionComponent,
    SignatureQuestionComponent,
    ProgressBarComponent,
  ],
  providers: [
    FormService,
    CurrencyPipe
  ]
})
export class FormQuestionModule { }
