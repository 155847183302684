import { Injectable } from "@angular/core";
import { IMapScaleResponse } from "src/app/shared/stopfinder/models/map";
import { INIT_ZOOM_LEVEL, MAP_PADDING_PIXEL, X_MAP_PADDING_PIXEL } from "src/app/shared/utils/constant";
import { TFMapType } from "src/app/shared/utils/enum";
import { NativeMapView } from "src/app/tf-map/core/native-mapview";
import { MapLayerName } from "src/app/tf-map/themes/enums/enum.map-layer";
import { DeviceService } from "../device/device.service";


@Injectable()
export class MapAttendanceService
{

  public static mapType = TFMapType.AttendanceMap;
  public static mapScale = INIT_ZOOM_LEVEL;

  constructor(private readonly deviceService: DeviceService,) { }

  public zoomToBounds(mapId: string): Promise<IMapScaleResponse>
  {
    const padding = this.deviceService.isiOS ? X_MAP_PADDING_PIXEL : MAP_PADDING_PIXEL;
    return new Promise((resolve, reject) =>
    {
      NativeMapView.nativeEsriMapPlugin.getMapLayerExtent(mapId, this.getMapLayers(), padding, (response) => resolve(response), () => reject());
    });
  }

  private getMapLayers(): MapLayerName[]
  {
    return [
      MapLayerName.stopfinderSchool,
      MapLayerName.stopfinderStudent,
      MapLayerName.stopfinderAttendance
    ];
  }
}
