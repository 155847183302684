import * as _ from 'lodash';
import * as moment from 'moment';
import
{
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewEncapsulation,
  NgZone,
  AfterViewInit,
} from '@angular/core';
import
{
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material';
import { IGeoData } from './geo.interface';
import { UnitOfLength, LocalName } from 'src/app/shared/utils/enum';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FEET_MAX, FEET_MIN, METERS_MIN, METERS_MAX } from 'src/app/shared/utils/constant';
import { MapService } from 'src/app/components/service/map/map.service';
import { StopfinderApiService } from 'src/app/shared/stopfinder/stopfinder-api.service';
import
{
  ConfirmationDialogComponent,
  ICallback,
  IDialogInput,
} from 'src/app/shared/layout/confirmation-dialog/confirmation-dialog.component';
import { TargetedBlockingScrollStrategy } from 'src/app/shared/material/targeted-blocking-scroll-strategy';
import { AndroidBackService } from 'src/app/androidBack.service';
import { AppService } from 'src/app/app.service';
import { TooltipService } from '../../../../components/service/tooltip/tooltip.service';
import { IPosition } from 'src/app/shared/tooltip/tooltip.component';
import { TimeRangeEnum } from './geo.interface';
import { MapGeoAlertService, IGeoAlert } from 'src/app/components/service/map/map-geoalert.service';
import { IPoint } from 'src/app/shared/stopfinder/models/map';
import { DeviceService } from 'src/app/components/service/device/device.service';
import { MapCoreService } from 'src/app/components/service/map/map-core.service';

@Component({
  selector: 'geo-add-action',
  templateUrl: './add.geo.component.html',
  styleUrls: ['./actions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddGeoBottomSheetComponent implements AfterViewInit, OnInit, OnDestroy
{
  public autoTicks = false;
  public disabled = false;
  public invert = false;
  public max: number = FEET_MAX;
  public min: number = FEET_MIN;
  public windowHeightOffset = 0;
  public showTicks = false;
  public step = 5;
  public thumbLabel = false;
  public value: number = FEET_MIN;
  public vertical = false;
  public tickInterval = 1;

  public geoForm: FormGroup;
  public formControls;
  public position: IPosition;
  public activeSlide = {
    width: `0px`
  };
  public lengthUnitTranslateText = UnitOfLength.Feet;

  private keyboardAnimating = false;
  private isUpdateNameClicked = false;
  private openedDialogRef: MatDialogRef<ConfirmationDialogComponent, any> = null;

  public timeRangeForm: FormGroup;
  public timeRangeStart: string = `00:00`;
  public timeStartDisplay: string;
  public timeRangeEnd: string = `23:59`;
  public timeEndDisplay: string;

  private apiTimeFormat = `HH:mm`;
  private displayTimeFormat = `hh:mm A`;
  private ios_format = `YYYY-MM-DD`;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { geoData: IGeoData, isEditMode: boolean },
    public _appService: AppService,
    public mapService: MapService,
    public readonly deviceService: DeviceService,
    private element: ElementRef,
    private readonly androidService: AndroidBackService,
    private readonly apiService: StopfinderApiService,
    private readonly bottomSheetRef: MatBottomSheetRef<AddGeoBottomSheetComponent>,
    private readonly dialogRef: MatDialog,
    private readonly geoAlertService: MapGeoAlertService,
    private readonly mapCoreService: MapCoreService,
    private readonly ngZone: NgZone,
    private readonly tooltipService: TooltipService,
    private readonly translate: TranslateService,
  )
  {
    moment.locale(this.translate.currentLang);

    this.setDistanceUnit();

    if (this.tooltipService.tooltip)
    {
      this.tooltipService.isTooltipEnabled = false;
    }

    if (this.data && this.data.geoData)
    {
      // set label value
      this.value = Number((this.data.isEditMode ? this.geoAlertService.getDistance(this.data.geoData.distance, true) : this.geoAlertService.defaultDistance).toFixed(0));

      if (!this.data.isEditMode)
      {
        this.data.geoData.startTime = this.timeRangeStart;
        this.data.geoData.endTime = this.timeRangeEnd;
      }
    }

    this.geoForm = new FormGroup({
      name: new FormControl({ value: _.get(this.data.geoData, 'name'), disabled: true }),
      enter: new FormControl({ value: _.get(this.data.geoData, 'enterAlert'), disabled: false }),
      exit: new FormControl({ value: _.get(this.data.geoData, 'exitAlert'), disabled: false }),
    });

    this.defaultTimeRangeInit();

    this.timeRangeForm = new FormGroup({
      startTimeInput: new FormControl({ value: this.timeRangeStart }),
      endTimeInput: new FormControl({ value: this.timeRangeEnd })
    });

    this.formControls = this.geoForm.controls;

    this.setGeoName(_.get(this.data.geoData, 'name'));

    this.geoForm.get('name').valueChanges.subscribe((nameValue: string) =>
    {
      this.data.geoData.name = nameValue;
      this.updateGeoAlert();
    });

    this.geoForm.get('enter').valueChanges.subscribe((enterValue: boolean) =>
    {
      this.data.geoData.enterAlert = enterValue;
      this.updateGeoAlert();
    });

    this.geoForm.get('exit').valueChanges.subscribe((exitValue: boolean) =>
    {
      this.data.geoData.exitAlert = exitValue;
      this.updateGeoAlert();
    });

    this.timeRangeForm.get('startTimeInput').valueChanges.subscribe((newTime: string) => this.updateTimeInput(newTime, TimeRangeEnum.start));

    this.timeRangeForm.get('endTimeInput').valueChanges.subscribe((newTime: string) => this.updateTimeInput(newTime, TimeRangeEnum.end));

    // must be bind this here to prevent the event not remove
    this.hideMapTools = this.hideMapTools.bind(this);
    this.keyboardStopAnimating = this.keyboardStopAnimating.bind(this);
    this.calcActivatedSlider();
  }

  private defaultTimeRangeInit()
  {
    // by default start value
    this.timeStartDisplay = this.translateTime(this.timeRangeStart, this.displayTimeFormat);
    // by default end value
    this.timeEndDisplay = this.translateTime(this.timeRangeEnd, this.displayTimeFormat);

    if (this.data.isEditMode)
    {
      this.timeRangeStart = this.translateTime(this.data.geoData.startTime);
      this.timeStartDisplay = this.translateTime(this.data.geoData.startTime, this.displayTimeFormat);
      this.timeRangeEnd = this.translateTime(this.data.geoData.endTime);
      this.timeEndDisplay = this.translateTime(this.data.geoData.endTime, this.displayTimeFormat);
    }
  }

  private translateTime = (time: string, format: string = this.apiTimeFormat) => moment(`${moment().utc().format(this.ios_format)}T${time}`).format(format);

  private updateGeoTimeRange = (time: string, endWith: string = `:00.999`): string => `${time}${endWith}`;

  private updateTimeInput(time: string, type: TimeRangeEnum, endWith: string = `:00.999`)
  {
    switch (type)
    {
      case TimeRangeEnum.start:
        // assign default start 00:00 to reset event
        _.isEmpty(time) && (time = this.timeRangeStart);
        const validStartTime = this.timeRangeValidation(time, this.timeRangeEnd, type);
        this.timeRangeStart = `${validStartTime}`;
        this.timeStartDisplay = this.translateTime(validStartTime, this.displayTimeFormat);
        this.data.geoData.startTime = this.updateGeoTimeRange(validStartTime, endWith);
        // update time range start value, with force no emit
        this.timeRangeForm.get('startTimeInput').setValue(this.timeRangeStart, { emitEvent: false });
        return;
      case TimeRangeEnum.end:
        // assign default start 23:59 to reset event
        _.isEmpty(time) && (time = this.timeRangeEnd);
        const validEndTime = this.timeRangeValidation(this.timeRangeStart, time, type);
        this.timeRangeEnd = `${validEndTime}`;
        this.timeEndDisplay = this.translateTime(validEndTime, this.displayTimeFormat);
        this.data.geoData.endTime = this.updateGeoTimeRange(validEndTime, endWith);
        // update time range end value, with force no emit
        this.timeRangeForm.get('endTimeInput').setValue(this.timeRangeEnd, { emitEvent: false });
        return;
    }
  }

  private timeRangeValidation(startTimeValue: string, endTimeValue: string, type: TimeRangeEnum): string
  {
    const YMD = moment().utc().format(this.ios_format);
    const prefix: string = 'T';

    const startRange = `00:00`;
    const endRange = `23:59`;

    const resetBeforeMax = `23:58`;
    const resetAfterMin = `00:01`;

    const maxTimeThreshold = moment(`${YMD}${prefix}${endRange}`);    // YYYY-MM-DDT23:59
    const minTimeThreshold = moment(`${YMD}${prefix}${startRange}`);  // YYYY-MM-DDT00:00

    const selectedStartTime = moment(`${YMD}${prefix}${startTimeValue}`);
    const selectedEndTime = moment(`${YMD}${prefix}${endTimeValue}`);

    switch (type)
    {
      case TimeRangeEnum.start:
        // start time is min: 00:00
        if (selectedStartTime.isSame(minTimeThreshold) && selectedEndTime.isSame(minTimeThreshold))
        {
          // end time cannot be the min threshold time
          return resetAfterMin;
        }
        if (selectedStartTime.isSameOrAfter(selectedEndTime))
        {
          return selectedEndTime.subtract(1, 'minutes').format(this.apiTimeFormat);
        }
        return startTimeValue;
      case TimeRangeEnum.end:
        // end time is the max: 23:59
        if (selectedEndTime.isSame(maxTimeThreshold) && selectedStartTime.isSame(maxTimeThreshold))
        {
          // start time cannot be max threshold time
          return resetBeforeMax;
        }
        // end time >= start time
        if (selectedEndTime.isSameOrBefore(selectedStartTime))
        {
          return selectedStartTime.add(1, 'minutes').format(this.apiTimeFormat);
        }
        return endTimeValue;
    }
  }

  public async updateGeoAlert()
  {
    const geoAlertId = this.data.isEditMode ? this.data.geoData.id : -1;
    const center: IPoint = await this.mapCoreService.getMapCenter(this.mapService.mapId);
    const geoAlert: IGeoAlert = {
      id: geoAlertId,
      name: this.data.geoData.name,
      longitude: center.longitude,
      latitude: center.latitude,
      distance: this.data.geoData.distance,
      fill: this.geoAlertService.getFillColor(this.data.geoData.enterAlert, this.data.geoData.exitAlert),
    };
    this.mapService.addGeoAlertPolygon(geoAlert);
  }

  private calcActivatedSlider()
  {
    const range = Math.round(this.max - this.min);
    const percentage = Math.round((this.value - this.min) * 100 / range);

    if (_.isEqual(this.value, this.min))
    {
      this.activeSlide = {
        width: `0px`
      };
    } else
    {
      this.activeSlide = {
        width: `${percentage}%`
      };
    }
  }

  public onInputChange(value: any)
  {
    this.ngZone.run(() =>
    {
      this.value = Number(value.target.value);
      this.calcActivatedSlider();
      this.data.geoData.distance = this.geoAlertService.getDistance(this.value, false);
      this.updateGeoAlert();
    });
  }

  ngOnInit()
  {
    // actions bottom sheet will broadcast 3 different status to geo.component.ts
    // 1. create mode, back button should trigger cancel dialog then be blocked till one option selected
    // 2. view and edit mode, back button should trigger cancel dialog then be blocked till one option selected
    // 3. delete mode, back button should trigger delete dialog then act as the same as Yes button
    // 4. rename mode
    this.androidService.onShouldCheckCallback(() =>
    {
      if (this.isUpdateNameClicked)
      {
        this.openedDialogRef && this.openedDialogRef.close();
        return;
      }
      // this value watched in geo.component.ts
      // there is an ngOnChanges registered to watch the value and trigger the cancel event there
      this.mapService.shouldTriggerOnBackHook = !this.mapService.shouldTriggerOnBackHook;
    });

    // hide geo alert button
    this.geoAlertService.onUIEditMode = true;
    window.addEventListener('keyboardDidShow', this.keyboardStopAnimating);

    this.lengthUnitTranslateText = this.translate.instant(`geo.add.geo.alert.length.unit.${this.geoAlertService.distanceUnit}`);
  }

  ngAfterViewInit()
  {
    this.bottomSheetRef.afterOpened().subscribe(() =>
    {
      this.updateCompassPosition();
    });

    this._appService.isMapToolOpened.subscribe((isOpened: boolean) =>
    {
      isOpened && this.element.nativeElement.classList.add('hide-bottom-sheet');
      !isOpened && this.element.nativeElement.classList.remove('hide-bottom-sheet');
    });
  }

  ngOnDestroy()
  {
    if (this.tooltipService.tooltip)
    {
      this.tooltipService.isTooltipEnabled = true;
    }

    window.removeEventListener('resize', this.hideMapTools);

    this.mapService.compassPosition = {
      position: 'absolute',
      bottom: `22px`
    };

    // show geo alert button
    this.geoAlertService.onUIEditMode = false;
    window.removeEventListener('keyboardDidShow', this.keyboardStopAnimating);
  }

  private keyboardStopAnimating = () => this.keyboardAnimating = false;

  private updateCompassPosition(isSheetOpened: boolean = true)
  {
    // size 22 is due to the default bottom position size in compass.component.scss
    const gapSize = 22;
    const bottomSize: number = isSheetOpened ? this.element.nativeElement.clientHeight + gapSize : gapSize;

    this.mapService.compassPosition = {
      position: 'absolute',
      bottom: `${bottomSize}px`
    };
  }

  public getSliderTickInterval(): number | 'auto'
  {
    if (this.showTicks)
    {
      return this.autoTicks ? 'auto' : this.tickInterval;
    }
    return 0;
  }

  async setDistanceUnit()
  {
    const { Items } = await this.apiService.getLocalization(this.mapService.studentSchedule.clientId).toPromise();
    let localNameString: string = Items && Items[0] && Items[0].LocalName;
    if (Items.length > 1)
    {
      localNameString = Items.reduce((output, item) => `${output.LocalName}$$$$${item.LocalName}`);
    }
    localNameString = (localNameString || '').toLowerCase();
    if (this.geoAlertService.distanceUnit !== UnitOfLength.Feet && localNameString.includes(LocalName.US.toLowerCase()))
    {
      this.setDistanceMaxAndMin(UnitOfLength.Feet);
      return;
    }
    if (this.geoAlertService.distanceUnit !== UnitOfLength.Meters && localNameString.includes(LocalName.Canada.toLowerCase()))
    {
      this.setDistanceMaxAndMin(UnitOfLength.Meters);
    }
  }

  private setDistanceMaxAndMin(unit: UnitOfLength)
  {
    this.geoAlertService.distanceUnit = unit;
    const _min = unit === UnitOfLength.Meters ? METERS_MIN : FEET_MIN;
    const _max = unit === UnitOfLength.Meters ? METERS_MAX : FEET_MAX
    this.min = _min;
    this.max = _max;
    this.geoAlertService.defaultDistance = _min;
    if (!this.data.isEditMode)
    {
      this.value = _min;
      this.geoForm.get('foot').setValue(this.value);
    }
    this.updateGeoAlert();
  }

  public updateGeoName()
  {
    // should enable unique validation for geo name
    const uniqueCheck = (result: ICallback): Promise<IDialogInput> =>
    {
      return new Promise<IDialogInput>(resolve =>
      {
        // should validate empty value
        if (_.isEmpty(_.trim(result.message)))
        {
          return resolve({
            isEmpty: true,
            errorMsg: this.translate.instant("geo.add.modal.add.name.required"),
          } as IDialogInput);
        }
        // should validate duplicate value
        this.apiService.checkGeoAlertNotTaken(-1, this.mapService.geoData.riderId, result.message)
          .toPromise().then((isNotDuplicated: boolean) =>
          {
            if (!isNotDuplicated)
            {
              return resolve({
                errorMsg: this.translate.instant("geo.add.modal.add.name.duplicated"),
                isError: true,
              } as IDialogInput);
            }
            // should pass okay to confirm dialog if all validation passed
            return resolve({
              isError: false,
            } as IDialogInput);
          });
      });
    };

    const sizeTop: number = Math.round(this.geoAlertService.calculateDialogTopValue());
    this.keyboardAnimating = true;
    // in case the keyboard doesn't show up.
    setTimeout(() =>
    {
      this.keyboardAnimating = false;
    }, 1000);
    // should prepare dialog config
    const config: MatDialogConfig = {
      data: {
        inputValue: this.data.geoData.name,
        inputLabel: this.translate.instant("geo.add.modal.add.name"),
        title: this.translate.instant("geo.add.modal.add.title"),
        enableInput: true,
        input: {
          maxLength: 100,
          isError: false,
          isEmpty: false,
        },
        action: this.translate.instant("geo.add.modal.add.save"),
        secondary: true,
        secondaryAction: this.translate.instant("geo.add.modal.add.cancel"),
        secondaryCallback: () =>
        {
          if (this.keyboardAnimating)
          {
            return;
          }
          this.openedDialogRef.close();
        },
        promiseCallback: uniqueCheck,
      },
      // set to true to disable overlay clickable to close the dialog
      disableClose: true,
      scrollStrategy: new TargetedBlockingScrollStrategy(),
      panelClass: ['confirm-dialog', `confirm-dialog-top-${_.isNull(sizeTop) ? 180 : sizeTop}`],
    };

    this.windowHeightOffset = document.querySelector('.mat-bottom-sheet-container').clientHeight;
    this.element.nativeElement.classList.add('hide-bottom-sheet');
    // should hide map compass and geo alert icon when keyboard shows up
    this.mapService.shouldHideMapTools = true;
    this.isUpdateNameClicked = true;
    // should open dialog to let user input geo name
    this.openedDialogRef = this.dialogRef.open(ConfirmationDialogComponent, config);

    window.removeEventListener('resize', this.hideMapTools);
    window.addEventListener('resize', this.hideMapTools);

    this.openedDialogRef.afterClosed().subscribe((geoName: string) =>
    {
      if (geoName)
      {
        this.geoForm.get('name').setValue(geoName);
        this.setGeoName(geoName);
      }
      this.isUpdateNameClicked = false;
    });

    this.openedDialogRef.backdropClick().subscribe(() =>
    {
      if (this.keyboardAnimating)
      {
        return;
      }
      this.openedDialogRef.close();
    });
  }

  private hideMapTools(e)
  {
    setTimeout(() =>
    {
      const currentHeight = window.innerHeight;
      if (this._appService.windowHeight - currentHeight <= this.windowHeightOffset)
      {
        this.element.nativeElement.classList.remove('hide-bottom-sheet');
        this.updateCompassPosition();
        this.ngZone.run(() => this.mapService.shouldHideMapTools = false);
      }
      else
      {
        this.element.nativeElement.classList.add('hide-bottom-sheet');
        this.updateCompassPosition(false);
        this.ngZone.run(() => this.mapService.shouldHideMapTools = true);
      }
    }, 100);
  }

  private setGeoName = (name: string) => this.mapService.geoData.name = name;

}
