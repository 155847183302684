import * as _ from 'lodash';
import
{
  Component,
  ViewEncapsulation,
  OnDestroy,
  Input,
  Output,
  OnInit,
  EventEmitter,
  TemplateRef,
  ViewChild,
  AfterViewInit,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import
{
  MAT_STEPPER_GLOBAL_OPTIONS,
  StepperSelectionEvent,
} from '@angular/cdk/stepper';

import
{
  FormBuilder,
  Validators,
} from '@angular/forms';

import
{
  IStepFlow,
  IStepBaseSteps,
  IStepIconBase,
  StepperTypeEnum,
} from '../../shared/interface/step-flow.interface';
import { MatStepper } from '@angular/material';

const EDIT_STEP_STATE = 'edit';
const DEFAULT_STEP_STATE = 'default';

/**
 * Parameters:
 * @param: stepConfig             - Mandatory, step data to generate the stepper
 * @returns: IStepFlow
 * 
 * @param: editIcon               - Optional, default value is EDIT_STEP_STATE
 * @returns: string
 * 
 * @param: defaultIcon            - Optional, default value is DEFAULT_STEP_STATE
 * @param: tempStepTemplate       - Optional, template for additional info of temp step
 * @param: repeatTempStepTemplate - Optional, template for repeated temp step additional info if different with default temp step
 * @param: template               - Optional, template for normal step additional info
 * @param: stepSelectionChange    - Optional, component will emit selection change event to caller
 * @param: stepLabelClick         - Optional, component will emit label click event to caller
 * @param: stepPreLabelClick      - Optional, component will emit prefix label click event to caller
 * 
 * @example
 * class: has-prefix    - needs to set if there should set a prefix label before stepper

    <sf-step-flow
      class="has-prefix"
      editIcon="radio_button_checked"
      defaultIcon="radio_button_unchecked"
      [stepConfig]="Function"
      (stepSelectionChange)="Function"
      (stepLabelClick)="Function"
      (stepPreLabelClick)="Function"
    ></sf-step-flow>

 */
@Component({
  selector: 'sf-step-flow',
  templateUrl: './step-flow.component.html',
  styleUrls: ['./step-flow.component.scss'],
  providers: [{
    provide: MAT_STEPPER_GLOBAL_OPTIONS,
    useValue: { displayDefaultIndicatorType: false }
  }],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepFlowComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @Input() stepConfig: IStepFlow = null;
  @Input() editIcon: string;
  @Input() defaultIcon: string;

  @Input() tempStepTemplate: TemplateRef<any>;
  @Input() repeatTempStepTemplate: TemplateRef<any>;
  @Input() template: TemplateRef<any>

  @Output() stepSelectionChange: EventEmitter<StepperSelectionEvent> = new EventEmitter<StepperSelectionEvent>();
  @Output() stepLabelClick: EventEmitter<number> = new EventEmitter<number>();
  @Output() stepPreLabelClick: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  private _linear = false;
  private _disableRipple = true;
  private _complete = false;
  private _editable = false;
  private _isPassed: StepperTypeEnum;

  public steps: IStepBaseSteps[];

  constructor(
    private readonly _formBuilder: FormBuilder,
    public translate: TranslateService,
  )
  { }

  ngOnInit()
  {
    this.steps = this.stepConfig.steps;
    this.generateStepGroup(this.steps);
  }

  ngAfterViewInit()
  {
  }

  ngOnChanges(changes: SimpleChanges): void
  {
    if (changes && changes.stepConfig && !changes.stepConfig.isFirstChange())
    {
      const previous: IStepFlow = changes.stepConfig.previousValue;
      const current: IStepFlow = changes.stepConfig.currentValue;
      if (!_.isEqual(previous.steps, current.steps))
      {
        this.steps = this.stepConfig.steps;
      }
    }
  }

  ngOnDestroy()
  {
    this.stepConfig = null;
    this.steps = null;
  }

  get isPassed()
  {
    return _.isEqual(this._isPassed, StepperTypeEnum.vertical);
  }

  set isLinear(value: boolean)
  {
    this._linear = value;
  }

  get isLinear()
  {
    return this._linear;
  }

  set disableRipple(value: boolean)
  {
    this._disableRipple = value;
  }

  get disableRipple()
  {
    return this._disableRipple;
  }

  set isCompleted(value: boolean)
  {
    this._complete = value;
  }

  get isCompleted()
  {
    return this._complete;
  }

  set isEditable(value: boolean)
  {
    this._editable = value;
  }

  get isEditable()
  {
    return this._editable;
  }

  private beforeEachValidation(stepperConfig: IStepFlow): StepperTypeEnum | null
  {
    if (!_.get(stepperConfig, 'steps') || _.isEmpty(stepperConfig.steps))
    {
      return null;
    }
    return StepperTypeEnum[stepperConfig.type] || null;
  }

  public afterEachValidation(stepConfig: IStepFlow): StepperTypeEnum
  {
    this._isPassed = this.beforeEachValidation(stepConfig);
    return this._isPassed;
  }

  private generateStepGroup(steps: IStepBaseSteps[])
  {
    _.forEach(steps, (step: IStepBaseSteps) =>
    {
      this[step.stepControl] = this._formBuilder.group({
        [step.stepControl]: ['', Validators.required]
      });
    });
  }

  public generateState(iconList: IStepIconBase[], index: number): string
  {
    if (iconList && iconList.length)
    {
      return iconList[index].stateName;
    }
    return _.isEqual(index, 0) ? EDIT_STEP_STATE : DEFAULT_STEP_STATE;
  }

  public setActiveStep(index: number): Promise<number>
  {
    return new Promise<number>((resolve, reject) =>
    {
      if ((index - 1) > this.stepper.steps.length)
      { // out of range
        reject(this.stepper.selectedIndex);
      }
      this.stepper.selectedIndex = index;
      resolve(index);
    });
  }
}
