import { Component, ViewEncapsulation, Input } from "@angular/core";

/**
 * Example:
 * Using outer condition to show or hide loading spinner
 * Loading spinner will have the highest z-index that cover all above child elements
 * <ng-container *ngIf="snackBarOpened">
 *   <refresh-snackbar></refresh-snackbar>
 * </ng-container>
 */

enum SpinnerMode
{
  INDETERMINATE = 'indeterminate',
  DETERMINATE = 'determinate',
}

@Component({
  selector: 'refresh-snackbar',
  templateUrl: './refresh-snackbar.component.html',
  styleUrls: ['./refresh-snackbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RefreshSnackBar
{
  // spinner mode, indeterminate or determinate
  @Input() spinnerMode: string = SpinnerMode.INDETERMINATE;
  @Input() spinnerColor = '#f33';
  @Input() spinnerProgress = 0;
  @Input() spinnerDiameter = 50;
  @Input() isOverlay = false;

  constructor() { }
}
