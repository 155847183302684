import { TemplateRef } from "@angular/core";

export interface IStepFlow extends IStepBase { }

export enum StepperTypeEnum
{
  vertical = 'vertical',
  horizontal = 'horizontal',
}

interface IStepBase
{
  type: StepperTypeEnum;
  steps: IStepBaseSteps[];
  icon?: IStepIconBase[];
}

export interface ITempStep extends IStepBaseSteps
{
  isTempStep: boolean;
  repeatTempStep?: boolean;
}

export interface IStepIconBase
{
  icon: string;
  stateName: string;
}

export interface IStepPlaceholder
{
  title: string;
  subTitle?: string;
  endWith?: string;
}

export interface IStepBaseSteps
{
  stepControl: string;
  title: string;
  subtitle?: string;
  message?: string;
  prefix?: IStepPlaceholder;
  content?: TemplateRef<any>;
}
