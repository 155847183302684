import { Component, OnInit, ElementRef, NgZone, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from '../../shared/stopfinder/models/subscription';
import { FormControl, FormGroup, Validators, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { MyErrorStateMatcher } from '../../schedule/share-subscription/share-subscription.component';
import { MatDialog } from '@angular/material';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { TargetedBlockingScrollStrategy } from '../../shared/material/targeted-blocking-scroll-strategy';
import { ManageSubscriptionService } from '../../settings/manage-subscriptions/manage-subscriptions.service';
import { StopfinderApiService } from '../../shared/stopfinder/stopfinder-api.service';
import { ConfirmationDialogComponent } from '../../shared/layout/confirmation-dialog/confirmation-dialog.component';
import { StateService } from '../../components/service/state/state.service';
import { AndroidBackService } from '../../androidBack.service';
import { AppService } from '../../app.service';
import { Language } from 'src/app/shared/utils/enum';
import { LocalStorageService } from 'src/app/shared/local-storage/local-storage.service';
import { DeviceService } from 'src/app/components/service/device/device.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileComponent implements OnInit
{
  public matcher;
  public profileForm: FormGroup;
  private formControls;
  private emailCheckTimeout;
  private subscriber;
  public spinnerOpened = false;
  public subscriptionsSharedWithMe = new Array<Subscription>();
  public updateLanguageTrigger: number; // reload the translate on pipe

  constructor(
    public _appService: AppService,
    public readonly deviceService: DeviceService,
    private readonly stopfinderApi: StopfinderApiService,
    private readonly dialog: MatDialog,
    private readonly manageSub: ManageSubscriptionService,
    private stateService: StateService,
    private readonly androidBackService: AndroidBackService,
    private readonly ngZone: NgZone,
    private readonly translate: TranslateService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly localStorageService: LocalStorageService,
  )
  {
    this.manageSub.getSubscriptionsShareWithOwner();
    this.manageSub.sortedSubscriptionShareWithOwnerObservable.subscribe((sub) =>
    {
      this.subscriptionsSharedWithMe = sub;
    });

    this.spinnerOpened = true;

    this.profileForm = new FormGroup({
      email: new FormControl({ value: this.subscriber ? this.subscriber.email : '', disabled: false }, [Validators.required, Validators.email, this.customEmailAfterTrimValidators()], this.validateEmail.bind(this)),
      first: new FormControl({ value: this.subscriber ? this.subscriber.firstName : '', disabled: false }, [Validators.required]),
      last: new FormControl({ value: this.subscriber ? this.subscriber.lastName : '', disabled: false }, [Validators.required]),
      cell: new FormControl({ value: this.subscriber ? this.subscriber.mobilePhoneNumber : '', disabled: false }, [Validators.required]),
    });
    this.formControls = this.profileForm.controls;

    this.stopfinderApi.getCurrentSubscriber().subscribe((sub) =>
    {
      this.subscriber = sub;
      this.setUserInfo();
      this.spinnerOpened = false;
    });
    this.matcher = new MyErrorStateMatcher();

    this._appService.lastLocation = `schedule`;
    this.getSubscriberLanguage();

    window.addEventListener('keyboardDidHide', () =>
    {
      this.showHideAppHelper(false);
    });

    window.addEventListener('keyboardDidShow', () =>
    {
      this.showHideAppHelper(true);
    });
  }

  ngOnInit()
  {
    this.androidBackService.onShouldCheckCallback(this.cancel.bind(this));
    // register external validation into state service
    this.stateService.disableCallback();
    this.stateService.setExternalValidation(this.cancel.bind(this));
  }

  ngOnDestroy()
  {
    this.androidBackService.onDestroyCallback();
    this.stateService.destroyStateChange();
  }

  cancel(elRef?: ElementRef)
  {
    this.ngZone.run(() =>
    {
      if (!this.profileForm.pristine)
      {
        this.androidBackService.onDisableMultipleTarget(true);
        this.dialog
          .open(ConfirmationDialogComponent, {
            disableClose: false,
            data: {
              title: this.translate.instant("profile.modal.cancel.title"),
              message: this.translate.instant("profile.modal.cancel.body"),
              action: this.translate.instant("profile.modal.cancel.yes"),
              secondary: true,
              secondaryAction: this.translate.instant("profile.modal.cancel.no"),
            },
            scrollStrategy: new TargetedBlockingScrollStrategy(),
            panelClass: "confirm-dialog"
          })
          .afterClosed()
          .subscribe(response =>
          {
            this.androidBackService.onDisableMultipleTarget(false);
            if (response)
            {
              this.stateService.handleBlockStateCallback(true) && this.back();
              return;
            }
            this.stateService.disableCallback();
          });
      } else
      {
        this.stateService.handleBlockStateCallback(false) && this.back();
      }
    });
  }

  back()
  {
    this.stateService.goRoute('schedule');
  }

  resetPassword()
  {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      disableClose: true,
      data: this.subscriber,
      scrollStrategy: new TargetedBlockingScrollStrategy()
    });

    // should add dismiss callback / validation once overlay been clicked.
    dialogRef.backdropClick().subscribe((value) =>
    {
      dialogRef.componentInstance.onCancel(value);
    });

    dialogRef.afterClosed().subscribe((value) => { });
  }

  saveChanges()
  {
    const first = this.formControls['first'].value;
    const last = this.formControls['last'].value;
    const cell = this.formControls['cell'].value;
    const email = this.formControls['email'].value;
    this.manageSub.patchFromProfile(this.subscriber, {
      id: this.subscriber.id,
      firstName: first,
      lastName: last,
      email: email,
      mobilePhoneNumber: (cell || '').replace(/\D/g, ''),
    }).subscribe(value => this.subscriber = value);

    this.profileForm.updateValueAndValidity();
    this.profileForm.markAsPristine();
  }

  setUserInfo()
  {
    this.profileForm.setValue({
      first: this.subscriber.firstName,
      last: this.subscriber.lastName,
      cell: this.subscriber.mobilePhoneNumber,
      email: this.subscriber.email,
    })
  }

  customEmailAfterTrimValidators(): ValidatorFn
  {
    return (control: FormControl): ValidationErrors =>
    {
      const value = control.value.subscriberEmail ? control.value.subscriberEmail.trimRight() : control.value.trimRight();
      if (value == null || value.length === 0)
      {
        return null; // don't validate empty values to allow optional controls
      }
      const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return EMAIL_REGEXP.test(value) ? null : { 'email': true };
    }
  }

  validateEmail({ value }: AbstractControl): any
  {
    // Due to angular's behavior, this will be fired multiple times, don't need to send multiple request to check same email.
    clearTimeout(this.emailCheckTimeout);
    return new Promise((resolve) =>
    {
      this.emailCheckTimeout = setTimeout(() =>
      {
        this.stopfinderApi.checkEmailNotTaken(value)
          .subscribe(
            response =>
            {
              if (response)
              {
                return resolve(null);
              }
              else
              {
                return resolve({ isTaken: true });
              }
            });
      }, 100);
    });
  }

  onChangeLanguage(lang: Language)
  {
    this.translate.use(lang).subscribe(data =>
    {
      this.ngZone.run(() =>
      {
        this.localStorageService.set('subscriberLanguage', lang);
        this.translate.currentLang = lang;
        this.updateLanguageTrigger = Math.random(); // if you need update the pipe translate, you must change this value
        this._appService.updatedLanguageEvent.emit(data);
      });
    })
  }

  changeLanguage()
  {
    const listPickerInstance = this._appService.listPickerInstance;

    if (!listPickerInstance)
    {
      return;
    };

    const config = {
      items: [
        { text: 'English', value: Language.en },
        { text: 'Español', value: Language.es },
        { text: 'Français', value: Language.fr },
      ],
      selectedValue: this.translate.currentLang,
      doneButtonLabel: this.translate.instant("profile.language.done"),
      cancelButtonLabel: this.translate.instant("profile.language.cancel")
    };

    this.updateClass(true);
    // Show the picker
    listPickerInstance.showPicker(config,
      (value) =>
      {
        this.ngZone.run(() =>
        {
          this.updateLanguage(value).subscribe(() => this.onChangeLanguage(value));
          this.updateClass(false);
          this.changeDetectorRef.markForCheck();
        });
      },
      () =>
      {
        this.ngZone.run(() =>
        {
          this.updateClass(false);
        });
      }
    );
  }

  private updateLanguage(value: string)
  {
    return this.stopfinderApi.changeLanguage(value);
  }

  private updateClass(isShow: boolean)
  {
    if (this.deviceService.isAndroid || this.deviceService.isiPad) return;

    const mainContent = document.getElementById('main-content-area');
    const header = document.getElementById('header');
    const overlay = document.getElementById('app-layout');
    const mainNav = header.getElementsByClassName('main-nav');
    if (isShow)
    {
      document.body.style.backgroundColor = "transparent";
      mainContent && mainContent.classList.add('opacity');
      overlay && overlay.classList.add('opacity');
      header && header.classList.add('opacity');
      mainNav && mainNav[0] && mainNav[0].classList.add('opacity');
    }
    else
    {
      document.body.style.backgroundColor = "#fff";
      mainContent && mainContent.classList.remove('opacity');
      overlay && overlay.classList.remove('opacity');
      header && header.classList.remove('opacity');
      mainNav && mainNav[0] && mainNav[0].classList.remove('opacity');
    }

    this.showHideAppHelper(isShow);
  }

  private getSubscriberLanguage()
  {
    this.stopfinderApi.getCurrentSubscriberLanguage().subscribe((value) =>
    {
      if (value && (value === Language.en || value === Language.es || value === Language.fr) && value !== this.translate.currentLang)
      {
        this.onChangeLanguage(value);
      }
    });
  }

  private showHideAppHelper(isShow: boolean)
  {
    const appHelper = document.getElementById('app-help');
    if (!appHelper) return;

    isShow ? appHelper.classList.add('opacity') :
      appHelper.classList.remove('opacity');
  }
}
