import * as _ from 'lodash';
import { Injectable } from "@angular/core";
import { GraphicNameEnum, IGraphic, IPoint } from "src/app/shared/stopfinder/models/map";
import { IAttendance, StudentSchedule, TripSchedule } from "src/app/shared/stopfinder/stopfinder-models";
import { EsriGeometryHelper } from "src/app/tf-map/core/esri-geometry-helper";
import { NativeMapView } from "src/app/tf-map/core/native-mapview";
import { MapLayerName } from "src/app/tf-map/themes/enums/enum.map-layer";
import { SymbolType } from "src/app/tf-map/themes/enums/enum.symbol-type";

@Injectable()
export class MapGraphicsService
{
  constructor() { }

  public studentStopGraphic = [];

  public generateGraphicsBasedOnEnum()
  {
    _.forEach(GraphicNameEnum, (eachGraphic: GraphicNameEnum) =>
    {
      this.setLayerGraphic(eachGraphic, []);
    });
  }

  public addMapGraphics(graphics: IGraphic[]): Promise<void>
  {
    return new Promise(async (resolve, reject) =>
    {
      NativeMapView.nativeEsriMapPlugin.addGraphics(graphics, () => { return resolve(); }, () => { return reject(); });
    });
  }

  public createSchoolGraphic(mapId: string, studentSchedule: StudentSchedule): IGraphic
  {
    const graphicName = GraphicNameEnum.schoolGraphic;
    const graphic: IGraphic = {
      mapId: mapId,
      layerId: MapLayerName.stopfinderSchool,
      graphicId: graphicName,
      geometryData: EsriGeometryHelper.toPointGeometryJSON(studentSchedule.schoolX, studentSchedule.schoolY),
      symbolName: SymbolType.sfSchoolAddressPictureSymbol,
      clearLayer: false,
      attributes: {
        text: studentSchedule.school,
        type: graphicName
      }
    };
    return graphic;
  }

  public createStudentGraphic(mapId: string, studentSchedule: StudentSchedule): IGraphic
  {
    const graphicName = GraphicNameEnum.studentGraphic;
    const graphic: IGraphic = {
      mapId: mapId,
      layerId: MapLayerName.stopfinderStudent,
      graphicId: graphicName,
      geometryData: EsriGeometryHelper.toPointGeometryJSON(studentSchedule.xCoord, studentSchedule.yCoord),
      symbolName: SymbolType.sfStudentAddressPictureSymbol,
      clearLayer: false,
      attributes: {
        type: graphicName,
        text: `${studentSchedule.firstName} ${studentSchedule.lastName}`
      }
    };
    return graphic;
  }

  /**
   * createStopGraphic
   */
  public createStopGraphic(mapId: string, graphicName: GraphicNameEnum, trip: TripSchedule, point: IPoint): IGraphic
  {
    const graphic: IGraphic = {
      mapId: mapId,
      layerId: MapLayerName.stopfinderBusStop,
      graphicId: graphicName,
      geometryData: EsriGeometryHelper.toPointGeometryJSON(point.longitude, point.latitude),
      // transfer stop is blue symbol
      // symbolName: trip.isTransfer ? SymbolType.tripStopPointSymbol : SymbolType.tripStopMarkerSymbol,
      symbolName: SymbolType.tripStopMarkerSymbol,
      clearLayer: false,
      attributes: {
        isTransfer: trip.isTransfer,
        type: graphicName,
        text: trip.toSchool ? trip.pickUpStopName : trip.dropOffStopName,
      }
    };
    return graphic;
  }

  public createAttendancePointGraphic(mapId: string, attendance: IAttendance): IGraphic
  {
    const graphicName = GraphicNameEnum.attendanceGraphics;
    const graphic: IGraphic = {
      mapId: mapId,
      layerId: MapLayerName.stopfinderAttendance,
      graphicId: graphicName,
      geometryData: EsriGeometryHelper.toPointGeometryJSON(attendance.longitude, attendance.latitude),
      symbolName: SymbolType.attendancePointSymbol,
      clearLayer: false,
      attributes: {
        type: graphicName,
        text: attendance.scannedDate
      }
    };
    return graphic;
  }

  /**
  * Public function to get layer graphic by layer name
  * Layer name equals to graphic id.
  * @param layerName  Mandatory. Name in GraphicNameEnum scope
  */
  public getLayerGraphic(layerName: GraphicNameEnum): IGraphic[]
  {
    return this[GraphicNameEnum[layerName]];
  }

  public setLayerGraphic(layerName: GraphicNameEnum, value: IGraphic[])
  {
    this[GraphicNameEnum[layerName]] = value;
  }

  public getStopLayerGraphic(): IGraphic[]
  {
    return this.getLayerGraphic(GraphicNameEnum.stopGraphic);
  }

  public setStopLayerGraphic(value: IGraphic[])
  {
    this.setLayerGraphic(GraphicNameEnum.stopGraphic, value);
  }

  public getStudentLayerGraphic(): IGraphic[]
  {
    return this.getLayerGraphic(GraphicNameEnum.studentGraphic);
  }

  public getSchoolLayerGraphic(): IGraphic[]
  {
    return this.getLayerGraphic(GraphicNameEnum.schoolGraphic);
  }

  public getAttendanceLayerGraphic(): IGraphic[]
  {
    return this.getLayerGraphic(GraphicNameEnum.attendanceGraphics);
  }

  public initAttendanceMapGraphics(mapId: string, studentSchedule: StudentSchedule, attendance: IAttendance)
  {
    this.getSchoolLayerGraphic().length = 0;
    this.getStudentLayerGraphic().length = 0;
    this.getAttendanceLayerGraphic().length = 0;

    this.getSchoolLayerGraphic().push(this.createSchoolGraphic(mapId, studentSchedule));
    this.getStudentLayerGraphic().push(this.createStudentGraphic(mapId, studentSchedule));
    this.getAttendanceLayerGraphic().push(this.createAttendancePointGraphic(mapId, attendance));
  }

  public async addStudent()
  {
    return this.addMapGraphics(this.getStudentLayerGraphic());
  }

  public async addSchool()
  {
    return this.addMapGraphics(this.getSchoolLayerGraphic());
  }

  public async addStop(displayOtherStop: boolean)
  {
    // stop graphic is not empty, add stop graphic onto map. stop graphic could be: pickup graphic or drop off graphic
    let graphics = this.getStopLayerGraphic();

    if (!displayOtherStop)
    {
      return this.addMapGraphics(this.studentStopGraphic);
    }

    if (_.isEmpty(graphics)) return Promise.resolve();

    return this.addMapGraphics(graphics);
  }

  public async addAttendance()
  {
    return this.addMapGraphics(this.getAttendanceLayerGraphic());
  }

  public async clearStudentStop(mapId: string)
  {
    return this.clearMapGraphics(mapId, MapLayerName.stopfinderStudentStop);
  }

  public async clearBusStop(mapId: string)
  {
    return this.clearMapGraphics(mapId, MapLayerName.stopfinderBusStop);
  }

  public async clearTripPath(mapId: string)
  {
    return this.clearMapGraphics(mapId, MapLayerName.stopfinderPath);
  }

  public async clearStudent(mapId: string)
  {
    return this.clearMapGraphics(mapId, MapLayerName.stopfinderStudent);
  }

  public async clearSchool(mapId: string)
  {
    return this.clearMapGraphics(mapId, MapLayerName.stopfinderSchool);
  }

  public async clearLabel(mapId: string)
  {
    return this.clearMapGraphics(mapId, MapLayerName.labelLayer);
  }

  public async clearGeoAlertText(mapId: string)
  {
    return this.clearMapGraphics(mapId, MapLayerName.stopfinderGeoAlertText);
  }

  public async clearGeoAlert(mapId: string)
  {
    return this.clearMapGraphics(mapId, MapLayerName.stopfinderGeoAlertPolygon);
  }

  public async clearAttendance(mapId: string)
  {
    return this.clearMapGraphics(mapId, MapLayerName.stopfinderAttendance);
  }

  public async clearAll(mapId: string)
  {
    return Promise.all([
      this.clearBusStop(mapId),
      this.clearSchool(mapId),
      this.clearStudent(mapId),
      this.clearTripPath(mapId),
      this.clearLabel(mapId),
      this.clearGeoAlertText(mapId),
      this.clearGeoAlert(mapId),
      this.clearStudentStop(mapId),
      this.clearAttendance(mapId),
    ]);
  }

  private clearMapGraphics(mapId: string, layerName: MapLayerName): Promise<void>
  {
    return new Promise(async (resolve, reject) =>
    {
      NativeMapView.nativeEsriMapPlugin.clearGraphics(mapId, layerName, () => { return resolve(); }, () => { return reject(); });
    });
  }
}
