export interface IUserDefinedField
{
  DisplayName: string;
  Type: IUserDefinedType;
  FalseDisplayName?: string;
  TrueDisplayName?: string;
}

export enum IUserDefinedType
{
  DATE_TIME = "Date/Time",
  DATE = "Date",
  TIME = "Time",
  NUMBER = "Number",
  PHONE_NUMBER = "Phone Number",
  MEMO = "Memo",
  TEXT = "Text",
  LIST = "List",
}




