import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef, MatDialog, MatDialogConfig, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { of, Subject } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';
import { DeviceService } from 'src/app/components/service/device/device.service';
import { ConfirmationDialogComponent } from 'src/app/shared/layout/confirmation-dialog/confirmation-dialog.component';
import { ETAAlertService } from 'src/app/shared/services/eta-alert.service';
import { EtaAlert } from 'src/app/shared/stopfinder/models/eta-alert';
import { EtaAlertComponent } from './eta-alert.component';
import { IndicatorService } from '../../../../../shared/refresh/indicator.service';
import { MapCoreService } from '../../../../../components/service/map/map-core.service';

@Component({
  selector: 'eta-alerts',
  templateUrl: './eta-alerts.component.html',
  styleUrls: ['../actions.component.scss'],
})
export class EtaAlertsComponent implements OnInit, OnDestroy
{
  private readonly destroy$ = new Subject<boolean>();

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: EtaAlert,
    public actionsBottomSheet: MatBottomSheet,
    public readonly deviceService: DeviceService,
    public readonly etaAlertService: ETAAlertService,
    private readonly mapCoreService: MapCoreService,
    private readonly translate: TranslateService,
    private readonly dialogRef: MatDialog,
    private readonly bottomSheetRef: MatBottomSheetRef<EtaAlertsComponent>,
    private readonly _loadingIndicatorService: IndicatorService)
  {

  }

  ngOnInit()
  {
    this._loadingIndicatorService.show();
    this.etaAlertService.fetchETAAlerts(this.data.subscriberId, this.data.riderId).pipe(first()).subscribe(() =>
    {
      this._loadingIndicatorService.close();
    });
  }

  ngOnDestroy()
  {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public cancel()
  {
    this.actionSplitViewMode();
    this.bottomSheetRef.dismiss();
  }

  public delete(etaAlert: EtaAlert)
  {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      data: {
        title: this.translate.instant("geo.delete.modal.eta.title"),
        message: this.translate.instant("geo.delete.modal.eta.body", { X: etaAlert.alertTime }),
        action: this.translate.instant("geo.delete.modal.eta.button.delete"),
        secondary: true,
        secondaryAction: this.translate.instant("geo.delete.modal.eta.button.cancel"),
      },
      panelClass: "confirm-dialog"
    };

    this.dialogRef.open(ConfirmationDialogComponent, dialogConfig)
      .afterClosed().pipe(
        switchMap((isDelete: boolean) =>
        {
          if (isDelete) 
          {
            this._loadingIndicatorService.show();
            return this.etaAlertService.deleteEtaAlert(etaAlert.id).pipe(tap(() => this._loadingIndicatorService.close()));
          }
          else
          {
            return of(false);
          }
        }), first()).subscribe();
  }

  public edit(etaAlert: EtaAlert)
  {
    this.bottomSheetRef.dismiss();
    this.bottomSheetRef.afterDismissed().subscribe(() =>
    {
      this.actionsBottomSheet.open(EtaAlertComponent, {
        data: etaAlert,
        panelClass: 'geo-history-wrapper',
        hasBackdrop: false,
      });
    });
  }
  private readonly actionSplitViewMode = () => this.deviceService.isAndroid && this.mapCoreService.splitMode();
}
