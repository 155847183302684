import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Graphics, TripStopPostData, TripStops, TripSchedule, StudentSchedule } from 'src/app/shared/stopfinder/stopfinder-models';
import { MapLayerName } from 'src/app/tf-map/themes/enums/enum.map-layer';
import { SymbolType } from 'src/app/tf-map/themes/enums/enum.symbol-type';
import { EPolyId } from 'src/app/tf-map/themes/enums/poly-id.enum';
import { decodePath, formatCoords } from 'src/app/shared/utils/utils';
import { MapService } from 'src/app/components/service/map/map.service';
import { StopfinderApiService } from 'src/app/shared/stopfinder/stopfinder-api.service';
import { EsriGeometryHelper } from 'src/app/tf-map/core/esri-geometry-helper';
import { GraphicNameEnum, IGraphic, IPoint } from 'src/app/shared/stopfinder/models/map';
import { MapGraphicsService } from '../map/map-graphics.service';


@Injectable()
export class PathLineService
{
  private stopsGraphics: IGraphic[];
  private studentStopGraphic: IGraphic[] = [];

  private _studentStopGraphic: IGraphic = null;
  private schoolPoint: IPoint = {
    longitude: null,
    latitude: null
  };

  constructor(
    private _apiService: StopfinderApiService,
    private _mapService: MapService,
    private graphicsService: MapGraphicsService,
  )
  {
    this.initTripPathAndStop = this.initTripPathAndStop.bind(this);
  }

  public async initTripPathAndStop(doZoom = false)
  {
    this.stopsGraphics = [];
    this.studentStopGraphic = [];
    this._studentStopGraphic = null;
    this.schoolPoint = {
      longitude: null,
      latitude: null
    };

    await this.graphicsService.clearTripPath(this._mapService.mapId);

    if (this._mapService.studentSchedule.displayTripPath
      && this._mapService.tripId
      && this._mapService.selectedTrip)
    {
      this.drawTripPath(doZoom);
    }
  }

  private async drawTripPath(doZoom = false)
  {
    const self = this,
      schedule = self._mapService.studentSchedule,
      postData: TripStopPostData = {
        ClientId: schedule.clientId,
        RiderId: schedule.riderId,
        DataSourceId: schedule.dataSourceId,
        Ids: [self._mapService.selectedTrip.id],
      },
      { Items } = await self.fetchTripStopsAndPath(postData);

    if (Items && Items[0] && _.isEqual(Items[0].Id, self._mapService.tripId))
    {
      const tripStops: TripStops[] = Items[0].TripStops;

      if (schedule.displayOtherStop)
      {
        self.showTripStops(schedule, tripStops).then(() =>
        {
          self.showTrips(tripStops, doZoom);
        });
      }
      else
      {
        self.showTrips(tripStops, doZoom);
      }
    }
  }

  private showTrips(tripStops: TripStops[], doZoom: boolean)
  {
    let navPath = this.extractTripPath(tripStops);
    if (_.isEmpty(navPath))
    {
      this._mapService.zoomToDefault(true);
      return;
    }

    this.addTripPath(navPath, doZoom).then(async (enforceZoom) =>
    {
      this._mapService.zoomToDefault(enforceZoom);
    }, () => { });
  }

  private showTripStops(schedule: StudentSchedule, tripStops: TripStops[]): Promise<any>
  {
    var self = this;
    return new Promise<any>(async (resolve, reject) =>
    {
      self.schoolPoint = {
        longitude: schedule.schoolX,
        latitude: schedule.schoolY
      };
      self.parseAndSetStudentStopGraphics();

      _.each(tripStops, (stop: TripStops, index: number) =>
      {
        if (self.isSchoolStop(stop))
        {
          return;
        }

        self.setStopsGraphics(stop, index);
      });

      try
      {
        await Promise.all([
          self.graphicsService.clearStudentStop(self._mapService.mapId),
          self.graphicsService.clearBusStop(self._mapService.mapId)
        ]);
        await self.addTripStopsAndLabel();
        resolve('');
      }
      catch
      {
        reject();
      }
    });
  }

  private extractTripPath(tripStops: TripStops[]): number[][][]
  {
    let navPath = [];
    let partPath = [];

    _.each(tripStops, (stop: TripStops) =>
    {
      if (stop.PathString == null || stop.PathString == "")
      {
        if (partPath.length > 0)
        {
          navPath.push([].concat(partPath));
          partPath = [];
        }
      }
      else
      {
        partPath = partPath.concat(decodePath(stop.PathString));
      }
    });

    if (partPath.length > 0)
    {
      navPath.push([].concat(partPath));
    }
    return navPath;
  }

  private parseAndSetStudentStopGraphics()
  {
    const tripStates = this._mapService.tripStates;
    const selectedTrip = this._mapService.selectedTrip;
    const selectedTripId = selectedTrip.id;
    const isPickupTrip = selectedTrip.toSchool;

    _.each(tripStates, (value: TripSchedule, key: GraphicNameEnum) =>
    {
      const point: IPoint = {
        longitude: isPickupTrip ? value.pickUpStopXCoord : value.dropOffStopXCoord,
        latitude: isPickupTrip ? value.pickUpStopYCoord : value.dropOffStopYCoord
      }
      const stopGraphic = this.graphicsService.createStopGraphic(this._mapService.mapId, key, value, point);

      if (value.id == selectedTripId)
      {
        this.setStudentStopGraphic(stopGraphic);
      }
    });
  }

  private setStudentStopGraphic(stopGraphic: IGraphic)
  {
    this._studentStopGraphic = stopGraphic;
  }

  private addTripPath(navPath: number[][][], doZoom = false): Promise<boolean>
  {
    return new Promise<any>(async (resolve, reject) =>
    {
      if (!_.isNull(navPath))
      {
        const graphics = [];
        graphics.push({
          mapId: this._mapService.mapId,
          layerId: MapLayerName.stopfinderPath,
          graphicId: EPolyId.onPathPlanned,
          geometryType: null,
          geometryData: EsriGeometryHelper.toPolylineGeometryJSON(navPath),
          symbolName: SymbolType.tripPathSymbol,
          symbolData: null
        } as Graphics);

        try
        {
          await this.graphicsService.addMapGraphics(graphics);
          resolve(doZoom);
        }
        catch
        {
          reject(false);
        }
      }
      reject(false);
    });
  }

  private setStopsGraphics(stop: TripStops, index: number)
  {
    const self = this,
      basicGraphics: IGraphic = {
        mapId: self._mapService.mapId,
        layerId: MapLayerName.stopfinderBusStop,
        graphicId: GraphicNameEnum.tripStopGraphics,
        geometryData: EsriGeometryHelper.toPointGeometryJSON(stop.Xcoord, stop.Ycoord),
        symbolName: SymbolType.tripStopPointSymbol,
        symbolData: null,
        clearLayer: false
      },
      labelGraphics: IGraphic = {
        ...basicGraphics,
        symbolName: SymbolType.tripStopLabelSymbol,
        symbolData: { text: (index + 1) },
        attributes: { name: "stop-sequence-label" }
      };

    if (self.isStudentStop(stop))
    {
      self.showStudentStop(labelGraphics);
      return;
    }

    self.stopsGraphics = self.stopsGraphics.concat({
      ...basicGraphics,
    }, {
      ...labelGraphics
    });
  }

  private isStudentStop(stop: TripStops): boolean
  {
    const graphic = this._studentStopGraphic;
    return graphic &&
      stop &&
      _.isEqual(formatCoords(graphic.geometryData.x), formatCoords(stop.Xcoord)) &&
      _.isEqual(formatCoords(graphic.geometryData.y), formatCoords(stop.Ycoord));
  }

  private isSchoolStop(stop: TripStops): boolean
  {
    return stop &&
      _.isEqual(formatCoords(this.schoolPoint.longitude), formatCoords(stop.Xcoord)) &&
      _.isEqual(formatCoords(this.schoolPoint.latitude), formatCoords(stop.Ycoord));
  }

  private showStudentStop(labelGraphics: IGraphic)
  {
    // move the student stop and label into 'student-stop-layer'.
    this._studentStopGraphic.layerId = MapLayerName.stopfinderStudentStop;
    labelGraphics.layerId = MapLayerName.stopfinderStudentStop;
    this.studentStopGraphic.push(this._studentStopGraphic, labelGraphics);
  }

  private async addTripStopsAndLabel()
  {
    return new Promise(async (resolve, reject) =>
    {
      if (this.stopsGraphics.length <= 0 && this.studentStopGraphic.length <= 0)
      {
        reject("There is no stops.");
      }

      try
      {
        await this.graphicsService.addMapGraphics(this.studentStopGraphic);
        await this.graphicsService.addMapGraphics(this.stopsGraphics);
        resolve("");
      }
      catch (ex)
      {
        reject(ex);
      }
    });
  }

  private fetchTripStopsAndPath(data: TripStopPostData)
  {
    return this._apiService.getTripStopsAndPath(data).toPromise();
  }
}
