import * as _ from 'lodash';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input, ChangeDetectionStrategy, Inject, OnDestroy, OnChanges, SimpleChanges, ChangeDetectorRef, OnInit, NgZone } from '@angular/core';
import { StudentSchedule, TripSchedule, ILastedGeoAlertNotification, ITripSchedule, IAttendance, StopStatus } from '../../shared/stopfinder/stopfinder-models';
import { AppService } from '../../app.service';
import { MessagesService } from './../../messages/messages.service';
import { MapService } from '../../components/service/map/map.service';
import { CommunicationType } from '../../shared/stopfinder/models/communication';
import
{
  MatDialog,
  MatBottomSheet,
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
  MatDialogConfig,
} from "@angular/material";
import { TargetedBlockingScrollStrategy } from '../../shared/material/targeted-blocking-scroll-strategy';
import { StateService } from '../../components/service/state/state.service';
import { PathLineService } from '../../components/service/path-line/path-line.service';
import
{
  IStepFlow,
  IStepBaseSteps,
  IStepPlaceholder,
  StepperTypeEnum,
} from '../../shared/interface/step-flow.interface';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Subscription, Subject, merge } from 'rxjs';
import { Router } from '@angular/router';
import { getCustomMomentTranslate } from '../../shared/utils/moment-translate';
import { ConfirmationDialogComponent } from '../../shared/layout/confirmation-dialog/confirmation-dialog.component';
import { ScheduleService } from '../schedule.service';
import { AttendanceService } from '../../components/service/attendance/attendance.service';
import { LocalStorageService } from '../../shared/local-storage/local-storage.service';
import { GPSStatus, VehicleLocationService } from 'src/app/shared/vehicle-location.service';
import { take, takeUntil } from 'rxjs/operators';
import { RealTimeUpdatesService } from '../../shared/real-time-updates.service';

interface ITripStepperEvent
{
  schedule?: StudentSchedule;
  event?: StepperSelectionEvent;
  tripId?: number;
  toSchool?: boolean
}

@Component({
  selector: 'sf-student-schedule',
  templateUrl: './student-schedule.component.html',
  styleUrls: ['./student-schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentScheduleComponent implements OnDestroy, OnChanges, OnInit
{
  @Input() studentSchedule: StudentSchedule = null;
  // this key is set as riderId-tripId, in schedule.component.ts
  @Input() geoNotifications: { [key: string]: ILastedGeoAlertNotification } = null;
  @Input() detailCardId: number;

  private readonly IOS_DATE_FORMAT: string = 'YYYY-MM-DD HH:mm';
  private _momentDefaultRounding = moment.relativeTimeRounding();
  private readonly ISO_YMD_FORMAT = 'YYYY-MM-DD';
  private readonly today = moment().format(this.ISO_YMD_FORMAT);
  public schedule: StudentSchedule;
  public storedGeoAlertNotification: ILastedGeoAlertNotification = null;

  private momentSubscription: Subscription;

  public firstDropOffTrip: number = null;
  private _destroy = new Subject<boolean>();
  private _correlationId = '';

  constructor(
    private readonly appService: AppService,
    private readonly messagesService: MessagesService,
    public dialog: MatDialog,
    private readonly bottomSheet: MatBottomSheet,
    private readonly stateService: StateService,
    private readonly mapService: MapService,
    private readonly pathLineService: PathLineService,
    public translate: TranslateService,
    public readonly scheduleService: ScheduleService,
    private readonly attendanceService: AttendanceService,
    private readonly localStorageService: LocalStorageService,
    private readonly _vehicleLocationService: VehicleLocationService,
    private readonly _realTimeUpdatesService: RealTimeUpdatesService,
    private readonly _cdRef: ChangeDetectorRef,
    private readonly _ngZone: NgZone,
    private readonly _attendanceService: AttendanceService,
  )
  {
    this.momentRelativeUpdate();
  }

  ngOnInit(): void
  {
    // monitor the resume of the app once it has the input value for studentschedules
    this.appService.resumeEvent.pipe(takeUntil(this._destroy)).subscribe(() =>
    {
      this._ngZone.run(() =>
      {
        if (this.studentSchedule && this.scheduleService.isScheduleToday(this.studentSchedule))
        {
          this.setUpNeededSignalRConnections();
        }
      })
    });
  }

  ngOnChanges(changes: SimpleChanges)
  {
    if (changes && changes.studentSchedule && !changes.studentSchedule.previousValue && !!changes.studentSchedule.currentValue)
    {
      setTimeout(() =>
      {
        if (this.scheduleService.isScheduleToday(changes.studentSchedule.currentValue))
        {
          this.setGeoAlertForSchedule();
          this.setUpNeededSignalRConnections();
        }
      }, 0);
    }
  }

  ngOnDestroy()
  {
    // revert moment default rounding
    moment.relativeTimeRounding(this._momentDefaultRounding);
    this.momentSubscription && this.momentSubscription.unsubscribe();
    this.stateService.destroyStateChange();
    this._destroy.next(true);
  }

  private momentRelativeUpdate()
  {
    // reference doc to update relativeTime is: https://momentjs.com/docs/
    this.momentSubscription = this.translate.stream('geo').subscribe((res: { [key: string]: any }) =>
    {
      moment.updateLocale(this.translate.currentLang, getCustomMomentTranslate(this.translate.currentLang));
      moment.relativeTimeThreshold('m', 61);
    });
  }

  public initialTripStep(tripSchedule: ITripSchedule, tripIndex?: number, riderId?: number, dataSourceId?: number): IStepFlow
  {
    let tripStep: IStepFlow = {
      type: StepperTypeEnum.vertical,
      steps: []
    };

    const stepControlPrefix = 'stepControl';

    // should generate pickup steps, icons, state, title and subtitle
    const pickup = <IStepBaseSteps>{
      stepControl: `${stepControlPrefix}Pickup`,
      title: tripSchedule.pickUpStopName,
      subtitle: tripSchedule.pickupSubtitle || '',
      message: tripSchedule.pickUpMessage || '',
      prefix: <IStepPlaceholder>{
        title: `${tripSchedule.pickUpTime ? this.getAdjustStopTime(true, tripSchedule) : ''}`,
        endWith: this.getCrossDayStatus(true, tripSchedule),
        subTitle: 'schedule.pickup'
      }
    };
    tripStep.steps.push(pickup);

    // should generate drop off steps, icons, state, title and subtitle
    const dropOff = <IStepBaseSteps>{
      stepControl: `${stepControlPrefix}Dropoff`,
      title: tripSchedule.dropOffStopName,
      subtitle: tripSchedule.dropOffSubtitle || '',
      message: tripSchedule.dropOffMessage || '',
      prefix: <IStepPlaceholder>{
        title: `${tripSchedule.dropOffTime ? this.getAdjustStopTime(false, tripSchedule) : ''}`,
        endWith: this.getCrossDayStatus(false, tripSchedule),
        subTitle: 'schedule.drop.off'
      }
    }
    tripStep.steps.push(dropOff);

    if (_.isNull(this.firstDropOffTrip) && !tripSchedule.toSchool)
    {
      !_.isUndefined(tripIndex) && (this.firstDropOffTrip = tripIndex - 1);
    }
    return tripStep;
  }

  /**
   * Public function to handle click event callback from trip stepper
   * Once stepper editable, this will return as StepperSelectionEvent interface
   * Otherwise will return customized interface which involve student schedule and clicked index
   * @param stepperSelectionEvent StepperSelectionEvent | Customized interface
   */
  public tripStepChangeHandler(stepperSelectionEvent: ITripStepperEvent)
  {
    if (_.get(stepperSelectionEvent, 'tripId'))
    {
      this.mapService.displayGeoAlertMapOnClickNotification(
        stepperSelectionEvent.schedule,
        this.appService,
        this.pathLineService,
        false,
        stepperSelectionEvent.tripId,
        stepperSelectionEvent.toSchool,
        false
      );
    }
    else
    {
      // this would be the trigger to open map and zoom to bound without zoom to specific trip
      // this.mapService.displayGeoAlertMap(stepperSelectionEvent.schedule, this.appService, this.pathLineService);
    }
  }

  public getSortedStudentSchedules(studentSchedule: StudentSchedule): StudentSchedule
  {
    if (_.isNull(studentSchedule))
    {
      return studentSchedule;
    }
    this.schedule = this.scheduleService.getTripScheduleOrder(this.studentSchedule);

    this.scheduleService.translateForTransfer = `schedule.transfer`;
    return this.schedule;
  }

  public getNotificationKey = (riderId: number, tripId: number, dataSourceId: number): string => `${riderId}-${tripId}-${dataSourceId}`;

  public shouldDisplayNotification(riderId: number, tripId: number, dataSourceId: number): boolean
  {
    // reset storedGeoAlertNotification as null in each schedule trips validation
    this.storedGeoAlertNotification = null;
    if (_.isEmpty(this.geoNotifications) || !_.has(this.geoNotifications, `${riderId}-${tripId}-${dataSourceId}`))
    {
      return false;
    }

    const mappingKey = `${this.schedule.riderId}-${tripId}-${dataSourceId}`;
    if (_.has(this.geoNotifications, `${mappingKey}`))
    {
      this.storedGeoAlertNotification = this.geoNotifications[`${mappingKey}`];
    }

    return !_.isNull(this.storedGeoAlertNotification);
  }

  public isSameGeoAlertNotification = (riderId: number, tripId: number, dataSourceId: number): boolean =>
  {
    // should validate if current stored notification trip id is equal to param trip id
    return moment(moment(this.schedule.date).format(this.ISO_YMD_FORMAT)).isSame(this.today) &&
      _.isEqual(riderId, this.storedGeoAlertNotification.riderId) &&
      _.isEqual(tripId, this.storedGeoAlertNotification.tripId) &&
      _.isEqual(dataSourceId, this.storedGeoAlertNotification.dataSourceId);
  }

  public getGeoAlertNotification = (): string => `${this.storedGeoAlertNotification.geoAlertNotification.body}`;

  public notificationTimestamp(): string
  {
    // sent on time (UTC) in geo notification
    const sentOn = moment(moment(this.storedGeoAlertNotification.geoAlertNotification.sentOn).format(this.IOS_DATE_FORMAT));
    // local time (UTC) format
    const rightNow = moment(moment().utc().format(this.IOS_DATE_FORMAT));

    // set the moment locale to current subscriber locale
    const currentSubscriberLang = this.localStorageService.get('subscriberLanguage') || 'en';
    sentOn.locale(currentSubscriberLang);
    rightNow.locale(currentSubscriberLang);

    return `${sentOn.from(rightNow)}`;
  }

  getAdjustStopTime(isPickUp: boolean, trip: TripSchedule)
  {
    let time = isPickUp ? trip.pickUpTime : trip.dropOffTime;
    let result = '';
    if (!trip)
    {
      return result;
    }
    return this.scheduleService.getAdjustTime(moment(time), trip.adjustMinutes).format('h:mm A');
  }

  getCrossDayStatus(isPickUp: boolean, trip: TripSchedule): string
  {
    const time = isPickUp ? trip.pickUpTime : trip.dropOffTime;
    let result = '';
    if (!trip || !trip.adjustMinutes)
    {
      return result;
    }

    const newTime = moment(time);
    if (newTime.isValid())
    {
      newTime.add('m', trip.adjustMinutes);
      var oldTime = moment(time);
      var offsetDays = newTime.startOf('d').diff(oldTime.startOf('d'), 'days');
      if (offsetDays > 0)
      {
        result = '(+1)';
      }
      else if (offsetDays < 0)
      {
        result = '(-1)';
      }
    }
    return result;
  }

  feedbackTap()
  {
    this.messagesService.selectedCommunication = {
      id: null,
      type: CommunicationType.Message,
      body: '',
      subject: `${this.studentSchedule.firstName} ${this.studentSchedule.lastName}`,
      sentOn: '',
      riderId: this.studentSchedule.riderId,
    };
    this.appService.lastLocation = 'schedule';
    this.stateService.goRoute('message-detail');
  }

  showStudentActionsSheet()
  {
    this.bottomSheet.open(
      StudentActionsBottomSheetComponent,
      {
        data: {
          studentSchedule: this.studentSchedule,
        },
        scrollStrategy: new TargetedBlockingScrollStrategy()
      }
    );
  }


  public openAttendanceMap(schedule: StudentSchedule)
  {
    this._attendanceService.attendanceObservable
      .pipe(
        take(1)
      ).subscribe((scans) =>
      {
        this.attendanceService.openAttendanceMap(true, this.appService, {
          schedule,
          attendance: scans
        })
      })
  }

  public showVehicleMessage(trip: TripSchedule): boolean
  {
    return this.studentSchedule.displayVehicle
      && this.scheduleService.isTripRunning(this.studentSchedule, trip)
      && !(trip.gpsStatus === GPSStatus.Searching || trip.gpsStatus === GPSStatus.ValidGPS || !trip.gpsStatus);
  }

  setGeoAlertForSchedule()
  {
    this.scheduleService.setGeoAlertForSchedule(this.studentSchedule);
  }

  setUpNeededSignalRConnections()
  {
    this._handleVehicleUpdates();
    this._handleRealTimeUpdates();
    this._handleAttendances();
  }

  private _handleVehicleUpdates()
  {
    // Set up vehicle location signalR connection for each trip running
    this.studentSchedule.trips.forEach((trip: TripSchedule) =>
    {
      if (this.studentSchedule.enableGeoAlerts && this.scheduleService.isTripRunning(this.studentSchedule, trip))
      {
        this._vehicleLocationService.getLastKnownVehicleLocation(this.studentSchedule, trip).pipe(take(1)).subscribe((lastPoint) =>
        {
          if (lastPoint || trip.gpsStatus !== GPSStatus.NoVehicleAssigned)
          {
            if (lastPoint && this._vehicleLocationService.isValidEvent(lastPoint))
            {
              this._vehicleLocationService.handleGPSTimer(this.studentSchedule, trip, this._vehicleLocationService.getTimeDiffMS(lastPoint));
              this.scheduleService.updateScheduleProperties(this.studentSchedule, trip, 'gpsStatus', GPSStatus.ValidGPS);
              this._cdRef.markForCheck();
            }
            else
            {
              this.scheduleService.updateScheduleProperties(this.studentSchedule, trip, 'gpsStatus', GPSStatus.NotAvailable);
              this._cdRef.markForCheck();
            }
          }
        });

        this._vehicleLocationService.monitorSignalRLocation(this.studentSchedule, trip)
          .pipe(
            takeUntil(merge(this.appService.pauseEvent, this._destroy))
          ).subscribe((val) =>
          {
            this.scheduleService.updateScheduleProperties(this.studentSchedule, trip, 'gpsStatus', GPSStatus.ValidGPS);
            this._cdRef.markForCheck();
          });
      }
    });
  }

  private _handleRealTimeUpdates()
  {
    this.studentSchedule.trips.forEach((trip: TripSchedule) =>
    {
      if (this.scheduleService.isTripRunning(this.studentSchedule, trip) && !!this.studentSchedule.enableEtaAlerts)
      {
        this._realTimeUpdatesService.fetchLastRealTimeUpdate(this.studentSchedule, trip, true).pipe(take(1)).subscribe((update: StopStatus) => this._handleRealTimeUpdateApiResponse(this.studentSchedule, trip, update, true));
        this._realTimeUpdatesService.fetchLastRealTimeUpdate(this.studentSchedule, trip, false).pipe(take(1)).subscribe((update: StopStatus) => this._handleRealTimeUpdateApiResponse(this.studentSchedule, trip, update, false));
        if (this.appService.versionDictionary[this.studentSchedule.clientId])
        {
          // Set up real time updates signalR connections
          this._realTimeUpdatesService.monitorRealTimeUpdatesGroup(this.studentSchedule, trip, true).pipe(
            takeUntil(merge(this.appService.pauseEvent, this._destroy))
          ).subscribe((message: StopStatus) =>
          {
            if (message)
            {
              this._handleRealTimeUpdateApiResponse(this.studentSchedule, trip, message, true);
            }
          });
          this._realTimeUpdatesService.monitorRealTimeUpdatesGroup(this.studentSchedule, trip, false).pipe(
            takeUntil(merge(this.appService.pauseEvent, this._destroy))
          ).subscribe((message: StopStatus) =>
          {
            if (message)
            {
              this._handleRealTimeUpdateApiResponse(this.studentSchedule, trip, message, false);
            }
          });
        }
      }
    });
  }

  private _handleAttendances()
  {
    this._attendanceService.registerForAttendanceUpdates(this.studentSchedule)
      .pipe(
        takeUntil(merge(this._destroy, this.appService.pauseEvent))
      ).subscribe(
        (attendance: IAttendance) =>
        {
          this.studentSchedule.attendanceString = attendance ? this._formatAttendanceText(attendance) : attendance;
          this._cdRef.markForCheck();
        });
  }

  private _formatAttendanceText(attendance: IAttendance)
  {
    moment.updateLocale(this.translate.currentLang, getCustomMomentTranslate(this.translate.currentLang));
    const time = `${moment(attendance.scannedDate).utc().from(moment().utc())}`;
    return this.translate.instant("schedule.scanned.notification.planned", { time });
  }

  noActiveVehicleText(trip: TripSchedule): string
  {
    switch (trip.gpsStatus)
    {
      case GPSStatus.NoVehicleAssigned:
        return this.translate.instant("schedule.vehicle.no.vehicle.assigned");
      case GPSStatus.NotAvailable:
        return this.translate.instant("schedule.vehicle.no.active.GPS");
    }
  }


  private _handleRealTimeUpdateApiResponse(studentSchedule: StudentSchedule, tripSchedule: TripSchedule, update: StopStatus, pickUp: boolean): void
  {
    if (!update || (!!update && !update.StopId))
    {
      this._clearStopMessages(studentSchedule, tripSchedule, pickUp);
    } else
    {
      this._processETAMessage(studentSchedule, tripSchedule, update, pickUp);
    }
    this._cdRef.markForCheck();
  }


  private _processETAMessage(studentSchedule: StudentSchedule, tripSchedule: ITripSchedule, update: StopStatus, pickUp: boolean): void
  {
    if (!update.ETA_TimeStamp)
    {
      this._clearStopMessages(studentSchedule, tripSchedule, pickUp);
      return;
    }

    const propertyName = pickUp ? 'pickUpMessage' : 'dropOffMessage';
    if (update.CorrelationId !== this._correlationId)
    {
      this._clearStopMessages(studentSchedule, tripSchedule, pickUp);
    }
    this._correlationId = update.CorrelationId;

    const formattedEtaMessage = this._formatEtaMessage(studentSchedule, update);
    if (!formattedEtaMessage)
    {
      return;
    }

    tripSchedule[propertyName] = formattedEtaMessage;
    this._cdRef.markForCheck();
    this._realTimeUpdatesService.removeEtaAfter5Mins(studentSchedule, tripSchedule, pickUp).pipe(take(1)).subscribe(() =>
    {
      this._clearStopMessages(studentSchedule, tripSchedule, pickUp);
    });
  }

  private _formatEtaMessage(studentSchedule: StudentSchedule, update: StopStatus)
  {
    if (update.Message)
    {
      return `${this.translate.instant("geo.eta")}: ${update.Message}`;
    }
    if (update.OnTime)
    {
      return `${this.translate.instant("geo.eta")}: ${this.translate.instant("geo.eta.on.time")}`;
    }

    const eta_Time = this._transformToDistrictTime(studentSchedule, update.ETA_TimeString);
    if (!eta_Time.isValid())
    {
      console.error('Invalid ETA Time String: ' + update.ETA_TimeString, update);
      return null;
    }

    return `${this.translate.instant("geo.eta")}: ${eta_Time.format('h:mm A')} &#8226; ${update.EtaInMinutes} ${this.translate.instant("geo.eta.minute.abbreviation")}`;
  }

  private _transformToDistrictTime(studentSchedule: StudentSchedule, eta: string): moment.Moment
  {
    const rawEta = moment.utc(eta);
    let transformedEta = rawEta.utcOffset(studentSchedule.timeZoneMinutes);
    return transformedEta;
  }

  private _clearStopMessages(studentSchedule: StudentSchedule, tripSchedule: TripSchedule, pickUp: boolean): void
  {
    const propertyName = pickUp ? 'pickUpMessage' : 'dropOffMessage';
    tripSchedule[propertyName] = '';
    this._cdRef.markForCheck();
  }
}

@Component({
  selector: 'student-actions-bottom-sheet',
  template: `
  <div class="sf-color-black" style="text-align: center">
    <h3>{{studentSchedule?.firstName}} {{studentSchedule?.lastName}}</h3>
  </div>
  <mat-nav-list>
    <a *ngIf="!studentSchedule || (studentSchedule.xCoord || studentSchedule.schoolX || studentSchedule.trips?.length > 0)"
       mat-list-item class="sf-color-black"
       (click)="openStudentMap($event)"
    >
      <mat-icon matListIcon>map</mat-icon>
      <!-- Map -->
      <span mat-line>{{ 'schedule.map' | translate }}</span>
      <span mat-line>{{ 'schedule.map.subtitle' | translate }}</span>
    </a>
    <a *ngIf="studentSchedule.feedbackEnabled" mat-list-item (click)="openFeedbackMessage($event)" class="sf-color-black" >
      <mat-icon matListIcon svgIcon="transfinder_message"></mat-icon>
      <!-- Message -->
      <span mat-line>{{ 'schedule.message' | translate }}</span>
      <span mat-line>{{ 'schedule.message.subtitle' | translate }}</span>
    </a>
    <a *ngIf="studentSchedule.subscriptionOwner" mat-list-item (click)="openShareSubscription($event)" class="sf-color-black">
      <mat-icon matListIcon svgIcon="ios_share"></mat-icon>
      <span mat-line>{{ 'schedule.share' | translate }}</span>
      <span mat-line>{{ 'schedule.share.subtitle' | translate }}</span>
    </a>
    <a *ngIf="studentSchedule.scannedHistoryEnabled && studentSchedule.attendanceEnabled" mat-list-item (click)="viewScannedHistory($event)" class="sf-color-black">
      <mat-icon matListIcon>history</mat-icon>
      <span mat-line>{{ 'schedule.scanned.view.history' | translate }}</span>
      <span mat-line>{{ 'schedule.scanned.view.history.desc' | translate }}</span>
    </a>
    <a *ngIf="studentSchedule.attendanceValue && studentSchedule.scannedHistoryEnabled && studentSchedule.attendanceEnabled" mat-list-item (click)="disableAttendance()">
      <mat-icon matListIcon>cancel</mat-icon>
      <span mat-line>{{ 'schedule.attendance.disable' | translate }}</span>
      <span mat-line>{{ 'schedule.attendance.disable.subtitle' | translate }}</span>
    </a>
    <a *ngIf="!studentSchedule.attendanceValue && studentSchedule.scannedHistoryEnabled && studentSchedule.attendanceEnabled" mat-list-item (click)="enableAttendance()">
      <mat-icon matListIcon>check</mat-icon>
      <span mat-line>{{ 'schedule.attendance.enable' | translate }}</span>
      <span mat-line>{{ 'schedule.attendance.enable.subtitle' | translate }}</span>
    </a>
  </mat-nav-list>
  `,
})
export class StudentActionsBottomSheetComponent
{
  public studentSchedule: StudentSchedule;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private readonly messagesService: MessagesService,
    private readonly appService: AppService,
    public dialog: MatDialog,
    private readonly bottomSheetRef: MatBottomSheetRef<StudentActionsBottomSheetComponent>,
    private stateService: StateService,
    private mapService: MapService,
    private readonly pathLineService: PathLineService,
    public translate: TranslateService,
    private router: Router,
    private readonly scheduleService: ScheduleService,
    private readonly attendanceService: AttendanceService,
  )
  {
    this.studentSchedule = data.studentSchedule as StudentSchedule;
  }

  openFeedbackMessage(event: any)
  {
    this.messagesService.selectedCommunication = {
      id: null,
      type: CommunicationType.Message,
      body: '',
      subject: `${this.studentSchedule.firstName} ${this.studentSchedule.lastName}`,
      sentOn: '',
      riderId: this.studentSchedule.riderId,
      dataSourceId: this.studentSchedule.dataSourceId,
    };
    this.appService.lastLocation = 'schedule';
    this.stateService.goRoute('message-detail');

    this.bottomSheetRef.dismiss();
  }

  openShareSubscription(event: any)
  {
    this.appService.currentStudentSchedule = this.studentSchedule;
    this.stateService.goRoute('sharesubscription');
  }

  shouldDisableMapEvent(): boolean
  {
    return !this.mapService.isMapPluginReady();
  }

  openStudentMap(event: any)
  {
    this.scheduleService.schedulesHiddenForMap = true;
    this.mapService.isVehicleDisplayEnabled = false;
    this.mapService.displayGeoAlertMap(this.studentSchedule, this.appService, this.pathLineService, false).then(() =>
    {
      this.mapService.busMovingTrackHandler(this.studentSchedule, false, this.appService);
    });
    this.bottomSheetRef.dismiss();
  }

  openAttendanceMap(schedule: StudentSchedule)
  {
    this.attendanceService.openAttendanceMap(true, this.appService, { schedule });
    this.bottomSheetRef.dismiss();
  }

  viewScannedHistory(event: any)
  {
    const riderId = this.studentSchedule.riderId;
    const dataSourceId = this.studentSchedule.dataSourceId;
    this.appService.lastLocation = 'schedule';
    this.attendanceService.updateSelectStudentSchedule(this.studentSchedule);
    this.router.navigate([`attendanceHistory/${riderId}/${dataSourceId}`]);

    this.bottomSheetRef.dismiss();
  }

  disableAttendance()
  {
    this.updateAttendanceValue(false, {
      title: this.translate.instant("schedule.attendance.disable.title"),
      message: `${this.translate.instant("schedule.attendance.disable.desc")}`,
      action: this.translate.instant("schedule.attendance.disable.action"),
      secondaryAction: this.translate.instant("schedule.attendance.cancel"),
    });
  }

  enableAttendance()
  {
    this.updateAttendanceValue(true, {
      title: this.translate.instant("schedule.attendance.enable.title"),
      message: `${this.translate.instant("schedule.attendance.enable.desc")}`,
      action: this.translate.instant("schedule.attendance.enable.action"),
      secondaryAction: this.translate.instant("schedule.attendance.cancel"),
    });
  }

  private updateAttendanceValue(attendanceValue: boolean, dialogConfig: {
    title?: string,
    message?: string,
    action?: string,
    secondaryAction?: string,
  })
  {
    this.bottomSheetRef.dismiss();
    this.bottomSheetRef.afterDismissed().subscribe(() =>
    {
      const _dialogConfig: MatDialogConfig = {
        disableClose: true,
        data: {
          ...dialogConfig,
          secondary: true,
        },
        panelClass: "confirm-dialog"
      };

      const openDialogRef = this.dialog.open(ConfirmationDialogComponent, _dialogConfig);
      openDialogRef.afterClosed().subscribe((val) =>
      {
        if (val)
        {
          const subscriptionId = this.studentSchedule.subscriptionId;
          const riderId = this.studentSchedule.riderId;
          this.messagesService.patchAttendance(subscriptionId,
            [{
              op: 'replace',
              path: 'attendanceValue',
              value: attendanceValue
            }]).subscribe((res) =>
            {
              !!res && this.scheduleService.refreshcachedScheduleDaysAttendanceValue(riderId, attendanceValue);
            });
        }
      });
    });
  }
}
