import { PipeTransform, Pipe } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'relativeDate' })
export class RelativeDate implements PipeTransform
{
  constructor(public translate: TranslateService) { }
  transform(value: string, showToday: boolean)
  {
    if (value)
    {
      moment.locale(this.translate.currentLang);
      const date = moment(value);
      if (moment().isSame(date, 'day'))
      {
        if (showToday)
        {
          return this.translate.instant("message.today");
        } else
        {
          return date.format('h:mm a'); // 2:06 pm
        }
      } else if (date.isAfter(moment().subtract(7, 'days'), 'day'))
      {
        return date.format('ddd'); // Tues
      } else if (moment().isSame(date, 'year'))
      {
        return date.format('MMM D'); // Jun 6th
      } else
      {
        return date.format('MMM D, YYYY'); // Jun 6th, 2019
      }
    }
  }
}
