import * as _ from 'lodash';
import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { IGeoData } from './geo.interface';
import { MapService } from 'src/app/components/service/map/map.service';
import { StopfinderApiService } from 'src/app/shared/stopfinder/stopfinder-api.service';
import { AppService } from 'src/app/app.service';
import { AndroidBackService } from 'src/app/androidBack.service';
import { TargetedBlockingScrollStrategy } from 'src/app/shared/material/targeted-blocking-scroll-strategy';
import { ConfirmationDialogComponent } from 'src/app/shared/layout/confirmation-dialog/confirmation-dialog.component';
import { IndicatorService } from '../../../../shared/refresh/indicator.service';
import
{
  MatBottomSheet,
  MatBottomSheetRef,
  MatDialog,
  MatDialogConfig,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material';

import { TranslateService } from '@ngx-translate/core';
import { AddGeoBottomSheetComponent } from './actions.component';
import { MapVehicleService } from 'src/app/components/service/map/map-vehicle.service';
import { DeviceService } from 'src/app/components/service/device/device.service';
import { MapCoreService } from 'src/app/components/service/map/map-core.service';

@Component({
  selector: 'geo-add-action',
  templateUrl: './view.geo.component.html',
  styleUrls: ['./actions.component.scss'],
})
export class ViewGeoComponent implements OnInit
{

  public geoData: IGeoData[] = [];
  public viewGeoTitle = '';

  private isDeleteClicked = false;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { riderId: number },
    public readonly _appService: AppService,
    public readonly deviceService: DeviceService,
    public readonly mapService: MapService,
    private actionsBottomSheet: MatBottomSheet,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly androidService: AndroidBackService,
    private readonly apiService: StopfinderApiService,
    private readonly bottomSheetRef: MatBottomSheetRef<ViewGeoComponent>,
    private readonly dialogRef: MatDialog,
    private readonly loadingIndicator: IndicatorService,
    private readonly mapCoreService: MapCoreService,
    private readonly translate: TranslateService,
    private readonly vehicleService: MapVehicleService,
  )
  {
    this.androidService.onShouldCheckCallback(this.backButtonControl.bind(this));
    this.androidService.disableAndroidBack = true;
  }

  ngOnInit()
  {
    this.getAllGeoAlerts();
    this.updatePageTitle();
  }

  ngOnDestroy()
  {
    this.androidService.onDestroyCallback();
  }

  private getAllGeoAlerts()
  {
    this.apiService.getAllGeoAlerts(this.data.riderId).toPromise().then(
      (result: IGeoData[]) =>
      {
        this.geoData = result;
        this.updatePageTitle();
        this._changeDetectorRef.markForCheck();
      }
    );
  }

  private updatePageTitle()
  {
    this.viewGeoTitle = `${this.translate.instant("geo.edit.title")}${this.geoData.length ? ` (${this.geoData.length})` : ''}`;
  }

  public cancel()
  {
    this.actionSplitViewMode();
    this.bottomSheetRef.dismiss();
    this.bottomSheetRef.afterDismissed().subscribe(() =>
    {
      this.mapService.turnOffGeoAddMode();
      this.mapService.zoomToDefault();
    });
  }

  public editCurrentGeoAlert(data: IGeoData)
  {
    // should re-assign data
    this.mapService.geoData = data;
    // should enable map visibility
    // zoom map to GeoAlert center.
    this._appService.setMapVisibility(true, data.xcoord, data.ycoord);
    // should enable actions header
    this.mapService.turnOnGeoAddMode();

    this.bottomSheetRef.dismiss();
    this.bottomSheetRef.afterDismissed().subscribe(() =>
    {
      // open actions bottom sheet
      this.actionsBottomSheet.open(AddGeoBottomSheetComponent, {
        data: {
          geoData: this.mapService.geoData,
          isEditMode: true,
        },
        panelClass: 'geo-configuration',
        hasBackdrop: false,
        scrollStrategy: new TargetedBlockingScrollStrategy()
      }).afterOpened().subscribe(() =>
      {
        const resetMapSize = () =>
        {
          const topPadding = document.querySelector(".geo-header").clientHeight;
          const bottomPadding = document.querySelector(".mat-bottom-sheet-container").clientHeight;
          this.mapService.setViewSize(
            0,
            topPadding,
            this._appService.windowWidth,
            this._appService.windowHeight - topPadding - bottomPadding);
        }
        this.deviceService.isiOS && resetMapSize();
        // should checked plugin status and then popover actions sheet
        this.mapService.isMapPluginReady() &&
          this.mapService.turnOnGeoMode(data.id, () =>
          {
            // callback to handle rest of the logic
            return data;
          }, this.deviceService.isAndroid ? resetMapSize : null);
      });
    });
  }

  public deleteGeoAlert(data: IGeoData)
  {
    this.isDeleteClicked = true;
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      data: {
        title: this.translate.instant("geo.delete.modal.delete.title"),
        message: `${this.translate.instant("geo.delete.modal.delete.body")} ${data.name}?`,
        action: this.translate.instant("geo.delete.modal.delete.delete"),
        secondary: true,
        secondaryAction: this.translate.instant("geo.delete.modal.delete.cancel"),
      },
      panelClass: "confirm-dialog"
    };

    this.dialogRef.open(ConfirmationDialogComponent, dialogConfig)
      .afterClosed().subscribe((confirmedResult: boolean) =>
      {
        if (confirmedResult)
        {
          this.loadingIndicator.show();
          this.mapService.turnOffGeoAddMode();
          this.mapService.hideGeoAlert(() =>
          {
            // confirm as true, delete existing geo alert
            this.apiService.deleteGeoAlert(data.id).toPromise().then(
              () =>
              {
                this.vehicleService.clearCenterToBus();
                this.mapService.fetchAndZoomToGeoAlerts(data.riderId);

                this.loadingIndicator.close();

                this.geoData = _.remove(this.geoData, (eachData: IGeoData) => eachData.id !== data.id);
                this.updatePageTitle();
                this._changeDetectorRef.markForCheck();
              }
            );
          });
        }
        this.isDeleteClicked = false;
      });
  }

  private backButtonControl()
  {
    !this.isDeleteClicked && this.cancel();
  }

  private readonly actionSplitViewMode = () => this.deviceService.isAndroid && this.mapCoreService.splitMode();
}
