import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { stringToDate } from "../../utils/utils";
import * as moment from 'moment';

interface Controls
{
  [key: string]: AbstractControl
}

export class DateValidation
{
  private _formControls: Controls;

  set formControls(value: Controls)
  {
    this._formControls = value;
  }

  get formControls(): Controls
  {
    return this._formControls;
  }

  constructor() { }

  public customStartDateAfterTodayValidators(): ValidatorFn
  {
    return (control: FormControl): ValidationErrors => 
    {
      const value = control.value;
      if (typeof value === 'string')
      {
        return this.isStartDateAfterToday(value) && !this.isStartDateBeforeEndDate(value) ? { 'startDateAfterToday': true } : null;
      }
    };
  }

  public customStartDateBeforeEndDateValidators(): ValidatorFn
  {
    return (control: FormControl): ValidationErrors =>
    {
      const value = control.value;
      if (typeof value === 'string')
      {
        return this.isStartDateBeforeEndDate(value) && !this.isStartDateAfterToday(value) ? { 'startDateBeforeEndDate': true } : null;
      }
      return null;
    };
  }

  public customStartDateAfterTodayAndBeforeEndDateValidators(): ValidatorFn
  {
    return (control: FormControl): ValidationErrors =>
    {
      const value = control.value;
      if (typeof value === 'string')
      {
        return this.isStartDateAfterToday(value) && this.isStartDateBeforeEndDate(value) ? { 'startDateAfterTodayAndBeforeEndDate': true } : null;
      }
    }
  }

  public customEndDateAfterStartDateValidators(): ValidatorFn
  {
    return (control: FormControl): ValidationErrors =>
    {
      let value = control.value;
      if (typeof value === 'string')
      {
        const startDate = (this.formControls && this.formControls['startDate']) ? this.formControls['startDate'].value : '';
        if (startDate !== '')
        {
          const endDate = stringToDate(value);
          return endDate < stringToDate(startDate) ? { 'endDateAfterStartDate': true } : null;
        }
      }
      return null;
    };
  }

  private isStartDateBeforeEndDate(startDate: string): boolean
  {
    const endDate = (this.formControls && this.formControls['endDate']) ? this.formControls['endDate'].value : '';
    if (endDate !== '')
    {
      return stringToDate(startDate) > stringToDate(endDate);
    }

    return null;
  }

  private isStartDateAfterToday(startDate: string): boolean
  {
    const start = moment(startDate);
    const today = moment(new Date());
    return start.diff(today, "days") < 0;
  }
}
