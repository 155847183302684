import * as _ from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { StopfinderApiService } from 'src/app/shared/stopfinder/stopfinder-api.service';
import { ITutorial } from 'src/app/tutorial/tutorial.export';
import { getAppVersion } from 'src/app/shared/utils/utils';

@Injectable()
export class TutorialService
{
  private _tutorialList: BehaviorSubject<ITutorial[]> = new BehaviorSubject<ITutorial[]>(null);
  private _tutorialImages: BehaviorSubject<ITutorial> = new BehaviorSubject<ITutorial>(null);
  private _tutorialImagesCache: BehaviorSubject<ITutorial[]> = new BehaviorSubject<ITutorial[]>(null);

  constructor(
    private readonly apiService: StopfinderApiService,
  ) { }


  get tutorialList()
  {
    return this._tutorialList;
  }

  get tutorialImages()
  {
    return this._tutorialImages;
  }

  get tutorialImagesCache()
  {
    return this._tutorialImagesCache;
  }

  public initializeServiceData()
  {
    this.updateTutorialList(null);
    this.updateTutorialImages(null);
    this._tutorialImagesCache.next(null);
  }

  public updateTutorialList = (tutorials: ITutorial[]) => this._tutorialList.next(tutorials);

  public updateTutorialImages = (tutorialImg: ITutorial) => this._tutorialImages.next(tutorialImg);

  public updateTutorialImagesCache = (tutorial: ITutorial) =>
  {
    const last = [].concat(this.tutorialImagesCache.getValue() || []);
    const existIndex = last.findIndex(item => Number(item.id) === Number(tutorial.id));
    if (existIndex < 0)
    {
      last.push(tutorial);
    } else
    {
      last[existIndex] = tutorial;
    }
    this._tutorialImagesCache.next(last);
  }

  public getTutorialList(): Observable<ITutorial[]>
  {
    return this.apiService.getTutorialList(getAppVersion()).pipe(map(res =>
    {
      !!res && this.updateTutorialList(res);
      return res;
    }), catchError(error =>
    {
      return of(null);
    }));
  }

  public getTutorialImages(tutorialId?: number, appVersion?: string, prevAppVersion?: string): Observable<ITutorial>
  {
    return this.apiService.getTutorialImages(tutorialId, appVersion, prevAppVersion).pipe(map(res =>
    {
      if (!!res)
      {
        this.updateTutorialImages(res);
        this.updateTutorialImagesCache(res);
      }
      return res;
    }), catchError(error =>
    {
      return of(null);
    }));
  }
}
