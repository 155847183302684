import { Injectable } from '@angular/core';
import { StopfinderApiService } from 'src/app/shared/stopfinder/stopfinder-api.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { formatVersion, getAppVersion } from 'src/app/shared/utils/utils';
import { LocalStorageService } from 'src/app/shared/local-storage/local-storage.service';
import { StateService } from '../state/state.service';
import { LOCAL_LAST_TOUR_INFO_KEY } from 'src/app/shared/utils/constant';
import { TutorialStateEnum } from 'src/app/tutorial/tutorial.interface';
import { FormSource, IFormSentRecipient } from 'src/app/form/form.interface';
import { CircularQueue } from 'src/app/shared/utils/circular-queue';
import { AppService } from 'src/app/app.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from 'src/app/shared/layout/confirmation-dialog/confirmation-dialog.component';
import { TargetedBlockingScrollStrategy } from 'src/app/shared/material/targeted-blocking-scroll-strategy';

@Injectable()
export class RouterInterceptService
{
  private eventQueue: CircularQueue = null;
  private checkUrls = [
    '/schedule',
  ];
  private formList: IFormSentRecipient[] = [];
  private dialogRef = null;

  constructor(
    private readonly router: Router,
    private readonly localStorageService: LocalStorageService,
    private readonly apiService: StopfinderApiService,
    private readonly stateService: StateService,
    private readonly appService: AppService,
    private readonly translate: TranslateService,
    private readonly dialog: MatDialog,
  )
  {
    this.eventQueue = new CircularQueue(10);
    this.setEventQueue();

    this.appService.tokenRefreshObservable.subscribe(() =>
    {
      this.eventQueue.clear();
      this.setEventQueue();
    });
  }

  public routerDetect(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean>
  {
    if (!this.checkUrls.includes(state.url))
    {
      return Promise.resolve(true);
    }

    const event = this.eventQueue.front();
    if (typeof event === 'function')
    {
      return event();
    }

    return Promise.resolve(true);
  }

  private setEventQueue()
  {
    this.eventQueue.enQueue(this.checkShowTour.bind(this));

    // enable check remind me later form
    this.eventQueue.enQueue(this.checkShowForm.bind(this));
  }

  private checkShowForm(): Promise<boolean>
  {
    return new Promise<boolean>(async (resolve, reject) =>
    {
      const _openFormPage = () =>
      {
        const firstForm = this.formList[0];
        const recipientId = firstForm && firstForm.id;
        if (recipientId)
        {
          this.openFormPage(firstForm.formSentId, recipientId, firstForm.formSentName);
          // remove the opened form
          this.formList.splice(0, 1);
        }
      }

      // reload form data when has the last one, improve performance
      if (this.formList.length < 1)
      {
        this.getRequiredAndNotOpenedForm().then((_formList: Array<IFormSentRecipient>) =>
        {
          this.formList = this.formList.concat(_formList);
          _openFormPage();
        });
      } else
      {
        _openFormPage();
      }

      return resolve(true);
    });
  }

  private async getRequiredAndNotOpenedForm()
  {
    return await this.apiService.getRequiredAndNotOpenedForm().toPromise();
  }

  private openFormPage(id: number, formRecipientId: number, formName: string)
  {
    this.translate.get("form.dialog").subscribe((item) =>
    {
      this.showOpenFormDialog(
        formName,
        item["parent.drop.off.desc"],
        item["parent.drop.off.action"],
      ).then(() =>
      {
        this.router.navigate([`formQuestion/${id}/${formRecipientId}`, {
          source: FormSource.remind
        }]);
      })
    });
  }

  private checkShowTour(): Promise<boolean>
  {
    return new Promise<boolean>(async (resolve, reject) =>
    {
      // remove the check tour event for the queue
      this.eventQueue.deQueue();

      let localLastTourInfo = this.getLocalTourInfo();
      if (!localLastTourInfo)
      {
        const subscribe = await this.getCurrentSubscriber().catch(() => resolve(true));
        if (!subscribe) return resolve(true);

        localLastTourInfo = this.setLocalTourInfo(subscribe.id, subscribe.lastTourVersionID);
      }

      // get the last tour info form local
      const subscribeId = localLastTourInfo.split("__")[0];
      const lastTourVersion = localLastTourInfo.split("__")[1];

      if (formatVersion(getAppVersion()) - formatVersion(lastTourVersion === "null" ? '' : lastTourVersion) > 0)
      {
        this.openTourPage(subscribeId);
        return resolve(false);
      }

      this.stateService.goRoute('/schedule')
      return resolve(false);
    });
  }

  private async getCurrentSubscriber()
  {
    return await this.apiService.getCurrentSubscriber().toPromise();
  }

  private openTourPage(subscriberId: number)
  {
    const prevAppVersion = this.getLocalTourInfo();
    this.stateService.goRoute(`/tutorialSlide`, {
      state: TutorialStateEnum.all,
      prevAppVersion: (prevAppVersion && prevAppVersion.split("__")[1]) || '0',
    });

    // update the app version when open the tour page
    this.apiService.patchSubscriber(subscriberId, [{
      path: 'lastTourVersionID',
      op: 'replace',
      value: getAppVersion()
    }]).subscribe(() =>
    {
      this.setLocalTourInfo(subscriberId, getAppVersion());
    })
  }

  private getLocalTourInfo()
  {
    return this.localStorageService.get(LOCAL_LAST_TOUR_INFO_KEY);
  }

  private setLocalTourInfo(subscriberId: number, version?: string)
  {
    const versionInfo = `${subscriberId}__${version ? version : ''}`;
    this.localStorageService.set(LOCAL_LAST_TOUR_INFO_KEY, versionInfo);
    return versionInfo;
  }

  private showOpenFormDialog(title: string, message: string, action: string): Promise<boolean>
  {
    return new Promise<boolean>((resolve, reject) =>
    {
      if (!this.dialogRef)
      {
        this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: true,
          data: {
            title,
            message,
            action,
            secondary: false,
          },
          scrollStrategy: new TargetedBlockingScrollStrategy(),
          panelClass: "confirm-dialog-open-form"
        });

        this.dialogRef.afterClosed().subscribe(result =>
        {
          this.dialogRef = undefined;
          if (result)
          {
            return resolve(true);
          }
        });
      }
    });
  }
}
