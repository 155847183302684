import { MapLayerName } from './../../../themes/enums/enum.map-layer';
import { ILayer } from './../../../themes/interfaces/interface.layer';
/**
 * This pairs up the layer with its map id
 */
export interface IMapLayerPair {
    /**
     * Map id of the map containing this layer
     */
    mapId: string;
    /**
     * Actual map layer
     */
    mapLayer: ILayer;
}

export class LayerFactory {
    public static mapLayerPairs: IMapLayerPair[] = [];
    public static mapLayers: MapLayerName[] = [];

    public static createMapLayer(mapLayerName: MapLayerName, mapId: string): ILayer {
        const layers = require('../../../../../assets/themes/theme.layer.json') as ILayer[];
        const layer = layers.find(_layer => {
            return _layer.name === mapLayerName;
        });
        this.mapLayers.push(mapLayerName);
        this.mapLayerPairs.push({ mapId: mapId, mapLayer: layer });
        return layer;
    }
}
