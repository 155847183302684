import
  {
    Component,
    Inject,
  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface DialogData
{
  message: string;
  enableInput?: boolean | false;
  inputLabel?: string;
  inputValue?: string;
  input?: IDialogInput;
  action: string;
  secondaryAction?: string;
  secondary: boolean;
  title: string;
  tertiary: boolean;
  tertiaryAction?: string;
  specialClass: boolean;
  promiseCallback?: (obj: ICallback) => Promise<IDialogInput>;
  simpleCallback?: (obj: ICallback) => void;
}

export interface IDialogInput
{
  isEmpty?: boolean;
  isError?: boolean;
  maxLength?: number;
  errorMsg?: string;
}

export interface ICallback
{
  message?: string;
  number?: number;
  callback?: Function;
  obj?: object;
}

@Component({
  selector: 'confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent
{
  public msg = '';

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  )
  {
    if (this.data && this.data.enableInput && this.data.inputValue)
    {
      this.msg = this.data.inputValue;
    }
  }

  onBlur(e)
  {
    const dom = document.querySelector("#confirm-dialog-input") as any;
    dom.focus();
  }

  public primaryAction()
  {
    // should trigger promise callback if exist
    if (this.data.promiseCallback)
    {
      if (this.data.enableInput)
      {
        this.data.promiseCallback({
          message: this.msg,
        } as ICallback).then((result: IDialogInput) =>
        {
          this.data.input = result;
          !result.isError && !result.isEmpty && this.dialogRef.close(this.msg ? this.msg : true);
        });
        return;
      }
    }

    // should trigger simple callback if exist
    if (this.data.simpleCallback)
    {
      this.data.simpleCallback({
        message: this.msg,
      } as ICallback);
      // should block dialogRef close action as callback will handle close event itself
      return;
    }

    this.dialogRef.close(this.msg ? this.msg : true);
  }
}
