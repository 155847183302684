import * as _ from 'lodash';
import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormService } from '../components/service/form/form.service';
import { IFormEmit } from './form.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: `sf-form`,
  templateUrl: 'form.component.html',
  styleUrls: ['form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SFFormComponent implements OnInit, AfterViewInit, OnDestroy
{

  @Output() formStatusEmit: EventEmitter<IFormEmit> = new EventEmitter<IFormEmit>();

  constructor(
    public readonly translate: TranslateService,
    public readonly formService: FormService,
  ) { }

  ngOnInit()
  {
    this.translate.stream("form").subscribe((translate) =>
    {
      this.formService.translateText.next(translate);
    });
  }

  ngAfterViewInit() { }

  ngOnDestroy()
  {
    this.formService.initializeServiceData();
  }
}
