import * as _ from 'lodash';
import
{
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Input,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { AppService } from '../../app.service';
import { TfMapFactory } from '../../tf-map/core/tf-map.factory';
import { TfMapOptions } from '../../tf-map/core/classes/tf-map.options.class';
import { eBaseMaps } from '../../tf-map/basemaps/classes/basemap.enum';
import { TfMap } from '../../tf-map/core/classes/tf-map.class';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { LayerFactory } from '../../tf-map/services/layer-management/factories/layer.factory';
import { MapLayerName } from '../../tf-map/themes/enums/enum.map-layer';
import { NativeMapView } from '../../tf-map/core/native-mapview';
import { StateService } from '../../components/service/state/state.service';
import { MapService } from 'src/app/components/service/map/map.service';
import { PathLineService } from '../../components/service/path-line/path-line.service';
import { MatSelectChange } from '@angular/material';
import { TooltipComponent } from '../../shared/tooltip/tooltip.component';
import { TooltipService } from '../../components/service/tooltip/tooltip.service';
import { SCHENECTADY_MAP_CENTER_X, SCHENECTADY_MAP_CENTER_Y } from 'src/app/shared/utils/constant';
import { Coordinates } from './esri-map.interface';
import { AttendanceService } from 'src/app/components/service/attendance/attendance.service';
import { MapVehicleService } from '../../components/service/map/map-vehicle.service';
import { GraphicNameEnum, ILayerState } from 'src/app/shared/stopfinder/models/map';
import { TFMapType } from 'src/app/shared/utils/enum';
import { DeviceService } from 'src/app/components/service/device/device.service';
import { ScheduleService } from '../../schedule/schedule.service';
import { TranslateService } from '@ngx-translate/core';
import { GPSStatus } from 'src/app/shared/vehicle-location.service';

@Component({
  selector: 'esri-map',
  templateUrl: './esri-map.component.html',
  styleUrls: ['./esri-map.component.scss'],
  animations: [
    trigger('new-overlay', [
      state('open', style({
        opacity: 0.7,
        'z-index': 900,
      })),
      state('closed', style({
        opacity: 0,
        'z-index': -1,
      })),
      transition('closed => open', animate('300ms ease-out')),
      transition('open => closed', animate('300ms ease-in'))
    ]),
  ],
})
export class EsriMapComponent implements OnInit, OnDestroy
{
  @ViewChild('esriMap', { static: true }) mainMap: ElementRef;
  @ViewChild('sfToolTip', { static: true }) tooltip: TooltipComponent;
  @Output() exitMap: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() mapVisibility = false;
  public showBasemaps = new BehaviorSubject<boolean>(null);
  public overlayState = 'closed';
  private mapLoaded = false;
  private readonly mapId: string = 'sfMap';

  constructor(
    public _appService: AppService,
    public readonly deviceService: DeviceService,
    private stateService: StateService,
    public mapService: MapService,
    public pathLineService: PathLineService,
    private tooltipService: TooltipService,
    public readonly scheduleService: ScheduleService,
    public translate: TranslateService,
    public attendanceService: AttendanceService,
  ) { }

  ngOnInit()
  {
    this.deviceService.deviceReady.subscribe((isReady) =>
    {
      if (isReady && !this.mapLoaded)
      {
        this.createMapView();
        this.stateService.leaveMap = this.closeMap.bind(this);
        this.tooltipService.tooltip = this.tooltip;
      }
    });
  }

  ngOnDestroy()
  {
    this.tooltipService.close();
    this.mapService.tooltipInstance = null;
  }

  private async createMapView()
  {
    const center: Coordinates = {
      "accuracy": 0,
      "altitude": null,
      "altitudeAccuracy": null,
      "heading": null,
      "longitude": SCHENECTADY_MAP_CENTER_X,
      "latitude": SCHENECTADY_MAP_CENTER_Y,
      "speed": null
    };
    TfMapFactory._create(this.mapId,
      new TfMapOptions(this.mainMap, eBaseMaps.Streets, center)).then(async (map: TfMap) =>
      {
        await this.setUpLayers(map);
        if (NativeMapView.nativeEsriMapPlugin)
        {
          NativeMapView.nativeEsriMapPlugin.toggleMapViewVisible(this.mapId, false, () => null, () => null);
        }
      });
  }

  /**
   * Set up all the layers for the map
   * @param map for the component
   */
  private async setUpLayers(map: TfMap)
  {
    const mapId = this.mapId;
    const stopfinderPathLayer = LayerFactory.createMapLayer(MapLayerName.stopfinderPath, mapId);
    const stopfinderStopLayer = LayerFactory.createMapLayer(MapLayerName.stopfinderBusStop, mapId);
    const stopfinderGeoAlertPolygonLayer = LayerFactory.createMapLayer(MapLayerName.stopfinderGeoAlertPolygon, mapId);
    const stopfinderSchoolLayer = LayerFactory.createMapLayer(MapLayerName.stopfinderSchool, mapId);
    const stopfinderStudentLayer = LayerFactory.createMapLayer(MapLayerName.stopfinderStudent, mapId);
    const stopfinderStudentStopLayer = LayerFactory.createMapLayer(MapLayerName.stopfinderStudentStop, mapId);
    const stopfinderLabelLayer = LayerFactory.createMapLayer(MapLayerName.labelLayer, mapId);
    const stopfinderGeoAlertTextLayer = LayerFactory.createMapLayer(MapLayerName.stopfinderGeoAlertText, mapId);
    const stopfinderAttendanceLayer = LayerFactory.createMapLayer(MapLayerName.stopfinderAttendance, mapId);

    return Promise.all([
      map.addLayer(stopfinderPathLayer.name, stopfinderPathLayer.index),
      map.addLayer(stopfinderStopLayer.name, stopfinderStopLayer.index),
      map.addLayer(stopfinderGeoAlertPolygonLayer.name, stopfinderGeoAlertPolygonLayer.index),
      map.addLayer(stopfinderSchoolLayer.name, stopfinderSchoolLayer.index),
      map.addLayer(stopfinderStudentLayer.name, stopfinderStudentLayer.index),
      map.addLayer(stopfinderStudentStopLayer.name, stopfinderStudentStopLayer.index),
      map.addLayer(stopfinderLabelLayer.name, stopfinderLabelLayer.index),
      map.addLayer(stopfinderGeoAlertTextLayer.name, stopfinderGeoAlertTextLayer.index),
      map.addLayer(stopfinderAttendanceLayer.name, stopfinderAttendanceLayer.index),
    ]).then(() =>
    {
      this.mapLoaded = true;
    });
  }

  async closeMap(mapType?: TFMapType)
  {
    if (mapType === TFMapType.AttendanceMap)
    {
      this.attendanceService.onCloseAttendanceMap(this._appService);
    } else
    {
      this.exitMap.emit(true);
      this.mapService.clearMap();
      this.scheduleService.schedulesHiddenForMap = false;
      this.scheduleService.selectedTrip = null;
    }
  }

  toolsClicked()
  {
    this.overlayState = this.overlayState === 'open' ? 'closed' : 'open';
    this.tooltipService.close();
  }

  overlayClicked()
  {
    this.toolsClicked();
    this.showBasemaps.next(true);
  }

  zoomToBounds()
  {
    this.mapService.zoomToDefault(true, true);
  }

  /**
   * Public function to switch map layouts
   * @param selectChangeEvent Emit parameters from sf-header component
   */
  public switchTripType(selectChangeEvent: { event: MatSelectChange })
  {
    const selectedOption: GraphicNameEnum = selectChangeEvent.event.value;

    // reset all layer states to false as default, use new instance not reference
    const useState: ILayerState = _.mapValues(_.assign({}, this.mapService.layerStates), () => false);

    // student layout currently load (must) as default. Can directly delete once optional
    _.set(useState, GraphicNameEnum.studentGraphic, true);
    // school layout currently load (must) as default. Can directly delete once optional
    _.set(useState, GraphicNameEnum.schoolGraphic, true);

    // only set layer match selected value to visible
    _.set(useState, selectedOption, true);
    this.mapService.selectedTrip = selectedOption;
    this.mapService.isVehicleDisplayEnabled = false;
    if (this.mapService.selectedTrip)
    {
      this.mapService.selectedTrip.gpsStatus = null;
    }

    const callback = async () =>
    {
      await this.mapService.clearGeoAlertMap();
      // update student schedule first, if is student header, don't update
      await this.mapService.refreshStudentScheduleAndTrip(this.mapService.studentSchedule);

      // do not zoom to bus or redraw GeoAlerts after switch layer.
      this.mapService.tripPathAndGeoAlertHandler(this.mapService.studentSchedule, this.pathLineService, false, false);
      this.mapService.busMovingTrackHandler(this.mapService.studentSchedule, false, this._appService);
    }

    this.mapService.currentLayerStates = useState;
    this.mapService.switchLayer(useState, callback);
  }

  get showBanner(): boolean
  {
    return this.mapService.isVehicleDisplayEnabled && !MapVehicleService.isVehicleDisplayingOnMap;
  }

  get getBannerText(): string
  {
    const trip = this.mapService.selectedTrip;
    if (!trip)
    {
      return this.translate.instant("schedule.vehicle.searching.vehicle");
    }

    switch (trip.gpsStatus)
    {
      case GPSStatus.NoVehicleAssigned:
        return this.translate.instant("schedule.vehicle.no.vehicle.assigned");
      case GPSStatus.NotAvailable:
        return this.translate.instant("schedule.vehicle.no.active.GPS");
      case GPSStatus.Searching:
      case GPSStatus.ValidGPS: //vehicle is not drawn on map yet but gps status is valid
        return this.translate.instant("schedule.vehicle.searching.vehicle");
    }
  }
}
