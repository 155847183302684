import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import {
  Observable,
  BehaviorSubject,
} from 'rxjs';
import {
  TooltipComponent,
  IPosition,
  ITooltip,
  ITooltipEvents,
} from '../../../shared/tooltip/tooltip.component';

@Injectable({
  providedIn: 'root',
})
export class TooltipService implements ITooltipEvents {

  private readonly _tooltipMessage = new BehaviorSubject<string>('');
  private readonly message: Observable<string> = this._tooltipMessage;
  private readonly _tooltipState = new BehaviorSubject<boolean>(false);
  private isFixedPosition = false;

  public tooltipCallback: Function;
  public isTooltipDarkMode: boolean;
  public isTooltipEnabled = true;

  private _tooltip: TooltipComponent;

  constructor(
  ) {
    this.tooltipStateWatcher();
  }

  set tooltip(tooltip: TooltipComponent) {
    this._tooltip = tooltip;
  }

  get tooltip() {
    return this._tooltip;
  }

  private tooltipStateWatcher() {
    // this is a message change watcher
    this.message.subscribe(value => {
      if (this.tooltip)
      {
        this.tooltip.config.message = value;
      }
    });
  }

  private updateState() {
    this._tooltipState.next(this.tooltip.showTooltip);
  }

  public config(tooltipConfig: ITooltip) {
    this.tooltip.config = tooltipConfig;
    !this._tooltipMessage && (this.updateMessage(tooltipConfig.message));
    return this;
  }

  public iconClickEvent() {
    this.tooltip.config.icon = !!this.tooltipCallback;
    if (this.tooltipCallback)
    {
      this.tooltip.config.iconClickCallback = this.tooltipCallback;
    }

    return this;
  }

  public updateMessage(message: string) {
    this._tooltipMessage.next(message);
  }

  public getTooltipMessage(): string {
    return this._tooltipMessage.getValue();
  }

  // true as open, false as closed
  public getTooltipStatus(): boolean {
    return this._tooltipState.getValue();
  }

  public show() {
    this.isTooltipEnabled && this.tooltip.show();
    this.updateState();
    return this;
  }

  public close() {
    this.tooltip.close();
    this.updateState();
    return this;
  }

  public toggleTooltip() {
    if (this._tooltipState.getValue())
    {
      return this.close();
    }
    return this.show();
  }

  public closeAll() {
    this.tooltip.close();
  }

  public updatePosition(position: IPosition) {
    !this.isFixedPosition && this.tooltip.updatePosition(position);
    return this;
  }

  public getPosition(): IPosition {
    return this.tooltip.getPosition();
  }

  public lockPosition() {
    this.isFixedPosition = true;
  }

  public unlockPosition() {
    this.isFixedPosition = false;
  }

  public reverseTooltipStyle(baseMapDarkStyle = false) {
    this.isTooltipDarkMode = baseMapDarkStyle;
    this.tooltip.close();

    const reverseStatus = _.get(this.tooltip, 'config.reversible');

    if (reverseStatus)
    {
      this.tooltip.config.reverse = !this.isTooltipDarkMode;
    }
  }

}
