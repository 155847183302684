import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { LocalStorageService } from '../../shared/local-storage/local-storage.service';

import { environment } from '../../../environments/environment';

import
{
	TokenRequest,
	TokenResponse,
	VersionResponse,
	StudentScheduleDay,
	StudentSchedule,
	Announcement,
	GridData,
	OperationResult,
	PushRegistration,
	Enrollment,
	Message,
	MessageThread,
	Invitation,
	Subscription,
	AppFeedback,
	Document,
	AppRating,
	CommunicationType,
	GeoAlertNotification,
	TripStopPostData,
	IScheduledNotification,
	ILastedGeoAlertNotification,
	IAttendance,
	IUserDefinedField,
	StopStatus,
} from './stopfinder-models';
import { ChangePasswordForm } from './models/change-password-form';
import { Subscriber } from './models/subscriber';
import { ForgotPassword } from './models/forgot-password';
import { IGeoData } from 'src/app/map/geo/action-sheet/actions/geo.interface';
import { IForm, IFormSendQuestionResult, IFormSentRecipient, IQuestion, IQuestionResult, ISaveQuestionPayload } from 'src/app/form/form.interface';
import { ITutorial } from 'src/app/tutorial/tutorial.export';
import { IVehiclePoint } from './models/map';
import { EtaAlert } from './models/eta-alert';
import { StopStatusDictionary } from '../real-time-updates.service';


export interface ApiException
{
	code: number;
	message: string;
}

export const StopfinderApiServiceBaseUri = 'stopfinderBaseUri';

@Injectable()
export class StopfinderApiService
{
	public stopfinderBaseUri: string;
	private requireStopFinderApi: string;
	constructor(
		private http: HttpClient,
		private localStorageService: LocalStorageService,
	)
	{
		this.stopfinderBaseUri = this.getStopFinderBaseUri();
		this.requireStopFinderApi = environment.requireStopfinderApi;
	}

	set stopFinderBaseUri(newValue: string)
	{
		if (newValue !== undefined && !!newValue)
		{
			this.stopfinderBaseUri = newValue;
		}
	}

	private getBrowserLang()
	{
		if (typeof window === 'undefined' || typeof window.navigator === 'undefined')
		{
			return undefined;
		}
		/** @type {?} */
		let browserLang = window.navigator.languages ? window.navigator.languages[0] : null;
		browserLang = browserLang || window.navigator.language || (window.navigator as any).browserLanguage || (window.navigator as any).userLanguage;
		if (typeof browserLang === 'undefined')
		{
			return undefined;
		}
		if (browserLang.indexOf('-') !== -1)
		{
			browserLang = browserLang.split('-')[0];
		}
		if (browserLang.indexOf('_') !== -1)
		{
			browserLang = browserLang.split('_')[0];
		}
		return browserLang;
	}

	getStopFinderBaseUri(): string
	{
		return this.localStorageService.get(StopfinderApiServiceBaseUri);
	}

	clearStopFinderBaseUri()
	{
		this.stopfinderBaseUri = '';
	}

	private handleError(error: HttpErrorResponse)
	{

		if (error.error instanceof ProgressEvent || error.error instanceof ErrorEvent)
		{
			// client side or unreachable network error,
			// normalize so 'error' prop is message, same as Angular
			return throwError({ ...error, error: 'Network error. Check your connection and try again.' });
		}
		else
		{
			// API returned error code
			return throwError(error);
		}
	}

	getEnvironmentURL(mail: string): Observable<string>
	{
		return this.http.get(
			`${this.requireStopFinderApi}?email=${encodeURIComponent(mail)}`,
			{
				responseType: 'text',
			},
		).pipe(catchError(error =>
		{
			return this.handleError(error);
		}));
	}

	postTokenRequest(tokenRequest: TokenRequest): Observable<TokenResponse>
	{
		const url = `${this.stopfinderBaseUri}/tokens`;
		return this.http.post<TokenResponse>(url, tokenRequest)
			.pipe(catchError(error =>
			{
				return this.handleError(error);
			}));
	}

	registerPushDevice(pushRegistration: PushRegistration)
	{
		const url = `${this.stopfinderBaseUri}/action/subscribers/push/register`;
		return this.http.post(url, pushRegistration)
			.pipe(catchError(error =>
			{
				return this.handleError(error);
			}));
	}

	unregisterPushDevice(pushRegistration: PushRegistration)
	{
		const url = `${this.stopfinderBaseUri}/action/subscribers/push/unregister`;
		return this.http.post(url, pushRegistration)
			.pipe(catchError(error =>
			{
				return this.handleError(error);
			}));
	}

	reRegisterPushDevice(pushRegistration: PushRegistration)
	{
		const url = `${this.stopfinderBaseUri}/action/subscribers/push/reregister`;
		return this.http.post(url, pushRegistration)
			.pipe(catchError(error =>
			{
				return this.handleError(error);
			}));
	}

	activateUser(enrollment: Enrollment): Observable<Enrollment>
	{
		const url = `${this.stopfinderBaseUri}/action/enrollments/${enrollment.guid}`;

		return this.http
			.post<Enrollment>(url, enrollment)
			.pipe(
				catchError(error =>
				{
					return this.handleError(error);
				})
			);
	}

	getEnrollment(guid: string): Observable<Enrollment>
	{
		const url = `${this.stopfinderBaseUri}/action/enrollments/${guid}`;
		return this.http
			.get<Enrollment>(url)
			.pipe(
				catchError(error =>
				{
					return this.handleError(error);
				})
			);
	}

	getStudentSchedules(
		dateStart: string, dateEnd: string
	): Observable<Array<StudentScheduleDay>>
	{
		const url = `${this.stopfinderBaseUri
			}/students?dateStart=${dateStart}&dateEnd=${dateEnd}`;
		return this.http.get<Array<StudentScheduleDay>>(url)
			.pipe(
				catchError(error =>
				{
					return this.handleError(error);
				}));
	}

	getStudentSchedule(
		dateStart: string
	): Observable<Array<StudentScheduleDay>>
	{
		const url = `${this.stopfinderBaseUri
			}/students?date=${dateStart}`;
		return this.http.get<Array<StudentScheduleDay>>(url)
			.pipe(
				catchError(error =>
				{
					return this.handleError(error);
				}));
	}

	getStudentScheduleByRiderId(
		dateStart: string,
		riderId: number
	): Observable<StudentSchedule[]>
	{
		const url = `${this.stopfinderBaseUri
			}/students/studentScheduleByRiderId?date=${dateStart}&riderId=${riderId}`;
		return this.http.get<StudentSchedule[]>(url)
			.pipe(
				catchError(error =>
				{
					return this.handleError(error);
				}));
	}

	getVersions(): Observable<Array<VersionResponse>>
	{
		const uri = `${this.stopfinderBaseUri}/systems/apiversions`;
		return this.http.get<Array<VersionResponse>>(uri).pipe(catchError(error =>
		{
			return this.handleError(error);
		}));
	}

	getAnnouncements(): Observable<Array<Announcement>>
	{
		const uri = `${this.stopfinderBaseUri}/announcementssent`;
		return this.http.get<Array<Announcement>>(uri).pipe(catchError(error =>
		{
			return this.handleError(error);
		}));
	}

	getAnnouncementSendDocuments(announcementsSentId: number): Observable<Array<Document>>
	{
		const uri = `${this.stopfinderBaseUri}/action/announcementssent/${announcementsSentId}/documents`;
		return this.http.get<Array<Document>>(uri).pipe(catchError(error =>
		{
			return this.handleError(error);
		}));
	}

	getMessageThreads(): Observable<Array<MessageThread>>
	{
		const uri = `${this.stopfinderBaseUri}/messagethreads`;
		return this.http.get<Array<MessageThread>>(uri).pipe(catchError(error =>
		{
			return this.handleError(error);
		}));
	}

	getMessages(messageThreadId: number): Observable<Array<Message>>
	{
		const uri = `${this.stopfinderBaseUri}/messagethreads/${messageThreadId}/messages`;
		return this.http.get<Array<Message>>(uri).pipe(catchError(error =>
		{
			return this.handleError(error);
		}));
	}

	markAnnouncementsStatus(announcementId: number, status: boolean, type: string)
	{
		const uri = `${this.stopfinderBaseUri}/announcements/${announcementId}`;
		const document = [{
			op: 'replace',
			path: type,
			value: status
		}];

		return this.http.patch<OperationResult>(uri, document).pipe(catchError(error =>
		{
			return this.handleError(error);
		}));
	}

	markMessagesReadStatus(messageId: number, status: boolean)
	{
		const uri = `${this.stopfinderBaseUri}/action/messagethreads/read`;
		const gridData: GridData = {
			ids: [messageId],
			value: status
		};

		return this.http.post<OperationResult>(uri, gridData).pipe(catchError(error =>
		{
			return this.handleError(error);
		}));
	}

	markMessagesArchiveStatus(messageIds: number, status: boolean)
	{
		const uri = `${this.stopfinderBaseUri}/action/messagethreads/archive`;
		const gridData: GridData = {
			ids: [messageIds],
			value: status
		};

		return this.http.post<OperationResult>(uri, gridData).pipe(catchError(error =>
		{
			return this.handleError(error);
		}));
	}

	postMessageThread(message: MessageThread)
	{
		const uri = `${this.stopfinderBaseUri}/messagethreads`;
		return this.http.post<MessageThread>(uri, message).pipe(catchError(error =>
		{
			return this.handleError(error);
		}));
	}

	postMessage(message: Message)
	{
		const uri = `${this.stopfinderBaseUri}/messages`;
		const req = new HttpRequest('POST', uri, message, { reportProgress: true });
		const localSubject = new BehaviorSubject<any>(null);
		this.http.request(req).pipe(
			map(event =>
			{
				localSubject.next(event);
			}),
			catchError(error =>
			{
				localSubject.error(error);
				return this.handleError(error);
			}),
		).subscribe();
		return localSubject.asObservable();
	}

	postShareInvitation(invitation: Invitation): Observable<any>
	{
		const uri = `${this.stopfinderBaseUri}/invitations`;
		return this.http.post<any>(uri, invitation).pipe(catchError(error =>
		{
			return this.handleError(error);
		}));
	}

	getDocumentStorage(id: number): Observable<any>
	{
		let uri = `${this.stopfinderBaseUri}/action/documents/${id}/storage`;
		const req = new HttpRequest('GET', uri, id, { reportProgress: true });
		const localSubject = new BehaviorSubject<any>(null);
		this.http.request(req).pipe(
			map(event =>
			{
				localSubject.next(event);
			}),
			catchError(error =>
			{
				localSubject.error(error);
				return this.handleError(error);
			}),
		).subscribe();
		return localSubject.asObservable();
	}

	patchSubscription(id: number, document: any)
	{
		const uri = `${this.stopfinderBaseUri}/subscriptions/${id}`;
		const localSubject = new BehaviorSubject<any>(null);
		this.http.patch<any>(uri, document).pipe(map(sub =>
		{
			localSubject.next(sub);
		}), catchError(error =>
		{
			return this.handleError(error);
		})).subscribe();
		return localSubject.asObservable();
	}

	getSubscriptionsByOwner()
	{
		const uri = `${this.stopfinderBaseUri}/subscriptions/owned`;
		return this.http.get<Array<Subscription>>(uri).pipe(catchError(error =>
		{
			return this.handleError(error);
		}));
	}

	hasOwnedSubscriptions()
	{
		const uri = `${this.stopfinderBaseUri}/action/subscriptions/hasowned`;
		return this.http.get<boolean>(uri).pipe(catchError(error =>
		{
			return this.handleError(error);
		}));
	}

	getSubscriptionsSharedWithOwner()
	{
		const uri = `${this.stopfinderBaseUri}/subscriptions/share`;
		return this.http.get<Array<Subscription>>(uri).pipe(catchError(error =>
		{
			return this.handleError(error);
		}));
	}

	getCurrentSubscriber()
	{
		const uri = `${this.stopfinderBaseUri}/action/subscribers/current`;
		return this.http.get<Subscriber>(uri).pipe(catchError(error =>
		{
			return this.handleError(error);
		}));
	}

	patchSubscriber(id: number, document: any)
	{
		const uri = `${this.stopfinderBaseUri}/subscribers/${id}`;
		return this.http.patch<any>(uri, document).pipe(catchError(error =>
		{
			return this.handleError(error);
		}));
	}

	changePassword(changePasswordForm: ChangePasswordForm)
	{
		const uri = `${this.stopfinderBaseUri}/action/subscribers/password/change`;
		return this.http.post(uri, changePasswordForm).pipe(catchError(error =>
		{
			return this.handleError(error);
		}));
	}

	checkEmailNotTaken(email: string)
	{
		const uri = `${this.stopfinderBaseUri}/action/subscribers/unique`;
		return this.http.get(uri,
			{
				params: { 'email': email }
			}).pipe(catchError(error =>
			{
				return this.handleError(error);
			}));
	}

	postAppFeedback(appFeedback: AppFeedback)
	{
		const uri = `${this.stopfinderBaseUri}/appfeedbacks`;
		return this.http.post<AppFeedback>(uri, appFeedback).pipe(catchError(error =>
		{
			return this.handleError(error);
		}));
	}

	postAppRating(appRating: AppRating)
	{
		const uri = `${this.stopfinderBaseUri}/apprating`;
		return this.http.post<any>(uri, appRating).pipe(catchError(error =>
		{
			return this.handleError(error);
		}));
	}

	verifyForgotPassword(guid: string, email: string): Observable<ForgotPassword>
	{
		const url = `${this.stopfinderBaseUri}/forgotpasswords/activated?guid=${guid}&email=${email}`;
		return this.http
			.get<ForgotPassword>(url)
			.pipe(
				catchError(error =>
				{
					return this.handleError(error);
				})
			);
	}

	createForgotPassword(email: string): Observable<ForgotPassword>
	{
		const url = `${this.stopfinderBaseUri}/forgotpasswords?email=${email}`;
		return this.http
			.post<ForgotPassword>(url, {})
			.pipe(
				catchError(error =>
				{
					return this.handleError(error);
				})
			);
	}

	submitForgotPassword(forgotPassword: ForgotPassword): Observable<ForgotPassword>
	{
		const url = `${this.stopfinderBaseUri}/forgotpasswords/changepassword`;
		return this.http
			.post<ForgotPassword>(url, forgotPassword)
			.pipe(
				catchError(error =>
				{
					return this.handleError(error);
				})
			);
	}

	getAppRating()
	{
		const uri = `${this.stopfinderBaseUri}/apprating`;
		return this.http.get<any>(uri).pipe(catchError(error =>
		{
			return this.handleError(error);
		}));
	}

	patchSubscriptionRange(id: number, document: any)
	{
		const uri = `${this.stopfinderBaseUri}/subscriptionRanges/${id}`;
		const localSubject = new BehaviorSubject<any>(null);
		this.http.patch<any>(uri, document).pipe(map(sub =>
		{
			localSubject.next(sub);
		}), catchError(error =>
		{
			return this.handleError(error);
		})).subscribe();
		return localSubject.asObservable();
	}

	patchSubscriptionRangeAsync(id: number, document: any)
	{
		const uri = `${this.stopfinderBaseUri}/subscriptionRanges/${id}`;
		return this.http.patch<any>(uri, document).pipe(
			catchError(error =>
			{
				return this.handleError(error);
			})
		);
	}
	sendSubscriptionChangedEmail(subscriptionId: number, subscriptionRangeId: number)
	{
		const uri = `${this.stopfinderBaseUri}/action/subscriptions/email?subscriptionId=${subscriptionId}&subscriptionRangeId=${subscriptionRangeId}`;
		return this.http.post<number>(uri, {}).pipe(
			catchError(error =>
			{
				return this.handleError(error);
			})
		);
	}

	getGeoAlertHistory(riderId: number, dataSourceId: number, date: string, tripId: number): Observable<any>
	{
		const language = this.localStorageService.get(`subscriberLanguage`) || this.getBrowserLang();
		const uri = `${this.stopfinderBaseUri}/geoAlertNotifications?riderId=${riderId}&dataSourceId=${dataSourceId}&date=${date}&tripId=${tripId}&language=${language}`;
		return this.http.get<Array<GeoAlertNotification>>(uri)
			.pipe(
				catchError(error =>
				{
					return this.handleError(error);
				}));
	}

	saveGeoData(data: IGeoData)
	{
		const uri = `${this.stopfinderBaseUri}/geoalerts`;
		return this.http.post<any>(uri, data).pipe(
			catchError(error =>
			{
				return this.handleError(error);
			})
		);
	}

	getAllGeoAlerts(riderId: number)
	{
		const uri = `${this.stopfinderBaseUri}/geoalerts/${riderId}`;
		return this.http.get<any>(uri).pipe(
			catchError(error =>
			{
				return this.handleError(error);
			})
		);
	}

	checkGeoAlertNotTaken(id: any, riderId: any, name: string)
	{
		const uri = `${this.stopfinderBaseUri}/geoalerts/unique`;
		return this.http.get(uri,
			{
				params: { 'id': id, 'riderId': riderId, 'name': name }
			}).pipe(catchError(error =>
			{
				return this.handleError(error);
			}));
	}

	deleteGeoAlert(id: any)
	{
		const uri = `${this.stopfinderBaseUri}/geoalerts/${id}`;
		return this.http.delete(uri).pipe(catchError(error =>
		{
			return this.handleError(error);
		}));
	}

	getTripStopsAndPath(data: TripStopPostData): Observable<any>
	{
		const url = `${this.stopfinderBaseUri}/students/tripstop`;
		return this.http
			.post<any>(url, data)
			.pipe(
				catchError(error =>
				{
					return this.handleError(error);
				})
			);
	}

	getGPSSwitch(clientId: number, riderId: number): Observable<any>
	{
		const url = `${this.stopfinderBaseUri}/students/verifygeoalertenabled?clientId=${clientId}&riderId=${riderId}`;
		return this.http
			.get<any>(url)
			.pipe(
				catchError(error =>
				{
					return this.handleError(error);
				})
			);
	}

	getLocalization(clientId: number): Observable<any>
	{
		const url = `${this.stopfinderBaseUri}/students/localization?clientId=${clientId}`;
		return this.http
			.get<any>(url)
			.pipe(
				catchError(error =>
				{
					return this.handleError(error);
				})
			);
	}

	getLastBusLocation(groupName: string, busNumber?: string): Observable<IVehiclePoint>
	{
		const url = `${this.stopfinderBaseUri}/gps?groupName=${encodeURIComponent(groupName)}`;
		return this.http
			.get<any>(url).pipe(map((result) =>
			{
				if (!!result)
				{
					return {
						Timestamp: result.timestamp,
						StartTime: result.startTime,
						Latitude: String(result.latitude),
						Longitude: String(result.longitude),
						busNumber: busNumber,
					} as IVehiclePoint;
				} else
				{
					return result;
				}
			}));
	}

	getScheduledGeoNotifications(subscriberId: number, data: IScheduledNotification[]): Observable<ILastedGeoAlertNotification[]>
	{
		const language = this.localStorageService.get('subscriberLanguage') || this.getBrowserLang();
		const url = `${this.stopfinderBaseUri}/GeoAlertNotifications/${subscriberId}?language=${language}`;
		return this.http
			.post<any>(url, data)
			.pipe(
				catchError(error =>
				{
					return this.handleError(error);
				})
			);
	}

	getMapCenterSettings(clientId: number): Observable<any>
	{
		const url = `${this.stopfinderBaseUri}/geoalertsettings/mapsettings/${clientId}`;
		return this.http
			.get<any>(url)
			.pipe(
				catchError(error =>
				{
					return this.handleError(error);
				})
			);
	}

	changeLanguage(language: string): Observable<any>
	{
		const url = `${this.stopfinderBaseUri}/action/subscribers/language/change?language=${language}`;
		return this.http.post<any>(url, {})
			.pipe(catchError(error =>
			{
				return this.handleError(error);
			}));
	}

	getCurrentSubscriberLanguage()
	{
		const uri = `${this.stopfinderBaseUri}/action/subscribers/language`;
		return this.http.get<any>(uri).pipe(catchError(error =>
		{
			return this.handleError(error);
		}));
	}

	getCurrentScanned(utcDate: string): Observable<Array<IAttendance>>
	{
		const url = `${this.stopfinderBaseUri}/action/scannedrecords/current?date=${utcDate}`;
		return this.http.get<Array<IAttendance>>(url)
			.pipe(catchError(error =>
			{
				return this.handleError(error);
			}));
	}

	getScannedHistory(riderId: number, dataSourceId: number, date: string): Observable<Array<IAttendance>>
	{
		const uri = `${this.stopfinderBaseUri}/action/scannedrecords?riderId=${riderId}&dataSourceId=${dataSourceId}&date=${date}`;
		return this.http.get<Array<IAttendance>>(uri)
			.pipe(catchError(error =>
			{
				return this.handleError(error);
			}));
	}

	getUnCompleteForms(recipientIds: number[]): Observable<number[]>
	{
		const url = `${this.stopfinderBaseUri}/action/formssent/uncomplete`;
		return this.http
			.post<number[]>(url, recipientIds)
			.pipe(
				catchError(error =>
				{
					return this.handleError(error);
				})
			);
	}

	getFormByFormSendId(formSendId: number, recipientId?: number): Observable<IForm>
	{
		const url = `${this.stopfinderBaseUri}/formssent/info/${formSendId}${recipientId ? `?recipientId=${recipientId}` : ''}`;
		return this.http
			.get<IForm>(url)
			.pipe(
				catchError(error =>
				{
					return this.handleError(error);
				})
			);
	}

	getFormSentExpiredStatus(formSendId: number | string): Observable<boolean>
	{
		const url = `${this.stopfinderBaseUri}/formssent/verify/${formSendId}`;
		return this.http.get<boolean>(url).pipe(catchError(error =>
		{
			return this.handleError(error);
		}));
	}

	getFormRecipientById(recipientId: number): Observable<IFormSentRecipient>
	{
		const url = `${this.stopfinderBaseUri}/action/formsentrecipients/${recipientId}`;
		return this.http
			.get<IFormSentRecipient>(url)
			.pipe(
				catchError(error =>
				{
					return this.handleError(error);
				})
			);
	}

	getQuestionByFormSendId(formSendId: number, recipientId: number): Observable<Array<IQuestion>>
	{
		const url = `${this.stopfinderBaseUri}/action/formssent/question/${formSendId}${recipientId ? `?formSentRecipientId=${recipientId}` : ''}`;
		return this.http
			.get<Array<IQuestion>>(url)
			.pipe(
				catchError(error =>
				{
					return this.handleError(error);
				})
			);
	}

	updateFormStatus(recipientId: number, statusId: number): Observable<boolean>
	{
		const url = `${this.stopfinderBaseUri}/action/formssent/updatestatus/${recipientId}?statusId=${statusId}`;
		return this.http
			.post<boolean>(url, {})
			.pipe(
				catchError(error =>
				{
					return this.handleError(error);
				})
			);
	}

	getFormSent(): Observable<Array<IForm>>
	{
		const uri = `${this.stopfinderBaseUri}/formssent`;
		return this.http.get<Array<IForm>>(uri).pipe(catchError(error =>
		{
			return this.handleError(error);
		}));
	}

	getRequiredAndNotOpenedForm(): Observable<Array<IFormSentRecipient>>
	{
		const uri = `${this.stopfinderBaseUri}/action/formsentrecipients/checkrequiredform`;
		return this.http.get<Array<IFormSentRecipient>>(uri).pipe(catchError(error =>
		{
			return this.handleError(error);
		}));
	}

	saveFormResults(data: ISaveQuestionPayload): Observable<boolean>
	{
		const url = `${this.stopfinderBaseUri}/QuestionResult`;
		return this.http.post<boolean>(url, data).pipe(
			catchError(error =>
			{
				return this.handleError(error);
			})
		);
	}

	getQuestionResults(formSentId: number, formSentRecipientId: number): Observable<any>
	{
		const url = `${this.stopfinderBaseUri}/action/questionresults?formSentId=${formSentId}&formSentRecipientId=${formSentRecipientId}`;
		return this.http.get<any>(url).pipe(
			catchError(error =>
			{
				return this.handleError(error);
			})
		);
	}

	markFormSentRecipientArchiveStatus(formSentRecipientIds: number, status: boolean)
	{
		const uri = `${this.stopfinderBaseUri}/action/formsentrecipients/archive`;
		const gridData: GridData = {
			ids: [formSentRecipientIds],
			value: status
		};

		return this.http.post<OperationResult>(uri, gridData).pipe(catchError(error =>
		{
			return this.handleError(error);
		}));
	}

	getTutorialList(version?: string)
	{
		const url = `${this.stopfinderBaseUri}/AppTutorial${version ? `?appVersion=${version}` : ``}`;
		return this.http.get<Array<ITutorial>>(url).pipe(
			catchError(error =>
			{
				return this.handleError(error);
			})
		);
	}

	getTutorialImages(id?: number, version?: string, prevAppVersion?: string)
	{
		const language = this.localStorageService.get('subscriberLanguage') || this.getBrowserLang();
		const url = id ?
			`${this.stopfinderBaseUri}/AppTutorial/Photos/Id?language=${language}&id=${id}`
			: `${this.stopfinderBaseUri}/AppTutorial/Photos/Version?language=${language}&appVersion=${version}&prevAppVersion=${prevAppVersion}`;

		return this.http.get<ITutorial>(url).pipe(
			catchError(error =>
			{
				return this.handleError(error);
			})
		);
	}

	patchAttendance(subscriptionId: number, document: any)
	{
		const uri = `${this.stopfinderBaseUri}/subscriptions/${subscriptionId}`;
		return this.http.patch<any>(uri, document).pipe(
			catchError(error =>
			{
				return this.handleError(error);
			})
		);
	}

	getUserDefinedFields(formSentRecipientId: number)
	{
		const url = `${this.stopfinderBaseUri}/action/formssent/udf?formSentRecipientId=${formSentRecipientId}`;
		return this.http.get<Array<IUserDefinedField>>(url).pipe(
			catchError(error =>
			{
				return this.handleError(error);
			})
		);
	}

	getETAAlerts(subscriberId: number, riderId: number): Observable<Array<EtaAlert>>
	{
		const url = `${this.stopfinderBaseUri}/etaalerts?subscriberId=${subscriberId}&riderId=${riderId}`;
		return this.http.get<Array<EtaAlert>>(url).pipe(
			catchError(error =>
			{
				return this.handleError(error);
			})
		);
	}

	createEtaAlert(etaAlert: EtaAlert): Observable<EtaAlert>
	{
		const url = `${this.stopfinderBaseUri}/etaalerts`;
		const body = etaAlert;
		return this.http.post<EtaAlert>(url, body).pipe(
			catchError(error =>
			{
				return this.handleError(error);
			})
		);
	}

	deleteEtaAlert(id: number): Observable<boolean>
	{
		const url = `${this.stopfinderBaseUri}/etaalerts/${id}`;
		return this.http.delete<boolean>(url).pipe(
			catchError(error =>
			{
				return this.handleError(error);
			})
		)
	}

	patchEtaAlert(id: number, document): Observable<EtaAlert>
	{
		const url = `${this.stopfinderBaseUri}/etaalerts/${id}`;
		return this.http.patch<EtaAlert>(url, document).pipe(
			catchError(error =>
			{
				return this.handleError(error);
			})
		)
	}

	putEtaAlert(etaAlert: EtaAlert): Observable<EtaAlert>
	{
		const url = `${this.stopfinderBaseUri}/etaalerts/${etaAlert.id}`;
		return this.http.put<EtaAlert>(url, etaAlert).pipe(
			catchError(error =>
			{
				return this.handleError(error);
			})
		)
	}

	getRealTimeUpdateMultiple(groupNames: string[]): Observable<StopStatusDictionary>
	{
		const url = `${this.stopfinderBaseUri}/realtimeupdates/multiple`;
		return this.http.post<any>(url, groupNames).pipe(
			map((response) =>
			{
				if (response)
				{
					Object.keys(response).forEach((key) =>
					{
						response[key] = {
							StopId: response[key].stopId,
							CorrelationId: response[key].correlationId,
							ETA_TimeStamp: response[key].etA_TimeStamp,
							Planned_TimeStamp: response[key].planned_TimeStamp,
							ETA_TimeString: response[key].etA_TimeString,
							Message: response[key].message,
							EtaInMinutes: response[key].etaInMinutes,
							OnTime: response[key].onTime,
						} as StopStatus
					});
					return response;
				}
				return null;
			}),
			catchError(error =>
			{
				return this.handleError(error);
			}))
	}
}
