import * as _ from 'lodash';
import { Component, Input, ChangeDetectionStrategy, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StudentSchedule, ILastedGeoAlertNotification, IAttendance } from '../../shared/stopfinder/stopfinder-models';
import { ScheduleService } from '../schedule.service';

@Component({
  selector: 'app-student-schedules',
  templateUrl: './student-schedules.component.html',
  styleUrls: ['./student-schedules.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentSchedulesComponent implements AfterViewInit
{
  nullSchedule: StudentSchedule = null;
  @Input() schedules: Array<StudentSchedule>;
  @Input() geoNotifications: ILastedGeoAlertNotification[];

  constructor(
    public scheduleService: ScheduleService,
    public translate: TranslateService,
  ) { }
  ngAfterViewInit(): void
  {
    if (!_.isNull(this.schedules) && _.isEmpty(this.schedules))
    {
      // force render student-schedules.
      setTimeout(() =>
      {
        this.schedules = [];
      }, 20);
    }
  }

}
