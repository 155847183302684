import { Injectable, OnDestroy, NgZone } from '@angular/core';

@Injectable()
export class AndroidBackService implements OnDestroy {
  public disableAndroidBack = false;

  private disableMultipleBack = false;
  private androidBackCallback: Function = () => {};

  constructor(private readonly ngZone: NgZone) {
  }

  ngOnDestroy() {
  }

  onAndroidBackCallback() {
    if (this.disableMultipleBack) {
      return;
    }
    this.ngZone.run(() => {
      this.androidBackCallback();
    });
  }

  onDisableMultipleTarget(enable: boolean) {
    this.disableMultipleBack = enable;
  }

  onShouldCheckCallback(callback: Function) {
    this.disableAndroidBack = true;
    this.disableMultipleBack = false;
    callback && (this.androidBackCallback = callback);
  }

  onDestroyCallback() {
    this.disableAndroidBack = false;
    this.disableMultipleBack = false;
    this.androidBackCallback = () => {};
  }
}

