export enum UnitOfLength
{
  Feet = "ft",
  Meters = "m"
}

export enum LocalName
{
  US = "United States",
  Canada = "Canada"
}

export enum Language
{
  en = "en",
  es = "es",
  fr = "fr"
}

export enum ListPickerEnum
{
  Today = 'Today',
  Yesterday = 'Yesterday',
  ThisWeek = 'This Week',
  LastWeek = 'Last Week'
}

export enum AppVersions
{
  APP_260 = '2.6.0',
  APP_261 = '2.6.1',
  APP_300 = '3.0.0',
}

export enum TFMapType
{
  AttendanceMap = 'Attendance',
  GeoAlertMap = 'GeoAlert'
}
