export interface IAttendance
{
  id: number;
  riderId: number;
  dataSourceId: number;
  localId?: string;
  boardBus?: boolean;
  tripId?: number;
  stopId?: number;
  stopName?: string;
  tripName?: string;
  clientId?: number;
  latitude?: number;
  longitude?: number;
  scannedDate?: string;  // utc date
}

export interface IAttendanceCoords
{
  attendanceLatitude: number;
  attendanceLongitude: number;
  schoolLatitude: number;
  schoolLongitude: number;
  studentLatitude: number;
  studentLongitude: number;
}


export enum AttendanceSource
{
  FROM_SCHEDULE = 'schedule',
  FROM_HISTORY = 'history',
}
