import * as _ from 'lodash';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IForm, IFormSentRecipient, FormSource } from '../form.interface';
import { AndroidBackService } from '../../androidBack.service';
import { FormService } from '../../components/service/form/form.service';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/app.service';
import { DeviceService } from 'src/app/components/service/device/device.service';
import { IGeoLocationPosition, IUserDefinedField } from 'src/app/shared/stopfinder/stopfinder-models';
import { map } from 'rxjs/operators';
import { zip } from 'rxjs';

@Component({
  selector: `sf-form-cover`,
  templateUrl: 'form.cover.component.html',
  styleUrls: ['form.cover.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SFFormCoverComponent implements OnInit, AfterViewInit, OnDestroy
{
  public formInfo: IForm = null;
  public selectRecipient: IFormSentRecipient;
  public recipientData: Array<IFormSentRecipient> = [];

  private formSendId: number;
  private recipientId: number;
  private checkFormComplete: boolean = false;
  private udfLists: IUserDefinedField[];

  constructor(
    public readonly formService: FormService,
    public readonly translate: TranslateService,
    private readonly router: ActivatedRoute,
    private readonly androidBackService: AndroidBackService,
    private readonly deviceService: DeviceService,
    private readonly appService: AppService,
  )
  { }

  ngOnInit()
  {
    this.androidBackService.onShouldCheckCallback(this.onCancel.bind(this));
    this.formSendId = Number(this.router.snapshot.paramMap.get('id'));
    this.recipientId = Number(this.router.snapshot.paramMap.get('recipientId'));
    this.formService.formSource = (this.router.snapshot.paramMap.get('source')) as FormSource
    this.getFormInfo();
  }

  ngAfterViewInit() { }

  ngOnDestroy()
  {
    this.androidBackService.onDestroyCallback();
  }

  onStartAnswer()
  {
    this.formService.updateLoadingStatus(true);
    // must check the form is completed when start question
    this.formService.hasFormCompleted().then(async (hasCompleted) =>
    {
      if (hasCompleted)
      {
        this.formService.updateLoadingStatus(false);
        this.formService.showFormCompletedAlert();
        return;
      }

      // check and get the location on location required form
      if (!!this.formInfo.requireLocation)
      {
        const geoLocationEnable = await this.checkAndSetGeoLocation();
        if (!geoLocationEnable)
        {
          this.formService.updateLoadingStatus(false);
          return;
        };
      }

      if (this.formService.questionsList.value)
      {
        this.formService.updateLoadingStatus(false);
        this.formService.updateCoverStatus(false);
        return;
      }

      this.formService.userDefendFields.next(null);
      zip(
        this.formService.getQuestions(this.formSendId, this.recipientId),
        this.formService.getUserDefinedFields(this.recipientId),
      ).subscribe(([question, udf]) =>
      {
        this.formService.userDefendFields.next(udf);

        this.formService.updateLoadingStatus(false);
        if ((question || []).length > 0)
        {
          this.formService.updateCoverStatus(false);
        }
      });

    }).catch((e) =>
    {
      this.formService.updateLoadingStatus(false);
    });
  }

  onRemindMeLater()
  {
    if (this.checkFormComplete) return;

    this.checkFormComplete = true;

    this.formService.hasFormCompleted().then((hasCompleted) =>
    {
      this.checkFormComplete = false;

      if (hasCompleted)
      {
        this.formService.showFormCompletedAlert();
        return;
      }

      this.formService.doRemindMeLater(this.selectRecipient.id);
    }).catch(() => { this.checkFormComplete = false; });
  }

  onCancel()
  {
    if (this.formService.isRequired) return;

    this.formService.updateFormStatusToCancel();
  }

  async checkAndSetGeoLocation(): Promise<boolean>
  {
    this.formService.geoLocationPosition.next(null);

    const geoLocationPosition = await this.appService.getGeoLocationPosition().catch((error) =>
    {
      error && this.appService.nativeDialogInstance.confirm(
        this.deviceService.isAndroid ? this.translate.instant(`form.enable.location.title`) : "",
        (value) => `${value}` === `1` && this.switchToSettings(error),
        this.deviceService.isAndroid ? "" : this.translate.instant(`form.enable.location.title`),
        [this.translate.instant(`form.enable.location.setting`),
        this.translate.instant(`form.enable.location.cancel`)]);
    });

    if (geoLocationPosition)
    {
      this.formService.geoLocationPosition.next(geoLocationPosition as IGeoLocationPosition);
      return true;
    } else
    {
      return false;
    }
  }

  private switchToSettings(error)
  {
    if (error.code === 3)
    {
      this.appService.diagnosticInstance.switchToLocationSettings();
    } else
    {
      this.appService.diagnosticInstance.switchToSettings();
    }
  }

  private getFormInfo(): void
  {
    if (this.formSendId <= 0)
    {
      this.formService.openMessagesPage();
      throw new Error("Unauthorized access!!!");
    }

    if (this.formService.formInfo.getValue())
    {
      this.formInfo = this.formService.formInfo.getValue();
      this.updateRecipient(this.formInfo.formSentRecipient,
        this.formService.selectRecipient.getValue() && this.formService.selectRecipient.getValue().id);
      return;
    }

    this.formService.getFormInfo(this.formSendId, this.recipientId).subscribe(form =>
    {
      this.formService.updateLoadingStatus(false);
      if (form)
      {
        this.formInfo = form;
        this.updateRecipient(form.formSentRecipient);
      }
      else
      {
        this.formService.openMessagesPage();
        throw new Error("error: blank form send");
      }
    })
  }

  private updateRecipient(recipients?: Array<IFormSentRecipient>, selectRecipientId?: number)
  {
    recipients = (recipients || this.recipientData).sort((a, b) => a.openedStatusId - b.openedStatusId);
    selectRecipientId = selectRecipientId || recipients[0].id;

    this.recipientData = recipients;
    this.selectRecipient = _.find(this.recipientData, { id: Number(selectRecipientId) });
    this.formService.updateSelectRecipient(this.selectRecipient);
  }
}
