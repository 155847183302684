import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import
{
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../app.service';
import { environment } from '../../environments/environment';
import { LocalStorageService } from '../shared/local-storage/local-storage.service';
import { ForgotPasswordService } from '../forgot-password/forgot-password.service';
import { DeviceService } from '../components/service/device/device.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [ForgotPasswordService]
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy
{
  public loginForm: FormGroup;
  public error: string;
  public version: string;
  public loggingIn = false;
  public showStoreIcons = true;
  public submitted = false;
  public clientIDRequired = false;
  public userNameRequired = false;
  public passwordRequired = false;
  public registrationId = 'test';
  public isSendingForgotPassword = false;
  public isKeyboardOpen = false;
  public loginPageBlock: any;
  public loginPageBlockNeedCalc: any;
  public loginPageExpandClass = 'login-page-expand';
  public loginPageExpandedBaseClass = 'login-page-expanded-';
  public loginPageExpandedClass = '';
  public deviceHeight: number;
  constructor(
    public app: AppService,
    public readonly deviceService: DeviceService,
    private readonly forgotPasswordService: ForgotPasswordService,
    private readonly formBuilder: FormBuilder,
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router,
    private readonly translate: TranslateService,
  ) { }

  ngOnInit()
  {
    this.version = environment.version;
    this.showStoreIcons = !this.deviceService.isNativeMobile;
    this.registrationId = this.app.pushDeviceToken;
    this.loginForm = this.formBuilder.group({
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8)
      ])
    });

  }

  // convenience getter for easy access to form fields
  get f()
  {
    return this.loginForm.controls;
  }

  ngAfterViewInit()
  {
    this.loginForm.valueChanges.subscribe((value) =>
    {
      if (value.email.indexOf(' ') !== -1)
      {
        this.loginForm.get('email').setValue(this.loginForm.get('email').value.trim());
      }
    });

    this.loginPageBlock = document.getElementById("login-page-block");
    this.loginPageBlockNeedCalc = document.getElementById("login-page-block-need-calc");

    const currentTime = (new Date()).getTime() + 10000;
    if (!this.deviceService.isiOS)
    {
      this.initialLoginPageBlockExpandedClass(currentTime);
    }
    this.initialLoginPageLoadIndicator(currentTime);
  }

  ngOnDestroy()
  {
    this.loggingIn = false;
  }

  initialLoginPageLoadIndicator(currentTime: any)
  {
    if ((new Date()).getTime() <= currentTime)
    {
      const loadingIndicatorElement = document.getElementsByClassName('loading-indicator')[0];
      this.deviceHeight = document.body.offsetHeight;
      if (this.deviceHeight && loadingIndicatorElement && (loadingIndicatorElement as HTMLElement).style.top !== `${Math.ceil(this.deviceHeight / 2)}px`)
      {
        (loadingIndicatorElement as HTMLElement).style.top = `${Math.ceil(this.deviceHeight / 2)}px`;
      }
      else
      {
        setTimeout(() =>
        {
          this.initialLoginPageLoadIndicator(currentTime);
        }, 100);
      }
    }
  }

  initialLoginPageBlockExpandedClass(currentTime: any)
  {
    if ((new Date()).getTime() <= currentTime)
    {
      if (!this.loginPageBlock)
      {
        this.loginPageBlock = document.getElementById("login-page-block");
      }
      if (!this.loginPageBlockNeedCalc)
      {
        this.loginPageBlockNeedCalc = document.getElementById("login-page-block-need-calc");
      }

      if (this.loginPageBlock && this.loginPageBlockNeedCalc && document.getElementById('logo-container'))
      {
        let needExpandedHeight = 0;
        if (document.getElementById('logo-container').offsetHeight >= 20)
        {
          needExpandedHeight = Math.ceil((((this.loginPageBlockNeedCalc.offsetHeight - document.body.offsetHeight / 2 - 20) / this.loginPageBlockNeedCalc.offsetHeight) * 100) / 1.8);
        }
        else
        {
          needExpandedHeight = Math.ceil(((((this.loginPageBlockNeedCalc.offsetHeight + (document.body.offsetWidth - 40) * 0.4 - 19) - document.body.offsetHeight / 2 - 20) / this.loginPageBlockNeedCalc.offsetHeight) * 100) / 1.8);
        }

        if (needExpandedHeight > 0)
        {
          if (needExpandedHeight >= 50)
          {
            this.loginPageBlock.style.transform = 'scale(0.49) translateY(-49%)';
          }
          else
          {
            this.loginPageBlock.style.transform = `scale(${1 - (needExpandedHeight + 1) / 100}) translateY(-${needExpandedHeight - 1}%)`;
          }
        }
      }
      else
      {
        setTimeout(() =>
        {
          this.initialLoginPageBlockExpandedClass(currentTime);
        }, 100);
      }
    }
  }

  onSubmit()
  {

    if (this.loginForm.invalid)
    {
      return;
    }

    this.submitted = true;
    this.loggingIn = true;

    this.app.login(this.f.email.value, this.f.password.value).subscribe(
      success =>
      {
        this.translate.use(this.app.subscriberLanguage ? this.app.subscriberLanguage : this.translate.currentLang);
        this.router.navigate(['/schedule']);
        this.localStorageService.set('currentEmail', this.f.email.value);
      },
      failure =>
      {
        this.loggingIn = false;
        this.app.unregisterPushDevice().subscribe();
        if (failure.error)
        {
          const error = failure.error.title || failure.error;
          switch (error)
          {
            case "Invalid credentials":
              this.error = this.translate.instant("login.login.invalid.credentials");
              break;
            case "Invalid subscriber":
              this.error = this.translate.instant("login.login.invalid.subscriber");
              break;
            case "Banned subscriber":
              this.error = this.translate.instant("login.login.banned.subscriber");
              break;
            case "Internal server error":
              this.error = this.translate.instant("login.login.internal.error");
              break;
            default:
              this.error = error;
              break;
          }
        }
        else
        {
          switch (failure.message)
          {
            case "App must be upgraded":
              this.error = this.translate.instant("login.modal.app.upgrade.title");
              break;
            case "Incompatible App Version":
              this.error = this.translate.instant("modal.api.upgrade.title");
              break;
            default:
              this.error = failure;
              break;
          }
        }
      }
    );
  }

  // This is temporary
  gotoActivation()
  {
    this.router.navigate(['/activation']);
  }

  onEmailBlur()
  {
    this.loginForm.get('email').setValue(this.loginForm.get('email').value.trim());
  }

  onPasswordBlur()
  {
    const passwordForm = this.loginForm.get('password');
    passwordForm.setValidators([
      Validators.required,
      Validators.minLength(8)
    ]);
    passwordForm.updateValueAndValidity();
  }

  onForgotPassword()
  {
    this.loginForm.get('password').setErrors(null);
    if (!this.isSendingForgotPassword)
    {
      this.error = '';
      const emailAddress = this.loginForm.get('email').value.trim();

      if (this.app.verifyEmailAddressFormat(emailAddress))
      {
        this.isSendingForgotPassword = true;
        this.forgotPasswordService.getForgotPasswordEnrollment(emailAddress).toPromise().then(
          response =>
          {
            this.forgotPasswordService.createForgotPassword(emailAddress)
              .subscribe(
                forgotPassword =>
                {
                  this.error = this.translate.instant("login.reset.password.email.success");
                  this.isSendingForgotPassword = false;
                },
                failure =>
                {
                  if (failure.error.toString() === 'Email has not been activated.')
                  {
                    this.error = this.translate.instant("login.reset.password.email.not.active");
                  }
                  else
                  {
                    this.error = this.translate.instant("login.reset.password.email.not.found");
                  }
                  this.isSendingForgotPassword = false;
                });
          },
          failure =>
          {
            this.error = this.translate.instant("login.reset.password.email.not.found");
            this.isSendingForgotPassword = false;
          });
      }
      else
      {
        this.loginForm.controls["email"].markAsTouched()
      }
    }
  }
}
