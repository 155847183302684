import { Component, Input, OnChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { trigger, style, animate, transition, state } from '@angular/animations';

@Component({
  selector: 'map-button',
  templateUrl: './map-button.component.html',
  styleUrls: ['./map-button.component.scss'],
  animations: [
    trigger('state', [
      state('true', style({ 'background-color': '#66FF66' })),
      state('false', style({ 'background-color': '#CCC' })),
      transition('true<=>false', animate(150))
    ])
  ]
})
export class MapButtonComponent implements OnChanges {

  @Input() imageName: string;
  @Input() iconSize: number;
  @Input() stateValue: boolean;

  public imagePath: string;
  public showState: boolean;

  constructor(
    public sanitizer: DomSanitizer, ) {
  }

  ngOnChanges() {
    this.imagePath = `./assets/images/${this.imageName}`;
    if (this.stateValue === false || this.stateValue === true)
    {
      this.showState = true;
    }
  }

}
