import { eBaseMaps, BaseMapThumbnailEnum } from '../classes/basemap.enum';

export interface IBasemapThumbnail
{
    title: string;
    thumbnail: string;
    type: eBaseMaps;
    translateKey: BaseMapThumbnailEnum;
}

export class BasemapThumbnail implements IBasemapThumbnail
{
    public title: string;
    public thumbnail: string;
    public type: eBaseMaps;
    public translateKey: BaseMapThumbnailEnum;

    constructor(title: string, thumbnail: string, map: eBaseMaps, translateKey: BaseMapThumbnailEnum)
    {
        this.title = title;
        this.thumbnail = thumbnail;
        this.type = map;
        this.translateKey = translateKey;
    }
}
