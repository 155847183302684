import { Component, Input, OnInit, OnDestroy, ViewChild, ElementRef, ViewEncapsulation, Inject, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import SignaturePad, { Options } from "signature_pad";

export interface SignatureOptions extends Options
{
  width?: number;
  height?: number;
  isShowUndo?: boolean;
  isShowClear?: boolean;
  isShowSave?: boolean;
  defaultValue?: string;
  customClass?: string;
  saveAction?: Function;
}

export interface SignatureDialogOptions extends SignatureOptions
{
  title?: string;
  closeAction?: Function;
}

@Component({
  selector: 'signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss']
})

export class SignaturePadComponent implements OnInit, OnDestroy
{
  @Input() public options: SignatureOptions;

  @ViewChild('signaturePadContent', { static: true }) signaturePadContent: ElementRef;
  @ViewChild('signaturePadCanvas', { static: true }) signaturePadCanvas: ElementRef;

  private signaturePad: SignaturePad = null;

  public signatureOptions: SignatureOptions = {};
  public isEmpty: boolean = true;

  public saveButtonText: string = "Save";
  public clearButtonText: string = "Clear";
  public undoButtonText: string = "Undo";

  constructor(
    private readonly ngZone: NgZone,
    private readonly translate: TranslateService
  ) { }

  ngOnInit()
  {
    this.setTranslate();
    this.signatureOptions = this.mergeOptions();
    this.signaturePad = this.initSignaturePad(this.signatureOptions);
  }

  ngOnDestroy()
  {
    this.signaturePad && this.signaturePad.off();
    this.signaturePad = null;
  }

  onClearSignature()
  {
    if (this.signaturePad && !this.signaturePad.isEmpty())
    {
      this.signaturePad.clear();
      this.setButtonDisabled();
    }
  }

  onUndoSignature()
  {
    if (this.signaturePad && !this.signaturePad.isEmpty())
    {
      const data = this.signaturePad.toData();
      data && data.pop();
      data && this.signaturePad.fromData(data);
      this.setButtonDisabled();
    }
  }

  onSaveSignature()
  {
    if (this.signaturePad && !this.signaturePad.isEmpty())
    {
      if (typeof this.signatureOptions.saveAction === "function")
      {
        const base64Image = this.signaturePad.toDataURL();
        this.signatureOptions.saveAction(base64Image);
      }
      this.setButtonDisabled();
    }
  }

  private setTranslate()
  {
    this.saveButtonText = this.translate.instant("signature.button.save");
    this.clearButtonText = this.translate.instant("signature.button.clear");
    this.undoButtonText = this.translate.instant("signature.button.undo");
  }

  private mergeOptions(): SignatureOptions
  {
    const signaturePadContentEle = this.signaturePadContent.nativeElement as HTMLElement;
    const defaultOptions: SignatureOptions = {
      isShowClear: true,
      isShowUndo: true,
      isShowSave: true,
      width: (signaturePadContentEle && signaturePadContentEle.offsetWidth) || 300,
      height: 300,
    };

    return { ...defaultOptions, ...this.options };
  }

  private initSignaturePad(options: SignatureOptions): SignaturePad
  {
    const signaturePad = new SignaturePad(this.signaturePadCanvas.nativeElement, this.signatureOptions);
    const { defaultValue, width, height } = (options || {}) as SignatureOptions;
    defaultValue && signaturePad.fromDataURL(defaultValue, { width, height, });
    defaultValue && this.setButtonDisabled(signaturePad);
    signaturePad.onEnd = this.onSignatureEnd.bind(this);
    return signaturePad;
  }

  private onSignatureEnd(event: any)
  {
    this.setButtonDisabled();
    if (typeof this.signatureOptions.onEnd === 'function')
    {
      this.signatureOptions.onEnd(event);
    }
  }

  private setButtonDisabled(signaturePad?: SignaturePad)
  {
    this.ngZone.run(() =>
    {
      this.isEmpty = (signaturePad || this.signaturePad).isEmpty();
    });
  }
}


@Component({
  styleUrls: ['signature-pad.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <div class="signature-pad-dialog-content">
      <header>
        <span>{{ dialogTitle }}</span>
        <mat-icon (click)="onCloseDialog()">close</mat-icon>
      </header>
      <signature-pad [options]="signatureOptions"></signature-pad>
    </div>
  `
})
export class SignaturePadDialogComponent
{
  public signatureOptions: SignatureOptions;
  public dialogTitle: string = "Signature";

  constructor(
    private readonly translate: TranslateService,
    public dialogRef: MatDialogRef<SignaturePadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SignatureDialogOptions,
  )
  {
    this.dialogTitle = data.title ? data.title : this.translate.instant("signature.dialog.title");
    this.signatureOptions = {
      ...data,
      customClass: "signature-pad-dialog",
      saveAction: this.onSaveSignature.bind(this)
    };
    this.dialogRef.addPanelClass(["signature-pad-dialog"]);
  }

  onSaveSignature(signature: string)
  {
    this.dialogRef && this.dialogRef.close();
    if (this.data && typeof this.data.saveAction === "function")
    {
      this.data.saveAction(signature);
    }
  }

  onCloseDialog()
  {
    typeof this.data.closeAction === "function" && this.data.closeAction();
    this.dialogRef && this.dialogRef.close();
  }
}
