import
{
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { ISubNavItem } from '../sub-nav-item.interface';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSelectChange, MatSelect } from '@angular/material';
import { IMapHeaderSelection } from 'src/app/components/interface/map-header-selection.interface';
import { MapService } from '../../../components/service/map/map.service';

export interface MenuOption
{
  text: string,
  action: any,
}

@Component({
  selector: 'sf-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit
{
  @Input() public title;
  @Input() public left;
  @Input() public leftImage = true;
  @Input() public leftBoth = false;
  @Input() public right;
  @Input() public rightImage: boolean;
  @Input() public rightMenu: boolean;
  @Input() public menuOptions: MenuOption[];
  @Input() public headerSelection?: IMapHeaderSelection;
  @Input() public leftIsDisabled = false;
  @Input() public rightIsDisabled = false;
  @Input() public titleColor: string = '#333333';
  @Input() public headerTheme: string;
  @Output() public leftButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() public centerButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() public rightButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() public menuItemClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() public headerOptionChangeEvent: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();
  subNavItems: ISubNavItem[];

  constructor(
    public router: Router,
    public route: ActivatedRoute,
  ) { }

  ngOnInit()
  {
    this.subNavItems = [
      {
        Path: 'schedule',
        Component: 'ScheduleComponent',
        Title: 'Subscriptions',
        Notifications: 0,
        DisplayNotifications: false
      },
      {
        Path: 'messages',
        Component: 'MessagesComponent',
        Title: 'Messages',
        Notifications: 2,
        DisplayNotifications: true
      },
    ];
  }

  ngAfterViewInit()
  {
  }

  setPath(path)
  {
    this.router.navigate([path]);
  }

  public getTheme = (): string => this.headerTheme;
}

@Component({
  selector: 'sf-header-selection',
  template: `
    <mat-select
      #headerDropdown
      [(value)]="headerSelection.defaultValue"
      (selectionChange)="headerOptionChangeEvent.emit($event)"
      disableOptionCentering
    >
      <mat-option
        class="sf-header-select"
        *ngFor="let option of headerSelection.options"
        [value]="option.value"
      >
        {{option.viewValue}}
      </mat-option>
    </mat-select>
  `
})
export class HeaderSelection implements OnInit, AfterViewInit
{
  @Input() public headerSelection?: IMapHeaderSelection;
  @Output() public headerOptionChangeEvent: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();
  @ViewChild('headerDropdown', { static: true }) headerDropdown: MatSelect;

  constructor(
    private readonly mapService: MapService,
  )
  {
  }

  ngOnInit() { }

  ngAfterViewInit()
  {
    if (this.headerDropdown)
    {
      this.mapService.headerDropdown = this.headerDropdown;
    }
  }
}
