import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, Inject, ViewEncapsulation, NgZone } from '@angular/core';
import { AnimationEvent } from '@angular/animations';

import { ToastData, TOAST_CONFIG_TOKEN, ToastConfig, defaultToastConfig } from './toast-config';
import { ToastRef } from './toast-ref';
import { toastAnimations, ToastAnimationState } from './toast-animation';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['toast.component.scss'],
  animations: [toastAnimations.fadeToast],
  encapsulation: ViewEncapsulation.None,
})
export class ToastComponent implements OnInit, OnDestroy
{

  public animationState: ToastAnimationState = 'default';
  public iconType: string;
  public duration: number;

  private intervalId: any;

  constructor(
    public readonly data: ToastData,
    public readonly ref: ToastRef,
    @Inject(TOAST_CONFIG_TOKEN) public toastConfig: ToastConfig,
    private ngZone: NgZone,
  )
  {
    this.toastConfig = defaultToastConfig;
    this.iconType = _.isEqual(data.type, 'success') ? `done` : data.type;
    this.duration = this.data.duration || 5000;
  }

  ngOnInit()
  {
    this.intervalId = setTimeout(() => this.animationState = 'closing', this.duration);
  }

  ngOnDestroy()
  {
    clearTimeout(this.intervalId);
  }

  public close()
  {
    this.ngZone.run(() =>
    {
      this.ref.close();
    });
  }

  public onFadeFinished(event: AnimationEvent)
  {
    const { toState } = event;
    const isFadeOut = _.isEqual((toState as ToastAnimationState), `closing`);
    const itFinished = _.isEqual(this.animationState, `closing`);

    isFadeOut && itFinished && this.close();
  }
}
