import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MapToolsComponent } from './map-tools/map-tools.component';
import { BasemapComponent } from './basemap/basemap.component';
import { MapButtonComponent } from '../map-button/map-button.component';
import { ActionSheetComponent } from '../action-sheet/action-sheet/action-sheet.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

// required for AOT compilation
export const HttpLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http);

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      useDefaultLang: true,
      defaultLanguage: 'en'
    })
  ],
  declarations: [
    MapToolsComponent,
    BasemapComponent,
    MapButtonComponent,
    ActionSheetComponent,
  ],
  exports: [
    MapToolsComponent,
    BasemapComponent,
    MapButtonComponent,
    ActionSheetComponent,
  ],
  entryComponents: [],
  providers: [
  ]
})
export class MapToolsModule { }
