import { MapLayerName } from "src/app/tf-map/themes/enums/enum.map-layer";
import { SymbolType } from "src/app/tf-map/themes/enums/enum.symbol-type";
import { StudentSchedule } from "./student-schedule";
import { AppService } from "src/app/app.service";


export enum GraphicNameEnum
{
  studentGraphic = 'studentGraphic',
  schoolGraphic = 'schoolGraphic',
  stopGraphic = 'stopGraphic',
  pickupGraphic = 'pickupGraphic',
  pickupTransferGraphic = 'pickupTransferGraphic',
  dropOffGraphic = 'dropOffGraphic',
  dropOffTransferGraphic = 'dropOffTransferGraphic',
  labelGraphics = 'labelGraphics',
  tripStopGraphics = 'tripStopGraphics',
  tripLabelGraphics = 'tripLabelGraphics',
  attendanceGraphics = 'attendanceGraphics',
}


export interface IPoint
{
  longitude: number,
  latitude: number,
}

export interface ILayerState
{
  [GraphicNameEnum.studentGraphic]?: boolean | true,
  [GraphicNameEnum.schoolGraphic]?: boolean | true,
  [GraphicNameEnum.pickupGraphic]?: boolean | true,
  [GraphicNameEnum.pickupTransferGraphic]?: boolean | false,
  [GraphicNameEnum.dropOffGraphic]?: boolean | false,
  [GraphicNameEnum.dropOffTransferGraphic]?: boolean | false,
  [GraphicNameEnum.labelGraphics]?: boolean | false,
  [GraphicNameEnum.attendanceGraphics]?: boolean | false,
}

export interface IGraphic
{
  mapId?: string,
  layerId?: MapLayerName,
  graphicId?: GraphicNameEnum,
  geometryData?: {
    spatialReference?: {
      wkid?: number
    },
    x?: number | null,
    y?: number | null
  },
  symbolName?: SymbolType,
  symbolData?: Object,
  clearLayer?: boolean,
  attributes?: IAttributes,
}

export interface IPosition
{
  position: string;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
}

export interface IVehiclePoint
{
  RowId?: string,
  StartTime: string,
  Timestamp: number,
  Longitude: string,
  Latitude: string,
  busNumber?: string
}

export interface IBusParameter
{
  mapId: string,
  tripId: number,
  studentSchedule: StudentSchedule,
  selectedTrip: any,
  appService?: AppService,
}

export interface IMapScaleResponse
{
  scale: number
}

interface IAttributes
{
  type?: string,
  text?: string,
  name?: string,
  isTransfer?: boolean
}
