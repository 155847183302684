
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import
{
  DEFAULT_PREVIEW_IMAGE,
  STUDENT_BOOLEAN_FIELDS,
  STUDENT_COORD_FIELDS,
  STUDENT_DATE_FIELDS,
  STUDENT_IMAGE_FIELDS,
  STUDENT_INTEGER_FIELDS,
  STUDENT_NUMBER_FIELDS,
  STUDENT_TIME_FIELDS,
  IMAGE_FILED_FLAG,
} from './constant';
import { IUserDefinedField, IUserDefinedType } from '../stopfinder/stopfinder-models';

var PHONE_NUMBER_PATTERNS = [{
  order: 1,
  mode: /^\D*(?:1|01|001)?\D*(\d{3})\D*(\d{3})\D*(\d{4})\D*$/,
  display: "001-(${groups[1]})${groups[2]}-${groups[3]}",
  value: "001${groups[1]}${groups[2]}${groups[3]}",
  region: "USA,Canada"
}];

export const formatPhoneNumber = (value: string) =>
{
  
  if (value === null || value === undefined)
  {
    return value;
  }
  let content = value.toString(),
    cleanPhone = content.replace(/\D/g, ''),
    groups = cleanPhone.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
  if (cleanPhone.length < 3)
  {
    content = cleanPhone;
  } else if (cleanPhone.length >= 3 && cleanPhone.length <= 6)
  {
    content = `(${groups[1]}) ${groups[2]}`;
  } else if (cleanPhone.length >= 6 && cleanPhone.length <= 10)
  {
    content = `(${groups[1]}) ${groups[2]}-${groups[3]}`;
  } else
  {
    var phoneNumberWithCountryCodePatterns = PHONE_NUMBER_PATTERNS;
    let longPhoneNumberMatched = false;
    for (let pattern of phoneNumberWithCountryCodePatterns)
    {
      groups = cleanPhone.match(pattern.mode);
      if (groups)
      {
        content = eval("`" + pattern.display + "`");
        longPhoneNumberMatched = true;
        break;
      }
    }
    if (!longPhoneNumberMatched)
    {
      content = content.substr(0, 16);
    }
  }

  return content;
}

export const isValidPhoneNumber = (value: string) =>
{
  if (value === null || value === undefined || value === '')
  {
    return false;
  }
  var digitsValue = value.replace(/\D/g, '');
  if (digitsValue.length < 10)
  {
    return false;
  }
  let phoneNumberWithCountryCodePatterns = PHONE_NUMBER_PATTERNS;
  let longPhoneNumberMatched = false;
  for (let pattern of phoneNumberWithCountryCodePatterns)
  {
    let matched = pattern.mode.test(digitsValue);
    if (matched)
    {
      longPhoneNumberMatched = true;
      break;
    }
  }
  return longPhoneNumberMatched;
}

export const decodePath = (encodePath: string) =>
{
  if (!encodePath)
  {
    return [];
  }

  let next5bits = null;
  let sum = null;
  let shifter = null;
  let index = 0;
  let currentLat = 0;
  let currentLng = 0;
  const coordinates = [];
  const polylineChars = encodePath;
  const length = polylineChars.length;

  while (index < length)
  {
    sum = 0;
    shifter = 0;
    do
    {
      next5bits = polylineChars[index++].charCodeAt(0) - 63;
      sum |= (next5bits & 31) << shifter;
      shifter += 5;
    } while (next5bits >= 32 && index < length);

    if (index >= length)
    {
      break;
    }

    currentLat += (sum & 1) == 1 ? ~(sum >> 1) : (sum >> 1);
    sum = 0;
    shifter = 0;

    do
    {
      next5bits = polylineChars[index++].charCodeAt(0) - 63;
      sum |= (next5bits & 31) << shifter;
      shifter += 5;
    } while (next5bits >= 32 && index < length);

    if (index >= length && next5bits >= 32)
    {
      break;
    }

    currentLng += (sum & 1) == 1 ? ~(sum >> 1) : (sum >> 1);
    coordinates.push([currentLng / 1E6, currentLat / 1E6]);
  }

  return coordinates;
}

export const formatCoords = (value): number =>
{
  if (!value)
  {
    return value;
  }
  let result = "";
  const valArray = String(value).split(".");
  result = `${valArray[0]}.${(valArray[1] || "").substring(0, 4)}`

  return Number(result);
}

export const formatVersion = (version: string): number =>
{
  return Number((version || '').toLowerCase().replace(/\.|v|:/g, "").padEnd(10, "0"));
};

export const getLocalRFApiVersion = (version: string): string =>
{
  if (formatVersion(version) >= 2000000000)
  {
    return environment.rfV2ApiVersion;
  } else
  {
    return environment.rfV1ApiVersion;
  }
}


export const getAppVersion = (): string =>
{
  const appVersion = environment.appVersion;
  return appVersion.trim();
}

export const stringToDate = (date: string): Date =>
{
  // Safari will get incorrect date if the date string contains '-'
  return new Date(moment(date).format('M/D/YYYY'));
}

export const generatePreviewImage = (type: string, encodedBytes: String): Promise<string> =>
{
  const generateImage = (imageSrc: string): Promise<string> =>
  {
    return new Promise<string>((resolve) =>
    {
      if (!imageSrc)
      {
        return resolve(DEFAULT_PREVIEW_IMAGE);
      }

      try
      {
        const canvas: HTMLCanvasElement = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const newImg = document.createElement("img");
        newImg.src = imageSrc;
        newImg.addEventListener("load", () =>
        {
          if (newImg.height > newImg.width)
          {
            canvas.height = 64;
            canvas.width = (canvas.height * newImg.width) / newImg.height;
          } else
          {
            canvas.width = 64;
            canvas.height = (canvas.width * newImg.height) / newImg.width;
          }

          context.scale(canvas.width / newImg.width, canvas.height / newImg.height);
          context.drawImage(newImg, 0, 0);

          return resolve(canvas.toDataURL());
        });

      } catch (e)
      {
        return resolve(DEFAULT_PREVIEW_IMAGE);
      }
    });
  }

  const mainType = (type || "").split("/")[0];
  switch (mainType)
  {
    case 'image':
      return generateImage(`data:${type};base64, ${encodedBytes}`);
    default:
      return generateImage(DEFAULT_PREVIEW_IMAGE);
  }
}

export const isImageSystemFiled = (answer: string) =>
{
  if (!answer) return answer;
  return answer.includes(IMAGE_FILED_FLAG);
}

export const setImageSystemFiledClass = (answer: string) =>
{
  return `${answer}`.split(IMAGE_FILED_FLAG)[1] || "";
}

export const formatSystemField = (fieldName: string, answer: string, udfFields?: IUserDefinedField[]): string =>
{
  if (!fieldName || !answer) return answer;

  const dateFields = new Set(STUDENT_DATE_FIELDS);
  const timeFields = new Set(STUDENT_TIME_FIELDS);
  const numberFields = new Set(STUDENT_NUMBER_FIELDS);

  (udfFields || []).forEach(({ DisplayName, Type }) =>
  {
    switch (Type)
    {
      case IUserDefinedType.DATE:
        dateFields.add(DisplayName);
        break;
      case IUserDefinedType.TIME:
        timeFields.add(DisplayName);
        break;
      case IUserDefinedType.NUMBER:
        numberFields.add(DisplayName);
        break;
    }
  });

  if (dateFields.has(fieldName))
  {
    return moment(answer).format("MM/DD/YYYY");
  } else if (timeFields.has(fieldName))
  {
    const _time = answer.split(" ");
    const time = moment(moment().format('MM/DD/YYYY') + " " + `${_time.length > 1 ? _time[1] : _time[0]}`);
    return moment(time).format("h:mm A");
  } else if (numberFields.has(fieldName))
  {
    let _answer = (+answer).toLocaleString();
    `${answer}`.includes(".") && (_answer = Number((+answer).toFixed(2)).toLocaleString());
    return _answer;
  } else if (STUDENT_INTEGER_FIELDS.has(fieldName))
  {
    return Number(answer).toLocaleString();
  } else if (STUDENT_BOOLEAN_FIELDS.has(fieldName))
  {
    return Number(answer) === 4 ? "True" : "False";
  } else if (STUDENT_COORD_FIELDS.has(fieldName))
  {
    return (+answer).toFixed(6);
  } else if (STUDENT_IMAGE_FIELDS.has(fieldName))
  {
    return answer && `${IMAGE_FILED_FLAG}${`answer  system_field_image ${fieldName}_${answer}`}`;
  } else
  {
    return answer;
  }
}
