
import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: 'stripHtml'})
export class StripHtmlPipe implements PipeTransform  {
  constructor() {}
  transform(value: string) {
    if (value) {
      return value.replace(/<[^>]*>/gi, '').replace(/&[^;]*;/gi, '');
    }
  }
}
