// map mode types
export enum MapModeType {
    Hide = 'Hide',  // trip list etc
    Track = 'Track',  // route-builder component
    ViewTrip = 'ViewTrip', // trip summary
    EditTrip = 'EditTrip',  // edit trip, segmented control set to map view
    MoveStop = 'MoveStop', // edit trip, move stop
    ViewStops = 'ViewStops',  // navigation stops panel.
    Navigation = 'Navigation',  // navigation mode.
    Session = 'Session', // View the selected session
    EditTripTablet = 'Edit Trip Tablet'
  }

  export interface IMapMode {
    type: MapModeType;
    padding: {
      top: number;
      bottom: number;
      left: number;
      right: number;
    };
    className: string;
  }
