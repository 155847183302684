import { AdditionalData } from "../shared/stopfinder/models/push-notification";
import { Document, IUserDefinedField } from "../shared/stopfinder/stopfinder-models";

export interface IForm
{
  id: number;
  clientId: number;
  name: string;
  description: string;
  displayName: string;
  displayDescription: string;
  required: boolean;
  read: boolean;
  archived: boolean;
  feedbackEnabled: boolean;
  expirationDateTime: string;
  sentOn: string;
  isExpirationEnabled: boolean;
  isShowAllQuestions: boolean;
  retryCount: number;
  isSubscriberOnly: boolean;
  formSentRecipient: IFormSentRecipient[] | [];
  requireLocation: boolean
  question: IQuestion[] | [];
}

export interface IQuestion
{
  Id: number;
  TypeId: QuestionType;
  QuestionContent: string;
  PickListMultiSelect: boolean;
  MinValue: number;
  MaxValue: number;
  MaxLength: number;
  NumberPrecision: string;
  TrueDisplayName: string;
  FalseDisplayName: string;
  Scale: number;
  LeftSideRatingLabel: string;
  RightSideRatingLabel: string;
  Required: boolean;
  FormatString: string;
  QuestionPickListOptions: IQuestionPickListOption[] | [];
  Answer?: string;
  SystemFieldName?: string;
  UDFTypeName?: string;
  attachments?: Document[];
}

export interface IQuestionPickListOption
{
  Id: number;
  QuestionId: number;
  PickList: string;
  Sort: boolean;
  IsOtherOption: boolean;
}

export interface IFormSentRecipient
{
  id: number;
  formSentId: number;
  formSentName?: string;
  riderId: number;
  riderName: string;
  riderFirstName: string;
  riderLastName: string;
  completed: string;
  openedStatus: FormStatusType;
  openedStatusId: number;
  archived?: boolean;
  latitude: number;
  longitude: number;
}

export interface IFormEmit
{
  remind?: boolean;
  submit?: boolean;
  cancel?: boolean;
}

export interface IFormSendQuestionResult
{
  formSendId: number,
  recipients: Array<IRecipientQuestionResult>,
}

export interface IRecipientQuestionResult
{
  recipientId: number,
  questionsResult: Array<IQuestionResult>,
}

export interface IQuestionResult
{
  questionId: number;
  typeId: number;
  value: string | number | any[];
  other: string | null;
  otherOptionId: number | null;
  document?: Document
}

export interface ISaveQuestionPayload
{
  formSentRecipientId: number;
  results: IQuestionResult[];
  latitude?: number;
  longitude?: number;
}

/**
  BOOLEAN = 1
  DATE = 2
  DATE_TIME = 3
  MEMO = 4
  NUMBER = 5
  PHONE_NUMBER = 6
  TEXT = 7
  TIME = 8
  ZIP_CODE = 9
  LIST = 10
  -- FRONT END CUSTOMIZED:
      LIST SINGLE SELECT = 10.1
      LIST MULTIPLE SELECT = 10.2
  RATING_SCALE = 11
  SYSTEM_FIELD = 12
  CURRENCY = 13
  ATTACHMENT = 14
  SIGNATURE = 15
 */
export declare type QuestionType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 10.1 | 10.2 | 11 | 12 | 13 | 14 | 15;
export enum FormQuestionEnum
{
  boolean = 1,
  date = 2,
  dateTime = 3,
  memo = 4,
  number = 5,
  phone = 6,
  text = 7,
  time = 8,
  zip = 9,
  list = 10,
  listSingleSelect = 10.1,
  listMultipleSelect = 10.2,
  rate = 11,
  systemField = 12,
  currency = 13,
  attachment = 14,
  signature = 15,
}

export const allQuestionsList = [
  FormQuestionEnum.boolean,
  FormQuestionEnum.date,
  FormQuestionEnum.dateTime,
  FormQuestionEnum.memo,
  FormQuestionEnum.number,
  FormQuestionEnum.phone,
  FormQuestionEnum.text,
  FormQuestionEnum.time,
  FormQuestionEnum.zip,
  FormQuestionEnum.listSingleSelect,
  FormQuestionEnum.listMultipleSelect,
  FormQuestionEnum.rate,
  FormQuestionEnum.systemField,
  FormQuestionEnum.currency,
  FormQuestionEnum.signature,
  FormQuestionEnum.attachment,
]

/*
  NOT_OPENED = 1
  COMPLETED = 2
  CANCELED = 3
  REMIND_ME_LATER = 4
*/
export declare type FormStatusType = 1 | 2 | 3 | 4;
export enum FormStatusEnum
{
  NOT_OPENED = 1,
  COMPLETED = 2,
  CANCELED = 3,
  REMIND_ME_LATER = 4,
}

export interface IQuestionData extends IQuestion, IQuestionResult
{
  disable?: boolean;
  disableOther?: boolean;
  showLabel?: boolean;
  label?: string;
  defaultVal?: number | string | null;
  otherVal?: string;
  min?: number | string;
  max?: number | string;
  scale?: number;
  udfFields?: IUserDefinedField[];
  maxLength?: number;
}

export enum ReplyQuestionStatusEnum
{
  start = 'Start',
  next = 'Next',
  validate = 'Validate',
  previous = 'Previous',
  cancel = 'Cancel',
  submit = 'Submit',
  process = 'Process',
  finish = 'Finish',

  warning = '',
  error = '',
  success = '',
  required = 'Answer is required.',
  invalid = 'Invalid number.',
  invalid_answer = 'Invalid answer.',
}

export interface IRequiredQuestionValid
{
  questionId: string;
  valid: boolean;
}

export interface IFormAnswer
{
  question: string;
  answer: string;
  type: QuestionType;
}

export enum FormSource
{
  message = 'MESSAGE',
  remind = 'REMIND_ME_LATER',
}

export enum FormNotificationType
{
  REMIND_LATER_FORM_REQUIRED = 'remind_later_form_required',
  REMIND_LATER_FORM_NOT_REQUIRED = 'remind_later_form_not_required',
  NEW_FORM_REQUIRED = 'new_form_required',
  NEW_FORM_NOT_REQUIRED = 'new_form_not_required',
}

export interface FormNotificationParams
{
  additionalData: AdditionalData;
  title?: string;
  isShowMap?: boolean;
  showNotification?: Function;
  hideMap?: Function;
}
