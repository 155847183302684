import { BaseVectorMapGenerator } from '../classes/basemap.base';
import { eBaseMaps, BaseMapThumbnailEnum } from '../classes/basemap.enum';

export class DarkGreyMap extends BaseVectorMapGenerator
{
  constructor()
  {
    super();
  }

  public get thumbnail(): string { return './assets/images/Dark-Grey-Canvas.png'; }
  public get title(): string { return 'Dark Grey'; }
  public get jsonUrl(): string { return './assets/vectors/dark_grey.json'; }
  public get allowRotation(): boolean { return true; }
  public get maxZoom(): number { return 21; }
  public get maxNativeZoom(): number { return 19; }
  public get type(): eBaseMaps { return eBaseMaps.DarkGrey; }
  public get translateKey(): BaseMapThumbnailEnum { return BaseMapThumbnailEnum.DarkGrey; }
}
